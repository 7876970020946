import React, { FC } from 'react';
import { Collapse, List } from '@mui/material';
import { MenuItem as MenuItemModel } from 'src/declarations/models/MenuItem';
import { MenuItem } from './MenuItem';

export interface SubMenuProps {
  open?: boolean;
  toggleMenuOpen: () => void;
  menuItems: Array<MenuItemModel>;
}

export const SubMenu: FC<SubMenuProps> = ({ open = true, menuItems, toggleMenuOpen }) => {
  return (
    <Collapse in={open} timeout='auto' unmountOnExit sx={{ pl: '20px' }}>
      <List component='div' disablePadding>
        {menuItems?.map((value) => {
          return <MenuItem key={value.label} item={value} menuIsOpen={open} toggleMenuOpen={toggleMenuOpen} />;
        })}
      </List>
    </Collapse>
  );
};
