import { inputGroupStyling } from '@/editor/MediaEditor/MediaEditorFields/EditMediaTextFields';
import Container from '@/components/Container';
import React, { FC, useEffect, useState } from 'react';
import { Employee } from '@/declarations/models/Employee';
import { Page } from '@/declarations/models/Page';
import M24PageFinder from '@/components/Finder/M24PageFinder/M24PageFinder';
import { Avatar, Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Api } from '@/services/Api';
import Styles from '@/assets/js/Styles';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { MaterialSymbol } from '@/components/MaterialSymbol';

interface EditEmployeePageFieldProps {
  employee?: Partial<Employee>;
  onChange: (page: Page | null) => void;
}

export const EditEmployeePageField: FC<EditEmployeePageFieldProps> = ({ employee, onChange }) => {
  const { t: tComponents } = useTranslation('components');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    let unmounted = false;
    if (employee?.linked_page_id) {
      setLoading(true);
      const ctx = Api.getPage(employee.linked_page_id);
      ctx
        .fetchDirect(null)
        .catch((e) => console.error(e))
        .then((res) => {
          if (!unmounted) {
            setSelectedPage(res || null);
          }
        })
        .finally(() => {
          if (!unmounted) {
            setLoading(false);
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, [employee?.linked_page_id]);

  const onSelectPage = (page?: Page | null) => {
    setPageFinderOpen(false);
    setSelectedPage(page || null);
    onChange(page || null);
  };

  return (
    <Container left sx={inputGroupStyling} column>
      <M24PageFinder
        open={pageFinderOpen}
        hideSiteSelector
        multiSelect={false}
        onSelectionConfirmed={(pages) => {
          if (pages.length) {
            onSelectPage(pages[0].source);
          }
        }}
        onClose={() => {
          setPageFinderOpen(false);
        }}
      />
      <Typography variant='h6'>{tComponents('EmployeeEditor.PageLinkTitle')}</Typography>
      {loading && <CircularProgress size={20} color='secondary' />}
      {!loading && selectedPage && (
        <Container
          spaceBetween
          fullWidth
          sx={{
            backgroundColor: Styles.Colors.LIGHTEST_GREY,
            border: `1px solid ${Styles.Colors.LIGHT_GREY}`,
            borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
            p: 2,
          }}>
          <Container gap={1}>
            <PageStatusCircle status={selectedPage.status} />
            <Typography>{selectedPage.title}</Typography>
          </Container>
          <Container>
            {selectedPage?.image_src && <Avatar src={selectedPage.image_src} alt={selectedPage.title} />}
            <Tooltip title={tComponents('EmployeeEditor.RemovePage')} arrow>
              <IconButton onClick={() => onSelectPage(null)}>
                <MaterialSymbol name='cancel' color='secondary' />
              </IconButton>
            </Tooltip>
          </Container>
        </Container>
      )}
      <Button variant='contained' color='secondary' onClick={() => setPageFinderOpen(true)}>
        {tComponents('EmployeeEditor.SelectPage')}
      </Button>
    </Container>
  );
};
