import React, { FC, useEffect, useState } from 'react';
import { Card } from '@/declarations/models/Card';
import { Api } from '@/services/Api';
import { useTranslation } from 'react-i18next';
import { M24MediaFinder } from '@/components/Finder/M24MediaFinder/M24MediaFinder';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import { GenericMedia } from '@/declarations/GenericMedia';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import Container from '@/components/Container';
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isDeepEqual } from '@/utils/object';
import { Skin } from '@/declarations/models/Skin';
import { Tag } from '@/declarations/models/Tag';
import { M24PageFinder } from '@/components/Finder/M24PageFinder/M24PageFinder';
import { Page } from '@/declarations/models/Page';
import Loader from '@/components/Loader';
import { editorStyling } from '@/editor/EmployeeEditor/EmployeeEditor';
import SimpleEditorHeader from '@/editor/lib/components/EditorHeader/SimpleEditorHeader';
import Styles from '@/assets/js/Styles';
import ImageWithFocusPointSelector from '@/components/ImageWithFocusPointSelector';
import { CardPreview } from '@/components/CardPreview';
import { BlockType } from '@/declarations/models/BlockType';
import { Status } from '@/declarations/models/Status';
import { CardSize } from '@/declarations/models/CardSize';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SkinSelectGroup } from '@/components/SkinSelectGroup';
import { Location } from '../../declarations/models/Location';

const Preview: FC<{ card: Card; skins: Skin[] }> = ({ card, skins }) => {
  const selectedSkin = skins.find((skin) => skin.class === card.skin);
  return (
    <Container
      fullWidth
      fullHeight
      sx={{
        backgroundColor: '#ccc',
        overflow: 'hidden',
      }}>
      <CardPreview
        image={card.mediaobject ?? undefined}
        selectedSkin={selectedSkin}
        title={card.title}
        description={card.description}
        callToActionButtonText={card.callToActionButtonText}
        footer={card?.footerText}
        location={card?.content?.location?.title}
        footerLocation={card?.footerLocation}
        pageLink={card?.page?.title}
        url={card?.page?.url}
        externalUrl={card.url}
      />
    </Container>
  );
};

interface CardEditorProps {
  siteId: number;
  cardId?: number;
  pageId?: number;
  onCloseEditor: () => void;
  skins?: Skin[];
  categories?: Tag[];
  onAfterSave?: (card: Card) => void;
  defaultLocale: string;
}

const getDefaultCard = (siteId: number, defaultLocale: string, pageId?: any): Card =>
  ({
    site_id: siteId,
    locale: defaultLocale,
    block_type: BlockType.CARD,
    type: BlockType.CARD,
    status: Status.GENERAL,
    id: undefined,
    title: '',
    description: '',
    skin: '',
    category: undefined,
    url: '',
    footerText: '',
    mediaobject: null,
    page_id: pageId || null,
    content: {
      size: CardSize.SMALL,
      page: null,
      type: BlockType.CARD,
      descr: '',
    },
  } as Card);

const CardEditor: FC<CardEditorProps> = ({
  siteId,
  cardId,
  pageId,
  onCloseEditor,
  skins,
  categories,
  onAfterSave,
  defaultLocale,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tAria } = useTranslation('aria');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mediaFinderOpen, setMediaFinderOpen] = useState<boolean>(false);
  const [card, setCard] = useState<Card | undefined>(
    cardId ? undefined : getDefaultCard(siteId, defaultLocale, pageId),
  );

  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);

  const [modifiedCard, setModifiedCard] = useState<Card | null>(card ?? null);
  const [dirty, setDirty] = useState<boolean>(false);

  const [locations, setLocations] = useState<Array<Location>>();

  useEffect(() => {
    if (siteId) {
      const ctx = Api.getSiteLocations(siteId);
      ctx
        .fetchDirect(null)
        .then((l) => !!l && setLocations(l))
        .finally(ctx.abort);
    }
  }, []);

  useEffect(() => {
    if (card) {
      setModifiedCard(card);
    }
  }, [card]);

  useEffect(() => {
    if (card && modifiedCard) {
      setDirty(!isDeepEqual(card, modifiedCard));
    }
  }, [card, modifiedCard]);

  useEffect(() => {
    if (cardId) {
      let unmounted = false;
      setIsLoading(true);
      const ctx = Api.getBlock(siteId, cardId);
      ctx
        .fetchDirect(null)
        .then((res) => {
          if (res && !unmounted) {
            if (res.descr) {
              res.description = res.descr;
            }
            // fixDescriptionAndSetCard(res as Card);
            setCard(res as Card);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => {
          if (!unmounted) {
            setIsLoading(false);
          }
        });
      return () => {
        unmounted = true;
        ctx.abort();
      };
    }
  }, [cardId, siteId]);

  const onSelectImage = (img?: GenericMedia<M24MediaModel>[]) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        mediaobject: img ? img[0].source : null,
      });
    }
    setMediaFinderOpen(false);
  };

  // FIXME: the connection between a "block" and "page" is messy.
  //  both page_id and page on root is used by backend, and content.page is used by the preview.
  const onSelectPage = (page?: Page) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        page_id: page?.id ? Number(page.id) : null,
        page: page ?? null,
        content: {
          ...modifiedCard.content,
          page: page ?? null,
        },
      });
    }
    setPageFinderOpen(false);
  };

  const save = async () => {
    if (modifiedCard) {
      const ctx = Api.saveBlock(siteId, modifiedCard);
      await ctx
        .fetchDirect(null)
        .then((res) => {
          if (res) {
            setCard(res as Card);
          }
          onAfterSave?.(card as Card);
        })
        .catch((e) => console.error(e))
        .finally(ctx.abort);
    }
  };

  const setTitle = (title: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        title,
      });
    }
  };

  const setDescription = (description: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        description,
        descr: description,
      });
    }
  };

  const setExternalUrl = (url: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        url,
      });
    }
  };

  const setFooterLocation = (footerLocation: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        footerLocation,
      });
    }
  };

  const setFooterText = (footerText: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        footerText,
      });
    }
  };
  const setCallToActionButtonText = (callToActionButtonText: string) => {
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        callToActionButtonText,
      });
    }
  };

  const onSkinChange = (skin: Skin | null) => {
    let _skin = skin?.class;

    if (modifiedCard) {
      if (modifiedCard?.skin === skin?.class) {
        _skin = '';
      }
      setModifiedCard({
        ...modifiedCard,
        skin: _skin ?? undefined,
      });
    }
  };

  const handleCategoryChange = (_e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedCategory = categories?.find((category) => category.id === Number(id));
    if (modifiedCard) {
      setModifiedCard({
        ...modifiedCard,
        category: selectedCategory ?? {},
      });
    }
  };

  const setFocusPoint = ({ x, y }: { x: string; y: string }) => {
    if (modifiedCard && modifiedCard.mediaobject) {
      setModifiedCard({
        ...modifiedCard,
        mediaobject: {
          ...modifiedCard.mediaobject,
          local: {
            ...modifiedCard.mediaobject.local,
            x,
            y,
          },
        },
      });
    }
  };

  return (
    <Container fullHeight fullWidth column gap={0}>
      <SimpleEditorHeader title={card?.title} model={card} isDirty={dirty} onClose={onCloseEditor} onSave={save} />

      <Container fullWidth gap={1} sx={{ marginTop: '16px', height: 'calc(100% -  56px)' }}>
        <Container
          data-testid='editor'
          fullWidth
          fullHeight
          column
          top
          gap={1}
          sx={{ ...editorStyling, backgroundColor: 'white' }}>
          {isLoading || !modifiedCard ? (
            <Loader />
          ) : (
            <Container column fullWidth gap={2}>
              <M24MediaFinder
                open={mediaFinderOpen}
                onClose={() => {
                  setMediaFinderOpen(false);
                }}
                onSelectionConfirmed={onSelectImage}
                defaultResourceType={MediaResourceType.IMAGE}
                selectableResourceTypes={[MediaResourceType.IMAGE]}
                multiSelect={false}
              />
              <M24PageFinder
                open={pageFinderOpen}
                hideSiteSelector
                onSelectionConfirmed={(pages) => {
                  if (pages.length) {
                    onSelectPage(pages[0].source);
                  }
                }}
                onClose={() => {
                  setPageFinderOpen(false);
                }}
              />
              <Container
                left
                gap={1}
                sx={{
                  padding: 2,
                  width: '100%',
                  backgroundColor: Styles.Colors.LIGHT_GREY,
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
                column>
                <Container left fullWidth gap={1} sx={{ minHeight: 'min-content' }}>
                  <Container fullWidth>
                    {modifiedCard.mediaobject?.url && (
                      <ImageWithFocusPointSelector
                        src={modifiedCard.mediaobject.url}
                        mimetype={modifiedCard.mediaobject?.mimetype}
                        x={modifiedCard.mediaobject.local?.x}
                        y={modifiedCard.mediaobject.local?.y}
                        maxWidth='150px'
                        onFocusPointChanged={setFocusPoint}
                        alt={modifiedCard.mediaobject.filename}
                      />
                    )}
                    <Container fullHeight column gap={1} sx={{ flexGrow: 1 }}>
                      <Container column fullWidth fullHeight top left>
                        {modifiedCard?.mediaobject && (
                          <Button
                            variant='text'
                            startIcon={<MaterialSymbol name='do_not_disturb_on' />}
                            onClick={() => onSelectImage(undefined)}>
                            {tComponents('CardEditor.RemoveImage')}
                          </Button>
                        )}
                        <Box flexGrow={1} display='flex' alignItems='center' justifyContent='center' width='100%'>
                          <Container column gap={1}>
                            <Button variant='contained' color='secondary' onClick={() => setMediaFinderOpen(true)}>
                              {tComponents(modifiedCard?.mediaobject ? 'CardEditor.SwapImage' : 'CardEditor.FindImage')}
                            </Button>
                          </Container>
                        </Box>
                      </Container>
                    </Container>
                  </Container>
                  <Container fullWidth>
                    <Box sx={{ maxHeight: '250px', overflow: 'auto' }}>
                      <SkinSelectGroup
                        selectedSkin={skins?.find((s) => s.class === modifiedCard?.skin)}
                        onSkinChange={onSkinChange}
                        availableSkins={skins}
                      />
                    </Box>
                  </Container>
                </Container>
              </Container>

              <Container column fullWidth left>
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField fullWidth value={modifiedCard.title ?? ''} onChange={(e) => setTitle(e.target.value)} />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.Title')}</Typography>}
                  labelPlacement='top'
                />
              </Container>
              <Container mb={0} column fullWidth left>
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField
                      minRows={2}
                      maxRows={10}
                      fullWidth
                      multiline
                      value={modifiedCard.descr ?? ''}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.Description')}</Typography>}
                  labelPlacement='top'
                />
              </Container>

              <Container fullWidth left>
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField
                      fullWidth
                      value={modifiedCard.callToActionButtonText ?? ''}
                      onChange={(e) => setCallToActionButtonText(e.target.value)}
                    />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.callToActionButtonText')}</Typography>}
                  labelPlacement='top'
                />

                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField
                      fullWidth
                      value={modifiedCard.footerLocation ?? ''}
                      onChange={(e) => setFooterLocation(e.target.value)}
                    />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.FooterLocation')}</Typography>}
                  labelPlacement='top'
                />

                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField
                      fullWidth
                      value={modifiedCard.footerText ?? ''}
                      onChange={(e) => setFooterText(e.target.value)}
                    />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.Footer')}</Typography>}
                  labelPlacement='top'
                />
              </Container>

              {!pageId && (
                <>
                  <Container column fullWidth left>
                    {modifiedCard.page && (
                      <Container
                        spaceBetween
                        fullWidth
                        sx={{
                          backgroundColor: Styles.Colors.LIGHTEST_GREY,
                          borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
                          p: 2,
                        }}>
                        <Container gap={1}>
                          <PageStatusCircle status={modifiedCard.page.status} />
                          <Typography>{modifiedCard.page.title}</Typography>
                        </Container>
                        <Container>
                          {modifiedCard.page?.image_src && (
                            <Avatar src={modifiedCard.page.image_src} alt={modifiedCard.page.title} />
                          )}
                          <Tooltip title={tComponents('CardEditor.RemovePage')} arrow>
                            <IconButton onClick={() => onSelectPage(undefined)}>
                              <MaterialSymbol name='cancel' color='secondary' />
                            </IconButton>
                          </Tooltip>
                        </Container>
                      </Container>
                    )}

                    <Button variant='contained' color='secondary' onClick={() => setPageFinderOpen(true)}>
                      {tComponents('CardEditor.Page')}
                    </Button>
                  </Container>
                </>
              )}

              <Container column fullWidth left>
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <TextField
                      fullWidth
                      value={modifiedCard.url ?? ''}
                      onChange={(e) => setExternalUrl(e.target.value)}
                    />
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.Url')}</Typography>}
                  labelPlacement='top'
                />
              </Container>

              <Box
                sx={{
                  flex: '1',
                  width: '100%',
                  maxHeight: '250px',
                  overflow: 'auto',
                  padding: 2,
                  backgroundColor: Styles.Colors.LIGHT_GREY,
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}>
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    width: '100%',
                    mx: 0,
                  }}
                  control={
                    <RadioGroup
                      sx={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        wrap: 'wrap',
                      }}
                      aria-label={tAria('components.CardEditor.Category')}
                      value={modifiedCard.category?.id ?? ''}
                      onChange={handleCategoryChange}>
                      {(categories || []).map((category) => (
                        <FormControlLabel
                          key={category.id}
                          control={<Radio role='radio' aria-label={category.tag} />}
                          value={category.id}
                          label={category.tag}
                        />
                      ))}
                      <FormControlLabel
                        key='nocategory'
                        control={<Radio role='radio' aria-label='Ingen' />}
                        value=''
                        label={tComponents('CardEditor.NoCategory')}
                      />
                    </RadioGroup>
                  }
                  label={<Typography fontWeight='bold'>{tComponents('CardEditor.Category')}</Typography>}
                  labelPlacement='top'
                />
              </Box>
            </Container>
          )}
        </Container>
        {modifiedCard && <Preview card={modifiedCard} skins={skins || []} />}
      </Container>
    </Container>
  );
};

export default CardEditor;
