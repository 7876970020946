import { createContext, useContext } from 'react';

export interface MediaEditorContextValue {
  editorOpen?: boolean;
  setEditorOpen: (open: boolean) => void;
  mediaId?: number;
  setMediaId: (id: number | undefined) => void;
}
export const MediaEditorContext = createContext<MediaEditorContextValue | null>(null);

export function useMediaEditorContext(): MediaEditorContextValue {
  const value = useContext(MediaEditorContext);
  if (!value) {
    throw new Error('MediaEditorContext accessed before init');
  }
  return value;
}
