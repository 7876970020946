import React, { Fragment } from 'react';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/forms/TextInput';
import SwitchInput from '../../../../components/forms/SwitchInput';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { M24MediaModel } from '../../../../declarations/models/M24MediaModel';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useKeyGenerator } from '../../../../hooks/useKeyGenerator';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import DropIndicatorArrow from '../../../../components/DropIndicatorArrow';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { MediaResourceType } from '../../../../declarations/models/MediaResourceType';
import AttachmentsBlockItem from './AttachmentsBlockItem';
import { AttachmentsBlock as AttachmentsBlockModel } from '../../../../declarations/models/blocks/AttachmentsBlock';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import Styles from '../../../../assets/js/Styles';

export const AttachmentsBlock: BlockComponent = () => {
  const { t } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  const getAttachmentItemKey = useKeyGenerator<M24MediaModel>((item): string =>
    String(item.id || item.dms_id || item.filename || item.url),
  );

  const {
    fields: selectedMediaItems,
    append,
    insert,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<AttachmentsBlockModel>, `${BlockPath}.items`, 'itemHash'>({
    name: `${blockPath}.items`,
  });

  const handleAddM24Model = (item: M24MediaModel | null) => {
    if (item) {
      append(item as M24MediaModel);
    }
  };

  const settingsAreaStyle = {
    width: '100%',
    backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR,
    padding: 2,
    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
    gap: 2,
  };

  return (
    <Container gap={0} column top left fullWidth>
      {(selectedMediaItems || []).map((item, i) => (
        <Fragment key={getAttachmentItemKey(item)}>
          <Collapse sx={{ width: '100%' }}>
            <DropIndicatorArrow
              label={t('Draggable.MoveHere')}
              borderStyle='dashed'
              typographyProps={{
                variant: 'caption',
                lineHeight: '1rem',
                fontStyle: 'italic',
              }}
              spacing={2}
              visible
              noArrow
            />
          </Collapse>
          <AttachmentsBlockItem
            index={i}
            onReorder={move}
            onAdd={(itemToAdd, addAtIndex) => itemToAdd && insert(addAtIndex, itemToAdd)}
            onRemove={remove}
          />
        </Fragment>
      ))}

      <Container pt={4} fullWidth>
        <FinderButton
          type={FinderType.M24}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
              items.forEach((item) => handleAddM24Model(item.source)),
            defaultResourceType: MediaResourceType.DOCUMENT,
            selectableResourceTypes: [MediaResourceType.DOCUMENT, MediaResourceType.IMAGE],
          }}
        />
      </Container>

      <br />

      <Container sx={settingsAreaStyle} column fullWidth left>
        <TextInput path={`${blockPath}.rowMax`} label={tComponents('AttachmentsBlock.MaxRows')} type='number' />

        <SwitchInput
          fullWidth={false}
          path={`${blockPath}.listSearch`}
          label={tComponents('AttachmentsBlock.ShowSearchField')}
        />

        <TextInput
          path={`${blockPath}.listSearchText`}
          label={tComponents('AttachmentsBlock.SearchFieldText')}
          type='text'
        />
      </Container>
    </Container>
  );
};

export default AttachmentsBlock;
