import Container from '@/components/Container';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { PageCountResponse } from '@/declarations/models/CountResponses';
import { useStore } from '@/components/store/Store';
import { Api } from '@/services/Api';
import { Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import Styles from '@/assets/js/Styles';
import { Page } from '@/declarations/models/Page';
import { apiTimestampToDate } from '@/utils/dates';
import CollapseContainer from '@/components/CollapseContainer';
import { DescriptionList } from './DescriptionList';

function sortByLastUpdated(page1: Page, page2: Page): number {
  const a = apiTimestampToDate(page1.updated_at || '')?.getTime() || 0;
  const b = apiTimestampToDate(page2.updated_at || '')?.getTime() || 0;

  return b - a;
}

export const ContentWidget: FC<{ is_event?: boolean }> = ({ is_event }) => {
  const { t: tComponents } = useTranslation('components');
  const [pageCount, setPageCount] = useState<PageCountResponse>();
  const {
    state: { selectedSite, selectedSiteLanguage },
  } = useStore();

  useEffect(() => {
    if (selectedSite && selectedSiteLanguage) {
      const ctx = Api.getPageCount(selectedSite.id!, is_event, selectedSiteLanguage);
      setPageCount(undefined);
      ctx
        .fetchDirect(null)
        .then((countRes) => !!countRes && setPageCount(countRes))
        .finally(ctx.abort);
    } else {
      setPageCount(undefined);
    }
  }, [is_event, selectedSite, selectedSiteLanguage]);

  const [allPages, setAllPages] = useState<Page[]>();
  useEffect(() => {
    if (selectedSite) {
      Api.getPagesListForSite(selectedSite?.id ?? 0, { is_event })
        .fetchDirect(null)
        .then((response) => setAllPages(response ?? []));
    }
  }, [is_event, selectedSite]);

  const recentlyEditedPagesForLanguage = useMemo(() => {
    return allPages
      ?.filter((page) => page.locale === selectedSiteLanguage)
      .sort(sortByLastUpdated)
      .slice(0, 6);
  }, [allPages, selectedSiteLanguage]);

  return (
    <Container
      fullWidth
      fullHeight
      top
      left
      column
      p={2}
      sx={{
        backgroundColor: is_event ? Styles.Colors.STRONG_GREEN_TRANSPARENT : Styles.Colors.LIGHT_GREY,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Container spaceBetween fullWidth>
        <Typography fontSize='1.25rem' fontWeight={500}>
          {tComponents(`DashboardView.ContentWidget.${is_event ? 'EventsTitle' : 'PagesTitle'}`)}
        </Typography>
        {is_event ? (
          <CustomIcon name='calendar_event' sx={{ height: '2rem', width: '2rem' }} />
        ) : (
          <MaterialSymbol name='folder_open' sx={{ fontSize: '2rem' }} />
        )}
      </Container>
      <Container fullWidth column left>
        <DescriptionList
          items={
            pageCount
              ? Object.entries(pageCount).map(([k, v]) => [`${tComponents(`DashboardView.CountResLabel.${k}`)}`, v])
              : undefined
          }
        />
        <hr
          style={{
            border: '1px solid rgba(0, 0, 0, 0.12)',
            width: '100%',
          }}
        />
        <CollapseContainer title={tComponents('DashboardView.ContentWidget.LastEdited')}>
          <List dense>
            {recentlyEditedPagesForLanguage?.map((page) => (
              <ListItem key={page.id}>
                <Link href={`/editor/${page.id}`}>{page.title}</Link>
              </ListItem>
            ))}
          </List>
        </CollapseContainer>
      </Container>
    </Container>
  );
};
