import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Subject } from 'rxjs';
import { ChildrenProp } from '../../../declarations/ChildrenProp';

/**
 * All types of events that will occur in the editor
 */
export enum EditorEventType {
  /**
   * A PreviewBlock has been clicked on
   */
  PREVIEW_BLOCK_CLICKED = 'previewBlockClicked',
  /**
   * A Section has been expanded
   */
  SECTION_EXPANDED = 'sectionExpanded',
  DRAGGING = 'dragging',
  SECTION_REORDERED = 'sectionReordered',
  BLOCK_OR_SECTION_ADDED = 'blockAdded',
  /**
   * A block was pasted in some section.
   * Payload contains the "id" of the source block if the block was cut away from somewhere else.
   */
  BLOCK_PASTED = 'blockPasted',
}

/**
 * Constraints what types can be used as payload
 */
type EditorEventPayloadMapType = { [type in EditorEventType]: null | unknown };

// TODO: 13/11/2023 find a better way to do type narrowing
/**
 * Definition of Payload-types for all EditorEventTypes
 */
interface EditorEventPayloadMap extends EditorEventPayloadMapType {
  [EditorEventType.PREVIEW_BLOCK_CLICKED]: { anchorId: string; scrollIntoView: boolean };
  [EditorEventType.SECTION_EXPANDED]: { anchorId: string };
  [EditorEventType.DRAGGING]: { anchorId?: string; type: string };
  [EditorEventType.SECTION_REORDERED]: { anchorId?: string; type: string };
  [EditorEventType.BLOCK_OR_SECTION_ADDED]: { anchorId?: string };
  [EditorEventType.BLOCK_PASTED]: { anchorId?: string; cutBlockId?: string };
}

/**
 * An event emitted from the event manager
 */
export interface EditorEvent<T extends EditorEventType = EditorEventType> {
  type: T;
  timestamp: number;
  payload: EditorEventPayloadMap[T];
}

/**
 * The operations available to perform on the EventManager
 */
export interface EventManagerApi {
  /**
   * Fire a certain event to notify all observers
   * @param event
   */
  fireEvent: <T extends EditorEventType, U extends EditorEvent<T>['payload']>(event: T, payload: U) => void;
  /**
   * Check whether an event has occurred at least once
   * @param eventType
   */
  hasBeenFired: (eventType: EditorEventType) => boolean;
}

/**
 * Additional data/operations on the context-value to be used internally
 */
type EventManagerApiInternal = EventManagerApi & Pick<Subject<EditorEvent>, 'subscribe'>;

/**
 * The actual EventManager-context providing the Internal (and external) API.
 */
const EventManager = createContext<EventManagerApiInternal | null>(null);

/**
 * Access the EditorEventManager
 */
export function useEditorEventManager(): EventManagerApi {
  const eventManager = useContext(EventManager);
  if (!eventManager) {
    throw new Error('EventManager not initialized');
  }
  return eventManager as EventManagerApi;
}

/**
 * Listen to and handle events from the EditorEventManager
 * @param handler
 */
export function useEditorEventHandler(handler: (event: EditorEvent) => Promise<void> | void): void {
  const eventHandler = useRef(handler);
  eventHandler.current = handler;

  const eventManager = useContext(EventManager);

  if (!eventManager) {
    throw new Error('EventManager not initialized');
  }

  useEffect(() => {
    const sub = eventManager.subscribe((event) => {
      eventHandler.current(event);
    });
    return () => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    };
  }, [eventManager]);
}

/**
 * Manages events dispatched between the Editor and the Preview.
 * @param children
 * @constructor
 */
export const EditorEventManager: FC<ChildrenProp> = ({ children }) => {
  const firedEventsCount = useRef<{ [event in EditorEventType]?: number }>({});
  const eventManager = useMemo<Subject<EditorEvent>>(() => new Subject<EditorEvent>(), []);

  const fireEvent = useCallback<EventManagerApiInternal['fireEvent']>(
    (event: EditorEventType, payload) => {
      firedEventsCount.current[event] = (firedEventsCount.current[event] || 0) + 1;
      eventManager.next({
        type: event,
        timestamp: Date.now(),
        payload,
      });
    },
    [eventManager],
  );

  const hasBeenFired = useCallback<EventManagerApiInternal['hasBeenFired']>((eventType) => {
    return (firedEventsCount.current[eventType] || 0) > 0;
  }, []);

  const eventHubApi = useMemo<EventManagerApiInternal>(
    () => ({
      subscribe: eventManager.subscribe.bind(eventManager),
      fireEvent,
      hasBeenFired,
    }),
    [hasBeenFired, eventManager, fireEvent],
  );

  return <EventManager.Provider value={eventHubApi}>{children}</EventManager.Provider>;
};

export default EditorEventManager;
