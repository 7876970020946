import { FormModuleInput, FormModuleResponse } from '@/declarations/models/FormModule';
import React, { FC, useState } from 'react';
import { Box, Button, CircularProgress, Collapse, TableCell, TableRow } from '@mui/material';
import { FormDataResponseDetails } from '@/views/FormView/FormData/FormDataResponseDetails';
import { formatAPITimestamp } from '@/utils/dates';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { notNil } from '@/utils/object';

export interface FormDataTableRowProps {
  row: FormModuleResponse;
  showDelete: boolean;
  onDelete?: (id: number) => Promise<void>;
  isDeleting?: boolean;
}

function flatInputs(row: FormModuleResponse): Array<FormModuleInput> {
  return row.content?.sections?.flatMap((section) => section.inputs) || [];
}

function findFirstNameValue(row: FormModuleResponse): string | undefined {
  return flatInputs(row)
    .map((input) => {
      if ((input.type === 'input' && input.subtype === 'name') || input.type === 'user') {
        if (input.firstname?.value || input.lastname?.value) {
          return `${input.firstname.value || ''} ${input.lastname.value || ''}`;
        }
      }
      return null;
    })
    .filter(notNil)[0];
}

function findFirstEmailValue(row: FormModuleResponse): string | undefined {
  return flatInputs(row)
    .map((input) => {
      if (input.type === 'input' && input.subtype === 'email') {
        return input.value;
      }
      if (input.type === 'user') {
        return input.email?.value;
      }
      return null;
    })
    .filter(notNil)[0];
}

export const FormDataTableRow: FC<FormDataTableRowProps> = ({ row, showDelete, onDelete, isDeleting }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component='td'>{formatAPITimestamp(row.created_at, 'datetime')}</TableCell>
        <TableCell component='td'>{findFirstNameValue(row)}</TableCell>
        <TableCell component='td'>{findFirstEmailValue(row)}</TableCell>
        <TableCell component='td'>{row.id}</TableCell>
        <TableCell component='td'>
          <Box display='flex' gap={1}>
            <Button variant='text' size='small' onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Lukk' : 'Vis data'}
            </Button>
            {showDelete && (
              <Button
                variant='contained'
                color='secondary'
                size='small'
                sx={{
                  backgroundColor: Styles.Colors.BLACK,
                }}
                onClick={() => onDelete?.(row.id!)}
                disabled={isDeleting}
                startIcon={
                  isDeleting ? <CircularProgress size={14} color='secondary' /> : <MaterialSymbol name='delete' fill />
                }>
                Slett
              </Button>
            )}
          </Box>
        </TableCell>
      </TableRow>

      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}>
        <TableCell component='td' colSpan={5} sx={{ p: 0 }}>
          <Collapse in={expanded} timeout={300} unmountOnExit>
            <FormDataResponseDetails response={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
