import React, { FC, useEffect, useState } from 'react';
import { ChildrenProp } from '@/declarations/ChildrenProp';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Api } from '@/services/Api';
import { useStore } from '@/components/store/Store';
import { FormModule, FormModuleResponse } from '@/declarations/models/FormModule';
import {
  Box,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import Styles from '@/assets/js/Styles';
import Loader from '@/components/Loader';
import Container from '@/components/Container';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import ActionMenuButton from '@/components/ActionMenuButton';
import Paginator from '@/components/Paginator';
import PageSizeSelector from '@/components/PageSizeSelector';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { FormDataTableRow } from './FormDataTableRow';

export const FormDataView: FC<ChildrenProp> = () => {
  const params = useParams<{ formId?: string }>();
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [responses, setResponses] = useState<Array<FormModuleResponse>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedForm, setSelectedForm] = useState<FormModule | undefined>(undefined);
  const [deletingIndividual, setDeletingIndividual] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeletingRow, setIsDeletingRow] = useState<Record<number, boolean>>({});
  const [refresh, setRefresh] = useState<number>(0);
  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [order, setOrder] = useState<string>('desc');

  useEffect(() => {
    if (!params.formId || !siteId) {
      return;
    }
    Api.getFormModule(Number(params.formId))
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setSelectedForm(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [params.formId, siteId]);

  useEffect(() => {
    if (!params.formId || !siteId) {
      return;
    }
    let unmounted = false;
    const ctx = Api.getFormModuleContent(siteId, Number(params.formId), page.start, page.rows, order);

    setIsLoading(true);
    ctx
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setResponses(res.contents);
          setTotalItemCount(res.total_count);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [params.formId, siteId, refresh, page, order]);

  if (!params.formId) {
    return null;
  }

  const onExportData = async (format: 'xls' | 'csv' | 'pdf') => {
    setIsExporting(true);
    const currentDate = new Date().toISOString().split('T')[0];
    const responseObj = await Api.exportFormModuleContent(siteId, Number(params.formId), format);
    const body = await responseObj.arrayBuffer();
    const url = URL.createObjectURL(new Blob([body], { type: responseObj.headers.get('Content-Type') ?? undefined }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedForm?.title || 'data'}-${currentDate}.${format}`;
    a.click();
    URL.revokeObjectURL(url);
    setIsExporting(false);
  };

  const onDeleteFormResponse = async (id: number) => {
    setIsDeletingRow({ ...isDeletingRow, [id]: true });
    await Api.deleteFormModuleContentById(Number(params.formId), id).fetchDirect(null);
    setIsDeletingRow((prev) => {
      const newState = { ...prev };
      delete newState[id];
      return newState;
    });
    setResponses((prev) => prev.filter((response) => response.id !== id));
  };

  const onDeleteData = async (days?: 90 | 180 | 365) => {
    setIsDeleting(true);
    if (!days) {
      await Api.deleteAllFormModuleContent(Number(params.formId)).fetchDirect(null);
    } else {
      await Api.deleteFormModuleContentByDaysOld(Number(params.formId), days).fetchDirect(null);
    }
    setIsDeleting(false);
    setRefresh(Date.now());
  };

  const handlePageChange = ({ start, rows }: { start?: number; rows?: number }) => {
    let _start = start;
    if (rows !== page.rows) {
      _start = 0;
    }
    setPage({ start: _start ?? page.start, rows: rows ?? page.rows });
  };

  return (
    <Box p={4} mx={4}>
      <Link to='/forms'>{tComponents('FormView.BackToOverview')}</Link>

      <ToolbarHeader
        heading={
          <h1>
            {tComponents('FormView.FormData')}: <i>{selectedForm?.title}</i>
          </h1>
        }
        // onQueryChange={() => {/* todo */}}
        boxProps={{
          mb: 4,
        }}
        bottomRight={
          <Box display='flex' whiteSpace='nowrap' gap={1}>
            <ActionMenuButton
              ButtonProps={{
                variant: 'contained',
                size: 'medium',
              }}
              width='200px'
              id='export-data-selector'
              ariaLabel='download'
              icon={
                isExporting ? <CircularProgress size={14} color='secondary' /> : <MaterialSymbol name='ios_share' />
              }
              disabled={!responses?.length || isExporting}
              text={tComponents('FormView.ExportData')}>
              <MenuItem onClick={() => onExportData('xls')}>Excel (.xlsx)</MenuItem>
              <MenuItem onClick={() => onExportData('csv')}>CSV</MenuItem>
              <MenuItem onClick={() => onExportData('pdf')}>PDF</MenuItem>
            </ActionMenuButton>

            <ActionMenuButton
              ButtonProps={{
                variant: 'contained',
                size: 'medium',
              }}
              width='200px'
              id='text-size-selector'
              ariaLabel='download'
              tooltipPlacement='top'
              icon={
                isDeleting ? <CircularProgress size={14} color='secondary' /> : <MaterialSymbol name='delete' fill />
              }
              disabled={!responses?.length || isDeleting}
              text={tComponents('FormView.DeleteData')}>
              <MenuItem onClick={() => onDeleteData(365)}>{tComponents('FormView.OlderThanX', { days: 365 })}</MenuItem>
              <MenuItem onClick={() => onDeleteData(180)}>{tComponents('FormView.OlderThanX', { days: 180 })}</MenuItem>
              <MenuItem onClick={() => onDeleteData(90)}>{tComponents('FormView.OlderThanX', { days: 90 })}</MenuItem>
              <MenuItem onClick={() => setDeletingIndividual(!deletingIndividual)}>
                {tComponents('FormView.DeleteIndividual')}
              </MenuItem>
              <MenuItem onClick={() => onDeleteData()}>{tComponents('FormView.DeleteAll')}</MenuItem>
            </ActionMenuButton>
          </Box>
        }
      />

      {isLoading && <Loader loadingText={tCommon('loadingContent')} />}
      {!isLoading && !responses.length && (
        <Container fullWidth fullHeight>
          <Typography color='textSecondary'>{tCommon('noContent')}</Typography>
        </Container>
      )}

      {!isLoading && responses.length > 0 && (
        <>
          <Table sx={{ minWidth: 650 }} size='small'>
            <TableHead sx={{ backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY }}>
              <TableRow>
                <TableCell component='th' sx={{ p: 2 }}>
                  <TableSortLabel
                    active
                    direction={order === 'asc' ? 'asc' : 'desc'}
                    onClick={() => {
                      handlePageChange({ ...page, start: 0 });
                      setOrder(order === 'asc' ? 'desc' : 'asc');
                    }}>
                    Dato
                  </TableSortLabel>
                </TableCell>
                <TableCell component='th'>Navn</TableCell>
                <TableCell component='th'>E-post</TableCell>
                <TableCell component='th'>Id-nr</TableCell>
                <TableCell component='th' width='20%' />
              </TableRow>
            </TableHead>
            <TableBody>
              {responses.map((row) => (
                <FormDataTableRow
                  key={row.id}
                  row={row}
                  showDelete={deletingIndividual}
                  onDelete={onDeleteFormResponse}
                  isDeleting={isDeletingRow[row.id!] || false}
                />
              ))}
            </TableBody>
          </Table>
          <Container p={2} fullWidth>
            <PageSizeSelector
              pageSize={page.rows}
              options={[10, 20, 50]}
              onChange={(rows) => handlePageChange({ rows })}
              variant='standard'
            />
            <Paginator
              page={page}
              onChange={(c, start, rows) => handlePageChange({ start, rows })}
              totalItemCount={totalItemCount}
            />
          </Container>
        </>
      )}
    </Box>
  );
};
