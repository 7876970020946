import React, { FC, ReactNode } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SectionType } from '../declarations/models/SectionType';

export interface SectionIconProps {
  sectionType: SectionType;
}

const SECTION_ICONS: { [section in SectionType]: ReactNode | undefined } = {
  [SectionType.ARTICLE_HEAD]: <MaterialSymbol name='title' fill />,
  [SectionType.ARTICLE_BODY]: <MaterialSymbol name='splitscreen' fill sharp />,
  [SectionType.ARTICLE_LINKS]: <MaterialSymbol name='add_link' fill />,
  [SectionType.GRID_ROW]: <MaterialSymbol name='view_comfy' fill sharp />,
  [SectionType.PAGE_GRID]: <MaterialSymbol name='view_module' fill sharp />,
  [SectionType.SITE_HEAD]: undefined,
  [SectionType.SITE_FOOTER]: undefined,
};

export const SectionIcon: FC<SectionIconProps> = ({ sectionType }) => {
  return <>{SECTION_ICONS[sectionType]}</>;
};

export default SectionIcon;
