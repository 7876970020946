import React from 'react';
import { DatelineBlock } from '@/declarations/models/blocks/DatelineBlock';
import { BlockPreviewComponent } from '@/editor/lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { formatAPITimestamp } from '@/utils/dates';
import { useWatch } from 'react-hook-form';
import { Page } from '@/declarations/models/Page';

export const DatelineBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as DatelineBlock;
  const publishtime = useWatch<Page, 'published_at'>({
    name: 'published_at',
  });

  // TODO: Placeholder. align with base2.css.
  return (
    <div className='article__grid'>
      <div className='article__metadata cfz'>
        <div className='module__content'>
          <i className='i-icon i-icon-time' />
          {currentBlock.text && <strong>{currentBlock.text}</strong>}
          {publishtime && (
            <time
              dateTime={publishtime}
              style={{
                marginLeft: '1rem',
                fontSize: '0.8rem',
                color: '#999',
              }}>
              {formatAPITimestamp(publishtime, currentBlock.showTime ? 'datetime' : 'date')}
            </time>
          )}
        </div>
      </div>
    </div>
  );
};
