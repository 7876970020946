/* istanbul ignore file */
export enum CssFilter {
  GRAYSCALE = 'filter-grayscale',
  GRAYSCALE_03 = 'filter-grayscale-03',
  GRAYSCALE_05 = 'filter-grayscale-05',
  GRAYSCALE_08 = 'filter-grayscale-08',
  BRIGHTNESS = 'filter-brightness',
  BRIGHTNESS_04 = 'filter-brightness-04',
  BRIGHTNESS_05 = 'filter-brightness-05',
  BRIGHTNESS_06 = 'filter-brightness-06',
  BRIGHTNESS_07 = 'filter-brightness-07',
  BRIGHTNESS_08 = 'filter-brightness-08',
  CONTRAST = 'filter-contrast',
  CONTRAST_05 = 'filter-contrast-05',
  CONTRAST_08 = 'filter-contrast-08',
  BLUR = 'filter-blur',
  BLUR_02 = 'filter-blur-2',
  BLUR_03 = 'filter-blur-3',
  BLUR_05 = 'filter-blur-5',
  BLUR_10 = 'filter-blur-10',
  BLUR_15 = 'filter-blur-15',
  BLUR_20 = 'filter-blur-20',
  BLUR_30 = 'filter-blur-30',
  BLUR_50 = 'filter-blur-50',
  SEPIA = 'filter-sepia',
  SEPIA_05 = 'filter-sepia-05',
  SEPIA_08 = 'filter-sepia-08',
  ZOOM_ANIMATION = 'animation-zoom',
}
