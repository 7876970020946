import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import ActionMenu from '../../../components/ActionMenu';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { Alignment } from '../../../declarations/models/Alignment';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import {
  ArticleTitleBlock,
  ArticleTitleBlockTextAlignment,
} from '../../../declarations/models/blocks/ArticleTitleBlock';

const ALIGNMENT_OPTIONS: Array<ArticleTitleBlockTextAlignment> = [Alignment.AUTO, Alignment.LEFT, Alignment.CENTER];
const TEXT_ALIGNMENT_ICONS: Record<ArticleTitleBlockTextAlignment, ReactNode> = {
  [Alignment.LEFT]: <MaterialSymbol name='format_align_left' />,
  [Alignment.AUTO]: <MaterialSymbol name='format_align_left' />,
  [Alignment.CENTER]: <MaterialSymbol name='format_align_center' />,
};

export const PageBlockArticleTitleTextAlignSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<ArticleTitleBlock>, `${typeof blockPath}.textAlign`>({
    name: `${blockPath}.textAlign`,
  });

  return (
    <ActionMenu
      id='article-title-block-text-alignment-selector'
      ariaLabel={tAria('components.PageBlockArticleTitleBlockTextAlignSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockArticleTitleBlockTextAlignSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? TEXT_ALIGNMENT_ICONS[value] : TEXT_ALIGNMENT_ICONS.auto}>
      {ALIGNMENT_OPTIONS.map((alignmentOption) => (
        <MenuItem key={alignmentOption} onClick={() => onChange(alignmentOption)} selected={value === alignmentOption}>
          <ListItemIcon>{TEXT_ALIGNMENT_ICONS[alignmentOption]}</ListItemIcon>
          <ListItemText>
            {tComponents(`PageBlockArticleTitleBlockTextAlignSetting.options.${alignmentOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockArticleTitleTextAlignSetting;
