/* istanbul ignore file */

/**
 * All possible actions that chan be performed on a block
 */
export enum BlockAction {
  TOGGLE_BLOCK_VISIBILITY = 'toggle_block_visibility',
  DUPLICATE_BLOCK = 'duplicate_block',
  DELETE_BLOCK = 'delete_block',
  COPY_BLOCK = 'copy_block',
  CUT_BLOCK = 'cut_block',
}
