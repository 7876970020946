import React from 'react';
import { useController } from 'react-hook-form';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { BlockModuleType } from '@/declarations/models/BlockModuleType';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ActionMenu from '@/components/ActionMenu';
import { useTranslation } from 'react-i18next';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BlockModuleState } from '@/declarations/models/BlockModuleState';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';

type BlockWithModuleTypeAndState = BaseBlock & {
  module__type: BlockModuleType;
  state?: BlockModuleState;
};

export const PageBlockCollapsibleStateSetting: SettingsComponent = () => {
  const { blockPath, block } = useCurrentBlock();
  const currentBlock = block as BlockWithModuleTypeAndState;
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');

  const STATE_OPTIONS: BlockModuleState[] = [
    BlockModuleState.EXPANDED,
    BlockModuleState.COLLAPSED,
    BlockModuleState.SEMI,
  ];
  const STATE_ICONS: Record<BlockModuleState, React.ReactNode> = {
    [BlockModuleState.EXPANDED]: <MaterialSymbol name='expand' />,
    [BlockModuleState.COLLAPSED]: <MaterialSymbol name='minimize' />,
    [BlockModuleState.SEMI]: <MaterialSymbol name='gradient' />,
  };

  const {
    field: { value: moduleState, onChange: setModuleState },
  } = useController<BlockSpecificPage<BlockWithModuleTypeAndState>, `${typeof blockPath}.state`>({
    name: `${blockPath}.state`,
  });

  if (currentBlock.module__type !== BlockModuleType.MODULE) {
    return <></>;
  }

  return (
    <ActionMenu
      id='page-block-collapsible-state-setting-selector'
      ariaLabel={tAria('components.PageBlockCollapsibleStateSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockCollapsibleStateSetting.Tooltip')}
      tooltipPlacement='top'
      icon={moduleState ? STATE_ICONS[moduleState] : STATE_ICONS.expanded}>
      {STATE_OPTIONS.map((option) => (
        <MenuItem
          key={option}
          onClick={() => setModuleState(option)}
          selected={(!moduleState && option === BlockModuleState.EXPANDED) || moduleState === option}>
          <ListItemIcon>{STATE_ICONS[option]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockCollapsibleStateSetting.options.${option}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockCollapsibleStateSetting;
