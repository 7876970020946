import React, { ReactNode } from 'react';
import { Box, BoxProps, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Status } from '../declarations/models/Status';
import SearchField from './SearchField';
import { toggleItem } from '../utils/array';

export interface ToolbarProps {
  heading?: ReactNode;
  bottomRight?: ReactNode;
  topRight?: ReactNode;
  query?: string;
  onQueryChange?: (query: string) => void;
  resetSearch?: number;
  selectedStatuses?: Array<Status>;
  onSelectedStatusesChange?: (statuses: Array<Status>) => void;
  boxProps?: BoxProps;
  renderSearchField?: (props: {
    query?: string;
    onQueryChange?: (query: string) => void;
    resetSearch?: number;
  }) => ReactNode;
}

const AVAILABLE_STATUSES: Array<Status> = [Status.PUBLISHED, Status.DRAFT, Status.ARCHIVED];
export const ToolbarHeader = ({
  heading,
  bottomRight,
  topRight,
  query,
  resetSearch = 0,
  onQueryChange,
  selectedStatuses,
  onSelectedStatusesChange,
  boxProps,
  renderSearchField,
}: ToolbarProps) => {
  const { t: tCommon } = useTranslation('common');
  const toggleStatusFilter = (status: Status) => {
    onSelectedStatusesChange?.(toggleItem(selectedStatuses ?? [], status));
  };
  const renderCheckbox = (selected: boolean, status: Status) => (
    <FormControlLabel
      key={status}
      control={
        <Checkbox
          disableRipple
          checked={selected}
          onChange={() => {
            toggleStatusFilter(status);
          }}
        />
      }
      label={tCommon(`Status.${status}`)}
      labelPlacement='end'
    />
  );
  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' gap={2} width='100%' {...boxProps}>
      <Box display='flex' flexDirection='row' alignItems='center' gap={2} justifyContent='space-between'>
        <Box flexGrow={1}>{heading}</Box>
        <Box>{topRight}</Box>
      </Box>

      <Box display='flex' flexDirection='row' alignItems='center' gap={2} justifyContent='space-between'>
        <Box display='flex' gap={1} flexGrow={1}>
          {renderSearchField
            ? renderSearchField({
                query,
                onQueryChange,
                resetSearch,
              })
            : onQueryChange && (
                <SearchField
                  round
                  onQueryChange={onQueryChange}
                  resetSearch={resetSearch}
                  placeholder={tCommon('search')}
                  initialValue={query}
                  autoComplete='off'
                  id='filter-query-input'
                  fullWidth
                  size='small'
                  sx={{
                    maxWidth: query ? '470px' : '350px',
                    transition: 'max-width 300ms ease',
                    '&:focus-within': {
                      maxWidth: '470px',
                    },
                  }}
                />
              )}
          {!!onSelectedStatusesChange && (
            <Box px={2} borderColor={theme.palette.grey.A400}>
              {AVAILABLE_STATUSES.map((status) => {
                return renderCheckbox(selectedStatuses?.includes(status) ?? false, status);
              })}
            </Box>
          )}
        </Box>
        {bottomRight}
      </Box>
    </Box>
  );
};
