/**
 * Type of function that generates a key for the given item
 */
export type KeyFactory<T> = (item: T) => string;

/**
 * A factory function that generates a key-generator that uses closure to cache the generated keys.
 * The reason for this is to avoid duplicate keys by appending "-N"
 * (where "N" is a number that is increased each time the same key is generated) to the generated key.
 * @param makeKey
 */
function makeCachedKeyGenerator<T>(makeKey: KeyFactory<T>) {
  const cache: { [key: string]: number } = {};
  return (item: T): string => {
    const key = makeKey(item);
    if (Object.keys(cache).includes(key)) {
      cache[key]++;
      return `${key}-${cache[key]}`;
    }
    cache[key] = 1;
    return key;
  };
}

/**
 * Creates a function that generates a key for the provided item, and makes sure this is unique.
 * Only needed if duplicate items MAY occur in a list.
 * @param keyFactory
 */
export function useKeyGenerator<T>(keyFactory: KeyFactory<T>): KeyFactory<T> {
  return makeCachedKeyGenerator(keyFactory);
}
