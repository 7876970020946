import React, { FC, MouseEvent, ReactNode, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  PopoverProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from './Container';

export interface DeleteButtonProps
  extends Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>,
    Pick<ButtonProps, 'color'> {
  deleteIcon?: ReactNode;
  iconColor?: 'action' | 'disabled' | 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  deleteLabel?: string | 'delete' | 'remove';
  onConfirm: () => void;
  onCancel?: () => void;
  component?: 'Button' | 'MenuItem' | 'IconButton';
  disabled?: boolean;
  tooltip?: string;
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  deleteIcon,
  deleteLabel = 'delete',
  onConfirm,
  onCancel,
  anchorOrigin,
  transformOrigin,
  component,
  disabled,
  color = 'inherit',
  iconColor = 'error',
  tooltip,
}) => {
  const button = useRef<HTMLElement | null>(null);

  const { t } = useTranslation('components');

  const [open, setOpen] = useState<boolean>(false);

  const openMenu = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const closeMenu = () => setOpen(false);

  const handleConfirm = () => {
    closeMenu();
    onConfirm?.();
  };
  const handleCancel = () => {
    closeMenu();
    onCancel?.();
  };
  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    closeMenu();
    onCancel?.();
  };

  const getDeleteLabel = (label?: string) => {
    if (!label || label === 'delete') return t('DeleteButton.DefaultButtonLabel');
    if (label === 'remove') return t('DeleteButton.AlternativeButtonLabel');
    return label;
  };

  const renderButton = () => {
    const icon = deleteIcon || <MaterialSymbol name='delete' fill color={iconColor} />;
    const label = getDeleteLabel(deleteLabel);
    if (component === 'IconButton') {
      return (
        <IconButton
          ref={(el) => {
            button.current = el;
          }}
          aria-label={label}
          aria-haspopup='true'
          aria-controls='confirm-delete-dialog'
          onClick={openMenu}>
          {icon}
        </IconButton>
      );
    }
    if (component === 'MenuItem') {
      return (
        <MenuItem
          ref={(el) => {
            button.current = el;
          }}
          aria-label={label}
          aria-haspopup='true'
          aria-controls='confirm-delete-dialog'
          onClick={openMenu}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{label}</ListItemText>
        </MenuItem>
      );
    }
    return (
      <Button
        ref={(el) => {
          button.current = el;
        }}
        color={color}
        aria-haspopup='true'
        aria-controls='confirm-delete-dialog'
        onClick={openMenu}
        startIcon={icon}
        disabled={disabled}>
        {label}
      </Button>
    );
  };

  return (
    <>
      {tooltip ? (
        <Tooltip title={tooltip} placement='top'>
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}
      <Popover
        id='confirm-delete-dialog'
        anchorEl={button.current}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}>
        <Paper>
          <Container column p={1}>
            <Typography>{t('DeleteButton.ConfirmDeleteQuestion')}</Typography>
            <Container right>
              <Button variant='outlined' color='secondary' onClick={handleCancel}>
                {t('DeleteButton.AbortOption')}
              </Button>
              <Button variant='contained' color='error' onClick={handleConfirm}>
                {t('DeleteButton.ConfirmOption')}
              </Button>
            </Container>
          </Container>
        </Paper>
      </Popover>
    </>
  );
};

export default DeleteButton;
