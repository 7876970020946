import React, { FC, ReactElement } from 'react';
import { ListItemAvatar } from '@mui/material';
import broken_image from '../assets/images/broken_image.png';
import Image from './Image';
import Styles from '../assets/js/Styles';

export interface ListImageProps {
  src?: string;
  alt?: string;
  heightPx?: number;
  widthPx?: number;
  borderRadius?: string;
  fallbackImage?: ReactElement;
}

export const ListImage: FC<ListImageProps> = ({
  src,
  alt,
  heightPx = 80,
  widthPx = 80,
  borderRadius = 4,
  fallbackImage,
}) => {
  const dimDiff = heightPx - widthPx;

  let imageContent;

  if (src) {
    imageContent = (
      <Image
        src={src}
        alt={alt}
        loading='lazy'
        style={{
          borderRadius: `${borderRadius}px`,
          width: `${widthPx}px`,
          height: `${heightPx}px`,
          objectFit: 'cover',
          overflow: 'hidden',
          margin: '8px',
        }}
      />
    );
  } else if (fallbackImage) {
    imageContent = React.cloneElement(fallbackImage, {
      sx: {
        fontSize: `${widthPx}px`,
        width: `${widthPx}px`,
        height: `${heightPx}px`,
        padding: `${dimDiff > 0 ? dimDiff / 2 + 8 : 8}px ${dimDiff < 0 ? -dimDiff / 2 + 8 : 8}px`,
        margin: '8px',
        color: 'rgba(0, 0, 0, 0.54)',
        borderRadius: `${borderRadius}px`,
      },
    });
  } else {
    imageContent = (
      <img
        src={broken_image}
        style={{
          borderRadius: `${borderRadius}px`,
          width: `${widthPx}px`,
          height: `${heightPx}px`,
          objectFit: 'contain',
          margin: '8px',
        }}
        alt=''
      />
    );
  }

  return <ListItemAvatar sx={{ height: `${heightPx + 16}px` }}>{imageContent}</ListItemAvatar>;
};

export default ListImage;
