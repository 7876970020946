import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import CustomIcon from '../../../components/CustomIcon';
import { DMBlock } from '../../../declarations/models/blocks/DMBlock';
import { ViewWidth } from '../../../declarations/ViewType';

const WIDTH_OPTIONS: Array<ViewWidth> = Object.values(ViewWidth);
const WIDTH_OPTIONS_ICONS: Record<ViewWidth, ReactNode> = {
  [ViewWidth.AUTO]: <CustomIcon name='width_normal' />,
  [ViewWidth.WIDE]: <CustomIcon name='width_wider' />,
  [ViewWidth.FULL_WIDTH]: <CustomIcon name='width_full' />,
};

export const PageBlockWidthSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<DMBlock>, `${typeof blockPath}.width`>({
    name: `${blockPath}.width`,
  });

  return (
    <ActionMenu
      id='width-layout-selector'
      ariaLabel={tAria('components.PageBlockLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockLayoutSetting.Tooltip')}
      icon={value ? WIDTH_OPTIONS_ICONS[value as ViewWidth] : WIDTH_OPTIONS_ICONS.auto}>
      {WIDTH_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{WIDTH_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};
