import React, { FC, Fragment } from 'react';
import {
  ContentFormBlock,
  FileInputFormBlock,
  FormModuleInput,
  FormModuleResponse,
  InputFormBlock,
  NameInputFormBlock,
  OptionsFormBlock,
  UserFormBlock,
  YesNoFormBlock,
} from '@/declarations/models/FormModule';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InputDetails: FC<{ input: InputFormBlock }> = ({ input }) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box sx={{ fontWeight: 'bold' }}>{input.label}</Box>
      {input.value && <Box whiteSpace='pre-line'>{input.value}</Box>}
    </Box>
  );
};

const NameInput: FC<{ input: NameInputFormBlock }> = ({ input }) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box display='flex' gap={2}>
        <Box sx={{ fontWeight: 'bold' }}>{input.firstname?.label}</Box>
        <Box>{input.firstname?.value}</Box>
      </Box>
      <Box display='flex' gap={2}>
        <Box sx={{ fontWeight: 'bold' }}>{input.lastname?.label}</Box>
        <Box>{input.lastname?.value}</Box>
      </Box>
    </Box>
  );
};

const AddressInput: FC<{ input: Pick<InputFormBlock, 'address' | 'postalCode' | 'postalPlace' | 'value'> }> = ({
  input,
}) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box display='flex' gap={2}>
        <Box sx={{ fontWeight: 'bold' }}>{input.address?.label}</Box>
        <Box>{input.value}</Box>
      </Box>
      {input.postalCode?.label && (
        <Box display='flex' gap={2}>
          <Box sx={{ fontWeight: 'bold' }}>{input.postalCode?.label}</Box>
          <Box>{input.postalCode?.value}</Box>
        </Box>
      )}
      {input.postalPlace?.label && (
        <Box display='flex' gap={2}>
          <Box sx={{ fontWeight: 'bold' }}>{input.postalPlace?.label}</Box>
          <Box>{input.postalPlace?.value}</Box>
        </Box>
      )}
    </Box>
  );
};

const OptionsInput: FC<{ input: OptionsFormBlock }> = ({ input }) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box sx={{ fontWeight: 'bold' }}>{input.label}</Box>
      {input.subtype === 'radio' && <Box>{input.value}</Box>}
      {input.subtype === 'checkbox' &&
        input.options?.filter((option) => option.value)?.map((option) => <Box key={option.label}>{option.label}</Box>)}
    </Box>
  );
};

const YesNoInput: FC<{ input: YesNoFormBlock }> = ({ input }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box sx={{ fontWeight: 'bold' }}>{input.label}</Box>
      <Box>{input.value ? tCommon('yes') : tCommon('no')}</Box>
    </Box>
  );
};

const Content: FC<{ input: ContentFormBlock }> = ({ input }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box sx={{ fontWeight: 'bold' }}>{input?.title || ''}</Box>
    </Box>
  );
};

const UserInput: FC<{ input: UserFormBlock }> = ({ input }) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box display='flex' gap={1}>
        <Box sx={{ fontWeight: 'bold' }}>Interntittel</Box>
        <Box>{input?.label || 'Ikke angitt'}</Box>
      </Box>
      <Box display='flex' gap={1}>
        <Box sx={{ fontWeight: 'bold' }}>Fornavn</Box>
        <Box>{input.firstname?.value}</Box>
      </Box>
      <Box display='flex' gap={1}>
        <Box sx={{ fontWeight: 'bold' }}>Etternavn</Box>
        <Box>{input.lastname?.value}</Box>
      </Box>
      <Box display='flex' gap={1}>
        <Box sx={{ fontWeight: 'bold' }}>Epost</Box>
        <Box>{input.email?.value}</Box>
      </Box>
      {input.subtype !== 'minimum' && (
        <Box display='flex' gap={1}>
          <Box sx={{ fontWeight: 'bold' }}>Telefon</Box>
          <Box>{input.phone?.value}</Box>
        </Box>
      )}
      {input.subtype === 'complete' && (
        <>
          <Box display='flex' gap={1}>
            <Box sx={{ fontWeight: 'bold' }}>Adresse</Box>
            <Box>{input.address?.value}</Box>
          </Box>
          <Box display='flex' gap={1}>
            <Box sx={{ fontWeight: 'bold' }}>Postnummer</Box>
            <Box>{input.postalCode?.value}</Box>
          </Box>
          <Box display='flex' gap={1}>
            <Box sx={{ fontWeight: 'bold' }}>Poststed</Box>
            <Box>{input.postalPlace?.value}</Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const FileInput: FC<{ input: FileInputFormBlock }> = ({ input }) => {
  return (
    <Box sx={{ p: 1 }} flexGrow={1}>
      <Box sx={{ fontWeight: 'bold' }}>{input.label}</Box>

      {input.dms_url && input.dms_url.includes('/file/') && (
        <Box>
          <a href={input.dms_url} target='_blank' rel='noreferrer'>
            {input.value}
          </a>
        </Box>
      )}

      {input.dms_url && input.dms_url.includes('/image/') && (
        <Box>
          {input.value}
          <Box marginBottom={1}>
            <a href={`${input.dms_url}?dimension=max&mediaType=image/jpeg`} target='_blank' rel='noreferrer'>
              JPEG
            </a>
            <span> | </span>
            <a href={`${input.dms_url}?dimension=max`} target='_blank' rel='noreferrer'>
              WEBP
            </a>
            <span> | </span>
            <a href={`${input.dms_url}?dimension=original`} target='_blank' rel='noreferrer'>
              ORIGINAL
            </a>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const FormDataResponseDetails: FC<{ response: FormModuleResponse }> = ({ response }) => {
  const flatInputBlocks = response.content?.sections?.flatMap((section) => section?.inputs) || [];

  const renderInputBlock = (inputBlock: FormModuleInput) => {
    switch (inputBlock.type) {
      case 'input':
        switch (inputBlock.subtype) {
          case 'address':
            return <AddressInput input={inputBlock} />;
          case 'mailchimp__subscribe':
            return null; // TODO: 13/10/2023
          case 'name':
            return <NameInput input={inputBlock} />;
          case 'file':
            return <FileInput input={inputBlock} />;
          default:
            return <InputDetails input={inputBlock} />;
        }
      case 'content':
        return <Content input={inputBlock} />;
      case 'options':
        return <OptionsInput input={inputBlock} />;
      case 'checkbox':
        return <YesNoInput input={inputBlock} />;
      case 'user':
        return <UserInput input={inputBlock} />;
      default:
        return null;
    }
  };

  return (
    <Box display='flex' flexWrap='wrap' gap={2} p={3}>
      {flatInputBlocks.map((inputBlock, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <Fragment key={index}>{renderInputBlock(inputBlock)}</Fragment>;
      })}
    </Box>
  );
};
