export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }
  if (error && typeof error === 'object' && 'message' in error) {
    const errorMessage = (error as { message: unknown }).message;
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
  }
  return 'Unknown error';
};
