import React from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { EventsManualBlock } from '../../../../declarations/models/blocks/EventsBlocks';
import EventsBlockPreviewItem from './EventsBlockPreviewItem';

export const EventsManualBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as EventsManualBlock;

  return (
    <div className={`pagelist pagelist--${currentBlock?.view || 'grid'}`}>
      <div className='pagelist__intro'>
        {block?.visibletitle && block?.title && <h2>{block?.title}</h2>}
        {block?.body && <p>{block?.body}</p>}
      </div>
      <div
        className={`row row--cards row--events row--${currentBlock?.view || 'grid'}  gridSize-${
          currentBlock?.gridSize || 'auto'
        } gridMobileBeaviour-${currentBlock?.gridMobileBehaviour || 'auto'}`}>
        <div className='row__grid'>
          <div className='row__content'>
            {currentBlock?.items.map((item) => (
              <EventsBlockPreviewItem key={item.id} item={item} block={currentBlock} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsManualBlockPreview;
