import React, { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SkinSelectorIcon } from '@/editor/lib/components/settings/SkinSelectorIcon';
import { Skin } from '../../../../declarations/models/Skin';
import Container from '../../../../components/Container';
import ActionMenu from '../../../../components/ActionMenu';

export interface SkinSelectorProps {
  availableSkins: Array<Skin>;
  selectedSkin: Skin | null;
  onSkinChange: (skin: Skin | null) => void;
  disabled?: boolean;
  sx?: SxProps;
}

export const SkinSelector: FC<SkinSelectorProps> = ({ availableSkins, selectedSkin, onSkinChange, disabled, sx }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  return (
    <ActionMenu
      id='skin-selector'
      ariaLabel={tAria('components.SkinSelector.SelectSkinTooltip')}
      tooltip={tComponents('SkinSelector.SelectSkinTooltip')}
      icon={<SkinSelectorIcon skin={selectedSkin} disabled={disabled} />}
      tooltipPlacement='top'
      disabled={disabled}
      sx={sx}>
      <MenuItem
        selected={selectedSkin === null}
        disabled={selectedSkin === null}
        onClick={() => onSkinChange(null)}
        divider>
        <ListItemIcon>
          <SkinSelectorIcon skin={null} disabled={disabled} />
        </ListItemIcon>
        <ListItemText>{tCommon('default')}</ListItemText>
      </MenuItem>

      {availableSkins.map((skin) => (
        <MenuItem
          key={skin.class || 'unknown-skin-class'}
          selected={selectedSkin === skin}
          disabled={selectedSkin === skin}
          onClick={() => onSkinChange(skin)}>
          <ListItemIcon>
            <SkinSelectorIcon skin={skin} disabled={disabled} />
          </ListItemIcon>
          <ListItemText>{skin.title}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default SkinSelector;
