import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ButtonBase, FormLabel, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import Container from '../../../../components/Container';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { DMSingleBlockModel } from '../../../../declarations/models/blocks/DMBlock';
import { settingsAreaStyle } from '../EmployeeBlocks/EmployeesAutoBlock';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { DMArtifact, DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import { Api } from '../../../../services/Api';
import Image from '../../../../components/Image';
import SingleDMItem from './SingleDMItem';

export const DMSingleBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  const [dmArtifact, setDmArtifact] = useState<DMArtifact>();

  const {
    field: { value: dmItem, onChange: setDmItem },
  } = useController<BlockSpecificPage<DMSingleBlockModel>, `${typeof blockPath}.dmItem`>({
    name: `${blockPath}.dmItem`,
  });

  const {
    field: { value: selectedImgIndex, onChange: setSelectedImgIndex },
  } = useController<BlockSpecificPage<DMSingleBlockModel>, `${typeof blockPath}.selectedImgIndex`>({
    name: `${blockPath}.selectedImgIndex`,
  });

  useEffect(() => {
    if (dmItem) {
      if (dmItem.uniqueId) {
        const ctx = Api.getDMArtifact(dmItem.uniqueId);

        ctx
          .fetchDirect(null)
          .then((res) => !!res && setDmArtifact(res))
          .finally(ctx.abort);
      }
    } else setDmArtifact(undefined);
    setSelectedImgIndex(0);
  }, [dmItem]);

  const handleSetDmItem = (item: DMMediaModel | null) => {
    if (item) setDmItem({ uniqueId: item['artifact.uniqueId'] });
  };

  return (
    <Container column fullWidth gap={2}>
      <FinderButton
        type={FinderType.DM}
        multiSelect={false}
        finderProps={{
          onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
            !!items.length && handleSetDmItem(items[0].source),
        }}
      />
      {!!dmArtifact && (
        <Container sx={settingsAreaStyle} column>
          <SingleDMItem dmArtifact={dmArtifact} onRemove={() => setDmItem(null)} />
          {dmArtifact.images?.length ? (
            dmArtifact.images.length > 1 && (
              <>
                <FormLabel sx={{ marginRight: 'auto' }}>{t('DMBlock.SelectMainPicture')}:</FormLabel>
                <Container left wrap>
                  {dmArtifact.images.map((image, i) => (
                    <ButtonBase
                      key={image.imageId}
                      onClick={() => setSelectedImgIndex(i)}
                      sx={
                        i === selectedImgIndex
                          ? { border: '4px solid green', borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }
                          : { margin: '4px' }
                      }>
                      <Image src={image.url} maxHeight='180px' maxWidth='180px' />
                    </ButtonBase>
                  ))}
                </Container>
              </>
            )
          ) : (
            <Typography fontStyle='italic'>{t('DMBlock.NoImages')}</Typography>
          )}
        </Container>
      )}
    </Container>
  );
};

export default DMSingleBlock;
