import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { MenuItem, TextField, Typography } from '@mui/material';
import { BannerBlock, BannerTextLineColor } from '../../../../declarations/models/blocks/BannerBlock';
import Container from '../../../../components/Container';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import CheckboxInput from '../../../../components/forms/CheckboxInput';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';

const TEXT_LINE_OPTIONS: Array<BannerTextLineColor> = [
  BannerTextLineColor.LIGHT_SEMI_TRANSPARENT,
  BannerTextLineColor.DARK_SEMI_TRANSPARENT,
];

export const BannerBlockTextEffects: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');
  const {
    field: { value: useTextbackgound },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.useTextbackgound`>({
    name: `${blockPath}.useTextbackgound`,
  });
  const {
    field: { value: textlineColor = BannerTextLineColor.DARK_SEMI_TRANSPARENT, onChange: onChangeTextlineColor },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.textlineColor`>({
    name: `${blockPath}.textlineColor`,
  });

  return (
    <Container fullWidth column sx={{ alignItems: 'baseline !important' }}>
      <Typography>{t('BannerBlock.legacySettings')}</Typography>

      <CheckboxInput
        fullWidth={false}
        path={`${blockPath}.useModuleContentBackgound`}
        label={t('BannerBlock.useModuleContentBackgound')}
      />

      <Container fullWidth column left>
        <CheckboxInput
          fullWidth={false}
          path={`${blockPath}.useTextbackgound`}
          label={t('BannerBlock.useTextbackgound')}
        />
        {useTextbackgound && (
          <TextField
            value={textlineColor}
            onChange={onChangeTextlineColor}
            select
            label={t(`BannerBlock.BannerBlockTextLineColorSelector.label`)}
            sx={{ width: '85%' }}>
            {TEXT_LINE_OPTIONS.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option ? t(`BannerBlock.BannerBlockTextLineColorSelector.${option}`) : ''}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </Container>
    </Container>
  );
};

export default BannerBlockTextEffects;
