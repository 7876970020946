import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Typography } from '@mui/material';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { HeaderMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/HeaderMenuBlock';
import Links from '../../../components/Links/Links';
import { LinkType } from '../../../declarations/models/LinkType';
import LinkListItem from '../../../components/Links/LinkListItem';
import { Draggable } from '../../../declarations/Draggable';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import LanguageMenuMainMenuSubMenuSettings from './LanguageMenuMainMenuSubMenuSettings';

export interface LanguageMenuMainMenuSettingsProps {
  blockPath: BlockPath;
}

export const LanguageMenuMainMenuSettings: FC<LanguageMenuMainMenuSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');

  const {
    fields: items,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.items`>({ name: `${blockPath}.items` });

  return (
    <>
      {items?.map((item, i) => (
        <LinkListItem
          key={`${item.id}`}
          linkType={item.type as LinkType}
          type={Draggable.OTHER}
          index={i}
          onReorder={move}
          onDelete={remove}
          itemsArrayName='items'
          showLinkDescriptionField={false}
          onChangeFile={() => null}
          blockPath={blockPath}
          iconOverride={'status' in item ? <PageStatusCircle status={item.status} size={20} /> : undefined}
          headerSubTitle={
            <Typography fontSize='small'>
              {t('Settings.LanguageSettings.childCount', { count: item.items?.length ?? 0 })}
            </Typography>
          }
          color={Styles.Colors.LIGHT_GREY}>
          <Container fullWidth p={2} column>
            <Container fullWidth>
              <TextInput
                path={`${blockPath}.items.${i}.title`}
                label={t('Settings.LanguageSettings.LanguageMenuSettings.menuPointTitle')}
                defaultValue=''
              />
              <TextInput
                path={`${blockPath}.items.${i}.cssClass`}
                label={t('Settings.LanguageSettings.LanguageMenuSettings.cssClass')}
                defaultValue=''
              />
              {item.type === LinkType.LINK && (
                <TextInput
                  path={`${blockPath}.items.${i}.url`}
                  label={t('Settings.LanguageSettings.LanguageMenuSettings.url')}
                  defaultValue=''
                />
              )}
            </Container>
            <LanguageMenuMainMenuSubMenuSettings itemPath={`${blockPath}.items.${i}`} />
          </Container>
        </LinkListItem>
      ))}
      <Links
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        showLinkItems={false}
        showLinkDescriptionField={false}
        showLinkStyleSelector={false}
        showListChildrenInput={false}
        linkItems={items}
        linkTypes={[LinkType.PAGE, LinkType.LINK]}
        blockPath={blockPath}
      />
    </>
  );
};

export default LanguageMenuMainMenuSettings;
