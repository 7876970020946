import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Container from '../../components/Container';
import SettingsInputContainer from '../../components/SettingsInputContainer';
import Styles from '../../assets/js/Styles';
import TextInput from '../../components/forms/TextInput';
import { Site } from '../../declarations/models/Site';
import { SectionType } from '../../declarations/models/SectionType';
import { BlockType } from '../../declarations/models/BlockType';
import { useControlledFieldArray } from '../../hooks/useControlledFieldArray';
import { Page } from '../../declarations/models/Page';
import { SectionsPath } from '../../editor/PageEditor/CurrentSectionProvider';
import {
  getOrCreateSettingsContentSectionBlockIndex,
  getOrCreateSettingsContentSectionIndex,
} from '../../utils/SettingsContent';

export const LanguageLogoSettings: FC = () => {
  const { t } = useTranslation('components');
  const logoHeader = useWatch<Site, `content.settings.logo_header`>({
    name: `content.settings.logo_header`,
    defaultValue: '',
  });

  const logoFooter = useWatch<Site, `content.settings.logo_footer`>({
    name: `content.settings.logo_footer`,
    defaultValue: '',
  });

  const { fields: sections, append } = useControlledFieldArray<Page, SectionsPath>({ name: 'content.sections' });

  const siteFooterSectionIndex = getOrCreateSettingsContentSectionIndex(SectionType.SITE_FOOTER, append, sections);
  const footerInfoBlockPath = `content.sections.${siteFooterSectionIndex}.blocks.${getOrCreateSettingsContentSectionBlockIndex(
    siteFooterSectionIndex,
    BlockType.FOOTER_INFO,
    sections,
  )}`;

  const logoConsolidatedFooter = useWatch({
    name: `${footerInfoBlockPath}.parentLogo`,
    defaultValue: '',
  });

  return (
    <>
      <SettingsInputContainer title={t('Settings.LanguageSettings.LanguageLogoSettings.logoHeader')}>
        <Container fullWidth gap={2} column>
          <Container fullWidth>
            <Container fullWidth column gap={2}>
              <TextInput
                path='content.settings.logo_header'
                label={t('Settings.LanguageSettings.LanguageLogoSettings.svgCode')}
                multiline
                minRows={7}
                maxRows={7}
              />
            </Container>
            <Container
              fullHeight
              sx={{
                width: '400px',
                maxWidth: '400px',
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
              }}>
              {logoHeader && (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: logoHeader }} />
              )}
            </Container>
          </Container>
          <TextInput
            path='content.settings.htmllogo'
            label={t('Settings.LanguageSettings.LanguageLogoSettings.logoTitle')}
            defaultValue=''
          />
        </Container>
      </SettingsInputContainer>
      <SettingsInputContainer title={t('Settings.LanguageSettings.LanguageLogoSettings.logoFooter')}>
        <Container fullWidth gap={2} column>
          <Container fullWidth>
            <Container fullWidth column gap={2}>
              <TextInput
                path='content.settings.logo_footer'
                label={t('Settings.LanguageSettings.LanguageLogoSettings.svgCode')}
                multiline
                minRows={7}
                maxRows={7}
              />
            </Container>
            <Container
              fullHeight
              sx={{
                width: '400px',
                maxWidth: '400px',
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
              }}>
              {logoFooter && (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: logoFooter }} />
              )}
            </Container>
          </Container>
        </Container>
      </SettingsInputContainer>
      <SettingsInputContainer title={t('Settings.LanguageSettings.LanguageLogoSettings.logoConsolidatedFooter')}>
        <Container fullWidth gap={2} column>
          <Container fullWidth>
            <Container fullWidth column gap={2}>
              <TextInput
                path={`${footerInfoBlockPath}.parentLogo`}
                label={t('Settings.LanguageSettings.LanguageLogoSettings.svgCode')}
                multiline
                minRows={7}
                maxRows={7}
              />
            </Container>
            <Container
              fullHeight
              sx={{
                width: '400px',
                maxWidth: '400px',
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
              }}>
              {logoConsolidatedFooter && (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: logoConsolidatedFooter }} />
              )}
            </Container>
          </Container>
          <TextInput
            path={`${footerInfoBlockPath}.parentUrl`}
            label={t('Settings.LanguageSettings.LanguageLogoSettings.parentUrl')}
            defaultValue=''
          />
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default LanguageLogoSettings;
