import React, { FC } from 'react';
import { Autocomplete, TextField, Chip, MenuItem } from '@mui/material';

interface MuseumSelectProps {
  tComp: (key: string) => string;
  allDimuOwners: any[];
  selectedOwners: any[];
  handleOwnerCodesChanged: (owners: any[]) => void;
  getOptionLabel: (option: any) => string;
  autocompleteItemStyle: (selected: boolean) => any;
}

const MuseumSelect: FC<MuseumSelectProps> = ({
  tComp,
  allDimuOwners,
  selectedOwners,
  handleOwnerCodesChanged,
  getOptionLabel,
  autocompleteItemStyle,
}) => {
  const defaultAutocompleteItemStyle = (selected: boolean) => ({
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
  });

  return (
    <Autocomplete
      multiple
      options={allDimuOwners}
      getOptionLabel={getOptionLabel}
      value={selectedOwners}
      size='small'
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          sx={autocompleteItemStyle ? autocompleteItemStyle(selected) : defaultAutocompleteItemStyle(selected)}>
          {getOptionLabel(option)}
        </MenuItem>
      )}
      fullWidth
      disableCloseOnSelect
      isOptionEqualToValue={(o, v) => o.identifier === v.identifier}
      renderInput={(params) => <TextField {...params} label={tComp('DMBlock.OwnerCodesLabel')} size='small' />}
      onChange={(e, v) => handleOwnerCodesChanged(v)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size='small' {...getTagProps({ index })} key={option.identifier} label={option.identifier} />
        ))
      }
    />
  );
};

export default MuseumSelect;
