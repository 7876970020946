import { Box, Button, SxProps, Typography } from '@mui/material';
import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { mergeSx } from '@/utils/mergeSx';
import Container from '@/components/Container';
import { WelcomeBannerWidget } from '@/views/DashboardView/widgets/WelcomeBannerWidget';
import { ContentWidget } from '@/views/DashboardView/widgets/ContentWidget';
import { MessagesWidget } from '@/views/DashboardView/widgets/MessagesWidget';
import { MediaWidget } from '@/views/DashboardView/widgets/MediaWidget';
import { FormsWidget } from '@/views/DashboardView/widgets/FormsWidget';
import { PermissionsWidget } from '@/views/DashboardView/widgets/PermissionsWidget';
import { SettingsShortcutWidget } from '@/views/DashboardView/widgets/SettingsShortcutWidget';
import { StylePreviewWidget } from '@/views/DashboardView/widgets/StylePreviewWidget';
import Styles from '@/assets/js/Styles';
import { useStore } from '@/components/store/Store';
import { useTranslation } from 'react-i18next';
import { Api } from '@/services/Api';
import { Domain } from '@/declarations/models/Domain';
import DashboardBannerBackgroundImage from '@/assets/images/vidar-nordli-mathisen-nsskWw0227Y-unsplash.webp?url';
import { MaterialSymbol } from '@/components/MaterialSymbol';

const DashboardGrid: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'auto repeat(auto-fill, 150px)',
        gridGap: '16px',
        width: '100%',
      }}>
      {children}
    </Box>
  );
};

const GridItem: FC<
  PropsWithChildren<{
    sx?: SxProps;
  }>
> = ({ children, sx }) => {
  return (
    <Box
      sx={mergeSx(
        {
          height: '100%',
          // border: `1px solid red`,
        },
        sx ?? {},
      )}>
      {children}
    </Box>
  );
};

export const DashboardView = () => {
  const {
    state: { selectedSite, selectedSiteLanguage, currentUser },
  } = useStore();
  const { t } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [domain, setDomain] = useState<Domain | null>(null);
  const [frontpageUrl, setFrontpageUrl] = useState<URL | null>(null);

  const currentUserRole = currentUser?.current_site_role;
  const isSuperAdmin = currentUser?.user?.is_super_admin;

  useEffect(() => {
    if (selectedSite) {
      Api.getDomainForSite(selectedSite.id!).fetchDirect(null).then(setDomain);
    }
  }, [selectedSite]);

  useEffect(() => {
    if (domain) {
      const protocol = window.location.protocol || 'https:';
      const host = domain.name || window.location.hostname;
      const path = domain.path || '';
      const url = new URL(`${protocol}//${host}/${path}/${selectedSiteLanguage || ''}`);
      setFrontpageUrl(url);
    }
  }, [domain, selectedSiteLanguage]);

  return (
    <>
      <Container
        p={6}
        pt={2}
        fullWidth
        column
        gap={0}
        top
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
        }}>
        {selectedSite && selectedSiteLanguage && (
          <Container spaceBetween fullWidth my={2} bottom>
            <Typography
              sx={{
                fontSize: '2.5rem',
                fontWeight: 400,
              }}>
              {selectedSite.name} ({t(`Languages.${selectedSiteLanguage}`)})
            </Typography>
            <a href={frontpageUrl?.href} target='_blank' rel='noreferrer'>
              <Typography variant='body2'>{frontpageUrl?.toString()}</Typography>
            </a>
          </Container>
        )}
        <DashboardGrid>
          <GridItem
            sx={{
              gridColumn: '1/3',
            }}>
            <WelcomeBannerWidget />
          </GridItem>

          <GridItem
            sx={{
              gridRow: 'span 2',
            }}>
            <MessagesWidget />
          </GridItem>

          <GridItem>
            <ContentWidget />
          </GridItem>
          <GridItem>
            <ContentWidget is_event />
          </GridItem>
          <GridItem>
            <MediaWidget />
          </GridItem>
          <GridItem>
            <FormsWidget />
          </GridItem>
          <GridItem>
            <PermissionsWidget />
          </GridItem>
          <GridItem>{(currentUserRole === 'admin' || isSuperAdmin) && <SettingsShortcutWidget />}</GridItem>
          <GridItem
            sx={{
              gridColumn: 'span 2',
            }}>
            <StylePreviewWidget />
          </GridItem>
        </DashboardGrid>
      </Container>
      <Container
        p={5}
        mt={6}
        sx={{
          backgroundColor: Styles.Colors.LIGHT_GREY,
        }}>
        <Box
          sx={{
            minWidth: '700px',
            display: 'flex',
            height: '100%',
            justifyContent: 'space-between',
            borderRadius: `${Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT}px`,
            backgroundColor: Styles.Colors.WHITE,
            border: `1px solid ${Styles.Colors.LIGHT_GREY}`,
            alignItems: 'stretch',
          }}>
          <Container
            sx={{
              flexBasis: '40%',
              height: 'auto',
              backgroundImage: `url(${DashboardBannerBackgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Container
            column
            top
            left
            fullWidth
            gap={2}
            p={4}
            my={4}
            sx={{
              flexBasis: '60%',
            }}>
            <Typography variant='h4'>{tComponents('DashboardView.BottomBanner.Heading')}</Typography>
            <Typography>{tComponents('DashboardView.BottomBanner.Text')}</Typography>
            <Container wrap gap={2} fullWidth left>
              <Button
                endIcon={
                  <MaterialSymbol
                    name='open_in_new'
                    sx={{
                      fontSize: '15px!important',
                    }}
                  />
                }
                variant='contained'
                color='secondary'
                target='_blank'
                href='https://manual.museum24.no'>
                {tComponents('DashboardView.BottomBanner.UserManual')}
              </Button>
            </Container>
          </Container>
        </Box>
      </Container>
    </>
  );
};
