import { PaginationPage } from '../declarations/PaginationPage';

export default function calculatePaginationInfo(
  page: PaginationPage,
  totalItemCount: number,
): {
  start: number;
  rows: number;
  pageNumber: number;
  pageSize: number;
  totalPageCount: number;
  totalItemCount: number;
} {
  let start: number;
  let pageSize: number;
  let pageNumber: number;

  if ('pageNumber' in page && 'pageSize' in page) {
    pageNumber = page.pageNumber;
    pageSize = page.pageSize || 1;
    start = (pageNumber - 1) * pageSize;
  } else {
    start = page.start;
    pageSize = page.rows || 1;
    pageNumber = Math.floor(start / pageSize) + 1;
  }

  const totalPageCount = Math.ceil(totalItemCount / pageSize);

  return {
    start,
    rows: pageSize,
    pageNumber,
    pageSize,
    totalPageCount,
    totalItemCount,
  };
}
