import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsInputContainer from '../../../components/SettingsInputContainer';
import TextInput from '../../../components/forms/TextInput';
import Container from '../../../components/Container';

export const WebpageTrackingSettings: FC = () => {
  const { t } = useTranslation('components');

  return (
    <>
      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageTrackingSettings.google`)}>
        <Container fullWidth spaceBetween gap={2}>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.googleGtagKey`)}
            </Typography>
            <TextInput path='content.settings.googleGtagKey' defaultValue='' label='' placeholder='G-XXXXXX' />
          </Container>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.googleTagmanagerKey`)}
            </Typography>
            <TextInput path='content.settings.googleTagmanagerKey' defaultValue='' label='' placeholder='Key' />
          </Container>
        </Container>

        <Container fullWidth spaceBetween gap={2}>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.googleSiteVerification`)}
            </Typography>
            <TextInput path='content.settings.googleSiteVerification' defaultValue='' label='' placeholder='UID' />
          </Container>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.googleMapsKey`)}
            </Typography>
            <TextInput
              path='content.settings.googleMapsKey'
              defaultValue=''
              label=''
              placeholder={t(`Settings.WebpageSettings.WebpageTrackingSettings.googleMapsKeyPlaceholder`)}
            />
          </Container>
        </Container>
      </SettingsInputContainer>

      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageTrackingSettings.facebook`)}>
        <Container fullWidth spaceBetween gap={2}>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookPixcelCode`)}
            </Typography>
            <TextInput
              path='content.settings.facebookPixcelCode'
              defaultValue=''
              label=''
              placeholder={t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookPixcelCodePlaceholder`)}
            />
            <Typography variant='caption'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookPixcelCodeCaption`)}
            </Typography>
          </Container>
          <Container column left fullWidth>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookDomainVerification`)}
            </Typography>
            <TextInput
              path='content.settings.facebookDomainVerification'
              defaultValue=''
              label=''
              placeholder={t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookDomainVerificationPlaceholder`)}
            />
            <Typography variant='caption'>
              {t(`Settings.WebpageSettings.WebpageTrackingSettings.facebookDomainVerificationCaption`)}
            </Typography>
          </Container>
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default WebpageTrackingSettings;
