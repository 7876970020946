import React, { FC } from 'react';
import { Icon, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import HeaderTitle from '../MediaBlock/MediaBlockHeader/HeaderTitle';
import { mergeSx } from '../../../../utils/mergeSx';

export interface PageListManualBlockItemHeaderProps {
  title?: string;
  siteName?: string;
  secondaryTitle?: string;
  open: boolean;
  overrideExpandIcon?: React.ReactNode;
}

export const PageListManualBlockItemHeader: FC<PageListManualBlockItemHeaderProps> = ({
  title,
  secondaryTitle,
  siteName,
  open,
  overrideExpandIcon,
}) => {
  return (
    <Container fullWidth spaceBetween>
      <Container left fullWidth>
        {overrideExpandIcon ?? (
          <Container sx={{ marginLeft: '10px', marginRight: '10px', marginTop: '-5px' }}>
            <Icon>
              <MaterialSymbol
                name='chevron_right'
                sx={mergeSx({ color: 'rgba(0, 0, 0, 0.54)' }, open ? { transform: 'rotate(90deg)' } : {})}
              />
            </Icon>
          </Container>
        )}
        <Container column left fullWidth>
          <HeaderTitle
            title={
              <span>
                {title}
                {siteName && (
                  <Typography
                    sx={{
                      display: 'inline-block',
                      ml: 2,
                    }}>
                    ({siteName})
                  </Typography>
                )}
                {secondaryTitle && (
                  <Typography
                    sx={{
                      display: 'inline-block',
                      fontStyle: 'italic',
                      ml: 2,
                      '&:before,&:after': {
                        content: '" – "',
                      },
                    }}>
                    {secondaryTitle}
                  </Typography>
                )}
              </span>
            }
          />
        </Container>
      </Container>
    </Container>
  );
};

export default PageListManualBlockItemHeader;
