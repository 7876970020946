import React, { FC } from 'react';
import { Autocomplete, TextField, Chip, MenuItem } from '@mui/material';

interface GradeSelectProps {
  tComp: (key: string) => string;
  allDMSchoolGrades: string[];
  selectedDMSchoolGrades: string[];
  autocompleteItemStyle: (selected: boolean) => any;
  handleDMSchoolGradesChanged: (grades: string[]) => void;
}

const GradeSelect: FC<GradeSelectProps> = ({
  tComp,
  allDMSchoolGrades,
  selectedDMSchoolGrades,
  autocompleteItemStyle,
  handleDMSchoolGradesChanged,
}) => {
  return (
    <Autocomplete
      multiple
      options={allDMSchoolGrades}
      getOptionLabel={(option) => tComp(`DMBlock.School.Grades.Options.${option}`)}
      value={selectedDMSchoolGrades}
      onChange={(e, v) => handleDMSchoolGradesChanged(v)}
      renderInput={(params) => (
        <TextField {...params} label={tComp('DMBlock.School.Grades.SelectGrade')} size='small' />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size='small'
            {...getTagProps({ index })}
            key={option}
            label={tComp(`DMBlock.School.Grades.Options.${option}`)}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} sx={autocompleteItemStyle(selected)}>
          {tComp(`DMBlock.School.Grades.Options.${option}`)}
        </MenuItem>
      )}
      fullWidth
      disableCloseOnSelect
    />
  );
};

export default GradeSelect;
