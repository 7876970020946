import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { MediaViewType } from '../../../declarations/models/blocks/MediaViewType';
import { AttachmentsBlock } from '../../../declarations/models/blocks/AttachmentsBlock';

// FIXME: first draft placeholder preview
export const AttachmentsBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as AttachmentsBlock;

  const getViewClass = () => {
    switch (currentBlock?.view) {
      case MediaViewType.LIST:
        return 'row--list';
      default:
        return '';
    }
  };

  const getViewWidth = () => {
    if (currentBlock?.view?.includes('wide')) {
      return 'wide';
    }
    return 'auto';
  };

  return (
    <>
      <div className='article__grid'>
        <section className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          <p>{currentBlock?.body}</p>
        </section>
      </div>

      {currentBlock?.listSearch && (
        <div className='article__grid'>
          <div className='form'>
            <label htmlFor='search_attachments' className='form__label'>
              {currentBlock?.listSearchText || 'Søk i listen'}
            </label>
            <div className='form__input input--icon'>
              <input id='search_attachments' type='search' className='search_attachments' />
              <i className='input__icon i-nav-search' />
            </div>
          </div>
        </div>
      )}

      <div className={`row row--attachments row--list width--${getViewWidth()} ${getViewClass()}`}>
        <div className='row__grid'>
          <div className='row__content'>
            {currentBlock?.items?.slice(0, currentBlock?.rowMax || 100).map((item, index) => (
              // eslint-disable-next-line
              <div className='module module--attachment' key={`${item.mimetype}-${index}`}>
                <div className='module__grid'>
                  <div className='module__media'>
                    {item?.type === 'image' ? <img alt='' className='attachment__image' src={item?.url} /> : null}

                    {item?.content?.poster?.url ? (
                      <img alt='' className='attachment__image' src={item?.content?.poster?.url} />
                    ) : null}
                  </div>
                  <div className='module__content'>
                    <div className='module__head'>{item.local?.title || item.title || item.filename}</div>
                    <div className='module__body'>{item.local?.caption || item?.content?.caption}</div>
                    <div className='module__foot'>
                      <div className='button'>Vis i nettleser..</div>
                      <div className='button'>Last ned..</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {currentBlock?.rowMax && currentBlock?.rowMax < currentBlock?.items?.length && (
            <div className='row__footer'>
              <div className='article__grid'>
                <div className='button button--primary'>Vis alle ({currentBlock?.items?.length})</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AttachmentsBlockPreview;
