import React, { PropsWithChildren, ReactElement, ReactNode, useState } from 'react';
import { ButtonBase, Collapse, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '../assets/js/Styles';
import DragAndDropReorderingItemContainer, {
  DragAndDropReorderingItemContainerProps,
} from './DragAndDropReorderingItemContainer';
import Container from './Container';
import DragAndDropIcon from './DragAndDropIcon';
import { EditorEvent, EditorEventType, EventManagerApi } from '../editor/lib/eventManager/EditorEventManager';
import { Draggable } from '../declarations/Draggable';
import ActionDropzone from './Section/ActionDropzone';
import DropIndicatorArrow from './DropIndicatorArrow';

export interface DraggableCollapseContainerProps<T>
  extends Pick<
    DragAndDropReorderingItemContainerProps<T>,
    'onReorder' | 'onRemove' | 'onAdd' | 'disabled' | 'index' | 'value' | 'type'
  > {
  color?: string;
  headerContent: (open: boolean) => ReactNode;
  secondaryAction?: ReactElement;
  unmountOnExit?: boolean;
  type: Draggable;
  useEventHandler?: (handler: (event: EditorEvent) => Promise<void> | void) => void;
  useEventManager?: EventManagerApi;
  renderDropIndicator?: (visible: boolean) => ReactElement;
  renderPlaceholder?: () => ReactElement;
  imageThumbnail?: ReactElement;
  initiallyOpen?: boolean;
}

export const DraggableCollapseContainer = <T,>({
  children,
  color = 'white',
  headerContent,
  secondaryAction,
  unmountOnExit = false,
  type = Draggable.OTHER,
  useEventHandler,
  useEventManager,
  renderPlaceholder,
  renderDropIndicator,
  imageThumbnail,
  initiallyOpen = false,
  ...dndProps
}: PropsWithChildren<DraggableCollapseContainerProps<T>>) => {
  const [open, setOpen] = useState<boolean>(initiallyOpen);

  const { t } = useTranslation('common');

  const renderDefaultPlaceholder = () => (
    <Container spaceBetween fullWidth>
      <ActionDropzone
        type={type}
        label={t('Draggable.DropHereToAbort')}
        sx={{
          backgroundColor: 'transparent',
          color: 'inherit',
        }}
        fullWidth
      />
    </Container>
  );

  const renderDefaultDropIndicator = (visible: boolean) => {
    return (
      <DropIndicatorArrow
        visible={visible}
        spacing={0.5}
        label={t('Draggable.MoveHere')}
        borderStyle='dashed'
        arrowStyle='dashed'
        color={Styles.Colors.DROP_INDICATOR_COLOR}
      />
    );
  };

  useEventHandler?.(async (event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.type === EditorEventType.DRAGGING && event.payload.type === type) {
      setOpen(false);
    }
  });

  return (
    <DragAndDropReorderingItemContainer
      useEventManager={useEventManager}
      {...dndProps}
      type={type}
      fullWidth
      gap={0}
      renderPlaceholder={renderPlaceholder || renderDefaultPlaceholder}
      renderDropIndicator={renderDropIndicator || renderDefaultDropIndicator}>
      {(dragHandleRef, dragging) => (
        <Container
          sx={{
            backgroundColor: color,
            border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            position: 'relative',
            overflow: 'hidden',
            p: 0,
            m: 0,
          }}
          gap={0}
          column
          top
          left
          fullWidth>
          <Container spaceBetween fullWidth gap={0}>
            {imageThumbnail && (
              <Container
                sx={{
                  backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
                  width: 'auto',
                  height: '50px',
                  aspectRatio: '1/1',
                  minHeight: '50px',
                }}
                left
                onClick={() => setOpen((o) => !o)}
                top>
                {imageThumbnail}
              </Container>
            )}
            <Container
              component={ButtonBase}
              sx={{ minHeight: '50px' }}
              onClick={() => setOpen((o) => !o)}
              left
              fullWidth>
              {headerContent(open)}
            </Container>

            <Container sx={{ alignSelf: 'stretch' }} right>
              {secondaryAction}
              {!dndProps.disabled && <DragAndDropIcon ref={dragHandleRef} isDragging={dragging} px={1} fullHeight />}
            </Container>
          </Container>

          <Collapse in={open} timeout='auto' sx={{ width: '100%' }} unmountOnExit={unmountOnExit}>
            <Fade in={open}>
              <Container fullWidth>{children}</Container>
            </Fade>
          </Collapse>
        </Container>
      )}
    </DragAndDropReorderingItemContainer>
  );
};

export default DraggableCollapseContainer;
