import React, { FC, useEffect, useState } from 'react';
import { Location } from '@/declarations/models/Location';
import { Api } from '@/services/Api';
import { Autocomplete, SxProps, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface LocationSelectorProps {
  value: number[];
  onChange: (value: number[]) => void;
  siteId?: number;
  sx?: SxProps;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
}

export const LocationSelector: FC<LocationSelectorProps> = ({
  value,
  onChange,
  siteId,
  sx,
  fullWidth = true,
  size = 'medium',
}) => {
  const { t: tComponent } = useTranslation('components');
  const [locations, setLocations] = useState<Array<Location>>([]);

  useEffect(() => {
    if (siteId) {
      const ctx = Api.getSiteLocations(siteId);
      ctx
        .fetchDirect([] as Location[])
        .then((l) => !!l && setLocations(l))
        .finally(ctx.abort);
    }
  }, [siteId]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      limitTags={1}
      renderInput={(params) => <TextField {...params} label={tComponent('LocationSelector.InputLabel')} size={size} />}
      getOptionLabel={(option) => option.title || 'Uten tittel'}
      renderOption={(props, option) =>
        value.includes(option.id!) ? null : (
          <li {...props} key={`location-option-${option.id}`}>
            {option.title}
          </li>
        )
      }
      options={locations}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      multiple
      value={locations.filter((l) => value.includes(l.id!))}
      onChange={(_, v) => onChange(v.map((l) => l.id!))}
      // hack. see index.css
      noOptionsText={<span className='hide-my-parent' />}
      sx={sx}
    />
  );
};
