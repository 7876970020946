import React, { FC } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import KPLogo from '../../icons/KPLogo';

export interface KPObjectFinderHeaderProps {
  onQueryChanged: (query: string) => void;
  schemaIds?: Array<number>;
  referencedFromId?: number;
  filterByOwner?: boolean;
  setFilterByOwner?: (filterByOwner: boolean) => void;
  kpOwnerId?: number;
}

export const KPObjectFinderHeader: FC<KPObjectFinderHeaderProps> = ({
  onQueryChanged,
  schemaIds,
  referencedFromId,
  filterByOwner,
  setFilterByOwner,
  kpOwnerId,
}) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  return (
    <FinderHeader
      onQueryChange={!referencedFromId ? onQueryChanged : undefined}
      title={
        <>
          <KPLogo height='40px' width='36px' />
          <Typography variant='h2' fontSize='28px' lineHeight='30px' fontWeight='700' letterSpacing='1px' pl={2}>
            KulturPunkt
          </Typography>
        </>
      }
      topRight={
        <Box>
          <RadioGroup
            aria-labelledby='select-kp-object-type-label'
            sx={{ display: 'flex', flexFlow: 'row wrap' }}
            value={filterByOwner ? '1' : '0'}
            onChange={(e, val) => setFilterByOwner && setFilterByOwner(val === '1')}>
            <FormControlLabel
              value='1'
              control={<Radio disableRipple />}
              disabled={!kpOwnerId}
              label={
                <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
                  {t('KPFinderHeader.FilterByOwner')}
                </Typography>
              }
              labelPlacement='end'
            />
            <FormControlLabel
              value='0'
              control={<Radio disableRipple />}
              label={
                <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
                  {tCommon('all')}
                </Typography>
              }
              labelPlacement='end'
            />
          </RadioGroup>
        </Box>
      }
      bottomRight={
        <FormControlLabel
          control={<Radio disableRipple checked />}
          label={
            <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
              {schemaIds?.length === 1 ? t(`KPFinderHeader.KPSchemaType.${schemaIds[0]}`) : 'KulturPunkt'}
            </Typography>
          }
          labelPlacement='end'
        />
      }
    />
  );
};

export default KPObjectFinderHeader;
