import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PaginationPage } from '../../declarations/PaginationPage';
import calculatePaginationInfo from '../../utils/calculatePaginationInfo';

export interface CurrentPageInfoProps {
  page: PaginationPage;
  totalItemCount: number;
}

export const CurrentPageInfo: FC<CurrentPageInfoProps> = ({ page, totalItemCount }) => {
  const { t } = useTranslation('components');
  const { start, pageSize, totalItemCount: count } = calculatePaginationInfo(page, totalItemCount);
  const endOfPage = Math.min(count, start + pageSize);
  return (
    <Typography variant='caption' sx={{ px: 2 }}>
      {t('CurrentPageInfo.ShowingXToYOutOfZItems', { from: !count ? 0 : start + 1, to: endOfPage, count })}
    </Typography>
  );
};

export default CurrentPageInfo;
