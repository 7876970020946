import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { GenericMedia } from '../../../declarations/GenericMedia';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';
import Container from '../../Container';
import { Card } from '../../../declarations/models/Card';
import { Person } from '../../../declarations/models/Person';

export interface SelectedCardItemProps {
  item: GenericMedia<Card | Person>;
  onRemoveItem: (item: GenericMedia<Card | Person>) => void;
}

export const SelectedCardItem: FC<SelectedCardItemProps> = ({ item, onRemoveItem }) => {
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column spaceBetween left fullWidth fullHeight>
        <Typography sx={{ wordBreak: 'break-word' }} fontWeight='bold'>
          {item.title}
        </Typography>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedCardItem;
