import React, { FC, useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { Button, Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import SiteSharingBlockSettings from '@/editor/PageEditor/EditorBlock/SiteSharingBlockSettings';
import Container from '../../../../components/Container';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { useApi } from '../../../../hooks/useApi';
import { Api } from '../../../../services/Api';
import { useStore } from '../../../../components/store/Store';
import SelectInput from '../../../../components/forms/SelectInput';
import { TagType } from '../../../../declarations/models/TagType';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { CardModuleBlock } from '../../../../declarations/models/blocks/CardModuleBlock';
import TextInput from '../../../../components/forms/TextInput';
import Styles from '../../../../assets/js/Styles';

export const CardModuleBlockCardSearch: FC = () => {
  const { state } = useStore();
  const { selectedSite } = state;
  const { blockPath, block } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const currentBlock = block as CardModuleBlock;

  const [open, setOpen] = useState<boolean>(false);
  const [allTags, isLoadingTags] = useApi(() => Api.getAllTagsForSite(selectedSite?.id || 0, {}), []);
  const categories = allTags.filter((tag) => tag.content?.isCardCategory === true && tag.type === TagType.CATEGORY);

  const currentSelectedFilterCategories = useWatch<BlockSpecificPage<CardModuleBlock>, `${BlockPath}.filterCategories`>(
    {
      name: `${blockPath}.filterCategories`,
    },
  );

  const {
    field: { value: filterCategories, onChange: onChangeSelectedCategories },
  } = useController<BlockSpecificPage<CardModuleBlock>, `${typeof blockPath}.categories`>({
    name: `${blockPath}.categories`,
  });

  const {
    field: { value: selectedSiteIdsCSV, onChange: onChangeSelectedSiteIdsCSV },
  } = useController<BlockSpecificPage<CardModuleBlock>, `${typeof blockPath}.site_ids`>({
    name: `${blockPath}.site_ids`,
  });

  useEffect(() => {
    onChangeSelectedCategories(
      currentSelectedFilterCategories ? currentSelectedFilterCategories.map((category) => category.tag).join(',') : '',
    );
  }, [currentSelectedFilterCategories, onChangeSelectedCategories]);

  type OrderByType = 'date' | 'title' | 'event';
  type SortDirection = 'asc' | 'desc';
  const ORDER_BY_OPTIONS: Array<OrderByType> = ['date', 'title', 'event'];
  const SORT_DIRECTION_OPTIONS: Array<SortDirection> = ['desc', 'asc'];

  return (
    <Container left column fullWidth p={2} sx={{ backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR }}>
      <Container mt={2} mb={4} gap={2} column top left fullWidth>
        <SelectInput
          label={tComponents('CardModuleBlockCardSearch.FilterCategories')}
          getOptionKey={(option) => option?.tag}
          getOptionLabel={(option) => option?.tag}
          path={`${blockPath}.filterCategories`}
          options={categories || []}
          isLoading={isLoadingTags}
          multiSelect
          initialMultiSelectValue={filterCategories?.split(',')}
        />

        {(currentBlock.searchFromPage?.id || currentBlock.tags) && (
          <Container
            column
            fullWidth
            p={2}
            mt={1}
            left
            sx={{
              backgroundColor: Styles.Colors.LIGHT_BLUE,
              borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
              border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
              fontStyle: 'italic',
            }}>
            <strong>{tComponents('CardModuleBlock.AutoOldSettings')}</strong>
            <ul>
              {currentBlock.searchFromPage?.title && (
                <li>
                  searchFromPage: {currentBlock.searchFromPage?.title} ({currentBlock.searchFromPage?.id})
                </li>
              )}
              {currentBlock.tags && <li>tags: {currentBlock.tags}</li>}
            </ul>
          </Container>
        )}

        <SiteSharingBlockSettings siteIds={selectedSiteIdsCSV} setSiteIds={onChangeSelectedSiteIdsCSV} />
        <Container fullWidth>
          <TextInput<BlockSpecificPage<CardModuleBlock>>
            path={`${blockPath}.rows`}
            label={tComponents('CardModuleBlockCardSearch.Rows')}
            type='number'
          />
          <SelectInput
            options={ORDER_BY_OPTIONS}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => tComponents(`CardModuleBlockCardSearch.OrderBySelector.${option}`)}
            path={`${blockPath}.orderBy`}
            label={tComponents('CardModuleBlockCardSearch.OrderBy')}
            defaultValue='date'
          />
          <SelectInput
            options={SORT_DIRECTION_OPTIONS}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => tComponents(`CardModuleBlockCardSearch.OrderSelector.${option}`)}
            path={`${blockPath}.order`}
            label={tComponents('CardModuleBlockCardSearch.Order')}
            defaultValue='desc'
          />
        </Container>
      </Container>
    </Container>
  );
};

export default CardModuleBlockCardSearch;
