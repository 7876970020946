import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { FooterAddressBlock } from '../../../declarations/models/blocks/settingsBlocks/FooterAddressBlock';
import DraggableCollapseContainer from '../../../components/DraggableCollapseContainer';
import { Draggable } from '../../../declarations/Draggable';
import DeleteButton from '../../../components/DeleteButton';
import SwitchInput from '../../../components/forms/SwitchInput';

export interface LanguageFooterAddressSettingsProps {
  blockPath: BlockPath;
}

export const LanguageFooterAddressSettings: FC<LanguageFooterAddressSettingsProps> = ({ blockPath }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const {
    fields: addresses,
    append,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<FooterAddressBlock>, `${BlockPath}.addresses`>({
    name: `${blockPath}.addresses`,
  });

  const renderAddressInputFields = (path: string) => (
    <Container fullWidth column top left>
      <TextInput
        path={`${path}.text`}
        label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.text')}
        defaultValue=''
        multiline
      />

      <TextInput
        path={`${path}.name`}
        label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.name')}
        defaultValue=''
      />

      <Container fullWidth spaceBetween>
        <TextInput
          path={`${path}.addressLabel`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.addressLabel')}
          defaultValue=''
        />
        <TextInput
          path={`${path}.address`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.address')}
          defaultValue=''
        />
      </Container>
      <Container fullWidth spaceBetween>
        <TextInput
          path={`${path}.postalCode`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.postalCode')}
          defaultValue=''
        />
        <TextInput
          path={`${path}.postalPlace`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.postalPlace')}
          defaultValue=''
        />
      </Container>
      <Container fullWidth spaceBetween>
        <TextInput
          path={`${path}.emailLabel`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.emailLabel')}
          defaultValue=''
        />
        <TextInput
          path={`${path}.email`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.email')}
          defaultValue=''
        />
      </Container>
      <Container fullWidth spaceBetween>
        <TextInput
          path={`${path}.phoneLabel`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.phoneLabel')}
          defaultValue=''
        />
        <TextInput
          path={`${path}.phone`}
          label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.phone')}
          defaultValue=''
        />
      </Container>
    </Container>
  );

  return (
    <Container fullWidth column top left gap={2}>
      <Typography>{tComponents('Settings.LanguageSettings.LanguageFooterSettings.infoText')}</Typography>
      {renderAddressInputFields(blockPath)}

      <Typography fontWeight={600}>
        {tComponents('Settings.LanguageSettings.LanguageFooterSettings.addressesTitle')}:
      </Typography>
      {!addresses?.length && <Typography variant='caption'>{tCommon('none')}</Typography>}
      {addresses?.map((address, index) => (
        <DraggableCollapseContainer
          key={address.id}
          onReorder={move}
          onRemove={remove}
          index={index}
          type={Draggable.ITEM}
          secondaryAction={
            <DeleteButton
              onConfirm={() => remove(index)}
              deleteLabel='remove'
              deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
            />
          }
          headerContent={
            // eslint-disable-next-line react/no-unstable-nested-components
            () => (
              <Typography sx={{ pl: 2 }}>
                {address.name || tComponents('Settings.LanguageSettings.LanguageFooterSettings.addressTitle')}
              </Typography>
            )
          }>
          <Container p={2} left fullWidth>
            {renderAddressInputFields(`${blockPath}.addresses.${index}`)}
          </Container>
        </DraggableCollapseContainer>
      ))}

      <Button
        variant='contained'
        color='secondary'
        startIcon={<MaterialSymbol name='add_circle' color='primary' />}
        onClick={() => append({})}
        size='large'
        sx={{ minWidth: '200px', justifyContent: 'flex-start' }}>
        {tCommon('addX', { x: tComponents(`Settings.LanguageSettings.LanguageFooterSettings.address`).toLowerCase() })}
      </Button>
      <Container fullWidth column mt={4}>
        <Container fullWidth spaceBetween>
          <TextInput
            path={`${blockPath}.orgNumberLabel`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.orgNumberLabel')}
            defaultValue=''
          />
          <TextInput
            path={`${blockPath}.orgNumber`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.orgNumber')}
            defaultValue=''
          />
        </Container>

        <Container fullWidth spaceBetween>
          <TextInput
            path={`${blockPath}.responsibleEditorLabel`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.responsibleEditorLabel')}
            defaultValue=''
          />
          <TextInput
            path={`${blockPath}.responsibleEditor`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.responsibleEditor')}
            defaultValue=''
          />
        </Container>

        <Container fullWidth spaceBetween>
          <TextInput
            path={`${blockPath}.editorLabel`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.editorLabel')}
            defaultValue=''
          />
          <TextInput
            path={`${blockPath}.editor`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.editor')}
            defaultValue=''
          />
        </Container>

        <Container fullWidth>
          <TextInput
            path={`${blockPath}.privacyLink`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.privacyLink`)}
            defaultValue=''
          />
          <TextInput
            path={`${blockPath}.privacyLinkLabel`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.privacyLinkLabel`)}
            defaultValue=''
          />
          <SwitchInput
            path={`${blockPath}.privacyLinkNewWindow`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.openInNewWindow`)}
            defaultValue={false}
          />
        </Container>
        <Container fullWidth>
          <TextInput
            path={`${blockPath}.uuLink`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.uuLink`)}
            defaultValue=''
          />
          <TextInput
            path={`${blockPath}.uuLinkLabel`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.uuLinkLabel`)}
            defaultValue=''
          />
          <SwitchInput
            path={`${blockPath}.uuLinkNewWindow`}
            label={tComponents(`Settings.LanguageSettings.LanguageFooterSettings.openInNewWindow`)}
            defaultValue={false}
          />
        </Container>

        <Container fullWidth spaceBetween>
          <TextInput
            path={`${blockPath}.copyright`}
            label={tComponents('Settings.LanguageSettings.LanguageFooterSettings.copyright')}
            defaultValue=''
          />
        </Container>
      </Container>
    </Container>
  );
};

export default LanguageFooterAddressSettings;
