import React, { FC } from 'react';
import { Page } from '@/declarations/models/Page';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Api } from '@/services/Api';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  Divider,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { Breadcrumb } from '@/declarations/models/Breadcrumb';
import { mergeSx } from '@/utils/mergeSx';
import { Status } from '@/declarations/models/Status';
import Loader from '@/components/Loader';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export interface EditorBreadcrumbItemProps {
  siteId?: number;
  locale?: string;
  breadcrumb: Breadcrumb;
  disableEditorLink?: boolean;
  truncateText?: boolean;
  onCreateChildPage?: (parentId: number, parentTitle: string) => Promise<void>;
  labelSx?: SxProps;
}

export const EditorBreadcrumbItem: FC<EditorBreadcrumbItemProps> = ({
  breadcrumb,
  siteId,
  locale,
  onCreateChildPage,
  labelSx,
  disableEditorLink = false,
  truncateText = false,
}) => {
  const { t: tComponents } = useTranslation('components');
  const [children, setChildren] = React.useState<Array<Page>>([]);
  const [childrenLoading, setChildrenLoading] = React.useState(false);
  const [hasBeenOpened, setHasBeenOpened] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleListItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setHasBeenOpened(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (!hasBeenOpened || !siteId || !locale) {
      return;
    }
    let mounted = true;
    const ctx = Api.getPageTree(siteId, locale, {
      page_id: breadcrumb.id,
      status_list: [Status.PUBLISHED, Status.GENERAL, Status.ARCHIVED, Status.DRAFT],
    });
    setChildrenLoading(true);
    ctx.fetchDirect(null).then((res) => {
      if (mounted) {
        setChildren(res?.pages?.filter((p) => p.id !== breadcrumb.id) || []);
        setChildrenLoading(false);
      }
    });

    return () => {
      mounted = false;
      ctx.abort();
    };
  }, [breadcrumb, hasBeenOpened, locale, siteId]);

  return (
    <>
      <List component='ul' aria-label={breadcrumb.title}>
        <ListItemButton
          id={`breadcrumb-list-button-${breadcrumb.id}`}
          aria-haspopup='listbox'
          aria-controls={`breadcrumb-menu-${breadcrumb.id}`}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleListItemClick}
          sx={{
            px: 0.5,
            py: 0,
          }}>
          <Tooltip title={breadcrumb.title} placement='bottom' arrow>
            <Typography
              variant='body2'
              color='text.secondary'
              sx={
                truncateText
                  ? mergeSx(
                      {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '5em',
                      },
                      labelSx ?? {},
                    )
                  : labelSx
              }>
              {breadcrumb.title}
            </Typography>
          </Tooltip>
        </ListItemButton>
      </List>

      <Menu
        id={`breadcrumb-menu-${breadcrumb.id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `breadcrumb-list-button-${breadcrumb.id}`,
          role: 'listbox',
        }}>
        {!disableEditorLink && (
          <MenuItem component={RouterLink} to={`/editor/${breadcrumb.id}`} role='link'>
            <ListItemIcon>
              <MaterialSymbol name='edit' fill />
            </ListItemIcon>

            <Typography variant='body2'>
              {tComponents('EditorBreadcrumbItem.EditLinkLabel', { title: breadcrumb.title })}
            </Typography>
          </MenuItem>
        )}

        {onCreateChildPage && (
          <MenuItem onClick={() => onCreateChildPage(breadcrumb.id, breadcrumb.title)}>
            <ListItemIcon>
              <MaterialSymbol name='add_circle' />
            </ListItemIcon>
            <Typography variant='body2'>{tComponents('EditorBreadcrumbItem.CreateChildPage')}</Typography>
          </MenuItem>
        )}

        {(!disableEditorLink || onCreateChildPage) && <Divider />}
        <Typography
          variant='body2'
          fontWeight='bold'
          sx={{
            mx: 2,
            mb: 0.5,
          }}>
          {tComponents('EditorBreadcrumbItem.ChildrenLabel')}
        </Typography>
        {childrenLoading && (
          <MenuItem disabled>
            <Loader />
          </MenuItem>
        )}
        {!childrenLoading && !children.length && (
          <MenuItem disabled>
            <Typography variant='body2' color='text.secondary'>
              {tComponents('EditorBreadcrumbItem.NoChildren')}
            </Typography>
          </MenuItem>
        )}
        {children.map((child) => (
          <MenuItem key={child.id} component={RouterLink} to={`/editor/${child.id}`} role='link'>
            <ListItemIcon>
              <MaterialSymbol name='edit' fill />
            </ListItemIcon>
            <Box mr={2}>
              <PageStatusCircle size={16} status={child.status} />
            </Box>
            {child.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
