import { SectionType } from '../../../declarations/models/SectionType';
import { Section } from '../../../declarations/models/Section';
import { BlockModelFactory } from './BlockModelFactory';
import { BlockType } from '../../../declarations/models/BlockType';

export abstract class SectionModelFactory {
  private static getBaseSection(type: SectionType): Section {
    return {
      type,
      blocks: [],
      visible: 'visible',
      arrows: 'allways',
      backgroundPosition: 'top',
      txtColor: 'light',
      _collapse: true,
    };
  }

  private static getHeadSection(): Section {
    return {
      ...SectionModelFactory.getBaseSection(SectionType.ARTICLE_HEAD),
      blocks: [BlockModelFactory.makeBlockModel(BlockType.ARTICLE_TITLE)],
    };
  }

  private static getGridSection(): Section {
    return {
      ...SectionModelFactory.getBaseSection(SectionType.GRID_ROW),
      blocks: [BlockModelFactory.makeBlockModel(BlockType.FRONTPAGE)],
    };
  }

  public static makeSectionModel(sectionType: SectionType): Section {
    switch (sectionType) {
      case SectionType.ARTICLE_HEAD:
        return SectionModelFactory.getHeadSection();
      case SectionType.GRID_ROW:
        return SectionModelFactory.getGridSection();
      default:
        // eslint-disable-next-line no-console
        console.warn(
          `[SectionModelFactory] Concrete factory for section type "${sectionType}" not implemented. Using base.`,
        );
        return { ...SectionModelFactory.getBaseSection(sectionType) };
    }
  }
}
