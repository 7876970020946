import React, { FC } from 'react';
import { CircularProgressProps, LinearProgress, Typography } from '@mui/material';
import Container from './Container';
import M24Logo from './icons/M24Logo';

export interface LoaderProps extends CircularProgressProps {
  loadingText?: string;
  width?: string;
  height?: string;
}

export const Loader: FC<LoaderProps> = ({ loadingText, width = '300px', height = '100px', ...progressProps }) => {
  return (
    <Container column fullHeight>
      <M24Logo color='secondary' width={width} height={height} />
      <LinearProgress sx={{ width: '100%' }} variant='indeterminate' color='secondary' {...progressProps} />
      {loadingText && <Typography>{loadingText}</Typography>}
    </Container>
  );
};

export default Loader;
