import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import Container from '../../../../components/Container';
import SelectInput from '../../../../components/forms/SelectInput';
import { BlockPath } from '../../CurrentBlockProvider';
import { SortDirection, SortType } from '../../../../declarations/models/SortOption';
import TextInput from '../../../../components/forms/TextInput';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { PageListAutoBlock as PageListAutoBlockModel } from '../../../../declarations/models/blocks/PageListBlocks';

export const PageAutoListingSelects: FC<{ blockPath: BlockPath }> = ({ blockPath }) => {
  const { t: tComp } = useTranslation('components');

  const {
    field: { value: maxItems, onChange: setMaxItems },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${typeof blockPath}.maxSearchResults`>({
    name: `${blockPath}.maxSearchResults`,
  });

  return (
    <Container fullWidth mb={2} mt={2}>
      <TextInput
        type='number'
        path={`${blockPath}.maxSearchResults`}
        defaultValue=''
        label={tComp('PageListAutoBlock.MaxSearchResultsSelector.Label')}
        size='small'
        min={1}
      />
      <SelectInput
        options={[undefined, SortType.TITLE, SortType.PUBLISHED_AT]}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tComp(`PageListAutoBlock.OrderBySelector.options.${o}`)}
        path={`${blockPath}.orderBy`}
        label={tComp('PageListAutoBlock.OrderBySelector.Label')}
        defaultValue={undefined}
        hideNoSelectionOption
      />
      <SelectInput
        options={[undefined, SortDirection.ASC, SortDirection.DESC]}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tComp(`PageListAutoBlock.OrderSelector.options.${o}`)}
        path={`${blockPath}.order`}
        label={tComp('PageListAutoBlock.OrderSelector.Label')}
        defaultValue={undefined}
        hideNoSelectionOption
      />
    </Container>
  );
};

export default PageAutoListingSelects;
