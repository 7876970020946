import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SharingGrantedModel } from '../../../declarations/models/SiteSharingModel';
import Container from '../../../components/Container';
import CollapseContainer from '../../../components/CollapseContainer';
import { apiTimestampToDate } from '../../../utils/dates';

export interface SiteSharingSettingsFormGrantedItemProps {
  item: SharingGrantedModel;
  borderColor?: string;
}

export const SiteSharingSettingsFormGrantedItem: FC<SiteSharingSettingsFormGrantedItemProps> = ({
  item,
  borderColor,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const infoField = (title: string, text: string) => (
    <Container left sx={{ textAlign: 'center' }}>
      <Typography>{title}:</Typography>
      <Typography>{text}</Typography>
    </Container>
  );

  return (
    <Container py={1} fullWidth>
      <CollapseContainer
        outlined
        borderColor={borderColor}
        title={`${item.site_granted} - ${tComponents(
          `Settings.SiteSettings.SiteSharingSettings.SharingContentType.${item.content_type}`,
        )}`}
        subTitle={tCommon(item.approval_status)}>
        <Container fullWidth column left gap={2}>
          {infoField(tCommon('createdAt'), apiTimestampToDate(item.created_at || '')?.toLocaleString() || '')}
          {infoField(tCommon('updatedAt'), apiTimestampToDate(item.updated_at || '')?.toLocaleString() || '')}
          {infoField(
            tComponents(`Settings.SiteSettings.SiteSharingSettings.contentType`),
            tComponents(`Settings.SiteSettings.SiteSharingSettings.SharingContentType.${item.content_type}`),
          )}
        </Container>
      </CollapseContainer>
    </Container>
  );
};

export default SiteSharingSettingsFormGrantedItem;
