import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WysiwygEditor } from '@/editor/PageEditor/EditorBlock/TextBlock/WysiwygEditor';
import Container from '../../../../components/Container';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { BannerBlock } from '../../../../declarations/models/blocks/BannerBlock';
import Styles from '../../../../assets/js/Styles';

export const BannerBlockTextEditor: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.bodytext`>({
    name: `${blockPath}.bodytext`,
  });

  const toolbar = 'bold italic | bullist numlist | removeformat';
  return (
    <Container fullWidth column left>
      <WysiwygEditor value={value ?? ''} onChange={onChange} type='tinymce' toolbar={toolbar} />
      <Typography sx={{ marginLeft: '4px', color: Styles.Colors.MEDIUM_DARK_GREY }} variant='caption'>
        {t('BannerBlock.BannerBlockTextEditor.recommendationLabel')}
      </Typography>
    </Container>
  );
};

export default BannerBlockTextEditor;
