import React, { FC, Fragment } from 'react';
import { useController } from 'react-hook-form';
import Container from '../../components/Container';
import { Page } from '../../declarations/models/Page';
import EditorSectionActionButton from '../lib/components/EditorSectionActionButton';
import { SectionAction } from '../lib/declarations/SectionAction';
import { useCurrentSection } from './CurrentSectionProvider';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';

export interface SectionSettingsProps {
  onRemoveSection: () => void;
  onDuplicateSection: () => void;
  onCopySection?: () => void;
  onCutSection?: () => void;
}

export const SectionSettings: FC<SectionSettingsProps> = ({
  onRemoveSection,
  onDuplicateSection,
  onCopySection,
  onCutSection,
}) => {
  const { sectionPath, sectionType } = useCurrentSection();

  const editorConfiguration = useEditorConfiguration();

  const {
    field: { value: isVisible, onChange: setIsVisible },
  } = useController<Page>({ name: `${sectionPath}.visible`, defaultValue: 'visible' });

  const settings = editorConfiguration.getSectionSettings(sectionType);

  return (
    <Container gap={0} right>
      {settings.map((SettingComponent, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`custom-section-setting-${i}`}>
          <SettingComponent />
        </Fragment>
      ))}

      <EditorSectionActionButton
        isVisible={isVisible !== 'hidden'}
        onDuplicate={onDuplicateSection}
        onToggleVisibility={() => setIsVisible(isVisible === 'hidden' ? 'visible' : 'hidden')}
        onDelete={onRemoveSection}
        hideable={editorConfiguration.isActionAvailableForSection(sectionType, SectionAction.TOGGLE_SECTION_VISIBILITY)}
        duplicatable={editorConfiguration.isActionAvailableForSection(sectionType, SectionAction.DUPLICATE_SECTION)}
        deletable={editorConfiguration.isActionAvailableForSection(sectionType, SectionAction.DELETE_SECTION)}
        copyable={editorConfiguration.isActionAvailableForSection(sectionType, SectionAction.COPY_SECTION)}
        cuttable={editorConfiguration.isActionAvailableForSection(sectionType, SectionAction.CUT_SECTION)}
        onCopy={onCopySection}
        onCut={onCutSection}
      />
    </Container>
  );
};

export default SectionSettings;
