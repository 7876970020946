import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import Container from '@/components/Container';
import { useStore } from '../../../components/store/Store';
import { Api } from '../../../services/Api';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import { SiteSharingModel } from '../../../declarations/models/SiteSharingModel';
import SiteSharingSettingsForm from './SiteSharingSettingsForm';
import { Settings } from '../../../Settings';

export const SiteSharingSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;
  const selectedSiteLanguage = state.selectedSiteLanguage || 'no';
  const mainSiteLanguage = state.selectedSiteData?.default_language || 'no';

  const handleSaveSettingsForm = useCallback(
    async (siteSharingModel: SiteSharingModel): Promise<void | SiteSharingModel> => {
      const savedFormData = await Api.saveSiteSharing<SiteSharingModel>(siteSharingModel, selectedSiteId).fetchDirect(
        null,
      );
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedFormData;
      }
    },
    [enqueueSnackbar, selectedSiteId, tCommon],
  );

  const handleLoadModel = () => {
    return Api.getSiteSharing(selectedSiteId);
  };

  const basePortal = Settings.PORTAL_HOST || 'https://dev.museum24.no/';
  const eventsExportUrlMainLanguage = `${basePortal}/api/site/${selectedSiteId}/events/export/simpleview/v2`;
  const eventsExportUrl = `${basePortal}/api/site/${selectedSiteId}/events/export/simpleview/v2?language=${selectedSiteLanguage}`;
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;

  return (
    <BaseSettingsViewForm<SiteSharingModel>
      title=''
      onSubmit={handleSaveSettingsForm}
      loadModel={handleLoadModel}
      saveButtonInHeader={false}
      saveButtonInTop={false}
      withLayout={false}>
      <Container left fullWidth column p={2}>
        <Typography my={2} variant='h1' fontSize='xx-large'>
          {tComponents(`MainMenu.settings.sharing`)}
        </Typography>
        <SiteSharingSettingsForm />

        {isSuperAdmin && (
          <div className='superadmin' style={{ backgroundColor: '#fbe6ff', padding: '1em' }}>
            <Typography my={2} variant='h1' fontSize='xx-large'>
              {tComponents('Settings.SiteSettings.SiteSharingSettings.Events.title')}
            </Typography>
            <Typography>{tComponents('Settings.SiteSettings.SiteSharingSettings.Events.intro')}</Typography>

            <ul>
              <li>
                <a target='_blank' rel='noreferrer' href={eventsExportUrlMainLanguage}>
                  {eventsExportUrlMainLanguage}
                </a>
              </li>
              {selectedSiteLanguage !== mainSiteLanguage && (
                <li>
                  <a target='_blank' rel='noreferrer' href={eventsExportUrl}>
                    {eventsExportUrl}
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
      </Container>
    </BaseSettingsViewForm>
  );
};

export default SiteSharingSettings;
