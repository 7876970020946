import * as React from 'react';
import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export interface SplitButtonProps<T> extends Omit<ButtonGroupProps, 'onClick'> {
  label: string;
  onClick: () => void;
  options: Array<T>;
  getItemLabel: (item: T) => string;
  onItemClick: (item: T) => void;
  getItemDisabled?: (item: T) => boolean;
  getItemIcon?: (item: T) => React.ReactNode;
}

export function SplitButton<T>({
  label,
  options,
  getItemLabel,
  getItemIcon,
  onClick,
  onItemClick,
  getItemDisabled,
  disabled,
  ...buttonProps
}: SplitButtonProps<T>) {
  const { t } = useTranslation('aria');
  const subMenuToggleButton = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const toggleSubMenuOopen = () => setOpen((prevOpen) => !prevOpen);

  const closeMenu = () => setOpen(false);

  return (
    <>
      <ButtonGroup ref={subMenuToggleButton} aria-label={label} {...buttonProps}>
        <Button style={{ borderColor: 'unset' }} onClick={onClick} disabled={disabled}>
          {label}
        </Button>
        <Button
          size='small'
          disabled={!options?.length}
          aria-controls='split-button-sub-menu'
          aria-expanded={open ? 'true' : undefined}
          aria-label={t('components.SplitButton.ShowAllOptions')}
          aria-haspopup='true'
          onClick={toggleSubMenuOopen}>
          <MaterialSymbol name='arrow_drop_down' />
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        onClose={closeMenu}
        anchorEl={subMenuToggleButton.current}
        disablePortal
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Paper>
          <MenuList id='split-button-sub-menu'>
            {options.map((option) => {
              const optionLabel = getItemLabel(option);
              const icon = getItemIcon?.(option);
              return (
                <MenuItem
                  key={optionLabel}
                  role='button'
                  disabled={!!getItemDisabled?.(option)}
                  onClick={() => {
                    onItemClick(option);
                    closeMenu();
                  }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  {optionLabel}
                  {icon}
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}

export default SplitButton;
