import React, { FC } from 'react';
import { ButtonBase, IconButton } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../../../assets/js/Styles';
import Container from '../../../components/Container';
import { CommonEmployeesViewProps } from '../EmployeesViewList';
import { iconButtonStyle } from '../../MediaView/MediaViewTypes/GridViewItem';
import { Employee } from '../../../declarations/models/Employee';
import Image from '../../../components/Image';
import EmployeeInfo from './EmployeeInfo';

export interface EmployeesViewItemProps extends Pick<CommonEmployeesViewProps, 'onItemClick' | 'onDeleteItem'> {
  item: Employee;
  disableClick?: boolean;
}

export const EmployeesGridViewItem: FC<EmployeesViewItemProps> = ({
  item,
  onItemClick,
  onDeleteItem,
  disableClick,
}) => {
  const ButtonComponent: typeof ButtonBase = disableClick ? (Container as typeof ButtonBase) : ButtonBase;
  let imgMimeType = '';
  if (item?.resource?.mimetype?.includes('png')) {
    imgMimeType = `&mediaType=${item?.resource?.mimetype}`;
  }
  return (
    <Container
      column
      sx={{
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        transition: 'all 150ms ease',
        width: '280px',
        backgroundColor: Styles.Colors.LIGHTEST_GREY,
        '&:hover, &:focus': { backgroundColor: Styles.Colors.LIGHT_GREY },
      }}>
      <ButtonComponent
        onClick={() => onItemClick(item)}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Container fullWidth column gap={0} fullHeight top>
          <Container top left sx={{ position: 'relative' }}>
            {item.resource?.url ? (
              <Image
                src={`${item.resource.url}?dimension=400x400&${imgMimeType}`}
                alt={item.resource.title}
                loading='lazy'
                focusPoint={item.image_focus_point}
                style={{
                  width: '280px',
                  height: '230px',
                  objectFit: 'cover',
                  overflow: 'hidden',
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
              />
            ) : (
              <MaterialSymbol
                name='person'
                fill
                sx={{
                  fontSize: '230px',
                  padding: '0 15px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
              />
            )}
            {onDeleteItem && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteItem(item);
                }}
                sx={{ ...iconButtonStyle, right: 0 }}>
                <MaterialSymbol name='delete' fill />
              </IconButton>
            )}
          </Container>

          <EmployeeInfo employee={item} />
        </Container>
      </ButtonComponent>
    </Container>
  );
};

export default EmployeesGridViewItem;
