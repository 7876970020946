import { PageFolder } from '@/declarations/models/Folder';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Styles from '@/assets/js/Styles';
import React from 'react';

interface PageFolderAutocompleteItem extends PageFolder {
  pathFromRoot: string;
}

function flattenFolders(folders: PageFolder[], pathFromRoot: string): PageFolderAutocompleteItem[] {
  return folders.reduce((acc, folder) => {
    const newPath = `${pathFromRoot} / ${folder.title}`;
    const item: PageFolderAutocompleteItem = { ...folder, pathFromRoot: newPath };
    return [...acc, item, ...flattenFolders(folder.children || [], newPath)];
  }, [] as PageFolderAutocompleteItem[]);
}

export function PageFolderAutocomplete({
  folders,
  onChange,
}: {
  folders: PageFolder[];
  onChange: (folder: PageFolder | null) => void;
}) {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const options: PageFolderAutocompleteItem[] = [
    {
      id: 0,
      title: tComponents('PageFolderAutocomplete.NoFolder'),
      created_at: '',
      updated_at: '',
      children: [],
      pathFromRoot: tComponents('PageFolderAutocomplete.NoFolder'),
    },
    ...flattenFolders(folders, ''),
  ];
  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      }}
      id='tags-autocomplete'
      renderInput={(params) => (
        <TextField
          {...params}
          label={tCommon('search')}
          sx={{
            '& label': {
              paddingLeft: '1rem',
            },
            '& .MuiInputBase-root': {
              borderRadius: Styles.Dimensions.RADIUS_ROUND_XL,
            },
          }}
        />
      )}
      getOptionLabel={(option) => option.pathFromRoot}
      options={options}
      onChange={(_, value) => {
        onChange(value);
      }}
      renderOption={(props, option) => (
        <li {...props} key={`folder-option-${option.id}`}>
          {option.pathFromRoot}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}
