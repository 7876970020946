import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';
import Container from '../../Container';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';

export interface SelectedM24MediaItemProps {
  item: GenericMedia<M24MediaModel>;
  onRemoveItem: (item: GenericMedia<M24MediaModel>) => void;
}

export const SelectedM24MediaItem: FC<SelectedM24MediaItemProps> = ({ item, onRemoveItem }) => {
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column spaceBetween left fullWidth fullHeight>
        <Typography sx={{ wordBreak: 'break-word' }} fontWeight='bold'>
          {item.title}
        </Typography>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedM24MediaItem;
