/* istanbul ignore file */
import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { TextAlignment } from '../Alignment';

export enum QuoteTextSize {
  NORMAL = 'h-normal',
  MEDIUM = 'h-medium',
  LARGE = 'h-large',
  EXTRA_LARGE = 'h-xl',
}

export interface QuoteBlock extends BaseBlock {
  type: BlockType.QUOTE;
  quote?: string;
  quote_size?: QuoteTextSize;
  byline?: string;
  position?: TextAlignment;
}
