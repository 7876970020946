import React, { FC, useMemo } from 'react';
import { MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from './Container';

export interface PageSizeSelectorProps extends Pick<SelectProps, 'variant' | 'color'> {
  pageSize: number;
  options?: Array<number>;
  onChange?: (pageSize: number) => void;
}

export const PageSizeSelector: FC<PageSizeSelectorProps> = ({
  pageSize = 10,
  options = [5, 10, 15, 25, 50, 100],
  onChange,
  variant = 'outlined',
  color = 'secondary',
}) => {
  const { t } = useTranslation('components');
  const pageSizeOptions = useMemo<Array<number>>(() => {
    if (options.includes(pageSize)) {
      return options;
    }
    return [...options, pageSize].sort((a, b) => a - b);
  }, [pageSize, options]);

  const label = t('PageSizeSelector.NumberOfElementsToShow');
  return (
    <Container right>
      <Typography id='change-page-size-label' variant='caption' sx={{ flex: '1 0 auto' }} noWrap>
        {label}
      </Typography>
      <Select
        id='page-size-selector-input'
        labelId='change-page-size-label'
        size='small'
        variant={variant}
        color={color}
        value={pageSize}
        onChange={(e) => onChange?.(Number(e.target.value))}>
        {pageSizeOptions.map((option) => (
          <MenuItem key={`page-size-option-${option}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

export default PageSizeSelector;
