import React, { FC } from 'react';
import Container from '../../../../../components/Container';
import { ExternalMediaModel, isYouTubeMediaModel } from '../../../../../declarations/models/ExternalMediaModel';
import HeaderTitle from './HeaderTitle';
import HeaderThumbnail from './HeaderThumbnail';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface YouTubeHeaderProps {
  item: ExternalMediaModel;
}

export const YouTubeHeader: FC<YouTubeHeaderProps & ChildrenProp> = ({ children, item }) => {
  if (!isYouTubeMediaModel(item)) {
    console.error('YouTube header did not receive a YouTube media Model. This error should not occur');
    return null;
  }

  const itemIsVisible = item?.visibility !== false;

  // TODO : Load video-title (Requires integration with the YouTube-API)
  //  (Load thumbnail from API as well?)

  return (
    <Container left>
      <HeaderThumbnail item={item} disabled={!itemIsVisible} />
      <Container column left fullWidth>
        <HeaderTitle title='YouTube video' disabled={!itemIsVisible} />
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default YouTubeHeader;
