import React, { FC } from 'react';
import { createBrowserRouter, Navigate, Outlet, Route, RouterProvider, Routes } from 'react-router-dom';
import M24FormEditor from '@/editor/M24FormEditor/M24FormEditor';
import CardsView from '@/views/CardsView/CardsView';
import SiteLocationsSettings from '@/views/SettingsView/SiteLocationsSettings/SiteLocationsSettings';
import SiteSharingSettings from '@/views/SettingsView/SiteSharingSettings/SiteSharingSettings';
import SiteUsersSettings from '@/views/SettingsView/SiteUsersSettings/SiteUsersSettings';
import SiteKeywordsSettings from '@/views/SettingsView/SiteKeywordsSettings/SiteKeywordsSettings';
import { SettingsView } from '@/views/SettingsView/SettingsView';
import { SiteCardCategoriesSettings } from '@/views/SettingsView/CardCategoriesView/SiteCardCategoriesSettings';
import { Settings } from '@/Settings';
import EkulturCallbackHandler from '@/components/EkulturLoginHandler/EkulturCallbackHandler';
import EkulturLogoutHandler from '@/components/EkulturLoginHandler/EkulturLogoutHandler';
import { DashboardView } from '@/views/DashboardView/DashboardView';
import NotFoundView from './views/NotFoundView';
import ContentView from './views/ContentView/ContentView';
import MediaView from './views/MediaView/MediaView';
import FormView from './views/FormView/FormView';
import WebpageSettingsView from './views/SettingsView/WebpageSettingsView/WebpageSettingsView';
import MainLayout from './components/MainLayout';
import OwnerSettingsView from './views/OwnerSettingsView';
import Editor from './editor/Editor';
import { EditorContext } from './editor/lib/declarations/EditorContext';
import EmployeesView from './views/EmployeesView/EmployeesView';
import StylesView from './views/StylesView';
import SiteCategoriesSettings from './views/SettingsView/SiteCategoriesSettings';
import LanguageSettingsView from './views/LanguageSettingsView/LanguageSettingsView';
import { useStore } from './components/store/Store';
import LanguageCreationView from './views/LanguageSettingsView/LanguageCreationView/LanguageCreationView';
import LanguageLogoSettings from './views/LanguageSettingsView/LanguageLogoSettings';
import LanguageMenuSettings from './views/LanguageSettingsView/LanguageMenuSettings/LanguageMenuSettings';
import LanguageFooterSettings from './views/LanguageSettingsView/LanguageFooterSettings/LanguageFooterSettings';
import LanguageInfoSettings from './views/LanguageSettingsView/LanguageInfoSettings';
import LanguageSEOSettings from './views/LanguageSettingsView/LanguageSEOSettings';
import Language404Settings from './views/LanguageSettingsView/Language404Settings';
import { ContentViewContentContext } from './views/ContentView/ContentViewContext';
import { FormDataView } from './views/FormView/FormData/FormDataView';
import EkulturLoginController from './components/EkulturLoginHandler/EkulturLoginController';
import { SitePageTemplateSettings } from './views/SettingsView/SitePageTemplateSettings/SitePageTemplateSettings';

const Root: FC = () => {
  const { state } = useStore();

  const languageRoutes = state.selectedSite?.site_contents?.map((content) => {
    return (
      <Route key={content.id} path={content.locale} element={<Outlet />}>
        <Route index element={<Navigate to='languageInfo' />} />
        <Route
          path='languageInfo'
          element={
            <LanguageSettingsView locale={content.locale}>
              <LanguageInfoSettings locale={content.locale} />
            </LanguageSettingsView>
          }
        />
        <Route
          path='seo'
          element={
            <LanguageSettingsView locale={content.locale}>
              <LanguageSEOSettings />
            </LanguageSettingsView>
          }
        />
        <Route
          path='404'
          element={
            <LanguageSettingsView locale={content.locale}>
              <Language404Settings />
            </LanguageSettingsView>
          }
        />
        <Route
          path='logo'
          element={
            <LanguageSettingsView locale={content.locale}>
              <LanguageLogoSettings />
            </LanguageSettingsView>
          }
        />
        <Route
          path='menu'
          element={
            <LanguageSettingsView locale={content.locale}>
              <LanguageMenuSettings />
            </LanguageSettingsView>
          }
        />
        <Route
          path='footer'
          element={
            <LanguageSettingsView locale={content.locale}>
              <LanguageFooterSettings />
            </LanguageSettingsView>
          }
        />
      </Route>
    );
  });

  return (
    <Routes>
      <Route
        path='/'
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }>
        <Route index element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<DashboardView />} />
        <Route path='content' element={<ContentView contentContext={ContentViewContentContext.PAGE} key='content' />} />
        <Route path='events' element={<ContentView contentContext={ContentViewContentContext.EVENT} key='events' />} />
        <Route path='employees' element={<EmployeesView />} />
        <Route path='cards' element={<CardsView />} />
        <Route path='media' element={<MediaView />} />
        <Route path='forms' element={<Outlet />}>
          <Route index element={<FormView />} />
          <Route path='data/:formId' element={<FormDataView />} />
        </Route>
        <Route path='settings' element={<SettingsView />}>
          <Route index element={<Navigate to='webpage' />} />
          <Route path='styles' element={<StylesView />} />
          <Route path='webpage' element={<WebpageSettingsView />} />
          <Route path='owner' element={<OwnerSettingsView />} />
          <Route path='sharing' element={<SiteSharingSettings />} />
          <Route path='locations' element={<SiteLocationsSettings />} />
          <Route path='users' element={<SiteUsersSettings />} />
          <Route path='keywords' element={<SiteKeywordsSettings />} />
          <Route path='categories' element={<SiteCategoriesSettings />} />
          <Route path='card-categories' element={<SiteCardCategoriesSettings />} />
          <Route path='page-templates' element={<SitePageTemplateSettings />} />
        </Route>
        <Route path='language-settings' element={<Outlet />}>
          <Route index element={<Navigate to='create' />} />
          {languageRoutes}
          <Route path='create' element={<LanguageCreationView />} />
        </Route>
      </Route>
      <Route path='/' element={<Outlet />}>
        <Route path='formEditor'>
          <Route path=':formId' element={<M24FormEditor />} />
        </Route>
      </Route>
      <Route path='*' element={<NotFoundView />} />
    </Routes>
  );
};

const EkulturLoginControllerWrapper: FC = () => {
  return (
    <EkulturLoginController>
      <Outlet />
    </EkulturLoginController>
  );
};

const router = createBrowserRouter([
  {
    path: Settings.EKULTUR_LOGIN_CALLBACK_PATH,
    element: <EkulturCallbackHandler />,
  },
  {
    path: '/logout',
    element: <EkulturLogoutHandler />,
  },
  {
    path: '/',
    element: <EkulturLoginControllerWrapper />,
    children: [
      {
        index: true,
        element: <Navigate to='dashboard' />,
      },
      {
        path: 'editor',
        children: [
          {
            path: ':modelId',
            element: <Editor context={EditorContext.PAGE} />,
          },
        ],
      },
      {
        path: '*',
        element: <Root />,
      },
    ],
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};

// export default App;
