import React, { FC } from 'react';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import Container from '@/components/Container';
import SelectInput from '@/components/forms/SelectInput';
import { SortDirection } from '@/declarations/models/SortOption';
import { useTranslation } from 'react-i18next';
import TextInput from '@/components/forms/TextInput';

export const DMSchoolBlockListingSelects: FC<{ blockPath: BlockPath }> = ({ blockPath }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  enum DMSortType {
    TITLE = 'artifact.ingress.title',
    UPDATED_AT = 'artifact.updatedDate',
    PUBLISHED_AT = 'artifact.publishedDate',
  }

  return (
    <Container fullWidth mb={2} mt={2}>
      <SelectInput
        options={Object.values(DMSortType)}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tCommon(`DMSortTypes.${o}`)}
        path={`${blockPath}.sortBy`}
        defaultValue={DMSortType.TITLE}
        label={tCommon('SortTypeLabel')}
        hideNoSelectionOption
      />
      <SelectInput
        options={[SortDirection.ASC, SortDirection.DESC]}
        getOptionKey={(o) => String(o)}
        getOptionLabel={(o) => tComp(`PageListAutoBlock.OrderSelector.options.${o}`)}
        path={`${blockPath}.order`}
        label={tComp('PageListAutoBlock.OrderSelector.Label')}
        defaultValue={SortDirection.DESC}
        hideNoSelectionOption
      />
      <TextInput
        type='number'
        path={`${blockPath}.maxItems`}
        defaultValue='25'
        label={tComp('PageListAutoBlock.MaxSearchResultsSelector.Label')}
        size='small'
        min={1}
      />
    </Container>
  );
};
