import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { MediaUsages } from '../../declarations/models/M24MediaModel';
import { Page } from '../../declarations/models/Page';
import { Api } from '../../services/Api';
import { Block } from '../../declarations/models/Block';
import Container from '../../components/Container';
import { Employee } from '../../declarations/models/Employee';

export interface MediaEditorPreviewUsageLinksProps {
  mediaUsages?: MediaUsages;
  siteId?: number;
}

const getUsageBlocks = async (setMethod: (blocks: Block[]) => void, siteId?: number, usageList?: Array<number>) => {
  if (usageList?.length && siteId) {
    const blockList: Array<Block> = [];
    await Promise.all(
      usageList.map(async (id) => {
        await Api.getBlock(siteId, id)
          .fetchDirect(null)
          .then((block) => !!block && blockList.push(block));
      }),
    ).finally(() => setMethod(blockList));
  }
};

const getUsageEmployees = async (
  setMethod: (employee: Employee[]) => void,
  siteId?: number,
  usageList?: Array<number>,
) => {
  if (usageList?.length && siteId) {
    const employeeList: Array<Employee> = [];
    await Promise.all(
      usageList.map(async (id) => {
        await Api.getEmployee(siteId, id)
          .fetchDirect(null)
          .then((employee) => !!employee && employeeList.push(employee));
      }),
    ).finally(() => setMethod(employeeList));
  }
};

const getUsagePages = async (setMethod: (blocks: Page[]) => void, usageList?: Array<number>) => {
  if (usageList?.length) {
    const pageList: Array<Page> = [];
    await Promise.all(
      usageList.map(async (id) => {
        await Api.getPage(id)
          .fetchDirect(null)
          .then((page) => !!page && pageList.push(page));
      }),
    ).finally(() => setMethod(pageList));
  }
};

export const linkStyle = {
  display: 'flex',
  alignItems: 'center',
};

export const MediaEditorPreviewUsageLinks: FC<MediaEditorPreviewUsageLinksProps> = ({ mediaUsages, siteId }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [pages, setPages] = useState<Array<Page>>([]);
  const [cards, setCards] = useState<Array<Block>>([]);
  const [persons, setPersons] = useState<Array<Block>>([]);
  const [others, setOthers] = useState<Array<Block>>([]);
  const [employees, setEmployees] = useState<Array<Employee>>([]);

  useEffect(() => {
    if (mediaUsages) {
      getUsagePages(setPages, mediaUsages.page_usages);
      getUsageBlocks(setCards, siteId, mediaUsages.card_usages);
      getUsageBlocks(setPersons, siteId, mediaUsages.person_usages);
      getUsageBlocks(setOthers, siteId, mediaUsages.other_usages);
      getUsageEmployees(setEmployees, siteId, mediaUsages.employee_usages);
    }
  }, [mediaUsages, siteId]);

  if (!pages.length && !cards.length && !persons.length && !others.length && !employees.length) {
    return <Typography fontStyle='italic'>{tComp('MediaView.NotInUse')}</Typography>;
  }

  return (
    <Container fullWidth fullHeight column right top>
      <Typography fontStyle='italic'>{tComp('MediaEditor.InUseLinksLabel')}</Typography>
      {!!pages.length && (
        <>
          <Typography variant='subtitle2'>{tCommon('MediaResourceType.page')}</Typography>
          {pages.map((page) => (
            <Link key={`page-${page.id}`} to={`/editor/${page.id}`} target='_blank' style={linkStyle}>
              <Typography textAlign='right'>{`${page.title || ''} (${page.id})`}</Typography>
              <MaterialSymbol name='open_in_new' />
            </Link>
          ))}
        </>
      )}
      {!!cards.length && (
        <>
          <Typography variant='subtitle2'>{tCommon('MediaResourceType.card')}</Typography>
          {cards.map((card) => (
            // TODO: path to card
            <Link key={`card-${card.id}`} to={`/card/${card.id}`} target='_blank' style={linkStyle}>
              <Typography textAlign='right'>{`${card.title || ''} (${card.id})`}</Typography>
              <MaterialSymbol name='open_in_new' />
            </Link>
          ))}
        </>
      )}
      {(!!persons.length || !!employees.length) && (
        <>
          <Typography variant='subtitle2'>{tCommon('MediaResourceType.person')}</Typography>
          {persons.map((person) => (
            // TODO: path to person
            <Link key={`person-${person.id}`} to={`/person/${person.id}`} target='_blank' style={linkStyle}>
              <Typography textAlign='right'>{`${person.title || ''} (${person.id})`}</Typography>
              <MaterialSymbol name='open_in_new' />
            </Link>
          ))}
          {employees.map((employee) => (
            // TODO: path to employee
            <Link key={`person-${employee.id}`} to={`/person/${employee.id}`} target='_blank' style={linkStyle}>
              <Typography textAlign='right'>{`${employee.first_name} ${employee.last_name} (${employee.id})`}</Typography>
              <MaterialSymbol name='open_in_new' />
            </Link>
          ))}
        </>
      )}
      {!!others.length && (
        <>
          <Typography variant='subtitle2'>{tCommon('others')}</Typography>
          {others.map((block) => (
            // TODO: path to other blocks?
            <Link key={`person-${block.id}`} to={`/block/${block.id}`} target='_blank' style={linkStyle}>
              <Typography textAlign='right'>{`${block.title || ''} (${block.id})`}</Typography>
              <MaterialSymbol name='open_in_new' />
            </Link>
          ))}
        </>
      )}
    </Container>
  );
};

export default MediaEditorPreviewUsageLinks;
