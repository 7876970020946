/* istanbul ignore file */
import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const M24Logo: FC<Omit<SvgIconProps, 'viewBox'>> = (props) => {
  return (
    <SvgIcon
      sx={{ width: 'unset', height: 'unset' }}
      height='32px'
      width='110px'
      {...props}
      viewBox='-43 -4 110 32'
      data-testid='M24Logo'>
      <path d='M50.1,15.9v-1c3.8-3,5.9-4.8,5.9-6.6c0-1.4-1.1-1.9-2.2-1.9c-1.3,0-2.2,0.5-2.9,1.3l-0.8-0.8     c0.8-1.1,2.2-1.7,3.7-1.7c1.8,0,3.6,1,3.6,3.1c0,2.1-2.2,4.1-5.1,6.4h5.2v1.2C57.4,15.9,50.1,15.9,50.1,15.9z' />
      <path d='M61.8,18.6v-2.7h-5v-1.1L61.3,8h1.8v6.7h1.5v1.2h-1.5v2.7L61.8,18.6L61.8,18.6z M61.8,9.2l-3.7,5.5h3.7     V9.2z' />
      <path d='M-10.2,16.8l1.5-2.4c1,0.9,2.9,1.8,4.6,1.8c1.5,0,2.2-0.6,2.2-1.4c0-2.2-7.8-0.4-7.8-5.6     C-9.7,6.9-7.8,5-4.3,5c2.2,0,4,0.8,5.3,1.8l-1.4,2.4c-0.8-0.9-2.3-1.5-3.9-1.5c-1.3,0-2.1,0.6-2.1,1.3c0,2,7.8,0.3,7.8,5.6     c0,2.4-2.1,4.2-5.7,4.2C-6.6,18.9-8.8,18.1-10.2,16.8z' />
      <path d='M3.2,11.9C3.2,8.1,6,5,10,5c3.9,0,6.6,2.9,6.6,7.3v0.8H6.8c0.2,1.7,1.6,3.1,3.8,3.1     c1.1,0,2.7-0.5,3.5-1.3l1.5,2.2c-1.3,1.2-3.4,1.8-5.4,1.8C6.2,18.9,3.2,16.2,3.2,11.9z M10,7.8c-2.1,0-3.1,1.6-3.3,2.9h6.5     C13.2,9.4,12.3,7.8,10,7.8z' />
      <path d='M-24.2,10.5v-0.2c0-1.2-0.6-2.1-2-2.1c-1.2,0-2.2,0.8-2.7,1.5v8.9h-3.5v-8.3c0-1.2-0.6-2.1-2-2.1     c-1.2,0-2.2,0.8-2.7,1.6v8.9h-3.5V5.3h3.5v1.7c0.6-0.8,2.3-2.1,4.3-2.1c1.9,0,3.2,0.9,3.7,2.4c0.8-1.2,2.5-2.4,4.5-2.4     c2.4,0,3.9,1.3,3.9,4v1.4L-24.2,10.5L-24.2,10.5z' />
      <path d='M-15.3,18.6V17c-0.9,1-2.5,2-4.6,2c-2.9,0-4.3-1.6-4.3-4.1v-2.6h3.5v1.2c0,1.8,1,2.4,2.4,2.4     c1.3,0,2.4-0.7,3-1.5V5.4h3.5v13.2H-15.3z' />
      <path d='M27.6,18.7V17c-0.9,1-2.5,2-4.6,2c-2.9,0-4.3-1.6-4.3-4.1V5.5h3.5v8c0,1.8,1,2.4,2.4,2.4     c1.3,0,2.4-0.7,3-1.5v-3.1H31v7.4C31,18.7,27.6,18.7,27.6,18.7z' />
      <path d='M43.9,18.6v-8.3c0-1.2-0.6-2.1-2-2.1c-1.2,0-2.2,0.8-2.7,1.5v8.9h-3.5v-8.3c0-1.2-0.6-2.1-2-2.1     c-1.2,0-2.2,0.8-2.7,1.6v2.5h-3.5V5.3H31v1.7C31.6,6.3,33.3,5,35.3,5c1.9,0,3.2,0.9,3.7,2.4C39.8,6.2,41.5,5,43.5,5     c2.4,0,3.9,1.3,3.9,4v9.5L43.9,18.6L43.9,18.6z' />
    </SvgIcon>
  );
};

export default M24Logo;
