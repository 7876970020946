import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ChildrenProp } from '../declarations/ChildrenProp';
import Container from './Container';
import Styles from '../assets/js/Styles';
import SettingsViewHeader from './SettingsViewHeader';
import SettingsViewMenu from './SettingsMenu';
import { MenuItem } from '../declarations/models/MenuItem';
import SaveFormButton from './SaveFormButton';

export interface SettingsViewLayoutProps extends ChildrenProp {
  title: string;
  infoText?: string;
  menuItems?: Array<MenuItem>;
  saveButtonInHeader?: boolean;
  saveButtonInTop?: boolean;
}

export const SettingsViewLayout: FC<SettingsViewLayoutProps> = ({
  children,
  title,
  infoText,
  menuItems,
  saveButtonInHeader = true,
  saveButtonInTop = !saveButtonInHeader,
}) => {
  return (
    <Container
      px={2}
      py={2}
      fullWidth
      fullHeight
      column
      sx={{
        backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
        border: `1px solid ${Styles.Colors.LIGHTEST_GREY}`,
      }}>
      <SettingsViewHeader title={title} saveButtonInHeader={saveButtonInHeader} />
      <Container spaceBetween fullWidth fullHeight top>
        {menuItems && <SettingsViewMenu width='220px' menuItems={menuItems} />}
        <Container fullWidth fullHeight top left column py={2} px={2}>
          <Container fullWidth spaceBetween>
            {saveButtonInTop && <SaveFormButton />}
          </Container>
          <Typography>{infoText}</Typography>
          <Container fullWidth fullHeight top left column sx={{ overflow: 'auto' }}>
            {children}
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default SettingsViewLayout;
