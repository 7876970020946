import React, { FC } from 'react';
import { Grow } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { M24MediaModel } from '../../../../../declarations/models/M24MediaModel';
import Container from '../../../../../components/Container';
import HeaderThumbnail from './HeaderThumbnail';
import HeaderTitle from './HeaderTitle';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface M24HeaderProps {
  open: boolean;
  item: M24MediaModel;
}

export const M24Header: FC<M24HeaderProps & ChildrenProp> = ({ children, open = true, item }) => {
  const altText = item?.content?.alttext;

  return (
    <Container left fullWidth>
      <HeaderThumbnail item={item} hidden={open} alt={altText} />
      <Container column left fullWidth>
        <Container gap={0} left fullWidth>
          <HeaderTitle title={item?.title || item.filename} />
          <Grow in={!open && !altText}>
            <MaterialSymbol name='error' fill color='error' />
          </Grow>
        </Container>
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default M24Header;
