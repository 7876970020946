import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import { M24MediaModel, PublishM24Media } from '@/declarations/models/M24MediaModel';
import { mediaUploader, STATUS } from '@ekultur/dms-upload';
import { toggleItem } from '@/utils/array';
import { Api } from '@/services/Api';
import { getMediaType } from '@/utils/MediaUtils';
import { Settings } from '@/Settings';
import Loader from '@/components/Loader';
import EditMediaStage from './EditMediaStage';
import { EditSelectedMediaProps } from '../EditSelectedMedia';
import UploadMediaStage from './UploadMediaStage';

interface UploadMediaModalProps extends Omit<EditSelectedMediaProps, 'selectedItems'> {
  open: boolean;
  closeModal: () => void;
}

enum UploadStage {
  UPLOAD = 'upload',
  EDIT = 'edit',
}

function LinearProgressWithLabel({ value }: { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          color='secondary'
          variant='determinate'
          value={value}
          style={{ height: '15px', borderRadius: '15px' }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const UploadMediaModal: FC<UploadMediaModalProps> = ({ open, closeModal, siteId, ...rest }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const confirm = useConfirmDialog();

  const [uploadStage, setUploadStage] = useState<UploadStage>(UploadStage.UPLOAD);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<File>>([]);
  const [fileMap, setFileMap] = useState<Array<{ file: File; id: string }>>([]);
  const [uploadedMediaItems, setUploadedMediaItems] = useState<Array<M24MediaModel>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<M24MediaModel>>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, any>>({});
  const adminApiHost = Settings.ADMIN_API_HOST;

  // Helper function to replace special characters in filenames
  const sanitizeFilename = (filename: string): string => {
    return filename.replace(/[^a-zA-Z0-9._-]/g, '_');
  };

  useEffect(() => {
    const newFileMap = files.map((file) => ({
      file: new File([file], sanitizeFilename(file.name), { type: file.type }),
      id: Math.round(Math.random() * 1e12).toString(),
    }));
    setFileMap(newFileMap);
  }, [files]);

  const handleUploadSelected = async () => {
    setIsLoading(true);
    setIsUploading(true);
    setIsPublishing(false);

    const config = {
      orderUploadEndpoint: {
        url: `${adminApiHost}/api/v2/media/order_upload_url/${siteId}`,
        method: 'GET',
      },
      commitMultipartEndpoint: {
        url: `${adminApiHost}/api/v2/media/commit/multipart/upload`,
      },
      chunkSize: 1e8,
      limit: 5,
      retry: 3,
      mock: false,
    };

    const uploadHandler = (upload: any) => {
      if (upload.status === STATUS.ERROR) {
        setUploadProgress((prev) => ({
          ...prev,
          [upload.id]: { error: upload.message },
        }));
      } else {
        setUploadProgress((prev) => ({
          ...prev,
          [upload.id]: { progress: (upload.progress.loaded / upload.progress.total) * 100 },
        }));
      }
    };

    try {
      const uploads = await mediaUploader(config, fileMap, uploadHandler);

      setIsUploading(false);
      setIsPublishing(true);

      const successfulUploads = uploads.finished;
      const failedUploads = uploads.failed;

      const mediaToPublish = successfulUploads
        .map((upload: any) => {
          const fileEntry = fileMap.find((f) => f.id === upload.id);
          if (!fileEntry) {
            console.error(`No file found for upload id ${upload.id}`);
            return null;
          }
          const { file } = fileEntry;
          const media: PublishM24Media = {
            dms_id: upload.dmsId,
            type: getMediaType(file.type),
            filename: file.name,
            mimetype: file.type,
          };
          return media;
        })
        .filter(Boolean) as PublishM24Media[];

      const publishedMediaItems: (M24MediaModel | null)[] = await Promise.all(
        mediaToPublish.map(async (media: PublishM24Media) => {
          try {
            const publishedMediaItem = await Api.publishMediaResource(siteId, media).fetchDirect(null);
            if (publishedMediaItem) {
              return publishedMediaItem;
            }
            console.error(`No media item returned for media ${media.filename}`);
            return null;
          } catch (error) {
            console.error(`Error publishing media ${media.filename}:`, error);
            return null;
          }
        }),
      );

      const validPublishedMediaItems = publishedMediaItems.filter((item): item is M24MediaModel => item !== null);

      setUploadedMediaItems(validPublishedMediaItems);

      if (failedUploads.length > 0) {
        console.error('Some files failed to upload:', failedUploads);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    } finally {
      setIsLoading(false);
      setIsUploading(false);
      setIsPublishing(false);
      setUploadStage(UploadStage.EDIT);
    }
  };

  const onSelectUploaded = (item: M24MediaModel) => {
    setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
  };

  const resetStage = () => {
    setUploadStage(UploadStage.UPLOAD);
    setFiles([]);
    setFileMap([]);
    setUploadedMediaItems([]);
    setSelectedItems([]);
    setUploadProgress({});
  };

  const handleCloseButton = async () => {
    if (uploadStage === UploadStage.UPLOAD || (await confirm(tComp('MediaView.MediaUpload.ConfirmLeaveEditStage')))) {
      closeModal();
      resetStage();
    }
  };

  const incompleteIcon = (name: string, active: boolean) => (
    <span
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '1.5rem',
        height: '1.5rem',
        fontSize: 'small',
        borderRadius: '50%',
        backgroundColor: active ? Styles.Colors.SUCCESS : Styles.Colors.MEDIUM_DARK_GREY,
        color: 'white',
        marginRight: '0.5rem',
      }}>
      {name}
    </span>
  );

  const getStepIcon = (name: string, active: boolean, completed: boolean) => {
    if (active) {
      return incompleteIcon(name, true);
    }
    if (completed) {
      return <MaterialSymbol name='check_circle' fill color='success' />;
    }
    return incompleteIcon(name, false);
  };

  const renderStageStepper = () => {
    return (
      <Stepper activeStep={uploadStage === UploadStage.UPLOAD ? 0 : 1} sx={{ margin: '0 0 2rem 0' }} alternativeLabel>
        <Step completed={uploadStage !== UploadStage.UPLOAD}>
          <StepLabel StepIconComponent={(props) => getStepIcon('1', props.active, props.completed)}>
            {tComp('MediaView.MediaUpload.UploadFiles')}
          </StepLabel>
        </Step>
        <Step completed={uploadStage === UploadStage.EDIT}>
          <StepLabel StepIconComponent={(props) => getStepIcon('2', props.active, props.completed)}>
            {tComp('MediaView.MediaUpload.AddMetadata')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={(props) => getStepIcon('3', props.active, props.completed)}>
            {tComp('MediaView.MediaUpload.AddToGallery')}
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  return (
    <Dialog open={open} fullScreen sx={{ margin: 4 }}>
      <DialogTitle> {tComp('MediaView.MediaUpload.Header')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            maxWidth: '1200px',
            mx: 'auto',
            mb: '6rem',
          }}>
          {renderStageStepper()}
        </Box>

        {/* Uploading Phase */}
        {isLoading && isUploading && (
          <Box sx={{ width: '100%' }}>
            {fileMap.map(({ file, id }) => {
              const progressInfo = uploadProgress[id] || {};
              const progressValue = progressInfo.progress || 0;
              const error = progressInfo.error || null;
              return (
                <Box key={id} sx={{ mb: 2 }}>
                  <Typography variant='body2'>{file.name}</Typography>
                  {error ? (
                    <Typography variant='body2' color='error'>
                      {error}
                    </Typography>
                  ) : (
                    <LinearProgressWithLabel value={progressValue} />
                  )}
                </Box>
              );
            })}
          </Box>
        )}

        {/* Publishing Phase */}
        {isLoading && isPublishing && <Loader />}

        {!isLoading && uploadStage === UploadStage.UPLOAD && (
          <UploadMediaStage validFiles={files} setValidFiles={setFiles} />
        )}
        {!isLoading && uploadStage === UploadStage.EDIT && (
          <EditMediaStage
            uploadsFailed={files.length - uploadedMediaItems.length}
            uploadedMediaItems={uploadedMediaItems}
            selectedItems={selectedItems}
            handleSelectItem={onSelectUploaded}
            siteId={siteId}
            {...rest}
          />
        )}
      </DialogContent>
      <DialogActions>
        {uploadStage === UploadStage.UPLOAD && (
          <>
            <Button variant='contained' onClick={handleCloseButton}>
              {tCommon('cancel')}
            </Button>
            <Button
              variant='contained'
              color='success'
              disabled={!files.length || isUploading || isPublishing}
              onClick={handleUploadSelected}>
              {tComp('MediaView.MediaUpload.Upload')}
            </Button>
          </>
        )}
        {uploadStage === UploadStage.EDIT && (
          <Button variant='contained' color='success' onClick={handleCloseButton}>
            {tCommon('done')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadMediaModal;
