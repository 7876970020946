import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, Slider } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';
import Container from '../Container';

export interface SliderInputProps<T>
  extends Omit<FormInputBaseProps<T>, 'minLength' | 'maxLength'>,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  valueLabelDisplay?: 'auto' | 'on' | 'off';
  /**
   * When an empty label is provided, use valueLabel to display a string after the value as label (eg. '%' for 50%)
   */
  valueLabel?: string;
  /**
   * The size of the slider.
   * @default 'medium'
   */
  size?: 'small' | 'medium';
  step?: number;
  marks?: boolean;
  /**
   * Return value as a decimal of 100.
   * ex: 3 -> 0.03, 20 -> 0.2
   */
  valueAsPercent?: boolean;
}

export default function SliderInput<T extends FieldValues>({
  labelPlacement,
  valueLabelDisplay = 'auto',
  valueLabel,
  size = 'medium',
  step,
  marks,
  valueAsPercent,
  ...baseProps
}: SliderInputProps<T>) {
  const { field, disabled, label, color, fullWidth, isError, errorMessage, min, max, defaultValue } =
    useFormInput<T>(baseProps);
  const { value, onChange, onBlur, name, ref } = field;

  const getInitialValue = (): number => {
    return Math.round(
      (valueAsPercent
        ? ((value as number) || (defaultValue as number)) * (value > 1 ? 1 : 100)
        : value || defaultValue) as number,
    );
  };

  const [internalState, setInternalState] = useState<number>(() => getInitialValue());
  const [labelText, setLabelText] = useState<string>(
    typeof label === 'string' ? label : (internalState as unknown as string),
  );

  // Hack: makes sure that any 'defaultValue' is reflected in the rest of the form (useWatch, preview, etc)
  useEffect(() => {
    onChange(getInitialValue());
  }, []);

  return (
    <FormControl color={color} fullWidth={fullWidth} disabled={disabled} error={isError}>
      <FormControlLabel
        control={
          <Container pl={2} pr={2} fullWidth>
            <Slider
              name={name}
              ref={ref}
              color={color}
              onChange={(_e, v) => {
                setInternalState(v as number);
              }}
              onChangeCommitted={(_e, v) => {
                if (valueAsPercent) {
                  onChange(Number.parseFloat(((v as number) / (max || 100)).toFixed(2)));
                } else {
                  onChange(v as number);
                }
                setLabelText(internalState as unknown as string);
              }}
              value={internalState}
              onBlur={onBlur}
              valueLabelDisplay={valueLabelDisplay}
              min={min}
              max={max}
              size={size}
              step={step}
              marks={marks}
            />
          </Container>
        }
        label={valueLabel ? `${labelText}${valueLabel}` : labelText}
        labelPlacement={labelPlacement}
      />
      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
