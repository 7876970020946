import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockLayout } from '../../../declarations/models/BlockLayout';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import CustomIcon from '../../../components/CustomIcon';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';
import { BlockModuleLayout } from '../../../declarations/models/BlockModuleLayout';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';

const LAYOUT_OPTIONS: Array<BlockLayout> = Object.values(BlockLayout);
const LAYOUT_OPTIONS_ICONS: Record<BlockLayout, ReactNode> = {
  [BlockLayout.AUTO]: <MaterialSymbol name='horizontal_split' sx={{ transform: 'scaleY(-1)' }} />,
  [BlockLayout.WIDE]: <CustomIcon name='width_wider' />,
  [BlockLayout.IMAGE_LEFT]: <CustomIcon name='image_left' />,
  [BlockLayout.IMAGE_RIGHT]: <CustomIcon name='image_right' />,
};

export const PageBlockLayoutSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<
      BaseBlock & {
        layout?: BlockLayout | BlockModuleLayout;
        module__type: BlockModuleType;
      }
    >,
    `${typeof blockPath}.layout`
  >({
    name: `${blockPath}.layout`,
  });

  const currentBlock = block as BaseBlock & {
    layout?: BlockLayout | BlockModuleLayout;
    module__type: BlockModuleType;
  };

  useEffect(() => {
    if (
      value &&
      currentBlock.module__type === BlockModuleType.BODY_TEXT &&
      !Object.values(BlockLayout).includes(value as BlockLayout)
    ) {
      onChange(BlockLayout.AUTO);
    }
  });

  return (
    <ActionMenu
      id='page-block-layout-selector'
      ariaLabel={tAria('components.PageBlockLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockLayoutSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? LAYOUT_OPTIONS_ICONS[value as BlockLayout] : LAYOUT_OPTIONS_ICONS.auto}
      disabled={currentBlock.module__type === BlockModuleType.MODULE}>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{LAYOUT_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockLayoutSetting;
