import React, { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import Container from '../../components/Container';
import { Credit, M24MediaModel } from '../../declarations/models/M24MediaModel';
import Styles from '../../assets/js/Styles';
import { Api } from '../../services/Api';
import { MediaLicense } from '../../declarations/models/MediaLicense';
import { Tag } from '../../declarations/models/Tag';
import EditMediaLicenseCreditsFields from '../../editor/MediaEditor/MediaEditorFields/EditMediaLicenseCreditsFields';
import EditMediaTagsField from '../../editor/MediaEditor/MediaEditorFields/EditMediaTagsField';

export interface EditSelectedMediaProps {
  selectedItems: Array<M24MediaModel>;
  refreshPage: () => void;
  tags: Array<Tag>;
  siteId: number;
}

export const EditSelectedMedia: FC<EditSelectedMediaProps> = ({ selectedItems, refreshPage, tags, siteId }) => {
  const { t: tComp } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();

  const [license, setLicense] = useState<MediaLicense>(MediaLicense.NONE);
  const [credits, setCredits] = useState<Array<Credit>>([]);
  const [tagsInput, setTagsInput] = useState<Array<string>>([]);

  const resetInputs = () => {
    setLicense(MediaLicense.NONE);
    setCredits([]);
    setTagsInput([]);
  };

  const doBatchUpdate = async () => {
    if (await confirm(tComp('EditSelectedMedia.ConfirmUpdateSelection'))) {
      const partRes: Partial<M24MediaModel> = { content: {} };
      if (credits.length) partRes.content!.credits = credits;
      if (license) partRes.content!.license_code = license;
      if (tagsInput.length) partRes.tags = tagsInput.map((tag) => ({ tag }));
      const ctx = Api.updateM24MediaResources(siteId, selectedItems.map((i) => i.id).join(','), partRes);
      const [res, err] = await ctx.fetch();
      if (res) {
        const nrFailed = res.total_count - res.res_count;
        if (nrFailed === 0) {
          enqueueSnackbar(tComp('MediaView.MediaUpdated', { nr: res.res_count }), { variant: 'success' });
        } else {
          enqueueSnackbar(tComp('MediaView.UpdatesFailed', { failed: nrFailed, total: res.total_count }), {
            variant: 'error',
          });
        }
        refreshPage();
        resetInputs();
      }
      if (err) enqueueSnackbar(err.message, { variant: 'error' });
      ctx.abort();
    }
  };

  return (
    <Container
      p={3}
      gap={3}
      sx={{ width: '500px', minWidth: '500px', backgroundColor: Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND }}
      column>
      <Typography variant='h5'>{tComp('MediaView.EditSelectedMedia')}</Typography>
      <EditMediaLicenseCreditsFields
        credits={credits}
        setCredits={setCredits}
        license={license}
        setLicense={setLicense}
      />
      <EditMediaTagsField allTags={tags} tagsInput={tagsInput} setTagsInput={setTagsInput} />
      <Container fullWidth>
        <Button
          disabled={!credits.length && !license && !tagsInput.length}
          fullWidth
          color='success'
          onClick={doBatchUpdate}
          variant='contained'>
          {tComp('MediaView.Update_selected')}
        </Button>
      </Container>
    </Container>
  );
};

export default EditSelectedMedia;
