export function mapRange(value: number, currentFrom: number, currentTo: number, from: number, to: number): number {
  if (value < currentFrom || value > currentTo) {
    throw new Error('Value is outside the specified range');
  }
  const oldRangeDiff = currentTo - currentFrom;
  const newRangeRiff = to - from;
  if (oldRangeDiff === 0 || newRangeRiff === 0) {
    throw new Error('Ranges must be greater than 0');
  }
  const factor = (value - currentFrom) / oldRangeDiff;
  const factorInNewRange = factor * newRangeRiff;
  return factorInNewRange + from;
}
