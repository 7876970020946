import React from 'react';
import { useController } from 'react-hook-form';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { useCurrentBlock } from '../CurrentBlockProvider';
import CardModuleViewTypeSelector from '../../lib/components/settings/CardModuleViewTypeSelector';
import { CardModuleBlock } from '../../../declarations/models/blocks/CardModuleBlock';

export const PageBlockCardModuleViewTypeSelector: SettingsComponent = () => {
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: selectedViewType, onChange: setSelectedViewType },
  } = useController<BlockSpecificPage<CardModuleBlock>, `${typeof blockPath}.view`>({ name: `${blockPath}.view` });

  return <CardModuleViewTypeSelector cardModuleView={selectedViewType} onChange={setSelectedViewType} />;
};

export default PageBlockCardModuleViewTypeSelector;
