import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../components/Container';
import { ViewType } from '../../declarations/ViewType';
import Loader from '../../components/Loader';
import EmployeesListView from './EmployeesViewTypes/EmployeesListView';
import EmployeesGridView from './EmployeesViewTypes/EmployeesGridView';
import { Employee } from '../../declarations/models/Employee';

export interface CommonEmployeesViewProps {
  items: Array<Employee>;
  onItemClick: (item: Employee) => void;
  onDeleteItem?: (item: Employee) => void;
}

interface EmployeesViewListProps extends CommonEmployeesViewProps {
  isLoading?: boolean;
  selectedViewType: ViewType;
}

export const EmployeesViewList = ({
  isLoading = false,
  items,
  selectedViewType = ViewType.GRID,
  ...commonProps
}: EmployeesViewListProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const renderContent = () => {
    if (isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Loader loadingText={tCommon('loadingContent')} />
        </Container>
      );
    }
    if (!isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Typography color='textSecondary'>{tComponents('MediaList.NoItems')}</Typography>
        </Container>
      );
    }
    switch (selectedViewType) {
      case ViewType.LIST:
        return <EmployeesListView {...(commonProps as CommonEmployeesViewProps)} items={items} />;
      case ViewType.GRID:
        return <EmployeesGridView {...(commonProps as CommonEmployeesViewProps)} items={items} />;
    }
    return null;
  };

  return (
    <Container fullHeight fullWidth column gap={0} left top>
      {renderContent()}
    </Container>
  );
};

export default EmployeesViewList;
