import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import { FormControl, FormLabel, Typography } from '@mui/material';
import { SetAsMainImageCheckbox } from '@/editor/PageEditor/SetAsMainImageCheckbox';
import { WysiwygEditor } from '@/editor/PageEditor/EditorBlock/TextBlock/WysiwygEditor';
import FinderButton, { FinderType } from '@/components/Finder/FinderButton';
import { UserSelectableIconName } from '@/components/UserSelectableIcon';
import { IconFinderButton } from '@/components/Finder/IconFinderButton';
import { AddMediaObject } from '@/components/AddMediaObject';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BannerBackgroundM24MediaModel, BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import { Page } from '@/declarations/models/Page';
import { IconFinderButtonSmall } from '@/components/Finder/IconFInderButtonSmall';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import TextInput from '../../../../components/forms/TextInput';
import SwitchInput from '../../../../components/forms/SwitchInput';
import { TextBlock as TextBlockModel } from '../../../../declarations/models/blocks/TextBlock';
import Links from '../../../../components/Links/Links';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';
import TitleAlignmentSelector from '../CommonTitleAlignmentSelector';

export const TextBlock: BlockComponent = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComp } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const eventManager = useEditorEventManager();

  const {
    field: { value: bodyText, onChange: onChangeBodyText },
  } = useController<BlockSpecificPage<TextBlockModel>, `${typeof blockPath}.bodytext`>({
    name: `${blockPath}.bodytext`,
  });

  const mediaObject = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.mediaobject`>({
    name: `${blockPath}.mediaobject`,
  });

  const {
    field: { value: icon, onChange: onChangeIcon },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.icon`>({
    name: `${blockPath}.icon`,
  });

  const {
    fields: linkItems,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<TextBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  return (
    <Container gap={2} column top left fullWidth>
      <AddMediaObject mediaObject={mediaObject} canEdit>
        <FormControl fullWidth>
          <TextInput
            path={`${blockPath}.mediaobject.local.caption`}
            label={tComp('TextBlock.localCaption')}
            size='small'
            multiline
            minRows={1}
            maxRows={8}
            defaultValue=''
          />
          <FormLabel sx={{ fontSize: 'small' }}>{tComp('TextBlock.localCaptionDescription')}</FormLabel>
        </FormControl>
        <SetAsMainImageCheckbox image={mediaObject as BannerBackgroundM24MediaModel | undefined} />
      </AddMediaObject>
      <Container spaceBetween fullWidth>
        <TextInput
          fullWidth
          path={`${blockPath}.title`}
          label={tCommon('title')}
          defaultValue=''
          InputProps={{
            endAdornment: <TitleAlignmentSelector blockPath={blockPath} />,
          }}
        />
        <Container sx={{ width: '25%' }}>
          <SwitchInput path={`${blockPath}.visibletitle`} label={tCommon('showTitle')} />
        </Container>
      </Container>

      <Container fullWidth column>
        <WysiwygEditor type='tinymce' value={bodyText ?? ''} onChange={onChangeBodyText} blockPath={blockPath} />
      </Container>

      <Container fullWidth column sx={{ border: '1px solid #ccc' }} p={2}>
        <TextInput path={`${blockPath}.itemsTitle`} label={tComp('TextBlock.TitleOverLinkList')} defaultValue='' />
        <Links
          onUpdate={update}
          onAppend={append}
          onRemove={remove}
          onReorder={move}
          linkItems={linkItems}
          showListChildrenInput
          blockPath={blockPath}
          useEventHandler={useEditorEventHandler}
          useEventManager={eventManager}
        />
      </Container>

      <TextInput<Page>
        path={`${blockPath}.tocId`}
        label={tComp('CommonBlockContent.FormLabels.AnchorID')}
        defaultValue=''
      />

      <Container left fullWidth>
        <IconFinderButtonSmall path={blockPath} />
      </Container>
      <Typography variant='caption'>{tComp('TextBlock.IconHelpText')}</Typography>
    </Container>
  );
};

export default TextBlock;
