import React from 'react';
import { useController } from 'react-hook-form';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import MediaViewTypeSelector from '../../lib/components/settings/MediaViewTypeSelector';
import { MediaBlock } from '../../../declarations/models/blocks/MediaBlock';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const PageBlockMediaViewTypeSelector: SettingsComponent = () => {
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: selectedViewType, onChange: setSelectedViewType },
  } = useController<BlockSpecificPage<MediaBlock>, `${typeof blockPath}.view`>({ name: `${blockPath}.view` });

  return <MediaViewTypeSelector mediaViewType={selectedViewType} onChange={setSelectedViewType} />;
};

export default PageBlockMediaViewTypeSelector;
