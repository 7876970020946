import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Container from '../../Container';
import { GenericMedia } from '../../../declarations/GenericMedia';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';
import { KPOwner } from '../../../declarations/models/KP';
import { resolveLocalizedString } from '../../../utils/strings';
import { renderKeyValuePair } from '../DMMediaFinder/SelectedDMMediaItem';

export interface SelectedKPOwnerItemProps {
  item: GenericMedia<KPOwner>;
  onRemoveItem: (item: GenericMedia<KPOwner>) => void;
}

export const SelectedKPOwnerItem: FC<SelectedKPOwnerItemProps> = ({ item, onRemoveItem }) => {
  const { t } = useTranslation('common');
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column top left fullWidth fullHeight>
        <Typography
          sx={{
            wordBreak: 'break-word',
            fontWeight: 'bold',
            overflow: 'hidden',
          }}>
          {item.title}
        </Typography>
        <Container column bottom fullWidth>
          {renderKeyValuePair(t('description'), resolveLocalizedString(i18next.language, item.source.description))}
        </Container>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedKPOwnerItem;
