import React, { FC } from 'react';
import { Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { MediaBlock } from '../../../../declarations/models/blocks/MediaBlock';
import TextInput from '../../../../components/forms/TextInput';
import Container from '../../../../components/Container';
import SwitchInput from '../../../../components/forms/SwitchInput';

export interface CaptionInputProps {
  toggleCaptionVisibilityPath: Path<BlockSpecificPage<MediaBlock>>;
  captionPath: Path<BlockSpecificPage<MediaBlock>>;
  hint?: string;
  placeholder?: string;
}

export const CaptionInput: FC<CaptionInputProps> = ({
  toggleCaptionVisibilityPath,
  captionPath,
  hint,
  placeholder,
}) => {
  const { t } = useTranslation('components');
  return (
    <Container column left fullWidth>
      <SwitchInput<BlockSpecificPage<MediaBlock>>
        path={toggleCaptionVisibilityPath}
        label={t('CaptionInput.HideCaption')}
        fullWidth={false}
      />
      <TextInput<BlockSpecificPage<MediaBlock>>
        path={captionPath}
        label={t('CaptionInput.CaptionLabel')}
        placeholder={placeholder}
        helperText={hint}
        multiline
        fullWidth
      />
    </Container>
  );
};

export default CaptionInput;
