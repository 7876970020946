import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useSnackbar } from 'notistack';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { useStore } from '../../../components/store/Store';
import Container from '../../../components/Container';
import Loader from '../../../components/Loader';
import SiteLocationsSettingsItem from './SiteLocationsSettingsItem';
import { Location } from '../../../declarations/models/Location';
import GoogleMap from '../../../components/GoogleMap';
import { GoogleMapMarker } from '../../../declarations/GoogleMapMarker';
import { useApi } from '../../../hooks/useApi';
import { Api } from '../../../services/Api';

export const SiteLocationsSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();

  const [locations, isLoadingLocations, reloadLocations] = useApi(() => Api.getSiteLocations(selectedSiteId), []);
  const [locationsToRender, setLocationsToRender] = useState<Array<Location>>(locations || []);
  const [markers, setMarkers] = useState<Array<GoogleMapMarker>>([]);

  const handleSaveSettingsForm = useCallback(
    async (l: Location): Promise<void | Location> => {
      const savedFormData = await Api.saveSiteLocation<Location>(l, selectedSiteId).fetchDirect(null);
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        reloadLocations();
        return savedFormData;
      }
    },
    [enqueueSnackbar, reloadLocations, selectedSiteId, tCommon],
  );

  const onDeleteLocation = async (location: Location) => {
    if (!location.id) {
      setLocationsToRender((prev) => {
        const idx = prev.indexOf(location);
        if (idx < 0) {
          return prev;
        }

        return prev.filter((l) => l !== location);
      });

      return;
    }

    if (
      await confirm(tComponents('Settings.SiteSettings.SiteLocationsSettings.deleteConfirmBody'), {
        title: (
          <Typography fontWeight={700} fontSize='x-large' sx={{ my: 2 }}>
            {tComponents('Settings.SiteSettings.SiteLocationsSettings.deleteConfirmTitle')}
          </Typography>
        ),
        renderConfirmButton: (onClick) => (
          <Button onClick={onClick} variant='contained' color='error' startIcon={<MaterialSymbol name='delete' />}>
            {tCommon('delete')}
          </Button>
        ),
      })
    ) {
      try {
        await Api.deleteSiteLocation(selectedSiteId, location.id).fetchDirect(null);
        enqueueSnackbar(tCommon('deleted'), { variant: 'success' });
        reloadLocations();
      } catch (e) {
        enqueueSnackbar(tCommon('deleteFailed'), { variant: 'error' });
      }
    }
  };

  const defaultZoom = 10; // Could probably be replaced with a zoom level based on how far the largest distance between the markers is

  const getAverage = (numbers: (number | undefined)[]): number => {
    const validNumbers = numbers?.filter((num) => typeof num === 'number') as number[];

    if (validNumbers.length === 0) {
      return 0;
    }

    const sum = validNumbers.reduce((acc, num) => acc + num, 0);
    return sum / validNumbers.length;
  };

  const defaultCenter =
    markers?.length > 0
      ? {
          lat: getAverage(markers.map((m) => m?.position?.lat)),
          lng: getAverage(markers.map((m) => m?.position?.lng)),
        }
      : undefined;

  useEffect(() => {
    reloadLocations();
  }, [reloadLocations, selectedSiteId]);

  useEffect(() => {
    setLocationsToRender(locations);
  }, [locations]);

  useEffect(() => {
    setMarkers(
      locationsToRender
        .filter((l) => l.latitude && l.longitude)
        .map((l) => {
          return {
            position: {
              lat: l.latitude ? parseFloat(l.latitude.toString()) : 0,
              lng: l.longitude ? parseFloat(l.longitude.toString()) : 0,
            },
            title: l.title,
          } as GoogleMapMarker;
        }),
    );
  }, [locationsToRender]);

  const renderLocationItems = () => {
    return (
      <Container
        fullWidth
        top
        left
        column
        sx={{
          overflow: 'auto',
          p: 2,
        }}>
        {locationsToRender?.map((location, index) => (
          <SiteLocationsSettingsItem
            key={`${location.id || ''}${index}`} // eslint-disable-line react/no-array-index-key
            location={location}
            defaultCenter={defaultCenter}
            onChange={(m) => {
              setLocationsToRender((prevState) => {
                const updatedMarkers = [...prevState];
                if (index !== -1) {
                  // only update the marker if the location has changed
                  const currentMarker = updatedMarkers[index];
                  if (currentMarker.latitude !== m.latitude || currentMarker.longitude !== m.longitude) {
                    currentMarker.latitude = m.latitude;
                    currentMarker.longitude = m.longitude;
                    return updatedMarkers;
                  }
                }
                // no changes to map position, prevent rerender by returning the previous state
                return prevState;
              });
            }}
            handleSaveSettingsForm={handleSaveSettingsForm}
            onDeleteLocation={onDeleteLocation}
          />
        ))}
      </Container>
    );
  };

  const addLocationButton = () => {
    return (
      <Button
        variant='contained'
        color='secondary'
        startIcon={<MaterialSymbol name='add' />}
        onClick={() => {
          setLocationsToRender([{}].concat(locationsToRender));
        }}>
        {tCommon('add')}
      </Button>
    );
  };

  const renderMap = () => {
    return (
      <Container fullWidth fullHeight column top left gap={0} px={2} sx={{ position: 'sticky' }}>
        <GoogleMap center={defaultCenter} zoom={defaultZoom} markers={markers} />
      </Container>
    );
  };

  return (
    <Container
      left
      top
      fullWidth
      fullHeight
      column
      p={2}
      sx={{
        maxHeight: 'calc(100vh - 220px)',
        minHeight: '300px',
      }}>
      <Typography py={2} variant='h1' fontSize='xx-large'>
        {tComponents(`MainMenu.settings.locations`)}
      </Typography>
      <Typography>{tComponents('Settings.SiteSettings.SiteLocationsSettings.infoText')}</Typography>

      <Container fullWidth fullHeight left top m={2}>
        {isLoadingLocations ? (
          <Loader size={25} />
        ) : (
          <>
            <Container fullWidth fullHeight top left column gap={2} sx={{ flexBasis: '100%' }}>
              {addLocationButton()}
              {renderLocationItems()}
            </Container>
            {renderMap()}
          </>
        )}
      </Container>
    </Container>
  );
};

export default SiteLocationsSettings;
