import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AuthenticationService from '../../services/AuthenticationService';
import Loader from '../Loader';
import Container from '../Container';
import Layout from '../Layout';
import { TranslationResourcesKeys } from '../../i18n/i18n-config';

export const EkulturCallbackHandler: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [error, setError] = useState<
    keyof TranslationResourcesKeys['components']['EkulturCallbackHandler']['errors'] | null
  >(null);

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  useEffect(() => {
    let unmounted = false;

    if (!code || !state) {
      setError('missingCodeOrState');
    } else {
      AuthenticationService.codeToToken(code, state)
        .then((redirectTo) => {
          let path: string;
          try {
            // Remove the origin, but keep the rest of the path
            path = redirectTo.replace(new URL(redirectTo).origin, '');
          } catch (e) {
            path = '';
          }
          navigate(path || '/', { replace: true });
        })
        .catch((reason) => {
          // eslint-disable-next-line no-console
          console.error(reason);
          if (!unmounted) {
            setError('swapForTokenFailed');
          }
        });
    }
    return () => {
      unmounted = true;
    };
  }, [code, state, navigate]);

  let content: ReactNode;

  if (error) {
    content = (
      <>
        <Typography color='error'>{tComponents(`EkulturCallbackHandler.errors.${error}`)}</Typography>
        <Button variant='contained' color='primary' role='link' onClick={() => navigate('/', { replace: true })}>
          {tCommon('tryAgain')}
        </Button>
      </>
    );
  } else {
    content = <Loader loadingText={tComponents('EkulturCallbackHandler.loggingIn')} />;
  }
  return (
    <Layout>
      <Container column fullHeight fullWidth>
        <Paper>
          <Container p={4} column>
            {content}
          </Container>
        </Paper>
      </Container>
    </Layout>
  );
};

export default EkulturCallbackHandler;
