import React, { FC, KeyboardEvent, useRef, useState } from 'react';
import { Avatar, Button, ClickAwayListener, IconButton, Link, Paper, Popover, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useStore } from './store/Store';
import Container from './Container';
import AppLanguageSelector from './AppLanguageSelector';
import { Settings } from '../Settings';
import Styles from '../assets/js/Styles';
import { ChildrenProp } from '../declarations/ChildrenProp';

const AvatarMenuContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.background.paper),
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  gridTemplateRows: 'repeat(6, min-content)',
  gridTemplateAreas: `
    "avatar             name"
    "settingsLink       settingsLink"
    "ownerSettingsLink  ownerSettingsLink"
    "menuFooter         menuFooter"
  `,
}));

export const UserAvatarMenu: FC<ChildrenProp> = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation(['aria', 'components']);
  const { state } = useStore();
  const { appVersion, currentUser } = state;
  const navigate = useNavigate();

  const [avatarMenuOpen, setAvatarMenuOpen] = useState<boolean>(false);

  const closeMenu = () => setAvatarMenuOpen(false);

  const handleEscapePressed = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeMenu();
      event.stopPropagation();
    }
  };

  const currentUserName = currentUser?.user?.name || currentUser?.cura_data?.primary_email || 'anonymous';
  const currentEmail = currentUser?.cura_data?.primary_email || 'No email address';
  const currentUuid = currentUser?.user?.cura_uuid || 'No uuid';

  const currentUserAvatar = (
    <MaterialSymbol
      name='account_circle'
      fill
      color='secondary'
      sx={{
        fontSize: '40px',
      }}
    />
  );

  return (
    <>
      <IconButton
        ref={buttonRef}
        aria-label={t('aria:components.UserAvatarMenu.toggleUserMenu')}
        onClick={() => setAvatarMenuOpen((o) => !o)}>
        {currentUserAvatar}
      </IconButton>
      <Popover
        open={avatarMenuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onKeyDown={handleEscapePressed}>
        <ClickAwayListener onClickAway={closeMenu}>
          <Paper elevation={3}>
            <AvatarMenuContainer gap={1} pl={2} pt={2} pr={1} pb={1} fullWidth>
              <Container pr={1} sx={{ gridArea: 'avatar' }} fullWidth>
                {currentUserAvatar}
              </Container>
              <Container sx={{ gridArea: 'name' }} left fullWidth column>
                <Link variant='button' color='inherit' href={Settings.EKULTUR_PROFILE_PATH} target='_blank'>
                  {currentUserName}
                </Link>
                <Typography fontSize={12}>
                  {currentEmail}
                  <br />
                  {currentUuid}
                </Typography>
              </Container>
              <Container sx={{ gridArea: 'settingsLink' }} left fullWidth>
                <Link component={RouterLink} color='inherit' to='/settings'>
                  {t('components:UserAvatarMenu.settings', { site: state.selectedSite?.name || '?' })}
                </Link>
              </Container>
              <Container sx={{ gridArea: 'ownerSettingsLink' }} left fullWidth>
                <Link component={RouterLink} color='inherit' to='/settings/owner'>
                  {t('components:UserAvatarMenu.ownerSettings', { owner: state.selectedSite?.owner?.name || '?' })}
                </Link>
              </Container>
            </AvatarMenuContainer>
            <Container
              py={1}
              px={2}
              sx={{ gridArea: 'menuFooter', backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY }}
              spaceBetween
              fullWidth>
              <Typography color='text.secondary'>v{appVersion}</Typography>
              <Container right fullWidth>
                <AppLanguageSelector color='secondary' />
                <Button variant='outlined' color='secondary' onClick={() => navigate('/logout')}>
                  {t('components:UserAvatarMenu.logOut')}
                </Button>
              </Container>
            </Container>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

export default UserAvatarMenu;
