import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Draggable } from '@/declarations/Draggable';
import { Employee } from '@/declarations/models/Employee';
import { Api } from '@/services/Api';
import DraggableContainer, { DraggableContainerProps } from '../../../../components/DraggableContainer';
import EmployeesBlockActions from './EmployeesBlockActions';
import Container from '../../../../components/Container';
import Styles from '../../../../assets/js/Styles';
import ListImage from '../../../../components/ListImage';

interface EmployeesBlockItemProps<T>
  extends Pick<DraggableContainerProps<T>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'> {
  siteId?: number;
  dragAndDropDisabled?: boolean;
  itemPathPrefix: string;
}

export const EmployeesBlockItem = <T extends Partial<Employee>>({
  index,
  onReorder,
  onRemove,
  onAdd,
  siteId,
  dragAndDropDisabled = false,
  itemPathPrefix: propItemPathPrefix,
}: EmployeesBlockItemProps<T>) => {
  const { t } = useTranslation('common');
  const itemPathPrefix = `${propItemPathPrefix}.${index}`;
  const [employee, setEmployee] = useState<Employee>();

  const {
    field: { value },
  } = useController({
    name: itemPathPrefix,
  });
  const item: T = value as T;

  useEffect(() => {
    if (item.id && siteId) {
      const ctx = Api.getEmployeeSimple(item.id);
      ctx
        .fetchDirect(null)
        .then((e) => e && setEmployee(e))
        .finally(ctx.abort);
    }
  }, [item, siteId]);

  return (
    <DraggableContainer<T>
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      type={Draggable.EMPLOYEE}
      value={item}
      disabled={dragAndDropDisabled}
      secondaryAction={<EmployeesBlockActions onDelete={() => onRemove?.(index)} />}
      headerContent={
        <Container left fullWidth>
          <ListImage
            src={employee?.resource?.url}
            alt={employee?.resource?.content?.alttext}
            fallbackImage={<MaterialSymbol name='person' />}
          />
          <Container left fullWidth wrap>
            {employee ? (
              <Typography>{`${employee.first_name} ${employee.last_name}`}</Typography>
            ) : (
              <Typography color={Styles.Colors.MEDIUM_GREY} fontStyle='italic'>
                {t('loading')}
              </Typography>
            )}
          </Container>
        </Container>
      }
    />
  );
};

export default EmployeesBlockItem;
