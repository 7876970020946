import React from 'react';
import { BlockComponent } from '@/editor/lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import Container from '@/components/Container';
import TextInput from '@/components/forms/TextInput';
import SwitchInput from '@/components/forms/SwitchInput';
import DateTimeInput from '@/components/forms/DateTimeInput';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';

export const DatelineBlock: BlockComponent = () => {
  const { blockPath } = useCurrentBlock();
  const { t: tComponents } = useTranslation('components');

  // In 1.0, you could change between subtypes.
  // Here we are just simulating the selection, it will always be dateline.
  const [selectedSubType, setSelectedSubType] = React.useState<'dateline' | 'metadata'>('dateline');

  return (
    <>
      <Container fullWidth left top>
        <RadioGroup
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
          }}
          onChange={(e) => setSelectedSubType(e.target.value as any)}
          value={selectedSubType}>
          <FormControlLabel value='dateline' control={<Radio />} label={tComponents('DatelineBlock.Dateline')} />
          <FormControlLabel value='metadata' control={<Radio />} label={tComponents('DatelineBlock.Metadata')} />
        </RadioGroup>
      </Container>
      {selectedSubType === 'dateline' && (
        <>
          <Typography>{tComponents('DatelineBlock.DatelineDescription')}</Typography>
          <Container fullWidth left top>
            <TextInput path={`${blockPath}.text`} label={tComponents('DatelineBlock.LabelText')} fullWidth={false} />
            <DateTimeInput
              isLoading={false}
              path='published_at'
              label={tComponents('DatelineBlock.LabelPublishedDate')}
              fullWidth
            />
            <SwitchInput
              path={`${blockPath}.showTime`}
              label={tComponents('DatelineBlock.LabelShowTime')}
              fullWidth={false}
            />
          </Container>
        </>
      )}
      {selectedSubType === 'metadata' && (
        <Container
          fullWidth
          left
          top
          sx={{
            backgroundColor: Styles.Colors.LIGHTEST_BLUE,
            p: 2,
          }}>
          <Typography>{tComponents('DatelineBlock.MetadataDescription')}</Typography>
        </Container>
      )}
    </>
  );
};
