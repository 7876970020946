import React, { FC } from 'react';
import { CommonMediaListViewTypeProps } from '@/components/MediaList/CommonMediaListViewTypeProps';
import { GenericMedia } from '@/declarations/GenericMedia';
import { Employee } from '@/declarations/models/Employee';
import EmployeesGridViewItem from '@/views/EmployeesView/EmployeesViewTypes/EmployeesGridViewItem';
import { noop } from '@/utils/functions';

export interface GridViewEmployeeVariantProps extends Omit<CommonMediaListViewTypeProps, 'items'> {
  item: GenericMedia;
}

export const GridViewEmployeeVariant: FC<GridViewEmployeeVariantProps> = ({ item }) => {
  const employee = item.source as Employee;
  return <EmployeesGridViewItem item={employee} onItemClick={noop} disableClick />;
};
