import React, { useCallback, useState } from 'react';
import {
  InputLabelProps as MuiInputLabelProps,
  InputProps as MuiInputProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FieldValues } from 'react-hook-form';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';
import { useDebounce } from '../../hooks/useDebounce';

export interface TextInputProps<T>
  extends FormInputBaseProps<T>,
    Pick<TextFieldProps, 'autoComplete' | 'multiline' | 'rows' | 'minRows' | 'maxRows'>,
    Pick<TextFieldProps, 'placeholder' | 'helperText' | 'size' | 'inputProps'> {
  type?: 'text' | 'number' | 'time';
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  handleOnChange?: (e: string) => void;
  maxLength?: number;
  inputFormatter?: (e: string) => string;
}

export default function TextInput<T extends FieldValues>({
  type = 'text',
  autoComplete = 'off',
  multiline = false,
  rows,
  minRows = 3,
  maxRows = 10,
  placeholder,
  helperText,
  InputProps,
  InputLabelProps,
  maxLength,
  handleOnChange,
  size = 'medium',
  inputProps,
  inputFormatter,
  ...baseProps
}: TextInputProps<T>) {
  const {
    field: { ref, name, value, onChange, onBlur },
    variant,
    color,
    isError,
    errorMessage,
    label,
    required,
    disabled,
    fullWidth,
    defaultValue,
  } = useFormInput<T>(baseProps);

  const [internalState, setInternalState] = useState<typeof value>(
    (value || defaultValue) ?? (undefined as typeof value),
  );
  const internalOnChange = useCallback(
    (e: typeof value) => {
      if (inputFormatter) {
        return setInternalState(inputFormatter(e) as typeof value);
      }
      return setInternalState(e);
    },
    [setInternalState, inputFormatter],
  );

  useDebounce(800, internalState, onChange);

  return (
    <TextField
      ref={ref}
      name={name}
      value={internalState}
      onChange={(e) => {
        internalOnChange((e.target.value || '') as typeof value);
        if (handleOnChange) handleOnChange(e.target.value || '');
      }}
      onBlur={onBlur}
      type={type}
      variant={variant}
      color={color}
      label={label}
      placeholder={placeholder}
      error={isError}
      helperText={errorMessage || helperText}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      multiline={multiline}
      rows={rows}
      minRows={rows == null ? minRows : undefined}
      maxRows={rows == null ? maxRows : undefined}
      size={size}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      inputProps={{
        ...inputProps,
        form: {
          autocomplete: 'off',
        },
        maxLength: maxLength || undefined,
      }}
    />
  );
}
