import React, { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import Styles from '@/assets/js/Styles';
import { Icon } from '@mui/material';
import { Draggable } from '@/declarations/Draggable';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { LinkExternal, M24MediaLinkItem } from '@/declarations/models/LinkExternal';
import { LinkType } from '@/declarations/models/LinkType';
import { Page } from '@/declarations/models/Page';
import { ChildrenProp } from '@/declarations/ChildrenProp';
import { EditorEvent, EventManagerApi } from '@/editor/lib/eventManager/EditorEventManager';
import DraggableCollapseContainer from '../DraggableCollapseContainer';
import LinkListItemHeader from './LinkListItemHeader';
import LinkListItemActions from './LinkListItemActions';
import LinkListItemContent from './LinkListItemContent';
import LinkListItemContentForm from './LinkListItemContentForm';
import { MaterialSymbol } from '../MaterialSymbol';

export interface LinkListItemProps<T> extends ChildrenProp {
  /**
   * The type of link. 'link', 'page', 'document' etc.
   */
  linkType: LinkType;
  /**
   * Used when `draggable` is set to true.
   *
   * The type should be unique for each resource. Given the same type, sections can be moved across containers.
   */
  type?: Draggable;
  /**
   * Used when `draggable` is set to true.
   *
   * The current index of this item
   */
  index: number;
  /**
   * Used when `draggable` is set to true.
   *
   * The value of this section.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  value?: T;
  /**
   * Requires `index`
   * Used when `draggable` is set to true.
   *
   * Callback to actually perform the reorder.
   * This is only called when an item is moved WITHIN the same container, meaning only it's position is changed.
   * To accept items from OTHER containers as well, implement the `onRemove()`- AND `onAdd` callbacks.
   *
   * @param fromIndex The item's old index
   * @param toIndex The item's new index
   */
  onReorder: (fromIndex: number, toIndex: number) => void;
  /**
   * Requires `index` and `deletable = true`
   *
   * Called when the user requests to PERMANENTLY DELETE a section.
   *
   * NOTE: Do not confuse this callback with the `onRemove` callback
   *
   * @param index The index of the section do permanently delete
   */
  onDelete: (index: number) => void;
  onChangeFile: (index: number) => void;
  itemsArrayName: string;
  /**
   * Requires `local.description`
   */
  showLinkDescriptionField: boolean;
  showCSSclassField?: boolean;
  blockPath: BlockPath | `${BlockPath}.${string}.${number}`;
  useEventHandler?: (handler: (event: EditorEvent) => Promise<void> | void) => void;
  useEventManager?: EventManagerApi;
  iconOverride?: ReactNode;
  color?: string;
  headerSubTitle?: ReactNode;
}

export type LinkListItemPathPrefix =
  | `${BlockPath}.${string}.${number}`
  | `${BlockPath}.${string}.${number}.${string}.${number}`;

export default function LinkListItem<T>({
  children,
  linkType,
  index,
  type = Draggable.ITEM,
  onReorder,
  onDelete,
  onChangeFile,
  itemsArrayName,
  showLinkDescriptionField,
  showCSSclassField = false,
  blockPath,
  useEventHandler,
  useEventManager,
  iconOverride,
  color = 'white',
  headerSubTitle,
}: PropsWithChildren<LinkListItemProps<T>>) {
  const { t } = useTranslation('common');

  const itemPathPrefix: LinkListItemPathPrefix = `${blockPath}.${itemsArrayName}.${index}`;

  const {
    field: { value },
  } = useController<
    BlockSpecificPage<BaseBlock & { [key: string]: Array<LinkExternal | M24MediaLinkItem | Page> }>,
    typeof itemPathPrefix
  >({
    name: itemPathPrefix,
  });

  const renderTypeIcon = (iconType: LinkType) => {
    switch (iconType) {
      case LinkType.PAGE:
        return (value as Page)?.image_src ? (
          <img
            src={(value as Page)?.image_src}
            alt={(value as Page)?.title}
            style={{
              width: '50px',
              height: 'auto',
              aspectRatio: '1/1',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : (
          <div
            style={{
              backgroundColor: Styles.Colors.MEDIUM_GREY,
              width: '50px',
              height: '50px',
            }}
          />
        );
      case LinkType.LINK:
        return (
          <Icon sx={{ marginLeft: '0', width: '40px' }}>
            <MaterialSymbol name='link' />
          </Icon>
        );
      case LinkType.DOCUMENT:
        return (
          <Icon sx={{ marginLeft: '0', width: '40px' }}>
            <MaterialSymbol name='picture_as_pdf' />
          </Icon>
        );
    }
  };

  const getInitialAnchorValue = () => {
    if (linkType === LinkType.PAGE) {
      const l = value as Page;
      return l?.local?.anchor || '';
    }
    if (linkType === LinkType.LINK) {
      const l = value as LinkExternal;
      return l?.anchor || '';
    }
    return '';
  };

  return (
    <DraggableCollapseContainer<T>
      unmountOnExit
      type={type}
      headerContent={
        // eslint-disable-next-line react/no-unstable-nested-components
        () => (
          <LinkListItemHeader item={value} icon={iconOverride || renderTypeIcon(linkType)} subTitle={headerSubTitle} />
        )
      }
      index={index}
      onReorder={onReorder}
      secondaryAction={<LinkListItemActions onDelete={() => onDelete?.(index)} />}
      useEventManager={useEventManager}
      useEventHandler={useEventHandler}
      color={color}>
      <>
        {children}
        {!children && (
          <LinkListItemContent item={value}>
            <LinkListItemContentForm
              index={index}
              showLinkDescriptionField={showLinkDescriptionField}
              showCSSclassField={showCSSclassField}
              onChangeFile={onChangeFile}
              linkType={linkType}
              itemsArrayName={itemsArrayName}
              initialAnchorValue={getInitialAnchorValue()}
              blockPath={blockPath}
            />
          </LinkListItemContent>
        )}
      </>
    </DraggableCollapseContainer>
  );
}
