import { BaseBlock } from '../declarations/models/blocks/BaseBlock';
import { Section } from '../declarations/models/Section';
import { SectionType } from '../declarations/models/SectionType';
import { BlockType } from '../declarations/models/BlockType';
import { SectionModelFactory } from '../editor/lib/factories/SectionModelFactory';
import { BlockModelFactory } from '../editor/lib/factories/BlockModelFactory';

export const getIndex = (array: Array<Section | BaseBlock>, type: SectionType | BlockType) => {
  return array.findIndex((obj) => obj.type === type);
};

export const getOrCreateSettingsContentSectionIndex = (
  type: SectionType,
  append: (section: Section) => void,
  sections: Array<Section>,
) => {
  const index = getIndex(sections, type);
  if (index === -1) {
    append(SectionModelFactory.makeSectionModel(type));
    getOrCreateSettingsContentSectionIndex(type, append, sections);
  }
  return index;
};

export const getOrCreateSettingsContentSectionBlockIndex = (
  sectionIndex: number,
  type: BlockType,
  sections: Array<Section>,
) => {
  const index = getIndex(sections[sectionIndex].blocks, type);
  if (index === -1) {
    sections[sectionIndex].blocks.push(BlockModelFactory.makeBlockModel(type));
    getOrCreateSettingsContentSectionBlockIndex(sectionIndex, type, sections);
  }
  return index;
};
