import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Grow, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { GenericMedia } from '../../declarations/GenericMedia';
import Container from '../Container';
import Image from '../Image';
import { ScreenSize, useScreenSize } from '../../hooks/useScreenSize';

export interface SelectedMediaItemContainerProps<T> {
  item: GenericMedia<T>;
  onRemoveItem: (item: GenericMedia<T>) => void;
}

/**
 * Used to render a selected Media Item (in the finder) in a uniform way.
 * @param children
 * @param item
 * @param onRemoveItem
 * @constructor
 */
export const SelectedMediaItemContainer = <T,>({
  children,
  item,
  onRemoveItem,
}: PropsWithChildren<SelectedMediaItemContainerProps<T>>) => {
  const container = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('common');
  const { isScreenSmallerThan } = useScreenSize();
  const isSmallScreen = isScreenSmallerThan(ScreenSize.LG);

  useEffect(() => {
    container.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  let imgMimeType = '';
  if (item?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item?.mimetype}`;
  }

  return (
    <Grow ref={container} in>
      <Container
        // istanbul ignore next
        gap={0}
        sx={(theme) => ({
          px: 1,
          pb: 1,
          '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        })}
        column={isSmallScreen}
        top
        spaceBetween={!isSmallScreen}
        fullWidth>
        <Container m={!isSmallScreen ? '0 8px 0 0' : '0 0 8px 0'} sx={{ width: isSmallScreen ? '100%' : '180px' }}>
          <Image src={`${item.url}?dimension=250x250&${imgMimeType}`} maxHeight='120px' maxWidth='100%' />
        </Container>
        {children}

        <IconButton
          sx={isSmallScreen ? { order: -1, alignSelf: 'flex-end' } : undefined}
          color='error'
          onClick={() => onRemoveItem(item)}
          aria-label={t('removeX', { x: item.title })}>
          <MaterialSymbol name='cancel' />
        </IconButton>
      </Container>
    </Grow>
  );
};

export default SelectedMediaItemContainer;
