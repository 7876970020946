import { QueryParam, QueryParams } from '@/declarations/QueryParams';
import { Settings } from '@/Settings';
import { RequestContext, RequestMapper } from './RequestContext';

/**
 * Creates a new request, and returns the created RequestContext
 */
export abstract class ApiRequest<T, R = T> {
  private readonly headers: Headers = new Headers();

  private readonly requestSettings: RequestInit = { credentials: 'omit' };

  private readonly queryParams: URLSearchParams = new URLSearchParams();

  private readonly api: 'admin' | 'portal';

  private readonly pathPrefix: string;

  private readonly path: Array<string | number>;

  private responseMapper?: RequestMapper<T, R>;

  protected constructor(api: 'admin' | 'portal', pathPrefix: string, path: Array<string | number>) {
    this.api = api;
    this.path = path;
    this.pathPrefix = pathPrefix;
  }

  public withAuth(): ApiRequest<T, R> {
    const token = localStorage.getItem(Settings.TOKEN_LOCAL_STORAGE_KEY);
    const id_token = localStorage.getItem(Settings.ID_TOKEN_LOCAL_STORAGE_KEY);
    if (token && id_token) {
      this.headers.set('Authorization', `Bearer ${token}`);
      this.headers.set('id-token', id_token);
      this.requestSettings.credentials = 'include';
    }
    return this;
  }

  public withParams(params?: QueryParams): ApiRequest<T, R> {
    if (!params) {
      return this;
    }
    Object.entries(params).forEach(([paramName, paramValue]) => {
      const append = (value: QueryParam) =>
        this.queryParams.append(paramName, value !== null && value !== undefined ? String(value) : '');
      if (Array.isArray(paramValue)) {
        paramValue.forEach(append);
      } else {
        append(paramValue);
      }
    });
    return this;
  }

  public withFormData(formData: FormData): ApiRequest<T, R> {
    this.requestSettings.body = formData;
    return this;
  }

  public withBody(body: Partial<T> | Array<Partial<T>> | string): ApiRequest<T, R> {
    if (typeof body === 'string') {
      this.headers.set('Content-Type', 'text/plain');
      this.requestSettings.body = body;
    } else {
      this.headers.set('Content-Type', 'application/json');
      this.requestSettings.body = JSON.stringify(body);
    }
    return this;
  }

  public withMapper(mapper: RequestMapper<T, R>): ApiRequest<T, R> {
    this.responseMapper = mapper;
    return this;
  }

  protected getRequestUrl(): string {
    const prefix = this.pathPrefix.split('/').filter((p) => !!p);
    const url = [Settings.ADMIN_API_HOST, ...prefix, ...this.path].join('/');
    const qp = this.queryParams.toString();
    return qp ? `${url}?${qp}` : url;
  }

  private fetch(method: 'get' | 'put' | 'post' | 'delete'): RequestContext<T, R> {
    const controller = new AbortController();
    const request = fetch(this.getRequestUrl(), {
      ...this.requestSettings,
      headers: this.headers,
      method,
      signal: controller.signal,
    });
    return new RequestContext<T, R>(request, controller, this.responseMapper);
  }

  public get(): RequestContext<T, R> {
    return this.fetch('get');
  }

  public put(): RequestContext<T, R> {
    return this.fetch('put');
  }

  public post(): RequestContext<T, R> {
    return this.fetch('post');
  }

  public delete(): RequestContext<T, R> {
    return this.fetch('delete');
  }
}
