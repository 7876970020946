/* istanbul ignore file */

export enum MediaViewType {
  LIST = 'list',
  LIST_NARROW = 'list-narrow',
  LIST_WIDE = 'list-wide',
  LIST_FULL = 'list-full',
  GRID = 'grid',
  GRID_NARROW = 'grid-narrow',
  GRID_WIDE = 'grid-wide',
  GRID_FULL = 'grid-full',
  GRID_PACKERY = 'grid__packery',
  GRID_PACKERY_NARROW = 'grid__packery-narrow',
  GRID_PACKERY_WIDE = 'grid__packery-wide',
  GRID_PACKERY_FULL = 'grid__packery-full',
  SLIDESHOW = 'slideshow',
  SLIDESHOW_NARROW = 'slideshow-narrow',
  SLIDESHOW_WIDE = 'slideshow-wide',
  SLIDESHOW_FULL = 'slideshow-full',
  COMPARE_TWO_IMAGES = 'compareTwoImages',
  COMPARE_TWO_IMAGES_NARROW = 'compareTwoImages-narrow',
  COMPARE_TWO_IMAGES_WIDE = 'compareTwoImages-wide',
  COMPARE_TWO_IMAGES_FULL = 'compareTwoImages-full',
}
