import Container from '@/components/Container';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import { useStore } from '@/components/store/Store';
import { Api } from '@/services/Api';
import { ModuleCountResponse } from '@/declarations/models/CountResponses';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '@/views/DashboardView/widgets/DescriptionList';

export const FormsWidget = () => {
  const {
    state: { selectedSite },
  } = useStore();
  const { t: tComponents } = useTranslation('components');
  const [formCounts, setFormCounts] = useState<ModuleCountResponse | undefined>(undefined);
  useEffect(() => {
    if (selectedSite) {
      Api.getModuleCount(selectedSite.id!, 'form').fetchDirect(undefined).then(setFormCounts);
    } else {
      setFormCounts(undefined);
    }
  }, [selectedSite]);

  const dl: [string, number][] = [
    [tComponents(`DashboardView.CountResLabel.in_use`), formCounts?.in_use ?? 0],
    [tComponents(`DashboardView.CountResLabel.total`), formCounts?.total ?? 0],
  ];

  return (
    <Container
      fullWidth
      fullHeight
      column
      left
      top
      p={2}
      sx={{
        border: `1px solid ${Styles.Colors.MEDIUM_YELLOW}`,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Container spaceBetween fullWidth>
        <Typography fontSize='1.25rem' fontWeight={500}>
          {tComponents('DashboardView.FormsWidget.Title')}
        </Typography>
        <MaterialSymbol name='assignment' sx={{ fontSize: '2rem' }} />
      </Container>
      <Container fullWidth column left>
        <DescriptionList items={dl} />
      </Container>
    </Container>
  );
};
