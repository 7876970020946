import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { RequestContext } from '../utils/ApiRequest/RequestContext';
import BaseModel from '../declarations/models/BaseModel';
import { useStore } from './store/Store';
import Layout from './Layout';
import Container from './Container';
import Loader from './Loader';

export interface SettingsViewFormDataProviderProps<Model extends object> {
  loadModel?: () => RequestContext<never, Model>;
}

export interface SettingsViewFormData {
  siteId: number;
}

const Data = createContext<SettingsViewFormData | null>(null);

/**
 * Access the data loaded in context of the settings form.
 *
 */
export function useSettingsFormData(): SettingsViewFormData {
  const data = useContext(Data);
  if (!data) {
    throw new Error('[SettingsViewFormDataProvider] Data accessed before loaded/initialized');
  }
  return data;
}

/**
 * Utility-hook to quickly reference the current value of the form
 */
export function useSettingsFormModel<Model extends object = BaseModel>(): Model {
  return useWatch() as unknown as Model;
}

/**
 * Responsible for loading and providing all required async/dynamic data
 * @param children
 * @param idPathParamName
 * @param loadModel
 * @constructor
 */
export const SettingsViewFormDataProvider = <Model extends BaseModel>({
  children,
  loadModel,
}: PropsWithChildren<SettingsViewFormDataProviderProps<Model>>) => {
  const { t } = useTranslation('common');
  const loadModelRef = useRef<typeof loadModel>(loadModel);
  loadModelRef.current = loadModel;

  const { state, changeSiteContext } = useStore();

  const siteId = Number(state?.selectedSite?.id || 0);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { reset } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tCommon } = useTranslation('common');

  const data = useMemo<SettingsViewFormData>(
    () => ({
      siteId,
    }),
    [siteId],
  );

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    const cancelFuncs: Array<() => void> = [];

    const reloadModel = async (): Promise<Model | null> => {
      if (siteId && siteId > 0 && loadModelRef.current) {
        const load = loadModelRef.current();
        cancelFuncs.push(load.abort);
        return load.fetchDirect(null);
      }
      return null;
    };

    let newModel: Model | undefined;

    Promise.all([reloadModel()] as const)
      .then(([loadedModel]) => {
        if (!unmounted) {
          const loadedPage = loadedModel as Model;
          reset(loadedPage);
        }
      })
      .finally(() => {
        if (!unmounted && !newModel) {
          setIsLoading(false);
        }
      });

    return () => {
      setIsLoading(false);
      unmounted = true;
      cancelFuncs?.forEach((abort) => abort?.());
    }; // TODO: does changeSiteContext and enqueueSnackbar have a reason for being deps here?
  }, [siteId, changeSiteContext, enqueueSnackbar, tCommon, reset]);

  if (isLoading) {
    return (
      <Container fullWidth pt={10} top column>
        <Loader loadingText={t('loadingContent')} />
      </Container>
    );
  }

  return <Data.Provider value={data}>{children}</Data.Provider>;
};

export default SettingsViewFormDataProvider;
