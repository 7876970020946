import React, { FC } from 'react';
import { FormControl, FormLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SetAsMainImageCheckbox } from '@/editor/PageEditor/SetAsMainImageCheckbox';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { AddMediaObject } from '../../../../components/AddMediaObject';
import Container from '../../../../components/Container';
import SwitchInput from '../../../../components/forms/SwitchInput';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { BannerBackgroundM24MediaModel, BannerBlock } from '../../../../declarations/models/blocks/BannerBlock';
import SliderInput from '../../../../components/forms/SliderInput';
import TextInput from '../../../../components/forms/TextInput';

const VideoBannerExplanation: FC = () => {
  const { t } = useTranslation('components');

  return (
    <Container
      fullWidth
      left
      top
      gap={2}
      sx={{
        backgroundColor: Styles.Colors.LIGHT_BLUE,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
        padding: 1,
        color: 'black',
      }}>
      <Container>
        <MaterialSymbol
          name='movie'
          sx={{
            fontSize: '3rem',
          }}
        />
      </Container>
      <Container
        left
        sx={{
          pt: 0.5,
          flexGrow: 1,
        }}>
        <Typography variant='body2'>{t('BannerBlock.VideoBannerExplanation')}</Typography>
      </Container>
    </Container>
  );
};

export const BannerBlockMediaBackground: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');

  const mediaObject = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.mediaobject`>({
    name: `${blockPath}.mediaobject`,
  });

  return (
    <AddMediaObject
      variant='banner'
      mediaObject={mediaObject}
      key={mediaObject?.url}
      endChildren={mediaObject?.type === 'video' ? <VideoBannerExplanation /> : undefined}>
      <Container column left fullWidth gap={0}>
        {mediaObject?.type === 'video' ? (
          <SwitchInput<BlockSpecificPage<BannerBlock>>
            fullWidth={false}
            color='primary'
            path={`${blockPath}.mediaobject.local.hideControls`}
            label={t('BannerBlock.BannerMediaBackground.hideVideoControls')}
          />
        ) : null}

        <SwitchInput<BlockSpecificPage<BannerBlock>>
          color='primary'
          fullWidth={false}
          path={`${blockPath}.mediaobject.alpha_on`}
          label={t('BannerBlock.BannerMediaBackground.overlay')}
        />
        {!!mediaObject?.alpha_on && (
          <SliderInput
            color='primary'
            path={`${blockPath}.mediaobject.alpha__strength` as `${BlockPath}.mediaobject.alpha__strength`}
            defaultValue={50}
            label={mediaObject?.alpha__strength || '50'}
            valueLabel='%'
            size='small'
            min={0}
            max={100}
            step={5}
          />
        )}
        <SwitchInput<BlockSpecificPage<BannerBlock>>
          color='primary'
          fullWidth={false}
          path={`${blockPath}.mediaobject.vignette_on`}
          label={t('BannerBlock.BannerMediaBackground.vignette')}
        />
        {!!mediaObject?.vignette_on && (
          <>
            <SliderInput
              path={`${blockPath}.mediaobject.vignette__size` as `${BlockPath}.mediaobject.vignette__size`}
              color='primary'
              defaultValue={50}
              label={mediaObject?.vignette__size || '50'}
              valueLabel='%'
              size='small'
              min={-100}
              max={100}
              step={5}
            />
            <SliderInput
              path={`${blockPath}.mediaobject.vignette__strength` as `${BlockPath}.mediaobject.vignette__strength`}
              color='primary'
              defaultValue={50}
              label={mediaObject?.vignette__strength || '50'}
              valueLabel='%'
              size='small'
              min={0}
              max={100}
              step={5}
            />
          </>
        )}
        <SwitchInput<BlockSpecificPage<BannerBlock>>
          path={`${blockPath}.mediaobject.gradient_on`}
          fullWidth={false}
          color='primary'
          label={t('BannerBlock.BannerMediaBackground.gradient')}
        />
        {!!mediaObject?.gradient_on && (
          <>
            <SliderInput
              path={`${blockPath}.mediaobject.gradient__size` as `${BlockPath}.mediaobject.gradient__size`}
              color='primary'
              defaultValue={50}
              label={mediaObject?.gradient__size || '50'}
              valueLabel='%'
              size='small'
              min={-100}
              max={100}
              step={5}
            />
            <SliderInput
              path={`${blockPath}.mediaobject.gradient__strength` as `${BlockPath}.mediaobject.gradient__strength`}
              color='primary'
              defaultValue={50}
              label={mediaObject?.gradient__strength || '50'}
              valueLabel='%'
              size='small'
              min={0}
              max={100}
              step={5}
            />
          </>
        )}
        <Container left column fullWidth gap={2} mt={1}>
          <FormControl fullWidth>
            <TextInput
              path={`${blockPath}.mediaCredits`}
              label={
                <Typography
                  sx={{
                    color: Styles.Colors.DARK_GREY,
                    fontSize: 'small',
                  }}>
                  {t('BannerBlock.BannerMediaBackground.mediaCredits')}
                </Typography>
              }
              size='small'
              defaultValue=''
            />
            <FormLabel sx={{ fontSize: 'small', color: 'white' }}>
              {t('BannerBlock.BannerMediaBackground.mediaCreditsDescription')}
            </FormLabel>
          </FormControl>
        </Container>
      </Container>
      <SetAsMainImageCheckbox image={mediaObject as BannerBackgroundM24MediaModel | undefined} />
    </AddMediaObject>
  );
};
