import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { GenericMedia } from '../../../declarations/GenericMedia';
import Container from '../../Container';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';
import { Employee } from '../../../declarations/models/Employee';

export interface SelectedEmployeeItemProps {
  item: GenericMedia<Employee>;
  onRemoveItem: (item: GenericMedia<Employee>) => void;
}

export const SelectedEmployeeItem: FC<SelectedEmployeeItemProps> = ({ item, onRemoveItem }) => {
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column spaceBetween left fullWidth fullHeight>
        <Typography sx={{ wordBreak: 'break-word' }} fontWeight='bold'>
          {item.title}
        </Typography>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedEmployeeItem;
