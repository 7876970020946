import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import {
  ExhibitionCategory,
  InformationCategory,
  KnowledgeCategory,
  MainCategory,
  SubCategory,
} from '../../../../declarations/Category';
import SelectInput from '../../../../components/forms/SelectInput';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { BlockPath } from '../../CurrentBlockProvider';
import PageAutoSubCategoriesSelect from './PageAutoSubCategoriesSelect';
import { PageListAutoBlock as PageListAutoBlockModel } from '../../../../declarations/models/blocks/PageListBlocks';

interface PageListCategorySettingsProps {
  blockPath: BlockPath;
}

export const PageListCategorySettings: FC<PageListCategorySettingsProps> = ({ blockPath }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [subOptions, setSubOptions] = useState<Array<SubCategory>>([]);

  const mainCategory = useWatch<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.mainCategory`>({
    name: `${blockPath}.mainCategory`,
  });

  const {
    field: { value: subCategories, onChange: onChangeSubCategories },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.subCategories`>({
    name: `${blockPath}.subCategories`,
  });

  useEffect(() => {
    if (mainCategory === MainCategory.EXHIBITION) setSubOptions(Object.values(ExhibitionCategory));
    else if (mainCategory === MainCategory.INFORMATION) setSubOptions(Object.values(InformationCategory));
    else if (mainCategory === MainCategory.KNOWLEDGE) setSubOptions(Object.values(KnowledgeCategory));
    else setSubOptions([]);
  }, [mainCategory, onChangeSubCategories]);

  return (
    <>
      <SelectInput
        options={Object.values(MainCategory).filter((c) => c !== MainCategory.EVENT)}
        getOptionKey={(c) => c ?? ''}
        getOptionLabel={(c) => tCommon(`MainCategory.${c ?? ''}`)}
        defaultValue={[]}
        hideNoSelectionOption
        onChange={() => onChangeSubCategories([])}
        path={`${blockPath}.mainCategory`}
        label={tComp('PageListAutoBlock.Categories.MainLabel')}
      />
      {!!subOptions.length && (
        <PageAutoSubCategoriesSelect
          subCategories={subCategories}
          setSubCategories={onChangeSubCategories}
          subOptions={subOptions}
        />
      )}
    </>
  );
};

export default PageListCategorySettings;
