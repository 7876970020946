import React, { FC, useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Grow, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import { SharedSiteSelector } from '@/components/Finder/SharedSiteSelector';
import { SharingContentType } from '@/declarations/models/SiteSharingModel';
import { Site } from '@/declarations/models/Site';
import { TagsAutocomplete } from '@/components/TagsAutocomplete';
import Container from '@/components/Container';
import { MediaResourceType } from '../../../declarations/models/MediaResourceType';
import { distinct } from '../../../utils/array';
import UploadMediaModal from '../../../views/MediaView/MediaUpload/UploadMediaModal';
import { Tag } from '../../../declarations/models/Tag';
import { Api } from '../../../services/Api';
import { TagType } from '../../../declarations/models/TagType';

export interface M24FinderHeaderProps {
  resourceType: MediaResourceType;
  onResourceTypeChanged: (resourceType: MediaResourceType) => void;
  selectableResourceTypes?: Array<MediaResourceType>;
  onQueryChange: (query: string) => void;
  forceRefresh: () => void;
  siteId: number;
  site: Site | null;
  selectedSharedSite: Site | null;
  onSelectSharedSite: (s: Site | null) => void;
  selectedTags: Tag[];
  onSelectedTagsChange: (tags: Tag[]) => void;
}

export const M24FinderHeader: FC<M24FinderHeaderProps> = ({
  resourceType,
  onResourceTypeChanged,
  onQueryChange,
  selectableResourceTypes = [MediaResourceType.IMAGE, MediaResourceType.VIDEO, MediaResourceType.AUDIO],
  forceRefresh,
  siteId,
  site,
  selectedSharedSite,
  onSelectSharedSite,
  selectedTags,
  onSelectedTagsChange,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const [uploadModalOpen, setUploadMediaOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<Array<Tag>>([]);

  useEffect(() => {
    const ctx = Api.getAllTagsForSite(siteId, { type: TagType.TAG });
    ctx
      .fetchDirect(null)
      .then((res) => res && setTags(res))
      .finally(ctx.abort);
  }, [siteId]);

  const handleCloseModal = () => {
    forceRefresh();
    setUploadMediaOpen(false);
  };

  return (
    <FinderHeader
      searchField={
        <Container
          gap={2}
          left
          sx={{
            flexGrow: 1,
            flexBasis: '20%',
          }}>
          <TagsAutocomplete
            tagType={TagType.TAG}
            siteId={siteId}
            tags={selectedTags}
            onTagsChange={onSelectedTagsChange}
            onQueryChange={onQueryChange}
          />
        </Container>
      }
      title={<Typography sx={{ fontSize: 'xx-large', fontWeight: 500 }}>{tComponents('Finder.MediaTitle')}</Typography>}
      topRight={
        <SharedSiteSelector
          siteContext={site}
          selectedSite={selectedSharedSite}
          setSelectedSite={onSelectSharedSite}
          contentType={SharingContentType.RESOURCES}
        />
      }
      bottomRight={
        <>
          {selectableResourceTypes.length > 1 ? (
            <FormControl component='fieldset' color='secondary'>
              <RadioGroup
                aria-labelledby='select-m24-media-type-label'
                sx={{ display: 'flex', flexFlow: 'row wrap' }}
                value={resourceType ?? ''}
                onChange={(_, v) => onResourceTypeChanged((v as MediaResourceType) || null)}>
                <Grow in>
                  <FormControlLabel value='' control={<Radio color='secondary' />} label={tCommon('all')} />
                </Grow>
                {distinct(selectableResourceTypes).map((mediaResourceType, i) => (
                  <Grow key={mediaResourceType} timeout={(i + 1) * 100} in>
                    <FormControlLabel
                      value={mediaResourceType}
                      control={<Radio color='secondary' />}
                      label={tCommon(`MediaResourceType.${mediaResourceType}`)}
                    />
                  </Grow>
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <FormLabel id='select-m24-media-type-label' component='legend'>
              {resourceType || selectableResourceTypes.length
                ? tCommon(`MediaResourceType.${resourceType || selectableResourceTypes[0]}`)
                : 'No media type?'}
            </FormLabel>
          )}
          <Button
            color='success'
            variant='contained'
            startIcon={<MaterialSymbol name='upload' />}
            sx={{ height: '40px' }}
            onClick={() => setUploadMediaOpen(!uploadModalOpen)}>
            {tComponents('MediaView.UploadMedia')}
          </Button>
        </>
      }>
      <UploadMediaModal
        open={uploadModalOpen}
        closeModal={handleCloseModal}
        siteId={siteId}
        tags={tags}
        refreshPage={forceRefresh}
      />
    </FinderHeader>
  );
};

export default M24FinderHeader;
