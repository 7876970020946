import React, { PropsWithChildren } from 'react';
import { Paper, SxProps } from '@mui/material';
import FinderSelectedMediaList, { FinderSelectedMediaListProps } from './FinderSelectedMediaList';
import MultiModal from '../MultiModal';
import { GenericMedia } from '../../declarations/GenericMedia';

export interface FinderLayoutProps<T = unknown> {
  open: boolean;
  selectedItems?: Array<GenericMedia<T>>;
  renderSelectedItem?: FinderSelectedMediaListProps<T>['renderItem'];
  onConfirmSelection: (selectedItems: Array<GenericMedia<T>>) => void;
  onClose: () => void;
  sx?: SxProps;
}

export const FinderLayout = <T,>({
  children,
  open,
  selectedItems,
  renderSelectedItem,
  onConfirmSelection,
  onClose,
  sx,
}: PropsWithChildren<FinderLayoutProps<T>>) => {
  return (
    <MultiModal
      open={open}
      secondaryContent={
        renderSelectedItem && (
          <FinderSelectedMediaList<T>
            selectedItems={selectedItems || []}
            onSelect={() => onConfirmSelection(selectedItems || [])}
            onCancel={onClose}
            renderItem={renderSelectedItem}
          />
        )
      }
      sx={sx || {}}>
      <Paper sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>{children}</Paper>
    </MultiModal>
  );
};

export default FinderLayout;
