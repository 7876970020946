import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ChildrenProp } from '../../declarations/ChildrenProp';
import { useStore } from '../../components/store/Store';
import { Api } from '../../services/Api';
import Container from '../../components/Container';
import { FormModule } from '../../declarations/models/FormModule';
import { ToolbarHeader } from '../../components/ToolbarHeader';
import { Status } from '../../declarations/models/Status';
import Loader from '../../components/Loader';
import { FormItem } from './FormItem';

export const FormView: FC<ChildrenProp> = () => {
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const selectedLocale = store?.state?.selectedSiteLanguage || 'no';
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [forms, setForms] = useState<Array<FormModule>>([]);
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [query, setQuery] = useState<string>('');
  const [statuses, setStatuses] = useState<Array<Status>>([]);
  const [isLoadingContentCounts, setIsLoadingContentCounts] = useState<boolean>(false);
  const [contentCounts, setContentCounts] = useState<Record<string, number>>({});

  useEffect(() => {
    let unmounted = false;
    const ctx = Api.getFormModules(siteId, selectedLocale);
    const ctxCounts = Api.getFormModuleContentCounts(siteId);

    setIsLoading(true);
    setIsLoadingContentCounts(true);
    ctx
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setForms(res.modules);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });

    ctxCounts
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setContentCounts(res.counts);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoadingContentCounts(false);
        }
      });

    return () => {
      unmounted = true;
      ctx.abort();
      ctxCounts.abort();
    };
  }, [siteId, selectedLocale]);

  function filter(f: Array<FormModule>): Array<FormModule> {
    return f.filter((form) => {
      if (query && !form.title?.toLowerCase().includes(query.toLowerCase())) {
        return false;
      }
      if (statuses.length && !statuses.includes(form.status)) {
        return false;
      }
      return true;
    });
  }

  const onEditFormClick = (id: number) => {
    navigate(`/formEditor/${id}`);
  };
  const onShowAnswersClick = (id: number) => {
    navigate(`/forms/data/${id}`);
  };
  const onDelete = (id: number) => {
    Api.deleteFormModule(siteId, id)
      .fetchDirect(null)
      .then(() => {
        setForms(forms.filter((form) => form.id !== id));
        enqueueSnackbar(tComponents('FormView.FormDeletedSuccess'), { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(tComponents('FormView.FormDeletedFailed', { error }), { variant: 'error' });
      });
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Container fullWidth fullHeight>
          <Loader loadingText={tCommon('loadingContent')} />
        </Container>
      );
    }
    if (!forms?.length) {
      return (
        <Container fullWidth fullHeight>
          <Typography color='textSecondary'>{tComponents('FormView.NoItems')}</Typography>
        </Container>
      );
    }
    return filter(forms).map((form) => (
      <FormItem
        key={form.id}
        form={form}
        onEditClick={onEditFormClick}
        onShowAnswersClick={onShowAnswersClick}
        onDelete={onDelete}
        contentCount={contentCounts?.[`${form.id}`]}
        isLoadingContentCounts={isLoadingContentCounts}
      />
    ));
  };

  return (
    <Container p={6} fullWidth fullHeight column gap={0} top>
      <ToolbarHeader
        query={query}
        onQueryChange={setQuery}
        selectedStatuses={statuses}
        onSelectedStatusesChange={setStatuses}
        heading={
          <Typography variant='h3' component='h1' fontSize={30}>
            {tComponents('FormView.Title')}
          </Typography>
        }
        topRight={
          <Button
            variant='contained'
            color='success'
            startIcon={<MaterialSymbol name='add' />}
            onClick={() => navigate('/formEditor/new')}
            type='button'>
            {tComponents('FormView.CreateNewForm')}
          </Button>
        }
        boxProps={{ alignSelf: 'stretch' }}
      />
      <Box alignSelf='stretch' alignItems='stretch' display='flex' mt={8} gap={4} flexDirection='column' role='list'>
        {renderContent()}
      </Box>
    </Container>
  );
};

export default FormView;
