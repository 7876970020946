import React, { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import BaseModel from '../declarations/models/BaseModel';
import Form from '../components/forms/Form';
import SettingsViewLayout from '../components/SettingsViewLayout';
import SettingsViewFormDataProvider, {
  SettingsViewFormDataProviderProps,
} from '../components/SettingsViewFormDataProvider';
import { MenuItem } from '../declarations/models/MenuItem';

export interface BaseSettingsViewFormProps<Model extends object | BaseModel>
  extends Pick<SettingsViewFormDataProviderProps<Model>, 'loadModel'> {
  onSubmit: (model: Model) => Promise<void | Model>;
  title: string;
  menuItems?: Array<MenuItem>;
  initialValue?: Model;
  withLayout?: boolean;
  saveButtonInHeader?: boolean;
  saveButtonInTop?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubmitHandler = createContext<BaseSettingsViewFormProps<any>['onSubmit'] | null>(null);

export function useFormSubmitHandler() {
  const handler = useContext(SubmitHandler);
  const handlerRef = useRef<typeof handler>(handler);
  handlerRef.current = handler;

  const { handleSubmit, reset } = useFormContext();

  return useCallback(async () => {
    await handleSubmit((model) => {
      handlerRef.current?.(model)?.then((savedFormData) => {
        if (savedFormData) {
          reset(savedFormData);
        }
      });
    })();
  }, [handleSubmit, reset]);
}
export const BaseSettingsViewForm = <Model extends object | BaseModel>({
  children,
  loadModel,
  onSubmit,
  title,
  menuItems,
  initialValue,
  withLayout = true,
  saveButtonInHeader,
  saveButtonInTop,
}: PropsWithChildren<BaseSettingsViewFormProps<Model>>) => {
  return (
    <Form<Model> variant='outlined' initialValue={initialValue}>
      <SubmitHandler.Provider value={onSubmit}>
        <SettingsViewFormDataProvider<Model> loadModel={loadModel}>
          <>
            {withLayout ? (
              <SettingsViewLayout
                title={title}
                menuItems={menuItems}
                saveButtonInHeader={saveButtonInHeader}
                saveButtonInTop={saveButtonInTop}>
                {children}
              </SettingsViewLayout>
            ) : (
              <>{children}</>
            )}
          </>
        </SettingsViewFormDataProvider>
      </SubmitHandler.Provider>
    </Form>
  );
};

export default BaseSettingsViewForm;
