/* istanbul ignore file */

/**
 * Represents content that should be prefetched when loading the Editor,
 * and provided via the DataEditor.
 */
export enum EditorDataSet {
  CATEGORIES = 'categories',
  SKINS = 'skins',
}
