import React, { FC } from 'react';
import { Pagination, PaginationProps } from '@mui/material';
import { PaginationPage } from '../declarations/PaginationPage';
import calculatePaginationInfo from '../utils/calculatePaginationInfo';

export interface PaginatorProps extends Pick<PaginationProps, 'variant' | 'color'> {
  page: PaginationPage;
  onChange: (pageNumber: number, start: number, rows: number) => void;
  totalItemCount: number;
  showSinglePage?: boolean;
}

export const Paginator: FC<PaginatorProps> = ({
  page,
  onChange,
  totalItemCount,
  variant = 'outlined',
  color = 'secondary',
  showSinglePage = false,
}) => {
  const { pageSize, pageNumber, totalPageCount } = calculatePaginationInfo(page, totalItemCount);

  const handleChange = (newPageNumber: number) => {
    const { start, rows } = calculatePaginationInfo({ pageNumber: newPageNumber, pageSize }, totalItemCount);
    onChange?.(newPageNumber, start, rows);
  };

  if (!showSinglePage && totalPageCount <= 1) {
    return null;
  }

  return (
    <Pagination
      page={pageNumber}
      count={totalPageCount}
      variant={variant}
      color={color}
      onChange={(_, pageNum) => handleChange(pageNum)}
      showFirstButton
      showLastButton
    />
  );
};

export default Paginator;
