import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChildrenProp } from '../declarations/ChildrenProp';

export const NotFoundView: FC<ChildrenProp> = () => {
  return (
    <>
      <Typography variant='h1'>Page not found</Typography>
      <Link to='/'>Go home</Link>
    </>
  );
};

export default NotFoundView;
