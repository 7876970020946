import React, { FC } from 'react';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { Employee } from '../../../declarations/models/Employee';
import { inputGroupStyling } from '../../MediaEditor/MediaEditorFields/EditMediaTextFields';
import { EmployeeTextField } from '../EmployeeEditor';

export interface EditEmployeeTextFieldsProps {
  employee?: Partial<Employee>;
  onChange: (attribute: EmployeeTextField, value: string) => void;
}

export const EditEmployeeTextFields: FC<EditEmployeeTextFieldsProps> = ({ employee, onChange }) => {
  const { t } = useTranslation('components');

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{t('EmployeeEditor.TextFieldsTitle')}</Typography>
      <Container fullWidth column left gap={2}>
        <Container fullWidth top gap={2}>
          <TextField
            size='small'
            label={t('EmployeeEditor.Firstname')}
            fullWidth
            required
            value={employee?.first_name ?? ''}
            onChange={(e) => onChange('first_name', e.target.value)}
          />
          <TextField
            size='small'
            label={t('EmployeeEditor.Lastname')}
            fullWidth
            required
            value={employee?.last_name ?? ''}
            onChange={(e) => onChange('last_name', e.target.value)}
          />
        </Container>
        <Container fullWidth top gap={2}>
          <TextField
            size='small'
            label={t('EmployeeEditor.Position')}
            fullWidth
            value={employee?.position ?? ''}
            onChange={(e) => onChange('position', e.target.value)}
          />
          <TextField
            size='small'
            label={t('EmployeeEditor.Department')}
            fullWidth
            value={employee?.department ?? ''}
            onChange={(e) => onChange('department', e.target.value)}
          />
        </Container>
        <TextField
          size='small'
          label={t('EmployeeEditor.Description')}
          fullWidth
          value={employee?.description ?? ''}
          multiline
          minRows={2}
          maxRows={4}
          onChange={(e) => onChange('description', e.target.value)}
        />
      </Container>
    </Container>
  );
};

export default EditEmployeeTextFields;
