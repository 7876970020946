import React, { FC, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { Skin } from '../../../../declarations/models/Skin';
import SkinSelector from '../../../lib/components/settings/SkinSelector';
import { BlockPath } from '../../CurrentBlockProvider';
import { useEditorData } from '../../../lib/components/EditorDataProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { PageListManualBlock } from '../../../../declarations/models/blocks/PageListBlocks';

export type PageListManualBlockItemSkinSelectorPath = `${BlockPath}.items.${number}.local`;

export interface PageListManualBlockItemSkinSelector {
  path: PageListManualBlockItemSkinSelectorPath;
}

export const PageListManualBlockItemSkinSelector: FC<PageListManualBlockItemSkinSelector> = ({ path }) => {
  const { skins } = useEditorData();

  const availableSkins = useMemo<Array<Skin>>(() => {
    return skins?.filter((skin) => skin.scope?.includes('grid') !== false);
  }, [skins]);

  const {
    field: { value: skinClass, onChange: setSkinClass },
  } = useController<BlockSpecificPage<PageListManualBlock>, `${typeof path}.skin`>({
    name: `${path}.skin`,
  });

  const selectedSkin = useMemo<Skin | null>(() => {
    return availableSkins.find((skin) => skin.class === skinClass) || null;
  }, [availableSkins, skinClass]);

  const handleSkinChanged = (skin: Skin | null) => {
    setSkinClass(skin?.class || '');
  };

  return <SkinSelector availableSkins={availableSkins} selectedSkin={selectedSkin} onSkinChange={handleSkinChanged} />;
};

export default PageListManualBlockItemSkinSelector;
