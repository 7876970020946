import {
  FieldArrayPath,
  FieldValues,
  useFieldArray,
  UseFieldArrayProps,
  UseFieldArrayReturn,
  useWatch,
} from 'react-hook-form';
import { useMemo } from 'react';

export function useControlledFieldArray<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>(
  props: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>,
): UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName> {
  const { fields: uncontrolledFields, ...rest } = useFieldArray<TFieldValues, TFieldArrayName, TKeyName>(props);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const controlledFields = useWatch<TFieldValues>({ name: props.name, defaultValue: [] });

  const fields = useMemo<typeof uncontrolledFields>(
    () =>
      uncontrolledFields.map((uncontrolledField, index) => ({
        ...uncontrolledField,
        ...controlledFields[index],
      })),
    [controlledFields, uncontrolledFields],
  );

  return {
    fields,
    ...rest,
  };
}
