import React, { ReactElement, useState } from 'react';
import { LinearProgress, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericMedia } from '@/declarations/GenericMedia';
import Container from '../Container';
import { ViewType } from '../../declarations/ViewType';
import Loader from '../Loader';
import Layout from '../Layout';
import ViewTypeSelector from '../ViewTypeSelector';
import ListView from './ViewTypes/ListView';
import Paginator, { PaginatorProps } from '../Paginator';
import CurrentPageInfo from './CurrentPageInfo';
import PageSizeSelector, { PageSizeSelectorProps } from '../PageSizeSelector';
import calculatePaginationInfo from '../../utils/calculatePaginationInfo';
import { CommonMediaListViewTypeProps } from './CommonMediaListViewTypeProps';
import GridView from './ViewTypes/GridView/GridView';

export interface MediaListProps<T = unknown>
  extends CommonMediaListViewTypeProps<T>,
    Pick<PaginatorProps, 'page' | 'totalItemCount'> {
  isLoading?: boolean;
  defaultSelectedViewType?: ViewType;
  onPageChange: PaginatorProps['onChange'];
  pageSizeOptions?: PageSizeSelectorProps['options'];
  onPageSizeChange?: PageSizeSelectorProps['onChange'];
  customHeaderElement?: ReactElement;
  disablePagination?: boolean;
  headerSx?: SxProps;
  renderListItemContent?: (item: GenericMedia<T>) => React.ReactNode;
  renderGridItemContent?: (item: GenericMedia<T>) => React.ReactNode;
  contentOverflow?: string;
  mainBackgroundColor?: string;
}

export const MediaList = <T,>({
  isLoading = false,
  items,
  defaultSelectedViewType = ViewType.LIST,
  page,
  totalItemCount,
  onPageChange,
  pageSizeOptions,
  onPageSizeChange,
  customHeaderElement,
  disablePagination,
  headerSx = { boxShadow: 1 },
  contentOverflow,
  ...commonProps
}: MediaListProps<T>) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(defaultSelectedViewType);

  const { pageSize } = calculatePaginationInfo(page, totalItemCount);

  const renderContent = () => {
    if (isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Loader loadingText={tCommon('loadingContent')} />
        </Container>
      );
    }
    if (!isLoading && !items?.length) {
      return (
        <Container fullWidth fullHeight>
          <Typography color='textSecondary'>{tComponents('MediaList.NoItems')}</Typography>
        </Container>
      );
    }
    switch (selectedViewType) {
      case ViewType.LIST:
        return <ListView {...(commonProps as CommonMediaListViewTypeProps)} items={items} />;
      case ViewType.GRID:
        return <GridView {...(commonProps as CommonMediaListViewTypeProps)} items={items} />;
    }
    return null;
  };

  return (
    <Container sx={{ position: 'relative' }} fullWidth>
      <Layout
        sx={{
          minHeight: '800px',
        }}
        headerContent={
          <Container sx={headerSx} column fullWidth>
            <Container fullWidth py={2} px={4}>
              {customHeaderElement && (
                <Container m={-1} fullWidth left>
                  {customHeaderElement}
                </Container>
              )}
              <Container right fullWidth>
                <CurrentPageInfo page={page} totalItemCount={totalItemCount} />
                <ViewTypeSelector value={selectedViewType} onChange={setSelectedViewType} />
              </Container>
            </Container>
            {isLoading && !!items?.length && (
              <LinearProgress variant='indeterminate' color='secondary' sx={{ width: '100%' }} />
            )}
          </Container>
        }
        footerContent={
          !disablePagination &&
          !!totalItemCount &&
          !!onPageChange && (
            <Container py={2} px={4} sx={{ boxShadow: 3 }} gap={3} right fullWidth>
              {!!onPageSizeChange && (
                <PageSizeSelector
                  pageSize={pageSize}
                  options={pageSizeOptions}
                  onChange={onPageSizeChange}
                  variant='standard'
                />
              )}
              <Paginator page={page} onChange={onPageChange} totalItemCount={totalItemCount} />
            </Container>
          )
        }
        contentOverflow={contentOverflow}
        scrollContentToTopTrigger={page}>
        {renderContent()}
      </Layout>
    </Container>
  );
};

export default MediaList;
