import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import i18next from 'i18next';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import KPLogo from '../../../../components/icons/KPLogo';
import DraggableContainer, { DraggableContainerProps } from '../../../../components/DraggableContainer';
import { Draggable } from '../../../../declarations/Draggable';
import Container from '../../../../components/Container';
import Styles from '../../../../assets/js/Styles';
import { noWrapTextStyle } from '../../../../views/MediaView/MediaViewTypes/ListViewItem';
import { resolveLocalizedString } from '../../../../utils/strings';
import { BlockPath } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { Api } from '../../../../services/Api';
import { KPBlockModel, KPDataItem, KPOwnerBlockModel } from '../../../../declarations/models/blocks/KPBlock';
import { KPProcessedFields } from '../../../../declarations/models/KP';
import ListImage from '../../../../components/ListImage';
import { getSizeButton } from './KPBlockOwnerItem';

export type KPBlockItemPathPrefix =
  | `${BlockPath}.articles`
  | `${BlockPath}.presentations`
  | `${BlockPath}.presentation.articles`;

export const KPBlockItem: FC<
  Pick<DraggableContainerProps<KPDataItem>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'> & {
    isPres?: boolean;
    itemPathPrefix: KPBlockItemPathPrefix;
  }
> = ({ index, onReorder, onRemove, onAdd, isPres, itemPathPrefix }) => {
  const { t: tCommon } = useTranslation('common');
  const currentLang = i18next.language;
  const itemPath: `${KPBlockItemPathPrefix}.${number}` = `${itemPathPrefix}.${index}`;
  const [item, setItem] = useState<KPProcessedFields>();

  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<KPBlockModel | KPOwnerBlockModel>, typeof itemPath>({
    name: itemPath,
  });

  const val = value as KPDataItem;

  useEffect(() => {
    if (val?.id) {
      const ctx = Api.getKPObject(val.id);
      ctx
        .fetchDirect(null)
        .then((e) => e && setItem(e))
        .finally(ctx.abort);
    } else setItem(undefined);
  }, [val?.id]);

  const handleChangeSize = () => {
    onChange({
      ...val,
      size: val.size === 'L' ? 'M' : 'L',
    });
  };

  const desc = resolveLocalizedString(currentLang, item?.description);
  const title = resolveLocalizedString(currentLang, item?.title);
  const owner = resolveLocalizedString(currentLang, item?.owner_name);
  const objectType = item?.schema_id ? tCommon(`KulturioSchemaTypes.${item.schema_id}`) : undefined;
  return (
    <DraggableContainer
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      type={Draggable.KP_ITEM}
      value={val}
      color={isPres ? Styles.Colors.KP_PRES : Styles.Colors.KP_ARTICLE}
      secondaryAction={
        <>
          {item?.schema_id === 12 && getSizeButton(val, handleChangeSize, tCommon('changeItemSize'))}
          <DeleteButton
            onConfirm={() => onRemove?.(index)}
            component='IconButton'
            tooltip={tCommon('remove')}
            deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
          />
        </>
      }
      headerContent={
        <Container left fullWidth>
          <ListImage
            src={item?.image_src}
            alt={desc}
            heightPx={!isPres ? 64 : 80}
            widthPx={!isPres ? 64 : 80}
            fallbackImage={<KPLogo />}
          />
          {item ? (
            <Container column left gap={0} sx={{ maxWidth: 'calc(100% - 72px)' }}>
              <Typography sx={noWrapTextStyle} fontWeight={500}>
                {title}
              </Typography>
              <Typography variant='caption' fontStyle='italic' sx={noWrapTextStyle}>
                {owner}
                {!!objectType && ` (${objectType})`}
              </Typography>
            </Container>
          ) : (
            <Typography color={Styles.Colors.MEDIUM_GREY} fontStyle='italic'>
              {tCommon('loading')}
            </Typography>
          )}
        </Container>
      }
    />
  );
};

export default KPBlockItem;
