import { M24MediaModel } from '../declarations/models/M24MediaModel';
import { DMMediaModel } from '../declarations/models/DMMediaModel';
import { MediaResourceType } from '../declarations/models/MediaResourceType';

export const prepareMediaItemAsM24MediaModel = (item: M24MediaModel | null) => {
  if (item) {
    return item as M24MediaModel;
  }
};

export const prepareMediaItemAsDMMediaModel = (item: DMMediaModel | null, url: string) => {
  if (item && url) {
    return {
      dimuId: item['artifact.uniqueId'],
      dmsId: item['artifact.defaultMediaIdentifier'],
      id: item['artifact.defaultMediaIdentifier'],
      item,
      objectId: item['identifier.id'],
      source: 'digitaltMuseum',
      type: 'external_url',
      url,
      local: {
        anySource: url,
        dmsId: item['artifact.defaultMediaIdentifier'],
        title: item['artifact.ingress.title'],
        sourceUrl: url,
        unknownSource: false,
        vimeoId: false,
        youtubeId: false,
        _editmode: true,
      },
    } as DMMediaModel;
  }
};

export const getMediaType = (mimeTypeString: string) => {
  const [mediaType, fileType] = mimeTypeString.split('/');
  switch (mediaType) {
    case MediaResourceType.IMAGE:
      if (fileType === 'gif') return MediaResourceType.ANIMATION;
      return MediaResourceType.IMAGE;
    case MediaResourceType.AUDIO:
      return MediaResourceType.AUDIO;
    case MediaResourceType.VIDEO:
      return MediaResourceType.VIDEO;
    case 'application':
      return MediaResourceType.DOCUMENT;
    default:
      throw new Error(`Unsupported media type: ${mediaType}`);
  }
};
