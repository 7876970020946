import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, AppBarProps, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from './Container';
import M24Logo from './icons/M24Logo';
import Styles from '../assets/js/Styles';

export interface HeaderProps extends Pick<AppBarProps, 'elevation'> {
  menuOpen?: boolean;
  onToggleMenuOpen?: () => void;
  additionalContentLeft?: ReactNode;
  additionalContentRight?: ReactNode;
  color?: AppBarProps['color'];
  hideLogo?: boolean;
  hideMenuButton?: boolean;
}

const Header: FC<HeaderProps> = ({
  menuOpen,
  onToggleMenuOpen,
  additionalContentLeft,
  additionalContentRight,
  color,
  hideLogo = false,
  hideMenuButton = false,
  elevation = 0,
}) => {
  const { t } = useTranslation('aria');
  const navigate = useNavigate();
  return (
    <AppBar position='static' color={color} elevation={elevation}>
      <Container gap={0} spaceBetween fullWidth fullHeight>
        <Container
          gap={0}
          left
          sx={{
            borderRight: hideMenuButton ? undefined : `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            p: 0.8,
          }}>
          {!hideMenuButton && (
            <IconButton
              size='large'
              sx={(theme) => ({
                borderRadius: 0,
                height: '100%',
                color: theme.palette.secondary.main,
              })}
              aria-label={t('components.Header.toggleMenuButton')}
              onClick={onToggleMenuOpen}>
              {menuOpen ? <MaterialSymbol name='menu_open' /> : <MaterialSymbol name='menu' />}
            </IconButton>
          )}
          {!hideLogo && (
            <Button
              role='link'
              color='inherit'
              sx={{ ml: 1 }}
              aria-label={t('components.Header.navigateToHome')}
              onClick={() => navigate('/')}>
              <M24Logo color='inherit' />
            </Button>
          )}
        </Container>
        <Container pl={2} left fullWidth>
          {additionalContentLeft}
        </Container>
        <Container pr={2} right sx={{ minWidth: 'fit-content' }}>
          {additionalContentRight}
        </Container>
      </Container>
    </AppBar>
  );
};

export default Header;
