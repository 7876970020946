import React, { FC, ReactNode, useRef, useState } from 'react';
import { CloseReason, OpenReason, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Frame from 'react-frame-component';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../../../assets/js/Styles';
import Container from '../../../components/Container';
import DeveloperPreview from '../../PageEditor/previews/DeveloperPreview';
import { Page } from '../../../declarations/models/Page';
import Preview from '../../PageEditor/previews/Preview';
import { Settings } from '../../../Settings';
import { useStore } from '../../../components/store/Store';

export interface EditorPreviewContainerProps {
  portalUrl?: string;
}

export enum PreviewType {
  AUTO = 'auto',
  FILL = 'fill',
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
  DESKTOP_4K = 'desktop_4k',
  DEVELOPER = 'developer',
}

// prettier-ignore
const enabledPreviewTypes = [
  PreviewType.AUTO,
  PreviewType.MOBILE,
  PreviewType.DEVELOPER,
];

interface DeviceSize {
  width: number;
  height: number;
}

type Devices = { [previewType in PreviewType]: DeviceSize | null };

const DEVICES: Devices = {
  [PreviewType.AUTO]: null,
  [PreviewType.FILL]: null,
  [PreviewType.MOBILE]: { width: 393, height: 851 },
  [PreviewType.TABLET]: { width: 1180, height: 820 },
  [PreviewType.DESKTOP]: { width: 1920, height: 1080 },
  [PreviewType.DESKTOP_4K]: { width: 3840, height: 2160 },
  [PreviewType.DEVELOPER]: null,
};

const DEVICE_ICON: { [previewType in PreviewType]: ReactNode } = {
  [PreviewType.AUTO]: <MaterialSymbol name='fullscreen' />,
  [PreviewType.FILL]: <MaterialSymbol name='fit_screen' />,
  [PreviewType.MOBILE]: <MaterialSymbol name='phone_android' />,
  [PreviewType.TABLET]: <MaterialSymbol name='tablet' />,
  [PreviewType.DESKTOP]: <MaterialSymbol name='desktop_windows' />,
  [PreviewType.DESKTOP_4K]: <MaterialSymbol name='4k' />,
  [PreviewType.DEVELOPER]: <MaterialSymbol name='data_object' />,
};

const BORDER_WIDTH = 2;

export const EditorPreviewContainer: FC<EditorPreviewContainerProps> = ({ portalUrl }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const previewContainer = useRef<HTMLDivElement | null>(null);

  const [selectedDeviceType, setSelectedDeviceType] = useState<PreviewType>(PreviewType.AUTO);
  const [orientationFlipped, setOrientationFlipped] = useState<boolean>(false);
  const device = DEVICES[selectedDeviceType];

  const [isDialOpen, setIsDialOpen] = useState<boolean>(false);
  const canCloseDialTimeout = useRef<boolean>(false);

  const openDial = (_e: unknown, reason: OpenReason) => {
    if (reason !== 'mouseEnter' && !isDialOpen) {
      setIsDialOpen(true);
      canCloseDialTimeout.current = false;
      // HACK: opening the dial triggers closing,
      //  wait for the animation to finish before allowing the dial to close again.
      setTimeout(() => {
        canCloseDialTimeout.current = true;
      }, 300);
    }
  };
  const closeDial = (_e: unknown, reason: CloseReason) => {
    if (reason !== 'mouseLeave' && isDialOpen && canCloseDialTimeout.current) {
      setIsDialOpen(false);
    }
  };

  let scale = 1;
  let width: string | number = '100%';
  let height: string | number = '100%';

  if (selectedDeviceType !== PreviewType.FILL && device) {
    const viewportWidth = previewContainer.current?.clientWidth || device.width;
    const viewportHeight = previewContainer.current?.clientHeight || device.height;
    const x = orientationFlipped ? device.height : device.width;
    const y = orientationFlipped ? device.width : device.height;
    const scaleX = viewportWidth / x;
    const scaleY = viewportHeight / y;
    scale = Math.min(scaleX, scaleY);
    width = Math.floor(x * scale) - BORDER_WIDTH * 2;
    height = Math.floor(y * scale) - BORDER_WIDTH * 2;
  }

  if (selectedDeviceType === PreviewType.AUTO) {
    scale = 0.5;
    width = '200%';
    height = '200%';
  }

  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const date = new Date();
  const today = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
  const basePortal = Settings.PORTAL_HOST || 'https://dev.museum24.no/';

  // Todo:Find a better way to get latest version
  const baseCSS = `${basePortal}/assets/design/css_v2/base2.css?t=${today}`;
  const siteCSS = `${basePortal}/api/design/${siteId}/style.css?t=${today}`;

  return (
    <>
      <Container
        ref={previewContainer}
        /* istanbul ignore next */
        sx={(theme) => ({
          backgroundColor: Styles.Colors.PREVIEW_BG_COLOR,
          color: theme.palette.getContrastText(Styles.Colors.PREVIEW_BG_COLOR),
          maxWidth: '50vw',
        })}
        fullHeight
        fullWidth>
        <Frame
          id='previewframe'
          name='preview'
          className='preview-iframe previewsize--auto'
          style={{
            width,
            height,
            position: 'absolute',
            scale: selectedDeviceType === PreviewType.AUTO ? String(scale) : '1',
          }}
          initialContent={`<!DOCTYPE html><html lang=${store.state.selectedSiteLanguage}>
              <head><meta content="width=device-width,initial-scale=1.0" name="viewport"/>
              <link href=${baseCSS} rel="stylesheet" type="text/css"/>
              <link href=${siteCSS} rel="stylesheet" type="text/css"/>
              </head>
              <body class="template"><div></div></body></html>`}>
          <Container
            sx={{
              width,
              height,
              border: `${BORDER_WIDTH}px solid #000`,
              '& > *': {
                transform: `scale(${scale})`,
              },
              overflow: 'auto',
            }}
            column
            top
            wrap>
            {selectedDeviceType === PreviewType.DEVELOPER ? <DeveloperPreview<Page> /> : <Preview />}
          </Container>
        </Frame>
      </Container>

      <SpeedDial
        /* istanbul ignore next */
        sx={(theme) => ({
          position: 'absolute',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          opacity: '0.5',
        })}
        open={isDialOpen}
        onOpen={openDial}
        onClose={closeDial}
        direction='left'
        FabProps={{ color: 'secondary' }}
        ariaLabel={tAria('components.EditorPreviewContainer.SelectDeviceType')}
        icon={<SpeedDialIcon icon={DEVICE_ICON[selectedDeviceType]} openIcon={<MaterialSymbol name='close' />} />}>
        {enabledPreviewTypes
          .filter((type) => (Settings.envIsProduction ? type !== PreviewType.DEVELOPER : true))
          .map((previewType) => (
            <SpeedDialAction
              id={`select-device-type-${previewType}-action`}
              /* istanbul ignore next */
              sx={
                previewType === selectedDeviceType
                  ? (theme) => ({
                      '&:not(:hover)': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.getContrastText(theme.palette.secondary.main),
                      },
                    })
                  : undefined
              }
              key={previewType}
              icon={DEVICE_ICON[previewType]}
              onClick={() => setSelectedDeviceType(previewType)}
              tooltipTitle={tComponents(`EditorPreviewContainer.PreviewType.${previewType}`)}
              tooltipPlacement='top'
              arrow
            />
          ))}
        {selectedDeviceType !== PreviewType.AUTO && selectedDeviceType !== PreviewType.FILL && (
          <SpeedDialAction
            id='flip-orientation-action'
            icon={<MaterialSymbol name='screen_rotation' />}
            onClick={() => setOrientationFlipped((flipped) => !flipped)}
            tooltipTitle={tComponents(`EditorPreviewContainer.FlipDeviceOrientation`)}
            tooltipPlacement='top'
            arrow
          />
        )}
      </SpeedDial>
    </>
  );
};

export default EditorPreviewContainer;
