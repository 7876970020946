import React, { FC, useState } from 'react';
import { Page } from '@/declarations/models/Page';
import { Api } from '@/services/Api';
import { useStore } from '@/components/store/Store';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import SettingsViewLayout from '@/components/SettingsViewLayout';
import { useTranslation } from 'react-i18next';
import { useApi } from '@/hooks/useApi';
import Loader from '@/components/Loader';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import { PageListRow } from '@/components/ui/PageListRow';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import ActionMenu from '@/components/ActionMenu';
import DeleteButton from '@/components/DeleteButton';
import { useSnackbar } from 'notistack';
import CreatePageModal from '@/components/CreatePageModal';
import { ContentViewContentContext } from '@/views/ContentView/ContentViewContext';

export const SitePageTemplateSettings: FC = () => {
  const store = useStore();
  const { t: tComponents } = useTranslation('components');
  const { t: tAria } = useTranslation('aria');
  const navigate = useNavigate();
  const siteId = store?.state?.selectedSite?.id || 0;
  const [listTemplatesResponse, isLoadingTemplates, reloadTemplates] = useApi(() => Api.listPageTemplates(siteId), {
    page_templates: [],
    count: 0,
  });
  const { enqueueSnackbar } = useSnackbar();

  const [createPageModalOpen, setCreatePageModalOpen] = useState<boolean>(false);

  async function deleteTemplate(template: Page) {
    try {
      await Api.deletePageTemplate(template.id!).fetchDirect(null);
      enqueueSnackbar(tComponents('Settings.SiteSettings.SitePageTemplateSettings.deleteTemplateSuccess'), {
        variant: 'success',
      });
      reloadTemplates();
    } catch (e) {
      enqueueSnackbar(tComponents('Settings.SiteSettings.SitePageTemplateSettings.deleteTemplateError'), {
        variant: 'error',
      });
    }
  }

  return (
    <SettingsViewLayout
      title={tComponents('MainMenu.settings.pageTemplates')}
      saveButtonInHeader={false}
      saveButtonInTop={false}>
      <Container fullWidth spaceBetween top>
        <Container column left fullWidth>
          <Typography variant='body1'>
            {tComponents('Settings.SiteSettings.SitePageTemplateSettings.infoText')}
          </Typography>
          <Link to='https://manual.museum24.no/maler' target='_blank'>
            {tComponents('Settings.SiteSettings.SitePageTemplateSettings.readMoreLabel')}
            <MaterialSymbol
              name='open_in_new'
              sx={{
                fontSize: 'small',
              }}
            />
          </Link>
        </Container>
        <Container>
          <Button
            variant='outlined'
            startIcon={<MaterialSymbol name='add' />}
            onClick={() => setCreatePageModalOpen(true)}
            type='button'
            sx={{
              textWrap: 'nowrap',
            }}>
            {tComponents('CreatePageModal.NewTemplate')}
          </Button>
        </Container>
      </Container>

      <Container column left top mt={2} fullWidth>
        {isLoadingTemplates ? (
          <Loader size={25} />
        ) : (
          <>
            <Container
              column
              fullWidth
              top
              left
              sx={{
                maxWidth: '1200px',
              }}>
              {listTemplatesResponse.page_templates?.map((template) => (
                <PageListRow
                  key={template.id!}
                  page={template}
                  role='link'
                  sx={{
                    backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHTEST,
                    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                    outline: 'none',
                    outlineOffset: -1,
                    '&:hover, &:focus-within': {
                      outline: `1px solid ${Styles.Colors.COPY_PASTE_PURPLE_LIGHT}`,
                    },
                  }}
                  onClick={() => navigate(`/editor/${template.id}`)}
                  actionChildren={
                    <ActionMenu
                      id={`template-actions-${template.id}`}
                      ariaLabel={tAria('SitePageTemplateSettings.ActionMenuLabel')}>
                      <DeleteButton
                        key={`template-actions-delete-${template.id}`}
                        onConfirm={() => deleteTemplate(template)}
                        component='MenuItem'
                      />
                    </ActionMenu>
                  }
                />
              ))}
            </Container>
          </>
        )}
      </Container>
      <CreatePageModal
        open={createPageModalOpen}
        parentPage={null}
        setPages={() => reloadTemplates()}
        type='template'
        closeCreatePageModal={() => setCreatePageModalOpen(false)}
        contentContext={ContentViewContentContext.PAGE}
      />
    </SettingsViewLayout>
  );
};
