import React from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetBlock } from '@/declarations/models/blocks/WidgetBlock';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const WidgetBlockPreview: BlockPreviewComponent = () => {
  const { t } = useTranslation('common');
  const { block } = useCurrentBlock();
  const currentBlock = block as WidgetBlock;

  let layout = 'auto';
  let width = currentBlock?.position || 'auto';
  if (currentBlock?.position === 'right' || currentBlock?.position === 'left') {
    layout = currentBlock?.position;
    width = 'auto';
  }

  return (
    <div className={`widget widget--preview widget--${width} layout--${layout}`}>
      <div className='widget__grid'>
        <div className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          <p>{currentBlock?.body}</p>
          <p>
            <strong>Widget-modul vises kun i sluttportal.</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WidgetBlockPreview;
