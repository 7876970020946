import React, { useEffect, useState } from 'react';
import { Api } from '@/services/Api';
import { useStore } from '@/components/store/Store';
import { Employee } from '@/declarations/models/Employee';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BylineBlockModel } from '../../../declarations/models/blocks/BylineBlock';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';

export const BylineBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as BylineBlockModel;
  const { state } = useStore();
  const siteId = state.selectedSite?.id || 0;
  const [employee, setEmployee] = useState<Employee | null>(null);

  let mode = 'person';

  if (currentBlock.mode) {
    mode = currentBlock.mode;
  } else if (currentBlock?.author?.id) {
    mode = 'person';
  } else {
    mode = 'manual';
  }

  useEffect(() => {
    if (currentBlock?.employees && currentBlock?.employees?.length > 0) {
      const ctx = Api.getEmployee(siteId, currentBlock?.employees[0].id || 0);
      ctx
        .fetchDirect(null)
        .then((response) => {
          setEmployee(response);
        })
        .catch((error) => {
          console.error(error);
          setEmployee(null);
        })
        .finally(ctx.abort);
    } else {
      setEmployee(null);
    }
  }, [currentBlock?.employees, siteId]);

  return (
    <div className='article__grid'>
      <div className='module article__byline'>
        <div className='module__grid'>
          <div className='module__media'>
            {mode === 'employee' && employee && <img src={employee?.resource?.url} alt='' />}
            {mode === 'manual' && currentBlock?.mediaobject && <img src={currentBlock?.mediaobject?.url} alt='' />}
            {mode === 'person' && <img src={currentBlock?.author?.content?.mediaobject?.url} alt='' />}
          </div>
          <div className='module__content'>
            <div className='byline__leadtext'>{currentBlock.leadtext}</div>
            <div className='byline__author'>
              {mode === 'employee' && employee && `${employee?.first_name} ${employee?.last_name}`}
              {mode === 'manual' && currentBlock?.authortext && currentBlock.authortext}
              {mode === 'person' && currentBlock?.author?.id && `${currentBlock?.author?.title}`}
            </div>
            <div className='byline__descr'>
              {(mode === 'employee' && employee?.position) || ''}
              {mode === 'manual' && currentBlock?.description}
              {mode === 'person' && currentBlock?.author?.id && `${currentBlock?.author?.content?.descr}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BylineBlockPreview;
