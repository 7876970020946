import React, { FC } from 'react';
import { UserSelectableIcon, UserSelectableIconName, UserSelectableIcons } from '@/components/UserSelectableIcon';
import { Button, Dialog, DialogContent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';

export interface IconFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedIcon: UserSelectableIconName) => void;
  selectedIcon?: UserSelectableIconName;
}

export const IconFinder: FC<IconFinderProps> = ({ open, onClose, selectedIcon, onSelectionConfirmed }) => {
  const { t } = useTranslation('common');
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent>
        <Container fullWidth right>
          <Button onClick={onClose}>{t('cancel')}</Button>
        </Container>
        <Grid container spacing={4}>
          {UserSelectableIcons.map((icon) => (
            <Grid key={icon} item>
              <Button
                variant='outlined'
                onClick={() => {
                  onSelectionConfirmed(icon);
                  onClose();
                }}
                sx={{
                  width: '128px',
                  height: '128px',
                  padding: 10,
                  display: 'flex',
                  backgroundColor: selectedIcon === icon ? Styles.Colors.MEDIUM_LIGHT_GREY : 'none',
                }}>
                <UserSelectableIcon
                  name={icon}
                  sx={{
                    fontSize: 128,
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
