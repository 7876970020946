import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { LinkType } from '../../../declarations/models/LinkType';
import Links from '../../../components/Links/Links';
import { Page } from '../../../declarations/models/Page';
import { LinkExternal } from '../../../declarations/models/LinkExternal';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';
import { Draggable } from '../../../declarations/Draggable';
import TextInput from '../../../components/forms/TextInput';
import LinkListItem from '../../../components/Links/LinkListItem';
import { FooterMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/FooterMenuBlock';

export interface LanguageFooterMenuSubMenuSettingsProps {
  itemPath: `${BlockPath}.items.${number}`;
}

export const LanguageFooterMenuSubMenuSettings: FC<LanguageFooterMenuSubMenuSettingsProps> = ({ itemPath }) => {
  const { t } = useTranslation('components');

  const {
    fields: items,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<FooterMenuBlock>, `${BlockPath}.items.${number}.items`>({
    name: `${itemPath}.items`,
  });

  return (
    <Container
      fullWidth
      left
      p={2}
      column
      sx={{
        backgroundColor: Styles.Colors.THEME_BG_COLOR,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Typography>{t('Settings.LanguageSettings.LanguageFooterSettings.menu.subMenu')}</Typography>
      {(items as unknown as Array<Page | LinkExternal>)?.map((item, i) => (
        <LinkListItem
          key={item.id ? item.id + i : (item.url || item.title || '') + i} // eslint-disable-line react/no-array-index-key
          linkType={item.type as LinkType}
          type={Draggable.OTHER}
          index={i}
          onReorder={move}
          onDelete={remove}
          itemsArrayName='items'
          showLinkDescriptionField={false}
          onChangeFile={() => null}
          blockPath={itemPath}
          iconOverride={'status' in item ? <PageStatusCircle status={item.status} size={20} /> : undefined}
          color={Styles.Colors.LIGHT_GREY}>
          <Container fullWidth p={2} column>
            <Container fullWidth>
              <TextInput
                path={`${itemPath}.items.${i}.title`}
                label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.menuPointTitle')}
                defaultValue=''
              />
              {item.type === LinkType.LINK && (
                <TextInput
                  path={`${itemPath}.items.${i}.url`}
                  label={t('Settings.LanguageSettings.LanguageMenuSettings.url')}
                  defaultValue=''
                />
              )}
              <TextInput
                path={`${itemPath}.items.${i}.cssClass`}
                label={t('Settings.LanguageSettings.LanguageFooterSettings.menu.cssClass')}
                defaultValue=''
              />
            </Container>
          </Container>
        </LinkListItem>
      ))}
      <Links
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        showLinkItems={false}
        showLinkDescriptionField={false}
        showLinkStyleSelector={false}
        showListChildrenInput={false}
        linkItems={items as unknown as Array<Page | LinkExternal>}
        linkTypes={[LinkType.PAGE, LinkType.LINK]}
        blockPath={itemPath}
      />
    </Container>
  );
};

export default LanguageFooterMenuSubMenuSettings;
