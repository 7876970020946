import React, { FC } from 'react';
import { WeekDay } from '@/declarations/WeekDay';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface WeekdaySelectorProps {
  value: Array<WeekDay>;
  onChange: (v: Array<WeekDay>) => void;
  label: string;
}

function parseValue(val?: WeekDay[] | string): WeekDay[] {
  if (typeof val === 'string') {
    return val.split(',').map((str) => {
      return str as WeekDay;
    });
  }
  return val ?? [];
}

const weekdayAsNumber = Object.values(WeekDay).reduce((acc, v, i) => {
  acc[v] = i;
  return acc;
}, {} as Record<WeekDay, number>);
function chronologically(a: WeekDay, b: WeekDay): number {
  return weekdayAsNumber[a] - weekdayAsNumber[b];
}

export const WeekdaySelector: FC<WeekdaySelectorProps> = ({ label, value, onChange }) => {
  const { t: tCommon } = useTranslation('common');

  const handleChange = (e: SelectChangeEvent<typeof value>) => {
    const newVal = parseValue(e.target.value);
    onChange(newVal);
  };

  return (
    <FormControl
      sx={{
        minWidth: 200,
      }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value ?? []}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.sort(chronologically).map((wd) => (
              <Chip size='small' key={wd} label={tCommon(`WeekDay.${wd}`)} />
            ))}
          </Box>
        )}>
        {Object.values(WeekDay).map((wd) => (
          <MenuItem key={wd} value={wd}>
            {tCommon(`WeekDay.${wd}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
