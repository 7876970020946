import React, { FC } from 'react';
import { GenericMedia } from '@/declarations/GenericMedia';
import { Page } from '@/declarations/models/Page';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import { Box, IconButton, List, ListItem, Tooltip, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';
import Image from '@/components/Image';
import Grid from '@mui/material/Grid';
import { formatAPITimestamp } from '@/utils/dates';

const Details = ({ item }: { item: GenericMedia<Page> }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Container column spaceBetween left fullWidth fullHeight>
      <Typography variant='body2' sx={{ fontWeight: 500 }}>
        {item.title}
      </Typography>

      <List dense>
        {item.source.event_data ? (
          <>
            {item.source.event_data.time_periods?.map((tp, i) => (
              <ListItem
                /* eslint-disable-next-line react/no-array-index-key */
                key={i}
                sx={{
                  display: 'flex',
                  gap: 1,
                  p: 0,
                }}>
                <Typography variant='body2' sx={{ fontWeight: 500 }}>
                  {tCommon('date')}:
                </Typography>
                <Typography variant='body2'>{formatAPITimestamp(tp.start_date)}</Typography>
              </ListItem>
            ))}
            <ListItem
              sx={{
                display: 'flex',
                gap: 1,
                p: 0,
              }}>
              <Typography variant='body2' sx={{ fontWeight: 500 }}>
                {tCommon('place')}:
              </Typography>
              <Typography variant='body2'>{item.source.location?.title}</Typography>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              sx={{
                display: 'flex',
                gap: 1,
                p: 0,
              }}>
              <Typography variant='body2' sx={{ fontWeight: 500 }}>
                {tCommon('lastEdited')}:
              </Typography>
              <Typography variant='body2'>{formatAPITimestamp(item.source.created_at)}</Typography>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                gap: 1,
                p: 0,
              }}>
              <Typography variant='body2' sx={{ fontWeight: 500 }}>
                {tCommon('status')}:
              </Typography>
              <Typography variant='body2'>{tCommon(`Status.${item.source.status}`)}</Typography>
            </ListItem>
          </>
        )}
      </List>
    </Container>
  );
};

const ImagePreview = ({ item }: { item: GenericMedia<Page> }) => {
  let imgMimeType = '';
  if (item?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item.mimetype}`;
  }
  return (
    <Box
      sx={{
        height: '100px',
        width: '150px',
      }}>
      <Image
        src={`${item.url}?&dimension=250x250&${imgMimeType}`}
        sx={{
          overflow: 'hidden',
          objectFit: 'cover',
          height: '100%',
          width: '100%',
        }}
      />
    </Box>
  );
};

export interface M24PageFinderSelectedItemProps {
  item: GenericMedia<Page>;
  onRemove: () => void;
}

export const M24PageFinderSelectedItem: FC<M24PageFinderSelectedItemProps> = ({ item, onRemove }) => {
  const { t: tCommon } = useTranslation('common');

  return (
    <Grid
      container
      sx={{
        py: 2,
        borderBottom: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
      }}>
      <Grid
        item
        xs={11}
        sx={{
          display: 'flex',
          gap: 2,
        }}>
        <Box>
          <ImagePreview item={item} />
        </Box>
        <Box flexGrow={1}>
          <Details item={item} />
        </Box>
      </Grid>

      <Grid item xs={1}>
        <Tooltip title={tCommon('remove')}>
          <IconButton onClick={onRemove}>
            <MaterialSymbol name='cancel' fill />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
