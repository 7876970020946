import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../../../CurrentBlockProvider';
import { BannerBlock } from '../../../../../declarations/models/blocks/BannerBlock';
import { BlockSpecificPage } from '../../../../lib/declarations/BlockSpecificPage';
import ActionMenuButton from '../../../../../components/ActionMenuButton';
import { VerticalAlignment } from '../../../../../declarations/models/VerticalAlignment';
import CustomIcon from '../../../../../components/CustomIcon';

const VERTICAL_ALIGNMENT_OPTIONS: Array<VerticalAlignment> = Object.values(VerticalAlignment);
const VERTICAL_ALIGNMENT_ICONS: Record<VerticalAlignment, ReactNode> = {
  [VerticalAlignment.DEFAULT]: <MaterialSymbol name='vertical_align_bottom' />,
  [VerticalAlignment.TOP]: <MaterialSymbol name='vertical_align_top' />,
  [VerticalAlignment.MIDDLE]: <MaterialSymbol name='vertical_align_center' />,
  [VerticalAlignment.BOTTOM]: <MaterialSymbol name='vertical_align_bottom' />,
  [VerticalAlignment.JUSTIFY]: <MaterialSymbol name='vertical_distribute' />,
};

export interface BannerBlockTitleAlignmentSelectorProps {
  width?: string;
}

export const BannerBlockTitleVerticalAlignmentSelector: FC<BannerBlockTitleAlignmentSelectorProps> = ({ width }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<BannerBlock & { textValign?: VerticalAlignment }>,
    `${typeof blockPath}.textValign`
  >({
    name: `${blockPath}.textValign`,
  });

  useEffect(() => {
    if (!value || !VERTICAL_ALIGNMENT_OPTIONS.includes(value)) {
      onChange(VerticalAlignment.MIDDLE);
    }
  }, [onChange, value]);

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.BannerTitle.BannerBlockTitleVerticalAlignmentSelector.ActionMenuLabel')}
      tooltip={tComponents('BannerBlock.BannerTitle.BannerBlockTitleVerticalAlignmentSelector.tooltip')}
      tooltipPlacement='top'
      icon={value ? VERTICAL_ALIGNMENT_ICONS[value] : VERTICAL_ALIGNMENT_ICONS.middle}
      width={width}>
      {VERTICAL_ALIGNMENT_OPTIONS.map((vAlignmentOption) => (
        <MenuItem
          key={vAlignmentOption}
          onClick={() => onChange(vAlignmentOption)}
          selected={value === vAlignmentOption}
          aria-label={tComponents(
            `BannerBlock.BannerTitle.BannerBlockTitleVerticalAlignmentSelector.${vAlignmentOption}`,
          )}>
          <ListItemIcon>{VERTICAL_ALIGNMENT_ICONS[vAlignmentOption]}</ListItemIcon>
          <ListItemText>
            {tComponents(`BannerBlock.BannerTitle.BannerBlockTitleVerticalAlignmentSelector.${vAlignmentOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default BannerBlockTitleVerticalAlignmentSelector;
