import React, { FC } from 'react';
import { M24MediaModel } from '../../../../../declarations/models/M24MediaModel';
import {
  ExternalMediaModel,
  isDiMuMediaModel,
  isVimeoMediaModel,
  isYouTubeMediaModel,
} from '../../../../../declarations/models/ExternalMediaModel';
import { isExternalMediaModel, isM24MediaModel } from '../../../../../utils/typeChecks';
import M24Header from './M24Header';
import DMHeader from './DMHeader';
import YouTubeHeader from './YouTubeHeader';
import VimeoHeader from './VimeoHeader';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface MediaBlockHeaderProps {
  item: M24MediaModel | ExternalMediaModel;
  open: boolean;
}

export const MediaBlockHeader: FC<MediaBlockHeaderProps & ChildrenProp> = ({ children, item, open = false }) => {
  if (isM24MediaModel(item)) {
    return (
      <M24Header open={open} item={item}>
        {children}
      </M24Header>
    );
  }
  if (isDiMuMediaModel(item)) {
    return (
      <DMHeader open={open} item={item}>
        {children}
      </DMHeader>
    );
  }
  if (isYouTubeMediaModel(item)) {
    return <YouTubeHeader item={item}>{children}</YouTubeHeader>;
  }
  if (isVimeoMediaModel(item)) {
    return <VimeoHeader item={item}>{children}</VimeoHeader>;
  }
  if (isExternalMediaModel(item)) {
    return <p>TODO: OtherMediaHeader</p>;
  }
  return null;
};

export default MediaBlockHeader;
