import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, IconButton, Radio, Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import KPLogo from '../../../../components/icons/KPLogo';
import { DraggableContainerProps } from '../../../../components/DraggableContainer';
import { Draggable } from '../../../../declarations/Draggable';
import Container from '../../../../components/Container';
import Styles from '../../../../assets/js/Styles';
import { noWrapTextStyle } from '../../../../views/MediaView/MediaViewTypes/ListViewItem';
import { resolveLocalizedString } from '../../../../utils/strings';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { Api } from '../../../../services/Api';
import { KPDataItem, KPOwnerBlockModel, KPOwnerItem } from '../../../../declarations/models/blocks/KPBlock';
import { KPOwner } from '../../../../declarations/models/KP';
import TextInput from '../../../../components/forms/TextInput';
import ListImage from '../../../../components/ListImage';
import DraggableCollapseContainer from '../../../../components/DraggableCollapseContainer';

export type KPBlockItemPathPrefix = `${BlockPath}.owners.${number}`;

export const getSizeButton = (val: KPDataItem, onChange: () => void, tooltip: string) => {
  // TODO: Make some better icons?
  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onChange} sx={{ position: 'relative', width: '24px' }}>
        {val.size === 'L' ? (
          <MaterialSymbol name='rectangle' fill sx={{ position: 'absolute' }} />
        ) : (
          <MaterialSymbol name='square' sx={{ position: 'absolute', padding: '1px' }} />
        )}
        <Typography variant='caption' color={Styles.Colors.KP_OWNER} sx={{ position: 'absolute' }}>
          {val.size}
        </Typography>
      </IconButton>
    </Tooltip>
  );
};

export const KPBlockOwnerItem: FC<
  Pick<DraggableContainerProps<KPOwnerItem>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'>
> = ({ index, onReorder, onRemove, onAdd }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComp } = useTranslation('components');
  const currentLang = i18next.language;
  const { blockPath } = useCurrentBlock();
  const itemPathPrefix: KPBlockItemPathPrefix = `${blockPath}.owners.${index}`;
  const [owner, setOwner] = useState<KPOwner>();

  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<KPOwnerBlockModel>, typeof itemPathPrefix>({
    name: itemPathPrefix,
  });

  const val = value as KPOwnerItem;

  useEffect(() => {
    if (val.id) {
      const ctx = Api.getKPOwner(val.id);
      ctx
        .fetchDirect(null)
        .then((e) => e && setOwner(e))
        .finally(ctx.abort);
    }
  }, [val.id]);

  const handleListPresentations = (v: boolean) => {
    onChange({
      ...val,
      listPresentations: v,
    });
  };

  const handleChangeSize = () => {
    onChange({
      ...val,
      size: val.size === 'L' ? 'M' : 'L',
    });
  };

  const imageSrc = owner?.image_url;
  const desc = resolveLocalizedString(currentLang, owner?.description);
  const title = resolveLocalizedString(currentLang, owner?.name);
  return (
    <DraggableCollapseContainer
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      type={Draggable.KP_ITEM}
      value={val}
      color={Styles.Colors.KP_OWNER}
      secondaryAction={
        <>
          {getSizeButton(val, handleChangeSize, tCommon('changeItemSize'))}
          <DeleteButton
            onConfirm={() => onRemove?.(index)}
            component='IconButton'
            tooltip={tCommon('remove')}
            deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
          />
        </>
      } // eslint-disable-next-line react/no-unstable-nested-components
      headerContent={() => (
        <Container left fullWidth>
          <ListImage src={imageSrc} alt={desc} fallbackImage={<KPLogo />} />
          {owner ? (
            <Typography sx={noWrapTextStyle} fontWeight={500}>
              {title}
            </Typography>
          ) : (
            <Typography color={Styles.Colors.MEDIUM_GREY} fontStyle='italic'>
              {tCommon('loading')}
            </Typography>
          )}
        </Container>
      )}>
      <Container left column fullWidth px={2} py={1}>
        <TextInput path={`${itemPathPrefix}.altTitle`} label={tComp('DMBlock.AltTitleLabel')} size='small' />
        <TextInput
          path={`${itemPathPrefix}.altDescription`}
          label={tComp('DMBlock.AltDescriptionLabel')}
          multiline
          minRows={1}
          maxRows={8}
          size='small'
        />
        <Container left fullWidth wrap>
          <FormControlLabel
            checked={!val?.listPresentations}
            onChange={() => handleListPresentations(false)}
            control={<Radio />}
            label={tComp('KPBlock.JustBanner')}
          />
          <FormControlLabel
            checked={!!val?.listPresentations}
            onChange={() => handleListPresentations(true)}
            control={<Radio />}
            label={tComp('KPBlock.ListPresentations')}
          />
        </Container>
      </Container>
    </DraggableCollapseContainer>
  );
};

export default KPBlockOwnerItem;
