import React, { forwardRef } from 'react';
import { Page } from '@/declarations/models/Page';
import Container, { ContainerProps } from '@/components/Container';
import { ButtonBase, Typography } from '@mui/material';
import { mergeSx } from '@/utils/mergeSx';
import Styles from '@/assets/js/Styles';

interface PageListRowProps extends Omit<ContainerProps, 'onClick'> {
  page: Page;
  onClick?: (p: Page) => void;
  actionChildren?: React.ReactNode;
}

/**
 * Simple listable component for a Page.
 */
const PageListRow = forwardRef<HTMLElement, PageListRowProps>(
  ({ page, children, actionChildren, onClick, ...rest }, ref) => {
    const NameColumn = (
      <Container
        column
        left
        top
        sx={{
          flex: 1,
        }}>
        <Typography variant='subtitle2' fontSize='medium' component='span' noWrap>
          {page.title}
        </Typography>
        {page.description && (
          <Typography variant='caption' fontSize='small' color='text.secondary' noWrap>
            {page.description}
          </Typography>
        )}
      </Container>
    );

    const ChildrenColumn = (
      <Container
        sx={{
          flex: 1,
          backgroundColor: 'peru',
        }}>
        {children}
      </Container>
    );

    return (
      <Container
        ref={ref}
        fullWidth
        spaceBetween
        {...rest}
        sx={mergeSx(
          {
            backgroundColor: Styles.Colors.LIGHT_GREY,
            p: 1,
          },
          rest.sx ?? {},
        )}>
        <>
          {onClick ? (
            <ButtonBase
              role={rest.role}
              onClick={() => onClick(page)}
              sx={{
                width: '100%',
              }}>
              {NameColumn}
            </ButtonBase>
          ) : (
            NameColumn
          )}
          {ChildrenColumn}
          {actionChildren && <Container>{actionChildren}</Container>}
        </>
      </Container>
    );
  },
);

PageListRow.displayName = 'PageListRow';

export { PageListRow };
