import React, { FC } from 'react';
import Header from '@/components/Header';
import Container from '@/components/Container';
import { Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormModule } from '@/declarations/models/FormModule';
import { formatAPITimestamp } from '@/utils/dates';
import SplitButton from '@/components/SplitButton';
import { SaveType } from '@/editor/lib/declarations/SaveType';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';

export interface M24FormEditorHeaderProps {
  onSave: (saveType: SaveType) => Promise<void>;
  form?: FormModule;
  isLoading?: boolean;
  menuOpen?: boolean;
  onToggleMenuOpen?: () => void;
}

const M24FormEditorHeader: FC<M24FormEditorHeaderProps> = ({ form, isLoading, onSave, menuOpen, onToggleMenuOpen }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { t: tAria } = useTranslation('aria');
  const navigate = useNavigate();

  const splitButtonOptions: SaveType[] = [];
  if (form?.status === 'draft') {
    splitButtonOptions.push(SaveType.SAVE_AND_PUBLISH);
  }
  if (form?.status === 'published') {
    splitButtonOptions.push(SaveType.SAVE_AS_NEW_VERSION);
    splitButtonOptions.push(SaveType.SAVE_AND_UNPUBLISH);
  }

  return (
    <Header
      additionalContentLeft={
        <Container spaceBetween fullWidth>
          <Container left pl={1}>
            <Tooltip title={tComponents('EditorHeader.GoBack')} arrow>
              <IconButton
                sx={{
                  backgroundColor: Styles.Colors.LIGHT_GREY,
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                  '&:hover': {
                    backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
                  },
                }}
                color='inherit'
                edge='start'
                size='small'
                aria-label={tAria('components.EditorHeader.GoBack')}
                onClick={() => navigate('/forms')}>
                <MaterialSymbol name='close' />
              </IconButton>
            </Tooltip>
            <Typography fontSize={16} variant='h6'>
              {tCommon('BlockType.form')}:{' '}
              {isLoading ? tCommon('loading') : form?.title ?? tComponents('FormEditor.NewForm')}
            </Typography>
          </Container>
          <Container right>
            {!!form?.status && (
              <Chip
                color={form.status === 'published' ? 'success' : 'warning'}
                variant='filled'
                label={tCommon(`Status.${form.status}`)}
              />
            )}
            {form?.updated_at && (
              <Chip label={tComponents('EditorHeader.LastUpdatedAt', { at: formatAPITimestamp(form.updated_at) })} />
            )}

            <SplitButton
              variant='contained'
              color='secondary'
              label={tCommon('save')}
              onClick={() => onSave(SaveType.SAVE)}
              options={splitButtonOptions}
              getItemLabel={(item) => tCommon(`SaveType.${item}`)}
              onItemClick={(saveType) => onSave(saveType)}
            />
          </Container>
        </Container>
      }
      hideLogo
      menuOpen={menuOpen}
      onToggleMenuOpen={onToggleMenuOpen}
      elevation={2}
    />
  );
};

export default M24FormEditorHeader;
