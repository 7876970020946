import React, { FC, Fragment } from 'react';
import { FormModule, FormModuleInput, UserFormBlockComplete } from '@/declarations/models/FormModule';
import { Box, Button } from '@mui/material';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';

const TextInputWithLabel = ({
  label,
  inputType = 'text',
  required = false,
}: {
  label: string;
  inputType?: string;
  required?: boolean;
}) => {
  const inputStyle = {
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
    width: '100%',
    lineHeight: 2,
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}>
      <label style={{ fontSize: '15px', fontWeight: '500', marginBottom: '4px' }} htmlFor={label}>
        {label || '(Mangler ledetekst)'} {required && <>*</>}
      </label>
      <div
        style={{
          width: '100%',
        }}>
        {inputType !== 'textarea' && <input style={inputStyle} type={inputType} id={label} name={label} />}
        {inputType === 'textarea' && <textarea style={inputStyle} id={label} name={label} />}
      </div>
    </div>
  );
};

const OptionsType = ({ input }: { input: FormModuleInput & { type: 'options' } }) => {
  if (input.subtype === 'radio') {
    return (
      <Box>
        <div style={{ fontSize: '15px', fontWeight: '500', marginBottom: '8px' }}>{input.label ?? 'Ett valg'}</div>
        <Box display='flex' width='100%' flexDirection='column' gap={1}>
          {input.options?.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i}>
              <Box>
                <input
                  defaultChecked={input.value === option.label}
                  type='radio'
                  id={option.label}
                  name={input.label}
                  value={option.label}
                />
                <label style={{ fontSize: '15px', marginLeft: '5px' }} htmlFor={option.label}>
                  {option.label}
                </label>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  if (input.subtype === 'checkbox') {
    return (
      <Box>
        <div style={{ fontSize: '15px', fontWeight: '500', marginBottom: '8px' }}>{input.label}</div>
        <Box display='flex' width='100%' flexDirection='column' gap={1}>
          {input.options?.map((option, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i}>
              <input
                defaultChecked={option.value}
                type='checkbox'
                id={option.label}
                name={input.label}
                value={option.label}
              />
              <label style={{ fontSize: '15px', marginLeft: '5px' }} htmlFor={option.label}>
                {option.label}
              </label>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return <>Options preview not implemented</>;
};
const CheckboxType = ({ input }: { input: FormModuleInput & { type: 'checkbox' } }) => {
  return (
    <Box mb={1} mt={1}>
      <input type='checkbox' id={input.label} name={input.label} value={input.label} />
      <label style={{ fontSize: '15px', marginLeft: '5px' }} htmlFor={input.label}>
        {input.label} {input.required && <>*</>}
      </label>
    </Box>
  );
};
const ButtonType = ({ input }: { input: FormModuleInput & { type: 'button' } }) => {
  const { t: tComponents } = useTranslation('components');
  const defaultLabel = tComponents(`FormEditor.Preview.button.${input.subtype || 'default'}`);
  return (
    <Container right={input.subtype !== 'prev'} left={input.subtype === 'prev'} fullWidth>
      <button
        type='button'
        style={{
          backgroundColor: 'white',
          border: '1px solid black',
          padding: '.5rem 1rem',
          cursor: 'pointer',
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }}>
        {input.label ?? defaultLabel}
      </button>
    </Container>
  );
  // return <button>{input.label}</button>;
};
const UserType = ({ input }: { input: FormModuleInput & { type: 'user' } }) => {
  const firstNameLabel = input.firstname?.label || 'Fornavn';
  const lastNameLabel = input.lastname?.label || 'Etternavn';
  const emailLabel = input.email?.label || 'E-post';
  const confirmEmailLabel = input.emailConfirm?.label || 'Bekreft E-post';
  const birthDateLabel = input.birth?.labelDay || 'Dag:';
  const birthMonthLabel = input.birth?.labelMonth || 'Måned:';
  const birthYearLabel = input.birth?.labelYear || 'År:';
  const countrySelectLabel = (input as UserFormBlockComplete).countryCode?.label ?? 'Land';
  return (
    <Box width='100%' display='flex' gap={2} flexDirection='column'>
      <TextInputWithLabel label={firstNameLabel} required />
      <TextInputWithLabel label={lastNameLabel} required />
      {input.birth?.enabled && (
        <>
          <div>{input.birth?.label ?? 'Født:'}</div>
          <Box display='flex' gap={2}>
            <TextInputWithLabel label={birthDateLabel} inputType='numeric' />
            <TextInputWithLabel label={birthMonthLabel} inputType='numeric' />
            <TextInputWithLabel label={birthYearLabel} inputType='numeric' />
          </Box>
        </>
      )}
      <TextInputWithLabel label={emailLabel} inputType='email' required />
      {input.confirmEmail && <TextInputWithLabel label={confirmEmailLabel} inputType='email' />}
      {input.subtype !== 'minimum' && (
        <TextInputWithLabel label={input.phone?.label || 'Telefon'} inputType='tel' required={input.phone?.required} />
      )}
      {input.subtype === 'complete' && (
        <>
          <TextInputWithLabel label={input.address?.label || 'Adresse'} required={input.address?.required} />
          {input.address2?.enabled && <TextInputWithLabel label={input.address2?.label || 'Adresse 2'} />}
          <Box display='flex' gap={2}>
            <Box flexGrow={1}>
              <TextInputWithLabel label={input.postalCode?.label || 'Postnr'} required={input.postalCode?.required} />
            </Box>
            <Box flexGrow={12}>
              <TextInputWithLabel
                label={input.postalPlace?.label || 'Poststed'}
                required={input.postalPlace?.required}
              />
            </Box>
          </Box>
          {input.state?.enabled && <TextInputWithLabel label={input.state?.label || 'Fylke'} />}
          {input.countryCode?.enabled && (
            <Box>
              <label style={{ fontSize: '15px', fontWeight: '500', marginBottom: '8px' }} htmlFor={countrySelectLabel}>
                {countrySelectLabel}
              </label>
              <select
                id={countrySelectLabel}
                style={{
                  display: 'block',
                  padding: '1rem 0.5rem',
                }}>
                <option value=''>Velg land</option>
                <option value='countries'>Komplett liste over nasjoner</option>
              </select>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const ContentType = ({ input }: { input: FormModuleInput & { type: 'content' } }) => {
  return (
    <Box>
      {input.title && (
        <h2 style={{ fontSize: '18px', fontWeight: '500', marginBottom: '8px', marginTop: '0' }}>{input.title}</h2>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      <Box fontSize={14}>{input.text && <p dangerouslySetInnerHTML={{ __html: input.text }} />}</Box>
    </Box>
  );
};

const InputType = ({ input }: { input: FormModuleInput & { type: 'input' } }) => {
  const { t: tComponents } = useTranslation('components');
  switch (input.subtype) {
    case 'text':
    case 'email':
    case 'date':
    case 'textarea':
      return <TextInputWithLabel label={input.label} inputType={input.subtype} required={input?.required} />;
    case 'file':
      return (
        <Box width='100%' mb={1}>
          <div style={{ fontSize: '15px', fontWeight: '500', marginBottom: '4px' }}>
            {input.label ?? tComponents('FormEditor.Preview.DefaultFileLabel')} {input?.required && '*'}
          </div>
          <Box display='flex' gap={2} width='100%'>
            <Button type='button' variant='contained'>
              {input.buttonLabel ?? tComponents('FormEditor.Preview.DefaultFileButtonLabel')}
            </Button>
            <input
              type='text'
              disabled
              placeholder='[JPG, PNG, DOC, XLS, PDF]'
              style={{
                flexGrow: 1,
                padding: '0.5rem',
                lineHeight: 2,
              }}
            />
          </Box>
        </Box>
      );
    case 'address':
      return (
        <Box width='100%'>
          <Box flexBasis='100%'>
            <TextInputWithLabel
              label={input.address?.label || tComponents('FormEditor.Address.AddressLabel')}
              required={input.required}
            />
          </Box>
          <Box mt={1} flexBasis='100%' display='flex' flexWrap='wrap' gap={2}>
            <Box flexGrow={1}>
              <TextInputWithLabel
                label={input.postalCode?.label || tComponents('FormEditor.Address.PostalCodeLabel')}
                required={input.required}
              />
            </Box>
            <Box flexGrow={12}>
              <TextInputWithLabel
                label={input.postalPlace?.label || tComponents('FormEditor.Address.PostalPlaceLabel')}
                required={input.required}
              />
            </Box>
          </Box>
        </Box>
      );
    case 'name':
      return (
        <>
          <TextInputWithLabel
            label={input.firstname?.label || tComponents('FormEditor.input.FirstNameLabel')}
            required={input.required}
          />
          <TextInputWithLabel
            label={input.lastname?.label || tComponents('FormEditor.input.LastNameLabel')}
            required={input.required}
          />
        </>
      );
    default:
      return <div>preview not implemented</div>;
  }
};

const InputPreview = ({ input }: { input: FormModuleInput }) => {
  const renderContent = () => {
    switch (input.type) {
      case 'input':
        return <InputType input={input} />;
      case 'content':
        return <ContentType input={input} />;
      case 'options':
        return <OptionsType input={input} />;
      case 'checkbox':
        return <CheckboxType input={input} />;
      case 'button':
        return <ButtonType input={input} />;
      case 'user':
        return <UserType input={input} />;
      default:
        return <div>preview not implemented</div>;
    }
  };

  return (
    <Container fullWidth column gap={1} left>
      {renderContent()}
    </Container>
  );
};

export interface M24FormPreviewProps {
  form?: FormModule;
  onBlockClick?: (sectionIndex: number, blockIndex: number) => void;
}

const M24FormPreview: FC<M24FormPreviewProps> = ({ form, onBlockClick }) => {
  return (
    <Container
      column
      fullWidth
      left
      sx={{
        overflow: 'auto',
        maxWidth: '600px',
        background: '#fff',
        marginTop: '20px',
        borderRadius: '5px',
      }}>
      <h1 style={{ marginLeft: '16px', fontSize: '20px' }}>{form?.title}</h1>
      {form?.content?.sections
        ?.filter((section) => section.hidden !== true)
        ?.map((section, sectionIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={sectionIndex}>
            {sectionIndex !== 0 && (
              <hr
                style={{
                  width: '100%',
                  border: `4px solid ${Styles.Colors.PREVIEW_BG_COLOR}`,
                }}
              />
            )}
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '100%',
                padding: '1rem',
              }}>
              {section.inputs
                ?.filter((input) => input.hidden !== true)
                ?.map((input, blockIndex) => {
                  return (
                    <Container
                      fullWidth
                      top
                      left
                      // eslint-disable-next-line react/no-array-index-key
                      key={blockIndex}
                      onClick={() => onBlockClick?.(sectionIndex, blockIndex)}>
                      <InputPreview input={input} />
                    </Container>
                  );
                })}
            </section>
          </Fragment>
        ))}
    </Container>
  );
};

export default M24FormPreview;
