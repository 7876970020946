import React, { useEffect, useState } from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { DMSingleBlockModel } from '../../../../declarations/models/blocks/DMBlock';
import { Api } from '../../../../services/Api';
import { DMArtifact } from '../../../../declarations/models/DMMediaModel';

export const DMBlockSinglePreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const dmBlock = block as DMSingleBlockModel;

  const [dmItem, setDmItem] = useState<DMArtifact>();

  useEffect(() => {
    if (dmBlock.dmItem?.uniqueId) {
      if (dmBlock.dmItem.uniqueId) {
        const ctx = Api.getDMArtifact(dmBlock.dmItem.uniqueId);

        ctx
          .fetchDirect(null)
          .then((res) => !!res && setDmItem(res))
          .finally(ctx.abort);
      }
    } else setDmItem(undefined);
  }, [dmBlock.dmItem]);

  const images = dmItem?.images;
  return (
    <div className='article__grid'>
      <div className={`article__content layout--${dmBlock.layout || 'auto'}`}>
        {dmBlock.visibletitle && (
          <div className='ekultur__intro'>
            <header className='article__bodytext'>
              <h2>{dmBlock.title || dmItem?.title}</h2>
            </header>
          </div>
        )}
        {!!images?.length && (
          <figure className={`article__media module--media media-format--auto layout--${dmBlock.layout || 'auto'}`}>
            <div className='module__media'>
              <img
                src={images[images.length > dmBlock.selectedImgIndex ? dmBlock.selectedImgIndex : 0].url}
                alt={dmItem?.motif?.description}
              />
            </div>
          </figure>
        )}
        <section className='article__bodytext'>
          <div className='module__body'>
            <p className='modulebodytext'>{dmBlock.body || dmItem?.description}</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DMBlockSinglePreview;
