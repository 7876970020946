import React, { FC } from 'react';
import { M24MediaModel } from '../../../../../declarations/models/M24MediaModel';
import {
  ExternalMediaModel,
  isDiMuMediaModel,
  isVimeoMediaModel,
  isYouTubeMediaModel,
} from '../../../../../declarations/models/ExternalMediaModel';
import { isExternalMediaModel, isM24MediaModel } from '../../../../../utils/typeChecks';
import M24Content from './M24Content';
import { BlockItemPathPrefix } from '../../../../../declarations/models/BlockItemPathPrefix';
import DMContent from './DMContent';
import YouTubeContent from './YouTubeContent';
import VimeoContent from './VimeoContent';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface MediaBlockItemContentProps {
  item: M24MediaModel | ExternalMediaModel;
  pathPrefix: BlockItemPathPrefix;
  editMedia: () => void;
}

export const MediaBlockItemContent: FC<MediaBlockItemContentProps & ChildrenProp> = ({
  children,
  item,
  pathPrefix,
  editMedia,
}) => {
  if (isM24MediaModel(item)) {
    return (
      <M24Content item={item} pathPrefix={pathPrefix} editMedia={editMedia}>
        {children}
      </M24Content>
    );
  }
  if (isDiMuMediaModel(item)) {
    return (
      <DMContent item={item} pathPrefix={pathPrefix}>
        {children}
      </DMContent>
    );
  }
  if (isYouTubeMediaModel(item)) {
    return <YouTubeContent item={item}>{children}</YouTubeContent>;
  }
  if (isVimeoMediaModel(item)) {
    return <VimeoContent item={item}>{children}</VimeoContent>;
  }
  if (isExternalMediaModel(item)) {
    return <p>TODO: Other Content</p>;
  }
  return null;
};

export default MediaBlockItemContent;
