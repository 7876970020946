import React, { FC, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useDebounce } from '@/hooks/useDebounce';
import type { Editor as EditorType } from 'tinymce';
import { WysiwygEditorProps } from '@/editor/PageEditor/EditorBlock/TextBlock/WysiwygEditor';

export const TinyEditor: FC<WysiwygEditorProps> = ({ value, onChange, toolbar }) => {
  const [editorValue, setEditorValue] = useState(value || '');
  useEffect(() => {
    setEditorValue(value || '');
  }, [value]);

  const editorRef = useRef<EditorType | null>(null);
  let cleaned_string;
  useDebounce(400, editorValue, onChange);

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      value={editorValue}
      onEditorChange={(newValue) => {
        setEditorValue(newValue);
      }}
      onPaste={(e, editor) => {
        console.log('onPaste editor:', editor);
        console.log('onPaste e:', e);
        // e.preventDefault();
        // GET AND WASH THE CONTENT: (OR CAN WE USE PASTE_PLUGIN OR PASTE_PREPROSESS?)
        // PUT IT INTO EDITOR:
        // editor.execCommand('mceInsertContent', false, content);
      }}
      init={{
        width: '100%',
        menubar: false,
        contextmenu: false,
        plugins: ['autoresize', 'lists', 'link', 'anchor', 'code'],
        toolbar: toolbar ?? 'blocks | bold italic | bullist numlist | link | removeformat | code | paste',
        content_style:
          'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:14px;min-height: 100px; line-height: 1.5; } ul { padding-left: 1.5em; list-style-type: disc; margin: 1.5em 0; } ul ul { padding-left:1.5em; margin: 0; } li { margin: 0.25em 0; } h3,h4,h5 { margin-top:2em;margin-bottom: -.75em; } h4 { font-style: italic; } h5 { font-size: .9em; text-transform: uppercase; } .mce-content-body { min-height: 100px; }',
        block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5;',
        max_height: 400,
        min_height: 300,
        extended_valid_elements: 'b',
        forced_root_block: '',
        force_br_newlines: true,
        force_p_newlines: false,
        paste_preprocess: (editor, e) => {
          console.log('paste_postprocess:e:', e);
          console.log('paste_postprocess:Orginal content:', e.content);
          cleaned_string = e.content;
          // cleaned_string = cleaned_string.replace(/style="[a-zA-Z0-9:;\.\s\(\)\-\,]*"/gi, '');
          cleaned_string = cleaned_string.replace(/style=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/class=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/id=".*?"/gi, '');
          cleaned_string = cleaned_string.replace(/\sdata-[^"'\s]+(?:=["'][^"']*["'])?=["'][^"']*["']/gi, '');
          cleaned_string = cleaned_string.replace(/\saria-[^"'\s]+(?:=["'][^"']*["'])?=["'][^"']*["']/gi, '');
          cleaned_string = cleaned_string.replace(/\sng-[^"'\s]+(?:=["'][^"']*["'])?=["'][^"']*["']/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?span[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<button[^>]*>.*?<\/button>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?section[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?article[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?main[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?nav[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?footer[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?header[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?figure[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?figcaption[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?img[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?video[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?audio[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?div[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?fieldset[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?label[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?checkbox[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?radio[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?input[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?iframe[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?small[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<\/?smaller[^>]*>/gi, '');
          cleaned_string = cleaned_string.replace(/<h1>/gi, '<h2>');
          cleaned_string = cleaned_string.replace(/<\/h1>/gi, '</h2>');
          cleaned_string = cleaned_string.replace(/<(|\/)(html|body|meta)[^>]*?>/gi, '').trim();
          cleaned_string = cleaned_string.replace(/^\s*(<br\s*\/?\s*>)+|(<br\s*\/?\s*>)+\s*$/gi, '');
          cleaned_string = cleaned_string.replace(/<(\w+)\s+>/g, '<$1>');
          cleaned_string = cleaned_string.replace(/<li>&nbsp;<\/li>/gi, '');
          cleaned_string = cleaned_string.replace(/<li><\/li>/gi, '');
          cleaned_string = cleaned_string.replace(/<ul><\/ul>/gi, '');

          console.log('paste_postprocess:Cleaned up content:', cleaned_string);

          e.content = cleaned_string;
          // editor.execCommand('mceInsertContent', false, cleaned_string);
        },
        formats: {
          bold: { inline: 'b', remove: 'all' },
        },
        paste_block_drop: false,
        paste_merge_formats: true,
        paste_data_images: false,
      }}
    />
  );
};
