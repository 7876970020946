import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Loader from '../../../components/Loader';
import { useStore } from '../../../components/store/Store';
import { Api } from '../../../services/Api';
import { TagType } from '../../../declarations/models/TagType';
import { Tag } from '../../../declarations/models/Tag';
import { RequestContext } from '../../../utils/ApiRequest/RequestContext';
import BaseModel from '../../../declarations/models/BaseModel';
import { EditorDataSet } from '../declarations/EditorDataSet';
import { Skin } from '../../../declarations/models/Skin';
import Container from '../../../components/Container';
import Layout from '../../../components/Layout';
import { useEditorConfiguration } from '../configuration/EditorConfigurationContext';
import { Page } from '../../../declarations/models/Page';
import { Site } from '../../../declarations/models/Site';
import { usePageEditorFormState } from '../../PageEditor/PageEditorFormStateProvider';

export interface EditorDataProviderProps<Model extends object> {
  loadModel: (modelId: number, versionId?: number) => RequestContext<never, Model>;
}

export interface EditorData {
  modelId: number | null;
  versionId?: number | null;
  siteId: number;
  categories: Array<Tag>;
  skins: Array<Skin>;
  pageLocale: string | null;
}

const Data = createContext<EditorData | null>(null);

/**
 * Access the data loaded in context of the editor.
 *
 */
export function useEditorData(): EditorData {
  const data = useContext(Data);
  if (!data) {
    throw new Error('[EditorDataProvider] Data accessed before loaded/initialized');
  }
  return data;
}

/**
 * Utility-hook too quickly reference the current value of the form
 */
export function useEditorModel<Model extends object = BaseModel>(): Model {
  return useWatch() as unknown as Model;
}

/**
 * Responsible for loading and providing all required async/dynamic data
 * @param children
 * @param idPathParamName
 * @param loadModel
 * @constructor
 */
export const EditorDataProvider = <Model extends object>({
  children,
  loadModel,
}: PropsWithChildren<EditorDataProviderProps<Model>>) => {
  const { t } = useTranslation('common');
  const loadModelRef = useRef<typeof loadModel>(loadModel);
  loadModelRef.current = loadModel;

  const { state, changeSiteContext } = useStore();
  const params = useParams<{ modelId?: string }>();

  const [searchParams] = useSearchParams();

  const siteId = Number(state?.selectedSite?.id || 0);
  const modelId = Number(params?.modelId || '') || null;
  const versionId = Number(searchParams.get('versionId') || '') || undefined;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [categories, setCategories] = useState<Array<Tag>>([]);
  const [pageLocale, setPageLocale] = useState<string | null>(null);

  const config = useEditorConfiguration();
  const { setValue } = useFormContext();
  const { setInitialFormData } = usePageEditorFormState();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tCommon } = useTranslation('common');

  const data = useMemo<EditorData>(
    () => ({
      siteId,
      modelId,
      versionId,
      categories,
      skins: state?.selectedSiteSkins || [],
      pageLocale,
    }),
    [siteId, modelId, versionId, categories, state?.selectedSiteSkins, pageLocale],
  );

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    const dataSetsToLoad = config.getDataSetsToPrefetch();
    const shouldLoadDataSet = (dataSet: EditorDataSet) => dataSetsToLoad.includes(dataSet);
    const cancelFuncs: Array<() => void> = [];

    const reloadModel = async (): Promise<Model | null> => {
      if (modelId && modelId > 0) {
        const load = loadModelRef.current(modelId, versionId);
        cancelFuncs.push(load.abort);
        return load.fetchDirect(null);
      }
      return null;
    };

    const loadCategories = async (): Promise<EditorData[EditorDataSet.CATEGORIES]> => {
      if (!shouldLoadDataSet(EditorDataSet.CATEGORIES)) {
        return [];
      }
      const load = Api.getAllTagsForSite(siteId, { type: TagType.CATEGORY });
      cancelFuncs.push(load.abort);
      return load.fetchDirect([]);
    };

    const loadAvailableSites = async (): Promise<Array<Site> | []> => {
      const availableSites = Api.getAllSites({ start: 0, rows: 500, settings: true });
      return availableSites.fetchDirect([]);
    };

    let newSite: Site | undefined;

    Promise.all([reloadModel(), loadCategories(), loadAvailableSites()] as const)
      .then(([loadedModel, loadedCategories, loadedAvailableSites]) => {
        if (!unmounted) {
          const loadedPage = loadedModel as Page;
          setInitialFormData(loadedPage);
          // eslint-disable-next-line no-restricted-syntax
          for (const [name, value] of Object.entries(loadedPage || {})) {
            setValue(name, value); // Don't use reset(), it causes input fields getting incorrect data from the form's defaultValues after reordering
          }
          setCategories(loadedCategories);
          setPageLocale(loadedPage?.locale);
          if (loadedPage?.site_id && loadedPage?.site_id !== siteId) {
            newSite = loadedAvailableSites?.find((site) => site.id === loadedPage?.site_id);
            if (newSite) {
              changeSiteContext(newSite);
              enqueueSnackbar(tCommon('siteChanged'), { variant: 'info' });
            }
          }
        }
      })
      .finally(() => {
        if (!unmounted && !newSite) {
          setIsLoading(false);
        }
      });

    return () => {
      unmounted = true;
      cancelFuncs?.forEach((abort) => abort?.());
    };
  }, [modelId, versionId, siteId, config, setValue, changeSiteContext, enqueueSnackbar, tCommon, setInitialFormData]);

  if (isLoading) {
    return (
      <Layout>
        <Container fullHeight fullWidth>
          <Loader loadingText={t('loadingContent')} />
        </Container>
      </Layout>
    );
  }

  return <Data.Provider value={data}>{children}</Data.Provider>;
};

export default EditorDataProvider;
