import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Collapse, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SetAsMainImageCheckbox } from '@/editor/PageEditor/SetAsMainImageCheckbox';
import Container from '@/components/Container';
import DraggableCollapseContainer, {
  DraggableCollapseContainerProps,
} from '../../../../components/DraggableCollapseContainer';
import { Draggable } from '../../../../declarations/Draggable';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { MediaBlock } from '../../../../declarations/models/blocks/MediaBlock';
import Styles from '../../../../assets/js/Styles';
import { deepCopy } from '../../../../utils/object';
import MediaBlockActions from './MediaBlockActions';
import MediaBlockHeader from './MediaBlockHeader/MediaBlockHeader';
import MediaBlockItemContent from './MediaBlockItemContent/MediaBlockItemContent';
import { isDiMuMediaModel } from '../../../../declarations/models/ExternalMediaModel';
import MediaOptionContainer from './MediaOptionContainer';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';
import { BlockItemPathPrefix } from '../../../../declarations/models/BlockItemPathPrefix';
import { M24MediaModel } from '../../../../declarations/models/M24MediaModel';
import { Api } from '../../../../services/Api';
import { isM24MediaModel } from '../../../../utils/typeChecks';
import { useMediaEditorContext } from '../../../MediaEditor/MediaEditorContext';

export interface MediaBlockItemProps<T>
  extends Pick<DraggableCollapseContainerProps<T>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'> {
  displayGridSelector: boolean;
  siteId?: number;
  itemPathPrefix: BlockItemPathPrefix;
}

export const MediaBlockItem = <T extends MediaBlock['items'][0]>({
  index,
  onReorder,
  onRemove,
  onAdd,
  displayGridSelector,
  siteId,
  itemPathPrefix,
}: MediaBlockItemProps<T>) => {
  const { t: tComp } = useTranslation('components');
  const eventManager = useEditorEventManager();
  const { setMediaId, setEditorOpen, editorOpen } = useMediaEditorContext();

  const {
    field: { value },
  } = useController<BlockSpecificPage<MediaBlock>, typeof itemPathPrefix>({
    name: itemPathPrefix,
  });
  const item: T = value as T;
  const isM24Media = isM24MediaModel(item);
  const [m24Item, setM24Item] = useState<M24MediaModel | undefined>(isM24Media ? item : undefined);

  useEffect(() => {
    if (isM24Media) {
      if (siteId && !editorOpen && item.id) {
        const ctx = Api.getM24MediaResource(siteId, item.id);
        ctx
          .fetchDirect(null)
          .then((e) => e && setM24Item(e))
          .finally(ctx.abort);
      }
    } else setM24Item(undefined);
  }, [item, siteId, editorOpen, isM24Media]);

  const { field: sizeField } = useController<BlockSpecificPage<MediaBlock>, `${typeof itemPathPrefix}.local.size`>({
    name: `${itemPathPrefix}.local.size`,
  });

  const handleEditMedia = () => {
    if (m24Item?.id) {
      setMediaId(m24Item.id);
      setEditorOpen(true);
    }
  };

  const gridSizeSelector = (
    <FormControl fullWidth>
      <RadioGroup {...sizeField} value={sizeField.value || 'default'} onClick={(e) => e.stopPropagation()} row>
        {(['default', 'medium'] as const).map((sizeOption) => (
          <FormControlLabel
            key={sizeOption}
            control={<Radio size='small' />}
            label={tComp(`MediaBlock.SizeLabel.${sizeOption}`)}
            value={sizeOption}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  return (
    <DraggableCollapseContainer<T>
      useEventHandler={useEditorEventHandler}
      useEventManager={eventManager}
      unmountOnExit
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      color={isDiMuMediaModel(item) ? 'grey' : 'white'}
      type={Draggable.MEDIA_BLOCK_ITEM}
      value={item}
      secondaryAction={
        <MediaBlockActions onDelete={() => onRemove?.(index)} onDuplicate={() => onAdd?.(deepCopy(item), index + 1)} />
      }
      headerContent={
        // eslint-disable-next-line react/no-unstable-nested-components
        (open) => (
          <MediaBlockHeader item={m24Item || item} open={open}>
            <Collapse in={displayGridSelector && !open}>{gridSizeSelector}</Collapse>
          </MediaBlockHeader>
        )
      }>
      <MediaBlockItemContent pathPrefix={itemPathPrefix} item={m24Item || item} editMedia={handleEditMedia}>
        <Container column fullWidth top left>
          <SetAsMainImageCheckbox image={m24Item} />
        </Container>
      </MediaBlockItemContent>
    </DraggableCollapseContainer>
  );
};

export default MediaBlockItem;
