import React, { FC } from 'react';
import { MaterialSymbol, MaterialSymbolProps } from './MaterialSymbol';

export const UserSelectableIcons = [
  'info',
  'eye',
  'date',
  'time',
  'download',
  'ticket',
  'person',
  'place',
  'email',
  'phone',
  'alert',
] as const;

export type UserSelectableIconName = (typeof UserSelectableIcons)[number];

// user selectable icons are tied to legacy, so we must translate to material symbols to display in the admin client
const iconMap: Record<UserSelectableIconName, string> = {
  info: 'info',
  eye: 'visibility',
  date: 'event',
  time: 'schedule',
  download: 'download',
  ticket: 'confirmation_number',
  person: 'person',
  place: 'near_me',
  email: 'email',
  phone: 'phone',
  alert: 'warning',
};

export const UserSelectableIcon: FC<{ name: UserSelectableIconName } & MaterialSymbolProps> = ({ name, ...rest }) => {
  return <MaterialSymbol name={iconMap[name]} {...rest} />;
};
