import React, { useEffect, useState } from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { DMAutoBlockModel } from '../../../../declarations/models/blocks/DMBlock';
import { Api } from '../../../../services/Api';
import { DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import DMBlockPreview from './DMBlockPreview';

export const DMBlockAutoPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const dmBlock = block as DMAutoBlockModel;

  const [dmItems, setDmItems] = useState<Array<DMMediaModel>>([]);

  let dmLink = false;

  if (dmBlock?.dmLink) {
    dmLink = true;
  }

  useEffect(() => {
    const ctx = Api.getDMSearchResult({
      start: 0,
      rows: dmBlock.maxItems || 12,
      query: dmBlock.query || undefined,
      subjects: dmBlock.subjects || undefined,
      owners: dmBlock.useOwnerCodes && dmBlock.useOwnerCodes ? dmBlock.ownerCodes || 'NONE' : undefined,
      licenses: dmBlock.licenseTypes?.length ? dmBlock.licenseTypes : undefined,
      types: dmBlock.objectTypes?.length ? dmBlock.objectTypes : undefined,
      order_by: dmBlock.sortBy || undefined,
      order: dmBlock.order || undefined,
      has_pictures: dmBlock.hasPictures ? true : undefined,
    });

    ctx
      .fetchDirect(null)
      .then((res) => !!res?.items && setDmItems(res.items))
      .finally(ctx.abort);
  }, [
    dmBlock.query,
    dmBlock.licenseTypes,
    dmBlock.maxItems,
    dmBlock.objectTypes,
    dmBlock.order,
    dmBlock.ownerCodes,
    dmBlock.sortBy,
    dmBlock.subjects,
    dmBlock.useOwnerCodes,
    dmBlock.hasPictures,
  ]);

  return (
    <DMBlockPreview
      dmBlock={dmBlock}
      dmItemsFull={dmItems}
      paginateButton={(dmBlock?.paginateMaxItems || 0) > (dmBlock?.maxItems || 0)}
      dmLink={dmLink}
    />
  );
};

export default DMBlockAutoPreview;
