/**
 * Return a a string value for a potentially localized string field.
 *
 * @param currentLang - Language code for the selected language
 * @param str - A string or an object with localized strings
 */
export function resolveLocalizedString(currentLang: string, str?: string | { [key: string]: string }): string {
  if (!str) return '';
  if (typeof str === 'string') return str;
  const valArr = Object.values(str);
  if (!valArr.length) return '';
  if (str[currentLang]) return str[currentLang];
  if (str.en) return str.en;
  if (str.no) return str.no;
  if (str.sv) return str.sv;
  return valArr.find((v) => !!v) || '';
}
