import React, { FC } from 'react';
import { TinyEditor } from '@/editor/PageEditor/EditorBlock/TextBlock/TinyEditor';

export interface WysiwygEditorProps {
  value: string;
  onChange: (value: string) => void;
  blockPath?: string;
  toolbar?: string;
}

export const WysiwygEditor: FC<
  WysiwygEditorProps & {
    type: 'tinymce';
  }
> = ({ type, ...rest }) => {
  if (type === 'tinymce') {
    return <TinyEditor {...rest} />;
  }

  console.warn('Unknown WysiwygEditor type', type);
  return null;
};
