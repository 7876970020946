import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useStore } from '@/components/store/Store';
import HeaderTitle from '../../editor/PageEditor/EditorBlock/MediaBlock/MediaBlockHeader/HeaderTitle';
import Container from '../Container';
import { Page } from '../../declarations/models/Page';
import { LinkExternal, M24MediaLinkItem } from '../../declarations/models/LinkExternal';
import { isM24MediaModel } from '../../utils/typeChecks';
import { ChildrenProp } from '../../declarations/ChildrenProp';

export interface LinkListItemHeaderProps<T> extends ChildrenProp {
  item: T;
  icon: ReactNode;
  subTitle?: ReactNode;
}

export const LinkListItemHeader: FC<LinkListItemHeaderProps<LinkExternal | M24MediaLinkItem | Page>> = ({
  children,
  item,
  icon,
  subTitle,
}) => {
  const {
    state: { selectedSite },
  } = useStore();
  let anchor = '';
  if ('anchor' in item) {
    anchor = `#${item?.anchor}`;
  }
  if (item.local && 'anchor' in item.local) {
    anchor = `#${item?.local.anchor}`;
  }

  // hack: when picking a page from a different site, the site name is called 'site_name'.
  //  after storing the page, site name is called 'site'...
  const siteName =
    'site_id' in item && selectedSite?.id === item?.site_id
      ? undefined
      : (item as Page)?.site || (item as Page)?.site_name;

  return (
    <Container left fullWidth px={1} gap={0}>
      <Container>{icon}</Container>
      <Container left fullWidth wrap>
        <HeaderTitle title={item?.title || (isM24MediaModel(item) ? item?.filename : '')} />

        {siteName && (
          <Typography
            sx={{
              display: 'inline-block',
              ml: 2,
            }}>
            ({siteName})
          </Typography>
        )}

        {subTitle}

        {item && 'local' in item && item.local && 'title' in item.local && item?.local?.title && (
          <Typography
            sx={{
              display: 'inline-block',
              fontStyle: 'italic',
              ml: 2,
              '&:before,&:after': {
                content: '" – "',
              },
            }}>
            {item.local.title}
          </Typography>
        )}
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default LinkListItemHeader;
