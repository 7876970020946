import React, { FC } from 'react';
import { Button, ButtonGroup, Chip, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseModel from '@/declarations/models/BaseModel';
import { formatAPITimestamp } from '@/utils/dates';
import Container from '@/components/Container';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export interface SimpleEditorHeaderProps {
  model?: Pick<BaseModel, 'created_at' | 'updated_at'>;
  title?: string;
  onClose: () => void;
  onSave: () => Promise<void>;
  isDirty?: boolean;
}

/**
 * SimpleEditorHeader, used with editors in modals
 * @param model
 * @param title
 * @param onClose
 * @param onSave
 * @param isDirty
 * @constructor
 */
export const SimpleEditorHeader: FC<SimpleEditorHeaderProps> = ({ model, title, onClose, onSave, isDirty }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const confirm = useConfirmDialog();

  const handleSaveAndExit = () => onSave().then(onClose);

  const handleExit = async () => {
    if (!isDirty || (await confirm(tCommon('exitUnsavedWarning')))) {
      onClose();
    }
  };

  return (
    <Container left fullWidth>
      <Button onClick={handleExit} startIcon={<MaterialSymbol name='cancel' />} sx={{ minWidth: 'fit-content' }}>
        {isDirty ? tCommon('cancel') : tCommon('exit')}
      </Button>
      <Divider orientation='vertical' variant='middle' flexItem sx={{ marginLeft: -1 }} />
      <Typography variant='h5' noWrap>
        {title}
      </Typography>
      <Container sx={{ minWidth: 'fit-content', marginLeft: 'auto' }}>
        {(model?.updated_at || model?.created_at) && (
          <Chip
            color='secondary'
            variant='outlined'
            label={tComponents('EditorHeader.LastUpdatedAt', {
              at: formatAPITimestamp(model?.updated_at || model?.created_at),
            })}
          />
        )}
        <ButtonGroup variant='contained' color='success' sx={{ minWidth: 'fit-content' }}>
          <Button disabled={!isDirty} onClick={onSave}>
            {tCommon('save')}
          </Button>
          <Button disabled={!isDirty} onClick={handleSaveAndExit} sx={{ width: 'fit-content' }}>
            {tCommon('saveAndExit')}
          </Button>
        </ButtonGroup>
      </Container>
    </Container>
  );
};

export default SimpleEditorHeader;
