import React, { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '@mui/material';
import SearchSelector from './SearchSelector';

interface SelectableLanguage {
  lng: string;
  label: string;
}

// These labels should not be translated.
const selectableLanguages: Array<SelectableLanguage> = [
  { lng: 'en', label: 'English' },
  { lng: 'no', label: 'Norsk' },
];

export const AppLanguageSelector: FC<PropsWithChildren<Pick<ButtonProps, 'color' | 'variant' | 'sx'>>> = (
  buttonProps,
) => {
  const { t: tARIA, i18n } = useTranslation('aria');

  const [selectedLanguage, setSelectedLanguage] = useState<SelectableLanguage | null>(
    selectableLanguages.find((l) => l.lng === i18n.language) || null,
  );

  const handleLanguageChange = async (language: SelectableLanguage | null) => {
    setSelectedLanguage(language);
    await i18n.changeLanguage(language?.lng);
  };

  return (
    <SearchSelector
      id='language-selector'
      label={tARIA('components.AppLanguageSelector.toggleLanguageSwitcher')}
      value={selectedLanguage}
      options={selectableLanguages}
      onChange={handleLanguageChange}
      getOptionLabel={(lng) => lng?.label || ''}
      getSelectedOptionLabel={(lng) => lng?.lng?.toUpperCase() || ''}
      getOptionKey={(lng) => lng?.lng || ''}
      required
      {...buttonProps}
    />
  );
};

export default AppLanguageSelector;
