import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { useCurrentBlock } from '../../../CurrentBlockProvider';
import { BannerBlock, BannerTitleTransformation } from '../../../../../declarations/models/blocks/BannerBlock';
import { BlockSpecificPage } from '../../../../lib/declarations/BlockSpecificPage';
import ActionMenuButton from '../../../../../components/ActionMenuButton';

const TRANSFORMATION_OPTIONS: Array<BannerTitleTransformation> = Object.values(BannerTitleTransformation);

export interface BannerBlockTitleTransformationSelectorProps {
  width?: string;
}

export const BannerBlockTitleTransformationSelector: FC<BannerBlockTitleTransformationSelectorProps> = ({ width }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.titleTransform`>({
    name: `${blockPath}.titleTransform`,
  });

  useEffect(() => {
    if (!value || !TRANSFORMATION_OPTIONS.includes(value)) {
      onChange(BannerTitleTransformation.NORMAL);
    }
  }, [onChange, value]);

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.BannerTitle.BannerBlockTitleTransformationSelector.ActionMenuLabel')}
      tooltip={tComponents('BannerBlock.BannerTitle.BannerBlockTitleTransformationSelector.tooltip')}
      tooltipPlacement='top'
      text={value === BannerTitleTransformation.UPPERCASE ? 'AB' : 'Ab'}
      width={width}>
      {TRANSFORMATION_OPTIONS.map((transformationOption) => (
        <MenuItem
          key={transformationOption}
          onClick={() => onChange(transformationOption)}
          selected={value === transformationOption}
          aria-label={tComponents(
            `BannerBlock.BannerTitle.BannerBlockTitleTransformationSelector.${transformationOption}`,
          )}>
          <ListItemText>
            {tComponents(`BannerBlock.BannerTitle.BannerBlockTitleTransformationSelector.${transformationOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default BannerBlockTitleTransformationSelector;
