import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup, Button, Typography } from '@mui/material';
import RadioInput from '@/components/forms/RadioInput';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PageListManualBlockItem from '@/editor/PageEditor/EditorBlock/PageListBlocks/PageListManualBlockItem';
import { noop } from '@/utils/functions';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import M24PageFinder from '../../../../components/Finder/M24PageFinder/M24PageFinder';
import { Page } from '../../../../declarations/models/Page';
import Styles from '../../../../assets/js/Styles';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { PageListAutoBlock as PageListAutoBlockModel } from '../../../../declarations/models/blocks/PageListBlocks';
import PageAutoListingSelects from './PageAutoListingSelects';
import PageListCategorySettings from './PageListCategorySettings';
import PageListPageTreeSettings from './PageListPageTreeSettings';
import PageListDisplayOptions from './PageListDisplayOptions';
import SiteSharingBlockSettings from '../SiteSharingBlockSettings';

import CheckboxInput from '../../../../components/forms/CheckboxInput';

export const PageListAutoBlock: BlockComponent = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');

  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);
  const [linkPageFinderOpen, setLinkPageFinderOpen] = useState<boolean>(false);
  const params = useParams<{ modelId?: string }>();

  const {
    field: { value: searchPathPageId, onChange: onChangeSearchPath },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.searchPathPageId`>({
    name: `${blockPath}.searchPathPageId`,
    defaultValue: params.modelId ? parseInt(params.modelId, 10) : undefined,
  });

  const {
    field: { value: searchLevel, onChange: onChangeSearchLevel },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.searchLevel`>({
    name: `${blockPath}.searchLevel`,
    defaultValue: 'children',
  });

  const {
    field: { value: displayType, onChange: onChangeDisplayType },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.displayType`>({
    name: `${blockPath}.displayType`,
  });

  const {
    field: { value: siteIds, onChange: setSiteIds },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.siteIds`>({
    name: `${blockPath}.siteIds`,
  });

  const {
    field: { value: showAllLinkPage, onChange: onChangeShowAllLinkPage },
  } = useController<BlockSpecificPage<PageListAutoBlockModel>, `${BlockPath}.showAllLinkPage`>({
    name: `${blockPath}.showAllLinkPage`,
  });

  return (
    <Container fullWidth column gap={2} left>
      <M24PageFinder
        hideSiteSelector
        open={pageFinderOpen}
        onClose={() => setPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          if (pages.length > 0) {
            onChangeSearchPath(pages[0].source.id);
          }
        }}
      />

      <M24PageFinder
        hideSiteSelector
        open={linkPageFinderOpen}
        onClose={() => setLinkPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          if (pages.length > 0) {
            onChangeShowAllLinkPage(pages[0].source);
          }
        }}
      />

      <Container>
        <RadioGroup value={displayType} onChange={(_, v) => onChangeDisplayType(v)} row>
          <FormControlLabel control={<Radio value='pageTree' />} label={t('PageListAutoBlock.PageTreeButtonLabel')} />
          <FormControlLabel
            control={<Radio value='categories' />}
            label={t('PageListAutoBlock.SelectCategoryButtonLabel')}
          />
        </RadioGroup>
      </Container>
      <Container
        column
        fullWidth
        sx={{
          backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
          borderRadius: Styles.Dimensions.NESTED_SECTION_BORDER_RADIUS,
        }}
        p={2}
        gap={2}>
        <Container fullWidth left column gap={2}>
          {displayType === 'pageTree' ? (
            <PageListPageTreeSettings
              searchPathPageId={searchPathPageId}
              setSearchPathPageId={onChangeSearchPath}
              searchLevel={searchLevel}
              setSearchLevel={onChangeSearchLevel}
              openPageFinder={() => setPageFinderOpen(true)}
            />
          ) : (
            <>
              <PageListCategorySettings blockPath={blockPath} />
              <SiteSharingBlockSettings siteIds={siteIds} setSiteIds={setSiteIds} />
            </>
          )}
          <PageAutoListingSelects blockPath={blockPath} />
          <PageListDisplayOptions blockPath={blockPath} />
        </Container>
      </Container>

      <Container
        column
        fullWidth
        sx={{
          backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
          borderRadius: Styles.Dimensions.NESTED_SECTION_BORDER_RADIUS,
        }}
        p={2}
        gap={2}>
        <Container column left fullWidth>
          <Typography variant='subtitle2'>{t('EventsBlock.ShowAllLinkPageTitle')}</Typography>
          {showAllLinkPage && (
            <PageListManualBlockItem
              index={0}
              onReorder={noop}
              onDelete={() => onChangeShowAllLinkPage(null)}
              overrideItemPath={`${blockPath}.showAllLinkPage`}
              color='primary'
              hideHighlightCheckbox
              hideSkinSelector
              disableReordering
            />
          )}
          <Button onClick={() => setLinkPageFinderOpen(true)} variant='contained' color='secondary'>
            {t('EventsBlock.ShowAllLinkPageButtonLabel')}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default PageListAutoBlock;
