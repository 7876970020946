import React, { FC } from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContentView } from '../ContentViewContext';

export interface PageTreeCheckboxProps {
  pageId?: number;
}

export const PageTreeCheckbox: FC<PageTreeCheckboxProps> = ({ pageId = 0 }) => {
  const { t } = useTranslation('components');
  const { selectionState } = useContentView();
  const { isSelected, isPartiallySelected, hasChildren, select, deselect } = selectionState;

  const selected = isSelected(pageId);
  const partiallySelected = isPartiallySelected(pageId);
  const pageHasChildren = hasChildren(pageId);

  const getTooltip = () => {
    if (partiallySelected) {
      if (selected) {
        return t('PageTreeCheckbox.Tooltips.SelectedAndPartiallySelectedChildren');
      }
      return t('PageTreeCheckbox.Tooltips.NotSelectedAndPartiallySelectedChildren');
    }
    if (pageHasChildren) {
      return t('PageTreeCheckbox.Tooltips.HasChildren');
    }
    return '';
  };

  const label = getTooltip();

  return (
    <Tooltip title={label} placement='right' enterDelay={500} enterNextDelay={300} arrow>
      <Checkbox
        color={!selected && partiallySelected ? 'primary' : 'secondary'}
        checked={selected}
        indeterminate={partiallySelected}
        aria-label={label}
        onClick={(e) => {
          // Stop event to prevent navigation when selecting an item
          e.stopPropagation();
          if (!selected || partiallySelected) {
            select(pageId, e.shiftKey);
          } else {
            deselect(pageId, e.shiftKey);
          }
        }}
      />
    </Tooltip>
  );
};

export default PageTreeCheckbox;
