import Container from '@/components/Container';
import { Link, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import React, { useEffect, useState } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { DescriptionList } from '@/views/DashboardView/widgets/DescriptionList';
import { useStore } from '@/components/store/Store';
import { SharingContentApprovalStatus, SiteSharingModel } from '@/declarations/models/SiteSharingModel';
import { Api } from '@/services/Api';
import { useTranslation } from 'react-i18next';

function isApproved(site: { approval_status?: SharingContentApprovalStatus }) {
  return site.approval_status === SharingContentApprovalStatus.APPROVED;
}
function isPending(site: { approval_status?: SharingContentApprovalStatus }) {
  return site.approval_status === SharingContentApprovalStatus.PENDING;
}

export const PermissionsWidget = () => {
  const { t: tComponents } = useTranslation('components');
  const {
    state: { selectedSite },
  } = useStore();
  const [sharingModel, setSharingModel] = useState<SiteSharingModel>();
  useEffect(() => {
    if (selectedSite) {
      Api.getSiteSharing(selectedSite.id!).fetchDirect(undefined).then(setSharingModel);
    }
  }, [selectedSite]);

  const accessToOtherSites = sharingModel?.granted?.filter(isApproved)?.length ?? 0;
  const sharedWithOtherSites = sharingModel?.given?.filter(isApproved)?.length ?? 0;

  const newRequests = sharingModel?.given?.filter(isPending)?.length ?? 0;

  const dl: [string, number][] = [
    [tComponents('DashboardView.SharingWidget.AccessGranted'), accessToOtherSites],
    [tComponents('DashboardView.SharingWidget.AccessGiven'), sharedWithOtherSites],
  ];
  return (
    <Container
      fullWidth
      fullHeight
      column
      left
      top
      p={2}
      sx={{
        border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Container spaceBetween fullWidth>
        <Typography fontSize='1.25rem' fontWeight={500}>
          {tComponents('DashboardView.SharingWidget.Title')}
        </Typography>
        <MaterialSymbol name='folder_supervised' sx={{ fontSize: '2rem' }} />
      </Container>
      <Container
        fullWidth
        column
        left
        sx={{
          mt: 2,
        }}>
        <DescriptionList items={dl} />
      </Container>
      <Container
        fullWidth
        p={2}
        mt={2}
        sx={{
          backgroundColor: Styles.Colors.LIGHT_GREY,
          fontStyle: 'italic',
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }}>
        {newRequests > 0 ? (
          <Link href='/settings/sharing'>
            <Typography>
              {tComponents('DashboardView.SharingWidget.NewRequests')} ({newRequests})
            </Typography>
          </Link>
        ) : (
          <Typography>{tComponents('DashboardView.SharingWidget.NoNewRequests')}</Typography>
        )}
      </Container>
    </Container>
  );
};
