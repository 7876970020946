import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import { styled, SxProps, Theme, useTheme } from '@mui/material';
import customIcons, { type CustomIconName } from '@/assets/images/svg/icons';
import { mergeSx } from '@/utils/mergeSx';

export interface CustomIconProps extends HTMLAttributes<HTMLSpanElement> {
  name: CustomIconName;
  color?: string;
  sx?: SxProps<Theme>;
}
const StyledSpan = styled('span')({});

const defaultSx: SxProps<Theme> = {
  width: '24px',
  height: '24px',
  padding: '2px',
};

export const CustomIcon = forwardRef<HTMLSpanElement, CustomIconProps>(({ name, color, ...rest }, ref) => {
  const theme = useTheme();
  const paletteColor = color ? theme.palette[color as keyof typeof theme.palette] : undefined;
  const style: CSSProperties = {
    color: paletteColor && typeof paletteColor === 'object' && 'main' in paletteColor ? paletteColor.main : color,
    ...rest.style,
  };
  const mergedSx = mergeSx(defaultSx, rest.sx || {});
  if (name in customIcons) {
    const Icon = customIcons[name];
    return (
      <StyledSpan {...rest} sx={mergedSx} style={style} ref={ref} aria-hidden data-testid={`CustomIcon-${name}`}>
        <Icon width='100%' height='100%' stroke='none' />
      </StyledSpan>
    );
  }
  console.warn(`CustomIcon: icon ${name} not found`);
  return null;
});

CustomIcon.displayName = 'CustomIcon';

export default CustomIcon;
