/* eslint-disable max-classes-per-file */
import { SectionType } from '../../../declarations/models/SectionType';
import { BlockType } from '../../../declarations/models/BlockType';
import { BlockComponent, BlockPreviewComponent, SettingsComponent } from '../declarations/EditorComponentTypes';
import { EditorContext } from '../declarations/EditorContext';
import { Section } from '../../../declarations/models/Section';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';
import { SectionAction } from '../declarations/SectionAction';
import { BlockAction } from '../declarations/BlockAction';
import { SectionModelFactory } from '../factories/SectionModelFactory';
import { BlockModelFactory } from '../factories/BlockModelFactory';
import { EditorDataSet } from '../declarations/EditorDataSet';

function warn(message: string) {
  // eslint-disable-next-line no-console
  console.warn(`[EditorRegistry][Builder] ${message}`);
}

// noinspection TypeScriptFieldCanBeMadeReadonly
/**
 * The EditorConfiguration is responsible for constructing and keeping track of the Editor's STATIC configuration (Never changes).
 * Any configuration/rules that is dependent on the CURRENT VALUE of the Model, should be added to the EditorService.
 *
 * Construct a new registry for the editor using the Builder:
 *
 * <pre>
 *   const registry = new EditorConfiguration.Builder()
 *                      // ...Configure registry here
 *                      .build();
 * </pre>
 */
export class EditorConfiguration {
  private static readonly ALL_BLOCKS = Object.values(BlockType);

  private static readonly ALL_SECTIONS = Object.values(SectionType);

  /**
   * Use this to construct the registry.
   * TODO: Configure available save-types
   */
  public static Builder = class EditorConfigurationBuilder {
    private readonly registry: EditorConfiguration;

    public constructor(context: EditorContext = EditorContext.PAGE) {
      this.registry = new EditorConfiguration(context);
    }

    /**
     * Registers the components used to render the specified BlockType
     * @param blockType
     * @param blockComponent
     * @param previewComponent
     * @param hideCommonBlockContent
     */
    public withBlock(
      blockType: BlockType,
      blockComponent: BlockComponent | null,
      previewComponent: BlockPreviewComponent | null,
      hideCommonBlockContent?: boolean,
    ): EditorConfigurationBuilder {
      const blockComponentAlreadySpecified = this.registry.blockComponentMap.has(blockType);
      const previewComponentAlreadySpecified = this.registry.previewComponentMap.has(blockType);

      if (blockComponentAlreadySpecified) {
        warn(`Overriding BlockComponent for BlockType '${blockType}'`);
      }
      if (previewComponentAlreadySpecified) {
        warn(`Overriding BlockPreviewComponent for BlockType '${blockType}'`);
      }

      if (blockComponent) {
        this.registry.blockComponentMap.set(blockType, blockComponent);
      } else if (blockComponentAlreadySpecified) {
        this.registry.blockComponentMap.delete(blockType);
      }

      if (previewComponent) {
        this.registry.previewComponentMap.set(blockType, previewComponent);
      } else if (previewComponentAlreadySpecified) {
        this.registry.previewComponentMap.delete(blockType);
      }

      if (hideCommonBlockContent) {
        this.registry.hideCommonBlockContent.set(blockType, hideCommonBlockContent);
      }

      return this;
    }

    /**
     * Specify what blocks should be "hidden" in the given section.
     * This function primarily exists to "hide" less relevant block-options when adding new blocks.
     * This may be changed or used for other features as well in the future.
     * @param sectionType
     * @param blockTypes
     */
    public withHiddenBlocksInSection(
      sectionType: SectionType,
      ...blockTypes: Array<BlockType>
    ): EditorConfigurationBuilder {
      if (!this.registry.hiddenBlocksMap.has(sectionType)) {
        this.registry.hiddenBlocksMap.set(sectionType, new Set<BlockType>(blockTypes));
      } else {
        blockTypes.forEach((blockType) => this.registry.hiddenBlocksMap.get(sectionType)!.add(blockType));
      }
      return this;
    }

    /**
     * Mark the blocks as "hidden" in all available sections
     * @param blockTypes
     */
    public withHiddenBlocksInAllSections(...blockTypes: Array<BlockType>): EditorConfigurationBuilder {
      EditorConfiguration.ALL_SECTIONS.forEach((sectionType) => {
        this.withHiddenBlocksInSection(sectionType, ...blockTypes);
      });
      return this;
    }

    /**
     * Register the components to use for the blocks that has no implementation provided.
     * @param blockPlaceholder
     * @param previewPlaceholder
     */
    public withPlaceholders(
      blockPlaceholder: BlockComponent | null,
      previewPlaceholder: BlockPreviewComponent | null,
    ): EditorConfigurationBuilder {
      this.registry.defaultBlockComponent = blockPlaceholder;
      this.registry.defaultPreviewComponent = previewPlaceholder;
      return this;
    }

    /**
     * Define what dataSets should be prefetched before rendering the form
     * @param dataToPrefetch
     */
    public withPrefetchedData(...dataToPrefetch: Array<EditorDataSet>): EditorConfigurationBuilder {
      this.registry.dataSets = new Set<EditorDataSet>(dataToPrefetch);
      return this;
    }

    /**
     * Register a settings-component for one or more sectionTypes
     * @param settingsComponent
     * @param sectionTypes
     */
    public withSettingForSections(
      settingsComponent: SettingsComponent,
      ...sectionTypes: Array<SectionType>
    ): EditorConfigurationBuilder {
      sectionTypes.forEach((sectionType) => {
        if (!this.registry.sectionSettingsMap.has(sectionType)) {
          this.registry.sectionSettingsMap.set(sectionType, [settingsComponent]);
        } else {
          this.registry.sectionSettingsMap.get(sectionType)!.push(settingsComponent);
        }
      });
      return this;
    }

    /**
     * Register a settings-component for one or more blockTypes
     * @param settingsComponent
     * @param blockTypes
     */
    public withSettingForBlocks(
      settingsComponent: SettingsComponent,
      ...blockTypes: Array<BlockType>
    ): EditorConfigurationBuilder {
      blockTypes.forEach((blockType) => {
        if (!this.registry.blockSettingsMap.has(blockType)) {
          this.registry.blockSettingsMap.set(blockType, [settingsComponent]);
        } else {
          this.registry.blockSettingsMap.get(blockType)!.push(settingsComponent);
        }
      });
      return this;
    }

    /**
     * Register a setting that should be available for all sections.
     * @param settingsComponent
     */
    public withSettingForAllSections(settingsComponent: SettingsComponent): EditorConfigurationBuilder {
      this.withSettingForSections(settingsComponent, ...EditorConfiguration.ALL_SECTIONS);
      return this;
    }

    /**
     * Register a setting that should be available for all blocks.
     * @param settingsComponent
     */
    public withSettingForAllBlocks(settingsComponent: SettingsComponent): EditorConfigurationBuilder {
      this.withSettingForBlocks(settingsComponent, ...EditorConfiguration.ALL_BLOCKS);
      return this;
    }

    /**
     * Set what sections should be available.
     * @param availableSections
     */
    public withAvailableSections(...availableSections: ReadonlyArray<SectionType>): EditorConfigurationBuilder {
      if (!availableSections?.length) {
        warn('No sections will be available');
      }
      this.registry.sections.clear();
      availableSections.forEach((sectionType) => this.registry.sections.add(sectionType));
      return this;
    }

    /**
     * Set what blocks should be available.
     * @param availableBlocks
     */
    public withAvailableBlocks(...availableBlocks: ReadonlyArray<BlockType>): EditorConfigurationBuilder {
      if (!availableBlocks?.length) {
        warn('No blocks will be available');
      }
      this.registry.blocks.clear();
      availableBlocks.forEach((blockType) => this.registry.blocks.add(blockType));
      return this;
    }

    /**
     * Make all sections available
     */
    public withAllSectionsAvailable(): EditorConfigurationBuilder {
      return this.withAvailableSections(...EditorConfiguration.ALL_SECTIONS);
    }

    /**
     * Specify one or more sections to be available
     * @param sectionTypes
     */
    public withSectionsAvailable(...sectionTypes: ReadonlyArray<SectionType>): EditorConfigurationBuilder {
      return this.withAvailableSections(...sectionTypes);
    }

    /**
     * Make all blocks available
     */
    public withAllBlocksAvailable(): EditorConfigurationBuilder {
      return this.withAvailableBlocks(...EditorConfiguration.ALL_BLOCKS);
    }

    /**
     * Specify one or more sections a block should be available in.
     * @param blockType
     * @param sectionTypes
     */
    public withBlockAvailableInSections(
      blockType: BlockType,
      ...sectionTypes: ReadonlyArray<SectionType>
    ): EditorConfigurationBuilder {
      if (!sectionTypes.length) {
        throw new Error('No SectionTypes provided');
      }
      sectionTypes.forEach((sectionType) => {
        if (!this.registry.blocksInSectionsMap.has(sectionType)) {
          this.registry.blocksInSectionsMap.set(sectionType, new Set<BlockType>([blockType]));
        } else {
          this.registry.blocksInSectionsMap.get(sectionType)!.add(blockType);
        }
      });
      return this;
    }

    /**
     * Add all blocks to the selected section
     * @param sectionType
     */
    public withAllBlocksAvailableInSection(sectionType: SectionType): EditorConfigurationBuilder {
      return this.withBlocksInSection(sectionType, ...EditorConfiguration.ALL_BLOCKS);
    }

    /**
     * Makes every available block available in every available section
     */
    public withAllBlocksAvailableInAllSections(): EditorConfigurationBuilder {
      EditorConfiguration.ALL_SECTIONS.forEach((sectionType) => {
        this.withAllBlocksAvailableInSection(sectionType);
      });
      return this;
    }

    /**
     * Specify one or mode blocks that should be available in the section
     * @param sectionType
     * @param blockTypes
     */
    public withBlocksInSection(
      sectionType: SectionType,
      ...blockTypes: ReadonlyArray<BlockType>
    ): EditorConfigurationBuilder {
      if (!blockTypes.length) {
        throw new Error('No BlockTypes provided');
      }
      if (!this.registry.blocksInSectionsMap.has(sectionType)) {
        this.registry.blocksInSectionsMap.set(sectionType, new Set<BlockType>(blockTypes));
      } else {
        const blockTypesInSection = this.registry.blocksInSectionsMap.get(sectionType)!;
        blockTypes.forEach((blockType) => {
          blockTypesInSection.add(blockType);
        });
      }
      return this;
    }

    /**
     * Set this block to be available in all sections.
     * @param blockType
     */
    public withBlockInAllSections(blockType: BlockType): EditorConfigurationBuilder {
      this.withBlockAvailableInSections(blockType, ...EditorConfiguration.ALL_SECTIONS);
      return this;
    }

    /**
     * Set all blocks to be available in this section.
     * Calling this function will override any previous mappings
     * @param sectionType
     */
    public withSectionContainingAllBlocks(sectionType: SectionType): EditorConfigurationBuilder {
      this.withBlocksInSection(sectionType, ...EditorConfiguration.ALL_BLOCKS);
      return this;
    }

    /**
     * Set all blocks to be available in this section, except the ones specified.
     * Calling this function will override any previous mappings
     * @param sectionType
     * @param exceptBlockTypes
     */
    public withSectionContainingAllBlocksExcept(
      sectionType: SectionType,
      ...exceptBlockTypes: ReadonlyArray<BlockType>
    ): EditorConfigurationBuilder {
      if (this.registry.blocksInSectionsMap.has(sectionType)) {
        /* istanbul ignore next */
        warn(`Blocks for section '${sectionType}' already specified.`);
      }

      const blockTypesToAdd = EditorConfiguration.ALL_BLOCKS.filter((bt) => !exceptBlockTypes.includes(bt));
      this.registry.blocksInSectionsMap.set(sectionType, new Set<BlockType>(blockTypesToAdd));
      return this;
    }

    /**
     * Enable an action for the specified sections
     * @param action
     * @param sections
     */
    public withActionEnabledForSections(
      action: SectionAction,
      ...sections: Array<SectionType>
    ): EditorConfigurationBuilder {
      sections.forEach((section) => {
        if (!this.registry.sectionActionMap.has(section)) {
          this.registry.sectionActionMap.set(section, new Set<SectionAction>([action]));
        } else if (!this.registry.sectionActionMap.get(section)!.has(action)) {
          this.registry.sectionActionMap.get(section)!.add(action);
        }
      });
      return this;
    }

    /**
     * Enable an action for the specified blocks
     * @param action
     * @param blocks
     */
    public withActionEnabledForBlocks(action: BlockAction, ...blocks: Array<BlockType>): EditorConfigurationBuilder {
      blocks.forEach((block) => {
        if (!this.registry.blockActionMap.has(block)) {
          this.registry.blockActionMap.set(block, new Set<BlockAction>([action]));
        } else if (!this.registry.blockActionMap.get(block)!.has(action)) {
          this.registry.blockActionMap.get(block)!.add(action);
        }
      });
      return this;
    }

    /**
     * Enable an action for all sections
     * @param action
     */
    public withActionEnabledForAllSections(action: SectionAction): EditorConfigurationBuilder {
      return this.withActionEnabledForSections(action, ...EditorConfiguration.ALL_SECTIONS);
    }

    /**
     * Enable an action for all blocks
     * @param action
     */
    public withActionEnabledForAllBlocks(action: BlockAction): EditorConfigurationBuilder {
      return this.withActionEnabledForBlocks(action, ...EditorConfiguration.ALL_BLOCKS);
    }

    /**
     * Enable an action for all sections, except the sections provided
     * @param action
     * @param exceptSections
     */
    public withActionEnabledForAllSectionsExcept(
      action: SectionAction,
      ...exceptSections: Array<SectionType>
    ): EditorConfigurationBuilder {
      return this.withActionEnabledForSections(
        action,
        ...EditorConfiguration.ALL_SECTIONS.filter((s) => !exceptSections.includes(s)),
      );
    }

    /**
     * Enable an action for all blocks, except the blocks provided
     * @param action
     * @param exceptBlocks
     */
    public withActionEnabledForAllBlocksExcept(
      action: BlockAction,
      ...exceptBlocks: Array<BlockType>
    ): EditorConfigurationBuilder {
      return this.withActionEnabledForBlocks(
        action,
        ...EditorConfiguration.ALL_BLOCKS.filter((b) => !exceptBlocks.includes(b)),
      );
    }

    /**
     * Build/complete the Registry
     */
    public build(): EditorConfiguration {
      return this.registry;
    }
  };

  private sections: Set<SectionType>;

  private blocks: Set<BlockType>;

  private hiddenBlocksMap: Map<SectionType, Set<BlockType>>;

  private defaultBlockComponent: BlockComponent | null;

  private defaultPreviewComponent: BlockPreviewComponent | null;

  private dataSets: Set<EditorDataSet>;

  public readonly context: EditorContext;

  private readonly blockComponentMap: Map<BlockType, BlockComponent>;

  private readonly previewComponentMap: Map<BlockType, BlockPreviewComponent>;

  private readonly hideCommonBlockContent: Map<BlockType, boolean>;

  private readonly blocksInSectionsMap: Map<SectionType, Set<BlockType>>;

  private readonly sectionActionMap: Map<SectionType, Set<SectionAction>>;

  private readonly blockActionMap: Map<BlockType, Set<BlockAction>>;

  private readonly sectionSettingsMap: Map<SectionType, Array<SettingsComponent>>;

  private readonly blockSettingsMap: Map<BlockType, Array<SettingsComponent>>;

  /**
   * Initializes the EditorConfiguration with default values
   * @private
   */
  private constructor(context: EditorContext) {
    this.sections = new Set<SectionType>();
    this.blocks = new Set<BlockType>();
    this.hiddenBlocksMap = new Map<SectionType, Set<BlockType>>();
    this.defaultBlockComponent = null;
    this.defaultPreviewComponent = null;
    this.dataSets = new Set<EditorDataSet>();
    this.context = context;
    this.blockComponentMap = new Map<BlockType, BlockComponent>();
    this.previewComponentMap = new Map<BlockType, BlockPreviewComponent>();
    this.hideCommonBlockContent = new Map<BlockType, boolean>();
    this.blocksInSectionsMap = new Map<SectionType, Set<BlockType>>();
    this.sectionActionMap = new Map<SectionType, Set<SectionAction>>();
    this.blockActionMap = new Map<BlockType, Set<BlockAction>>();
    this.sectionSettingsMap = new Map<SectionType, Array<SettingsComponent>>();
    this.blockSettingsMap = new Map<BlockType, Array<SettingsComponent>>();
  }

  /**
   * Get the sections that are generally available
   */
  public getAvailableSections(): ReadonlyArray<SectionType> {
    return Array.from(this.sections);
  }

  /**
   * Get the blocks that are generally available
   */
  public getAvailableBlocks(): ReadonlyArray<BlockType> {
    return Array.from(this.blocks);
  }

  /**
   *
   */
  public getDataSetsToPrefetch(): ReadonlyArray<EditorDataSet> {
    return Array.from(this.dataSets);
  }

  /**
   * Get all available blocks for the given section, if it's available
   * @param sectionType
   */
  public getBlocksAvailableInSection(sectionType: SectionType): ReadonlyArray<BlockType> {
    if (this.isSectionAvailable(sectionType) && this.blocksInSectionsMap.has(sectionType)) {
      return Array.from(this.blocksInSectionsMap.get(sectionType)!).filter((blockType) =>
        this.isBlockAvailable(blockType),
      );
    }
    return [];
  }

  /**
   * Get the editor-component-constructor for the specified block
   * @param blockType
   */
  public getBlockComponent(blockType: BlockType): BlockComponent | null {
    if (!this.isBlockAvailable(blockType)) {
      return null;
    }
    if (this.blockComponentMap.has(blockType)) {
      return this.blockComponentMap.get(blockType)!;
    }
    return this.defaultBlockComponent;
  }

  /**
   * Get the preview-component-constructor for the specified block
   * @param blockType
   */
  public getBlockPreviewComponent(blockType: BlockType): BlockPreviewComponent | null {
    if (!this.isBlockAvailable(blockType)) {
      return null;
    }
    if (this.previewComponentMap.has(blockType)) {
      return this.previewComponentMap.get(blockType)!;
    }
    return this.defaultPreviewComponent;
  }

  public getHideCommonBlockContent(blockType: BlockType): boolean | null {
    if (!this.isBlockAvailable(blockType)) {
      return null;
    }
    if (this.hideCommonBlockContent.has(blockType)) {
      return this.hideCommonBlockContent.get(blockType)!;
    }

    return false;
  }

  /**
   * Generate a section with default values for the specified SectionType
   * @param sectionType
   */
  public getDefaultSectionModel(sectionType: SectionType): Section | null {
    if (this.isSectionAvailable(sectionType)) {
      return SectionModelFactory.makeSectionModel(sectionType);
    }
    return null;
  }

  /**
   * Generate a block with default values for the specified BlockType
   * @param blockType
   */
  public getDefaultBlockModel(blockType: BlockType): BaseBlock | null {
    if (this.isBlockAvailable(blockType)) {
      return BlockModelFactory.makeBlockModel(blockType);
    }
    return null;
  }

  /**
   * Get all actions that are available for the specified SectionType
   * @param sectionType
   */
  public getAvailableActionsForSection(sectionType: SectionType): Array<SectionAction> {
    if (!this.isSectionAvailable(sectionType) || !this.sectionActionMap.has(sectionType)) {
      return [];
    }
    return Array.from(this.sectionActionMap.get(sectionType)!);
  }

  /**
   * Get all actions that are available for the specified BlockType
   * @param blockType
   */
  public getAvailableActionsForBlock(blockType: BlockType): ReadonlyArray<BlockAction> {
    if (!this.isBlockAvailable(blockType) || !this.blockActionMap.has(blockType)) {
      return [];
    }
    return Array.from(this.blockActionMap.get(blockType)!);
  }

  /**
   * Get the available blocks that should be visible
   */
  public getVisibleBlocks(sectionType: SectionType): ReadonlyArray<BlockType> {
    return this.getBlocksAvailableInSection(sectionType).filter((block) => !this.isHiddenBlock(sectionType, block));
  }

  /**
   * Get the available blocks that should be hidden
   */
  public getHiddenBlocks(sectionType: SectionType): ReadonlyArray<BlockType> {
    return this.getBlocksAvailableInSection(sectionType).filter((block) => this.isHiddenBlock(sectionType, block));
  }

  /**
   * Get the settings-components to render for the given Section
   * @param sectionType
   */
  public getSectionSettings(sectionType: SectionType): ReadonlyArray<SettingsComponent> {
    if (!this.isSectionAvailable(sectionType) || !this.sectionSettingsMap.has(sectionType)) {
      return [];
    }
    return this.sectionSettingsMap.get(sectionType)!;
  }

  /**
   * Get the settings-components to render for the given Block
   * @param blockType
   */
  public getBlockSettings(blockType: BlockType): ReadonlyArray<SettingsComponent> {
    if (!this.isBlockAvailable(blockType) || !this.blockSettingsMap.has(blockType)) {
      return [];
    }
    return this.blockSettingsMap.get(blockType)!;
  }

  /**
   * Get whether a section is available
   * @param sectionType
   */
  public isSectionAvailable(sectionType: SectionType): boolean {
    return this.sections.has(sectionType);
  }

  /**
   * Get whether a block is available
   * @param blockType
   */
  public isBlockAvailable(blockType: BlockType): boolean {
    return this.blocks.has(blockType);
  }

  /**
   * Get whether a block is available in a section
   * @param sectionType
   * @param blockType
   */
  public isBlockAvailableInSection(sectionType: SectionType, blockType: BlockType): boolean {
    return (
      this.isSectionAvailable(sectionType) &&
      this.isBlockAvailable(blockType) &&
      !!this.blocksInSectionsMap.get(sectionType)?.has(blockType)
    );
  }

  /**
   * Get whether a block should be hidden in the given section
   * @param sectionType
   * @param blockType
   */
  public isHiddenBlock(sectionType: SectionType, blockType: BlockType): boolean {
    return this.hiddenBlocksMap.has(sectionType) && this.hiddenBlocksMap.get(sectionType)!.has(blockType);
  }

  /**
   * Check whether an action is available for the given section
   * @param sectionType
   * @param actionType
   */
  public isActionAvailableForSection(sectionType: SectionType, actionType: SectionAction): boolean {
    return this.getAvailableActionsForSection(sectionType).includes(actionType);
  }

  /**
   * Check whether an action is available for the given block
   * @param blockType
   * @param actionType
   */
  public isActionAvailableForBlock(blockType: BlockType, actionType: BlockAction): boolean {
    return this.getAvailableActionsForBlock(blockType).includes(actionType);
  }
}
