import React, { FC } from 'react';
import { Divider, List } from '@mui/material';
import { CommonMediaViewProps } from '../MediaViewList';
import { EditState } from '../MediaView';
import ListViewItem from './ListViewItem';

export const ListView: FC<CommonMediaViewProps> = ({ items, editState, getIsSelected, onItemClick, onDeleteItem }) => {
  return (
    <List sx={{ width: '100%', padding: '0 16px' }}>
      {items.map((item) => {
        const isSelected = editState !== EditState.SINGLE && !!getIsSelected?.(item);
        return (
          <div key={item.id}>
            <Divider />
            <ListViewItem
              item={item}
              onItemClick={() => onItemClick(item)}
              editState={editState}
              isSelected={isSelected}
              onDeleteItem={onDeleteItem}
            />
          </div>
        );
      })}
    </List>
  );
};

export default ListView;
