import React, { FC } from 'react';
import Container from '@/components/Container';
import { LinearProgress, Typography } from '@mui/material';

export const DescriptionList: FC<{ items?: [string, string | number][] }> = ({ items }) => {
  return (
    <Container column fullWidth gap={0.5}>
      {items ? (
        items.map(([k, v]) => (
          <Container key={k} fullWidth spaceBetween>
            <Typography>{k}:</Typography>
            {v}
          </Container>
        ))
      ) : (
        <LinearProgress variant='indeterminate' color='secondary' sx={{ width: '100%' }} />
      )}
    </Container>
  );
};
