import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { BannerBlock, BannerLayout } from '../../../declarations/models/blocks/BannerBlock';
import ActionMenu from '../../../components/ActionMenu';

const LAYOUT_OPTIONS: Array<BannerLayout> = Object.values(BannerLayout);

export const PageBlockBannerLayoutSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.layout`>({
    name: `${blockPath}.layout`,
  });

  const getIcon = (layout?: BannerLayout) => {
    switch (layout) {
      case BannerLayout.TEXT_ON_IMAGE:
        return <CustomIcon name='banner_text_on_image' />;
      case BannerLayout.IMAGE_TOP:
        return <CustomIcon name='banner_image_above' />;
      case BannerLayout.IMAGE_LEFT:
        return <CustomIcon name='banner_image_left' />;
      case BannerLayout.IMAGE_RIGHT:
        return <CustomIcon name='banner_image_left' sx={{ transform: 'scaleX(-1)' }} />;
      case BannerLayout.BOX:
        return <MaterialSymbol name='magnification_large' />;
      case BannerLayout.TEXTLINES:
        return <MaterialSymbol name='text_ad' />;
      case BannerLayout.VARIABLE:
        return <CustomIcon name='banner_variable_height' />;
      default:
        return <CustomIcon name='banner_text_on_image' />;
    }
  };
  return (
    <ActionMenu
      id='banner-id-selector'
      ariaLabel={tAria('components.PageBlockBannerLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockBannerLayoutSetting.Tooltip')}
      icon={getIcon(value)}>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockBannerLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{getIcon(layoutOption)}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockBannerLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};
