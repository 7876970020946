import React, { FC } from 'react';
import { Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../components/Container';
import { EmployeeLink } from '../../../declarations/models/Employee';
import { inputGroupStyling } from '../../MediaEditor/MediaEditorFields/EditMediaTextFields';
import { isValidUrl } from '../../../utils/url';

interface EditEmployeeLinksFieldProps {
  links?: Array<EmployeeLink>;
  onChangeLinks: (links: Array<EmployeeLink>) => void;
}

export const EditEmployeeLinksField: FC<EditEmployeeLinksFieldProps> = ({ links = [], onChangeLinks }) => {
  const { t } = useTranslation('components');

  const addNewLink = () => {
    onChangeLinks([...links, { link_name: '' } as EmployeeLink]);
  };

  const handleChangeExternalLink = (index: number, title: string, url?: string) => {
    onChangeLinks([...links.slice(0, index), { link_name: title, url_external: url }, ...links.slice(index + 1)]);
  };

  const handleRemoveLink = (index: number) => {
    onChangeLinks([...links.slice(0, index), ...links.slice(index + 1)]);
  };

  const getLinkInputs = (link: EmployeeLink, i: number) => {
    return (
      <Container fullWidth key={i} gap={2} top>
        <>
          <TextField
            size='small'
            label={t('EmployeeEditor.LinkName')}
            fullWidth
            value={link.link_name}
            onChange={(e) => handleChangeExternalLink(i, e.target.value, link.url_external)}
          />
          <TextField
            size='small'
            label={t('EmployeeEditor.UrlExternal')}
            fullWidth
            helperText={
              link.url_external && !isValidUrl(link.url_external) ? t('EmployeeEditor.InvalidUrl') : undefined
            }
            value={link.url_external ?? ''}
            onChange={(e) => handleChangeExternalLink(i, link.link_name, e.target.value)}
          />
        </>
        <Tooltip title={t('EmployeeEditor.RemoveLink')} arrow>
          <IconButton onClick={() => handleRemoveLink(i)} sx={{ marginLeft: -1 }}>
            <MaterialSymbol name='delete' fill />
          </IconButton>
        </Tooltip>
      </Container>
    );
  };

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{t('EmployeeEditor.LinksFieldTitle')}</Typography>
      <Container fullWidth column left gap={2}>
        {links.map(getLinkInputs)}
        <Container gap={2}>
          <Button sx={{ height: '40px' }} variant='contained' color='success' onClick={addNewLink}>
            {t('EmployeeEditor.AddExternal')}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default EditEmployeeLinksField;
