import React, { FC } from 'react';
import { Autocomplete, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { Tag } from '../../../declarations/models/Tag';
import { inputGroupStyling } from './EditMediaTextFields';

export interface EditMediaTagsFieldProps {
  tagsInput?: Array<string>;
  setTagsInput: (tags: Array<string>) => void;
  allTags: Array<Tag>;
}

export const EditMediaTagsField: FC<EditMediaTagsFieldProps> = ({ tagsInput = [], setTagsInput, allTags }) => {
  const { t: tComp } = useTranslation('components');

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{tComp('MediaView.Tags')}</Typography>
      <Typography variant='caption'>{tComp('MediaEditor.TagsInfo')}</Typography>
      <Autocomplete
        size='small'
        value={tagsInput}
        fullWidth
        multiple
        renderOption={(props, option) => (
          <MenuItem {...props} sx={{ padding: '2px 16px !important', fontSize: '13px' }}>
            {option}
          </MenuItem>
        )}
        freeSolo
        onChange={(_, v) => setTagsInput(v)}
        renderInput={(params) => <TextField {...params} label={tComp('MediaEditor.TagsLabel')} />}
        options={allTags.map((tag) => tag.tag)}
      />
    </Container>
  );
};

export default EditMediaTagsField;
