import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../assets/js/Styles';
import DragAndDropReorderingItemContainer, {
  DragAndDropReorderingItemContainerProps,
} from './DragAndDropReorderingItemContainer';
import Container from './Container';
import DragAndDropIcon from './DragAndDropIcon';
import { EditorEvent, EventManagerApi } from '../editor/lib/eventManager/EditorEventManager';
import { Draggable } from '../declarations/Draggable';
import ActionDropzone from './Section/ActionDropzone';
import DropIndicatorArrow from './DropIndicatorArrow';

export interface DraggableContainerProps<T>
  extends Pick<
    DragAndDropReorderingItemContainerProps<T>,
    'onReorder' | 'onRemove' | 'onAdd' | 'disabled' | 'index' | 'value' | 'type'
  > {
  color?: string;
  headerContent: ReactNode;
  secondaryAction?: ReactElement;
  type: Draggable;
  useEventHandler?: (handler: (event: EditorEvent) => Promise<void> | void) => void;
  useEventManager?: EventManagerApi;
  renderDropIndicator?: (visible: boolean) => ReactElement;
  renderPlaceholder?: () => ReactElement;
}

export const DraggableContainer = <T,>({
  color = 'white',
  headerContent,
  secondaryAction,
  type = Draggable.OTHER,
  useEventManager,
  renderPlaceholder,
  renderDropIndicator,
  ...dndProps
}: PropsWithChildren<DraggableContainerProps<T>>) => {
  const { t } = useTranslation('common');

  const renderDefaultPlaceholder = () => (
    <Container spaceBetween fullWidth>
      <ActionDropzone
        type={type}
        label={t('Draggable.DropHereToAbort')}
        sx={{
          backgroundColor: 'transparent',
          color: 'inherit',
        }}
        fullWidth
      />
    </Container>
  );

  const renderDefaultDropIndicator = (visible: boolean) => {
    return (
      <DropIndicatorArrow
        visible={visible}
        spacing={1}
        label={t('Draggable.MoveHere')}
        borderStyle='dashed'
        arrowStyle='dashed'
        color={Styles.Colors.DROP_INDICATOR_COLOR}
      />
    );
  };

  return (
    <DragAndDropReorderingItemContainer
      useEventManager={useEventManager}
      {...dndProps}
      type={type}
      fullWidth
      gap={0}
      renderPlaceholder={renderPlaceholder || renderDefaultPlaceholder}
      renderDropIndicator={renderDropIndicator || renderDefaultDropIndicator}>
      {(dragHandleRef, dragging) => (
        <Container
          sx={{
            backgroundColor: color,
            border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            position: 'relative',
            overflow: 'hidden',
            maxWidth: '100%',
            p: 0,
            m: 0,
          }}
          gap={0}
          spaceBetween
          fullWidth>
          <Container sx={{ maxWidth: 'calc(100% - 80px)', minHeight: '50px' }} left fullWidth>
            {headerContent}
          </Container>
          <Container gap={0} sx={{ alignSelf: 'stretch' }} right>
            {secondaryAction}
            <DragAndDropIcon ref={dragHandleRef} isDragging={dragging} px={1} fullHeight />
          </Container>
        </Container>
      )}
    </DragAndDropReorderingItemContainer>
  );
};

export default DraggableContainer;
