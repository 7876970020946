import React, { FC } from 'react';
import { Divider, List } from '@mui/material';
import { CommonEmployeesViewProps } from '../EmployeesViewList';
import EmployeesListViewItem from './EmployeesListViewItem';

export const EmployeesListView: FC<CommonEmployeesViewProps> = ({ items, onItemClick, onDeleteItem }) => {
  return (
    <List sx={{ width: '100%', padding: '0 16px' }}>
      {items.map((item) => {
        return (
          <div key={item.id}>
            <Divider />
            <EmployeesListViewItem item={item} onItemClick={onItemClick} onDeleteItem={onDeleteItem} />
          </div>
        );
      })}
    </List>
  );
};

export default EmployeesListView;
