import React, { FC, useMemo } from 'react';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import { useEditorConfiguration } from '../../lib/configuration/EditorConfigurationContext';
import Container from '../../../components/Container';
import CommonBlockContent from './CommonBlockContent';
import { useCurrentBlock } from '../CurrentBlockProvider';

/**
 * Responsible for rendering the correct block for the given BlockType
 * @constructor
 */
export const EditorBlock: FC = () => {
  const config = useEditorConfiguration();
  const { blockType } = useCurrentBlock();

  const EditorBlockComponent = useMemo<BlockComponent | null>(() => {
    return config.getBlockComponent(blockType);
  }, [blockType, config]);

  const hideCommonBlockContent = useMemo<boolean | null>(() => {
    return config.getHideCommonBlockContent(blockType);
  }, [blockType, config]);

  if (!EditorBlockComponent) {
    return null;
  }

  return (
    <Container column top fullWidth gap={2}>
      {!hideCommonBlockContent && <CommonBlockContent />}
      <EditorBlockComponent />
    </Container>
  );
};

export default EditorBlock;
