import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStore } from '@/components/store/Store';
import { Api } from '@/services/Api';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import { SortDirection, SortType } from '@/declarations/models/SortOption';
import { MediaCountResponse } from '@/declarations/models/CountResponses';
import { DescriptionList } from '@/views/DashboardView/widgets/DescriptionList';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';

export const MediaWidget = () => {
  const {
    state: { selectedSite },
  } = useStore();
  const { t: tComponents } = useTranslation('components');
  const [image, setImage] = useState<string>();
  const [counts, setCounts] = useState<MediaCountResponse>();
  useEffect(() => {
    if (selectedSite) {
      Api.getMediaCount(selectedSite.id!).fetchDirect(undefined).then(setCounts);
    }
  }, [selectedSite]);
  useEffect(() => {
    if (selectedSite) {
      Api.getM24MediaResources(selectedSite.id!, {
        type: MediaResourceType.IMAGE,
        order_by: SortType.CREATED_AT,
        order: SortDirection.DESC,
        rows: 1,
      })
        .fetchDirect(undefined)
        .then((media) => {
          if (media?.totalCount && media.totalCount > 0) {
            setImage(media.items[0].url);
          } else {
            setImage(undefined);
          }
        });
    }
  }, [selectedSite]);

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '150px',
        backgroundImage: image ? `url(${image})` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        color: 'white',
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Box
        sx={{
          backgroundColor: 'rgba(0,0,0,0.6)',
          p: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }}>
        <Container spaceBetween fullWidth>
          <Typography fontSize='1.25rem' fontWeight={500}>
            {tComponents('DashboardView.MediaWidget.Title')}
          </Typography>
          <MaterialSymbol name='image' sx={{ fontSize: '2rem' }} />
        </Container>
        <Container fullWidth column left>
          <DescriptionList
            items={
              counts
                ? Object.entries(counts).map(([k, v]) => [`${tComponents(`DashboardView.CountResLabel.${k}`)}`, v])
                : undefined
            }
          />
        </Container>
      </Box>
    </Box>
  );
};
