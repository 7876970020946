import Container from '@/components/Container';
import React from 'react';
import { useStore } from '@/components/store/Store';
import { Button } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';

export const SettingsShortcutWidget = () => {
  const {
    state: { selectedSiteLanguage },
  } = useStore();
  const { t: tComponents } = useTranslation('components');

  if (!selectedSiteLanguage) {
    return null;
  }

  return (
    <Container
      column
      fullWidth
      sx={{
        p: 4,
        backgroundColor: Styles.Colors.LIGHTEST_GREY,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}>
      <Button variant='contained' color='secondary' fullWidth href={`/language-settings/${selectedSiteLanguage}/menu`}>
        {tComponents('DashboardView.SettingsShortcutWidget.ChangeMenu')}
      </Button>
      <Button
        variant='contained'
        color='secondary'
        fullWidth
        href={`/language-settings/${selectedSiteLanguage}/footer`}>
        {tComponents('DashboardView.SettingsShortcutWidget.ChangeFooter')}
      </Button>
    </Container>
  );
};
