import React, { FC } from 'react';
import {
  DMAutoBlockModel,
  DmIdItem,
  DMManualBlockModel,
  DMViewType,
} from '../../../../declarations/models/blocks/DMBlock';
import { DMArtifact, DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import { BlockType } from '../../../../declarations/models/BlockType';
import MapPlaceholder from '../../../../assets/images/mjosa_map.png';
import DMBlockPreviewItem from './DMBlockPreviewItem';

interface DmBlockPreviewProps {
  dmBlock: DMAutoBlockModel | DMManualBlockModel;
  dmItemsFull?: Array<DMMediaModel>;
  museum?: DMArtifact;
  dmItems?: Array<DmIdItem>;
  paginateButton?: boolean | undefined;
  dmLink?: boolean;
}

export const DMBlockPreview: FC<DmBlockPreviewProps> = ({
  dmBlock,
  dmItemsFull,
  museum,
  dmItems,
  paginateButton = false,
  dmLink = false,
}) => {
  return (
    <div
      className={`ekultur ekultur--digitaltmuseum ekultur--${dmBlock.view} gridSize-${
        dmBlock?.gridSize || 'auto'
      } ekultur--${dmBlock?.width || 'auto'}`}>
      <div className='ekultur__intro'>
        {dmBlock?.visibletitle && dmBlock?.title ? <h2>{dmBlock.title}</h2> : null}
        {dmBlock?.body && <p>{dmBlock?.body}</p>}
      </div>
      {dmBlock.view === DMViewType.MAP ? (
        <div className='ekultur__content'>
          <div className='preview__map'>
            <div>Her vil det vises et kart med aktuelle punkter. Se endelig resultat i portal.</div>
            <img style={{}} src={MapPlaceholder} alt='map' />
          </div>
        </div>
      ) : (
        <div className='ekultur__grid'>
          {dmBlock.type === BlockType.DM_MANUAL && museum && (
            <div className='module ecard skin-digitaltmuseum' data-type='Museum'>
              <div className='module__media'>
                {!!museum?.images?.length && <img src={museum.images[0].url} alt='' />}
              </div>
              <div className='module__content'>
                <div className='module__head'>
                  <h2 className='ecard__title'>{dmBlock.museumData?.altTitle || museum.title}</h2>
                </div>
                <div className='module__body'>
                  <p>
                    {dmBlock.museumData?.altDescription ||
                      museum?.description ||
                      '(Her vises ingress hentet fra DM hvis ikke alternativ beskrivelse er angitt)'}
                  </p>
                </div>
                <div className='module__foot'>
                  <div className='module__button'>Besøk oss på DigitaltMuseum</div>
                </div>
              </div>
            </div>
          )}
          {dmItemsFull
            ? dmItemsFull.map((item) => <DMBlockPreviewItem key={item['artifact.uniqueId']} dmItem={item} />)
            : dmItems?.map((item) => <DMBlockPreviewItem key={item.uniqueId} uniqueId={item.uniqueId} />)}
        </div>
      )}
      {(paginateButton || dmLink) && (
        <div className='row ekultur__search'>
          <div className='row__grid'>
            <div className='row__footer'>
              {paginateButton && <div className='button button--primary'>Se flere treff</div>}
              {dmLink && (
                <div className='button button--primary'>{dmBlock.dmLinkText || 'Se mer på DigitaltMuseum'}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DMBlockPreview;
