import React, { forwardRef, ImgHTMLAttributes, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import PlaceholderImage from '../assets/images/broken_image.png';
import { Coordinate } from '../declarations/models/Coordinate';
import { mapRange } from '../utils/number';
import { mergeSx } from '../utils/mergeSx';

type InheritedProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'style'> & BoxProps;

export interface ImageProps extends InheritedProps {
  focusPoint?: Coordinate;
  disabled?: boolean;
  usePlaceholder?: boolean;
}

export const calculateObjectPosition = (x?: string, y?: string) => {
  if (!x || !y) {
    return undefined;
  }
  const xPercent = mapRange(Number(x), -1, 1, 0, 100);
  const yPercent = mapRange(Number(y), -1, 1, 100, 0);
  return `${xPercent}% ${yPercent}%`;
};

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt = '', src, focusPoint, sx = {}, disabled = false, usePlaceholder, ...attributes }, ref) => {
    const [isError, setIsError] = useState<boolean>(false);

    if (!usePlaceholder && (!src || isError)) return <Box sx={sx} />;

    return (
      <Box
        component='img'
        ref={ref}
        {...attributes}
        sx={mergeSx(
          {
            objectPosition: calculateObjectPosition(focusPoint?.x, focusPoint?.y),
            transition: `object-position 150ms ease-in-out`,
          },
          sx,
          disabled
            ? {
                opacity: '0.5',
                filter: 'grayscale(60%)',
              }
            : {},
        )}
        src={!src || isError ? PlaceholderImage : src}
        alt={alt}
        onError={() => setIsError(true)}
      />
    );
  },
);

Image.displayName = 'Image';

export default Image;
