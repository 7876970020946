import React, { FC, useCallback } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SharingContentApprovalStatus, SharingGivenModel } from '../../../declarations/models/SiteSharingModel';
import Container from '../../../components/Container';
import DeleteButton from '../../../components/DeleteButton';
import CollapseContainer from '../../../components/CollapseContainer';
import { apiTimestampToDate } from '../../../utils/dates';
import AcceptButton from '../../../components/AcceptButton';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';

export interface SiteSharingSettingsFormGivenItemProps {
  item: SharingGivenModel;
  borderColor?: string;
  onRequestSuccess: (result: SharingGivenModel) => void;
}

export const SiteSharingSettingsFormGivenItem: FC<SiteSharingSettingsFormGivenItemProps> = ({
  item,
  borderColor,
  onRequestSuccess,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const handleSaveSiteSharingRequest = useCallback(
    async (siteSharingRequest: SharingGivenModel): Promise<void | SharingGivenModel> => {
      const approval = item.approval_status === SharingContentApprovalStatus.APPROVED;
      const savedData = await Api.saveSiteSharingModel<SharingGivenModel>(
        siteSharingRequest,
        selectedSiteId,
      ).fetchDirect(null);
      if (!savedData) {
        enqueueSnackbar(tCommon(approval ? 'saveFailed' : 'deleteFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon(approval ? 'saveSuccess' : 'deleteSuccess'), { variant: 'success' });
        return { ...item, approval_status: savedData.approval_status };
      }
    },
    [enqueueSnackbar, item, selectedSiteId, tCommon],
  );

  const infoField = (title: string, text: string) => (
    <Container left sx={{ textAlign: 'center' }}>
      <Typography>{title}:</Typography>
      <Typography>{text}</Typography>
    </Container>
  );

  return (
    <Container py={1} fullWidth>
      <CollapseContainer
        outlined
        borderColor={borderColor}
        title={`${item.site_given} - ${tComponents(
          `Settings.SiteSettings.SiteSharingSettings.SharingContentType.${item.content_type}`,
        )}`}
        subTitle={tCommon(item.approval_status)}>
        <Container fullWidth column left gap={2}>
          {infoField(tCommon('createdAt'), apiTimestampToDate(item.created_at || '')?.toLocaleString() || '')}
          {infoField(tCommon('updatedAt'), apiTimestampToDate(item.updated_at || '')?.toLocaleString() || '')}
          {infoField(
            tComponents(`Settings.SiteSettings.SiteSharingSettings.contentType`),
            tComponents(`Settings.SiteSettings.SiteSharingSettings.SharingContentType.${item.content_type}`),
          )}

          <Container fullWidth right>
            {item.approval_status === SharingContentApprovalStatus.PENDING && (
              <>
                <AcceptButton
                  onConfirm={() => {
                    const itemToApprove = {
                      ...item,
                      approval_status: SharingContentApprovalStatus.APPROVED,
                      remote_site_id: item.site_id,
                    };
                    handleSaveSiteSharingRequest(itemToApprove).then((model) => {
                      if (model) {
                        onRequestSuccess(model);
                      }
                    });
                  }}
                />
                <DeleteButton
                  onConfirm={() => {
                    const itemToDeny = {
                      ...item,
                      approval_status: SharingContentApprovalStatus.DENIED,
                      remote_site_id: item.site_id,
                    };
                    handleSaveSiteSharingRequest(itemToDeny).then((model) => {
                      if (model) {
                        onRequestSuccess(model);
                      }
                    });
                  }}
                  deleteLabel={tCommon('decline')}
                  deleteIcon={<MaterialSymbol name='cancel' />}
                />
              </>
            )}
            <DeleteButton
              onConfirm={() => {
                const itemToRemove = {
                  ...item,
                  approval_status: SharingContentApprovalStatus.REMOVED,
                  remote_site_id: item.site_id,
                };
                handleSaveSiteSharingRequest(itemToRemove).then((model) => {
                  if (model) {
                    onRequestSuccess(model);
                  }
                });
              }}
            />
          </Container>
        </Container>
      </CollapseContainer>
    </Container>
  );
};

export default SiteSharingSettingsFormGivenItem;
