import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import { isM24MediaModel } from '../../../../utils/typeChecks';
import { ChildrenProp } from '../../../../declarations/ChildrenProp';
import { M24MediaModel } from '../../../../declarations/models/M24MediaModel';
import TextInput from '../../../../components/forms/TextInput';
import { BlockItemPathPrefix } from '../../../../declarations/models/BlockItemPathPrefix';

export interface AttachmentsBlockItemContentProps extends ChildrenProp {
  item: M24MediaModel;
  pathPrefix: BlockItemPathPrefix;
}

export const AttachmentsBlockItemContent: FC<AttachmentsBlockItemContentProps> = ({ pathPrefix, item }) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const fileName = isM24MediaModel(item) ? item.filename : '';
  return (
    <Container p={2} fullWidth>
      <Container gap={2} top spaceBetween fullWidth>
        <Container fullWidth column left>
          {(fileName || item?.url) && <Typography>{fileName || item?.url}</Typography>}

          <Container fullWidth gap={2} column top left py={1}>
            <TextInput label={tCommon('title')} fullWidth path={`${pathPrefix}.local.title`} defaultValue='' />
            <TextInput
              path={`${pathPrefix}.local.caption`}
              label={tCommon('description')}
              fullWidth
              multiline
              defaultValue=''
            />
          </Container>
          <Button
            sx={{ typography: 'caption', textAlign: 'left', '&, &:hover': { textDecoration: 'underline' } }}
            startIcon={<MaterialSymbol name='edit' fill />}>
            {tComp('M24Content.EditGlobalMediaData')}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default AttachmentsBlockItemContent;
