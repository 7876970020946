import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { MediaBlock } from '../../../declarations/models/blocks/MediaBlock';
import { MediaViewType } from '../../../declarations/models/blocks/MediaViewType';
import ImagePreview from '../../../components/ImagePreview';
import { isExternalMediaModel, isM24MediaModel } from '../../../utils/typeChecks';
import { ExternalMediaModel } from '../../../declarations/models/ExternalMediaModel';

export const MediaBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as MediaBlock;

  const getViewClass = () => {
    switch (currentBlock?.view) {
      case MediaViewType.LIST:
        return 'media--auto';
      case MediaViewType.LIST_NARROW:
        return 'media--narrow';
      case MediaViewType.LIST_WIDE:
        return 'media--wide';
      case MediaViewType.LIST_FULL:
        return 'media--full';
      case MediaViewType.GRID:
        return 'media--grid';
      case MediaViewType.GRID_NARROW:
        return 'media--grid-narrow';
      case MediaViewType.GRID_WIDE:
        return 'media--grid-wide';
      case MediaViewType.GRID_FULL:
        return 'media--grid-full';
      case MediaViewType.GRID_PACKERY:
        return 'media--autogrid';
      case MediaViewType.GRID_PACKERY_NARROW:
        return 'media--autogrid-narrow';
      case MediaViewType.GRID_PACKERY_WIDE:
        return 'media--autogrid-wide';
      case MediaViewType.GRID_PACKERY_FULL:
        return 'media--autogrid-full';
      case MediaViewType.SLIDESHOW:
        return 'media--slideshow';
      case MediaViewType.SLIDESHOW_NARROW:
        return 'media--slideshow-narrow';
      case MediaViewType.SLIDESHOW_WIDE:
        return 'media--slideshow-wide';
      case MediaViewType.SLIDESHOW_FULL:
        return 'media--slideshow-full';
      case MediaViewType.COMPARE_TWO_IMAGES:
        return 'media--compare';
      case MediaViewType.COMPARE_TWO_IMAGES_NARROW:
        return 'media--compare-narrow';
      case MediaViewType.COMPARE_TWO_IMAGES_WIDE:
        return 'media--compare  width-wide';
      case MediaViewType.COMPARE_TWO_IMAGES_FULL:
        return 'media--compare width-full';
      default:
        return '';
    }
  };

  const getViewWidth = () => {
    if (currentBlock?.view?.includes('wide')) {
      return 'wide';
    }
    if (currentBlock?.view?.includes('full')) {
      return 'full';
    }
    if (currentBlock?.view?.includes('narrow')) {
      return 'narrow';
    }
    if (currentBlock?.view?.includes('article')) {
      return 'article';
    }
    return 'auto';
  };

  const compareImagesArray = () => {
    const arr: string[][] = [[]];

    currentBlock?.items?.forEach((item, i) => {
      const index = Math.floor(i / 2);
      if (!arr[index]) {
        arr[index] = [];
      }
      if (isM24MediaModel(item)) {
        arr[index][i % 2] = item.url;
      }

      if (isExternalMediaModel(item)) {
        const itemWithUrl = item as ExternalMediaModel & { url: string };
        arr[index][i % 2] = itemWithUrl.url;
      }

      if (arr[index].length === 0) {
        arr.pop();
      }
    });

    if (arr[arr.length - 1]?.length !== 2) {
      arr.pop();
    }

    return arr;
  };

  return (
    <>
      <div className={`article__grid alignmodify--${currentBlock?.view}`}>
        <section className='article__bodytext'>
          {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
          <p>{currentBlock?.body}</p>
        </section>
      </div>
      <div className='section__grid'>
        <div
          data-fullscreen={false}
          data-media-thumb-captions-visible={currentBlock.displayCaptionsOnThumbnails || false}
          className={`media media-format--${currentBlock?.format} media-layout--${
            currentBlock?.layout || 'auto'
          } width--${getViewWidth()} ${getViewClass()} gridSize-${currentBlock?.gridSize || 'auto'}`}>
          {[
            MediaViewType.LIST,
            MediaViewType.LIST_NARROW,
            MediaViewType.LIST_WIDE,
            MediaViewType.LIST_FULL,
            MediaViewType.GRID,
            MediaViewType.GRID_NARROW,
            MediaViewType.GRID_WIDE,
            MediaViewType.GRID_FULL,
            MediaViewType.GRID_PACKERY,
            MediaViewType.GRID_PACKERY_NARROW,
            MediaViewType.GRID_PACKERY_WIDE,
            MediaViewType.GRID_PACKERY_FULL,
          ].includes(currentBlock?.view) && (
            <ul className='media__list'>
              {currentBlock?.items?.map((item, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className={`media__item galleryitem--${item.local?.size || ''}`} key={index}>
                    <figure
                      className={`module module--media type--${item?.type || 'image'}  format--${
                        currentBlock?.format || 'auto'
                      } layout--${currentBlock?.layout || 'auto'}`}>
                      <div className={`module__grid skin-media ${currentBlock?.skin || 'skin-media-auto'}`}>
                        {item.type === 'audio' /* eslint-disable-next-line jsx-a11y/media-has-caption */ && (
                          <audio controls>
                            <source src={item?.play_urls?.mp3} type='audio/mp3' />
                          </audio>
                        )}
                        {item.type === 'video' /* eslint-disable-next-line jsx-a11y/media-has-caption */ && (
                          <video controls>
                            <source src={item?.play_urls?.mp4} type='video/mp4' />
                          </video>
                        )}
                        {item.type !== 'audio' && item.type !== 'video' && <ImagePreview mediaObject={item} />}
                        <div className='module__content'>
                          <figcaption className='media__caption'>
                            {!item?.local?.disableCaption && (
                              <span>{item?.local?.caption || item.content?.caption}</span>
                            )}
                            <span className='media__credit  caption--credit'>
                              {item?.content?.credits &&
                                item?.content?.credits?.length > 0 &&
                                item?.content?.credits?.map(
                                  (credit, i) =>
                                    credit?.name && (
                                      <span className={credit?.type} key={credit?.name}>
                                        {credit?.name}
                                      </span>
                                    ),
                                )}
                            </span>
                          </figcaption>
                        </div>
                      </div>
                    </figure>
                  </li>
                );
              })}
            </ul>
          )}
          {[
            MediaViewType.SLIDESHOW,
            MediaViewType.SLIDESHOW_NARROW,
            MediaViewType.SLIDESHOW_WIDE,
            MediaViewType.SLIDESHOW_FULL,
          ].includes(currentBlock?.view) &&
            currentBlock?.items?.length > 0 && (
              <div className='media__viewport' style={{ overflow: 'hidden', position: 'relative' }}>
                <ul className='media__list'>
                  <li className='media__item media_active-slide item--current galleryitem--'>
                    <figure
                      className={`module module--media format--${currentBlock?.format} layout--${currentBlock?.layout} module--small`}>
                      <div
                        className={`module__grid ${
                          isM24MediaModel(currentBlock?.items[0]) ? 'skin-media' : 'skin-module-media'
                        } ${currentBlock?.skin} `}>
                        <ImagePreview
                          mediaObject={currentBlock?.items[0]}
                          imgClassName='img-landscape'
                          skin={`${isM24MediaModel(currentBlock?.items[0]) ? 'skin-media' : 'skin-module-media'} ${
                            currentBlock?.skin || 'skin-media-auto'
                          }`}
                        />
                      </div>
                    </figure>
                  </li>
                </ul>
              </div>
            )}
          {[
            MediaViewType.COMPARE_TWO_IMAGES,
            MediaViewType.COMPARE_TWO_IMAGES_NARROW,
            MediaViewType.COMPARE_TWO_IMAGES_WIDE,
            MediaViewType.COMPARE_TWO_IMAGES_FULL,
          ].includes(currentBlock?.view) &&
            currentBlock?.items?.length >= 2 && (
              <>
                {compareImagesArray()?.map((imagePair, index) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <figure key={index} className='module module--media module--normal'>
                      <div className='twentytwenty-wrapper twentytwenty-horizontal'>
                        <div
                          className='module__media twentytwenty-container'
                          style={{
                            height: `480px`,
                          }}>
                          <img
                            src={`${imagePair[0]}?dimension=800x800`}
                            alt=''
                            style={{
                              position: 'absolute',
                              overflow: 'hidden',
                              display: 'block',
                              zIndex: 2,
                              clipPath: 'polygon(0% 0%,50% 0%,50% 100%,0% 100%)',
                              top: 0,
                              left: 0,
                            }}
                          />
                          <img
                            src={`${imagePair[1]}?dimension=800x800`}
                            alt=''
                            style={{
                              position: 'absolute',
                              overflow: 'hidden',
                              display: 'block',
                              zIndex: 1,
                              right: 0,
                            }}
                          />
                        </div>
                      </div>
                    </figure>
                  );
                })}
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default MediaBlockPreview;
