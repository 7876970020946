import { SxProps, Theme } from '@mui/material';

export function mergeSx(...styles: Array<SxProps<Theme>>): SxProps<Theme> {
  return styles.reduce((acc, val) => {
    if (typeof val === 'object' && Array.isArray(val)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return [...acc, ...(val as Array<unknown>)];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [...acc, val];
  }, [] as SxProps<Theme>);
}
