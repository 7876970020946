import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { Avatar, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomIcon from '@/components/CustomIcon';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { BannerBlock, BannerSize } from '../../../declarations/models/blocks/BannerBlock';
import ActionMenu from '../../../components/ActionMenu';
import Styles from '../../../assets/js/Styles';

export const PageBlockBannerSizeSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.size`>({
    name: `${blockPath}.size`,
  });
  const ITEM_SIZE = 60;

  const getIcon = (size?: BannerSize) => {
    switch (size) {
      case BannerSize.SMALL:
        return <CustomIcon name='size_s' />;
      case BannerSize.SMALL_MEDIUM:
        return <CustomIcon name='size_s_large' />;
      case BannerSize.MEDIUM_SMALL:
        return <CustomIcon name='size_m_small' />;
      case BannerSize.MEDIUM:
        return <CustomIcon name='size_m' />;
      case BannerSize.LARGE_MEDIUM:
        return <CustomIcon name='size_l' />;
      case BannerSize.MEDIUM_LARGE:
        return <CustomIcon name='size_m_large' />;
      case BannerSize.LARGE:
        return <CustomIcon name='size_l' />;
      case BannerSize.XL:
        return <CustomIcon name='size_xl' />;
      default:
        return <CustomIcon name='size_m' />;
    }
  };

  const Item = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingRight: '5px',
    textAlign: 'end',
    whiteSpace: 'nowrap',
    minHeight: `${ITEM_SIZE}px`,
    minWidth: `${ITEM_SIZE}px`,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Styles.Colors.THEME_PRIMARY,
    color: theme.palette.getContrastText(Styles.Colors.THEME_PRIMARY_BG_HOVER),
  }));

  const ItemOverlay = styled('div')(() => ({
    backgroundColor: Styles.Colors.SIZE_SELECTOR_BG_HOVER,
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'block',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: Styles.Colors.THEME_PRIMARY,
    top: 0,
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  }));

  const ItemContainer = styled('div')(() => ({
    minHeight: `${ITEM_SIZE}px`,
    minWidth: `${ITEM_SIZE}px`,
    position: 'relative',
  }));

  return (
    <ActionMenu
      id='banner-id-selector'
      ariaLabel={tAria('components.PageBlockBannerSizeSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockBannerSizeSetting.Tooltip')}
      icon={getIcon(value)}>
      <Grid
        container
        direction='column'
        flexWrap='nowrap'
        width={`${4 * ITEM_SIZE + 16}px`}
        height={`${4 * ITEM_SIZE}px`}
        paddingLeft='8px'
        paddingRight='8px'>
        <Grid container direction='row' flexWrap='nowrap'>
          <Grid container direction='column' flexWrap='nowrap'>
            <Grid container flexWrap='nowrap'>
              <Grid item zeroMinWidth xs={6}>
                <ItemContainer onClick={() => onChange(BannerSize.SMALL)}>
                  <Item sx={{ backgroundColor: Styles.Colors.SIZE_SELECTOR_DARK_GREY }}>{BannerSize.SMALL}</Item>
                  <ItemOverlay sx={{ zIndex: 100, opacity: value === BannerSize.SMALL ? 1 : 0 }} />
                </ItemContainer>
              </Grid>
              <Grid item zeroMinWidth xs={6}>
                <ItemContainer onClick={() => onChange(BannerSize.MEDIUM_SMALL)}>
                  <Item sx={{ backgroundColor: Styles.Colors.SIZE_SELECTOR_MEDIUM_DARK_GREY }}>
                    {BannerSize.MEDIUM_SMALL}
                  </Item>
                  <ItemOverlay
                    sx={{
                      width: `${2 * ITEM_SIZE}px`,
                      left: `${-ITEM_SIZE}px`,
                      zIndex: 90,
                      opacity: value === BannerSize.MEDIUM_SMALL ? 1 : 0,
                    }}
                  />
                </ItemContainer>
              </Grid>
            </Grid>
            <Grid container flexWrap='nowrap'>
              <Grid item zeroMinWidth xs={6}>
                <ItemContainer onClick={() => onChange(BannerSize.SMALL_MEDIUM)}>
                  <Item sx={{ backgroundColor: Styles.Colors.SIZE_SELECTOR_MEDIUM_DARK_GREY }}>
                    {BannerSize.SMALL_MEDIUM}
                  </Item>
                  <ItemOverlay
                    sx={{
                      height: `${2 * ITEM_SIZE}px`,
                      top: `${-ITEM_SIZE}px`,
                      zIndex: 90,
                      opacity: value === BannerSize.SMALL_MEDIUM ? 1 : 0,
                    }}
                  />
                </ItemContainer>
              </Grid>
              <Grid item zeroMinWidth xs={6}>
                <ItemContainer onClick={() => onChange(BannerSize.MEDIUM)}>
                  <Item sx={{ backgroundColor: Styles.Colors.SIZE_SELECTOR_MEDIUM_GREY }}>{BannerSize.MEDIUM}</Item>
                  <ItemOverlay
                    sx={{
                      width: `${2 * ITEM_SIZE}px`,
                      height: `${2 * ITEM_SIZE}px`,
                      top: `${-ITEM_SIZE}px`,
                      left: `${-ITEM_SIZE}px`,
                      zIndex: 80,
                      opacity: value === BannerSize.MEDIUM ? 1 : 0,
                    }}
                  />
                </ItemContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item zeroMinWidth xs={12}>
              <ItemContainer onClick={() => onChange(BannerSize.LARGE_MEDIUM)}>
                <Item
                  sx={{
                    width: `${2 * ITEM_SIZE}px`,
                    height: `${2 * ITEM_SIZE}px`,
                    backgroundColor: Styles.Colors.SIZE_SELECTOR_MEDIUM_LIGHT_GREY,
                  }}>
                  {BannerSize.LARGE_MEDIUM}
                </Item>
                <ItemOverlay
                  sx={{
                    width: `${4 * ITEM_SIZE}px`,
                    height: `${2 * ITEM_SIZE}px`,
                    left: `${-2 * ITEM_SIZE}px`,
                    zIndex: 70,
                    opacity: value === BannerSize.LARGE_MEDIUM ? 1 : 0,
                  }}
                />
              </ItemContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid container flexWrap='nowrap'>
          <Grid item zeroMinWidth xs={6}>
            <ItemContainer onClick={() => onChange(BannerSize.MEDIUM_LARGE)}>
              <Item
                sx={{
                  width: `${2 * ITEM_SIZE}px`,
                  backgroundColor: Styles.Colors.SIZE_SELECTOR_MEDIUM_LIGHT_GREY,
                }}>
                {BannerSize.MEDIUM_LARGE}
              </Item>
              <ItemOverlay
                sx={{
                  width: `${2 * ITEM_SIZE}px`,
                  height: `${3 * ITEM_SIZE}px`,
                  top: `${-2 * ITEM_SIZE}px`,
                  zIndex: 70,
                  opacity: value === BannerSize.MEDIUM_LARGE ? 1 : 0,
                }}
              />
            </ItemContainer>
          </Grid>
          <Grid item zeroMinWidth xs={6}>
            <ItemContainer onClick={() => onChange(BannerSize.LARGE)}>
              <Item sx={{ width: `${2 * ITEM_SIZE}px`, backgroundColor: Styles.Colors.SIZE_SELECTOR_LIGHT_GREY }}>
                {BannerSize.LARGE}
              </Item>
              <ItemOverlay
                sx={{
                  width: `${4 * ITEM_SIZE}px`,
                  height: `${3 * ITEM_SIZE}px`,
                  top: `${-2 * ITEM_SIZE}px`,
                  left: `${-2 * ITEM_SIZE}px`,
                  zIndex: 60,
                  opacity: value === BannerSize.LARGE ? 1 : 0,
                }}
              />
            </ItemContainer>
          </Grid>
        </Grid>
        <Grid container flexWrap='nowrap'>
          <Grid item zeroMinWidth xs={12}>
            <ItemContainer onClick={() => onChange(BannerSize.XL)}>
              <Item sx={{ width: `${4 * ITEM_SIZE}px`, backgroundColor: Styles.Colors.SIZE_SELECTOR_LIGHTEST_GREY }}>
                {BannerSize.XL}
              </Item>
              <ItemOverlay
                sx={{
                  width: `${4 * ITEM_SIZE}px`,
                  height: `${4 * ITEM_SIZE}px`,
                  top: `${-3 * ITEM_SIZE}px`,
                  zIndex: 50,
                  opacity: value === BannerSize.XL ? 1 : 0,
                }}
              />
            </ItemContainer>
          </Grid>
        </Grid>
      </Grid>
    </ActionMenu>
  );
};
