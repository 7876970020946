import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import { Page } from '@/declarations/models/Page';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../CurrentBlockProvider';
import { ArticleTitleBlock as ArticleTitleBlockModel } from '../../../declarations/models/blocks/ArticleTitleBlock';
import TextInput from '../../../components/forms/TextInput';
import Container from '../../../components/Container';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';

import SwitchInput from '../../../components/forms/SwitchInput';

export const ArticleTitleBlock: BlockComponent = () => {
  const { blockPath } = useCurrentBlock();

  const { t: tComp } = useTranslation('components');
  const syncPageDescription = useWatch<BlockSpecificPage<ArticleTitleBlockModel>, `${BlockPath}.syncPageDescription`>({
    name: `${blockPath}.syncPageDescription`,
  });
  const leadtext = useWatch<BlockSpecificPage<ArticleTitleBlockModel>, `${BlockPath}.leadtext`>({
    name: `${blockPath}.leadtext`,
  });

  const {
    field: { onChange: onChangePageDescription },
  } = useController<Page, 'description'>({ name: 'description' });

  useEffect(() => {
    if (syncPageDescription) {
      onChangePageDescription(leadtext);
    }
  }, [syncPageDescription, leadtext, onChangePageDescription]);

  return (
    <Container gap={2} column top left fullWidth>
      <TextInput<BlockSpecificPage<ArticleTitleBlockModel>>
        path={`${blockPath}.title`}
        label={tComp('ArticleTitleBlock.Heading')}
        multiline
        size='small'
        rows='2'
      />

      <SwitchInput path={`${blockPath}.hidetitle`} label={tComp('BannerBlock.BannerTitle.HideTitleLabel')} />

      <TextInput<BlockSpecificPage<ArticleTitleBlockModel>>
        path={`${blockPath}.leadtext`}
        label={tComp('ArticleTitleBlock.Preamble')}
        multiline
        size='small'
        rows='4'
      />

      <TextInput<BlockSpecificPage<ArticleTitleBlockModel>>
        path='insert_title'
        label={tComp('ArticleTitleBlock.InsertTitle')}
        size='small'
        rows='1'
      />

      <SwitchInput path={`${blockPath}.hideInsertTitle`} label={tComp('ArticleTitleBlock.HideInsertTitle')} />
    </Container>
  );
};

export default ArticleTitleBlock;
