/* istanbul ignore file */

export enum ViewType {
  LIST = 'list',
  GRID = 'grid',
}

export enum ViewWidth {
  AUTO = 'auto',
  WIDE = 'wide',
  FULL_WIDTH = 'full',
}
