import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { EditorContext } from './lib/declarations/EditorContext';
import PageEditor from './PageEditor/PageEditor';

export interface EditorProps {
  context: EditorContext;
}

/**
 * The purpose of this component is to render the Editor based on the provided context.
 * @param context
 * @param idPathParam
 * @constructor
 */
export const Editor: FC<EditorProps> = ({ context }) => {
  const { pathname } = useLocation();

  switch (context) {
    case EditorContext.PAGE:
      return <PageEditor key={pathname} />;
    default:
      // eslint-disable-next-line no-console
      console.error(`[EDITOR] Editor for context ${context} no implemented...`);
      return null;
  }
};

export default Editor;
