import SettingsViewLayout from '@/components/SettingsViewLayout';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@/declarations/models/Tag';
import { Api } from '@/services/Api';
import { TagType } from '@/declarations/models/TagType';
import { useStore } from '@/components/store/Store';
import { CommonTagEditingList } from '@/views/SettingsView/CommonTagEditingList';

const isCardCategoryPredicate = (tag: Tag) => Boolean(tag?.content?.isCardCategory);
export const SiteCardCategoriesSettings: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const selectedSiteId = useMemo(() => state.selectedSite?.id || 0, [state.selectedSite]);
  const fetchMethod = useCallback(
    () => Api.getAllTagsForSite(selectedSiteId, { type: TagType.CATEGORY }),
    [selectedSiteId],
  );

  return (
    <SettingsViewLayout
      title={tComponents('MainMenu.settings.cardCategories')}
      saveButtonInHeader={false}
      saveButtonInTop={false}>
      <CommonTagEditingList
        searchLabel={tComponents('Settings.SiteSettings.SiteKeywordsSettings.newCardCategory')}
        getTagsMethod={fetchMethod}
        defaultNewTag={{
          type: TagType.CATEGORY,
          content: {
            isCardCategory: true,
          },
        }}
        postFetchPredicates={[isCardCategoryPredicate]}
      />
    </SettingsViewLayout>
  );
};
