import React, { FC, ReactNode } from 'react';
import { Button, ButtonGroup, Tooltip, TooltipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ButtonGroupProps } from '@mui/material/ButtonGroup';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ViewType } from '../declarations/ViewType';

export interface ViewTypeSelectorProps extends Pick<ButtonGroupProps, 'color'> {
  value: ViewType;
  onChange: (viewType: ViewType) => void;
  options?: Array<ViewType>;
  tooltipPlacement?: TooltipProps['placement'];
}

const VIEW_TYPE_ICONS: Record<ViewType, ReactNode> = {
  [ViewType.LIST]: <MaterialSymbol name='list' />,
  [ViewType.GRID]: <MaterialSymbol name='view_comfy' />,
};

/**
 * A button-group where the user can select how data should be displayed
 * @param value
 * @param onChange
 * @param options The available ViewTypes
 * @param variant
 * @param color
 * @constructor
 */
export const ViewTypeSelector: FC<ViewTypeSelectorProps> = ({
  value,
  onChange,
  options = Object.values(ViewType),
  color = 'secondary',
  tooltipPlacement,
}) => {
  const { t } = useTranslation('common');
  return (
    <ButtonGroup variant='outlined' color={color} disableElevation>
      {options.map((option) => {
        const label = t(`ViewType.${option}`);
        const isSelected = option === value;
        return (
          <Tooltip key={option} title={label} placement={tooltipPlacement} arrow>
            <Button
              aria-label={label}
              aria-pressed={isSelected}
              variant={isSelected ? 'contained' : 'outlined'}
              onClick={() => onChange(option)}>
              {VIEW_TYPE_ICONS[option]}
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default ViewTypeSelector;
