import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BlockPath } from '../../editor/PageEditor/CurrentBlockProvider';
import Container from '../Container';
import { BlockSpecificPage } from '../../editor/lib/declarations/BlockSpecificPage';
import { LinkType } from '../../declarations/models/LinkType';
import { BaseBlock } from '../../declarations/models/blocks/BaseBlock';
import { Page } from '../../declarations/models/Page';
import { LinkExternal, LinkTarget, M24MediaLinkItem } from '../../declarations/models/LinkExternal';
import AddExternalLinkModal from './AddExternalLinksModal';
import LinkListItem from './LinkListItem';
import { Draggable } from '../../declarations/Draggable';
import M24MediaFinder from '../Finder/M24MediaFinder/M24MediaFinder';
import { MediaResourceType } from '../../declarations/models/MediaResourceType';
import M24PageFinder from '../Finder/M24PageFinder/M24PageFinder';
import RadioInput from '../forms/RadioInput';
import { LinkStyle } from '../../declarations/models/LinkStyle';
import { useEditorModel } from '../../editor/lib/components/EditorDataProvider';
import SwitchInput from '../forms/SwitchInput';
import { EditorEvent, EventManagerApi } from '../../editor/lib/eventManager/EditorEventManager';
import Styles from '../../assets/js/Styles';

export interface LinksProps {
  itemsArrayName?: string;
  linkItems?: Array<LinkExternal | M24MediaLinkItem | Page>;
  onUpdate: (changeFileIndex: number, item: M24MediaLinkItem) => void;
  onAppend: (item: LinkExternal | M24MediaLinkItem | Page) => void;
  onRemove: (index: number) => void;
  onReorder: (fromIndex: number, toIndex: number) => void;
  linkTypes?: Array<LinkType>;
  linkStyleTypes?: Array<LinkStyle>;
  showLinkStyleSelector?: boolean;
  showListChildrenInput?: boolean;
  showLinkDescriptionField?: boolean;
  showLinkItems?: boolean;
  blockPath: BlockPath | `${BlockPath}.${string}.${number}`;
  useEventHandler?: (handler: (event: EditorEvent) => Promise<void> | void) => void;
  useEventManager?: EventManagerApi;
}

export const Links: FC<LinksProps> = ({
  itemsArrayName = 'items',
  linkItems,
  onUpdate,
  onAppend,
  onRemove,
  onReorder,
  linkTypes = [LinkType.PAGE, LinkType.LINK, LinkType.DOCUMENT],
  linkStyleTypes = [LinkStyle.BUTTONS, LinkStyle.TEXT_LINKS],
  showLinkStyleSelector = true,
  showListChildrenInput = false,
  showLinkDescriptionField = false,
  showLinkItems = true,
  blockPath,
  useEventHandler,
  useEventManager,
}) => {
  const { t } = useTranslation('components');
  const model: Page = useEditorModel<Page>();
  const children = model.children as Array<Page>;
  const [addInternalExternalLinkModalOpen, setAddInternalExternalLinkModalOpen] = useState<boolean>(false);
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);
  const [eventFinderOpen, setEventFinderOpen] = useState<boolean>(false);
  const [mediaFinderOpen, setMediaFinderOpen] = useState<boolean>(false);
  const [fileToChange, setFileToChange] = useState<M24MediaLinkItem | null>();
  const [changeFileIndex, setChangeFileIndex] = useState<number | null>();

  const handleAddOrChangeM24MediaLinkItem = (item: M24MediaLinkItem) => {
    if (changeFileIndex != null && fileToChange != null) {
      onUpdate(changeFileIndex, {
        ...item,
        local: {
          ...(item.local || {}),
          title: fileToChange.local.title,
        },
        target: fileToChange.target,
      });
      setChangeFileIndex(null);
      setFileToChange(null);
    } else {
      onAppend(item);
    }
  };

  const handleAddM24PageLink = (item: Page) => {
    onAppend({
      ...item,
      target: LinkTarget.STANDARD,
    });
  };

  const getLinkKey = (link: LinkExternal | M24MediaLinkItem | Page) => {
    return `${link.id}-${link.type}`;
  };

  return (
    <Container fullWidth column>
      <Container left fullWidth mt={1} mb={0}>
        <Container left>
          {linkTypes.includes(LinkType.PAGE) && (
            <>
              <Button
                variant='contained'
                color='secondary'
                sx={{ flex: '1 0 auto' }}
                onClick={() => setPageFinderOpen(true)}>
                {t(`Links.LinkButtons.${LinkType.PAGE}`)}
              </Button>
              <Button
                variant='contained'
                color='secondary'
                sx={{ flex: '1 0 auto' }}
                onClick={() => setEventFinderOpen(true)}>
                {t(`Links.LinkButtons.${LinkType.EVENT}`)}
              </Button>
            </>
          )}
          {linkTypes.includes(LinkType.DOCUMENT) && (
            <Button
              variant='contained'
              color='secondary'
              sx={{ flex: '1 0 auto' }}
              onClick={() => setMediaFinderOpen(true)}>
              {t(`Links.LinkButtons.${LinkType.DOCUMENT}`)}
            </Button>
          )}
          {linkTypes.includes(LinkType.LINK) && (
            <Button
              variant='outlined'
              color='secondary'
              sx={{ flex: '1 0 auto' }}
              onClick={() => setAddInternalExternalLinkModalOpen(true)}>
              {t(`Links.LinkButtons.${LinkType.LINK}`)}
            </Button>
          )}
        </Container>
      </Container>

      {linkItems && showLinkItems && linkItems.length > 0 ? (
        <Container py={2} mt={2} gap={0} column top left fullWidth>
          {linkItems?.map((link, i) => (
            <LinkListItem
              key={getLinkKey(link)}
              linkType={link.type as LinkType}
              type={Draggable.OTHER}
              index={i}
              onReorder={onReorder}
              onDelete={onRemove}
              onChangeFile={() => {
                setMediaFinderOpen(true);
                setChangeFileIndex(i);
                setFileToChange(link as M24MediaLinkItem);
              }}
              itemsArrayName={itemsArrayName}
              showLinkDescriptionField={showLinkDescriptionField}
              blockPath={blockPath}
              useEventHandler={useEventHandler}
              useEventManager={useEventManager}
              color={Styles.Colors.LIGHT_GREY}
            />
          ))}
        </Container>
      ) : null}

      <AddExternalLinkModal
        open={addInternalExternalLinkModalOpen}
        onClose={() => {
          setAddInternalExternalLinkModalOpen(false);
        }}
        linkType={LinkType.LINK}
        onAddExternalLink={onAppend}
      />
      <M24MediaFinder
        open={mediaFinderOpen}
        onClose={() => {
          setMediaFinderOpen(false);
          setChangeFileIndex(null);
          setFileToChange(null);
        }}
        onSelectionConfirmed={(items) =>
          items.forEach((item) => {
            const m24MediaLinkItem = item.source as M24MediaLinkItem;
            m24MediaLinkItem.local = { title: m24MediaLinkItem.filename, ...m24MediaLinkItem.local };
            m24MediaLinkItem.target = LinkTarget.STANDARD;
            m24MediaLinkItem.type = MediaResourceType.DOCUMENT;
            handleAddOrChangeM24MediaLinkItem(m24MediaLinkItem);
          })
        }
        defaultResourceType={MediaResourceType.DOCUMENT}
        selectableResourceTypes={[MediaResourceType.DOCUMENT]}
        multiSelect
      />
      <M24PageFinder
        open={pageFinderOpen}
        onClose={() => setPageFinderOpen(false)}
        onSelectionConfirmed={(items) => items.forEach((item) => handleAddM24PageLink(item.source))}
        multiSelect
      />

      <M24PageFinder
        open={eventFinderOpen}
        onClose={() => setEventFinderOpen(false)}
        onSelectionConfirmed={(items) => items.forEach((item) => handleAddM24PageLink(item.source))}
        multiSelect
        isEvent
      />

      {showListChildrenInput && (
        <Container py={1} mt={1} gap={0} column top left fullWidth>
          <SwitchInput
            fullWidth={false}
            path={`${blockPath}.listchildren`}
            label={`${t('Links.ListChildren')} (${children?.length || 0})`}
          />
        </Container>
      )}

      {linkItems && showLinkStyleSelector && (
        <RadioInput<BlockSpecificPage<BaseBlock>, LinkStyle>
          options={linkStyleTypes}
          getOptionKey={(o) => o}
          getOptionLabel={(o) => t(`Links.Options.${o}`)}
          path={`${blockPath as unknown as BlockPath}.linkstyle`}
          defaultValue={LinkStyle.TEXT_LINKS}
          label={`${t(`Links.SelectLinkStyle`)}:`}
          direction='horizontal'
          required
        />
      )}
    </Container>
  );
};

export default Links;
