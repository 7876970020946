import React, { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '../../../components/DeleteButton';
import ActionMenu, { ActionMenuProps } from '../../../components/ActionMenu';
import TextInput from '../../../components/forms/TextInput';

export interface EditorSectionActionButtonProps extends Pick<ActionMenuProps, 'edge' | 'tooltipPlacement'> {
  isVisible?: boolean;
  onDuplicate?: () => void;
  onToggleVisibility?: () => void;
  onDelete?: () => void;
  hideable?: boolean;
  duplicatable?: boolean;
  deletable?: boolean;
  copyable?: boolean;
  onCopy?: () => void;
  cuttable?: boolean;
  onCut?: () => void;
}

export const EditorSectionActionButton: FC<EditorSectionActionButtonProps> = ({
  isVisible,
  onDuplicate,
  onToggleVisibility,
  onDelete,
  onCopy,
  onCut,
  copyable = false,
  cuttable = false,
  hideable = false,
  duplicatable = false,
  deletable = false,
  edge,
  tooltipPlacement = 'top',
}) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  if (!hideable && !duplicatable && !deletable) {
    return null;
  }

  return (
    <ActionMenu
      id='section-actions'
      ariaLabel={tAria('components.EditorSectionActionButton.ActionMenuLabel')}
      tooltip={tComponents('EditorSectionActionButton.Tooltip')}
      tooltipPlacement={tooltipPlacement}
      icon={isVisible ? <MaterialSymbol name='visibility' fill /> : <MaterialSymbol name='visibility_off' />}
      edge={edge}>
      {hideable && (
        <MenuItem onClick={onToggleVisibility}>
          <ListItemIcon>
            {isVisible ? <MaterialSymbol name='visibility_off' fill /> : <MaterialSymbol name='visibility' fill />}
          </ListItemIcon>
          <ListItemText>{tCommon(isVisible ? 'hide' : 'show')}</ListItemText>
        </MenuItem>
      )}

      {duplicatable && (
        <MenuItem onClick={onDuplicate} divider>
          <ListItemIcon>
            <MaterialSymbol name='content_copy' />
          </ListItemIcon>
          <ListItemText>{tCommon('duplicate')}</ListItemText>
        </MenuItem>
      )}
      {copyable && (
        <MenuItem
          onClick={onCopy}
          divider
          sx={{
            backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHTEST,
            '&:hover': {
              backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHT,
            },
          }}>
          <ListItemIcon>
            <MaterialSymbol name='content_copy' fill />
          </ListItemIcon>
          <ListItemText>{tCommon('copy')}</ListItemText>
        </MenuItem>
      )}
      {cuttable && (
        <MenuItem
          onClick={onCut}
          divider
          sx={{
            backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHTEST,
            '&:hover': {
              backgroundColor: Styles.Colors.COPY_PASTE_PURPLE_LIGHT,
            },
          }}>
          <ListItemIcon>
            <MaterialSymbol name='content_cut' />
          </ListItemIcon>
          <ListItemText>{tCommon('cut')}</ListItemText>
        </MenuItem>
      )}

      {deletable && <DeleteButton component='MenuItem' onConfirm={() => onDelete?.()} />}
    </ActionMenu>
  );
};

export default EditorSectionActionButton;
