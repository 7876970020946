import React, { FC, Fragment } from 'react';
import { useController } from 'react-hook-form';
import Container from '../../components/Container';
import { Page } from '../../declarations/models/Page';
import EditorSectionActionButton from '../lib/components/EditorSectionActionButton';
import { BlockAction } from '../lib/declarations/BlockAction';
import { useCurrentBlock } from './CurrentBlockProvider';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';

export interface PageBlockSettingsProps {
  onDuplicateBlock: () => void;
  onRemoveBlock: () => void;
  onCopyBlock?: () => void;
  onCutBlock?: () => void;
}

export const BlockSettings: FC<PageBlockSettingsProps> = ({
  onDuplicateBlock,
  onRemoveBlock,
  onCopyBlock,
  onCutBlock,
}) => {
  const { blockPath, blockType } = useCurrentBlock();

  const editorConfiguration = useEditorConfiguration();

  const {
    field: { value: isVisible, onChange: setIsVisible },
  } = useController<Page>({ name: `${blockPath}.visible` });

  return (
    <>
      <Container gap={0} right>
        {editorConfiguration.getBlockSettings(blockType).map((SettingsComponent, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`custom-block-settings-${i}`}>
            <SettingsComponent />
          </Fragment>
        ))}

        <EditorSectionActionButton
          isVisible={isVisible !== 'hidden'}
          onDuplicate={onDuplicateBlock}
          onCopy={onCopyBlock}
          onCut={onCutBlock}
          onToggleVisibility={() => setIsVisible(isVisible === 'hidden' ? 'visible' : 'hidden')}
          onDelete={onRemoveBlock}
          hideable={editorConfiguration.isActionAvailableForBlock(blockType, BlockAction.TOGGLE_BLOCK_VISIBILITY)}
          duplicatable={editorConfiguration.isActionAvailableForBlock(blockType, BlockAction.DUPLICATE_BLOCK)}
          deletable={editorConfiguration.isActionAvailableForBlock(blockType, BlockAction.DELETE_BLOCK)}
          copyable={editorConfiguration.isActionAvailableForBlock(blockType, BlockAction.COPY_BLOCK)}
          cuttable={editorConfiguration.isActionAvailableForBlock(blockType, BlockAction.CUT_BLOCK)}
        />
      </Container>
    </>
  );
};

export default BlockSettings;
