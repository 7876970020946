import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

export interface HeaderTitleProps {
  title: ReactNode;
  disabled?: boolean;
}

export const HeaderTitle: FC<HeaderTitleProps> = ({ title, disabled = false }) => (
  <Typography
    sx={{
      textDecoration: 'underline',
      color: disabled ? 'text.disabled' : 'text.primary',
      fontWeight: 'bold',
      textAlign: 'left',
      px: 1,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }}>
    {title}
  </Typography>
);

export default HeaderTitle;
