import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useEditorData } from '../../lib/components/EditorDataProvider';
import { Skin } from '../../../declarations/models/Skin';
import { Page } from '../../../declarations/models/Page';
import SkinSelector from '../../lib/components/settings/SkinSelector';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const PageBlockSkinSelector: SettingsComponent = () => {
  const { blockPath } = useCurrentBlock();
  const { skins } = useEditorData();

  const availableSkins = useMemo<Array<Skin>>(() => {
    return skins?.filter((skin) => skin.scope?.includes('grid') !== false);
  }, [skins]);

  const {
    field: { value: skinClass, onChange: setSkinClass },
  } = useController<Page, `${typeof blockPath}.skin`>({ name: `${blockPath}.skin` });

  const selectedSkin = useMemo<Skin | null>(() => {
    return availableSkins.find((skin) => skin.class === skinClass) || null;
  }, [availableSkins, skinClass]);

  const handleSkinChanged = (skin: Skin | null) => {
    setSkinClass(skin?.class || '');
  };

  return <SkinSelector availableSkins={availableSkins} selectedSkin={selectedSkin} onSkinChange={handleSkinChanged} />;
};

export default PageBlockSkinSelector;
