import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SiteLocationField } from '@/views/SettingsView/SiteLocationsSettings/SiteLocationField';
import { useSettingsFormModel } from '@/components/SettingsViewFormDataProvider';
import SaveFormButton from '../../../components/SaveFormButton';
import { Location } from '../../../declarations/models/Location';

export interface SiteLocationsSettingsItemFormProps {
  onChange: (m: Location) => void;
  onDelete?: () => void;
  defaultCenter?: { lat: number; lng: number };
}
export const SiteLocationsSettingsItemForm: FC<SiteLocationsSettingsItemFormProps> = ({
  onChange,
  onDelete,
  defaultCenter,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { reset } = useFormContext();
  const value = useSettingsFormModel<Location>();

  const onLocationChanged = (location: Location) => {
    reset(location, {
      // will make sure the form state dirty flag is set
      keepDefaultValues: true,
    });
    // tell parent we changed, perhaps they need to rerender their map of markers.
    onChange(location);
  };

  const title = useWatch<Location, `title`>({
    name: `title`,
    defaultValue: '',
  });

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<MaterialSymbol name='expand_more' />}>
          {title || tComponents('Settings.SiteSettings.SiteLocationsSettings.missingTitle')}
        </AccordionSummary>
        <AccordionDetails>
          <SiteLocationField defaultCenter={defaultCenter} value={value} onChange={onLocationChanged}>
            <SaveFormButton />
            {onDelete && (
              <Button
                onClick={onDelete}
                color={value?.id ? 'error' : 'inherit'}
                startIcon={value?.id ? <MaterialSymbol fill name='delete' /> : null}>
                {value?.id ? tCommon('delete') : tCommon('cancel')}
              </Button>
            )}
          </SiteLocationField>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SiteLocationsSettingsItemForm;
