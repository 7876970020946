import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Styles from '@/assets/js/Styles';
import { LinkExternal, LinkTarget } from '../../declarations/models/LinkExternal';
import { LinkType } from '../../declarations/models/LinkType';

enum AddType {
  ADD_AND_NEW = 'add_and_new',
  ADD_AND_CLOSE = 'add_and_close',
}

export interface AddInternalExternalLinkModalProps {
  open: boolean;
  onClose: () => void;
  linkType: LinkType;
  onAddExternalLink: (externalLink: LinkExternal) => void;
}

const AddExternalLinkModal: FC<AddInternalExternalLinkModalProps> = ({
  open,
  onClose,
  linkType,
  onAddExternalLink,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const form = useRef<HTMLFormElement | null>(null);
  const [linkTitle, setLinkTitle] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [linkAnchor, setLinkAnchor] = useState('');
  const [linkTarget, setLinkTarget] = useState<LinkTarget>(LinkTarget.STANDARD);

  useEffect(() => {
    if (open) {
      setLinkTitle('');
      setLinkUrl('');
      setLinkTarget(LinkTarget.STANDARD);
    }
  }, [open]);

  const externalLink: LinkExternal = {
    title: linkTitle,
    type: linkType,
    url: linkUrl,
    anchor: linkAnchor,
    target: linkTarget,
  };

  const handleChangeLinkTitle = (title: string) => {
    setLinkTitle(title);
  };

  const handleChangeLinkUrl = (url: string) => {
    setLinkUrl(url);
  };

  const handleChangeLinkAnchor = (url: string) => {
    setLinkAnchor(url);
  };

  const handleChangeLinkTarget = (target: LinkTarget) => {
    setLinkTarget(target);
  };

  const resetFields = () => {
    setLinkTitle('');
    setLinkUrl('');
    setLinkAnchor('');
    setLinkTarget(LinkTarget.STANDARD);
  };
  const handleAddExternalLink = async (addType: AddType) => {
    const formValid = form.current?.reportValidity() ?? form.current?.checkValidity() ?? true;
    if (!formValid) {
      enqueueSnackbar(tComponents('AddExternalLinkModal.InvalidForm'), { variant: 'error' });
      return;
    }

    const addLink = () => {
      onAddExternalLink(externalLink);
      resetFields();
      enqueueSnackbar(tComponents('AddExternalLinkModal.LinkAdded'), { variant: 'success' });
    };

    if (addType === 'add_and_new') {
      addLink();
    } else if (addType === 'add_and_close') {
      addLink();
      onClose();
    } else {
      enqueueSnackbar(tComponents('AddExternalLinkModal.InvalidForm'), { variant: 'error' });
    }
  };

  const handleOnClose = () => {
    resetFields();
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleOnClose}>
        <DialogTitle>{tComponents('AddExternalLinkModal.AddLink')}</DialogTitle>
        <DialogContent>
          <form ref={form}>
            <TextField
              name='linkTitle'
              id='linkTitle'
              type='text'
              label={tComponents('AddExternalLinkModal.TitleOnUrl')}
              value={linkTitle}
              onChange={(e) => handleChangeLinkTitle(e.target.value || '')}
              required
              fullWidth
              autoComplete='off'
              margin='normal'
              color='secondary'
            />
            <TextField
              name='url'
              id='url'
              label={tComponents('AddExternalLinkModal.FullUrlOrId')}
              value={linkUrl}
              onChange={(e) => handleChangeLinkUrl(e.target.value || '')}
              fullWidth
              autoComplete='off'
              margin='normal'
              color='secondary'
            />
            <TextField
              name='anchor'
              id='anchor'
              type='text'
              label={tComponents('AddExternalLinkModal.Anchor')}
              value={linkAnchor}
              onChange={(e) => handleChangeLinkAnchor(e.target.value || '')}
              fullWidth
              autoComplete='off'
              margin='normal'
              color='secondary'
              InputProps={{
                startAdornment: linkAnchor ? <Typography color={Styles.Colors.MEDIUM_GREY}>#</Typography> : null,
              }}
            />
            <Select
              value={linkTarget || LinkTarget.STANDARD}
              onChange={(e) => handleChangeLinkTarget((e.target.value as LinkTarget) || LinkTarget.STANDARD)}>
              <MenuItem value={LinkTarget.STANDARD}>{tComponents('AddExternalLinkModal.Auto')}</MenuItem>
              <MenuItem value={LinkTarget.SAME_TAB}>{tComponents('AddExternalLinkModal.SameTab')}</MenuItem>
              <MenuItem value={LinkTarget.NEW_TAB}>{tComponents('AddExternalLinkModal.NewTab')}</MenuItem>
              <MenuItem value={LinkTarget.NEW_COMMON_TAB}>{tComponents('AddExternalLinkModal.NewCommonTab')}</MenuItem>
            </Select>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color='inherit' onClick={handleOnClose}>
            {tCommon('cancel')}
          </Button>
          <Button variant='contained' color='secondary' onClick={() => handleAddExternalLink(AddType.ADD_AND_NEW)}>
            {tComponents('AddExternalLinkModal.AddAndNew')}
          </Button>
          <Button variant='contained' color='secondary' onClick={() => handleAddExternalLink(AddType.ADD_AND_CLOSE)}>
            {tComponents('AddExternalLinkModal.AddAndClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExternalLinkModal;
