import React, { FC, useMemo } from 'react';
import { M24MediaModel } from '../../../../../declarations/models/M24MediaModel';
import {
  ExternalMediaModel,
  isVimeoMediaModel,
  isYouTubeMediaModel,
} from '../../../../../declarations/models/ExternalMediaModel';
import Image, { ImageProps } from '../../../../../components/Image';
import { Coordinate } from '../../../../../declarations/models/Coordinate';
import { getVimeoThumbnailURL, getYouTubeThumbnailURL } from '../../../../../utils/url';

export interface HeaderThumbnailProps extends Omit<ImageProps, 'src' | 'sx' | 'focusPoint' | 'ref'> {
  hidden?: boolean;
  item: M24MediaModel | ExternalMediaModel;
}

const widthTransitionDuration = 75;
const fadeTransitionDuration = 125;

export const HeaderThumbnail: FC<HeaderThumbnailProps> = ({ hidden = false, item, ...imageProps }) => {
  const { source, mimetype, focusPoint } = useMemo(() => {
    let src = (item as M24MediaModel)?.url || (item as ExternalMediaModel)?.local?.anySource || '';
    const mtype = (item as M24MediaModel)?.mimetype || (item as ExternalMediaModel)?.mimetype || '';

    if (isYouTubeMediaModel(item)) {
      src = getYouTubeThumbnailURL(item.local?.youtubeId) || '';
    } else if (isVimeoMediaModel(item)) {
      src = getVimeoThumbnailURL(item.local?.vimeoId) || '';
    }

    return {
      source: src,
      mimetype: mtype,
      focusPoint: {
        x: item.x || item.local?.x || '0',
        y: item.y || item.local?.y || '0',
      } as Coordinate,
    };
  }, [item]);

  let imgMimeType = '';
  if (item.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${item.mimetype}`;
  }
  return (
    <Image
      src={`${source}?dimension=250x250&${imgMimeType}`}
      sx={{
        objectFit: 'cover',
        overflow: 'hidden',
        width: hidden ? 0 : '100px',
        opacity: hidden ? 0 : 1,
        height: '75px',
        transition: `
                  width ${widthTransitionDuration}ms ease-in-out ${hidden ? fadeTransitionDuration : 0}ms,
                  opacity ${fadeTransitionDuration}ms ease ${hidden ? 0 : widthTransitionDuration}ms,
                  object-position 150ms ease-in-out
                `,
        flex: '1 0 auto',
      }}
      focusPoint={focusPoint}
      {...imageProps}
    />
  );
};

export default HeaderThumbnail;
