import React, { FC, ReactNode, useMemo } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { MediaViewType } from '../../../../declarations/models/blocks/MediaViewType';
import ActionMenu from '../../../../components/ActionMenu';
import Container from '../../../../components/Container';
import CustomIcon from '../../../../components/CustomIcon';

export interface MediaViewTypeSelectorProps {
  mediaViewType: MediaViewType;
  onChange: (type: MediaViewType) => void;
}

enum MediaViewTypeWidth {
  NARROW = 'narrow',
  WIDE = 'wide',
  FULL = 'full',
}

enum MediaViewTypeVariant {
  LIST = 'list',
  GRID = 'grid',
  GRID_PACKERY = 'grid__packery',
  SLIDESHOW = 'slideshow',
  COMPARE_TWO_IMAGES = 'compareTwoImages',
}

const allMediaViewTypes = Object.values(MediaViewType).map(String);
if (Object.values(MediaViewTypeVariant).some((v) => !allMediaViewTypes.includes(v))) {
  throw new Error('The enum MediaViewTypeVariant MUST be a subset of the enum MediaViewType');
}

const VARIANT_ICONS: Record<MediaViewTypeVariant, ReactNode> = {
  [MediaViewTypeVariant.LIST]: <MaterialSymbol name='panorama' fill />,
  [MediaViewTypeVariant.GRID]: <CustomIcon name='gallery' />,
  [MediaViewTypeVariant.GRID_PACKERY]: <CustomIcon name='gallery_auto' />,
  [MediaViewTypeVariant.SLIDESHOW]: <MaterialSymbol name='slideshow' fill />,
  [MediaViewTypeVariant.COMPARE_TWO_IMAGES]: <MaterialSymbol name='compare' fill />,
};

const WIDTH_ICONS: Record<MediaViewTypeWidth | 'null', ReactNode> = {
  null: <CustomIcon name='width_normal' />,
  [MediaViewTypeWidth.NARROW]: <CustomIcon name='width_normal' />,
  [MediaViewTypeWidth.WIDE]: <CustomIcon name='width_wider' />,
  [MediaViewTypeWidth.FULL]: <CustomIcon name='width_full' />,
};

const AVAILABLE_WIDTHS: Record<MediaViewTypeVariant, Array<null | MediaViewTypeWidth>> = {
  [MediaViewTypeVariant.LIST]: [null, MediaViewTypeWidth.NARROW, MediaViewTypeWidth.WIDE, MediaViewTypeWidth.FULL],
  [MediaViewTypeVariant.GRID]: [null, MediaViewTypeWidth.NARROW, MediaViewTypeWidth.WIDE, MediaViewTypeWidth.FULL],
  [MediaViewTypeVariant.GRID_PACKERY]: [
    null,
    MediaViewTypeWidth.NARROW,
    MediaViewTypeWidth.WIDE,
    MediaViewTypeWidth.FULL,
  ],
  [MediaViewTypeVariant.SLIDESHOW]: [null, MediaViewTypeWidth.NARROW, MediaViewTypeWidth.WIDE, MediaViewTypeWidth.FULL],
  [MediaViewTypeVariant.COMPARE_TWO_IMAGES]: [
    null,
    MediaViewTypeWidth.NARROW,
    MediaViewTypeWidth.WIDE,
    MediaViewTypeWidth.FULL,
  ],
};

export const MediaViewTypeSelector: FC<MediaViewTypeSelectorProps> = ({ mediaViewType, onChange }) => {
  const { t } = useTranslation('components');

  const currentWidth = useMemo<null | MediaViewTypeWidth>(() => {
    if (mediaViewType?.toString()?.endsWith(MediaViewTypeWidth.WIDE)) {
      return MediaViewTypeWidth.WIDE;
    }
    if (mediaViewType?.toString()?.endsWith(MediaViewTypeWidth.FULL)) {
      return MediaViewTypeWidth.FULL;
    }
    if (mediaViewType?.toString()?.endsWith(MediaViewTypeWidth.NARROW)) {
      return MediaViewTypeWidth.NARROW;
    }
    return null;
  }, [mediaViewType]);

  const currentVariant = useMemo<MediaViewTypeVariant>(() => {
    return Object.values(MediaViewTypeWidth).reduce((previousValue, width) => {
      return previousValue?.replace(`-${width}`, '');
    }, mediaViewType?.toString()) as unknown as MediaViewTypeVariant;
  }, [mediaViewType]);

  const handleChange = (newVariant: MediaViewTypeVariant, newWidth: MediaViewTypeWidth | null) => {
    if (newWidth !== null && AVAILABLE_WIDTHS[newVariant].includes(newWidth)) {
      onChange(`${newVariant}-${newWidth}` as MediaViewType);
    } else {
      onChange(newVariant as string as MediaViewType);
    }
  };

  const selectableWidths = AVAILABLE_WIDTHS[currentVariant] || [];

  return (
    <Container gap={0}>
      {selectableWidths.length > 1 && (
        <ActionMenu
          id='select-media-block-view-type-width'
          ariaLabel={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltip={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltipPlacement='top'
          icon={WIDTH_ICONS[currentWidth || 'null']}>
          {selectableWidths.map((width) => (
            <MenuItem
              key={width || 'none-option'}
              selected={width === currentWidth}
              disabled={width === currentWidth}
              onClick={() => handleChange(currentVariant, width)}>
              <ListItemIcon>{WIDTH_ICONS[width || 'null']}</ListItemIcon>
              <ListItemText>{t(`ViewTypeSelector.ViewTypeWidth.${width || 'none'}`)}</ListItemText>
            </MenuItem>
          ))}
        </ActionMenu>
      )}
      <ActionMenu
        id='select-media-block-view-type'
        ariaLabel={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltip={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltipPlacement='top'
        icon={VARIANT_ICONS[currentVariant]}>
        {(Object.values(MediaViewTypeVariant).map(String) as Array<MediaViewTypeVariant>).map((type) => (
          <MenuItem
            key={type}
            selected={type === currentVariant}
            disabled={type === currentVariant}
            onClick={() => handleChange(type, currentWidth)}>
            <ListItemIcon>{VARIANT_ICONS[type]}</ListItemIcon>
            <ListItemText>{t(`MediaViewTypeSelector.MediaViewTypeVariant.${type}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>
    </Container>
  );
};

export default MediaViewTypeSelector;
