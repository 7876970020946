import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function useErrorMessage(error?: FieldError, limits?: { min?: number; max?: number }): string | null {
  const { t } = useTranslation('common', { keyPrefix: 'validation' });

  if (!error) {
    return null;
  }

  if (error.message) {
    return error.message;
  }

  switch (error.type) {
    case 'required':
      return t('required');
    case 'min':
      return t('min', { min: limits?.min || 0 });
    case 'max':
      return t('max', { max: limits?.max || 0 });
    case 'maxLength':
      return t('maxLength');
    case 'minLength':
      return t('minLength');
    case 'pattern':
      return t('pattern');
    default:
      return t('genericError');
  }
}
