/* istanbul ignore file */

import { UserSelectableIconName } from '@/components/UserSelectableIcon';
import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { VerticalAlignment } from '../VerticalAlignment';
import { M24MediaModel } from '../M24MediaModel';
import { ExternalMediaModel } from '../ExternalMediaModel';
import { MediaResourceType } from '../MediaResourceType';
import { LinkExternal, M24MediaLinkItem } from '../LinkExternal';
import { LinkStyle } from '../LinkStyle';
import { Skin } from '../Skin';
import { Page } from '../Page';

export enum BannerSize {
  SMALL = 's',
  SMALL_MEDIUM = 's-m',
  MEDIUM_SMALL = 'm-s',
  MEDIUM = 'm',
  LARGE_MEDIUM = 'l-m',
  MEDIUM_LARGE = 'm-l',
  LARGE = 'l',
  XL = 'xl',
}

export enum BannerLayout {
  TEXT_ON_IMAGE = 'img',
  IMAGE_TOP = 'img-top',
  IMAGE_LEFT = 'img-left',
  IMAGE_RIGHT = 'img-right',
  VARIABLE = 'default',
  // SPECIAL = 'special',
  BOX = 'box',
  TEXTLINES = 'textlines',
}

export enum BannerTitleSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum BannerTitleWeight {
  DEFAULT = 'default',
  THIN = 'thin',
  NORMAL = 'normal',
  BOLD = 'bold',
}

export enum BannerTitleTransformation {
  NORMAL = 'normal',
  UPPERCASE = 'uppercase',
}

export enum BlockBackgroundPosition {
  CENTER = 'center',
  TOP = 'top',
}

export enum BannerTextLineColor {
  LIGHT_SEMI_TRANSPARENT = 'line-light',
  DARK_SEMI_TRANSPARENT = 'line-dark',
}

interface BannerBackgroundMediaSettingsModel {
  alpha__strength?: string;
  alpha_on?: boolean;
  gradient__size?: string;
  gradient__strength?: string;
  gradient_on?: boolean;
  vignette__size?: string;
  vignette__strength?: string;
  vignette_on?: boolean;
  skin_on?: boolean;
  skin?: Skin;
}
export type BannerBackgroundM24MediaModel = M24MediaModel & BannerBackgroundMediaSettingsModel;
export type BannerBackgroundExternalMediaModel = ExternalMediaModel & {
  url?: string;
  id?: string;
  title?: string;
} & BannerBackgroundMediaSettingsModel;

export interface BannerBlock extends BaseBlock {
  type: BlockType.BANNER | BlockType.FRONTPAGE;
  audio?: M24MediaModel & { type: MediaResourceType.AUDIO };
  backgroundPosition?: BlockBackgroundPosition;
  bodytext?: string;
  hidetitle?: boolean;
  icon?: UserSelectableIconName;
  insertTitle?: string;
  isTransparent?: boolean;
  items: Array<LinkExternal | M24MediaLinkItem | Page>;
  layout?: BannerLayout;
  linkstyle?: LinkStyle;
  mediaCredits?: string;
  mediaitems: Array<M24MediaModel | ExternalMediaModel>;
  mediaobject?: BannerBackgroundM24MediaModel | BannerBackgroundExternalMediaModel;
  size?: BannerSize;
  textValign?: VerticalAlignment;
  textlineColor?: BannerTextLineColor;
  titleSize?: BannerTitleSize;
  titleTransform?: BannerTitleTransformation;
  titleWeight?: BannerTitleWeight;
  useModuleContentBackgound: boolean; // Typo from old M24
  useTextbackgound: boolean; // Typo from old M24
  dmSearch?: boolean;
  dmSearchSettings?: {
    completeUrl?: string;
    labelPlaceholder?: string;
    labelButton?: string;
  };
  titleLevel?: string;
}
