import Styles from '@/assets/js/Styles';
import React, { FC } from 'react';
import { Status } from '@/declarations/models/Status';

const GrayCircle = ({ size }: { size: number }) => (
  <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 4}
      fill={Styles.Colors.MEDIUM_GREY}
      stroke={Styles.Colors.MEDIUM_DARK_GREY}
    />
  </svg>
);

const BlueCircle = ({ size }: { size: number }) => (
  <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg'>
    <circle
      cx={size / 2}
      cy={size / 2}
      r={size / 4}
      fill={Styles.Colors.MEDIUM_BLUE}
      stroke={Styles.Colors.MEDIUM_DARK_GREY}
    />
  </svg>
);

const GreenCircle = ({ size }: { size: number }) => (
  <svg width={size} height={size} xmlns='http://www.w3.org/2000/svg'>
    <circle cx={size / 2} cy={size / 2} r={size / 2} fill={Styles.Colors.STRONG_GREEN_TRANSPARENT} />
    <circle cx={size / 2} cy={size / 2} r={size / 4} fill={Styles.Colors.STRONG_GREEN} />
  </svg>
);

export const PageStatusCircle: FC<{ status?: Status; size?: number }> = ({ status, size = 24 }) => {
  if (status === Status.PUBLISHED) {
    return <GreenCircle size={size} />;
  }
  if (status === Status.ARCHIVED) {
    return <BlueCircle size={size} />;
  }
  return <GrayCircle size={size} />;
};
