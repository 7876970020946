import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import Container from '@/components/Container';
import { BlockPath, useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { FormBlockModel } from '@/declarations/models/blocks/FormBlockModel';
import { FormModule } from '@/declarations/models/FormModule';
import { Api } from '@/services/Api';
import Styles from '@/assets/js/Styles';
import { Status } from '@/declarations/models/Status';
import FinderButton, { FinderType } from '@/components/Finder/FinderButton';
import { GenericMedia } from '@/declarations/GenericMedia';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import { useTranslation } from 'react-i18next';

const FormBlock: React.FC = () => {
  const [form, setForm] = useState<FormModule | null>(null);
  const { blockPath } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');

  const {
    field: { onChange, value },
  } = useController<BlockSpecificPage<FormBlockModel>, `${BlockPath}.formobject.id`>({
    name: `${blockPath}.formobject.id`,
  });

  useEffect(() => {
    if (value) {
      const ctx = Api.getFormModule(value);

      ctx
        .fetchDirect(null)
        .then((response) => {
          setForm(response);
        })
        .catch((error) => {
          console.error(error);
          setForm(null);
        })
        .finally(ctx.abort);
    }
  }, [value]);

  return (
    <Container fullWidth mt={2} column>
      {form && (
        <Box
          display='flex'
          width='100%'
          bgcolor={Styles.Colors.LIGHT_GREY}
          px={2}
          py={1}
          alignItems='center'
          borderRadius={1}>
          <Box display='flex' gap={2} flexGrow={1} alignItems='center'>
            <MaterialSymbol
              name='circle'
              fill
              sx={{ fontSize: '16px' }}
              color={form.status === Status.PUBLISHED ? 'success' : 'disabled'}
            />
            <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>{form.title}</Typography>
          </Box>
          <DeleteButton
            onConfirm={() => {
              setForm(null);
              onChange(null);
            }}
            deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
            component='IconButton'
            tooltip={tCommon('remove')}
          />
        </Box>
      )}

      <FinderButton
        type={FinderType.FORM}
        finderProps={{
          onSelectionConfirmed: (selected: Array<GenericMedia<FormModule>>) => {
            onChange(selected[0].source.id);
          },
        }}
      />
    </Container>
  );
};

export default FormBlock;
