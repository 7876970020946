import React, { FC, useMemo, useRef } from 'react';
import { useEditorConfiguration } from '../../lib/configuration/EditorConfigurationContext';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import {
  EditorEventType,
  useEditorEventHandler,
  useEditorEventManager,
} from '../../lib/eventManager/EditorEventManager';
import { useCurrentSection } from '../CurrentSectionProvider';

/**
 * Responsible for rendering the correct preview block for the given BlockType
 * @constructor
 */
export const PreviewBlock: FC = () => {
  const config = useEditorConfiguration();
  const { block, blockType, blockPath } = useCurrentBlock();
  const { sectionType, sectionPath } = useCurrentSection();
  const container = useRef<HTMLDivElement | null>(null);
  const eventManager = useEditorEventManager();

  useEditorEventHandler(async (event) => {
    if (event.type === EditorEventType.SECTION_EXPANDED && event.payload.anchorId === blockPath) {
      container.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  const PreviewBlockComponent = useMemo<BlockPreviewComponent | null>(() => {
    return config.getBlockPreviewComponent(blockType);
  }, [blockType, config]);

  if (!PreviewBlockComponent) {
    return null;
  }

  const dataSize = 'size' in block ? block.size : '';
  const dataLayout = block.layout || '';

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,jsx-a11y/control-has-associated-label
    <div
      title={`BlockType.${blockType}`}
      data-module={blockType}
      data-size={dataSize}
      data-layout={dataLayout}
      className={`block block--${sectionType} txtalign--${block?.textAlign || 'auto'}`}
      ref={container}
      onClick={() => {
        // hack: Block-sections are mounted lazy
        // 1. make sure the parent section of this block is expanded, so the block anchor is available
        eventManager.fireEvent(EditorEventType.PREVIEW_BLOCK_CLICKED, { anchorId: sectionPath, scrollIntoView: false });
        setTimeout(() => {
          // 2. in setTimeout to let the block be rendered before attempting to expand + scroll
          eventManager.fireEvent(EditorEventType.PREVIEW_BLOCK_CLICKED, { anchorId: blockPath, scrollIntoView: true });
        });
      }}
      style={{ cursor: 'pointer' }}
      role='button'>
      <PreviewBlockComponent />
    </div>
  );
};

export default PreviewBlock;
