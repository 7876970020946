import { Box, Button, FormControl, FormControlLabel, Grow, Radio, RadioGroup, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import Styles from '@/assets/js/Styles';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import React, { FC, useState } from 'react';
import { EditState } from '@/views/MediaView/MediaView';
import { useTranslation } from 'react-i18next';
import UploadMediaModal from '@/views/MediaView/MediaUpload/UploadMediaModal';
import { Tag } from '@/declarations/models/Tag';
import { TagsAutocomplete } from '@/components/TagsAutocomplete';
import { TagType } from '@/declarations/models/TagType';

export interface MediaViewHeaderProps {
  siteId: number;
  tags: Array<Tag>;
  searchInput?: string;
  setSearchInput?: (value: string) => void;
  refreshPage: () => void;
  selectedMediaType: MediaResourceType | null;
  setSelectedMediaType: React.Dispatch<React.SetStateAction<MediaResourceType | null>>;
  editState: EditState;
  setEditState: React.Dispatch<React.SetStateAction<EditState>>;
  doBatchDelete: () => Promise<void>;
  someSelected: boolean;
  allSelected: boolean;
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
}

export const MediaViewHeader: FC<MediaViewHeaderProps> = ({
  searchInput,
  setSearchInput,
  siteId,
  tags,
  refreshPage,
  selectedMediaType,
  setSelectedMediaType,
  editState,
  setEditState,
  doBatchDelete,
  someSelected,
  allSelected,
  selectedTags,
  setSelectedTags,
}) => {
  const { t: tComponent } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [uploadModalOpen, setUploadMediaOpen] = useState<boolean>(false);

  const handleCloseUploadModal = () => {
    refreshPage();
    setUploadMediaOpen(false);
  };

  return (
    <>
      <UploadMediaModal
        open={uploadModalOpen}
        closeModal={handleCloseUploadModal}
        siteId={siteId}
        tags={tags}
        refreshPage={refreshPage}
      />
      <ToolbarHeader
        renderSearchField={({ query, onQueryChange }) => (
          <TagsAutocomplete
            tagType={TagType.TAG}
            siteId={siteId}
            query={query}
            onQueryChange={onQueryChange}
            tags={selectedTags}
            onTagsChange={setSelectedTags}
          />
        )}
        query={searchInput}
        onQueryChange={setSearchInput}
        heading={
          <Typography variant='h3' component='h1' fontSize={30}>
            {tComponent('MediaView.Title')}
          </Typography>
        }
        topRight={
          <Button
            onClick={() => setUploadMediaOpen(!uploadModalOpen)}
            variant='contained'
            color='success'
            startIcon={<MaterialSymbol name='upload' />}
            type='button'>
            {tComponent('MediaView.UploadMedia')}
          </Button>
        }
        bottomRight={
          <Box flexGrow={3} display='flex' justifyContent='space-between'>
            <Box>
              <FormControl component='fieldset' color='secondary' fullWidth>
                <RadioGroup
                  aria-labelledby='select-m24-media-type-label'
                  sx={{ display: 'flex', flexFlow: 'row wrap', gap: 2 }}
                  value={selectedMediaType ?? ''}
                  onChange={(e, v) => setSelectedMediaType((v as MediaResourceType) || null)}>
                  <Grow in>
                    <FormControlLabel
                      sx={{ margin: 0 }}
                      value=''
                      control={<Radio color='secondary' sx={{ padding: 0, paddingRight: 0.5 }} />}
                      label={tCommon('all')}
                    />
                  </Grow>
                  {[
                    MediaResourceType.IMAGE,
                    MediaResourceType.VIDEO,
                    MediaResourceType.AUDIO,
                    MediaResourceType.DOCUMENT,
                    MediaResourceType.ANIMATION,
                  ].map((mt, i) => (
                    <Grow key={mt} timeout={(i + 1) * 100} in>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        value={mt}
                        control={<Radio color='secondary' sx={{ padding: 0, paddingRight: 0.5 }} />}
                        label={tCommon(`MediaResourceType.${mt}`)}
                      />
                    </Grow>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box display='flex' gap={1}>
              <Button
                variant='outlined'
                sx={{
                  px: 3,
                  whiteSpace: 'nowrap',
                  minWidth: 'fit-content',
                  backgroundColor:
                    editState === EditState.GROUP_UPDATE ? Styles.Colors.BLACK : Styles.Colors.LIGHT_GREY,
                  '&:hover': {
                    backgroundColor:
                      editState === EditState.GROUP_UPDATE ? Styles.Colors.DARK_GREY : Styles.Colors.LIGHTEST_GREY,
                  },
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                }}
                color={editState === EditState.GROUP_UPDATE ? 'primary' : 'secondary'}
                onClick={() =>
                  setEditState((prevState) => {
                    if (prevState === EditState.GROUP_UPDATE) {
                      return EditState.SINGLE;
                    }
                    return EditState.GROUP_UPDATE;
                  })
                }>
                {tComponent('MediaView.EditState.group_update')}
              </Button>
              <Button
                variant='outlined'
                sx={{
                  px: 3,
                  whiteSpace: 'nowrap',
                  minWidth: 'fit-content',
                  backgroundColor:
                    editState === EditState.GROUP_DELETE ? Styles.Colors.BLACK : Styles.Colors.LIGHT_GREY,
                  '&:hover': {
                    backgroundColor:
                      editState === EditState.GROUP_DELETE ? Styles.Colors.DARK_GREY : Styles.Colors.LIGHTEST_GREY,
                  },
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                }}
                color={editState === EditState.GROUP_DELETE ? 'primary' : 'secondary'}
                onClick={() =>
                  setEditState((prevState) => {
                    if (prevState === EditState.GROUP_DELETE) {
                      return EditState.SINGLE;
                    }
                    return EditState.GROUP_DELETE;
                  })
                }>
                {tComponent('MediaView.EditState.group_delete')}
              </Button>
              {editState === EditState.GROUP_DELETE && (someSelected || allSelected) && (
                <Button
                  color='error'
                  onClick={doBatchDelete}
                  variant='contained'
                  startIcon={<MaterialSymbol name='delete' fill />}>
                  {tComponent('MediaView.Delete_selected')}
                </Button>
              )}
            </Box>
          </Box>
        }
        boxProps={{ alignSelf: 'stretch' }}
      />
    </>
  );
};
