import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../../components/Container';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { KPDataItem, KPOwnerBlockModel } from '../../../../declarations/models/blocks/KPBlock';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { KPOwner, KPProcessedFields } from '../../../../declarations/models/KP';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import KPBlockOwnerItem from './KPBlockOwnerItem';
import KPBlockItem from './KPBlockItem';
import { KPSchemaId } from '../../../../declarations/KPSchemaId';

export const KPOwnerBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  const {
    fields: owners,
    append: appendOwner,
    insert: insertOwner,
    move: moveOwner,
    remove: removeOwner,
  } = useControlledFieldArray<BlockSpecificPage<KPOwnerBlockModel>, `${BlockPath}.owners`, 'itemHash'>({
    name: `${blockPath}.owners`,
  });

  const handleAddOwner = (owner: KPOwner | null) => {
    if (owner?.id) appendOwner({ id: owner.id, size: 'L', listPresentations: false });
  };

  const {
    fields: presentations,
    append: appendPres,
    insert: insertPres,
    move: movePres,
    remove: removePres,
  } = useControlledFieldArray<BlockSpecificPage<KPOwnerBlockModel>, `${BlockPath}.presentations`, 'itemHash'>({
    name: `${blockPath}.presentations`,
  });

  const handleAddPres = (pres: KPProcessedFields | null) => {
    if (pres?.document_id) appendPres({ id: pres.document_id, size: 'M' });
  };

  return (
    <Container column fullWidth gap={2}>
      <Container column gap={2} fullWidth>
        <FinderButton
          type={FinderType.KP_OWNER}
          overrideText={t('KPBlock.KPOwnersLabel')}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<KPOwner>>) =>
              items.forEach((item) => handleAddOwner(item.source)),
          }}
        />
        <Container column gap={0} fullWidth my={-1}>
          {owners.map((o, i) => (
            <KPBlockOwnerItem
              key={o.id}
              index={i}
              onAdd={(item, index) => item && insertOwner(index, item)}
              onReorder={moveOwner}
              onRemove={removeOwner}
            />
          ))}
        </Container>
      </Container>
      <Container column gap={2} fullWidth>
        <FinderButton
          type={FinderType.KP}
          overrideText={t('KPBlock.KPPresentationsLabel')}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<KPProcessedFields>>) =>
              items.forEach((item) => handleAddPres(item.source)),
            schemaIds: [KPSchemaId.KP_PRESENTATION],
          }}
        />
        <Container column gap={0} fullWidth my={-1}>
          {presentations.map((o, i) => (
            <KPBlockItem
              key={o.id}
              index={i}
              onAdd={(item, index) => item && insertPres(index, item as KPDataItem)}
              onReorder={movePres}
              onRemove={removePres}
              isPres
              itemPathPrefix={`${blockPath}.presentations`}
            />
          ))}
        </Container>
      </Container>
    </Container>
  );
};

export default KPOwnerBlock;
