import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SimpleEditorHeader from '@/editor/lib/components/EditorHeader/SimpleEditorHeader';
import { Page } from '@/declarations/models/Page';
import Container from '../../components/Container';
import { useStore } from '../../components/store/Store';
import { Api } from '../../services/Api';
import Styles from '../../assets/js/Styles';
import { isDeepEqual } from '../../utils/object';
import { Employee, EmployeeLink } from '../../declarations/models/Employee';
import EditEmployeeTextFields from './EmployeeEditorFields/EditEmployeeTextFields';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import EditEmployeeImageField from './EmployeeEditorFields/EditEmployeeImageField';
import { Coordinate } from '../../declarations/models/Coordinate';
import EmployeeEditorPreview from './EmployeeEditorPreview';
import EditEmployeeContactFields from './EmployeeEditorFields/EditEmployeeContactFields';
import EditEmployeeLinksField from './EmployeeEditorFields/EditEmployeeLinksField';
import { EditEmployeePageField } from './EmployeeEditorFields/EditEmployeePageField';

export interface EmployeeEditorProps {
  employeeId?: number;
  onCloseEditor: () => void;
}

export const editorStyling = {
  maxHeight: '100%',
  overflowY: 'auto',
  padding: 2,
  borderRadius: 2,
  backgroundColor: Styles.Colors.FINDER_M24_PRIMARY_HEADER_BACKGROUND,
};

export type EmployeeTextField = 'first_name' | 'last_name' | 'position' | 'department' | 'description';
export type EmployeeContactField = 'email_primary' | 'email_secondary' | 'phone_primary' | 'phone_secondary';
type EmployeeField = EmployeeTextField | EmployeeContactField;

export const EmployeeEditor: FC<EmployeeEditorProps> = ({ employeeId, onCloseEditor }) => {
  const { state } = useStore();
  const { t: tComp } = useTranslation('components');
  const { enqueueSnackbar } = useSnackbar();
  const siteId = state.selectedSite?.id;
  const selectedSiteLanguage = state.selectedSiteLanguage || 'no';
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [savedEmployee, setSavedEmployee] = useState<Employee>();
  const [modifiedEmployee, setModifiedEmployee] = useState<Partial<Employee>>();

  const [employeeImage, setEmployeeImage] = useState<M24MediaModel>();

  useEffect(() => {
    if (employeeId && siteId) {
      const ctx = Api.getEmployee(siteId, employeeId);
      ctx
        .fetchDirect(null)
        .catch((e) => console.error(e))
        .then((res) => res && setSavedEmployee(res))
        .finally(ctx.abort);
    }
  }, [employeeId, siteId]);

  useEffect(() => {
    const employeeWithLocale = {
      ...savedEmployee,
      locale: savedEmployee?.locale || selectedSiteLanguage,
    };
    setModifiedEmployee(employeeWithLocale);
  }, [savedEmployee]);

  useEffect(() => {
    if (modifiedEmployee?.resource_id) {
      const ctx = Api.getM24MediaResourceSimple(modifiedEmployee?.resource_id);
      ctx
        .fetchDirect(null)
        .catch((e) => console.error(e))
        .then((res) => res && setEmployeeImage(res))
        .finally(ctx.abort);
    } else if (!modifiedEmployee?.resource_id) setEmployeeImage(undefined);
  }, [modifiedEmployee?.resource_id, siteId]);

  useEffect(() => setIsDirty(!isDeepEqual(savedEmployee, modifiedEmployee)), [modifiedEmployee, savedEmployee]);

  const handleSaveEmployee = async () => {
    if (siteId) {
      const empty_required = [];
      if (!modifiedEmployee?.first_name) empty_required.push('first_name');
      if (!modifiedEmployee?.last_name) empty_required.push('last_name');
      if (empty_required.length) {
        enqueueSnackbar(tComp('EmployeeEditor.RequiredFieldsMissing', { fields: empty_required.join(', ') }), {
          variant: 'error',
        });
      } else if (modifiedEmployee) {
        const id = savedEmployee?.id;
        const ctx = id
          ? Api.updateEmployee(siteId, id, modifiedEmployee)
          : Api.createEmployee(siteId, modifiedEmployee);
        await ctx
          .fetchDirect(null)
          .then((res) => {
            if (res) {
              setSavedEmployee(res);
              enqueueSnackbar(tComp('EmployeeEditor.SaveSuccess'), { variant: 'success' });
            }
          })
          .catch((e) =>
            enqueueSnackbar(tComp('EmployeeEditor.SaveFailed', { error: e.error || e }), { variant: 'error' }),
          );
      }
    } else enqueueSnackbar(tComp('EmployeeEditor.SaveFailed', { error: 'Site ID missing' }), { variant: 'error' });
  };

  const handleImageChange = (imageId?: number | null) => {
    setModifiedEmployee({
      ...modifiedEmployee,
      resource_id: imageId,
    });
  };

  const handleFocusPointChange = (focusPoint: Coordinate) => {
    setModifiedEmployee({
      ...modifiedEmployee,
      image_focus_point: focusPoint,
    });
  };

  const handleChangeStringValue = (attribute: EmployeeField, value: string) => {
    setModifiedEmployee({
      ...modifiedEmployee,
      [attribute]: value,
    });
  };

  const handleLinksChange = (links: Array<EmployeeLink>) => {
    setModifiedEmployee({
      ...modifiedEmployee,
      link_list: links,
    });
  };

  const handleChangePageLink = (page: Page | null) => {
    setModifiedEmployee({
      ...modifiedEmployee,
      linked_page_id: page?.id ?? null,
    });
  };

  return (
    <Container fullHeight fullWidth column gap={0}>
      <SimpleEditorHeader
        model={savedEmployee}
        title={savedEmployee ? `${savedEmployee.first_name} ${savedEmployee?.last_name}` : ''}
        onClose={onCloseEditor}
        onSave={handleSaveEmployee}
        isDirty={isDirty}
      />
      <Container fullWidth gap={2} sx={{ marginTop: '16px', height: 'calc(100% -  56px)' }}>
        <Container fullWidth fullHeight column top gap={2} sx={editorStyling}>
          <EditEmployeeImageField
            employee={modifiedEmployee}
            image={employeeImage}
            setFocusPoint={handleFocusPointChange}
            setImageId={handleImageChange}
          />
          <EditEmployeeTextFields employee={modifiedEmployee} onChange={handleChangeStringValue} />
          <EditEmployeeContactFields employee={modifiedEmployee} onChange={handleChangeStringValue} />
          <EditEmployeePageField employee={modifiedEmployee} onChange={handleChangePageLink} />
          <EditEmployeeLinksField links={modifiedEmployee?.link_list} onChangeLinks={handleLinksChange} />
        </Container>
        <EmployeeEditorPreview employee={modifiedEmployee} employeeImage={employeeImage} />
      </Container>
    </Container>
  );
};

export default EmployeeEditor;
