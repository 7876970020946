import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import Styles from '@/assets/js/Styles';
import SettingsInputContainer from '../../../components/SettingsInputContainer';
import Container from '../../../components/Container';
import { Site } from '../../../declarations/models/Site';
import FinderButton, { FinderType } from '../../../components/Finder/FinderButton';
import { prepareMediaItemAsM24MediaModel } from '../../../utils/MediaUtils';
import ImagePreview from '../../../components/ImagePreview';
import DeleteButton from '../../../components/DeleteButton';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';

export const WebpageIconsSettings: FC = () => {
  const { t } = useTranslation('components');

  const iconStyle: CSSProperties = {
    width: '100px',
    height: '100px',
  };

  const noIconStyle: CSSProperties = {
    ...iconStyle,
    backgroundColor: 'white',
    border: `1px dashed ${Styles.Colors.DARK_GREY}`,
    borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const {
    field: { value: favicon, onChange: onChangeFavicon },
  } = useController<Site, `content.settings.favicon`>({
    name: `content.settings.favicon`,
  });

  const {
    field: { value: touchicon, onChange: onChangeTouchicon },
  } = useController<Site, `content.settings.touchicon`>({
    name: `content.settings.touchicon`,
  });

  return (
    <>
      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageIconsSettings.favicon`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='caption'>
              {t(`Settings.WebpageSettings.WebpageIconsSettings.faviconCaption`)}
            </Typography>

            <Container gap={2} py={2}>
              {favicon && favicon.url !== undefined && (
                <>
                  <ImagePreview mediaObject={favicon} imgStyle={favicon ? iconStyle : noIconStyle} />
                  <Typography variant='caption'>test{favicon.title || favicon.local?.title}</Typography>
                </>
              )}
              {!favicon && (
                <div style={noIconStyle}>{t(`Settings.WebpageSettings.WebpageIconsSettings.noImageChosen`)}</div>
              )}
            </Container>

            <Container fullWidth left>
              <FinderButton
                type={FinderType.M24}
                finderProps={{
                  onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
                    items.forEach((item) => onChangeFavicon(prepareMediaItemAsM24MediaModel(item.source))),
                }}
                multiSelect={false}
                overrideText={t(`Settings.WebpageSettings.WebpageIconsSettings.addFileButtonLabel`)}
                hideIcon
                sx={{ height: '40px', width: '70%' }}
              />
              {favicon && (
                <DeleteButton onConfirm={() => onChangeFavicon(null)} deleteLabel='remove' iconColor='secondary' />
              )}
            </Container>
          </Container>
        </Container>
      </SettingsInputContainer>
      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageIconsSettings.touchicon`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='caption'>
              {t(`Settings.WebpageSettings.WebpageIconsSettings.touchiconCaption`)}
            </Typography>

            <Container gap={2} py={2}>
              {touchicon && touchicon.url !== undefined && (
                <>
                  <ImagePreview mediaObject={touchicon} imgStyle={touchicon ? iconStyle : noIconStyle} />
                  <Typography variant='caption'>test{touchicon.title || touchicon.local?.title}</Typography>
                </>
              )}
              {!touchicon && (
                <div style={noIconStyle}>{t(`Settings.WebpageSettings.WebpageIconsSettings.noImageChosen`)}</div>
              )}
            </Container>

            <Container fullWidth left>
              <FinderButton
                type={FinderType.M24}
                finderProps={{
                  onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
                    items.forEach((item) => onChangeTouchicon(prepareMediaItemAsM24MediaModel(item.source))),
                }}
                multiSelect={false}
                overrideText={t(`Settings.WebpageSettings.WebpageIconsSettings.addFileButtonLabel`)}
                hideIcon
                sx={{ height: '40px', width: '70%' }}
              />
              {touchicon && (
                <DeleteButton
                  onConfirm={() => onChangeTouchicon(undefined)}
                  deleteLabel='remove'
                  iconColor='secondary'
                />
              )}
            </Container>
          </Container>
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default WebpageIconsSettings;
