import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import { MediaViewType } from '../../../declarations/models/blocks/MediaViewType';
import CustomIcon from '../../../components/CustomIcon';
import Container from '../../../components/Container';
import { AttachmentsBlock } from '../../../declarations/models/blocks/AttachmentsBlock';

enum ViewTypeWidth {
  WIDE = 'wide',
}

enum ViewTypeVariant {
  LIST = 'list',
}

const allMediaViewTypes = Object.values(MediaViewType).map(String);
if (Object.values(ViewTypeVariant).some((v) => !allMediaViewTypes.includes(v))) {
  throw new Error('The enum MediaViewTypeVariant MUST be a subset of the enum MediaViewType');
}

const VARIANT_ICONS: Record<ViewTypeVariant, ReactNode> = {
  [ViewTypeVariant.LIST]: <MaterialSymbol name='list' />,
};

const WIDTH_ICONS: Record<ViewTypeWidth | 'null', ReactNode> = {
  null: <CustomIcon name='width_normal' />,
  [ViewTypeWidth.WIDE]: <CustomIcon name='width_wider' />,
};

const AVAILABLE_WIDTHS: Record<ViewTypeVariant, Array<null | ViewTypeWidth>> = {
  [ViewTypeVariant.LIST]: [null, ViewTypeWidth.WIDE],
};

export const PageBlockAttachmentsViewSetting: SettingsComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<AttachmentsBlock>, `${typeof blockPath}.view`>({
    name: `${blockPath}.view`,
  });

  const currentWidth = useMemo<null | ViewTypeWidth>(() => {
    if (value?.toString()?.endsWith(ViewTypeWidth.WIDE)) {
      return ViewTypeWidth.WIDE;
    }
    return null;
  }, [value]);

  const currentVariant = useMemo<ViewTypeVariant>(() => {
    return Object.values(ViewTypeWidth).reduce((previousValue, width) => {
      return previousValue?.replace(`-${width}`, '');
    }, value?.toString()) as unknown as ViewTypeVariant;
  }, [value]);

  const handleChange = (newVariant: ViewTypeVariant, newWidth: ViewTypeWidth | null) => {
    if (newWidth !== null && AVAILABLE_WIDTHS[newVariant].includes(newWidth)) {
      onChange(`${newVariant}-${newWidth}` as MediaViewType);
    } else {
      onChange(newVariant as string as MediaViewType);
    }
  };

  const selectableWidths = AVAILABLE_WIDTHS[currentVariant] || [];

  return (
    <Container gap={0}>
      {selectableWidths.length > 1 && (
        <ActionMenu
          id='select-attachment-block-view-type-width'
          ariaLabel={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltip={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltipPlacement='top'
          icon={WIDTH_ICONS[currentWidth || 'null']}>
          {selectableWidths.map((width) => (
            <MenuItem
              key={width || 'none-option'}
              selected={width === currentWidth}
              disabled={width === currentWidth}
              onClick={() => handleChange(currentVariant, width)}>
              <ListItemIcon>{WIDTH_ICONS[width || 'null']}</ListItemIcon>
              <ListItemText>{t(`PageBlockAttachmentViewSetting.ViewTypeWidth.${width || 'none'}`)}</ListItemText>
            </MenuItem>
          ))}
        </ActionMenu>
      )}

      <ActionMenu
        id='select-attachment-block-view-type'
        ariaLabel={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltip={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltipPlacement='top'
        icon={VARIANT_ICONS[currentVariant]}>
        {(Object.values(ViewTypeVariant).map(String) as Array<ViewTypeVariant>).map((type) => (
          <MenuItem
            key={type}
            selected={type === currentVariant}
            disabled={type === currentVariant}
            onClick={() => handleChange(type, currentWidth)}>
            <ListItemIcon>{VARIANT_ICONS[type]}</ListItemIcon>
            <ListItemText>{t(`PageBlockAttachmentViewSetting.ViewTypeVariant.${type}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>
    </Container>
  );
};

export default PageBlockAttachmentsViewSetting;
