import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Layout from '../../Layout';
import MediaList from '../../MediaList/MediaList';
import { ViewType } from '../../../declarations/ViewType';
import FinderLayout from '../FinderLayout';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { toggleItem } from '../../../utils/array';
import { Api } from '../../../services/Api';
import { MediaLicense } from '../../../declarations/models/MediaLicense';
import SelectedKPObjectItem from './SelectedKPObjectItem';
import KPObjectFinderHeader from './KPObjectFinderHeader';
import { KPProcessedFields } from '../../../declarations/models/KP';
import { resolveLocalizedString } from '../../../utils/strings';
import { useStore } from '../../store/Store';

export interface KPObjectFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<KPProcessedFields>>) => void;
  multiSelect?: boolean;
  schemaIds?: Array<number>;
  referencedFromId?: number;
}

export const KPObjectFinder: FC<KPObjectFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  multiSelect = true,
  schemaIds,
  referencedFromId,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();

  const [page, setPage] = useState<{ pageNumber: number; pageSize: number }>({ pageNumber: 1, pageSize: 20 });
  const resetPage = () => {
    setPage((prevPage) => ({ ...prevPage, pageNumber: 1 }));
  };
  const kpOwnerId = Number(state.selectedSite?.content?.settings?.kulturpunktOwnerID_v3);
  const [filterByOwner, _setFilterByOwner] = useState<boolean>(!!kpOwnerId);
  const setFilterByOwner: typeof _setFilterByOwner = (filter) => {
    resetPage();
    _setFilterByOwner(filter);
  };
  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<KPProcessedFields>>>([]);
  const [items, setItems] = useState<Array<GenericMedia<KPProcessedFields>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryString, _setQueryString] = useState<string>('');
  const setQueryString: typeof _setQueryString = (query) => {
    resetPage();
    _setQueryString(query);
  };

  const resetState = () => {
    setSelectedItems([]);
    setQueryString('');
    setFilterByOwner(!!kpOwnerId);
    setPage({ pageNumber: 1, pageSize: 20 });
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  const isSelected = (item: GenericMedia<KPProcessedFields>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<KPProcessedFields>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: KPObjectFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) return;

    let unmounted = false;
    setIsLoading(true);

    if (referencedFromId) {
      const ctx = Api.getKPObjectsFromParentId(referencedFromId);
      ctx
        .fetchDirect(null)
        .then((objects) => {
          if (!unmounted) {
            setTotalCount(objects?.length ?? 0);
            setItems(
              (objects || []).map(
                (item) =>
                  ({
                    id: String(item.document_id),
                    title: item.internal_title || resolveLocalizedString(i18next.language, item.title),
                    url: item.image_src,
                    license: MediaLicense.NONE,
                    description: item.schema_id ? tCommon(`KulturioSchemaTypes.${item.schema_id}`) : undefined,
                    source: item,
                  } as GenericMedia<KPProcessedFields>),
              ),
            );
          }
        })
        .finally(() => {
          ctx.abort();
          if (!unmounted) setIsLoading(false);
        });
    } else {
      const ctx = Api.getKPObjects({
        start: page.pageNumber - 1,
        rows: page.pageSize,
        schema_ids: schemaIds,
        owner_id: filterByOwner ? kpOwnerId : undefined,
        query: queryString,
      });
      ctx
        .fetchDirect(null)
        .then((loadedPage) => {
          if (!unmounted) {
            setTotalCount(loadedPage?.total_count ?? 0);
            setItems(
              (loadedPage?.items || []).map(
                (item) =>
                  ({
                    id: String(item.document_id),
                    title: item.internal_title || resolveLocalizedString(i18next.language, item.title),
                    url: item.image_src,
                    license: MediaLicense.NONE,
                    description: tCommon(`KulturioSchemaTypes.${item.schema_id}`),
                    source: item,
                  } as GenericMedia<KPProcessedFields>),
              ),
            );
          }
        })
        .finally(() => {
          ctx.abort();
          if (!unmounted) setIsLoading(false);
        });
    }
    return () => {
      unmounted = true;
    };
  }, [queryString, page, open, schemaIds, tCommon, filterByOwner, kpOwnerId, referencedFromId]);

  return (
    <FinderLayout<KPProcessedFields>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => <SelectedKPObjectItem item={item} onRemoveItem={handleSelectionChanged} />}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout
        headerContent={
          <KPObjectFinderHeader
            referencedFromId={referencedFromId}
            onQueryChanged={setQueryString}
            schemaIds={schemaIds}
            filterByOwner={filterByOwner}
            setFilterByOwner={setFilterByOwner}
            kpOwnerId={kpOwnerId}
          />
        }>
        <MediaList
          isLoading={isLoading}
          items={items}
          page={!referencedFromId ? page : { pageNumber: 1, pageSize: totalCount }}
          totalItemCount={totalCount}
          onPageChange={(pageNumber, _, pageSize) => handlePageChanged({ pageNumber, pageSize })}
          onPageSizeChange={(pageSize) => handlePageChanged({ pageSize })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
          disablePagination={!!referencedFromId}
        />
      </Layout>
    </FinderLayout>
  );
};

export default KPObjectFinder;
