import React, { Fragment, ReactNode, useMemo, useRef, useState } from 'react';
import { Button, ButtonProps, Divider, Grow, Typography, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { mergeSx } from '@/utils/mergeSx';
import Container from './Container';

export interface ButtonCollectionProps<T> extends Pick<ButtonProps, 'color' | 'variant'> {
  title?: string;
  buttons: Array<T>;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, button: T) => void;
  getButtonKey: (button: T) => string;
  getButtonLabel: (button: T) => string;
  getButtonIcon?: (button: T) => ReactNode;
  getButtonColor?: (button: T) => string | undefined;
  getIsDisabled?: (button: T) => boolean;
  getIsHideable?: (button: T) => boolean;
  animationDuration?: number;
  reverseAnimation?: boolean;
  showTitleLine?: boolean;
  left?: boolean;
  children?: ReactNode;
}

export const addButtonSx = {
  gap: '6px',
  backgroundColor: Styles.Colors.LIGHTEST_GREY,
  fontSize: '0.65rem',
  flexDirection: 'column',
  color: Styles.Colors.DARK_GREY,
  borderRadius: Styles.Dimensions.RADIUS_NONE,
  borderColor: Styles.Colors.LIGHTEST_GREY,
  lineHeight: '1.15',
  padding: '2px',
  width: '100px',
  height: '85px',
};

export function ButtonCollection<T>({
  title = '',
  buttons = [],
  onClick,
  getButtonKey,
  getButtonLabel,
  getButtonIcon,
  getButtonColor,
  getIsDisabled,
  getIsHideable,
  animationDuration = 1000,
  reverseAnimation = false,
  variant = 'outlined',
  color = 'secondary',
  showTitleLine = true,
  left = false,
  children,
}: ButtonCollectionProps<T>) {
  const getIsHideableRef = useRef(getIsHideable);
  getIsHideableRef.current = getIsHideable;

  const { t } = useTranslation('components');
  const [showHidden, setShowHidden] = useState<boolean>(false);

  const visibleButtons = useMemo<Array<T>>(() => {
    return !getIsHideableRef.current ? buttons : buttons.filter((b) => !getIsHideableRef.current?.(b));
  }, [buttons]);

  const hiddenButtons = useMemo<Array<T>>(() => {
    return !getIsHideableRef.current ? [] : buttons.filter(getIsHideableRef.current);
  }, [buttons]);

  const renderButton = (button: T) => {
    const buttonLabel = getButtonLabel(button);
    const btnColor = getButtonColor?.(button) || color;
    return (
      <Button
        variant={variant}
        color={color}
        onClick={(event) => onClick(event, button)}
        disabled={getIsDisabled?.(button)}
        aria-label={`${title} ${buttonLabel}`}
        sx={mergeSx(addButtonSx, {
          backgroundColor: btnColor,
          '&:hover': { backgroundColor: btnColor },
        })}>
        {getButtonIcon?.(button)}
        {buttonLabel}
      </Button>
    );
  };

  return (
    <Container column fullWidth left={left}>
      {showTitleLine && (
        <Divider sx={{ mt: 3, mb: 1, width: '100%' }}>
          <Typography id='button-group-title' variant='overline' lineHeight='1rem'>
            {title}
          </Typography>
        </Divider>
      )}

      <Container role='group' aria-labelledby='button-group-title' left wrap fullWidth>
        {visibleButtons.map((b) => {
          return <Fragment key={getButtonKey(b)}>{renderButton(b)}</Fragment>;
        })}
        {hiddenButtons.map((b, i) => {
          return (
            <Grow
              key={getButtonKey(b)}
              in={showHidden}
              timeout={{
                enter: (reverseAnimation ? buttons.length : 1) * i * (animationDuration / buttons.length),
                exit: 250,
              }}
              mountOnEnter
              unmountOnExit>
              {renderButton(b)}
            </Grow>
          );
        })}
        {!!hiddenButtons?.length && (
          <Button color='inherit' variant='text' onClick={() => setShowHidden((o) => !o)} sx={addButtonSx}>
            {t(`ButtonCollection.${showHidden ? 'ShowLess' : 'ShowMore'}`)}
          </Button>
        )}
        {children}
      </Container>
    </Container>
  );
}

export default ButtonCollection;
