import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { SkinSelectorIcon } from '@/editor/lib/components/settings/SkinSelectorIcon';
import Container from '@/components/Container';
import React, { FC } from 'react';
import { Skin } from '@/declarations/models/Skin';
import { useTranslation } from 'react-i18next';

export interface SkinRadioSelectProps {
  label?: string;
  availableSkins?: Array<Skin>;
  selectedSkin?: Skin | null;
  onSkinChange: (skin: Skin | null) => void;
}

export const SkinSelectGroup: FC<SkinRadioSelectProps> = ({ label, availableSkins, selectedSkin, onSkinChange }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tAria } = useTranslation('aria');
  return (
    <Container column fullWidth left>
      <Typography fontWeight='bold'>{label ?? tComponents('CardEditor.SelectSkin')}</Typography>
      <ToggleButtonGroup
        value={selectedSkin?.class || null}
        exclusive
        sx={{
          flexWrap: 'wrap',
        }}
        onChange={(_e, value) => onSkinChange(value)}
        aria-label={tAria('components.CardEditor.Skin')}>
        {availableSkins?.map((skin) => (
          <Tooltip key={`${skin.class}-${skin.scope}-${skin.title}`} title={skin.title}>
            <ToggleButton
              sx={{
                textTransform: 'none',
              }}
              value={skin}
              aria-label={skin.title}
              style={{ backgroundColor: selectedSkin?.class === skin.class ? '#ccc' : '#fff' }}
              selected={selectedSkin?.class === skin.class}>
              <SkinSelectorIcon skin={skin} />
            </ToggleButton>
          </Tooltip>
        ))}
      </ToggleButtonGroup>
    </Container>
  );
};
