import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ChildrenProp } from '../declarations/ChildrenProp';
import Container from './Container';
import SaveFormButton from './SaveFormButton';

export interface SettingsViewHeaderProps extends ChildrenProp {
  title: string;
  saveButtonInHeader?: boolean;
}

export const SettingsViewHeader: FC<SettingsViewHeaderProps> = ({ title, saveButtonInHeader }) => {
  return (
    <Container fullWidth spaceBetween>
      <Typography my={2} variant='h1' fontSize='xx-large'>
        {title}
      </Typography>
      {saveButtonInHeader && <SaveFormButton />}
    </Container>
  );
};

export default SettingsViewHeader;
