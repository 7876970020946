import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Collapse, Typography } from '@mui/material';
import TitleAlignmentSelector from '@/editor/PageEditor/EditorBlock/CommonTitleAlignmentSelector';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import { Page } from '../../../declarations/models/Page';
import SwitchInput from '../../../components/forms/SwitchInput';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const CommonBlockContent: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Container column left fullWidth gap={0}>
      <Button
        variant='text'
        startIcon={
          <MaterialSymbol
            name='chevron_right'
            sx={{
              transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 100ms ease-in-out',
            }}
          />
        }
        onClick={() => setOpen((o) => !o)}>
        <Typography
          sx={{
            typography: 'subtitle1',
            fontWeight: 'bold',
            '&, &:hover': {
              textDecoration: 'underline',
            },
          }}>
          {`${open ? `${tCommon('close')} : ` : ''}${tComponents('CommonBlockContent.BlockSettings')}`}
        </Typography>
      </Button>

      <Collapse in={open} sx={{ width: '100%' }}>
        <Container p={2} mt={2} gap={2} column top left fullWidth>
          <Container spaceBetween fullWidth>
            <TextInput<Page>
              path={`${blockPath}.title`}
              label={tCommon('title')}
              defaultValue=''
              fullWidth
              InputProps={{
                endAdornment: <TitleAlignmentSelector blockPath={blockPath} />,
              }}
            />
            <Box flexShrink={1}>
              <SwitchInput<Page> path={`${blockPath}.visibletitle`} label={tCommon('showTitle')} />
            </Box>
          </Container>

          <TextInput<Page>
            path={`${blockPath}.body`}
            label={tComponents('CommonBlockContent.FormLabels.Body')}
            defaultValue=''
            multiline
          />

          <TextInput<Page>
            path={`${blockPath}.tocId`}
            label={tComponents('CommonBlockContent.FormLabels.AnchorID')}
            defaultValue=''
          />
        </Container>
      </Collapse>
    </Container>
  );
};

export default CommonBlockContent;
