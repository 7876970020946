import * as buffer from 'buffer';

export function isJWTExpired(jwt: string): boolean {
  if (!jwt || !/^[\w-]+\.[\w-]+\.[\w-]+$/.test(jwt)) {
    throw new Error('Not a JWT token');
  }
  const [, payload] = jwt
    .split('.')
    .slice(0, 2)
    .map((part) => buffer.Buffer.from(part, 'base64').toString())
    .map((part) => JSON.parse(part));
  if (!Number.isNaN(Number(payload?.exp))) {
    // payload.exp is in seconds. Convert to MS
    return new Date(Number(payload.exp) * 1000).getTime() <= Date.now();
  }
  return false;
}
