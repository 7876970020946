import React, { FC, useMemo } from 'react';
import { EditorContext } from '../declarations/EditorContext';
import { EditorConfiguration } from './EditorConfiguration';
import { EditorConfigurationFactory } from '../factories/EditorConfigurationFactory';
import { ChildrenProp } from '../../../declarations/ChildrenProp';
import { Configuration } from './EditorConfigurationContext';

export interface EditorConfigurationProviderProps extends ChildrenProp {
  context: EditorContext;
}

/**
 * Provides the relevant configuration for the current EditorContext
 * @param children
 * @param context
 * @constructor
 */
export const EditorConfigurationProvider: FC<EditorConfigurationProviderProps> = ({ children, context }) => {
  const editorConfiguration = useMemo<EditorConfiguration>(() => {
    return EditorConfigurationFactory.makeConfiguration(context);
  }, [context]);

  return <Configuration.Provider value={editorConfiguration}>{children}</Configuration.Provider>;
};

export default EditorConfigurationProvider;
