import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { QuoteBlock as QuoteBlockModel } from '../../../declarations/models/blocks/QuoteBlock';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const QuoteBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  return (
    <Container gap={2} column top left fullWidth>
      <TextInput<BlockSpecificPage<QuoteBlockModel>>
        path={`${blockPath}.quote`}
        label={t('QuoteBlock.quote')}
        multiline
        defaultValue=''
      />
      <TextInput<BlockSpecificPage<QuoteBlockModel>>
        path={`${blockPath}.byline`}
        label={t('QuoteBlock.byline')}
        defaultValue=''
      />
    </Container>
  );
};

export default QuoteBlock;
