import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockLayout } from '../../../declarations/models/BlockLayout';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import CustomIcon from '../../../components/CustomIcon';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';

const LAYOUT_OPTIONS: Array<BlockLayout> = Object.values(BlockLayout);
const LAYOUT_OPTIONS_ICONS: Record<BlockLayout, ReactNode> = {
  [BlockLayout.AUTO]: <MaterialSymbol name='horizontal_split' sx={{ transform: 'scaleY(-1)' }} />,
  [BlockLayout.WIDE]: <CustomIcon name='width_wider' />,
  [BlockLayout.IMAGE_LEFT]: <MaterialSymbol name='vertical_split' sx={{ transform: 'scaleX(-1)' }} />,
  [BlockLayout.IMAGE_RIGHT]: <MaterialSymbol name='vertical_split' />,
};

export const PageBlockSingleLayoutSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BaseBlock & { layout?: BlockLayout }>, `${typeof blockPath}.layout`>({
    name: `${blockPath}.layout`,
  });

  useEffect(() => {
    if (value && !Object.values(BlockLayout).includes(value as BlockLayout)) {
      onChange(BlockLayout.AUTO);
    }
  });

  return (
    <ActionMenu
      id='page-block-layout-selector'
      ariaLabel={tAria('components.PageBlockLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockLayoutSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? LAYOUT_OPTIONS_ICONS[value as BlockLayout] : LAYOUT_OPTIONS_ICONS.auto}>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          disabled={value === layoutOption}
          aria-label={tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{LAYOUT_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockSingleLayoutSetting;
