import { useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

export enum ScreenSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

const allSizesInDescOrder: Array<ScreenSize> = [
  ScreenSize.XL,
  ScreenSize.LG,
  ScreenSize.MD,
  ScreenSize.SM,
  ScreenSize.XS,
];

export function useScreenSize() {
  const theme = useTheme();

  const [currentScreenSizePx, setCurrentScreenSizePx] = useState<number>(window.innerWidth);

  const currentScreenSize = useMemo<ScreenSize>(() => {
    return allSizesInDescOrder.find((dt) => currentScreenSizePx > theme.breakpoints.values[dt]) || ScreenSize.LG;
  }, [currentScreenSizePx, theme]);

  const currentBreakpointPx = useMemo<number>(() => {
    return theme.breakpoints.values[currentScreenSize];
  }, [currentScreenSize, theme]);

  const isScreenLargerThan = useCallback(
    (screenSize: ScreenSize) => {
      return currentBreakpointPx >= theme.breakpoints.values[screenSize];
    },
    [currentBreakpointPx, theme],
  );

  const isScreenSmallerThan = useCallback(
    (screenSize: ScreenSize) => {
      return currentBreakpointPx < theme.breakpoints.values[screenSize];
    },
    [currentBreakpointPx, theme],
  );

  const valueByScreenSize = useCallback(
    <T>(values: { [screenSize in ScreenSize]: T }): T => {
      return values[currentScreenSize];
    },
    [currentScreenSize],
  );

  useEffect(() => {
    const resizeHandler = () => {
      setCurrentScreenSizePx(window.innerWidth);
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [theme]);

  return useMemo(
    () =>
      ({
        currentScreenSize,
        isScreenLargerThan,
        isScreenSmallerThan,
        valueByScreenSize,
      } as const),
    [currentScreenSize, isScreenLargerThan, isScreenSmallerThan, valueByScreenSize],
  );
}
