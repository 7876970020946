/* istanbul ignore file */
export enum Draggable {
  SECTION = 'section',
  BLOCK = 'block',
  ITEM = 'item',
  MEDIA_BLOCK_ITEM = 'mediaBlockItem',
  ATTACHMENTS_BLOCK_ITEM = 'attachmentsBlockItem',
  CARD_BLOCK_ITEM = 'cardBlockItem',
  PAGE_LIST_ITEM = 'pageListItem',
  EMPLOYEE = 'employee',
  DM_ITEM = 'dm_item',
  KP_ITEM = 'kp_item',
  KP_PRES_ITEM = 'kp_pres_item',
  OTHER = 'other',
}
