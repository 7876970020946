import React, { FC } from 'react';
import { Typography } from '@mui/material';
import Container from '../Container';
import { LinkExternal, M24MediaLinkItem } from '../../declarations/models/LinkExternal';
import { Page } from '../../declarations/models/Page';
import { isM24MediaModel } from '../../utils/typeChecks';
import { ChildrenProp } from '../../declarations/ChildrenProp';

export interface LinkListItemContentProps<T> extends ChildrenProp {
  item: T;
}

export const LinkListItemContent: FC<LinkListItemContentProps<LinkExternal | M24MediaLinkItem | Page>> = ({
  children,
  item,
}) => {
  const fileName = isM24MediaModel(item) ? item.filename : '';
  const title = isM24MediaModel(item) && item?.local?.title ? item?.local?.title : item?.title || '';

  return (
    <Container p={2} fullWidth>
      <Container gap={2} top spaceBetween fullWidth>
        <Container fullWidth column left>
          <Typography sx={{ fontWeight: 'medium' }}>{item?.title || ''}</Typography>
          {(fileName || item?.url) && <Typography>{fileName || item?.url}</Typography>}
          <Container fullWidth>{children}</Container>
        </Container>
      </Container>
    </Container>
  );
};

export default LinkListItemContent;
