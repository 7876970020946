import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import SettingsViewLayout from '../../components/SettingsViewLayout';
import Container from '../../components/Container';
import { EventCategory, ExhibitionCategory, InformationCategory, KnowledgeCategory } from '../../declarations/Category';
import CategoriesInfo from '../../components/CategoriesInfo';

export const SiteCategoriesSettings: FC = () => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  return (
    <SettingsViewLayout
      title={tComp('MainMenu.settings.categories')}
      saveButtonInHeader={false}
      saveButtonInTop={false}>
      <CategoriesInfo />
      <Container fullWidth top mt={2}>
        <Container fullWidth column>
          <Typography fontWeight={700}>{tCommon('MainCategory.event')}</Typography>
          {Object.values(EventCategory).map((c) => (
            <Typography key={c}>{tCommon(`SubCategory.${c}`)}</Typography>
          ))}
        </Container>
        <Container fullWidth column>
          <Typography fontWeight={700}>{tCommon('MainCategory.exhibition')}</Typography>
          {Object.values(ExhibitionCategory).map((c) => (
            <Typography key={c}>{tCommon(`SubCategory.${c}`)}</Typography>
          ))}
        </Container>
        <Container fullWidth column>
          <Typography fontWeight={700}>{tCommon('MainCategory.information')}</Typography>
          {Object.values(InformationCategory).map((c) => (
            <Typography key={c}>{tCommon(`SubCategory.${c}`)}</Typography>
          ))}
        </Container>
        <Container fullWidth column>
          <Typography fontWeight={700}>{tCommon('MainCategory.knowledge')}</Typography>
          {Object.values(KnowledgeCategory).map((c) => (
            <Typography key={c}>{tCommon(`SubCategory.${c}`)}</Typography>
          ))}
        </Container>
        <Container fullWidth column>
          <Typography fontWeight={700}>{tCommon('MainCategory.')}</Typography>
        </Container>
      </Container>
    </SettingsViewLayout>
  );
};

export default SiteCategoriesSettings;
