import React, { FC, useEffect, useState } from 'react';
import { Alert, Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CollapseContainer from '@/components/CollapseContainer';
import { useStore } from '../../../components/store/Store';
import { Api } from '../../../services/Api';
import { SharingContentType, SharingGrantedModel } from '../../../declarations/models/SiteSharingModel';
import Container from '../../../components/Container';
import { linkStyle } from '../../MediaEditor/MediaEditorPreviewUsageLinks';

interface SiteSharingBlockSettingsProps {
  siteIds?: string;
  setSiteIds?: (v: string) => void;
}

export const SiteSharingBlockSettings: FC<SiteSharingBlockSettingsProps> = ({ siteIds, setSiteIds }) => {
  const { t: tComp } = useTranslation('components');
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const [availableSites, setAvailableSites] = useState<Array<SharingGrantedModel>>([]);
  const [selectedSiteIds, setSelectedSiteIds] = useState<Array<number>>([]);

  useEffect(() => {
    if (siteIds) {
      const idList =
        siteIds
          .split(',')
          .map((id: string) => Number(id))
          .filter((id: number) => !!id) || [];
      setSelectedSiteIds(idList);
    } else if (siteId) {
      setSiteIds?.(String(siteId));
      setSelectedSiteIds([siteId]);
    }
  }, []);

  useEffect(() => {
    if (siteId) {
      const ctx = Api.getSiteSharing(siteId, { approved: true });
      ctx
        .fetchDirect(null)
        .then((res) => {
          const validSites = res?.granted?.filter((site) => site.content_type === SharingContentType.CONTENT);
          if (validSites?.length) setAvailableSites(validSites);
        })
        .then(ctx.abort);
    }
  }, [siteId]);

  const handleCheckSite = (site_id?: number) => {
    if (site_id) {
      const listIndex = selectedSiteIds.indexOf(site_id);
      let tempArr: number[];
      if (listIndex < 0) tempArr = [...selectedSiteIds, site_id];
      else tempArr = [...selectedSiteIds.slice(0, listIndex), ...selectedSiteIds.slice(listIndex + 1)];
      setSelectedSiteIds(tempArr);
      setSiteIds?.(tempArr.join(','));
    }
  };

  const handleCheckAll = () => {
    if (selectedSiteIds.length > 0) {
      setSelectedSiteIds([]);
      setSiteIds?.('');
    } else {
      const tempArr = availableSites.map((site) => site.has_access_to_site_id).filter((id) => !!id) as number[];
      if (siteId) tempArr.push(siteId);
      setSelectedSiteIds(tempArr);
      setSiteIds?.(tempArr.join(','));
    }
  };

  const allChecked = selectedSiteIds.length === availableSites.length + 1;
  const someChecked = selectedSiteIds.length > 0 && selectedSiteIds.length <= availableSites.length;

  return (
    <CollapseContainer title={`${tComp('SiteSharingBlockSettings.SelectSites')} (${selectedSiteIds.length})`}>
      <FormControl fullWidth>
        <Container fullWidth left>
          {!!availableSites.length && (
            <FormControlLabel
              control={<Checkbox onChange={handleCheckAll} checked={allChecked} indeterminate={someChecked} />}
              label={tComp(
                allChecked || someChecked
                  ? 'SiteSharingBlockSettings.UnselectAll'
                  : 'SiteSharingBlockSettings.SelectAll',
              )}
            />
          )}
        </Container>
        <FormGroup row>
          <FormControlLabel
            key={siteId}
            label={state.selectedSite?.name}
            control={
              <Checkbox
                checked={selectedSiteIds.some((id) => id === siteId)}
                onChange={() => handleCheckSite(siteId)}
              />
            }
          />
          {availableSites.map((site) => (
            <FormControlLabel
              key={site.has_access_to_site_id}
              label={site.site_granted}
              control={
                <Checkbox
                  checked={selectedSiteIds.some((id) => id === site.has_access_to_site_id)}
                  onChange={() => handleCheckSite(site.has_access_to_site_id)}
                />
              }
            />
          ))}
        </FormGroup>
        <Alert severity='info'>
          <Link target='_blank' to='/settings/sharing' style={linkStyle}>
            {tComp('SiteSharingBlockSettings.MuseumSharingInfo')}
            <MaterialSymbol name='open_in_new' sx={{ fontSize: 'small' }} />
          </Link>
        </Alert>
      </FormControl>
    </CollapseContainer>
  );
};

export default SiteSharingBlockSettings;
