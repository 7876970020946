import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import Styles from '../../../assets/js/Styles';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';
import { BlockLayout } from '../../../declarations/models/BlockLayout';
import { BlockModuleLayout } from '../../../declarations/models/BlockModuleLayout';
import { BlockType } from '../../../declarations/models/BlockType';

const MODULE_OPTION_ICONS: Record<BlockModuleType, ReactNode> = {
  [BlockModuleType.BODY_TEXT]: <MaterialSymbol name='subject' />,
  [BlockModuleType.INLINE]: <MaterialSymbol name='subject' />,
  [BlockModuleType.MODULE]: (
    <MaterialSymbol
      name='notes'
      sx={{
        color: Styles.Colors.MENU_ICON_INVERTED_COLOR,
        backgroundColor: Styles.Colors.DARK_GREY,
        borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
      }}
    />
  ),
};

export const PageBlockModuleTypeSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath, blockType } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<
      BaseBlock & {
        layout?: BlockLayout | BlockModuleLayout;
        module__type: BlockModuleType;
      }
    >,
    `${typeof blockPath}.module__type`
  >({
    name: `${blockPath}.module__type`,
  });

  const MODULE_OPTIONS: Array<BlockModuleType> = [];
  if (blockType === BlockType.TEXT) {
    MODULE_OPTIONS.push(BlockModuleType.BODY_TEXT, BlockModuleType.MODULE);
  } else {
    MODULE_OPTIONS.push(BlockModuleType.INLINE, BlockModuleType.MODULE);
  }

  return (
    <ActionMenu
      id='page-block-layout-selector'
      ariaLabel={tAria('components.PageBlockModuleTypeSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockModuleTypeSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? MODULE_OPTION_ICONS[value] : MODULE_OPTION_ICONS.bodytext}>
      {MODULE_OPTIONS.map((moduleOption) => (
        <MenuItem
          key={moduleOption}
          onClick={() => onChange(moduleOption)}
          selected={value === moduleOption}
          aria-label={tComponents(`PageBlockModuleTypeSetting.options.${moduleOption}`)}>
          <ListItemIcon>{MODULE_OPTION_ICONS[moduleOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockModuleTypeSetting.options.${moduleOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockModuleTypeSetting;
