/**
 * Removes an item from a list if it is added, or adds it if not.
 * Returns an updated shallow copy of the provided items.
 * @param items The list of items to check against
 * @param item The item to toggle
 * @param comparator Optionally provide a function to determine equality. Reference equality by default.
 */
export function toggleItem<T>(items: Array<T>, item: T, comparator?: (a: T, b: T) => boolean): Array<T> {
  const itemsCpy = [...items];
  const idx = itemsCpy.findIndex((current) => (comparator ? comparator(item, current) : item === current));
  if (idx >= 0) {
    itemsCpy.splice(idx, 1);
  } else {
    itemsCpy.push(item);
  }
  return itemsCpy;
}

/**
 * Return a list of unique items based on reference equality, or by providing a custom comparator
 * @param items A list of potentially duplicate elements
 * @param isEqual a function to decide when two items are equal. Defaults to strict equality.
 */
export function distinct<T>(items: Array<T>, isEqual?: (item1: T, item2: T) => boolean): Array<T> {
  if (!Array.isArray(items)) {
    return [];
  }
  return items.reduce((distinctItems, item) => {
    if (distinctItems.some((currentItem) => (isEqual ? isEqual(item, currentItem) : item === currentItem))) {
      return distinctItems;
    }
    return [...distinctItems, item];
  }, [] as Array<T>);
}
