/* istanbul ignore file */

export enum DMObjectType {
  ARTICLE = 'Article',
  PERSON = 'Person',
  ARCHITECTURE = 'Architecture',
  ARTWORK = 'Fineart',
  BUILDING = 'Building',
  STORY = 'Story',
  PHOTOGRAPHY = 'Photograph',
  THING = 'Thing',
  EVENT = 'Event',
  CONSTRUCTION = 'Construction',
  ART_OR_DESIGN = 'Artdesign',
  AUDIO = 'Audio',
  NAVAL_VESSEL = 'NavalVessel',
  FOLDER = 'Folder',
  MUSEUM = 'Museum',
  ORGANIZATION = 'Organization',
  SCHOOL = 'School',
  SCHOOL_PHYSICAL = 'school_physical',
  SCHOOL_DIGITAL = 'school_digital',
  TIME_PERIOD = 'TimePeriod',
  EXHIBITION = 'Exhibition',
  INVESTIGATION = 'Investigation',
  VIDEO = 'Video',
  VIRTUAL = 'Virtual',
}
