import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Site } from '@/declarations/models/Site';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import { SharedSiteSelector } from '@/components/Finder/SharedSiteSelector';
import { SharingContentType } from '@/declarations/models/SiteSharingModel';
import { FullScreenModal } from '@/components/FullScreenModal';
import EmployeeEditor from '@/editor/EmployeeEditor/EmployeeEditor';

export interface EmployeeFinderHeaderProps {
  onQueryChange: (query: string) => void;
  forceRefresh: () => void;
  site: Site | null;
  selectedSharedSite: Site | null;
  onSelectSharedSite: (s: Site | null) => void;
}

export const EmployeeFinderHeader: FC<EmployeeFinderHeaderProps> = ({
  onQueryChange,
  forceRefresh,
  site,
  selectedSharedSite,
  onSelectSharedSite,
}) => {
  const { t } = useTranslation('components');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    forceRefresh();
    setModalOpen(false);
  };

  return (
    <FinderHeader
      title={<Typography sx={{ fontSize: 'xx-large', fontWeight: 500 }}>{t('EmployeesView.Title')}</Typography>}
      onQueryChange={onQueryChange}
      topRight={
        <SharedSiteSelector
          siteContext={site}
          selectedSite={selectedSharedSite}
          setSelectedSite={onSelectSharedSite}
          contentType={SharingContentType.CONTENT}
        />
      }>
      <FullScreenModal modalOpen={modalOpen}>
        <EmployeeEditor onCloseEditor={handleCloseModal} />
      </FullScreenModal>
    </FinderHeader>
  );
};

export default EmployeeFinderHeader;
