import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import SelectButton from '@/components/SelectButton';
import Styles from '@/assets/js/Styles';
import { Status } from '@/declarations/models/Status';
import { formatAPITimestamp } from '@/utils/dates';
import Grid from '@mui/material/Grid';
import Container from '@/components/Container';
import Loader from '@/components/Loader';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { FormModule } from '../../../declarations/models/FormModule';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { useStore } from '../../store/Store';
import { Api } from '../../../services/Api';
import FinderLayout from '../FinderLayout';
import Layout from '../../Layout';
import FormFinderHeader from './FormFinderHeader';

export interface FormFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<FormModule>>) => void;
}

export const FormFinder: FC<FormFinderProps> = ({ open, onClose, onSelectionConfirmed }) => {
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const selectedLocale = store?.state?.selectedSiteLanguage || 'no';
  const [forms, setForms] = useState<Array<GenericMedia<FormModule>>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<FormModule>>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<Status[]>([]);
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const resetState = () => {
    setSelectedItems([]);
    setSearchQuery('');
    setStatusFilter([]);
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  const filteredForms = forms.filter((form) => {
    if (searchQuery && !form.title?.toLowerCase().includes(searchQuery.toLowerCase())) {
      return false;
    }
    if (statusFilter.length && !statusFilter.includes(form.source.status)) {
      return false;
    }
    return true;
  });

  const isSelected = (item: GenericMedia<FormModule>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<FormModule>) => {
    setSelectedItems((previouslySelected) =>
      previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
    );
  };

  const handleSelectionConfirmed: FormFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getFormModules(siteId, selectedLocale);

    ctx
      .fetchDirect(null)
      .then((response) => {
        if (!unmounted) {
          setForms(
            (response?.modules || []).map((m) => ({
              id: String(m.id || 0),
              title: `${m.title}`,
              url: '',
              source: m,
            })),
          );
          setIsLoading(false);
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });

    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [open, siteId]);

  return (
    <FinderLayout<FormModule>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => (
        <Container column top left fullWidth fullHeight m={4}>
          <Typography sx={{ wordBreak: 'break-word' }} fontWeight='bold'>
            {item.title}
          </Typography>
        </Container>
      )}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout
        headerContent={
          <FormFinderHeader
            onQueryChange={setSearchQuery}
            onStatusFilterChange={setStatusFilter}
            statusFilter={statusFilter}
          />
        }>
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            paddingX: 2,
          }}>
          {isLoading && <Loader loadingText={tCommon('loadingContent')} />}
          {filteredForms.length === 0 && !isLoading && (
            <Typography color='textSecondary'>{tComponents('FormView.NoItems')}</Typography>
          )}
          {filteredForms.map((form) => (
            <Grid
              container
              key={form.id}
              display='flex'
              alignItems='center'
              onClick={() => handleSelectionChanged(form)}
              p={1}
              borderRadius={1}
              border={`1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`}
              bgcolor='rgba(0,0,0,0.1)'
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                },
              }}>
              <Grid item xs={12} lg={7}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    flexGrow: 1,
                  }}>
                  {form.title}
                </Typography>
              </Grid>

              <Grid item xs={2} lg={3}>
                <Typography>
                  {tComponents('FormView.NumberOfPagesInUse', { count: form.source.used_in_pages?.length ?? 0 })}
                </Typography>
              </Grid>

              <Grid item xs={10} lg={2} display='flex' justifyContent='space-between'>
                <Box display='flex' alignItems='center' gap={1}>
                  <MaterialSymbol
                    name='circle'
                    fill
                    sx={{ fontSize: '16px', top: 0 }}
                    color={form.source.status === Status.PUBLISHED ? 'success' : 'disabled'}
                  />
                  <Typography variant='caption' color='textSecondary'>
                    {tCommon(`Status.${form.source.status}`)} {formatAPITimestamp(form.source.updated_at)}
                  </Typography>
                </Box>

                <SelectButton selected={isSelected(form)} onChange={() => handleSelectionChanged(form)} />
              </Grid>
            </Grid>
          ))}
        </Box>
      </Layout>
    </FinderLayout>
  );
};

export default FormFinder;
