import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { CommonMediaListViewTypeProps } from '../../CommonMediaListViewTypeProps';
import Container from '../../../Container';
import Styles from '../../../../assets/js/Styles';
import Image from '../../../Image';
import FavouriteButton from '../../../FavouriteButton';
import { DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import { MediaLicense } from '../../../../declarations/models/MediaLicense';

export interface GridViewDMVariantProps extends Omit<CommonMediaListViewTypeProps, 'items'> {
  item: GenericMedia;
  isFavourite?: boolean;
}

function renderKeyValuePair(key: string, value = ''): ReactNode {
  if (!value) {
    return null;
  }
  return (
    <Container top left fullWidth>
      <Typography variant='caption' fontWeight='bold'>
        {key}:
      </Typography>
      <Typography variant='caption' sx={{ wordBreak: 'break-word', textAlign: 'left' }}>
        {value}
      </Typography>
    </Container>
  );
}

export const GridViewDMVariant: FC<GridViewDMVariantProps> = ({
  item,
  isFavouriteable,
  isFavourite = false,
  onFavouriteChanged,
}) => {
  const source = item.source as DMMediaModel | null;
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  return (
    <Container p={1} column top fullWidth fullHeight>
      <Container sx={{ flex: 0, backgroundColor: Styles.Colors.MEDIUM_GREY, height: '200px' }} fullWidth>
        <Image
          src={`${item.url}?&dimension=250x250`}
          alt={item.title}
          loading='lazy'
          sx={{
            width: '200px',
            height: '200px',
            objectFit: 'contain',
            overflow: 'hidden',
          }}
        />
      </Container>
      <Container gap={2} column left spaceBetween fullHeight fullWidth>
        <Container column top left gap={0}>
          {isFavouriteable && (
            <FavouriteButton
              isFavourite={isFavourite}
              edge='start'
              onChange={(e) => {
                e.stopPropagation();
                onFavouriteChanged?.(item, !isFavourite);
              }}
              color='inherit'
              selectedColor='inherit'
            />
          )}
          <Typography
            variant='subtitle1'
            component='span'
            fontSize='0.9rem'
            fontWeight='bold'
            lineHeight='1.1rem'
            textAlign='left'>
            {item.title}
          </Typography>
        </Container>
        <Container column fullWidth>
          {renderKeyValuePair(tComponents('GridViewDMVariant.Owner'), source?.['identifier.owner'])}
          {renderKeyValuePair(
            tComponents('GridViewDMVariant.License'),
            source?.['artifact.ingress.license']
              ?.filter((l) => l in MediaLicense)
              .map((l) => tCommon(`MediaLicense.Label.${l}`))
              .join('; '),
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default GridViewDMVariant;
