import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { ConnectDragSource, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import Container, { ContainerProps } from './Container';
import { Draggable } from '../declarations/Draggable';
import { mergeSx } from '../utils/mergeSx';
import { EditorEventType, EventManagerApi } from '../editor/lib/eventManager/EditorEventManager';

export interface DragAndDropReorderingItemContainerProps<T> extends Omit<ContainerProps, 'column' | 'children'> {
  /**
   * Specify a different type for two lists to separate them from each other
   */
  type?: Draggable;
  /**
   * The index of the item being dragged
   */
  index: number;
  /**
   * The value of this item. Used when moving items across multiple instances of the same list-type
   */
  value?: T;
  /**
   * Handle the actual reordering
   * @param fromIndex The index of the item being dragged/dropped
   * @param toIndex The index of the item is dropped at.
   */
  onReorder: (fromIndex: number, toIndex: number) => void;
  /**
   * Handle the actual removal when an item is removed from this list to another
   * @param index The index of the item to remove
   */
  onRemove?: (index: number) => void;
  /**
   * Handle add an element from another list/container of the same type as this
   * @param item The item that was added
   * @param index The index where the item was dropped
   */
  onAdd?: (item: T | null, index: number) => void;
  /**
   * Renders a placeholder for the item being dragged
   */
  renderPlaceholder: () => ReactElement;
  /**
   * Renders an indicator either above or below an item to indicate its new position if dropped
   * @param visible Whether the placeholder should be visible or not
   */
  renderDropIndicator: (visible: boolean) => ReactElement;
  /**
   * Renders a draggable item
   * @param dragHandleRef The ref to be set on the drag-handle
   * @param dragging Whether THIS item is being dragged.
   * @param anyDragging Whether ANY item is being dragged. May be used to style a dropzone (item not being dragged) when dragging
   */
  children: (dragHandleRef: ConnectDragSource, dragging: boolean, anyDragging: boolean) => ReactElement;
  /**
   * Whether to disable DragAndDrop
   */
  disabled?: boolean;
  useEventManager?: EventManagerApi;
}

/**
 * The data being transferred from an item to a dropzone
 */
type DataTransfer<T> = {
  handlerId: string | symbol | null;
  index: number;
  value: T | null;
  containerElement: HTMLElement | null;
  getCurrentItemPosition: () => number;
  removeFromSourceContainer: () => void;
};

/**
 * Check whether a number is a valid index (A.K.A. positive int)
 * @param i The index to check
 */
function isValidIndex(i?: number): i is number {
  return i !== undefined && i >= 0;
}

/**
 * Use this component to make a list reorderable using drag-and-drop
 * @constructor
 */
export default function DragAndDropReorderingItemContainer<T>({
  type = Draggable.OTHER,
  index,
  value,
  onReorder,
  onAdd,
  onRemove,
  children,
  renderPlaceholder,
  renderDropIndicator,
  disabled = false,
  sx,
  useEventManager,
  ...containerProps
}: DragAndDropReorderingItemContainerProps<T>) {
  const onReorderCallback = useRef(onReorder);
  onReorderCallback.current = onReorder;
  const onAddCallback = useRef(onAdd);
  onAddCallback.current = onAdd;
  const onRemoveCallback = useRef(onRemove);
  onRemoveCallback.current = onRemove;

  const containerElement = useRef<HTMLDivElement | null>(null);
  const eventManager = useEventManager;

  const [dragDirection, setDragDirection] = useState<'up' | 'down' | null>(null);

  const getCurrentItemPosition = useCallback<DataTransfer<T>['getCurrentItemPosition']>(() => {
    const itemRect = containerElement.current?.getBoundingClientRect();
    if (!itemRect) {
      return 0;
    }
    return itemRect.top + itemRect.height / 2;
  }, []);

  const [{ canDrop, isHovering }, initDropzone] = useDrop(
    () => ({
      accept: type,
      canDrop: (item: DataTransfer<T> | null, monitor) =>
        !disabled &&
        !!item &&
        !!onReorderCallback.current &&
        isValidIndex(index) &&
        isValidIndex(item.index) &&
        item.handlerId !== monitor.getHandlerId(),
      drop: (item: DataTransfer<T> | null) => {
        setDragDirection(null);
        if (item) {
          if (item.containerElement !== containerElement.current?.parentElement) {
            item.removeFromSourceContainer();
            onAddCallback.current?.(item.value, dragDirection === 'up' ? index : index + 1);
          } else {
            onReorderCallback.current?.(item.index, index);
          }
        }
      },
      hover: (item: DataTransfer<T> | null, monitor) => {
        const currentItemCenterY = item?.getCurrentItemPosition() || 0;
        const currentCursorY = monitor.getClientOffset()?.y || 0;
        const deltaY = currentCursorY - currentItemCenterY;
        setDragDirection(deltaY < 0 ? 'up' : 'down');
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isHovering: monitor.isOver(),
      }),
    }),
    [dragDirection, disabled, index, type],
  );

  const [{ isDragging }, initDragHandle, initDragPreview] = useDrag(
    () => ({
      type,
      canDrag: () => !disabled && isValidIndex(index),
      item: (monitor): DataTransfer<T> => ({
        handlerId: monitor.getHandlerId(),
        index,
        value: value ?? null,
        containerElement: containerElement.current?.parentElement || null,
        getCurrentItemPosition,
        removeFromSourceContainer: () => onRemoveCallback.current?.(index),
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [value, index, disabled, type, getCurrentItemPosition],
  );

  const makeDropIndicator = (placement: 'above' | 'below') => {
    const visible =
      canDrop &&
      !isDragging &&
      isHovering &&
      ((placement === 'above' && dragDirection === 'up') || (placement === 'below' && dragDirection === 'down'));

    return renderDropIndicator(visible);
  };

  initDragPreview(getEmptyImage());
  initDropzone(containerElement);

  useEffect(() => {
    if (isDragging) {
      eventManager?.fireEvent(EditorEventType.DRAGGING, { type });
    }
  }, [eventManager, isDragging, type]);

  return (
    <Container ref={containerElement} sx={mergeSx(sx || {}, { position: 'relative' })} column {...containerProps}>
      {makeDropIndicator('above')}
      {isDragging ? renderPlaceholder() : children(initDragHandle, isDragging, canDrop)}
      {makeDropIndicator('below')}
    </Container>
  );
}
