import React, { FC } from 'react';
import { Breadcrumbs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '@/declarations/models/Breadcrumb';
import { EditorBreadcrumbItem } from '@/editor/lib/components/EditorHeader/EditorBreadcrumbItem';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export interface EditorBreadcrumbsProps {
  breadcrumbs: Array<Breadcrumb>;
  siteId?: number;
  locale?: string;
  onCreateChildPage?: (parentId: number, parentTitle: string) => Promise<void>;
}

export const EditorBreadcrumbs: FC<EditorBreadcrumbsProps> = ({ breadcrumbs, siteId, locale, onCreateChildPage }) => {
  const { t } = useTranslation('aria');

  if (!breadcrumbs?.length) {
    return null;
  }

  const breadcrumbsCopy = [...breadcrumbs];
  const lastBreadcrumb = breadcrumbsCopy.pop();

  return (
    <Breadcrumbs
      aria-label={t('components.EditorBreadcrumbs.Description')}
      separator={<MaterialSymbol name='chevron_right' />}>
      {breadcrumbsCopy.map((breadcrumb) => (
        <EditorBreadcrumbItem
          key={breadcrumb.id}
          siteId={siteId}
          locale={locale}
          breadcrumb={breadcrumb}
          truncateText
          onCreateChildPage={onCreateChildPage}
        />
      ))}
      {lastBreadcrumb && (
        <EditorBreadcrumbItem
          siteId={siteId}
          locale={locale}
          breadcrumb={lastBreadcrumb}
          disableEditorLink
          onCreateChildPage={onCreateChildPage}
          labelSx={{
            fontWeight: 700,
          }}
        />
      )}
    </Breadcrumbs>
  );
};

export default EditorBreadcrumbs;
