/* istanbul ignore file */

import { M24MediaModel } from './M24MediaModel';

export enum LinkTarget {
  STANDARD = 'auto',
  SAME_TAB = '_self',
  NEW_TAB = '_blank',
  NEW_COMMON_TAB = 'm24ref',
}

export interface LinkExternal {
  id?: number;
  type: string;
  target?: LinkTarget;
  title?: string;
  url?: string;
  anchor?: string;
  local?: any;
}

export interface M24MediaLinkItem extends M24MediaModel {
  id?: number;
  target?: LinkTarget;
  anchor?: string;
}
