/* istanbul ignore file */

import BaseModel from './BaseModel';

export enum SharingContentType {
  RESOURCES = 'resources',
  CONTENT = 'content',
  TEMPLATES = 'templates',
}

export enum SharingContentApprovalStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REMOVED = 'removed',
  DENIED = 'denied',
}

export interface SharingRequestModel extends BaseModel {
  site_id?: number;
  has_access_to_site_id?: number;
  content_type: SharingContentType;
  permission_level?: string;
  approval_status?: SharingContentApprovalStatus;
  dam?: boolean;
  new?: boolean;
  remote_site_id?: number;
}

export interface SharingGrantedModel extends SharingRequestModel {
  site_granted?: string;
}

export interface SharingGivenModel extends SharingRequestModel {
  site_given?: string;
}
export interface SiteSharingModel extends BaseModel {
  granted: Array<SharingGrantedModel>;
  given: Array<SharingGivenModel>;
}
