import React, { FC, PropsWithChildren } from 'react';
import { Skin } from '@/declarations/models/Skin';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';
import { Box, Card as MUICard, CardMedia, Typography } from '@mui/material';
import { calculateObjectPosition } from '@/components/Image';
import Styles from '@/assets/js/Styles';

export const CardPreview: FC<
  PropsWithChildren<{
    image?: {
      filename?: string;
      url?: string;
      mimetype?: string;
      local?: {
        x?: string;
        y?: string;
      };
    };
    selectedSkin?: Skin;
    title?: string | undefined;
    description?: string | undefined;
    callToActionButtonText?: string | undefined;
    size?: 'small' | 'medium' | 'large';
    footer?: string;
    footerLocation?: string;
    location?: string;
    url?: string;
    externalUrl?: string;
    pageLink?: string;
  }>
> = ({
  children,
  image,
  selectedSkin,
  title,
  description,
  callToActionButtonText,
  size = 'medium',
  footer,
  footerLocation,
  location,
  url,
  externalUrl,
  pageLink,
}) => {
  const height = (
    {
      small: 250,
      medium: 400,
      large: 600,
    } as const
  )[size];
  const width = height;
  const fontSize = (
    {
      small: '.85rem',
      medium: '1rem',
      large: '1.25rem',
    } as const
  )[size];
  const titleSize = (
    {
      small: '1.05rem',
      medium: '1.5rem',
      large: '1.75rem',
    } as const
  )[size];

  let imgMimeType = '';
  if (image?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${image?.mimetype}`;
  }
  const { t: tComponents } = useTranslation('components');

  return (
    <Container column sx={{ overflow: 'hidden' }}>
      <MUICard
        sx={{
          height: 'auto',
          width,
          borderRadius: 0,
          backgroundColor: selectedSkin?.hex || Styles.Colors.WHITE,
          color: selectedSkin?.txt || Styles.Colors.BLACK,
        }}>
        {image?.url && (
          <CardMedia
            component='img'
            height={width * 0.75}
            image={`${image?.url}?dimension=400x400&${imgMimeType}`}
            alt={image?.filename}
            sx={{
              objectPosition: calculateObjectPosition(image?.local?.x, image?.local?.y),
              objectFit: 'cover',
            }}
          />
        )}
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          sx={{
            p: size === 'small' ? 1 : 2,
            gap: size === 'small' ? 1 : 2,
          }}>
          <Box minHeight={100}>
            <Box display='flex' gap={1} mb={1}>
              <Typography fontSize={titleSize}>{title}</Typography>
            </Box>
            <Typography fontSize={fontSize} variant='body2'>
              {description}
            </Typography>
            <Typography fontSize={fontSize} variant='body2' mt={1} sx={{ textDecoration: 'underline' }}>
              {callToActionButtonText}
            </Typography>
          </Box>

          <Container left column gap={0}>
            <Typography fontSize={fontSize} variant='body2' sx={{ textTransform: 'uppercase' }}>
              {location && location}
            </Typography>
            <Typography fontSize={fontSize} variant='body2' sx={{ textTransform: 'uppercase' }}>
              {footerLocation && footerLocation}
            </Typography>
            <Typography fontSize={fontSize} variant='body2'>
              {footer && footer}
            </Typography>
          </Container>

          {children}
        </Box>
      </MUICard>

      {externalUrl && (
        <Container p={1} column gap={0}>
          {tComponents('CardEditor.Url')}:
          <a
            target='_blank'
            rel='noreferrer'
            href={externalUrl}
            style={{ lineHeight: '1', textDecoration: 'none', padding: '3px' }}>
            <Typography
              fontSize={fontSize}
              variant='caption'
              sx={{
                fontSize: '15px',
                lineHeight: '1.25',
                textDecoration: 'underline',
                wordBreak: 'break-all',
              }}>
              {externalUrl}
            </Typography>
          </a>
        </Container>
      )}
      {url && (
        <Container p={1} column gap={0}>
          {tComponents('CardEditor.Page')}:
          <a
            target='_blank'
            rel='noreferrer'
            href={`//${url}`}
            style={{ lineHeight: '1', textDecoration: 'none', padding: '3px' }}>
            <Typography
              fontSize={fontSize}
              variant='caption'
              sx={{
                fontSize: '15px',
                lineHeight: '1.25',
                textDecoration: 'underline',
                wordBreak: 'break-all',
              }}>
              https://{url}
            </Typography>
          </a>
        </Container>
      )}
    </Container>
  );
};
