import React, { FC } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { ChildrenProp } from '../declarations/ChildrenProp';

interface MediaEditorModalProps extends ChildrenProp {
  modalOpen: boolean;
}

export const FullScreenModal: FC<MediaEditorModalProps> = ({ children, modalOpen }) => {
  return (
    <Dialog open={modalOpen} fullScreen sx={{ margin: 4 }}>
      <DialogContent sx={{ padding: 2 }}>{children}</DialogContent>
    </Dialog>
  );
};

export default FullScreenModal;
