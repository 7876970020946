import React, { FC, useEffect, useState } from 'react';
import { Site } from '@/declarations/models/Site';
import Layout from '../../Layout';
import EmployeeFinderHeader from './EmployeeFinderHeader';
import { ViewType } from '../../../declarations/ViewType';
import { GenericMedia } from '../../../declarations/GenericMedia';
import MediaList from '../../MediaList/MediaList';
import { Api } from '../../../services/Api';
import { useStore } from '../../store/Store';
import FinderLayout from '../FinderLayout';
import { toggleItem } from '../../../utils/array';
import SelectedEmployeeItem from './SelectedEmployeeItem';
import { Employee } from '../../../declarations/models/Employee';
import { SortDirection, SortType } from '../../../declarations/models/SortOption';

export interface EmployeeFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<Employee>>) => void;
  multiSelect?: boolean;
}

export const EmployeeFinder: FC<EmployeeFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  multiSelect = true,
}) => {
  const store = useStore();
  const site = store?.state?.selectedSite;
  const siteId = site?.id || 0;
  const selectedSiteLanguage = store?.state?.selectedSiteLanguage || 'no';
  const [employees, setEmployees] = useState<Array<GenericMedia<Employee>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshDep, setRefreshDep] = useState(Date.now());
  const [selectedSharedSite, setSelectedSharedSite] = useState<Site | null>(null);

  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<Employee>>>([]);

  const resetState = () => {
    setSelectedItems([]);
    setSearchQuery('');
    setSelectedSharedSite(null);
    setPage({ start: 0, rows: 20 });
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  const isSelected = (item: GenericMedia<Employee>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<Employee>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: EmployeeFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getEmployees(selectedSharedSite?.id ?? siteId, {
      ...page,
      order: SortDirection.DESC,
      order_by: SortType.UPDATED_AT,
      text_query: searchQuery,
      locale: selectedSiteLanguage,
    });
    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.count || 0);
          setEmployees(
            (loadedPage?.result || []).map(
              (item) =>
                ({
                  id: String(item.id || 0),
                  title: `${item.first_name} ${item.last_name}`,
                  url: item.resource?.url || '',
                  source: item,
                } as GenericMedia<Employee>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [page, selectedSharedSite, siteId, open, searchQuery, refreshDep]);

  return (
    <FinderLayout<Employee>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => <SelectedEmployeeItem item={item} onRemoveItem={handleSelectionChanged} />}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout
        headerContent={
          <EmployeeFinderHeader
            onQueryChange={setSearchQuery}
            forceRefresh={() => setRefreshDep(Date.now())}
            site={site}
            selectedSharedSite={selectedSharedSite}
            onSelectSharedSite={setSelectedSharedSite}
          />
        }>
        <MediaList
          isLoading={isLoading}
          items={employees}
          page={page}
          variant='employee'
          totalItemCount={totalCount}
          onPageChange={(_c, start, rows) => handlePageChanged({ start, rows })}
          onPageSizeChange={(rows) => handlePageChanged({ rows })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
          // TODO: Implement isFavourite-able
          isFavouriteable={false}
        />
      </Layout>
    </FinderLayout>
  );
};

export default EmployeeFinder;
