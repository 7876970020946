import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import Styles from '../../../assets/js/Styles';

export const DeprecatedBlock: BlockPreviewComponent = () => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { block, blockType } = useCurrentBlock();

  const blockName = tCommon(`BlockType.${blockType}`);
  return (
    <Container
      column
      fullWidth
      p={2}
      mt={0}
      gap={0}
      left
      sx={{
        backgroundColor: Styles.Colors.LIGHT_BLUE,
        borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
        border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
        fontStyle: 'italic',
        fontSize: '15px',
      }}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: tComponents(`Migration.intro`) }} />

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: tComponents(`Migration.${blockType}`) }} />

      <a target='_blank' rel='noreferrer' href={tComponents(`Manual.${blockType}`)}>
        {tComponents(`Migration.readMore`)} {tComponents(`Manual.${blockType}`)}
      </a>
    </Container>
  );
};

export default DeprecatedBlock;
