import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { SectionType } from '../../declarations/models/SectionType';
import { Section as SectionModel } from '../../declarations/models/Section';

export type SectionsPath = 'content.sections';
export type SectionPath = `${SectionsPath}.0`;

export interface CurrentSectionProviderProps {
  section: SectionModel;
  sectionIndex: number;
}

export interface UseCurrentSectionReturn {
  sectionIndex: number;
  sectionType: SectionType;
  sectionPath: SectionPath;
  section: Readonly<SectionModel>;
}

const CurrentSectionContext = createContext<UseCurrentSectionReturn | null>(null);

export function useCurrentSection(): UseCurrentSectionReturn {
  const currentSection = useContext(CurrentSectionContext);
  if (!currentSection) {
    throw new Error('[CurrentSection] Current section not available');
  }
  return currentSection;
}

export const CurrentSectionProvider: FC<PropsWithChildren<CurrentSectionProviderProps>> = ({
  children,
  section,
  sectionIndex,
}) => {
  return (
    <CurrentSectionContext.Provider
      value={
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        {
          section,
          sectionIndex,
          sectionType: section.type,
          sectionPath: `content.sections.${sectionIndex}` as SectionPath,
        }
      }>
      {children}
    </CurrentSectionContext.Provider>
  );
};
