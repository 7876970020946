import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import CurrentPageInfo from '@/components/MediaList/CurrentPageInfo';
import ViewTypeSelector from '@/components/ViewTypeSelector';
import { SortSelector } from '@/components/SortSelector';
import { ChildrenProp } from '@/declarations/ChildrenProp';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import { useStore } from '@/components/store/Store';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { Api } from '@/services/Api';
import { ViewType } from '@/declarations/ViewType';
import { toggleItem } from '@/utils/array';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import { FilterOption } from '@/declarations/models/FilterOption';
import { Tag } from '@/declarations/models/Tag';
import { TagType } from '@/declarations/models/TagType';
import Container from '../../components/Container';
import PageSizeSelector from '../../components/PageSizeSelector';
import Paginator from '../../components/Paginator';
import MediaViewList from './MediaViewList';
import calculatePaginationInfo from '../../utils/calculatePaginationInfo';
import EditSelectedMedia from './EditSelectedMedia';
import FullScreenModal from '../../components/FullScreenModal';
import MediaEditor from '../../editor/MediaEditor/MediaEditor';
import { MediaViewHeader } from './MediaViewHeader';

export enum EditState {
  SINGLE = 'single',
  GROUP_UPDATE = 'group_update',
  GROUP_DELETE = 'group_delete',
}

export const MediaView: FC<ChildrenProp> = () => {
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirmDialog();
  const { t: tComponent } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const resetPage = () => {
    setPage((prevPage) => ({ ...prevPage, start: 0 }));
  };
  const [selectedMediaType, _setSelectedMediaType] = useState<MediaResourceType | null>(null);
  const setSelectedMediaType: typeof _setSelectedMediaType = (type) => {
    resetPage();
    _setSelectedMediaType(type);
  };
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(ViewType.GRID);

  const [mediaItems, setMediaItems] = useState<Array<M24MediaModel>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { pageSize } = calculatePaginationInfo(page, totalCount);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchInput, _setSearchInput] = useState<string>('');
  const setSearchInput: typeof _setSearchInput = (value) => {
    resetPage();
    _setSearchInput(value);
  };
  const [selectedTags, _setSelectedTags] = useState<Array<Tag>>([]);
  const setSelectedTags: typeof _setSelectedTags = (_selectedTags) => {
    resetPage();
    _setSelectedTags(_selectedTags);
  };
  const [filterOption, _setFilterOption] = useState<FilterOption>(FilterOption.NONE);
  const setFilterOption: typeof _setFilterOption = (option) => {
    resetPage();
    _setFilterOption(option);
  };
  const [sortOption, _setSortOption] = useState<SortOption>({ sortBy: SortType.CREATED_AT, order: SortDirection.DESC });
  const setSortOption: typeof _setSortOption = (option) => {
    resetPage();
    _setSortOption(option);
  };
  const [refreshDep, setRefreshDep] = useState(Date.now);
  const refreshPage = () => setRefreshDep(Date.now());

  const [editState, setEditState] = useState<EditState>(EditState.SINGLE);
  const [selectedItems, setSelectedItems] = useState<Array<M24MediaModel>>([]);
  const [selectedOnPage, setSelectedOnPage] = useState<Array<M24MediaModel>>([]);
  const allSelected = selectedOnPage.length === mediaItems.length;
  const someSelected = !allSelected && !!selectedOnPage.length;

  const [editMediaId, setEditMediaId] = useState<number>(0);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const isSelected = (item: M24MediaModel): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleOnItemClick = (item: M24MediaModel) => {
    if (editState === EditState.SINGLE) {
      if (item.id) {
        setEditMediaId(item.id);
        setEditModalOpen(true);
      }
    } else setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
  };

  const handleSelectAll = () => {
    if (!someSelected && !allSelected) setSelectedItems(mediaItems);
    else setSelectedItems([]);
  };

  const handleItemDelete = async (item: M24MediaModel) => {
    const id = Number(item.id);
    if (!Number.isNaN(id) && (await confirm(tComponent('MediaView.ConfirmDelete')))) {
      const [, error] = await Api.deleteM24MediaResource(siteId, id).fetch();
      if (error) enqueueSnackbar(error.message, { variant: 'error' });
      else enqueueSnackbar('Deleted item', { variant: 'success' });
      refreshPage();
    }
  };

  const doBatchDelete = async () => {
    if (await confirm(tComponent('MediaView.ConfirmBatchDelete'))) {
      const resourceIds = selectedOnPage.map((item) => item.id).filter((id): id is number => id !== undefined);

      if (resourceIds.length > 0) {
        const [, error] = await Api.deleteM24MediaResources(siteId, resourceIds).fetch();

        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Deleted items successfully', { variant: 'success' });
          refreshPage();
        }
      }
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleCloseModal = () => {
    refreshPage();
    setEditModalOpen(false);
  };

  useEffect(() => {
    setSelectedOnPage(selectedItems.filter((item) => mediaItems.some((media) => media.id === item.id)));
  }, [mediaItems, selectedItems]);

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getM24MediaResources(siteId, {
      ...page,
      query: searchInput || undefined,
      order_by: sortOption.sortBy,
      order: sortOption.order,
      created_by_id: filterOption === 'created_by_id' ? store.state.currentUser?.user?.id : undefined,
      type: selectedMediaType ?? undefined,
      get_tags: true,
      get_usage_data: true,
      tags: selectedTags.map((t) => t.tag),
    });

    const tagCtx = Api.getAllTagsForSite(siteId, { type: TagType.TAG });

    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.count || 0);
          setMediaItems(loadedPage?.items || []);
        }
      })
      .then(() => tagCtx.fetchDirect(null).then((res) => res && setTags(res)))
      .finally(() => {
        if (!unmounted) {
          setIsLoading(false);
        }
      });
    return () => {
      unmounted = true;
      tagCtx.abort();
      ctx.abort();
    };
  }, [
    page,
    selectedMediaType,
    siteId,
    sortOption,
    filterOption,
    refreshDep,
    searchInput,
    store.state.currentUser?.user?.id,
    selectedTags,
  ]);

  return (
    <Container p={6} fullWidth fullHeight column gap={0} top>
      <FullScreenModal modalOpen={editModalOpen}>
        <MediaEditor onCloseEditor={handleCloseModal} resourceId={editMediaId} />
      </FullScreenModal>
      <MediaViewHeader
        siteId={siteId}
        tags={tags}
        editState={editState}
        setEditState={setEditState}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedMediaType={selectedMediaType}
        setSelectedMediaType={setSelectedMediaType}
        refreshPage={refreshPage}
        doBatchDelete={doBatchDelete}
        someSelected={someSelected}
        allSelected={allSelected}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      <Container sx={{ mt: 2 }} fullWidth spaceBetween>
        <Container gap={2}>
          <SortSelector
            availableOptions={[SortType.TITLE, SortType.CREATED_AT, SortType.UPDATED_AT]}
            sortOption={sortOption}
            setSortOption={setSortOption}
          />
          {/* todo: implement "missing alt" filter in API */}
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={filterOption === FilterOption.MISSING_ALT}
                onChange={() =>
                  setFilterOption((prev) =>
                    prev === FilterOption.MISSING_ALT ? FilterOption.NONE : FilterOption.MISSING_ALT,
                  )
                }
              />
            }
            label={tCommon('FilterOptions.missing_alt')}
          /> */}
          {editState !== EditState.SINGLE && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ padding: '8px' }}
                    color='success'
                    onChange={handleSelectAll}
                    checked={allSelected}
                    indeterminate={someSelected}
                  />
                }
                label={tCommon('selectAll')}
              />
              <Typography variant='caption'>
                {tComponent('MediaView.NrSelected', { nr: selectedItems.length })}
              </Typography>
            </>
          )}
        </Container>
        <Container>
          <CurrentPageInfo page={page} totalItemCount={totalCount} />
          <ViewTypeSelector value={selectedViewType} onChange={setSelectedViewType} />
        </Container>
      </Container>
      <Container
        top
        gap={0}
        mt={2}
        sx={{
          flex: 1,
        }}>
        <MediaViewList
          isLoading={isLoading}
          items={mediaItems}
          onItemClick={handleOnItemClick}
          getIsSelected={isSelected}
          editState={editState}
          selectedViewType={selectedViewType}
          onDeleteItem={handleItemDelete}
          someSelected={someSelected || allSelected}
        />
        {editState === EditState.GROUP_UPDATE && !!selectedOnPage.length && (
          <EditSelectedMedia selectedItems={selectedOnPage} refreshPage={refreshPage} tags={tags} siteId={siteId} />
        )}
      </Container>
      <Container p={2} sx={{ boxShadow: 3 }} fullWidth>
        <PageSizeSelector pageSize={pageSize} onChange={(rows) => handlePageChanged({ rows })} variant='standard' />
        <Paginator
          page={page}
          onChange={(c, start, rows) => handlePageChanged({ start, rows })}
          totalItemCount={totalCount}
        />
      </Container>
    </Container>
  );
};

export default MediaView;
