import React, { useState } from 'react';
import { SnackbarContentProps, useSnackbar } from 'notistack';
import { Card, CardActions, CardContent, IconButton, Typography, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Container from '@/components/Container';

interface ExpandableSnackbarProps extends SnackbarContentProps {
  message: string;
  expandedContent: string;
  variant: 'default' | 'error' | 'success' | 'warning' | 'info' | 'expandableError';
}

const ExpandableSnackbar = React.forwardRef<HTMLDivElement, ExpandableSnackbarProps>((props, ref) => {
  const { id, message, expandedContent, variant } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { closeSnackbar } = useSnackbar();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    closeSnackbar(id);
  };

  const backgroundColor = variant === 'expandableError' ? theme.palette.error.main : theme.palette.background.paper;
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Container sx={{ maxWidth: '600px' }}>
      <Card ref={ref} sx={{ backgroundColor, color: textColor }}>
        <CardActions>
          <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
            {message}
          </Typography>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            sx={{ color: textColor }}>
            <ExpandMoreIcon />
          </IconButton>
          <Container sx={{ marginLeft: 'auto' }}>
            <IconButton onClick={handleClose} aria-label='close' sx={{ color: textColor }}>
              <CloseIcon />
            </IconButton>
          </Container>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent sx={{ color: textColor }}>
            <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
              {expandedContent}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Container>
  );
});

ExpandableSnackbar.displayName = 'ExpandableSnackbar';

export default ExpandableSnackbar;
