import React, { FC, ReactNode } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActionMenu from '../../../../components/ActionMenu';
import Container from '../../../../components/Container';

interface ViewTypeSelectorProps {
  viewType: string;
  viewTypes: Array<string>;
  onChange: (type: string) => void;
  variantIcons: Record<string, ReactNode>;
  variantTranslationPath: string;
}

export const ViewTypeSelector: FC<ViewTypeSelectorProps> = ({
  viewType,
  viewTypes,
  onChange,
  variantIcons,
  variantTranslationPath,
}) => {
  const { t } = useTranslation('components');

  return (
    <Container gap={0}>
      <ActionMenu
        id='select-card-module-block-view-type'
        ariaLabel={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltip={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltipPlacement='top'
        icon={variantIcons[viewType]}>
        {viewTypes.map((type) => (
          <MenuItem key={type} selected={type === viewType} disabled={type === viewType} onClick={() => onChange(type)}>
            <ListItemIcon>{variantIcons[type]}</ListItemIcon>
            <ListItemText>{t(`${variantTranslationPath}.${type}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>
    </Container>
  );
};

export default ViewTypeSelector;
