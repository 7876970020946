import { BaseBlock } from './BaseBlock';
import { Employee } from '../Employee';
import { BlockType } from '../BlockType';
import { SortDirection, SortType } from '../SortOption';

export enum EmployeesViewType {
  GRID = 'grid',
  LIST = 'list',
}

export interface EmployeesBlock extends BaseBlock {
  type: BlockType.EMPLOYEES_AUTO | BlockType.EMPLOYEES_MANUAL;
  showEmail?: boolean;
  showPhone?: boolean;
  showLinks?: boolean;
  showPosition?: boolean;
  showSite?: boolean;
  showDepartment?: boolean;
  showDescription?: boolean;
  enableSearch?: boolean;
  view: EmployeesViewType;
  siteIds?: string;
  items?: Array<Partial<Employee>>;
  sortBy?: SortType;
  order?: SortDirection;
}
