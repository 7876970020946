import React, { FC, useEffect, useState } from 'react';
import { Api } from '@/services/Api';
import { Tag } from '@/declarations/models/Tag';
import { useDebounce } from '@/hooks/useDebounce';
import { Autocomplete, TextField } from '@mui/material';
import { TagType } from '@/declarations/models/TagType';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';

export interface TagsAutocompleteProps {
  tagType: TagType;
  siteId?: number;
  query?: string;
  onQueryChange?: (query: string) => void;
  tags?: Tag[];
  onTagsChange?: (tags: Tag[]) => void;
}

/**
 * Autocompleting and selecting tags. Serves as both autocomplete+select, and as a search field.
 */
export const TagsAutocomplete: FC<TagsAutocompleteProps> = ({
  tagType,
  siteId,
  query,
  onQueryChange,
  tags = [],
  onTagsChange,
}) => {
  const { t: tCommon } = useTranslation('common');
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [internalQuery, setInternalQuery] = useState<string>(query ?? '');
  useDebounce(300, internalQuery, onQueryChange);

  useEffect(() => {
    if (siteId) {
      const ctx = Api.getAllTagsForSite(siteId, { type: tagType });
      ctx.fetchDirect([]).then(setAllTags);

      return () => {
        ctx.abort();
      };
    }
  }, [siteId, tagType]);

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      }}
      id='tags-autocomplete'
      renderInput={(params) => (
        <TextField
          {...params}
          label={tCommon('search')}
          sx={{
            '& label': {
              paddingLeft: '1rem',
            },
            '& .MuiInputBase-root': {
              borderRadius: Styles.Dimensions.RADIUS_ROUND_XL,
            },
          }}
        />
      )}
      options={allTags}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.tag)}
      onChange={(_, value) => {
        onTagsChange?.(value);
      }}
      onInputChange={(_, value) => setInternalQuery(value)}
      value={tags}
      multiple
      filterSelectedOptions
      clearOnBlur={false}
      // hack. see index.css
      noOptionsText={<span className='hide-my-parent' />}
    />
  );
};
