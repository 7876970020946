import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { BlockType } from '@/declarations/models/BlockType';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { SectionPath } from './CurrentSectionProvider';

export type BlocksPath = `${SectionPath}.blocks`;
export type BlockPath = `${BlocksPath}.0`;

export interface CurrentBlockProviderProps {
  block: BaseBlock;
  blockIndex: number;
  sectionIndex: number;
}

export interface UseCurrentBlockContextValue<T extends BaseBlock = BaseBlock> {
  blockIndex: number;
  blockType: BlockType;
  blockPath: BlockPath;
  block: Readonly<T>;
}

const CurrentBlockContext = createContext<UseCurrentBlockContextValue<BaseBlock> | null>(null);

export function useCurrentBlock<T extends BaseBlock = BaseBlock>(): UseCurrentBlockContextValue<T> {
  const currentBlock = useContext(CurrentBlockContext);
  if (!currentBlock) {
    throw new Error(`[CurrentBlock] Current block is not available`);
  }
  return currentBlock as UseCurrentBlockContextValue<T>;
}

export const CurrentBlockProvider: FC<PropsWithChildren<CurrentBlockProviderProps>> = ({
  children,
  block,
  blockIndex,
  sectionIndex,
}) => {
  return (
    <CurrentBlockContext.Provider
      value={
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        {
          block,
          blockIndex,
          blockType: block.type,
          blockPath: `content.sections.${sectionIndex}.blocks.${blockIndex}` as BlockPath,
        }
      }>
      {children}
    </CurrentBlockContext.Provider>
  );
};

export default CurrentBlockProvider;
