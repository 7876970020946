import React, { FC } from 'react';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import { useController, useWatch } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import Container from '@/components/Container';
import { FinderButton, FinderType } from '@/components/Finder/FinderButton';
import { UserSelectableIcon, UserSelectableIconName } from '@/components/UserSelectableIcon';
import { Box, FormControl, FormLabel, IconButton, Tooltip, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import TextInput from '@/components/forms/TextInput';

const SelectedRow: FC<{ icon: React.ReactNode; children: React.ReactNode; onRemove?: () => void }> = ({
  icon,
  children,
  onRemove,
}) => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '72px',
      }}>
      <Box
        sx={{
          borderBottomLeftRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          borderTopLeftRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          backgroundColor: Styles.Colors.MEDIUM_DARK_GREY,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100px',
        }}>
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          p: 2,
          backgroundColor: Styles.Colors.LIGHT_GREY,
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottomRightRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          borderTopRightRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }}>
        {children}

        {!!onRemove && (
          <Tooltip title={t('remove')}>
            <IconButton onClick={onRemove}>
              <MaterialSymbol name='do_not_disturb_on' />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export function BannerIconAndSoundSettings({ path }: { path: BlockPath }) {
  const { t } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const {
    field: { value: icon, onChange: onChangeIcon },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof path}.icon`>({
    name: `${path}.icon`,
  });
  const {
    field: { value: audio, onChange: onChangeAudio },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof path}.audio`>({
    name: `${path}.audio`,
  });

  const mediaObject = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.mediaobject`>({
    name: `${path}.mediaobject`,
  });

  const missingMediaObject = !mediaObject?.id;

  return (
    <Container column fullWidth>
      {!!icon && (
        <SelectedRow
          icon={<UserSelectableIcon name={icon} sx={{ fontSize: 48, color: 'white' }} />}
          onRemove={() => onChangeIcon(null)}>
          <Typography fontWeight={700}>
            {t('icon')}: {t(`userIcon.${icon}`)}
          </Typography>
        </SelectedRow>
      )}
      {!!audio && (
        <SelectedRow
          icon={<MaterialSymbol name='volume_up' sx={{ fontSize: 48, color: 'white' }} />}
          onRemove={() => {
            onChangeAudio(null);
          }}>
          <Box display='flex' flexDirection='column'>
            <Typography fontWeight={700}>
              {t('MediaResourceType.audio')}: {audio.filename}
            </Typography>
            {missingMediaObject && (
              /* keep 1.0 behaviour: if sound only is selected, present input for setting the media credit */
              <FormControl
                fullWidth
                sx={{
                  mt: 2,
                }}>
                <TextInput
                  path={`${path}.mediaCredits`}
                  label={
                    <Typography
                      sx={{
                        fontSize: 'small',
                      }}>
                      {tComponents('BannerBlock.BannerMediaBackground.mediaCredits')}
                    </Typography>
                  }
                  size='small'
                  defaultValue=''
                />
                <FormLabel sx={{ fontSize: 'small' }}>
                  {tComponents('BannerBlock.BannerMediaBackground.mediaCreditsDescription')}
                </FormLabel>
              </FormControl>
            )}
          </Box>
        </SelectedRow>
      )}

      <Container fullWidth>
        <FinderButton
          type={FinderType.ICON}
          overrideText={t('icon')}
          multiSelect={false}
          finderProps={{
            selectedIcon: icon,
            onSelectionConfirmed: (selected: UserSelectableIconName) => {
              onChangeIcon(selected);
            },
          }}
        />
        <FinderButton
          type={FinderType.M24}
          overrideText={t('MediaResourceType.audio')}
          overrideIcon={<MaterialSymbol name='volume_up' />}
          multiSelect={false}
          finderProps={{
            selectableResourceTypes: [MediaResourceType.AUDIO],
            onSelectionConfirmed: (selected) => {
              onChangeAudio(selected[0].source);
            },
            defaultResourceType: MediaResourceType.AUDIO,
          }}
        />
      </Container>
    </Container>
  );
}
