import React, { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import Container from '../Container';
import { LinkTarget } from '../../declarations/models/LinkExternal';
import { LinkType } from '../../declarations/models/LinkType';
import TextInput from '../forms/TextInput';
import SelectInput from '../forms/SelectInput';
import { BlockPath } from '../../editor/PageEditor/CurrentBlockProvider';

export interface LinkListItemContentFormProps {
  linkType?: LinkType;
  index: number;
  onChangeFile: (index: number) => void;
  showLinkDescriptionField: boolean;
  showCSSclassField?: boolean;
  itemsArrayName: string;
  initialAnchorValue: string;
  blockPath: BlockPath | `${BlockPath}.${string}.${number}`;
}

const TARGET: Array<LinkTarget> = [
  LinkTarget.STANDARD,
  LinkTarget.SAME_TAB,
  LinkTarget.NEW_TAB,
  LinkTarget.NEW_COMMON_TAB,
];

export const LinkListItemContentForm: FC<LinkListItemContentFormProps> = ({
  linkType,
  index,
  onChangeFile,
  showLinkDescriptionField,
  showCSSclassField,
  itemsArrayName,
  initialAnchorValue,
  blockPath,
}) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [anchor, setAnchor] = useState<string>(initialAnchorValue);

  return (
    <Container fullWidth gap={2} column top left py={1}>
      {linkType === LinkType.DOCUMENT && (
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => onChangeFile(index)}
          aria-label={tComp('LinkListItem.ChangeFile')}
          sx={{ flex: '1 0 auto' }}>
          {tComp('LinkListItem.ChangeFile')}
        </Button>
      )}
      {linkType && [LinkType.DOCUMENT, LinkType.PAGE].includes(linkType) && (
        <TextInput
          label={tCommon('title')}
          fullWidth
          path={`${blockPath}.${itemsArrayName}.${index}.local.title`}
          defaultValue=''
        />
      )}
      {linkType === LinkType.PAGE && (
        <TextInput
          type='text'
          label={tComp('LinkListItem.Anchor')}
          fullWidth
          path={`${blockPath}.${itemsArrayName}.${index}.local.anchor`}
          defaultValue=''
          handleOnChange={setAnchor}
          InputProps={{
            startAdornment: anchor ? <Typography color={Styles.Colors.MEDIUM_GREY}>#</Typography> : null,
          }}
        />
      )}
      {linkType === LinkType.LINK && (
        <>
          <TextInput
            label={tCommon('title')}
            fullWidth
            path={`${blockPath}.${itemsArrayName}.${index}.title`}
            defaultValue=''
          />
          <TextInput
            label={tComp('LinkListItem.Url')}
            fullWidth
            path={`${blockPath}.${itemsArrayName}.${index}.url`}
            defaultValue=''
          />
          <TextInput
            type='text'
            label={tComp('LinkListItem.Anchor')}
            fullWidth
            path={`${blockPath}.${itemsArrayName}.${index}.anchor`}
            defaultValue=''
            handleOnChange={setAnchor}
            InputProps={{
              startAdornment: anchor ? <Typography color={Styles.Colors.MEDIUM_GREY}>#</Typography> : null,
            }}
          />
        </>
      )}
      {linkType === LinkType.LINK && (
        <SelectInput
          defaultValue={LinkTarget.STANDARD}
          label={tComp('LinkListItem.OpeningRule')}
          getOptionKey={(option) => option}
          getOptionLabel={(target) => tComp(`LinkListItem.Target.${target}`)}
          path={`${blockPath}.${itemsArrayName}.${index}.target`}
          options={TARGET}
        />
      )}

      {(linkType === LinkType.DOCUMENT || linkType === LinkType.PAGE) && (
        <SelectInput
          defaultValue={LinkTarget.STANDARD}
          label={tComp('LinkListItem.OpeningRule')}
          getOptionKey={(option) => option}
          getOptionLabel={(target) => tComp(`LinkListItem.Target.${target}`)}
          path={`${blockPath}.${itemsArrayName}.${index}.local.target`}
          options={TARGET}
        />
      )}
      {showCSSclassField && (
        <TextInput path={`${blockPath}.${itemsArrayName}.${index}.cssClass`} label={tCommon('cssClass')} fullWidth />
      )}

      {showLinkDescriptionField && (
        <TextInput
          path={`${blockPath}.${itemsArrayName}.${index}.local.description`}
          label={tCommon('description')}
          multiline
          fullWidth
          variant='filled'
        />
      )}
    </Container>
  );
};

export default LinkListItemContentForm;
