import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { PageVersion } from '@/declarations/models/PageVersion';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useStore } from '@/components/store/Store';
import SplitButton from '../../../components/SplitButton';
import { SaveType } from '../declarations/SaveType';
import { useEditorSubmitHandler } from '../BaseEditor';
import { EditorContext } from '../declarations/EditorContext';
import { useEditorModel } from './EditorDataProvider';
import { Page } from '../../../declarations/models/Page';
import { Status } from '../../../declarations/models/Status';
import { useEditorConfiguration } from '../configuration/EditorConfigurationContext';
import { usePageEditorFormState } from '../../PageEditor/PageEditorFormStateProvider';

const getItemIcon = (saveType: SaveType) => {
  if (saveType === SaveType.DELETE_DRAFT) {
    return <MaterialSymbol name='delete' fill color='error' />;
  }
};

export interface EditorSaveButtonProps {
  options?: Array<SaveType>;
  pageVersions?: Array<PageVersion>;
}
export const PageEditorSaveButton: FC<EditorSaveButtonProps> = ({ options, pageVersions }) => {
  const { t: tCommon } = useTranslation('common');
  const {
    formState: { isValid, isValidating },
  } = useFormContext();
  const { state } = useStore();

  const model = useEditorModel();
  const { context } = useEditorConfiguration();
  const submitForm = useEditorSubmitHandler();
  const { isSubmitting } = usePageEditorFormState();
  const [disabled, setDisabled] = useState<boolean>(true);
  const isFrontpage = model?.id === state.selectedSiteDefaultPageId;

  const selectedVersion = pageVersions?.find((v) => v.id === (model as Page)?.active_version_id);
  const hasDraftVersion = pageVersions?.some((v) => v.status === Status.DRAFT);

  const isSaveTypeDisabled = (saveType: SaveType): boolean => {
    if (!isValid || isValidating || isSubmitting || context !== EditorContext.PAGE) {
      return true;
    }
    const pageStatus = (model as Page)?.status || Status.DRAFT;
    const versionStatus = selectedVersion?.status || Status.DRAFT;
    switch (saveType) {
      // case SaveType.SAVE_AND_ARCHIVE:
      //   return status === Status.ARCHIVED;
      case SaveType.SAVE_AND_PUBLISH:
        return pageStatus === Status.PUBLISHED && versionStatus === Status.PUBLISHED;
      case SaveType.SAVE_AND_UNPUBLISH:
        return isFrontpage || pageStatus !== Status.PUBLISHED || versionStatus !== Status.PUBLISHED;
      case SaveType.SAVE_AND_ARCHIVE:
        return pageStatus === Status.ARCHIVED;
      case SaveType.SAVE_AS_NEW_VERSION:
        return pageStatus !== Status.PUBLISHED || !!hasDraftVersion;
      default:
        return false;
    }
  };

  const canDeleteDraft = useMemo(() => {
    return !!hasDraftVersion && selectedVersion?.status === Status.DRAFT;
  }, [hasDraftVersion, selectedVersion]);

  const disableSaveButton = (saveType?: SaveType) => {
    setDisabled(true);
    setTimeout(async () => {
      await submitForm(saveType || SaveType.SAVE);
    }, 0);
  };

  useEffect(() => {
    setDisabled(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    if (!isSubmitting) {
      setDisabled(false);
    }
  }, [isSubmitting, isValid]);

  const defaultSaveOptions = [
    SaveType.SAVE_AND_PUBLISH,
    SaveType.SAVE_AND_UNPUBLISH,
    SaveType.SAVE_AND_ARCHIVE,
    SaveType.SAVE_AS_NEW_VERSION,
    SaveType.SAVE_AS_NEW_TEMPLATE,
  ];

  return (
    <>
      {(!options || options?.length > 0) && (
        <SplitButton
          variant='contained'
          color='secondary'
          disabled={disabled}
          label={tCommon(`SaveType.${SaveType.SAVE}`)}
          onClick={() => disableSaveButton()}
          options={(options || defaultSaveOptions).concat(canDeleteDraft ? [SaveType.DELETE_DRAFT] : [])}
          getItemLabel={(option: SaveType) => tCommon(`SaveType.${option}`)}
          getItemDisabled={isSaveTypeDisabled}
          getItemIcon={getItemIcon}
          onItemClick={(saveType) => disableSaveButton(saveType)}
          sx={{
            height: '33px',
          }}
        />
      )}
      {options && options?.length === 0 && (
        <Button variant='contained' color='secondary' disabled={disabled} onClick={() => disableSaveButton()}>
          {tCommon(`SaveType.${SaveType.SAVE}`)}
        </Button>
      )}
    </>
  );
};

export default PageEditorSaveButton;
