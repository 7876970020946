import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Box, Button, Dialog } from '@mui/material';
import Container from '@/components/Container';
import { useTranslation } from 'react-i18next';

export interface PromptDialogOptions {
  title?: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  renderConfirmButton?: (onClick: () => void) => React.ReactNode;
  renderCancelButton?: (onClick: () => void) => React.ReactNode;
}

interface ConfirmFunc {
  (message: React.ReactNode | string, options?: PromptDialogOptions): Promise<boolean>;
}
interface OnConfirmFunc {
  (choice: boolean): void;
}

const ConfirmDialog = createContext<ConfirmFunc>(() => Promise.resolve(false));

const MessageWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Container p={2} mt={2}>
    {children}
  </Container>
);

export const ConfirmDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');

  const defaultOpts: PromptDialogOptions = useMemo(
    () => ({
      title: undefined,
      confirmLabel: t('ok'),
      cancelLabel: t('cancel'),
    }),
    [t],
  );

  const fn = useRef<OnConfirmFunc>();
  const message = useRef<Parameters<ConfirmFunc>[0]>();
  const options = useRef<Parameters<ConfirmFunc>[1]>(defaultOpts);

  const confirm: ConfirmFunc = useCallback(
    (msg, opts) => {
      return new Promise((resolve: OnConfirmFunc) => {
        fn.current = (choice) => {
          resolve(choice);
          setOpen(false);
        };
        if (typeof msg === 'string') {
          message.current = <MessageWrapper>{msg}</MessageWrapper>;
        } else {
          message.current = msg;
        }
        options.current = { ...defaultOpts, ...opts };

        setOpen(true);
      });
    },
    [defaultOpts],
  );

  return (
    <>
      <ConfirmDialog.Provider value={confirm}>
        {children}
        <Dialog open={open}>
          {options.current?.title && <Container>{options.current!.title}</Container>}
          <Container
            column
            p={0}
            sx={{
              maxWidth: '1200px',
            }}>
            {message.current}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                mx: 2,
                mb: 2,
                p: 1,
                gap: 2,
              }}>
              {options.current?.renderConfirmButton ? (
                options.current!.renderConfirmButton(() => fn.current!(true))
              ) : (
                <Button color='success' variant='outlined' onClick={() => fn.current!(true)}>
                  {options.current!.confirmLabel}
                </Button>
              )}
              {options.current?.renderCancelButton ? (
                options.current!.renderCancelButton(() => fn.current!(false))
              ) : (
                <Button onClick={() => fn.current!(false)}>{options.current!.cancelLabel}</Button>
              )}
            </Box>
          </Container>
        </Dialog>
      </ConfirmDialog.Provider>
    </>
  );
};

export default function useConfirmDialog() {
  return useContext<ConfirmFunc>(ConfirmDialog);
}
