import React from 'react';
import { useController } from 'react-hook-form';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { Page } from '../../../declarations/models/Page';
import DirectionSelector from '../../lib/components/settings/DirectionSelector';
import { useCurrentSection } from '../CurrentSectionProvider';

export const PageSectionDirectionSettings: SettingsComponent = () => {
  const { sectionPath } = useCurrentSection();

  const {
    field: { value: direction, onChange: setDirection },
  } = useController<Page, `${typeof sectionPath}.direction`>({ name: `${sectionPath}.direction` });

  return <DirectionSelector direction={direction || null} onDirectionChange={(dir) => setDirection(dir || '')} />;
};

export default PageSectionDirectionSettings;
