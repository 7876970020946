import React, { FC, useState } from 'react';
import { useStore } from '@/components/store/Store';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import FullScreenModal from '@/components/FullScreenModal';
import CardEditor from '@/editor/CardEditor/CardEditor';
import { Button, Typography } from '@mui/material';
import Container from '../../../../components/Container';
import Image from '../../../../components/Image';
import { isM24MediaModel } from '../../../../utils/typeChecks';
import { ChildrenProp } from '../../../../declarations/ChildrenProp';
import { Card } from '../../../../declarations/models/Card';
import { Person } from '../../../../declarations/models/Person';
import { useEditorData, useEditorModel } from '../../../lib/components/EditorDataProvider';

export interface CardModuleBlockItemContentProps {
  item: Card | Person;
}

export const CardModuleBlockItemContent: FC<CardModuleBlockItemContentProps & ChildrenProp> = ({ children, item }) => {
  const altText = isM24MediaModel(item.content?.mediaobject) ? item?.content?.mediaobject?.content?.alttext : '';
  const { siteId, modelId, skins, pageLocale, categories } = useEditorData();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [changed, setChanged] = useState('');
  const {
    state: { currentUser },
  } = useStore();
  const isSuperAdmin = currentUser?.user?.is_super_admin;
  return (
    <Container p={1} pt={0} fullWidth>
      <Container gap={2} top spaceBetween>
        <Container column sx={{ maxWidth: '200px' }} left fullWidth>
          <Image
            src={item.content?.mediaobject?.url || item.mediaobject?.url}
            title={item.title}
            alt={altText}
            maxWidth='200px'
          />
        </Container>
      </Container>
      <Container m={2} column fullWidth right>
        {children}

        {item.site_id === siteId && isSuperAdmin && (
          <>
            <Button
              style={{ backgroundColor: '#fbe6ff' }}
              variant='outlined'
              size='small'
              startIcon={<MaterialSymbol name='edit' fill sx={{ fontSize: 'small' }} />}
              onClick={() => {
                setEditModalOpen(true);
              }}>
              SuperAdmin: Endre kort (globalt) {changed}
            </Button>
            <FullScreenModal modalOpen={editModalOpen}>
              <CardEditor
                siteId={siteId}
                cardId={item.id}
                onCloseEditor={() => setEditModalOpen(false)}
                skins={skins}
                categories={categories}
                onAfterSave={() => {
                  setChanged(' - ENDRET');
                }}
                defaultLocale={item.locale}
              />
            </FullScreenModal>
          </>
        )}
      </Container>
    </Container>
  );
};

export default CardModuleBlockItemContent;
