import React, { FC, useEffect, useState } from 'react';
import { Button, ClickAwayListener, Fade, Modal, Paper, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleMapMarker } from '../declarations/GoogleMapMarker';
import Container from './Container';
import GoogleMap from './GoogleMap';
import GoogleMapsAutoComplete, { PlaceType } from './GoogleMapsAutoComplete';

export interface GoogleMapModalProps {
  open: boolean;
  onClose: () => void;
  onSelectConfirmed: (marker: GoogleMapMarker, address?: string) => void;
  sx?: SxProps;
  initialMarker?: GoogleMapMarker;
  zoom: number;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
}

export const GoogleMapModal: FC<GoogleMapModalProps> = ({
  initialMarker,
  zoom,
  defaultCenter,
  open,
  onClose,
  onSelectConfirmed,
  sx,
}) => {
  const { t: tCommon } = useTranslation('common');
  const [selectedLocation, setSelectedLocation] = useState<GoogleMapMarker>();
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [center, setCenter] = useState<{ lat: number; lng: number } | undefined>(defaultCenter);
  const googleMapId = 'google-map-in-modal';

  const handleOnSelectAddress = (pos: {
    placeType: PlaceType;
    location: { lat: number; lng: number };
    address: string;
  }) => {
    setSelectedAddress(pos.address);
    setSelectedLocation({ position: pos.location });
    setCenter(pos.location);
  };

  useEffect(() => {
    if (initialMarker) {
      setSelectedLocation(initialMarker);
    }
  }, [initialMarker]);

  return (
    <Modal open={open} onClose={onClose} sx={sx || {}}>
      <Fade in={open}>
        <Container p={4} fullWidth fullHeight>
          <ClickAwayListener onClickAway={() => onClose?.()} mouseEvent='onMouseUp'>
            <Container fullHeight fullWidth>
              <Paper sx={{ width: '100%', height: '100%', position: 'relative' }}>
                <Container right column gap={2} fullWidth fullHeight p={2}>
                  <Container fullWidth spaceBetween>
                    <GoogleMapsAutoComplete onSelect={handleOnSelectAddress} googleMapId={googleMapId} />
                    <Container>
                      <Button
                        disabled={!selectedLocation}
                        variant='contained'
                        color='success'
                        onClick={() => {
                          if (selectedLocation) onSelectConfirmed(selectedLocation, selectedAddress);
                        }}>
                        {tCommon('save')}
                      </Button>
                      <Button variant='contained' onClick={() => onClose?.()}>
                        {tCommon('cancel')}
                      </Button>
                    </Container>
                  </Container>
                  <GoogleMap
                    zoom={zoom}
                    center={center}
                    selectedLocation={selectedLocation}
                    selectable
                    onSelect={(marker) => {
                      setSelectedLocation(marker);
                    }}
                    id={googleMapId}
                  />
                </Container>
              </Paper>
            </Container>
          </ClickAwayListener>
        </Container>
      </Fade>
    </Modal>
  );
};

export default GoogleMapModal;
