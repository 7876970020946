/**
 * Settings. Provides easy access to ENV-variables and aliases for easier access.
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
export abstract class Settings {
  private static readonly DEFAULT_PROFILE_PATH: string = '#profile';

  /**
   * The host of the Admin-API to use.
   */
  public static readonly ADMIN_API_HOST: string = String(import.meta.env.VITE_ADMIN_API_HOST) || '';

  /**
   * The host of the Admin-API to use.
   */
  public static readonly PORTAL_HOST: string = String(import.meta.env.VITE_PORTAL_HOST) || '';

  /**
   * The key used to store/retrieve the users token in LocalStorage
   */
  public static readonly TOKEN_LOCAL_STORAGE_KEY: string = String(import.meta.env.VITE_TOKEN_LOCAL_STORAGE_KEY) || '';

  /**
   * The key used to store/retrieve the users id token in LocalStorage
   */
  public static readonly ID_TOKEN_LOCAL_STORAGE_KEY: string =
    String(import.meta.env.VITE_ID_TOKEN_LOCAL_STORAGE_KEY) || '';

  /**
   * The eKultur application id to use for login. Can be found in the eKultur admin.
   */
  public static readonly EKULTUR_APPLICATION_ID: string = String(
    import.meta.env.VITE_EKULTUR_APPLICATION_ID || '7675bc66-af7e-4030-a1b9-f6bb956e6474',
  );

  /**
   * The URL to the eKultur-host to authenticate against
   */
  public static readonly EKULTUR_LOGIN_HOST: string = String(import.meta.env.VITE_EKULTUR_LOGIN_HOST) || '';

  /**
   * The path to redirect the user to when clicking it's avatar or name
   */
  public static readonly EKULTUR_PROFILE_PATH: string =
    String(import.meta.env.VITE_EKULTUR_PROFILE_PATH) || Settings.DEFAULT_PROFILE_PATH;

  /**
   * The gateway to use for login against eKultur
   */
  public static readonly EKULTUR_LOGIN_GATEWAY: string = String(import.meta.env.VITE_EKULTUR_LOGIN_GATEWAY) || '';

  /**
   * To use "same_site" on cookies when logging in or not
   */
  public static readonly EKULTUR_SAME_SITE = String(import.meta.env.VITE_EKULTUR_SAME_SITE) === 'true';

  /**
   * The path (relative to origin) to redirect back to when login is successful.
   * (This is the endpoint where the code and state will be swapped for a token)
   */
  public static readonly EKULTUR_LOGIN_CALLBACK_PATH: string =
    String(import.meta.env.VITE_EKULTUR_LOGIN_CALLBACK_PATH) || '';

  /**
   * The same DM-host the backend is set to upload media to. Used to load media from DM.
   */
  public static readonly DM_HOST: string = String(import.meta.env.VITE_DM_HOST) || '';

  /**
   * Returns true if running in a development environment.
   * E.g. a development server or non-optimized build intended for development.
   */
  public static get envIsDevelopment(): boolean {
    return import.meta.env.MODE === 'development';
  }

  /**
   * Returns true if running in a test environment.
   * E.g. running all tests locally or in CI
   */
  public static get envIsTest(): boolean {
    return import.meta.env.MODE === 'test';
  }

  /**
   * Returns true if running in a production environment.
   * E.g. When serving the result of a production build
   */
  public static get envIsProduction(): boolean {
    return import.meta.env.MODE === 'production';
  }

  /**
   * Run this to make sure the settings are correct.
   */
  public static validateSettings(): void {
    const err = (variableName: string, msg?: string): never => {
      throw new Error(
        `[ERROR][Settings] Environment variable "${variableName}" not set: ${msg}. Fix this issue and restart the server to load changes.`,
      );
    };
    const warn = (variableName: string, msg: string): void => {
      // eslint-disable-next-line no-console
      console.warn(`[WARNING][Settings] Environment variable "${variableName}": ${msg}`);
    };
    if (!import.meta.env.MODE) {
      err(
        'MODE | NODE_ENV',
        'This error should theoretically not happen. Have you forgotten to build using --mode, or not provided a NODE_ENV?',
      );
    }
    if (!Settings.ADMIN_API_HOST) {
      err('VITE_ADMIN_API_HOST', 'Must be a valid URL');
    }
    if (!Settings.PORTAL_HOST) {
      err('VITE_PORTAL_HOST', 'Must be a valid URL');
    }
    if (!Settings.TOKEN_LOCAL_STORAGE_KEY) {
      err('VITE_TOKEN_LOCAL_STORAGE_KEY');
    } else if (Settings.TOKEN_LOCAL_STORAGE_KEY !== 'kit.access_token') {
      warn(
        'VITE_TOKEN_LOCAL_STORAGE_KEY',
        'Specifying another key than "kit.access_token" may result in forcing users to log in again.',
      );
    }
    if (!Settings.EKULTUR_LOGIN_HOST) {
      err('VITE_EKULTUR_LOGIN_HOST');
    }
    if (!Settings.EKULTUR_PROFILE_PATH) {
      warn('VITE_EKULTUR_PROFILE_PATH', `Not provided and will fallback to: ${Settings.DEFAULT_PROFILE_PATH}`);
    }
    if (!Settings.EKULTUR_LOGIN_GATEWAY) {
      err('VITE_EKULTUR_LOGIN_GATEWAY');
    }
    if (!Settings.EKULTUR_LOGIN_CALLBACK_PATH) {
      err('VITE_EKULTUR_LOGIN_CALLBACK_PATH');
    } else if (Settings.EKULTUR_LOGIN_CALLBACK_PATH !== '/oauth2/callback') {
      warn(
        'VITE_EKULTUR_LOGIN_CALLBACK_PATH',
        'Paths other that the standard "/oauth2/callback" is discouraged, and must be explicitly whitelisted by eKultur.',
      );
    }
    if (!Settings.DM_HOST) {
      err('VITE_DM_HOST', 'The DM-host must be provided to load media and previews.');
    }
    if (!Settings.EKULTUR_APPLICATION_ID) {
      err('VITE_EKULTUR_APPLICATION_ID', 'The eKultur application id must be provided.');
    }
  }
}
