import React, { FC, useState } from 'react';
import { Alert, Button, ButtonBase, Checkbox, Collapse, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';
import EditSelectedMedia, { EditSelectedMediaProps } from '../EditSelectedMedia';
import { GetImage } from '../MediaViewTypes/GetImage';
import { iconButtonStyle } from '../MediaViewTypes/GridViewItem';

interface EditMediaStageProps extends EditSelectedMediaProps {
  uploadsFailed: number;
  uploadedMediaItems: Array<M24MediaModel>;
  handleSelectItem: (item: M24MediaModel) => void;
}

export const EditMediaStage: FC<EditMediaStageProps> = ({
  uploadsFailed,
  uploadedMediaItems,
  selectedItems,
  handleSelectItem,
  ...rest
}) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const [alertOpen, setAlertOpen] = useState<boolean>(true);
  const uploaded = uploadedMediaItems.length;

  return (
    <Container fullWidth fullHeight spaceBetween top gap={3}>
      <Container fullWidth column left>
        <Container left fullWidth>
          <Collapse in={alertOpen}>
            {uploadsFailed > 0 ? (
              <Alert onClose={() => setAlertOpen(false)} variant='filled' severity='warning'>
                {t('MediaView.MediaUpload.UploadsFailed', {
                  failed: uploadsFailed,
                  total: uploadsFailed + uploaded,
                })}
              </Alert>
            ) : (
              <Alert onClose={() => setAlertOpen(false)} variant='filled'>
                {uploaded === 1
                  ? t('MediaView.MediaUpload.OneUploaded')
                  : t('MediaView.MediaUpload.FilesUploaded', { files: uploaded })}
              </Alert>
            )}
          </Collapse>
          <Container>
            <Button
              onClick={() => {
                uploadedMediaItems
                  .filter((item) => !selectedItems.some((selectedItem) => selectedItem.id === item.id))
                  .forEach((item) => handleSelectItem(item));
              }}>
              {tCommon('selectAll')}
            </Button>
          </Container>
        </Container>
        <Container top left p={1.5} gap={1.5} sx={{ flexFlow: 'row wrap !important' }}>
          {uploadedMediaItems.map((item) => {
            const isSelected = selectedItems.some((i) => i.id === item.id);
            return (
              <Container
                key={item.id}
                column
                sx={{
                  border: '3px solid transparent',
                  outline: isSelected ? `4px solid ${Styles.Colors.MEDIA_ITEM_SELECTED_COLOR}` : '1px solid',
                  borderRadius: Styles.Dimensions.RADIUS_ROUND_SM,
                  transition: 'all 150ms ease',
                  width: '206px',
                  background: Styles.Colors.LIGHT_GREY,
                }}>
                <Container fullWidth gap={0} bottom left>
                  <Container top left column sx={{ position: 'relative' }}>
                    <ButtonBase onClick={() => handleSelectItem(item)}>
                      <GetImage item={item} height={200} width={200} />
                    </ButtonBase>
                    <Typography fontSize={12}>{item.filename}</Typography>
                    <Checkbox
                      checked={isSelected}
                      color='success'
                      sx={{ ...iconButtonStyle, padding: 0, borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}
                      onClick={() => handleSelectItem(item)}
                    />
                  </Container>
                </Container>
              </Container>
            );
          })}
        </Container>
      </Container>
      <EditSelectedMedia selectedItems={selectedItems} {...rest} />
    </Container>
  );
};

export default EditMediaStage;
