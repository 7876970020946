import React, { FC } from 'react';
import { noop } from '@/utils/functions';
import { useTranslation } from 'react-i18next';
import { EditorSectionActionButtonProps } from '../../editor/lib/components/EditorSectionActionButton';
import Container from '../Container';
import DeleteButton from '../DeleteButton';
import { MaterialSymbol } from '../MaterialSymbol';

export type LinkListItemActionsProps = Pick<EditorSectionActionButtonProps, 'onDelete'>;

export const LinkListItemActions: FC<LinkListItemActionsProps> = ({ onDelete }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Container right>
      <DeleteButton
        onConfirm={onDelete ?? noop}
        component='IconButton'
        tooltip={tCommon('remove')}
        deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
      />
    </Container>
  );
};

export default LinkListItemActions;
