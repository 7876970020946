/* istanbul ignore file */

export enum BlockModuleLayout {
  AUTO = 'auto',
  PORTRAIT = 'img-portrait',
  LANDSCAPE = 'img-landscape',
  LIST = 'list',
  LEFT = 'left',
  RIGHT = 'right',
}
