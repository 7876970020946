import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { useController, useWatch } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import TextInput from '../../../../components/forms/TextInput';
import SwitchInput from '../../../../components/forms/SwitchInput';
import { TableBlock as TableBlockModel } from '../../../../declarations/models/blocks/TableBlock';
import TableBlockRowItem from './TableBlockRowItem';
import TableBlockTextInput from './TableBlockTextInput';
import { BlockModuleType } from '../../../../declarations/models/BlockModuleType';
import SelectInput from '../../../../components/forms/SelectInput';
import { BlockModuleState } from '../../../../declarations/models/BlockModuleState';

export const TableBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);

  const {
    fields: items,
    append,
    replace,
    // move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<TableBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  const {
    field: { value: columns, onChange: setColumns },
  } = useController<BlockSpecificPage<TableBlockModel>, `${BlockPath}.columns`>({ name: `${blockPath}.columns` });

  const moduleType = useWatch<BlockSpecificPage<TableBlockModel>, `${BlockPath}.module__type`>({
    name: `${blockPath}.module__type`,
  });

  const handleAddRow = () => {
    append({
      type: 'row',
      ...Array.from({ length: 5 }, (_, i) => i).reduce((acc: { [key: number]: string }, key) => {
        acc[key] = '';
        return acc;
      }, {}),
    });
  };

  return (
    <Container gap={2} column top left fullWidth>
      <Container column fullWidth>
        {items?.map((row, i) => (
          // TODO: add draggable?
          <TableBlockRowItem
            // eslint-disable-next-line react/no-array-index-key
            key={`row-${i}`}
            index={i}
            onRemove={remove}
            columns={columns}
          />
        ))}
      </Container>
      <Container fullWidth column>
        <Container fullWidth spaceBetween>
          <TextInput
            type='number'
            label={t('TableBlock.NrOfColumns')}
            size='small'
            path={`${blockPath}.columns`}
            inputProps={{ min: '0' }}
            fullWidth={false}
          />
          <Button
            sx={{
              typography: 'subtitle1',
              fontWeight: 'bold',
            }}
            startIcon={<MaterialSymbol name='add' />}
            onClick={handleAddRow}>
            {t('TableBlock.AddRow')}
          </Button>
        </Container>
        <Container fullWidth left wrap>
          <SwitchInput path={`${blockPath}.firstasheader`} label={t('TableBlock.RowAsHeader')} fullWidth={false} />
          <SwitchInput path={`${blockPath}.firstColAsHeader`} label={t('TableBlock.ColAsHeader')} fullWidth={false} />
          {moduleType === BlockModuleType.MODULE && (
            <Container>
              <SelectInput
                fullWidth={false}
                size='small'
                path={`${blockPath}.state`}
                label={t('TableBlock.InitialStatePlaceholder')}
                getOptionKey={(option) => option}
                getOptionLabel={(option) => t(`TableBlock.DisplayState.${option}`)}
                options={[BlockModuleState.COLLAPSED, BlockModuleState.EXPANDED]}
                hideNoSelectionOption
                defaultValue={BlockModuleState.EXPANDED}
              />
              <Typography>{t('TableBlock.SelectInitialState')}</Typography>
            </Container>
          )}
        </Container>

        <Container fullWidth column>
          <Accordion
            expanded={collapseOpen}
            onChange={() => setCollapseOpen((o) => !o)}
            elevation={0}
            disableGutters
            sx={{
              width: '100%',
            }}>
            <AccordionSummary
              sx={{
                flexDirection: 'row-reverse',
              }}
              expandIcon={
                <MaterialSymbol name='chevron_right' sx={collapseOpen ? { transform: 'rotate(270deg)' } : undefined} />
              }>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  px: 2,
                  '&, &:hover': {
                    textDecoration: 'underline',
                  },
                }}>
                {collapseOpen ? t('TableBlock.HideInputField') : t('TableBlock.ShowInputField')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableBlockTextInput onParseText={replace} setColumns={setColumns} isEmpty={!items?.length} />
            </AccordionDetails>
          </Accordion>
        </Container>
      </Container>
    </Container>
  );
};

export default TableBlock;
