import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import DateTimeInput from '@/components/forms/DateTimeInput';
import TextInput from '@/components/forms/TextInput';
import Container from '@/components/Container';
import DeleteButton from '@/components/DeleteButton';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WeekdaySelector } from '@/editor/PageEditor/WeekdaySelector';

export type EventDataTimePeriodProps = {
  index: number;
  onRemove?: () => void;
};

type EventDataTimePeriodPath = `event_data.time_periods.${number}`;

export const EventDataTimePeriod: FC<EventDataTimePeriodProps> = ({ index, onRemove }) => {
  const { t: tComponents } = useTranslation('components');
  const itemPathPrefix: EventDataTimePeriodPath = `event_data.time_periods.${index}`;
  const {
    field: { value: startTime, onChange: onStartTimeChange },
  } = useController({
    name: `${itemPathPrefix}.start_time`,
  });
  const {
    field: { value: endTime, onChange: onEndTimeChange },
  } = useController({
    name: `${itemPathPrefix}.end_time`,
  });
  const {
    field: { value: endDate, onChange: onEndDateChange },
  } = useController({
    name: `${itemPathPrefix}.end_date`,
  });

  const {
    field: { value: weekdays, onChange: onChangeWeekdays },
  } = useController({
    name: `${itemPathPrefix}.week_days`,
  });

  const [showEndDate, setShowEndDate] = React.useState<boolean>(endDate != null);
  const [showTimes, setShowTimes] = React.useState<boolean>(true);

  return (
    <Container column left fullWidth gap={2}>
      <Container fullWidth left>
        <Container
          left
          sx={{
            flex: 1,
          }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showEndDate}
                onChange={(e) => {
                  if (!e.target.checked) {
                    onEndDateChange(null);
                  }
                  setShowEndDate(e.target.checked);
                }}
              />
            }
            label={tComponents('EventData.UseEndDateLabel')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showTimes}
                onChange={(e) => {
                  if (!e.target.checked) {
                    onStartTimeChange(null);
                    onEndTimeChange(null);
                  }
                  setShowTimes(e.target.checked);
                }}
              />
            }
            label={tComponents('EventData.UseTimeLabel')}
          />
        </Container>

        <DeleteButton onConfirm={() => onRemove?.()} />
      </Container>
      <Container fullWidth left gap={2} wrap>
        <DateTimeInput
          defaultValue={null}
          inputType='date'
          path={`${itemPathPrefix}.start_date`}
          label={tComponents('EventData.StartTime')}
        />
        {showEndDate && (
          <DateTimeInput
            defaultValue={null}
            inputType='date'
            path={`${itemPathPrefix}.end_date`}
            label={tComponents('EventData.EndTime')}
          />
        )}
        {showTimes && (
          <>
            <DateTimeInput defaultValue={null} inputType='time' path={`${itemPathPrefix}.start_time`} label='' />
            <DateTimeInput defaultValue={null} inputType='time' path={`${itemPathPrefix}.end_time`} label='' />
          </>
        )}
        <TextInput
          fullWidth={false}
          defaultValue=''
          path={`${itemPathPrefix}.free_text`}
          label={tComponents('EventData.FreeText')}
        />
        {showEndDate && (
          <WeekdaySelector label={tComponents('EventData.Weekdays')} value={weekdays} onChange={onChangeWeekdays} />
        )}
      </Container>
    </Container>
  );
};
