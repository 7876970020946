import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import { styled, SxProps, Theme, useTheme } from '@mui/material';

export interface MaterialSymbolProps extends HTMLAttributes<HTMLSpanElement> {
  name: string;
  sharp?: boolean;
  fill?: boolean;
  color?: string;
  sx?: SxProps<Theme>;
}

const StyledSpan = styled('span')({});

const MaterialSymbol = forwardRef<HTMLSpanElement, MaterialSymbolProps>(
  ({ name, fill, color, sharp, ...rest }, ref) => {
    const theme = useTheme();
    const paletteColor = color ? theme.palette[color as keyof typeof theme.palette] : undefined;
    let fontVariationSettings = '';
    if (fill) {
      fontVariationSettings = '"FILL" 1 ';
    }
    const style: CSSProperties = {
      fontVariationSettings,
      color: paletteColor && typeof paletteColor === 'object' && 'main' in paletteColor ? paletteColor.main : color,
      ...rest.style,
    };
    return (
      <StyledSpan
        {...rest}
        style={{ ...style, ...rest.style }}
        ref={ref}
        className={`material-symbols-${sharp ? 'sharp' : 'outlined'} ${rest.className ?? ''}`}
        aria-hidden
        data-testid={`MaterialSymbol-${name}${fill ? '-fill' : ''}`}>
        {name}
      </StyledSpan>
    );
  },
);

MaterialSymbol.displayName = 'MaterialSymbol';

export { MaterialSymbol };
