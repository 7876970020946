import React, { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import Container, { ContainerProps } from '../Container';
import { toggleItem } from '../../utils/array';
import {
  EditorEventType,
  EventManagerApi,
  useEditorEventHandler,
} from '../../editor/lib/eventManager/EditorEventManager';

export interface SectionsControllerProps extends ContainerProps {
  onlyOneSectionExpandedAtATime?: boolean;
  defaultExpanded?: Array<string>;
  nestedController?: boolean;
  eventManager?: EventManagerApi;
}

interface SectionsControllerApi {
  toggleExpand: (sectionId: string) => void;
  isExpanded: (sectionId: string) => boolean;
  isNestedSection: boolean;
  parentSectionController: SectionsControllerApi | null;
}

const SectionsControllerContext = createContext<SectionsControllerApi | null>(null);

export function useSectionsController(): SectionsControllerApi | null {
  return useContext(SectionsControllerContext);
}

export const SectionsController: FC<SectionsControllerProps> = ({
  children,
  defaultExpanded,
  onlyOneSectionExpandedAtATime = true,
  nestedController = false,
  eventManager,
  ...containerProps
}) => {
  const [expanded, setExpanded] = useState<Array<string>>(defaultExpanded || []);

  const parentSectionController = useSectionsController();
  const toggleExpand = useCallback<SectionsControllerApi['toggleExpand']>(
    (sectionId: string) => {
      return setExpanded((prevExpanded) => {
        if (!prevExpanded.includes(sectionId) && eventManager) {
          eventManager.fireEvent(EditorEventType.SECTION_EXPANDED, { anchorId: sectionId });
        }
        if (onlyOneSectionExpandedAtATime) {
          return prevExpanded.includes(sectionId) ? [] : [sectionId];
        }
        return toggleItem(prevExpanded, sectionId);
      });
    },
    [eventManager, onlyOneSectionExpandedAtATime],
  );

  const closeAllSiblings = (sectionPath?: string) => {
    const sectionPaths = sectionPath?.split('.') ?? [];
    sectionPaths.pop();
    const parentSection = sectionPaths.join('.');
    if (parentSection) {
      setExpanded((prevExpanded) => {
        return prevExpanded.filter((section) => !section.startsWith(parentSection));
      });
    }
  };

  useEditorEventHandler(async (event) => {
    if (
      event.payload.anchorId &&
      [EditorEventType.SECTION_REORDERED, EditorEventType.BLOCK_PASTED].includes(event.type)
    ) {
      closeAllSiblings(event.payload.anchorId as string);
    }
  });

  const isExpanded = useCallback<SectionsControllerApi['isExpanded']>(
    (sectionId) => {
      return expanded.includes(sectionId);
    },
    [expanded],
  );

  const context = useMemo<SectionsControllerApi>(
    () => ({
      isExpanded,
      toggleExpand,
      isNestedSection: nestedController,
      parentSectionController,
    }),
    [isExpanded, nestedController, toggleExpand, parentSectionController],
  );

  return (
    <SectionsControllerContext.Provider value={context}>
      <Container gap={0} p={0} column top fullWidth {...containerProps} wrap={false}>
        {children}
      </Container>
    </SectionsControllerContext.Provider>
  );
};

export default SectionsController;
