import React, { FC } from 'react';
import { Divider } from '@mui/material';
import Container from '../../../components/Container';
import GridViewItem from './GridViewItem';
import { CommonMediaViewProps } from '../MediaViewList';
import { EditState } from '../MediaView';

export const GridView: FC<CommonMediaViewProps> = ({ items, editState, getIsSelected, onItemClick, onDeleteItem }) => {
  return (
    <>
      <Divider flexItem variant='middle' />
      <Container top left p='13px' gap={1.25} sx={{ flexFlow: 'row wrap !important' }}>
        {items.map((item) => {
          const isSelected = editState !== EditState.SINGLE && !!getIsSelected?.(item);

          return (
            <GridViewItem
              key={item.id}
              item={item}
              onItemClick={() => onItemClick(item)}
              editState={editState}
              isSelected={isSelected}
              onDeleteItem={onDeleteItem}
            />
          );
        })}
      </Container>
    </>
  );
};

export default GridView;
