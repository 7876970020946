import React from 'react';
import { CommonMediaList } from '@/editor/PageEditor/EditorBlock/MediaBlock/CommonMediaList';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { useStore } from '../../../../components/store/Store';

export const MediaBlock: BlockComponent = () => {
  const { blockPath } = useCurrentBlock();
  const { state } = useStore();
  const siteId = state.selectedSite?.id;

  return <CommonMediaList blockPath={`${blockPath}`} siteId={siteId} />;
};

export default MediaBlock;
