import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Paper } from '@mui/material';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CheckboxInput from '@/components/forms/CheckboxInput';
import BannerTitle from './BannerBlockTitle/BannerTitle';
import Container from '../../../../components/Container';
import Styles from '../../../../assets/js/Styles';
import { BannerBlockMediaBackground } from './BannerBlockMediaBackground';
import BannerBlockTextEditor from './BannerBlockTextEditor';
import BannerBlockTextEffects from './BannerBlockTextEffects';
import { BannerBlockAdditionalImages } from './BannerBlockAdditionalImages';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BannerBlock as BannerBlockModel } from '../../../../declarations/models/blocks/BannerBlock';
import Links from '../../../../components/Links/Links';
import { LinkStyle } from '../../../../declarations/models/LinkStyle';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';
import { BannerBlockAdvancedSettings } from './BannerBlockAdvancedSettings';

export const BannerBlock: FC = () => {
  const { blockPath } = useCurrentBlock();
  const eventManager = useEditorEventManager();
  const { t: tComponents } = useTranslation('components');
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState<boolean>(false);

  const {
    fields: linkItems,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<BannerBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  const mediaObject = useWatch<BlockSpecificPage<BannerBlockModel>, `${BlockPath}.mediaobject`>({
    name: `${blockPath}.mediaobject`,
  });

  const {
    field: { value: useTextbackgound },
  } = useController<BlockSpecificPage<BannerBlockModel>, `${typeof blockPath}.isTransparent`>({
    name: `${blockPath}.isTransparent`,
  });

  return (
    <Container fullWidth column gap={2}>
      <BannerBlockMediaBackground />
      {mediaObject?.type !== 'video' && <BannerBlockAdditionalImages />}
      <BannerTitle />
      <BannerBlockTextEditor />
      <Links
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        linkItems={linkItems}
        linkStyleTypes={[LinkStyle.BUTTONS, LinkStyle.TEXT_LINKS, LinkStyle.CLICKABLE_MODULE]}
        blockPath={blockPath}
        useEventHandler={useEditorEventHandler}
        useEventManager={eventManager}
      />
      <Container fullWidth left>
        <Accordion
          expanded={advancedSettingsOpen}
          onChange={() => setAdvancedSettingsOpen((o) => !o)}
          elevation={0}
          disableGutters
          sx={{
            width: '100%',
          }}>
          <AccordionSummary
            sx={{
              flexDirection: 'row-reverse',
            }}
            expandIcon={
              <MaterialSymbol
                name='chevron_right'
                sx={advancedSettingsOpen ? { transform: 'rotate(270deg)' } : undefined}
              />
            }>
            <Typography
              sx={{
                fontWeight: 'bold',
                px: 2,
                '&, &:hover': {
                  textDecoration: 'underline',
                },
              }}>
              {tComponents('BannerBlock.MoreSettingsLabel')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Paper>
              <Container
                sx={{ backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR }}
                p={2}
                mt={2}
                mb={4}
                gap={2}
                column
                top
                left
                fullWidth>
                <CheckboxInput
                  fullWidth={false}
                  path={`${blockPath}.nextButton`}
                  label={tComponents('BannerBlock.NextButtonLabel')}
                />
                <CheckboxInput
                  fullWidth={false}
                  path={`${blockPath}.isTransparent`}
                  label={tComponents('BannerBlock.transparent')}
                />
                <BannerBlockAdvancedSettings />
                <BannerBlockTextEffects />
              </Container>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Container>
  );
};

export default BannerBlock;
