import React, { ReactElement, SyntheticEvent, useMemo, useRef, useState } from 'react';
import { Button, ButtonProps, CircularProgress, Menu, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from './Container';

export interface SearchSelectorProps<T> extends Pick<Partial<ButtonProps>, 'color' | 'variant' | 'sx'> {
  id: string;
  label: string;
  value: T | null;
  options: Array<T>;
  onChange: (newValue: T | null) => void;
  getOptionLabel: (option: T | null) => string;
  getSelectedOptionLabel?: (option: T | null) => string;
  getOptionKey: (option: T | null) => string | number;
  required?: boolean;
  searchable?: boolean;
  disabled?: boolean;
}

const ELEMENT_HEIGHT_PX = 32;
const SEARCH_BOX_HEIGHT_PX = 64;
const MAX_NUM_ELEMENTS_TO_SHOW = 5.5;

export function SearchSelector<T>({
  id,
  label,
  value,
  onChange,
  options,
  getOptionLabel,
  getSelectedOptionLabel,
  getOptionKey,
  sx,
  variant = 'outlined',
  color = 'primary',
  required,
  searchable,
  disabled,
}: SearchSelectorProps<T>): ReactElement | null {
  const { t: tARIA } = useTranslation('aria');
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const getOptionLabelRef = useRef(getOptionLabel);
  getOptionLabelRef.current = getOptionLabel;
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const availableOptions = useMemo<Array<T>>(() => {
    if (!searchValue) {
      return options;
    }
    const sv = searchValue.toLowerCase();
    return options.filter((opt) => getOptionLabelRef.current(opt).toLowerCase().includes(sv));
  }, [searchValue, options]);

  const toggleOpen = () => setIsOpen((o) => !o);
  const close = () => setIsOpen(false);

  const stopPropagation = (e: SyntheticEvent) => e.stopPropagation();

  const handleSearchValueChanged = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
  };

  const handleSelectItem = (item: T | null) => () => {
    onChange(item);
    close();
  };

  if (!value && !options.length) {
    return <CircularProgress color={color} variant='indeterminate' size={25} />;
  }

  return (
    <>
      <Button
        variant={variant}
        color={color}
        sx={sx}
        ref={buttonRef}
        id={id}
        aria-controls={`${id}-items`}
        aria-label={label}
        aria-haspopup='true'
        onClick={toggleOpen}
        disabled={disabled ?? !options.length}
        startIcon={!options.length && <CircularProgress color={color} variant='indeterminate' size={15} />}
        endIcon={isOpen ? <MaterialSymbol name='arrow_drop_up' /> : <MaterialSymbol name='arrow_drop_down' />}>
        {getSelectedOptionLabel ? getSelectedOptionLabel(value) : getOptionLabel(value)}
      </Button>
      <Menu
        id={`${id}-items`}
        anchorEl={buttonRef.current}
        open={isOpen}
        onClose={close}
        PaperProps={{
          style: { maxHeight: (searchable ? SEARCH_BOX_HEIGHT_PX : 0) + MAX_NUM_ELEMENTS_TO_SHOW * ELEMENT_HEIGHT_PX },
        }}>
        {searchable && (
          <Container
            p='8px 16px'
            sx={{ position: 'sticky', backgroundColor: 'background.paper', top: 0, zIndex: 1 }}
            fullWidth>
            <TextField
              aria-label={tARIA('components.SearchSelector.SearchFieldLabel')}
              placeholder={tCommon('search')}
              value={searchValue}
              onChange={(e) => handleSearchValueChanged(e.target.value)}
              onKeyDown={stopPropagation}
              onKeyPress={stopPropagation}
              onKeyUp={stopPropagation}
              autoFocus
              size='small'
              fullWidth
              InputProps={{
                startAdornment: <MaterialSymbol name='search' />,
              }}
            />
          </Container>
        )}
        {searchable && !!searchValue && !availableOptions?.length && (
          <MenuItem key='no-hit' disabled dense>
            {tComponents('SearchSelector.noMatch')}
          </MenuItem>
        )}
        {!required && (
          <MenuItem
            key={getOptionKey(null)}
            selected={value === null}
            disabled={value === null}
            onClick={handleSelectItem(null)}
            dense>
            {getOptionLabel(null)}
          </MenuItem>
        )}
        {availableOptions.map((option) => {
          const optionSelected = getOptionKey(option) === getOptionKey(value);
          return (
            <MenuItem
              key={getOptionKey(option)}
              selected={optionSelected}
              disabled={optionSelected}
              onClick={handleSelectItem(option)}
              dense>
              {getOptionLabel(option)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default SearchSelector;
