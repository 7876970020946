import React, { FC, ImgHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { MediaLicense } from '../declarations/models/MediaLicense';
import BY from '../assets/images/svg/cc/by.svg';
import BY_NC from '../assets/images/svg/cc/by-nc.svg';
import BY_NC_ND from '../assets/images/svg/cc/by-nc-nd.svg';
import BY_NC_SA from '../assets/images/svg/cc/by-nc-sa.svg';
import BY_ND from '../assets/images/svg/cc/by-nd.svg';
import BY_SA from '../assets/images/svg/cc/by-sa.svg';
import PDM from '../assets/images/svg/cc/pdm.svg';
import Container, { ContainerProps } from './Container';

export interface CCIconProps extends ImgHTMLAttributes<HTMLImageElement>, Pick<ContainerProps, 'sx'> {
  license: MediaLicense;
  hideDescription?: boolean;
}

export const CCIcon: FC<CCIconProps> = ({ license, hideDescription = false, sx, ...imgProps }) => {
  const { t } = useTranslation('common');
  let src: typeof BY | null = null;
  switch (license) {
    case MediaLicense.CC_BY:
      src = BY;
      break;
    case MediaLicense.CC_BY_SA:
      src = BY_SA;
      break;
    case MediaLicense.CC_BY_ND:
      src = BY_ND;
      break;
    case MediaLicense.CC_BY_NC:
      src = BY_NC;
      break;
    case MediaLicense.CC_BY_NC_SA:
      src = BY_NC_SA;
      break;
    case MediaLicense.CC_BY_NC_ND:
      src = BY_NC_ND;
      break;
    case MediaLicense.CC_PDM:
      src = PDM;
      break;
  }

  const label = t(`MediaLicense.Label.${license}`);
  const description = t(`MediaLicense.Description.${license}`);

  return (
    <Container sx={sx} spaceBetween>
      <Tooltip title={label} placement='top' arrow disableInteractive>
        {!src || license === MediaLicense.COPYRIGHT ? (
          <MaterialSymbol name='copyright' sx={{ fontSize: '42px' }} />
        ) : (
          <img src={src} loading='lazy' alt={label} {...imgProps} />
        )}
      </Tooltip>
      {!hideDescription && (
        <Tooltip title={description} placement='top' arrow disableInteractive>
          <MaterialSymbol name='info' color='info' />
        </Tooltip>
      )}
    </Container>
  );
};

export default CCIcon;
