import React from 'react';
import { FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, Switch } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';

export interface SwitchInputProps<T>
  extends Omit<FormInputBaseProps<T>, 'min' | 'max' | 'minLength' | 'maxLength'>,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  /**
   * Enable this to make the checkbox appear checked when the value is false.
   * Useful to make labels more user-friendly without changing the data.
   */
  invert?: boolean;
}

export default function SwitchInput<T extends FieldValues>({
  labelPlacement,
  invert = false,
  ...baseProps
}: SwitchInputProps<T>) {
  const { field, disabled, label, color, fullWidth, required, isError, errorMessage } = useFormInput<T>(baseProps);
  const { value, onChange, onBlur, name, ref } = field;

  return (
    <FormControl color={color} fullWidth={fullWidth} required={required} disabled={disabled} error={isError}>
      <FormControlLabel
        control={
          <Switch
            ref={ref}
            checked={invert ? !value : !!value}
            onChange={(_, checked) => onChange(invert ? !checked : checked)}
            onBlur={onBlur}
            name={name}
            color={color}
          />
        }
        label={required ? `${label} *` : label}
        labelPlacement={labelPlacement}
      />
      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
