import i18n from 'i18next';

const dateOpts = { month: 'short', year: 'numeric', day: 'numeric' } as const;
const timeOpts = { hour: 'numeric', minute: 'numeric' } as const;

/**
 * Takes a valid timestamp from the DB (E.g. "20211220-112849-315210") and converts it to a Date-object.
 * @param timestamp The timestamp to convert
 * @return The created Date-instance or null, if the timestamp is invalid
 */
export function apiTimestampToDate(timestamp?: string): Date | null {
  if (!timestamp || !timestamp.match(/^\d{8}-\d{6}-\d{6}$/)) return null;

  const [date, time, microseconds] = timestamp.split('-');
  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(4, 6)) - 1;
  const day = Number(date.substring(6, 8));
  const hour = Number(time.substring(0, 2));
  const minute = Number(time.substring(2, 4));
  const second = Number(time.substring(4, 6));

  return new Date(year, month, day, hour, minute, second, Number(microseconds) / 1000);
}

/**
 * Converts a Date to the datetime-format the API expects
 * @param date
 */
export function dateToApiTimestamp(date: Date | string | number): string | null {
  const dateInstance = new Date(date);
  if (dateInstance.toString() === 'Invalid Date') {
    return null;
  }
  const year = dateInstance.getFullYear();
  const month = String(dateInstance.getMonth() + 1).padStart(2, '0');
  const day = String(dateInstance.getDate()).padStart(2, '0');
  const hour = String(dateInstance.getHours()).padStart(2, '0');
  const minute = String(dateInstance.getMinutes()).padStart(2, '0');
  const second = String(dateInstance.getSeconds()).padStart(2, '0');
  const millisecond = String(dateInstance.getMilliseconds()).padEnd(6, '0');
  return `${year}${month}${day}-${hour}${minute}${second}-${millisecond}`;
}

/**
 * Get the mode (date, time or datetime) for displaying date, based on the actual timestamp.
 *
 * - <code>time</code> is used when sometime "today"
 * - <code>datetime</code> is used when sometime during the last week, or in the future
 * - <code>date</code> is used when over one week ago
 *
 * @param thenDate
 */
export function getTimestampMode(thenDate?: Date): 'date' | 'datetime' | 'time' {
  const now = new Date();
  const then = thenDate || now;
  const diff = now.getTime() - then.getTime();
  if (diff > 7 * 24 * 60 * 60 * 1000) {
    // over one week ago
    return 'date';
  }
  if (
    now.getUTCFullYear() === then.getUTCFullYear() &&
    now.getUTCMonth() === then.getUTCMonth() &&
    now.getUTCDate() === then.getUTCDate()
  ) {
    // Sometime today
    // return 'datetime';
    return 'datetime';
  }
  return 'datetime';
}

/**
 * Takes a timestamp from the DB (E.g. "20211220-112849-315210") and turns it into a human-readable format
 * @param timestamp The timestamp to parse
 * @param format What format the result should be in
 * @return The parsed timestamp or null if the timestamp is invalid
 */
export function formatAPITimestamp(
  timestamp?: string,
  format: 'auto' | 'date' | 'time' | 'datetime' = 'auto',
): string | null {
  const d = apiTimestampToDate(timestamp);
  if (!d) return null;
  const mode = format === 'auto' ? getTimestampMode(d) : format;
  if (mode === 'date') return d.toLocaleDateString(i18n.language, dateOpts);
  if (mode === 'time') return d.toLocaleTimeString(i18n.language, timeOpts);
  return d.toLocaleString(i18n.language, { ...dateOpts, ...timeOpts });
}

/**
 *
 * @param timestamp
 */
export function formatISOString(timestamp?: string): string | null {
  if (!timestamp) return null;
  const [date, t] = timestamp.split('T');
  const [year, month, day] = date.split('-');
  const [time, timeZone] = t.split('+');
  const [hour, minute, second] = time.split(':');
  const dateObj = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));
  const mode = getTimestampMode(dateObj);
  if (mode === 'date') return dateObj.toLocaleDateString(i18n.language, dateOpts);
  if (mode === 'time') return dateObj.toLocaleTimeString(i18n.language, timeOpts);
  return dateObj.toLocaleString(i18n.language, { ...dateOpts, ...timeOpts });
}
