import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExternalMediaModel, isDiMuMediaModel } from '../../../../../declarations/models/ExternalMediaModel';
import Container from '../../../../../components/Container';
import HeaderThumbnail from './HeaderThumbnail';
import HeaderTitle from './HeaderTitle';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface DMHeaderProps {
  open: boolean;
  item: ExternalMediaModel;
}

export const DMHeader: FC<DMHeaderProps & ChildrenProp> = ({ children, item, open = false }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  if (!isDiMuMediaModel(item)) {
    console.error('DMHeader used with incompatible type.');
    return null;
  }
  const itemIsVisible = item.visibility !== false;
  const title =
    item.local?.title ||
    item.item?.['artifact.ingress.title'] ||
    item.id ||
    item.dimuId ||
    item.dmsId ||
    item.objectId ||
    '';
  const owner = item.item?.['identifier.owner'] || '';
  const license = (item.item?.['artifact.ingress.license'] || [])
    .map((l) => tCommon(`MediaLicense.Label.${l}`))
    .join(', ');

  const renderProperty = (label: string, value: string, wrap = true) => (
    <Container sx={{ px: 1 }} top left>
      <Typography variant='caption' fontWeight='bold'>
        {label}
      </Typography>
      <Typography variant='caption' textAlign='left' noWrap={!wrap}>
        {value}
      </Typography>
    </Container>
  );

  return (
    <Container
      sx={{ color: itemIsVisible ? 'text.primary' : 'text.disabled', transition: 'color 150ms ease' }}
      left
      fullWidth>
      <HeaderThumbnail item={item} hidden={open} alt={title} disabled={!itemIsVisible} />
      <Container column left fullWidth>
        <HeaderTitle title={title} disabled={!itemIsVisible} />
        <Container gap={0} top left fullWidth wrap>
          {renderProperty(tComponents('DMHeader.OwnerLabel'), owner, false)}
          {renderProperty(tComponents('DMHeader.LicenseLabel'), license)}
        </Container>
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default DMHeader;
