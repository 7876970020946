import { ActionType } from '../declarations/ActionType';
import { Site } from '../declarations/models/Site';
import { hasProps } from './object';
import { Page } from '../declarations/models/Page';
import { MediaResourceType } from '../declarations/models/MediaResourceType';
import { M24MediaModel } from '../declarations/models/M24MediaModel';
import { DMMediaModel } from '../declarations/models/DMMediaModel';
import { ExternalMediaModel } from '../declarations/models/ExternalMediaModel';

/**
 * Check whether a value is an Action type or not
 * @param value
 */
export function isActionType(value: unknown): value is ActionType {
  return Object.entries(ActionType).some(([enumKey, enumValue]) => enumKey === value || enumValue === value);
}

/**
 * Check whether a value is a valid Site
 * @param value
 */
export function isSite(value: unknown): value is Site {
  const keys: Array<keyof Site> = ['default_language', 'name', 'status', 'owner_id'];
  return hasProps(value, ...keys);
}

/**
 * Check whether a value is a valid Page
 * @param value
 */
export function isPage(value: unknown): value is Page {
  const keys: Array<keyof Page> = ['title', 'status', 'site_id', 'locale'];
  return hasProps(value, ...keys);
}

export function isM24MediaModel(model: unknown): model is M24MediaModel {
  return (
    !!model &&
    typeof model === 'object' &&
    !Array.isArray(model) &&
    'type' in model &&
    Object.values(MediaResourceType).includes((model as M24MediaModel).type)
  );
}

export function isDMMediaModel(model: unknown): model is DMMediaModel {
  return (
    !!model &&
    typeof model === 'object' &&
    !Array.isArray(model) &&
    Object.keys(model).every((key) => key.startsWith('artifact.') || key.startsWith('identifier.'))
  );
}

export function isExternalMediaModel(model: unknown): model is ExternalMediaModel {
  return !isM24MediaModel(model) && !isDMMediaModel(model);
}
