import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { IconButton, ListItemText, ListSubheader, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../components/Container';
import { Credit } from '../../../declarations/models/M24MediaModel';
import { MediaLicense } from '../../../declarations/models/MediaLicense';
import { CreditType } from '../../../declarations/CreditType';
import { inputGroupStyling } from './EditMediaTextFields';

export interface EditMediaLicenseCreditsFieldsProps {
  credits?: Array<Credit>;
  setCredits: (credits: Array<Credit>) => void;
  license?: MediaLicense;
  setLicense: (license: MediaLicense) => void;
}

export const licenseMenuProps = {
  MenuListProps: {
    style: {
      maxHeight: '50vh',
    },
  },
};

export const EditMediaLicenseCreditFields: FC<EditMediaLicenseCreditsFieldsProps> = ({
  credits = [],
  setCredits,
  license = MediaLicense.NONE,
  setLicense,
}) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const [creditType, setCreditType] = useState<string>('');
  const [creditName, setCreditName] = useState<string>('');

  const handleAddCredit = () => {
    setCredits([...credits, { type: creditType, name: creditName }]);
  };

  useEffect(() => {
    if (!Array.isArray(credits) || credits.length === 0) {
      setCredits([{ type: creditType, name: creditName }]);
      setCreditName('');
    }
  }, [credits]);

  const editCreditName = (index: number, value: string) => {
    const updatedCredits = [...credits]; // Create a copy of the array
    updatedCredits[index] = { ...updatedCredits[index], name: value }; // Update the value at the specified index
    setCredits(updatedCredits);
  };

  const editCreditType = (index: number, value: string) => {
    const updatedCredits = [...credits]; // Create a copy of the array
    updatedCredits[index] = { ...updatedCredits[index], type: value }; // Update the value at the specified index
    setCredits(updatedCredits);
  };

  const handleRemoveCredit = (index: number) => {
    setCredits([...credits.slice(0, index), ...credits.slice(index + 1)]);
  };

  const handleChangeLicense = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setLicense(val as MediaLicense);
  };

  const getMenuItem = (lic: MediaLicense) => (
    <MenuItem value={lic} sx={{ paddingLeft: 4, gap: 1 }}>
      <ListItemText>{tCommon(`MediaLicense.Label.${lic}`)}</ListItemText>
      <Tooltip title={tCommon(`MediaLicense.Description.${lic}`)}>
        <MaterialSymbol name='help' fill sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
      </Tooltip>
    </MenuItem>
  );

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{tComp('MediaEditor.LicenseAndCredits')}</Typography>
      <Container mb={1} fullWidth>
        <TextField
          id='cc-select'
          size='small'
          defaultValue=''
          color='secondary'
          fullWidth
          value={license}
          SelectProps={{
            MenuProps: licenseMenuProps,
            renderValue: (v) => tCommon(`MediaLicense.Label.${v}`),
          }}
          label={tComp('MediaEditor.LicenseType')}
          onChange={handleChangeLicense}
          select>
          <MenuItem value={MediaLicense.NONE} sx={{ paddingLeft: 4 }}>
            {tCommon('noneSelected')}
          </MenuItem>
          <ListSubheader>{tCommon('MediaLicense.FreeReuse')}</ListSubheader>
          {getMenuItem(MediaLicense.CC_PDM)}
          {getMenuItem(MediaLicense.CC_BY)}
          {getMenuItem(MediaLicense.CC_BY_SA)}
          <ListSubheader>{tCommon('MediaLicense.RestrictedReuse')}</ListSubheader>
          {getMenuItem(MediaLicense.CC_BY_NC)}
          {getMenuItem(MediaLicense.CC_BY_NC_SA)}
          {getMenuItem(MediaLicense.CC_BY_NC_ND)}
          <ListSubheader>{tCommon('MediaLicense.NoReuse')}</ListSubheader>
          {getMenuItem(MediaLicense.COPYRIGHT)}
        </TextField>
      </Container>

      <Container column fullWidth gap={2}>
        {Array.isArray(credits) &&
          credits.length > 0 &&
          credits.map((credit, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Container key={`${i}-${credit.type}`} fullWidth spaceBetween>
              <TextField
                size='small'
                fullWidth
                color='secondary'
                select
                label={tComp('MediaEditor.CreditType')}
                onChange={(e) => editCreditType(i, e.target.value)}
                value={credit.type}>
                {Object.values(CreditType).map((c) => (
                  <MenuItem key={c} value={c}>
                    {tComp(`MediaView.CreditTypes.${c}`)}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                size='small'
                fullWidth
                value={credit.name}
                onChange={(e) => editCreditName(i, e.target.value)}
                label={tComp('MediaEditor.CreditName')}
              />

              <IconButton size='small' onClick={() => handleRemoveCredit(i)}>
                <MaterialSymbol name='delete' fill />
              </IconButton>

              <IconButton color='success' onClick={handleAddCredit}>
                <MaterialSymbol name='add' />
              </IconButton>
            </Container>
          ))}
      </Container>
    </Container>
  );
};

export default EditMediaLicenseCreditFields;
