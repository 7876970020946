import React, { FC } from 'react';
import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SubCategory } from '../../../../declarations/Category';

interface SiteSharingBlockSettingsProps {
  subCategories?: Array<SubCategory>;
  setSubCategories?: (v: Array<SubCategory>) => void;
  subOptions: Array<SubCategory>;
}

export const PageAutoSubCategoriesSelect: FC<SiteSharingBlockSettingsProps> = ({
  subCategories = [],
  setSubCategories,
  subOptions,
}) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const handleChangeSubCategories = (v: string | SubCategory[]) => {
    if (Array.isArray(v)) setSubCategories?.(v);
  };

  return (
    <FormControl fullWidth size='small'>
      <InputLabel>{tComp('PageListAutoBlock.Categories.SubLabel')}</InputLabel>
      <Select
        value={subCategories}
        multiple
        onChange={(e) => handleChangeSubCategories(e.target.value)}
        renderValue={(v) =>
          Array.isArray(v)
            ? v.map((c) => (
                <Chip
                  variant='outlined'
                  color='secondary'
                  size='small'
                  key={c}
                  label={tCommon(`SubCategory.${c}`)}
                  sx={{ marginRight: 1 }}
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={() => setSubCategories?.(subCategories.filter((sc) => sc !== c))}
                />
              ))
            : v
        }>
        {subOptions.map((o) => (
          <MenuItem key={o} value={o}>
            {tCommon(`SubCategory.${o}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PageAutoSubCategoriesSelect;
