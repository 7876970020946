import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { deepCopy } from '@/utils/object';

export interface BlockClipboard {
  value: BaseBlock; // or Block?
  blockId: string; // path
}

export interface BlockClipboardContextValue {
  currentClipboard: BlockClipboard | null;
  currentCutBlockId: string | null;
  copy: (blockId: string, value: BaseBlock) => void;
  cut: (blockId: string, value: BaseBlock, onAfterPaste?: () => void) => void;
  paste: () => BaseBlock | undefined;
}

export const BlockClipboardContext = createContext<BlockClipboardContextValue | null>(null);

export function useBlockClipboardContext(): BlockClipboardContextValue {
  const value = useContext(BlockClipboardContext);
  if (!value) {
    throw new Error('BlockClipboardContext accessed before init');
  }
  return value;
}

export function useBlockClipboard() {
  const [currentClipboardItem, setCurrentClipboardItem] = useState<BlockClipboard | null>(null);
  const [currentCutBlockId, setCurrentCutBlockId] = useState<string | null>(null);
  const onPasteAfterCutRef = useRef<() => void>();
  const copy = useCallback<BlockClipboardContextValue['copy']>((blockId, value) => {
    setCurrentClipboardItem({ blockId, value });
  }, []);
  const cut = useCallback<BlockClipboardContextValue['cut']>((blockId, value, onAfterPaste) => {
    setCurrentClipboardItem({ blockId, value });
    setCurrentCutBlockId(blockId);
    onPasteAfterCutRef.current = onAfterPaste;
  }, []);
  const paste = useCallback<BlockClipboardContextValue['paste']>(() => {
    setCurrentCutBlockId(null);
    if (onPasteAfterCutRef.current) {
      onPasteAfterCutRef.current();
      onPasteAfterCutRef.current = undefined;
    }
    if (currentClipboardItem) {
      return deepCopy(currentClipboardItem.value);
    }
    return undefined;
  }, [currentClipboardItem]);
  const blockClipboardContextValue = useMemo<BlockClipboardContextValue>(
    () => ({
      currentClipboard: currentClipboardItem,
      currentCutBlockId,
      copy,
      cut,
      paste,
    }),
    [currentClipboardItem, currentCutBlockId, copy, cut, paste],
  );

  return blockClipboardContextValue;
}
