import React from 'react';
import { useController } from 'react-hook-form';
import { SectionWidth } from '../../../declarations/models/Section';
import { SectionType } from '../../../declarations/models/SectionType';
import { Page } from '../../../declarations/models/Page';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import WidthSelector from '../../lib/components/settings/WidthSelector';
import { useCurrentSection } from '../CurrentSectionProvider';

const COMMON_OPTIONS: Array<SectionWidth> = ['section__grid', 'wide__grid'];

const INTROSECTION_OPTIONS: Array<SectionWidth> = ['full__grid', 'section__grid', 'wide__grid'];

export const PageSectionWidthSettings: SettingsComponent = () => {
  const { sectionType, sectionPath, section } = useCurrentSection();
  const isIntrosection = !!section?.introSection;

  const {
    field: { value: width, onChange: setWidth },
  } = useController<Page, `${typeof sectionPath}.width`>({ name: `${sectionPath}.width` });

  let availableOptions: Array<SectionWidth> = COMMON_OPTIONS;

  if (sectionType === SectionType.PAGE_GRID) {
    availableOptions = [...COMMON_OPTIONS, 'article__grid'];
  } else if (sectionType === SectionType.GRID_ROW) {
    availableOptions = [...COMMON_OPTIONS, 'full__grid'];
  } else if (sectionType === SectionType.ARTICLE_BODY && isIntrosection) {
    availableOptions = [...INTROSECTION_OPTIONS];
  } else if (sectionType === SectionType.ARTICLE_BODY && !isIntrosection) {
    return null;
  }

  return (
    <WidthSelector availableOptions={availableOptions} width={width || null} onWidthChange={(w) => setWidth(w || '')} />
  );
};

export default PageSectionWidthSettings;
