import React, { FC } from 'react';
import { Breadcrumbs, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import Styles from '../../../../assets/js/Styles';
import DeleteButton from '../../../../components/DeleteButton';
import { usePageEditorContext } from '../../PageEditorContext';

interface PageListPageTreeSettingsProps {
  searchPathPageId?: number;
  setSearchPathPageId?: (v: number | null) => void;
  searchLevel?: 'siblings' | 'children';
  setSearchLevel?: (v: string) => void;
  openPageFinder: () => void;
}

export const PageListPageTreeSettings: FC<PageListPageTreeSettingsProps> = ({
  searchPathPageId,
  setSearchPathPageId,
  searchLevel,
  setSearchLevel,
  openPageFinder,
}) => {
  const { t: tComp } = useTranslation('components');
  const { selectionState, pages } = usePageEditorContext();

  return (
    <>
      <Container column fullWidth left gap={0}>
        <Typography variant='body2'>{tComp('PageListAutoBlock.SearchPath.Label')}</Typography>
        <Container
          p={1}
          fullWidth
          spaceBetween
          sx={{
            backgroundColor: Styles.Colors.THEME_BG_COLOR,
            borderRadius: Styles.Dimensions.NESTED_SECTION_BORDER_RADIUS,
          }}>
          <Container sx={{ width: '80%' }} left wrap>
            <Breadcrumbs
              aria-label={tComp('components.EditorBreadcrumbs.Description')}
              separator={<MaterialSymbol name='chevron_right' />}>
              {searchPathPageId &&
                selectionState?.getParents(searchPathPageId)?.map((breadcrumb) => (
                  <Typography key={breadcrumb.id} variant='body2' color='text.secondary'>
                    {breadcrumb.title}
                  </Typography>
                ))}
              <Typography color='text.primary'>
                {pages?.find((value) => value.id === searchPathPageId)?.title}
              </Typography>
            </Breadcrumbs>
            {!searchPathPageId && (
              <Typography sx={{ color: Styles.Colors.MEDIUM_GREY }}>
                {tComp('PageListAutoBlock.SearchPath.NoSelection')}
              </Typography>
            )}
          </Container>
          <Container gap={0} right>
            <Button onClick={openPageFinder}>
              <MaterialSymbol name='edit' fill />
            </Button>
            <DeleteButton onConfirm={() => setSearchPathPageId?.(null)} />
          </Container>
        </Container>
      </Container>
      <Container fullWidth left column gap={4}>
        <FormControl>
          <RadioGroup
            value={searchLevel}
            defaultValue='children'
            onChange={(e) => setSearchLevel?.(e.target.value || 'children')}
            row>
            <FormControlLabel
              value='children'
              control={<Radio color='secondary' />}
              label={`${tComp('PageListAutoBlock.SearchLevel.children')} (${
                searchPathPageId ? selectionState?.getChildren(searchPathPageId)?.length : '-'
              })`}
            />
            <FormControlLabel
              value='siblings'
              control={<Radio color='secondary' />}
              label={`${tComp('PageListAutoBlock.SearchLevel.siblings')} (${
                searchPathPageId ? selectionState?.getSiblings(searchPathPageId)?.length : '-'
              })`}
            />{' '}
          </RadioGroup>
        </FormControl>
      </Container>
    </>
  );
};

export default PageListPageTreeSettings;
