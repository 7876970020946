import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DMMediaModel } from '../../../declarations/models/DMMediaModel';
import Container from '../../Container';
import { GenericMedia } from '../../../declarations/GenericMedia';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';

export interface SelectedDMMediaItemProps {
  item: GenericMedia<DMMediaModel>;
  onRemoveItem: (item: GenericMedia<DMMediaModel>) => void;
}

export function renderKeyValuePair(key: string, value = ''): ReactNode {
  if (!value) return null;
  return (
    <Container top left fullWidth>
      <Typography fontWeight='bold'>{key}:</Typography>
      <Typography sx={{ wordBreak: 'break-word' }}>{value}</Typography>
    </Container>
  );
}

export const SelectedDMMediaItem: FC<SelectedDMMediaItemProps> = ({ item, onRemoveItem }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column spaceBetween left fullWidth fullHeight>
        <Typography
          sx={{
            wordBreak: 'break-word',
            fontWeight: 'bold',
            overflow: 'hidden',
          }}>
          {item.title}
        </Typography>
        <Container column bottom fullWidth>
          {renderKeyValuePair(tComponents('SelectedDMMediaItem.Owner'), item.source?.['identifier.owner'])}
          {renderKeyValuePair(
            tComponents('SelectedDMMediaItem.License'),
            item.source?.['artifact.ingress.license']
              ?.map((license) => tCommon(`MediaLicense.Label.${license}`))
              .join('; '),
          )}
        </Container>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedDMMediaItem;
