/* istanbul ignore file */
/**
 * Schema IDs for different Kulturio document types.
 * List and information about different schemas can be found at
 * https://admin.kulturio.org/admin/schema with super admin privileges.
 *
 * Other schemas are for Kulturio Pro exhibitions, such as Båthallen and Polar Bear.
 */
export enum KPSchemaId {
  KP_ARTICLE = 2,
  KP_PRESENTATION = 12,
  KP_KIOSK_VIEW = 13,
  KP_KIOSK_DEVICE = 14,
}
