import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Page } from '../../declarations/models/Page';
import { RecursiveSelectionState } from '../../hooks/useRecursiveSelectionState';

export interface PageEditorContextValue {
  pages?: Array<Page>;
  setPages: Dispatch<SetStateAction<Array<Page>>>;
  selectionState?: RecursiveSelectionState<Page>;
}
export const PageEditorContext = createContext<PageEditorContextValue | null>(null);

/**
 * A similar context to ContentView, but more lightweight and suited for PageEditor.
 *
 * Do not confuse this with useContentViewContext.
 */
export function usePageEditorContext(): PageEditorContextValue {
  const value = useContext(PageEditorContext);
  if (!value) {
    throw new Error('PageEditorContext accessed before init');
  }
  return value;
}
