import Container from '@/components/Container';
import React, { FC } from 'react';
import { Skin } from '@/declarations/models/Skin';
import Styles from '@/assets/js/Styles';

export const SkinSelectorIcon: FC<{ skin: Skin | null; disabled?: boolean }> = ({ skin, disabled }) => {
  return (
    <Container
      component='span'
      aria-hidden='true'
      sx={(theme) => ({
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
        border: `1px solid ${Styles.Colors.MEDIUM_DARK_GREY}`,
        typography: 'caption',
        color: skin?.txt || 'text.primary',
        backgroundColor: skin?.hex || 'background.paper',
        opacity: disabled ? '50%' : '100%',
      })}>
      Ab
    </Container>
  );
};
