import React, { FC } from 'react';
import Styles from '@/assets/js/Styles';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { HeaderMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/HeaderMenuBlock';
import LinkListItem from '../../../components/Links/LinkListItem';
import { LinkType } from '../../../declarations/models/LinkType';
import { Draggable } from '../../../declarations/Draggable';
import Links from '../../../components/Links/Links';

export interface LanguageMenuFocusMenuSettings {
  blockPath: BlockPath;
}

export const LanguageMenuFocusMenuSettings: FC<LanguageMenuFocusMenuSettings> = ({ blockPath }) => {
  const {
    fields: focusItems,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.focusitems`>({
    name: `${blockPath}.focusitems`,
  });

  return (
    <>
      {focusItems?.map((item, i) => (
        <LinkListItem
          key={`${item.id}`}
          linkType={item.type as LinkType}
          type={Draggable.OTHER}
          index={i}
          onReorder={move}
          onDelete={remove}
          itemsArrayName='focusitems'
          showLinkDescriptionField={false}
          showCSSclassField
          onChangeFile={() => null}
          blockPath={blockPath}
          iconOverride={'status' in item ? <PageStatusCircle status={item.status} size={20} /> : undefined}
          color={Styles.Colors.LIGHT_GREY}
        />
      ))}
      <Links
        onUpdate={update}
        onAppend={append}
        onRemove={remove}
        onReorder={move}
        showLinkItems={false}
        showLinkDescriptionField={false}
        showLinkStyleSelector={false}
        showListChildrenInput={false}
        linkItems={focusItems}
        linkTypes={[LinkType.PAGE, LinkType.LINK]}
        blockPath={blockPath}
      />
    </>
  );
};

export default LanguageMenuFocusMenuSettings;
