import React, { FC } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../components/Container';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import { Employee } from '../../declarations/models/Employee';
import Image from '../../components/Image';
import { editorStyling } from './EmployeeEditor';
import EmployeeInfo from '../../views/EmployeesView/EmployeesViewTypes/EmployeeInfo';
import Styles from '../../assets/js/Styles';

export interface EmployeeEditorPreviewProps {
  employee?: Partial<Employee>;
  employeeImage?: M24MediaModel;
}

export const EmployeeEditorPreview: FC<EmployeeEditorPreviewProps> = ({ employee, employeeImage }) => {
  let imgMimeType = '';
  if (employeeImage?.mimetype?.includes('png')) {
    imgMimeType = `&mediaType=${employeeImage?.mimetype}`;
  }

  return (
    <Container fullWidth fullHeight>
      <Container sx={{ ...editorStyling, maxWidth: '400px' }} column>
        {employeeImage?.url ? (
          <Image
            src={`${employeeImage?.url}?dimension=400x400&${imgMimeType}`}
            focusPoint={employee?.image_focus_point}
            sx={{
              width: 'min(100%, 400px)',
              objectFit: 'cover',
              overflow: 'hidden',
              aspectRatio: '1',
              borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            }}
          />
        ) : (
          <MaterialSymbol
            name='person'
            fill
            sx={{
              fontSize: '250px',
              padding: '0 20px',
              color: 'rgba(0, 0, 0, 0.54)',
              backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
              borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            }}
          />
        )}
        {employee && <EmployeeInfo employee={employee} />}
      </Container>
    </Container>
  );
};

export default EmployeeEditorPreview;
