import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import { BlockModuleLayout } from '../../../declarations/models/BlockModuleLayout';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';
import { BlockLayout } from '../../../declarations/models/BlockLayout';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';

const LAYOUT_OPTIONS: Array<BlockModuleLayout> = Object.values(BlockModuleLayout);
const LAYOUT_OPTIONS_ICONS: Record<BlockModuleLayout, ReactNode> = {
  [BlockModuleLayout.AUTO]: <MaterialSymbol name='view_day' />,
  [BlockModuleLayout.PORTRAIT]: <MaterialSymbol name='vertical_split' sx={{ transform: 'scaleX(-1)' }} />,
  [BlockModuleLayout.LANDSCAPE]: <MaterialSymbol name='horizontal_split' sx={{ transform: 'scaleY(-1)' }} />,
  [BlockModuleLayout.LIST]: <MaterialSymbol name='list' />,
  [BlockModuleLayout.LEFT]: <MaterialSymbol name='vertical_align_bottom' sx={{ transform: 'rotate(90deg)' }} />,
  [BlockModuleLayout.RIGHT]: <MaterialSymbol name='vertical_align_bottom' sx={{ transform: 'rotate(-90deg)' }} />,
};

export const PageBlockModuleLayoutSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<BaseBlock & { layout?: BlockLayout | BlockModuleLayout; module__type: BlockModuleType }>,
    `${typeof blockPath}.layout`
  >({
    name: `${blockPath}.layout`,
  });

  const currentBlock = block as BaseBlock & {
    layout?: BlockLayout | BlockModuleLayout;
    module__type: BlockModuleType;
  };

  useEffect(() => {
    if (
      value &&
      currentBlock.module__type === BlockModuleType.MODULE &&
      !Object.values(BlockModuleLayout).includes(value as BlockModuleLayout)
    ) {
      onChange(BlockModuleLayout.AUTO);
    }
  });

  return (
    <ActionMenu
      id='page-block-layout-selector'
      ariaLabel={tAria('components.PageBlockModuleLayoutSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockModuleLayoutSetting.Tooltip')}
      tooltipPlacement='top'
      icon={
        value && currentBlock.module__type === BlockModuleType.MODULE
          ? LAYOUT_OPTIONS_ICONS[value as BlockModuleLayout]
          : LAYOUT_OPTIONS_ICONS.auto
      }
      disabled={
        !currentBlock.module__type ||
        currentBlock.module__type === (BlockModuleType.BODY_TEXT || BlockModuleType.INLINE)
      }>
      {LAYOUT_OPTIONS.map((layoutOption) => (
        <MenuItem
          key={layoutOption}
          onClick={() => onChange(layoutOption)}
          selected={value === layoutOption}
          aria-label={tComponents(`PageBlockModuleLayoutSetting.options.${layoutOption}`)}>
          <ListItemIcon>{LAYOUT_OPTIONS_ICONS[layoutOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockModuleLayoutSetting.options.${layoutOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockModuleLayoutSetting;
