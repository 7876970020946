import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../../components/Container';
import { LinkTarget } from '../../../../declarations/models/LinkExternal';
import { BlockPath } from '../../CurrentBlockProvider';
import TextInput from '../../../../components/forms/TextInput';
import CheckboxInput from '../../../../components/forms/CheckboxInput';
import SelectInput from '../../../../components/forms/SelectInput';

export interface PageListManualBlockItemContentProps {
  index: number;
  blockPath: BlockPath;
  hideTitleInput?: boolean;
  overrideItemPath?: string;
  hideHighlightCheckbox?: boolean;
}

enum PageLinkTarget {
  STANDARD = 'auto',
  SAME_TAB = '_self',
  NEW_TAB = '_blank',
  NEW_COMMON_TAB = 'm24ref',
}

const TARGET: Array<PageLinkTarget> = [
  PageLinkTarget.STANDARD,
  PageLinkTarget.SAME_TAB,
  PageLinkTarget.NEW_TAB,
  PageLinkTarget.NEW_COMMON_TAB,
];

export const PageListManualBlockItemContent: FC<PageListManualBlockItemContentProps> = ({
  index,
  blockPath,
  hideTitleInput = false,
  hideHighlightCheckbox = false,
  overrideItemPath,
}) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const itemPath = overrideItemPath || `${blockPath}.items.${index}`;

  return (
    <Container column p={2} fullWidth>
      <Container fullWidth gap={2} column>
        {!hideTitleInput && (
          <>
            <TextInput
              label={tCommon('title')}
              fullWidth
              path={`${itemPath}.local.title`}
              defaultValue=''
              helperText={tComp('PageListManualBlock.PageListManualBlockItemContent.TitleHelperText')}
            />
            <TextInput
              label={tCommon('description')}
              fullWidth
              path={`${itemPath}.local.description`}
              defaultValue=''
              helperText={tComp('PageListManualBlock.PageListManualBlockItemContent.DescriptionHelperText')}
              multiline
            />
          </>
        )}
        <SelectInput
          defaultValue={LinkTarget.STANDARD}
          label={tComp('LinkListItem.OpeningRule')}
          getOptionKey={(option) => option}
          getOptionLabel={(target) => tComp(`LinkListItem.Target.${target}`)}
          path={`${itemPath}.local.target`}
          options={TARGET}
        />
        {!hideHighlightCheckbox && (
          <CheckboxInput
            path={`${itemPath}.local.highlight`}
            label={tComp(`PageListManualBlock.PageListManualBlockItemContent.Highlight`)}
          />
        )}
      </Container>
    </Container>
  );
};

export default PageListManualBlockItemContent;
