import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../../../CurrentBlockProvider';
import { BannerBlock } from '../../../../../declarations/models/blocks/BannerBlock';
import { BlockSpecificPage } from '../../../../lib/declarations/BlockSpecificPage';
import ActionMenuButton from '../../../../../components/ActionMenuButton';
import { Alignment, BannerTextAlignment } from '../../../../../declarations/models/Alignment';

const ALIGNMENT_OPTIONS: Array<BannerTextAlignment> = [
  Alignment.DEFAULT,
  Alignment.LEFT,
  Alignment.CENTER,
  Alignment.RIGHT,
];
const ALIGNMENT_ICONS: Record<BannerTextAlignment, ReactNode> = {
  [Alignment.DEFAULT]: <MaterialSymbol name='format_align_left' />,
  [Alignment.LEFT]: <MaterialSymbol name='format_align_left' />,
  [Alignment.CENTER]: <MaterialSymbol name='format_align_center' />,
  [Alignment.RIGHT]: <MaterialSymbol name='format_align_right' />,
};

export interface BannerBlockTitleAlignmentSelectorProps {
  width?: string;
}

export const BannerBlockTitleAlignmentSelector: FC<BannerBlockTitleAlignmentSelectorProps> = ({ width }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<BannerBlock & { textAlign?: BannerTextAlignment }>,
    `${typeof blockPath}.textAlign`
  >({
    name: `${blockPath}.textAlign`,
  });

  useEffect(() => {
    if (!value || !ALIGNMENT_OPTIONS.includes(value)) {
      onChange(Alignment.DEFAULT);
    }
  }, [onChange, value]);

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.BannerTitle.BannerBlockTitleAlignmentSelector.ActionMenuLabel')}
      tooltip={tComponents('BannerBlock.BannerTitle.BannerBlockTitleAlignmentSelector.tooltip')}
      tooltipPlacement='top'
      icon={value ? ALIGNMENT_ICONS[value] : ALIGNMENT_ICONS.center}
      width={width}>
      {ALIGNMENT_OPTIONS.map((alignmentOption) => (
        <MenuItem
          key={alignmentOption}
          onClick={() => onChange(alignmentOption)}
          selected={value === alignmentOption}
          aria-label={tComponents(`BannerBlock.BannerTitle.BannerBlockTitleAlignmentSelector.${alignmentOption}`)}>
          <ListItemIcon>{ALIGNMENT_ICONS[alignmentOption]}</ListItemIcon>
          <ListItemText>
            {tComponents(`BannerBlock.BannerTitle.BannerBlockTitleAlignmentSelector.${alignmentOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default BannerBlockTitleAlignmentSelector;
