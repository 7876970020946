import React, { CSSProperties, FC } from 'react';
import { M24MediaModel } from '../declarations/models/M24MediaModel';
import { ExternalMediaModel, isDiMuMediaModel } from '../declarations/models/ExternalMediaModel';
import { isM24MediaModel } from '../utils/typeChecks';
import { ChildrenProp } from '../declarations/ChildrenProp';
import { calculateObjectPosition } from './Image';

export interface ImagePreviewProps extends ChildrenProp {
  mediaObject?: M24MediaModel | ExternalMediaModel;
  skin?: string;
  imgClassName?: string;
  id?: string;
  imgStyle?: CSSProperties;
}

export const ImagePreview: FC<ImagePreviewProps> = ({ children, mediaObject, imgClassName, skin, id, imgStyle }) => {
  const getDiMuAltText = () => {
    if (!isDiMuMediaModel(mediaObject)) {
      return null;
    }
    return (
      mediaObject?.local.title ||
      mediaObject?.item['artifact.ingress.title'] ||
      mediaObject?.id ||
      mediaObject?.dimuId ||
      mediaObject?.dmsId ||
      mediaObject?.objectId ||
      ''
    );
  };

  const altText = isM24MediaModel(mediaObject) ? mediaObject?.content?.alttext : getDiMuAltText();
  let imgUrl = mediaObject?.url;

  let imgMimeType = '';
  if (mediaObject?.mimetype?.includes('png')) {
    imgMimeType = `?mediaType=${mediaObject?.mimetype}`;
  }

  if (mediaObject) {
    if (mediaObject.type === 'external_url' && mediaObject.local?.youtubeId) {
      imgUrl = `https://img.youtube.com/vi/${mediaObject.local?.youtubeId}/hqdefault.jpg`;
    }

    return (
      <>
        <div id={id} className={`module__media ${skin || ''}`}>
          <img
            style={{
              ...imgStyle,
              objectPosition: calculateObjectPosition(mediaObject.local?.x, mediaObject.local?.y),
            }}
            src={`${imgUrl}${imgMimeType}`}
            alt={altText || ''}
            className={imgClassName || ''}
          />
        </div>
        {children}
      </>
    );
  }

  return null;
};

export default ImagePreview;
