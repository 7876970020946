import React, { FC, ReactNode, useMemo } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import ActionMenu from '../../../../components/ActionMenu';
import Container from '../../../../components/Container';
import CustomIcon from '../../../../components/CustomIcon';
import { CardModuleView } from '../../../../declarations/models/blocks/CardModuleBlock';

export interface CardModuleViewTypeSelectorProps {
  cardModuleView: CardModuleView;
  onChange: (type: CardModuleView) => void;
}

enum CardModuleViewWidth {
  NARROW = 'narrow',
  WIDE = 'wide',
  FULL = 'full',
}

enum CardModuleViewTypeVariant {
  GRID = 'grid',
  LIST = 'list',
  HORIZONTAL = 'horizontal',
}

const allCardModuleViews = Object.values(CardModuleView).map(String);
if (Object.values(CardModuleViewTypeVariant).some((v) => !allCardModuleViews.includes(v))) {
  throw new Error('The enum CardModuleViewTypeVariant MUST be a subset of the enum CardModuleView');
}

const VARIANT_ICONS: Record<CardModuleViewTypeVariant, ReactNode> = {
  [CardModuleView.GRID]: <MaterialSymbol name='view_module' fill />,
  [CardModuleView.LIST]: <MaterialSymbol name='view_headline' fill />,
  [CardModuleView.HORIZONTAL]: <MaterialSymbol name='view_column' fill />,
};

const WIDTH_ICONS: Record<CardModuleViewWidth | 'null', ReactNode> = {
  null: <CustomIcon name='width_normal' />,
  [CardModuleViewWidth.NARROW]: <CustomIcon name='width_normal' />,
  [CardModuleViewWidth.WIDE]: <CustomIcon name='width_wider' />,
  [CardModuleViewWidth.FULL]: <CustomIcon name='width_full' />,
};

const AVAILABLE_WIDTHS: Record<CardModuleViewTypeVariant, Array<null | CardModuleViewWidth>> = {
  [CardModuleViewTypeVariant.GRID]: [
    null,
    CardModuleViewWidth.NARROW,
    CardModuleViewWidth.WIDE,
    CardModuleViewWidth.FULL,
  ],
  [CardModuleViewTypeVariant.LIST]: [
    null,
    CardModuleViewWidth.NARROW,
    CardModuleViewWidth.WIDE,
    CardModuleViewWidth.FULL,
  ],
  [CardModuleViewTypeVariant.HORIZONTAL]: [null, CardModuleViewWidth.WIDE, CardModuleViewWidth.FULL],
};

export const CardModuleViewTypeSelector: FC<CardModuleViewTypeSelectorProps> = ({ cardModuleView, onChange }) => {
  const { t } = useTranslation('components');

  const currentWidth = useMemo<null | CardModuleViewWidth>(() => {
    if (cardModuleView?.toString()?.endsWith(CardModuleViewWidth.WIDE)) {
      return CardModuleViewWidth.WIDE;
    }
    if (cardModuleView?.toString()?.endsWith(CardModuleViewWidth.FULL)) {
      return CardModuleViewWidth.FULL;
    }
    if (cardModuleView?.toString()?.endsWith(CardModuleViewWidth.NARROW)) {
      return CardModuleViewWidth.NARROW;
    }
    return null;
  }, [cardModuleView]);

  const currentVariant = useMemo<CardModuleViewTypeVariant>(() => {
    return Object.values(CardModuleViewWidth).reduce((previousValue, width) => {
      return previousValue?.replace(`-${width}`, '');
    }, cardModuleView?.toString()) as unknown as CardModuleViewTypeVariant;
  }, [cardModuleView]);

  const handleChange = (newVariant: CardModuleViewTypeVariant, newWidth: CardModuleViewWidth | null) => {
    if (newWidth !== null && AVAILABLE_WIDTHS[newVariant].includes(newWidth)) {
      onChange(`${newVariant}-${newWidth}` as CardModuleView);
    } else {
      onChange(newVariant as string as CardModuleView);
    }
  };

  const selectableWidths = AVAILABLE_WIDTHS[currentVariant] || [];

  return (
    <Container gap={0}>
      <ActionMenu
        id='select-card-module-block-view-type'
        ariaLabel={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltip={t('ViewTypeSelector.SelectViewTypeVariant')}
        tooltipPlacement='top'
        icon={VARIANT_ICONS[currentVariant]}>
        {(Object.values(CardModuleViewTypeVariant).map(String) as Array<CardModuleViewTypeVariant>).map((type) => (
          <MenuItem
            key={type}
            selected={type === currentVariant}
            disabled={type === currentVariant}
            onClick={() => handleChange(type, currentWidth)}>
            <ListItemIcon>{VARIANT_ICONS[type]}</ListItemIcon>
            <ListItemText>{t(`CardModuleViewTypeSelector.CardModuleViewTypeVariant.${type}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>
      {selectableWidths.length > 1 && (
        <ActionMenu
          id='select-card-module-block-view-type-width'
          ariaLabel={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltip={t('ViewTypeSelector.SelectViewTypeWidth')}
          tooltipPlacement='top'
          icon={WIDTH_ICONS[currentWidth || 'null']}>
          {selectableWidths.map((width) => (
            <MenuItem
              key={width || 'none-option'}
              selected={width === currentWidth}
              disabled={width === currentWidth}
              onClick={() => handleChange(currentVariant, width)}>
              <ListItemIcon>{WIDTH_ICONS[width || 'null']}</ListItemIcon>
              <ListItemText>{t(`ViewTypeSelector.ViewTypeWidth.${width || 'none'}`)}</ListItemText>
            </MenuItem>
          ))}
        </ActionMenu>
      )}
    </Container>
  );
};

export default CardModuleViewTypeSelector;
