import React, { FC } from 'react';
import { ExternalMediaModel, isVimeoMediaModel } from '../../../../../declarations/models/ExternalMediaModel';
import Container from '../../../../../components/Container';
import HeaderTitle from './HeaderTitle';
import HeaderThumbnail from './HeaderThumbnail';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface VimeoHeaderProps {
  item: ExternalMediaModel;
}

export const VimeoHeader: FC<VimeoHeaderProps & ChildrenProp> = ({ children, item }) => {
  if (!isVimeoMediaModel(item)) {
    console.error('Vimeo header did not receive a Vimeo media Model. This error should not occur');
    return null;
  }

  const itemIsVisible = item?.visibility !== false;

  // TODO: Load Vimeo thumbnail from API (Requires integration)
  // TODO: Load title of video from API

  return (
    <Container left>
      <HeaderThumbnail item={item} disabled={!itemIsVisible} />
      <Container column left fullWidth>
        <HeaderTitle title='Vimeo video' disabled={!itemIsVisible} />
        <Container px={1}>{children}</Container>
      </Container>
    </Container>
  );
};

export default VimeoHeader;
