import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import CollapseContainer from '../../../components/CollapseContainer';
import Container from '../../../components/Container';
import { SectionType } from '../../../declarations/models/SectionType';
import { BlockType } from '../../../declarations/models/BlockType';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { Page } from '../../../declarations/models/Page';
import { SectionsPath } from '../../../editor/PageEditor/CurrentSectionProvider';
import {
  getOrCreateSettingsContentSectionBlockIndex,
  getOrCreateSettingsContentSectionIndex,
} from '../../../utils/SettingsContent';
import LanguageMenuMainMenuSettings from './LanguageMenuMainMenuSettings';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import LanguageMenuFocusMenuSettings from './LanguageMenuFocusMenuSettings';
import LanguageMainMenuLanguagesMenuSettings from './LanguageMainMenuLanguagesMenuSettings';
import LanguageMenuSearchFieldSettings from './LanguageMenuSearchFieldSettings';

export const LanguageMenuSettings: FC = () => {
  const { t } = useTranslation('components');

  const { fields: sections, append } = useControlledFieldArray<Page, SectionsPath>({ name: 'content.sections' });

  const siteHeaderSectionIndex = getOrCreateSettingsContentSectionIndex(SectionType.SITE_HEAD, append, sections);
  const headerMenuBlockPath =
    `content.sections.${siteHeaderSectionIndex}.blocks.${getOrCreateSettingsContentSectionBlockIndex(
      siteHeaderSectionIndex,
      BlockType.HEADER_MENU,
      sections,
    )}` as BlockPath;

  const headerMenuValue = useWatch({ name: headerMenuBlockPath });

  const mainMenuCount = headerMenuValue?.items?.length || 0;
  const focusMenuCount = headerMenuValue?.focusitems?.length || 0;
  const languageMenuCount = headerMenuValue?.languageitems?.length || 0;

  return (
    <Container column fullWidth>
      <CollapseContainer
        title={t('Settings.LanguageSettings.LanguageMenuSettings.mainMenu')}
        subTitle={t('Settings.LanguageSettings.childCount', { count: mainMenuCount })}>
        <LanguageMenuMainMenuSettings blockPath={headerMenuBlockPath} />
      </CollapseContainer>
      <CollapseContainer
        title={t('Settings.LanguageSettings.LanguageMenuSettings.focusMenu')}
        subTitle={t('Settings.LanguageSettings.childCount', { count: focusMenuCount })}>
        <LanguageMenuFocusMenuSettings blockPath={headerMenuBlockPath} />
      </CollapseContainer>
      <CollapseContainer
        title={t('Settings.LanguageSettings.LanguageMenuSettings.languageMenu')}
        subTitle={t('Settings.LanguageSettings.childCount', { count: languageMenuCount })}>
        <LanguageMainMenuLanguagesMenuSettings blockPath={headerMenuBlockPath} />
      </CollapseContainer>
      <CollapseContainer title={t('Settings.LanguageSettings.LanguageMenuSettings.searchField')}>
        <LanguageMenuSearchFieldSettings blockPath={headerMenuBlockPath} />
      </CollapseContainer>
    </Container>
  );
};

export default LanguageMenuSettings;
