import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import Container from '../../../components/Container';
import { Page } from '../../../declarations/models/Page';
import { SectionsPath } from '../../../editor/PageEditor/CurrentSectionProvider';
import {
  getOrCreateSettingsContentSectionBlockIndex,
  getOrCreateSettingsContentSectionIndex,
} from '../../../utils/SettingsContent';
import { SectionType } from '../../../declarations/models/SectionType';
import CollapseContainer from '../../../components/CollapseContainer';
import { BlockType } from '../../../declarations/models/BlockType';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import LanguageFooterAddressSettings from './LanguageFooterAddressSettings';
import LanguageFooterSocialSettings from './LanguageFooterSocialSettings';
import LanguageFooterMenuSettings from './LanguageFooterMenuSettings';

export const LanguageFooterSettings: FC = () => {
  const { t } = useTranslation('components');
  const { fields: sections, append } = useControlledFieldArray<Page, SectionsPath>({ name: 'content.sections' });
  const siteFooterSectionIndex = getOrCreateSettingsContentSectionIndex(SectionType.SITE_FOOTER, append, sections);

  const footerAddressBlockPath =
    `content.sections.${siteFooterSectionIndex}.blocks.${getOrCreateSettingsContentSectionBlockIndex(
      siteFooterSectionIndex,
      BlockType.FOOTER_ADDRESS,
      sections,
    )}` as BlockPath;

  const footerSocialBlockPath =
    `content.sections.${siteFooterSectionIndex}.blocks.${getOrCreateSettingsContentSectionBlockIndex(
      siteFooterSectionIndex,
      BlockType.FOOTER_SOCIAL,
      sections,
    )}` as BlockPath;

  const footerMenuBlockPath =
    `content.sections.${siteFooterSectionIndex}.blocks.${getOrCreateSettingsContentSectionBlockIndex(
      siteFooterSectionIndex,
      BlockType.FOOTER_MENU,
      sections,
    )}` as BlockPath;

  const footerMenuValue = useWatch({ name: footerMenuBlockPath });

  const menuCount = footerMenuValue?.items?.length || 0;

  return (
    <Container column fullWidth>
      <CollapseContainer title={t('Settings.LanguageSettings.LanguageFooterSettings.addressTitle')}>
        <LanguageFooterAddressSettings blockPath={footerAddressBlockPath} />
      </CollapseContainer>
      <CollapseContainer title={t('Settings.LanguageSettings.LanguageFooterSettings.socialTitle')}>
        <LanguageFooterSocialSettings blockPath={footerSocialBlockPath} />
      </CollapseContainer>
      <CollapseContainer
        title={t('Settings.LanguageSettings.LanguageFooterSettings.menuTitle')}
        subTitle={t('Settings.LanguageSettings.childCount', { count: menuCount })}>
        <LanguageFooterMenuSettings blockPath={footerMenuBlockPath} />
      </CollapseContainer>
    </Container>
  );
};

export default LanguageFooterSettings;
