import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import { BlockPath, useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { useStore } from '@/components/store/Store';
import { CommonMediaList } from '@/editor/PageEditor/EditorBlock/MediaBlock/CommonMediaList';
import Container from '@/components/Container';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export const BannerBlockAdditionalImages = () => {
  const { t: tComponents } = useTranslation('components');
  const [expanded, setExpanded] = useState(false);
  const { blockPath } = useCurrentBlock();
  const mediaObject = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.mediaobject`>({
    name: `${blockPath}.mediaobject`,
  });

  const mediaitems = useWatch<BlockSpecificPage<BannerBlock>, `${BlockPath}.mediaitems`>({
    name: `${blockPath}.mediaitems`,
  });

  const { state } = useStore();
  const siteId = state.selectedSite?.id;

  if (!mediaObject?.id) {
    return null;
  }

  return (
    <Container fullWidth left p={0}>
      <Accordion
        elevation={0}
        disableGutters
        sx={{
          width: '100%',
        }}
        expanded={expanded}
        onChange={(_e, exp) => setExpanded(exp)}>
        <AccordionSummary
          sx={{
            flexDirection: 'row-reverse',
            p: 0,
          }}
          expandIcon={
            <MaterialSymbol name='chevron_right' sx={expanded ? { transform: 'rotate(270deg)' } : undefined} />
          }>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 'small',
              px: 1,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}>
            {tComponents('BannerBlock.AddSlideshowImagesLabel', { count: mediaitems?.length ?? 0 })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 0,
          }}>
          <CommonMediaList
            blockPath={`${blockPath}`}
            listName='mediaitems'
            siteId={siteId}
            sx={{
              p: 1,
              backgroundColor: 'background.default',
            }}
          />
          <Container fullWidth right mt={1}>
            <Button size='large' startIcon={<MaterialSymbol name='close' />} onClick={() => setExpanded(false)}>
              {tComponents('BannerBlock.CloseSlideshowImagesButton')}
            </Button>
          </Container>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
