import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useBlocker } from 'react-router-dom';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { useTranslation } from 'react-i18next';
import { Page } from '../../declarations/models/Page';
import { isDeepEqual, removeEmptyAndPrivateValues } from '../../utils/object';

export interface PageEditorFormStateContextValue {
  initialFormData: Page | null;
  setInitialFormData: Dispatch<SetStateAction<Page | null>>;
  currentFormData: Page | null;
  setCurrentFormData: Dispatch<SetStateAction<Page | null>>;
  isDirty: boolean;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}
const PageEditorFormStateContext = createContext<PageEditorFormStateContextValue | null>(null);

/**
 * Custom form state context hook to use instead of useFormContext() where needed.
 * Keeps track of how the Page model was when loaded / last saved and
 * form state (where react-hook-form's state is not suitable)
 */
export function usePageEditorFormState(): PageEditorFormStateContextValue {
  const value = useContext(PageEditorFormStateContext);
  if (!value) {
    throw new Error('[PageEditorFormStateProvider] PageEditorFormStateContext accessed before init');
  }
  return value;
}

export const PageEditorFormStateProvider = ({ children }: PropsWithChildren) => {
  const { t: tCommon } = useTranslation('common');
  const [initialPageData, setInitialPageData] = useState<Page | null>(null);
  const [currentPageData, setCurrentPageData] = useState<Page | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dirty = useMemo<boolean>(() => {
    const a = removeEmptyAndPrivateValues(initialPageData ?? {});
    const b = removeEmptyAndPrivateValues(currentPageData ?? {});
    return !isDeepEqual(a, b);
  }, [currentPageData, initialPageData]);

  // keep track of dirtiness in a ref to use in the beforeunload handler
  const dirtyRef = React.useRef<boolean>(dirty);
  useEffect(() => {
    dirtyRef.current = dirty;
  }, [dirty]);

  const formStateContextValue = useMemo<PageEditorFormStateContextValue>(
    () => ({
      initialFormData: initialPageData || null,
      setInitialFormData: setInitialPageData,
      currentFormData: currentPageData || null,
      setCurrentFormData: setCurrentPageData,
      isDirty: dirty,
      isSubmitting,
      setIsSubmitting,
    }),
    [currentPageData, dirty, initialPageData, isSubmitting],
  );

  const blocker = useBlocker(dirty);
  const confirm = useConfirmDialog();

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (dirtyRef.current) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm(tCommon('exitUnsavedWarning')).then((result) => {
        if (result) {
          blocker.proceed();
        } else {
          blocker.reset();
        }
      });
    }
  }, [blocker, confirm, tCommon]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <PageEditorFormStateContext.Provider value={formStateContextValue}>{children}</PageEditorFormStateContext.Provider>
  );
};
