/* istanbul ignore file */

export enum CardSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  COLLAPSED = 'collapsed',
}

export enum CardMediaRatio {
  AUTO = 'auto',
  FREE = 'free',
  R_1_1 = '1-1',
  R_3_4 = '3-4',
  R_2_3 = '2-3',
  R_4_3 = '4-3',
  R_3_2 = '3-2',
  R_16_9 = '16-9',
}
