import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const UnimplementedBlockPreview: BlockPreviewComponent = () => {
  const { t } = useTranslation('common');
  const { blockType } = useCurrentBlock();

  const blockName = t(`BlockType.${blockType}`);
  return (
    <Container pl={4} top left column fullWidth>
      <Typography variant='subtitle1' color='textSecondary'>
        The preview for &quot;{blockName}&quot; block has not yet been implemented.
      </Typography>
    </Container>
  );
};

export default UnimplementedBlockPreview;
