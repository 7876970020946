import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { PageListManualBlock as PageListManualBlockModel } from '../../../../declarations/models/blocks/PageListBlocks';
import { Page } from '../../../../declarations/models/Page';
import M24PageFinder from '../../../../components/Finder/M24PageFinder/M24PageFinder';
import PageListManualBlockItem from './PageListManualBlockItem';
import PageListDisplayOptions from './PageListDisplayOptions';

export const PageListManualBlock: BlockComponent = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);

  const {
    fields: items,
    append,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<PageListManualBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  return (
    <Container fullWidth column gap={4} left>
      <M24PageFinder
        open={pageFinderOpen}
        onClose={() => setPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          pages.forEach((page) => {
            if (page.source.id) {
              append({ id: page.source.id });
            }
          });
        }}
        multiSelect
      />
      <Container fullWidth column p={1} gap={0}>
        {items?.map((item, index) => {
          return (
            <PageListManualBlockItem
              key={String(item.id) + index} // eslint-disable-line react/no-array-index-key
              index={index}
              onReorder={move}
              onDelete={remove}
              color={Styles.Colors.LIGHT_GREY}
            />
          );
        })}
      </Container>
      <Button variant='contained' color='secondary' sx={{ flex: '1 0 auto' }} onClick={() => setPageFinderOpen(true)}>
        {t('PageListManualBlock.SelectPages')}
      </Button>
      <PageListDisplayOptions blockPath={blockPath} />
    </Container>
  );
};

export default PageListManualBlock;
