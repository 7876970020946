import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../../../CurrentBlockProvider';
import { BannerBlock, BannerTitleSize } from '../../../../../declarations/models/blocks/BannerBlock';
import { BlockSpecificPage } from '../../../../lib/declarations/BlockSpecificPage';
import ActionMenuButton from '../../../../../components/ActionMenuButton';

const SIZE_OPTIONS: Array<BannerTitleSize> = Object.values(BannerTitleSize);

export const BannerBlockTitleSizeSelector: FC = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.titleSize`>({
    name: `${blockPath}.titleSize`,
  });

  useEffect(() => {
    if (!value || !SIZE_OPTIONS.includes(value)) {
      onChange(BannerTitleSize.DEFAULT);
    }
  }, [onChange, value]);

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.BannerTitle.BannerBlockTitleSizeSelector.ActionMenuLabel')}
      tooltip={tComponents('BannerBlock.BannerTitle.BannerBlockTitleSizeSelector.tooltip')}
      tooltipPlacement='top'
      icon={<MaterialSymbol name='format_size' />}
      text={
        value
          ? tComponents(`BannerBlock.BannerTitle.BannerBlockTitleSizeSelector.${value}`)
          : tComponents(`BannerBlock.BannerTitle.BannerBlockTitleSizeSelector.${BannerTitleSize.DEFAULT}`)
      }>
      {SIZE_OPTIONS.map((sizeOption) => (
        <MenuItem
          key={sizeOption}
          onClick={() => onChange(sizeOption)}
          selected={value === sizeOption}
          aria-label={tComponents(`BannerBlock.BannerTitle.BannerBlockTitleSizeSelector.${sizeOption}`)}>
          <ListItemIcon>
            <MaterialSymbol name='format_size' />
          </ListItemIcon>
          <ListItemText>
            {tComponents(`BannerBlock.BannerTitle.BannerBlockTitleSizeSelector.${sizeOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default BannerBlockTitleSizeSelector;
