import React, { FC, useMemo } from 'react';
import { SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import ActionMenu from '@/components/ActionMenu';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SortSelectorProps {
  sortOption: SortOption;
  setSortOption: (sort: SortOption) => void;
  availableOptions?: Array<SortType>;
}

export const SortSelector: FC<SortSelectorProps> = ({
  sortOption,
  setSortOption,
  availableOptions = Object.values(SortType),
}) => {
  const { t: tCommon } = useTranslation('common');

  const sortOptions = useMemo(() => {
    return availableOptions.reduce((acc, curr) => {
      if (curr === SortType.RELEVANCE)
        return [
          ...acc,
          {
            sortBy: curr,
            order: SortDirection.DESC,
          },
        ];
      return [
        ...acc,
        {
          sortBy: curr,
          order: SortDirection.ASC,
        },
        {
          sortBy: curr,
          order: SortDirection.DESC,
        },
      ];
    }, [] as SortOption[]);
  }, [availableOptions]);

  return (
    <>
      <ActionMenu
        tooltip={tCommon('SortLabel')}
        id='sort-selector'
        ariaLabel='sort-selector'
        icon={<MaterialSymbol name='sort' />}>
        {sortOptions.map((opt) => (
          <MenuItem
            key={`${opt.sortBy}-${opt.order}`}
            onClick={() => setSortOption(opt)}
            selected={sortOption.order === opt.order && sortOption.sortBy === opt.sortBy}>
            <ListItemText>{tCommon(`SortOptions.${opt.sortBy}_${opt.order}`)}</ListItemText>
          </MenuItem>
        ))}
      </ActionMenu>
      {tCommon(`SortOptions.${sortOption.sortBy}_${sortOption.order}`)}
    </>
  );
};
