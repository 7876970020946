import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { BlockType } from '@/declarations/models/BlockType';
import { Location } from '@/declarations/models/Location';

// TODO: 28/05/2024 Mold this to fit better with the old API
export interface MapV2Block extends BaseBlock {
  type: BlockType.MAP_V2;
  markers: Array<MapV2Marker>;
  siteLocations: Array<Location>;
  mapDesign: MapDesign;
  mapType: MapType;
  enableStreetView: boolean;
  enableMapType: boolean;
  enableWayfinder: boolean;
  width?: string;
}

export interface MapV2Marker {
  type: 'location';
  location: Location;
}

export enum MapType {
  ROADMAP = 'ROADMAP',
  SATELLITE = 'SATELLITE',
  // TODO:+ hybrid and terrain
}

export enum MapDesign {
  NORMAL = 'normal',
  BLACK_WHITE = 'black_white',
}

export const LILLEHAMMER_COORDINATES = {
  lat: 61.1152713,
  lng: 10.466230600000017,
};
