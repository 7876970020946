import React, { FC, useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStore } from './store/Store';
import { Api } from '../services/Api';
import SearchSelector from './SearchSelector';
import { useApi } from '../hooks/useApi';

export const SiteSelector: FC = () => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state, changeSiteContext, changeSiteLanguage } = useStore();
  const { selectedSite, selectedSiteLanguage } = state;
  const navigate = useNavigate();

  let siteUrl = selectedSite?.url;
  if (siteUrl && siteUrl.includes('https://localhost')) {
    siteUrl = siteUrl.replace('https', 'http');
  }

  let [availableSites] = useApi(() => Api.getAllSites({ start: 0, rows: 500, settings: true, is_admin2: true }), []);

  useEffect(() => {
    const firstAvailableSite = availableSites?.length ? availableSites[0] : null;

    availableSites = availableSites.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

    if (selectedSite) {
      const selectedSiteFresh = availableSites.find((s) => s.id === selectedSite?.id);
      if (selectedSiteFresh) changeSiteContext(selectedSiteFresh);
      else if (firstAvailableSite) changeSiteContext(firstAvailableSite);
    } else if (firstAvailableSite) changeSiteContext(firstAvailableSite);
    else changeSiteContext(null);
  }, [selectedSite, availableSites, changeSiteContext]);

  return (
    <>
      {siteUrl && (
        <Tooltip title={tCommon(`showPortal`)} placement='bottom' arrow>
          <IconButton disabled={!siteUrl} onClick={() => window.open(`${siteUrl}/${selectedSiteLanguage}`, 'portal')}>
            <MaterialSymbol
              name='preview'
              sx={{
                fontSize: 30,
                color: '#000',
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      <SearchSelector
        id='site-selector'
        color='inherit'
        label={t('SiteSelector.selectOrChangeActiveSite')}
        value={selectedSite}
        onChange={(val) => {
          changeSiteContext(val);
          navigate('/dashboard');
        }}
        options={availableSites}
        getOptionKey={(site) => site?.id || 'null'}
        getOptionLabel={(site) => site?.name || 'null'}
        required
        searchable={availableSites.length > 5}
      />
      {selectedSite && (
        <SearchSelector
          id='site-language-selector'
          color='inherit'
          label={t('SiteSelector.selectOrChangeActiveSiteLanguage')}
          value={selectedSiteLanguage}
          onChange={changeSiteLanguage}
          options={availableSites?.length ? selectedSite?.site_contents?.map((c) => c.locale) || [] : []}
          getOptionKey={(c) => c || 'null'}
          getOptionLabel={(c) => c?.toUpperCase() || 'null'}
          required
          searchable={false}
        />
      )}
    </>
  );
};

export default SiteSelector;
