/* istanbul ignore file */
import { Settings } from '@/Settings';
import { PageFolder } from '@/declarations/models/Folder';
import { Site } from '@/declarations/models/Site';
import { RequestContext } from '@/utils/ApiRequest/RequestContext';
import { AdminApiRequest } from '@/utils/ApiRequest/AdminApiRequest';
import { QueryParams } from '@/declarations/QueryParams';
import { Page } from '@/declarations/models/Page';
import { Status } from '@/declarations/models/Status';
import { Tag } from '@/declarations/models/Tag';
import { TagType } from '@/declarations/models/TagType';
import { Domain } from '@/declarations/models/Domain';
import { PageVersion } from '@/declarations/models/PageVersion';
import { Designs } from '@/declarations/models/Designs';
import { Design } from '@/declarations/models/Design';
import { MediaResourceType } from '@/declarations/models/MediaResourceType';
import { PaginationResult } from '@/declarations/PaginationResult';
import { DMObjectType } from '@/declarations/models/DMObjectType';
import { MediaLicense } from '@/declarations/models/MediaLicense';
import { DMQueryResponse } from '@/declarations/models/DMQueryResponse';
import { DMArtifact, DMMediaModel } from '@/declarations/models/DMMediaModel';
import { UserInformation } from '@/declarations/models/UserInformation';
import { Owner } from '@/declarations/models/Owner';
import { M24MediaModel, PublishM24Media } from '@/declarations/models/M24MediaModel';
import { BlockType } from '@/declarations/models/BlockType';
import { PageRevision } from '@/declarations/models/PageRevision';
import { DMSortType, SortDirection, SortType } from '@/declarations/models/SortOption';
import { BatchUpdateResult } from '@/declarations/BatchUpdateResult';
import { Language } from '@/declarations/models/Language';
import { SharingRequestModel, SiteSharingModel } from '@/declarations/models/SiteSharingModel';
import { Location } from '@/declarations/models/Location';
import { User } from '@/declarations/models/User';
import { Block } from '@/declarations/models/Block';
import { Employee } from '@/declarations/models/Employee';
import { DimuOwner } from '@/declarations/models/DimuOwner';
import { Card } from '@/declarations/models/Card';
import { KPObject, KPOwner, KPProcessedFields } from '@/declarations/models/KP';
import { GetKPObjectsResult } from '@/declarations/GetKPObjectsResult';
import { GetKPOwnersResult } from '@/declarations/GetKPOwnersResult';
import { MainCategory, SubCategory } from '@/declarations/Category';
import { MediaCountResponse, ModuleCountResponse, PageCountResponse } from '@/declarations/models/CountResponses';
import { FormModule, FormModuleResponse } from '@/declarations/models/FormModule';

interface PaginationParams extends QueryParams {
  start?: number;
  rows?: number;
}

interface GetSitesQueryParams extends PaginationParams {
  settings?: boolean;
}

interface GetEventListForSiteQueryParams extends PaginationParams {
  /**
   * Comma separated list
   */
  sub_category: Array<string>;
  /**
   * Order type - Valid values are 'asc' or 'desc'
   */
  order: 'asc' | 'desc';
  /**
   * Default = 'title'
   */
  order_by: 'title' | 'published_at';
  /**
   * (5 | 10 | 15 | 20 | 25 | 30 | 40 | 50 | 100)
   */
  rows: number;
  start: number;
  /**
   * comma separated list - default = all statuses except deleted
   */
  status_list: Array<string>;
  text_query: string;
}

export interface GetPagesListForSiteQueryParams extends PaginationParams {
  /**
   * Default = false
   */
  is_event: boolean;
  main_category: MainCategory;
  sub_categories: Array<SubCategory>;
  /**
   * Order type - Valid values are 'asc' or 'desc'
   */
  order: SortDirection;
  /**
   * Default = 'title'
   */
  order_by: SortType;
  page_parent_id: number;

  rows: number;
  start: number;
  /**
   * comma separated list - default = all statuses except deleted
   */
  status_list: Array<Status>;
  text_query: string;
  past_events: boolean;
  site_ids: string;
  search_level: string;
  locale: string;
  location_ids: Array<number>;
}

interface GetPagesForSiteQueryParams extends PaginationParams {
  /**
   * comma separated list of categories
   */
  categories: Array<string>;
  /**
   * includes content in query false = søkes kun i tittel,
   *     description, path
   */
  deep_query: boolean;
  /**
   * an array with ancestor pages
   */
  include_ancestors: boolean;
  /**
   * a string with titles as path
   */
  include_breadcrumbs: boolean;
  /**
   * category is included in the result
   */
  include_category: boolean;
  /**
   * number of children for each page
   */
  include_children_count: boolean;
  /**
   * only frontpages
   */
  is_frontpage: boolean;
  /**
   * only templates
   */
  is_template: boolean;
  /**
   * for specific locale
   */
  locale: string;
  /**
   * Order type - Valid values are 'asc' or 'desc'
   */
  order: 'asc' | 'desc';
  /**
   * Value to order by - valid values are created_at, updated_at, title
   */
  order_by: 'created_at' | 'updated_at' | 'title';
  /**
   * identifies page with child pages
   */
  page_id: number;
  /**
   * String for text search on page title (simple LIKE search)
   */
  query: string;
  /**
   * Comma separated list of site ids from which to get content.
   */
  site_ids: Array<number>;
  /**
   * comma separated list of statuses
   */
  status: Array<Status>;
  /**
   * comma separated list of tags
   */
  tags: Array<string>;
  /**
   * filters on user id
   */
  updated_by_id: number;
}

export interface GetPageQueryParams extends QueryParams {
  version_id?: number;
  locale?: string;
}

interface GetPageTreeQueryParams extends QueryParams {
  locale: string;
  order: SortDirection;
  order_by: SortType;
  page_id: number;
  status_list: Array<Status>;
}

export interface BulkEditBody {
  status?: Status;
  parent_id?: number | string;
  item_type?: 'page';
  item_ids: Array<string | number>;
}

interface GetAllTagsForSiteQueryParams extends QueryParams {
  type: TagType;
}

interface GetTagSearchResultQueryParams extends QueryParams {
  categories: string;
  tags: string;
  languages: string;
  rows: string;
  order: 'asc' | 'desc';
  order_by: 'date' | 'title' | 'event';
  entity_type: 'blocks';
}

interface GetM24MediaResourcesQueryParams extends QueryParams {
  type: MediaResourceType;
  start: number;
  rows: number;
  import_collection_id: string;
  dam: boolean;
  site_ids: string;
  tags: string[];
  order: SortDirection;
  order_by: SortType;
  query: string;
  get_tags: boolean;
  get_usage_data: boolean;
}

interface GetDMSearchQueryParams extends QueryParams {
  query: string;
  owners: string;
  subjects: string;
  start: number;
  rows: number;
  types: Array<DMObjectType>;
  licenses: Array<MediaLicense>;
  order: SortDirection;
  order_by: DMSortType;
  has_location: boolean;
  has_pictures: boolean;
  classification: Array<string>;
  names: Array<string>;
}

interface GetKPObjectsQueryParams extends PaginationParams {
  query: string;
  schema_ids: Array<number>;
  owner_id: number;
  order: SortDirection;
  order_by: SortType;
}

interface GetKPOwnersQueryParams extends PaginationParams {
  query: string;
  order: SortDirection;
  order_by: SortType;
}

interface GetBlocksQueryParams extends QueryParams {
  block_type: BlockType;
  query: string;
  start: number;
  rows: number;
  has_resources: boolean;
  order: 'asc' | 'desc';
  order_by: 'updated_at' | 'created_at' | 'title';
  categories: string; // comma-separated list
  locale: string;
}

interface GetPageBlocksQueryParams extends QueryParams {
  locale: string;
  block_type: string;
  start: number;
  rows: number;
  include_children: boolean;
}

export interface GetEmployeesQueryParams extends QueryParams {
  employee_ids: string;
  site_ids: string;
  text_query: string;
  status: Status;
  order: SortDirection;
  order_by: SortType;
}

export interface GetOwnersQueryParams extends QueryParams {
  top_level_only?: string;
}

export interface ListPageTemplatesReponse {
  page_templates: Page[];
  count: number;
}

/**
 * All available API-endpoints.
 *
 * Example of usage in a React component:
 * <pre>
 *     const [roles, setRoles] = useState<Array<Role>>([]);
 *     useEffect(() => {
 *       const request = Api.getAllRoles();
 *       const [response, error, source] = await request.fetch()
 *       if (source.name == 'AbortError) {
 *           console.info("Request was aborted")
 *       } else if (error) {
 *           console.error("Unable to fetch roles", error, source)
 *       }
 *       setRoles(error ? [] : response);
 *       return () => request.abort();
 *     }, []);
 * </pre>
 * Or use fetchDirect() to simplify all the checks:
 * <pre>
 *     const [roles, setRoles] = useState<Array<Role>>([]);
 *     useEffect(() => {
 *       const request = Api.getAllRoles();
 *       setRoles(await request.fetchDirect([]));
 *       return () => request.abort();
 *     }, []);
 * </pre>
 */
export abstract class Api {
  // ***************************
  // ******** OWNER API ********
  // ***************************

  public static getOwners(queryParams?: GetOwnersQueryParams): RequestContext<{
    owners: Array<Owner>;
    total_count: number;
  }> {
    return new AdminApiRequest<{ owners: Array<Owner>; total_count: number }>('owner', 'owners')
      .withAuth()
      .withParams(queryParams || {})
      .get();
  }

  /**
   * Get an owner by its ID
   * @param ownerId
   */
  public static getOwnerById(ownerId: number): RequestContext<Owner> {
    return new AdminApiRequest<Owner>('owner', 'owner', ownerId).withAuth().get();
  }

  // ***************************
  // ******** SITES API ********
  // ***************************

  /**
   * Get all sites the logged user has access to
   */
  public static getAllSites(queryParams?: GetSitesQueryParams): RequestContext<{ sites: Array<Site> }, Array<Site>> {
    return new AdminApiRequest<{ sites: Array<Site> }, Array<Site>>('site', 'sites')
      .withAuth()
      .withParams(queryParams)
      .withMapper((res) => res?.sites || [])
      .get();
  }

  /**
   * Get all sites the logged user has access to
   */
  public static getAllSitesForSiteSharing(): RequestContext<{ sites: Array<Site> }, Array<Site>> {
    return new AdminApiRequest<{ sites: Array<Site> }, Array<Site>>('site', 'sites', 'all')
      .withAuth()
      .withMapper((res) => res?.sites || [])
      .get();
  }

  /**
   * Get a site
   * @param siteId
   */
  public static getSite(siteId: number): RequestContext<Site> {
    return new AdminApiRequest<Site>('site', 'site', siteId).withAuth().get();
  }

  /**
   * Save site settings
   * @param model
   */
  public static saveSiteSettings<Model extends Site>(model: Partial<Model>): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'site').withAuth().withBody(model).post();
  }

  /**
   * Get a site_content (language settings)
   * @param siteId
   * @param languageCode
   */
  public static getSiteContent(siteId: number, languageCode: string): RequestContext<Site> {
    return new AdminApiRequest<Site>('site', 'site_content', siteId, languageCode).withAuth().get();
  }

  /**
   * Save site_content (language settings)
   * @param model
   * @param siteId
   * @param languageCode
   */
  public static saveSiteContent<Model extends Site>(
    model: Partial<Model>,
    siteId: number,
    languageCode: string,
  ): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'site_content', siteId, languageCode).withAuth().withBody(model).post();
  }

  /**
   * Get site sharing data
   * @param siteId
   * @param queryParams
   */
  public static getSiteSharing(siteId: number, queryParams?: { approved?: boolean }): RequestContext<SiteSharingModel> {
    return new AdminApiRequest<SiteSharingModel>('site', 'sharing', siteId).withAuth().withParams(queryParams).get();
  }

  /**
   * Save site sharing
   * @param model
   * @param siteId
   */
  public static saveSiteSharing<Model extends SiteSharingModel>(
    model: Partial<Model>,
    siteId: number,
  ): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'sharing', siteId).withAuth().withBody(model).post();
  }

  /**
   * Save site sharing request
   * @param model
   * @param siteId
   */
  public static saveSiteSharingModel<Model extends SharingRequestModel>(
    model: Model,
    siteId: number,
  ): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'sharing', 'save', siteId).withAuth().withBody(model).post();
  }

  /**
   * Save site sharing request
   * @param model
   * @param siteId
   */
  public static saveSiteSharingRequest<Model extends SharingRequestModel>(
    model: Model,
    siteId: number,
  ): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'sharing', 'request', siteId).withAuth().withBody(model).post();
  }

  /**
   * Get site locations
   * @param siteId
   */
  public static getSiteLocations(siteId: number): RequestContext<{ locations: Array<Location> }, Array<Location>> {
    return new AdminApiRequest<{ locations: Array<Location> }, Array<Location>>('site', 'locations', siteId)
      .withAuth()
      .withMapper((res) => res?.locations || [])
      .get();
  }

  /**
   * Save a site location
   * @param model
   * @param siteId
   */
  public static saveSiteLocation<Model extends Location>(model: Partial<Model>, siteId: number): RequestContext<Model> {
    return new AdminApiRequest<Model>('site', 'locations', siteId).withAuth().withBody(model).post();
  }

  /**
   * Delete a site location
   * @param siteId
   * @param locationId
   */
  public static deleteSiteLocation(siteId: number, locationId: number): RequestContext<void> {
    return new AdminApiRequest<void>('v2', 'locations', siteId, locationId).withAuth().delete();
  }

  /**
   * Get all sibling sites for a site
   * @param siteId
   */
  public static getSiblingSites(siteId: number): RequestContext<Array<Site>> {
    return new AdminApiRequest<Array<Site>>('v2', 'site', siteId, 'siblings').withAuth().get();
  }

  /**
   * Check if site has siblings
   * @param siteId
   */
  public static getHasSiblingSites(siteId: number): RequestContext<Array<Site>> {
    return new AdminApiRequest<Array<Site>>('v2', 'site', siteId, 'has_siblings').withAuth().get();
  }

  /**
   * Get all page paths for a site
   * @param siteId
   * @param locale - Optional locale parameter for filtering
   */
  public static getSitePagePaths(siteId: number, locale?: string): RequestContext<Array<string>> {
    return new AdminApiRequest<Array<string>>('v2', 'site', siteId, 'page_paths')
      .withAuth()
      .withParams({ locale })
      .get();
  }

  // ***************************
  // ******* DOMAINS API *******
  // ***************************

  /**
   *
   * @param siteId
   */
  public static getDomainForSite(siteId: number): RequestContext<{ domains: Array<Domain> }, Domain | null> {
    return new AdminApiRequest<{ domains: Array<Domain> }, Domain | null>('site', siteId, 'domains')
      .withAuth()
      .withMapper((payload) => payload?.domains?.[0] || null)
      .get();
  }

  // ***************************
  // ******** EVENTS API *******
  // ***************************

  /**
   * Get events list for a site
   * @param siteId
   * @param queryParams
   */
  public static getEventsListForSite(
    siteId: number,
    queryParams?: Partial<GetEventListForSiteQueryParams>,
  ): RequestContext<
    {
      pages: Array<Page>;
    },
    Array<Page>
  > {
    return new AdminApiRequest<{ pages: Array<Page> }, Array<Page>>('site', 'events', 'list', siteId)
      .withAuth()
      .withParams(queryParams)
      .withMapper((res) => res?.pages || [])
      .get();
  }

  // ***************************
  // ******** PAGES API ********
  // ***************************

  /**
   * Get preview for pagelist:pagetree
   * @param siteId
   * @param queryParams
   */
  public static getPagesListPagetree(
    siteId: number,
    pageId: any,
    queryParams?: Partial<GetPagesListForSiteQueryParams>,
  ): RequestContext<
    {
      pages: Array<Page>;
    },
    Array<Page>
  > {
    return new AdminApiRequest<{ pages: Array<Page> }, Array<Page>>(
      'v2',
      'page',
      siteId,
      'pages',
      pageId,
      'list',
      'auto',
      'pagetree',
    )
      .withAuth()
      .withParams({
        ...queryParams,
      })
      .withMapper((res) => res?.pages || [])
      .get();
  }

  /**
   * Get preview for pagelist:categories
   * @param siteId
   * @param queryParams
   */
  public static getPagesListCategories(queryParams?: Partial<GetPagesListForSiteQueryParams>): RequestContext<
    {
      pages: Array<Page>;
    },
    Array<Page>
  > {
    return new AdminApiRequest<{ pages: Array<Page> }, Array<Page>>('v2', 'page', 'pages', 'list', 'auto', 'categories')
      .withAuth()
      .withParams({
        ...queryParams,
        sub_categories: queryParams?.sub_categories?.join(','),
        location_ids: queryParams?.location_ids?.join(','),
        site_ids: queryParams?.site_ids,
      })
      .withMapper((res) => res?.pages || [])
      .get();
  }

  /**
   * Get pages list for a site
   * This is a new endpoint that replaces getAllPagesForSite (/site/pages/<siteID>)
   * @param siteId
   * @param queryParams
   */
  public static getPagesListForSite(
    siteId: number,
    queryParams?: Partial<GetPagesListForSiteQueryParams>,
  ): RequestContext<
    {
      pages: Array<Page>;
    },
    Array<Page>
  > {
    return new AdminApiRequest<{ pages: Array<Page> }, Array<Page>>('site', 'pages', 'list', siteId)
      .withAuth()
      .withParams({
        ...queryParams,
        sub_categories: queryParams?.sub_categories?.join(','),
        status_list: queryParams?.status_list?.join(','),
        location_ids: queryParams?.location_ids?.join(','),
        site_ids: queryParams?.site_ids,
      })
      .withMapper((res) => res?.pages || [])
      .get();
  }

  /**
   * Get all pages for a site
   * @param siteId
   * @param queryParams
   * @deprecated This is the old endpoint for getting all pages. Use **getPagesListForSite()** instead if possible.
   */
  public static getAllPagesForSite(
    siteId: number,
    queryParams?: Partial<GetPagesForSiteQueryParams>,
  ): RequestContext<
    {
      pages: Array<Page>;
    },
    Array<Page>
  > {
    return new AdminApiRequest<{ pages: Array<Page> }, Array<Page>>('site', 'pages', siteId)
      .withAuth()
      .withParams(queryParams)
      .withMapper((res) => res?.pages || [])
      .get();
  }

  /**
   * Get a page
   * @param pageId
   * @param queryParams
   */
  public static getPage(pageId: number, queryParams?: Partial<GetPageQueryParams>): RequestContext<Page> {
    return new AdminApiRequest<Page>('site', 'page', pageId).withAuth().withParams(queryParams).get();
  }

  /**
   * Get simplified page metadata
   *
   * @param pageId
   */
  public static getPageSimple(pageId: number): RequestContext<Page> {
    return new AdminApiRequest<Page>('site', 'page', 'simple', pageId).withAuth().get();
  }

  /**
   * Create or save a page
   * @param siteId
   * @param page
   */
  public static savePage(siteId: number, page: Partial<Page>): RequestContext<Page> {
    return new AdminApiRequest<Page>('site', 'page', siteId).withAuth().withBody(page).post();
  }

  /**
   * Create or save a page version
   * @param pageId
   * @param pageVersion
   */
  public static savePageVersion(pageId: number, pageVersion: Partial<PageVersion>): RequestContext<PageVersion> {
    return new AdminApiRequest<PageVersion>('site', 'page', 'version', pageId).withAuth().withBody(pageVersion).post();
  }

  /**
   * Delete a page version
   * @param pageId
   * @param versionId
   */
  public static deletePageVersion(pageId: number, versionId: number): RequestContext<void> {
    return new AdminApiRequest<void>('site', 'page', 'version', pageId, versionId).withAuth().delete();
  }

  /**
   * Delete a page
   * @param pageId The ID of the page to delete
   */
  public static deletePage(pageId: number): RequestContext<string> {
    return new AdminApiRequest<string>('site', 'page', pageId).withAuth().delete();
  }

  /**
   * Get page revisions
   * @param pageId
   * @param start
   * @param rows
   * @param version_id
   */
  public static getPageRevisions(
    pageId: number,
    start: number,
    rows: number,
    version_id: number,
  ): RequestContext<
    {
      revisions: Array<PageRevision>;
    },
    Array<PageRevision>
  > {
    return new AdminApiRequest<
      {
        revisions: Array<PageRevision>;
      },
      Array<PageRevision>
    >('site', 'page', 'revisions', pageId)
      .withAuth()
      .withParams({ start, rows, version_id })
      .withMapper((res) => res?.revisions || [])
      .get();
  }

  /**
   * Get a Page tree as a list of pages
   *
   * @param siteId
   * @param locale
   * @param queryParams
   */
  public static getPageTree(
    siteId: number,
    locale: string,
    queryParams?: Partial<GetPageTreeQueryParams>,
  ): RequestContext<{
    pages: Array<Page>;
    site: Site;
  }> {
    return new AdminApiRequest<{ pages: Array<Page>; site: Site }>('site', siteId, 'pages', 'tree', locale)
      .withAuth()
      .withParams({ ...queryParams, status_list: queryParams?.status_list?.join(',') })
      .get();
  }

  /**
   * Get a count of pages for a Site
   *
   * @param siteId - The ID of the site to get page counts from
   * @param isEvent - If true, count events instead of other page types
   */
  public static getPageCount(siteId: number, isEvent?: boolean, locale?: string): RequestContext<PageCountResponse> {
    return new AdminApiRequest<PageCountResponse>('site', 'pages', siteId, 'count')
      .withAuth()
      .withParams({ is_event: !!isEvent, locale })
      .get();
  }

  /**
   * Get versions for a page (published/active and draft)
   * @param siteId
   * @param body
   */
  public static getPageVersions(pageId: number): RequestContext<{ versions: PageVersion[] }> {
    return new AdminApiRequest<{ versions: PageVersion[] }>('v2', 'page', pageId, 'versions').withAuth().get();
  }

  /**
   * Create a new draft if there does not exist a draft already
   * @param siteId
   * @param body
   */
  public static createPageDraft(pageId: number, page: Page): RequestContext<Page> {
    return new AdminApiRequest<Page>('v2', 'page', pageId, 'versions').withAuth().withBody(page).post();
  }

  /**
   * Publish a draft, will make the current published version inactive
   * @param siteId
   * @param body
   */
  public static publishPageDraft(pageId: number, versionId: number, page: Page): RequestContext<Page> {
    return new AdminApiRequest<Page>('v2', 'page', pageId, 'versions', versionId).withAuth().withBody(page).put();
  }

  public static getPageIdsWithDraft(siteId: number): RequestContext<{ page_ids: Array<number> }> {
    return new AdminApiRequest<{ page_ids: Array<number> }>('v2', 'page', 'list', 'draft', siteId).withAuth().get();
  }

  // *****
  // ** Page templates
  // *****
  public static listPageTemplates(siteId: number): RequestContext<ListPageTemplatesReponse> {
    return new AdminApiRequest<ListPageTemplatesReponse>('v2', 'page', 'site', siteId, 'template').withAuth().get();
  }

  public static getPageTemplate(templatePageId: number): RequestContext<Page> {
    return new AdminApiRequest<Page>('v2', 'page', templatePageId, 'template').withAuth().get();
  }

  public static postPageTemplate(page: Page): RequestContext<Page> {
    return new AdminApiRequest<Page>('v2', 'page', 'template', 'create').withAuth().withBody(page).post();
  }

  public static putPageTemplate(templatePageId: number, page: Page): RequestContext<Page> {
    return new AdminApiRequest<Page>('v2', 'page', templatePageId, 'template', 'update')
      .withAuth()
      .withBody(page)
      .put();
  }

  public static deletePageTemplate(templatePageId: number): RequestContext<void> {
    return new AdminApiRequest<void>('v2', 'page', templatePageId, 'template', 'delete').withAuth().delete();
  }

  // ***************************
  // ******** BULK API *********
  // ***************************

  public static bulkEditPages(
    siteId: number,
    body: BulkEditBody,
  ): RequestContext<
    BulkEditBody,
    {
      changed: Array<Page>;
    }
  > {
    return new AdminApiRequest<BulkEditBody, { changed: Array<Page> }>('site', siteId, 'bulk')
      .withAuth()
      .withBody(body)
      .post();
  }

  // ***************************
  // ******** TAGS API *********
  // ***************************

  /**
   *
   * @param siteId
   * @param queryParams
   */
  public static getAllTagsForSite(
    siteId: number,
    queryParams?: Partial<GetAllTagsForSiteQueryParams>,
  ): RequestContext<
    {
      tags: Array<Tag>;
    },
    Array<Tag>
  > {
    return new AdminApiRequest<{ tags: Array<Tag> }, Array<Tag>>('tag', 'tags', siteId)
      .withAuth()
      .withParams(queryParams)
      .withMapper((res) => res?.tags || [])
      .get();
  }

  /**
   *
   * @param siteId
   * @param queryParams
   */
  public static getTaggedCardSearchResult(
    siteId: number,
    queryParams?: Partial<GetTagSearchResultQueryParams>,
  ): RequestContext<
    {
      tagged: Array<Card>;
    },
    Array<Card>
  > {
    return new AdminApiRequest<{ tagged: Array<Card> }, Array<Card>>('tag', 'tagged', siteId)
      .withAuth()
      .withParams(queryParams)
      .withMapper((res) => res?.tagged || [])
      .get();
  }

  /**
   * Create or save a tag
   * @param siteId
   * @param tag
   */
  public static saveTag(siteId: number, tag: Partial<Tag>): RequestContext<Tag, { tag: Tag }> {
    return new AdminApiRequest<Tag, { tag: Tag }>('tag', 'tags', siteId).withAuth().withBody(tag).post();
  }

  public static deleteTag(siteId: number, tag: Partial<Tag>): RequestContext<Tag> {
    return new AdminApiRequest<Tag>('tag', 'tags', siteId).withAuth().withBody(tag).delete();
  }

  // ***************************
  // ****** DESIGNS API ********
  // ***************************

  /**
   *
   * @param siteId
   */
  public static getAllDesignsForSite(siteId: number): RequestContext<{ designs: Designs }, Array<Design>> {
    return new AdminApiRequest<{ designs: Designs }, Array<Design>>('design', 'site', siteId)
      .withAuth()
      .withMapper((body) => [...(body?.designs?.active || []), ...(body?.designs?.experimental || [])])
      .get();
  }

  // ***************************
  // ******* MEDIA API *********
  // ***************************

  /**
   * Fetch a M24 media resource
   *
   * @param siteId - the ID of the site owning the resource
   * @param resourceId - The ID of the resource to be fetched
   */
  public static getM24MediaResource(siteId: number, resourceId: number): RequestContext<M24MediaModel> {
    return new AdminApiRequest<M24MediaModel>('media', 'resources', siteId, resourceId).withAuth().get();
  }

  /**
   * Fetch a M24 media resource
   *
   * @param resourceId - The ID of the resource to be fetched
   */
  public static getM24MediaResourceSimple(resourceId: number): RequestContext<M24MediaModel> {
    return new AdminApiRequest<M24MediaModel>('media', 'resources', 'resource', resourceId).withAuth().get();
  }

  /**
   *
   * @param siteId
   * @param queryParams
   */
  public static getM24MediaResources(
    siteId: number,
    queryParams?: Partial<GetM24MediaResourcesQueryParams>,
  ): RequestContext<
    {
      query_count: number;
      resources: Array<M24MediaModel>;
      total_count: number;
    },
    PaginationResult<M24MediaModel>
  > {
    const start = queryParams?.start || 0;
    const rows = queryParams?.rows || 10;
    const tags = queryParams?.tags?.join(',');
    return new AdminApiRequest<
      {
        query_count: number;
        resources: Array<M24MediaModel>;
        total_count: number;
      },
      PaginationResult<M24MediaModel>
    >('media', 'resources', siteId)
      .withAuth()
      .withParams({
        order_by: 'created_at',
        ...(queryParams || {}),
        start,
        rows,
        tags,
      })
      .withMapper((res) => ({
        start,
        rows,
        count: res?.query_count ?? res?.resources?.length ?? 0,
        items: res?.resources || [],
        totalCount: res?.total_count ?? 0,
      }))
      .get();
  }

  /**
   * TODO: Create separate endpoint for create/update (post/put)
   *
   * @param siteId - The ID of the site owning the media resource
   * @param resource - The media resource object to be created
   */
  public static createM24MediaResource(
    siteId: number,
    resource: Partial<M24MediaModel>,
  ): RequestContext<M24MediaModel> {
    return new AdminApiRequest<M24MediaModel>('media', 'resources', siteId).withAuth().withBody(resource).post();
  }

  /**
   *
   * @param siteId - The ID of the site owning the media resource
   * @param resourceId - The ID of the media resource
   */
  public static deleteM24MediaResource(siteId: number, resourceId: number): RequestContext<string> {
    return new AdminApiRequest<string>('v2', 'media', 'delete')
      .withAuth()
      .withParams({
        site_id: siteId,
        resource_id: resourceId,
      })
      .delete();
  }

  /**
   * Delete multiple M24 media resources
   * @param siteId
   * @param resourceIds
   */
  public static deleteM24MediaResources(
    siteId: number,
    resourceIds: number[],
  ): RequestContext<{ site_id: number; resource_ids: number[] }, string> {
    const requestBody: { site_id: number; resource_ids: number[] } = {
      site_id: siteId,
      resource_ids: resourceIds,
    };
    return new AdminApiRequest<{ site_id: number; resource_ids: number[] }, string>('v2', 'media', 'delete', 'batch')
      .withAuth()
      .withBody(requestBody)
      .delete();
  }

  /**
   * Update certain attributes of a list of resources
   *
   * @param siteId - The ID of the site owning the media resources
   * @param resource_ids - The list of IDs of all the resources to be updated
   * @param resource - Partial  {@link M24MediaModel} resource object containing updated data
   */
  public static updateM24MediaResources(
    siteId: number,
    resource_ids: string,
    resource: Partial<M24MediaModel>,
  ): RequestContext<M24MediaModel, BatchUpdateResult> {
    return new AdminApiRequest<M24MediaModel, BatchUpdateResult>('media', 'resources', siteId, 'batch')
      .withParams({ resource_ids })
      .withBody(resource)
      .withAuth()
      .post();
  }

  /**
   * Upload a file to the DMS database, and save it as a media resource in the M24 database
   *
   * @param siteId - The ID of the site owning the media resource
   * @param file - The media file to be uploaded
   * @param type - The {@link MediaResourceType} of the file
   */
  public static uploadMediaToDms(siteId: number, file: File, type?: MediaResourceType): RequestContext<M24MediaModel> {
    const form = new FormData();
    form.append('site_id', String(siteId));
    form.append('file', file);
    if (type) form.append('type', type);
    return new AdminApiRequest<M24MediaModel>('media', 'upload').withAuth().withFormData(form).post();
  }

  /**
   * Get a list of media resources for a Site based on emsIds
   * @param siteId
   * @param emsIds
   */
  public static getEMSMediaResources(siteId: number, emsIds: number[]): RequestContext<Array<M24MediaModel>> {
    return new AdminApiRequest<Array<M24MediaModel>>('v2', 'media', 'resources', siteId)
      .withAuth()
      .withParams({ emsIds })
      .get();
  }

  /**
   * Publish a EMS media resource in M24 database and in EMS
   * @param siteId - ID of the site the user is publishing on.
   * @param media - Data on the media being published.
   */
  public static publishMediaResource(
    siteId: number,
    media: PublishM24Media,
  ): RequestContext<{ site_id: number; media: PublishM24Media }, M24MediaModel> {
    const requestBody: { site_id: number; media: PublishM24Media } = {
      site_id: siteId,
      media,
    };
    return new AdminApiRequest<{ site_id: number; media: PublishM24Media }, M24MediaModel>('v2', 'media', 'publish')
      .withAuth()
      .withBody(requestBody)
      .post();
  }

  /**
   * Delete a media resource from both the M24 database and the EMS.
   *
   * @param dmsId - The ID of the media resource in the DMS database
   * @param siteId - The ID of the site owning the media resource
   *
   * THIS IS NOT YET IMPLEMENTED IN BACKEND
   */
  public static deleteMediaFromEms(siteId: number, dmsId: number): RequestContext<string> {
    return new AdminApiRequest<string>('v2', 'media', 'delete', dmsId).withParams({ siteId }).withAuth().delete();
  }

  /**
   * Get a count of media resources for a Site
   *
   * @param siteId - The ID of the site to get media counts from
   */
  public static getMediaCount(siteId: number): RequestContext<MediaCountResponse> {
    return new AdminApiRequest<MediaCountResponse>('media', 'resources', siteId, 'count').withAuth().get();
  }

  // ***************************
  // ********* DM API **********
  // ***************************

  /**
   * Get a single DM object by its unique DiMu ID.
   *
   * @param uniqueId - The 'artifact.uniqueId' property used to get the object.
   */
  public static getDMArtifact(uniqueId: string): RequestContext<DMArtifact> {
    return new AdminApiRequest<DMArtifact>('dimu', 'artifact', uniqueId).withAuth().get();
  }

  /**
   *
   * @param queryParams
   */
  public static getDMSearchResult(
    queryParams?: Partial<GetDMSearchQueryParams>,
  ): RequestContext<DMQueryResponse<Partial<DMMediaModel>>, PaginationResult<Partial<DMMediaModel>>> {
    const start = queryParams?.start || 0;
    const rows = queryParams?.rows || 10;
    const owners = queryParams?.owners?.replace(/^[\W_]+|[\W_]+$/g, '');
    return new AdminApiRequest<DMQueryResponse<Partial<DMMediaModel>>, PaginationResult<Partial<DMMediaModel>>>(
      'dimu',
      'search',
    )
      .withAuth()
      .withParams({
        start,
        rows,
        query: queryParams?.query || undefined,
        owners: owners || undefined,
        subjects: queryParams?.subjects || undefined,
        has_location: queryParams?.has_location,
        has_pictures: queryParams?.has_pictures,
        sort:
          queryParams?.order_by && queryParams?.order_by !== DMSortType.RELEVANCE
            ? `${queryParams?.order_by} ${queryParams.order || SortDirection.ASC}`
            : undefined,
        types: queryParams?.types ? queryParams.types.join(',') : undefined,
        licenses: queryParams?.licenses ? queryParams?.licenses.join(',') : undefined,
        names: queryParams?.names ? queryParams?.names.join(',') : undefined,
        classification: queryParams?.classification ? queryParams?.classification.join(',') : undefined,
      })
      .withMapper((res) => ({
        start,
        rows,
        count: res?.response?.docs?.length ?? 0,
        totalCount: res?.response?.numFound ?? 0,
        items: res?.response?.docs || [],
      }))
      .get();
  }

  /**
   * Get a list of DM objects based on a list of unique IDs
   *
   * @param objectIds - list of DM uniqueId values, either as a array of strings or a single comma separated string
   */
  public static getDMObjects(
    objectIds: string,
  ): RequestContext<DMQueryResponse<Partial<DMMediaModel>>, Array<Partial<DMMediaModel>>> {
    return new AdminApiRequest<DMQueryResponse<Partial<DMMediaModel>>, Array<Partial<DMMediaModel>>>('dimu', 'objects')
      .withAuth()
      .withParams({ object_ids: objectIds })
      .withMapper((res) => res?.response.docs || [])
      .get();
  }

  public static getDimuOwners(country_code?: string): RequestContext<{ owners: Array<DimuOwner> }> {
    return new AdminApiRequest<{ owners: Array<DimuOwner> }>('dimu', 'owners')
      .withAuth()
      .withParams({ country_code })
      .get();
  }

  // ***************************
  // ********* KP API **********
  // ***************************

  /**
   * Find a processed Kulturio object (KulturPunkt or presentation document)
   *
   * @param kp_id - The Kulturio ID of the object to find
   */
  public static getKPObject(kp_id: number): RequestContext<KPProcessedFields> {
    return new AdminApiRequest<KPProcessedFields>('kp', 'objects', kp_id).withAuth().get();
  }

  /**
   * Find a full Kulturio object (KulturPunkt or presentation document)
   *
   * @param kp_id - The Kulturio ID of the object to find
   */
  public static getKPObjectFull(kp_id: number): RequestContext<KPObject> {
    return new AdminApiRequest<KPObject>('kp', 'objects', kp_id).withAuth().withParams({ full_object: true }).get();
  }

  /**
   * Find processed KP objects based on query parameters
   *
   * @param queryParams
   */
  public static getKPObjects(
    queryParams?: Partial<GetKPObjectsQueryParams>,
  ): RequestContext<GetKPObjectsResult<KPProcessedFields>> {
    return new AdminApiRequest<GetKPObjectsResult<KPProcessedFields>>('kp', 'objects')
      .withAuth()
      .withParams(queryParams)
      .get();
  }

  /**
   * Find full KP objects based on query parameters
   *
   * @param queryParams
   */
  public static getKPObjectsFull(
    queryParams?: Partial<GetKPObjectsQueryParams>,
  ): RequestContext<GetKPObjectsResult<KPObject>> {
    return new AdminApiRequest<GetKPObjectsResult<KPObject>>('kp', 'objects')
      .withAuth()
      .withParams({ ...queryParams, full_objects: true })
      .get();
  }

  public static getKPObjectsFromParentId(id: number): RequestContext<Array<KPProcessedFields>> {
    return new AdminApiRequest<Array<KPProcessedFields>>('kp', 'presentations', id, 'objects').withAuth().get();
  }

  /**
   * Find a Kulturio owner
   *
   * @param kp_id - The Kulturio ID of the owner to find
   */
  public static getKPOwner(kp_id: number): RequestContext<KPOwner> {
    return new AdminApiRequest<KPOwner>('kp', 'owners', kp_id).withAuth().get();
  }

  /**
   *
   * @param queryParams
   */
  public static getKPOwners(queryParams?: Partial<GetKPOwnersQueryParams>): RequestContext<GetKPOwnersResult> {
    return new AdminApiRequest<GetKPOwnersResult>('kp', 'owners').withAuth().withParams(queryParams).get();
  }

  // ***************************
  // ******** USER API *********
  // ***************************

  /**
   * Get user info for the user of the current session
   */
  public static getCurrentUserInfo(): RequestContext<UserInformation> {
    return new AdminApiRequest<UserInformation>('user', 'me').withAuth().get();
  }

  public static getUsers(siteId: number): RequestContext<{ users: Array<User> }, Array<User>> {
    return new AdminApiRequest<{ users: Array<User> }, Array<User>>('user', 'users')
      .withAuth()
      .withParams({ site_id: siteId })
      .withMapper((res) => res?.users || [])
      .get();
  }

  /** Find a user based on id or email
   *
   * @param id - ID of the user to find
   * @param email - Email of the user to find
   */
  public static getUser(id?: number, email?: string): RequestContext<User> {
    return new AdminApiRequest<User>('user', 'user').withParams({ id, email }).withAuth().get();
  }

  /**
   * Endpoint for triggering a sync between admin API and eKultur.
   * Syncing means that permissions to owners in m24 are updated based on the users permissions in eKultur.
   */
  public static syncEKulturUser(): RequestContext<void> {
    return new AdminApiRequest<void>('v2', 'user', 'sync', 'ekultur', 'roles').withAuth().get();
  }

  /**
   * Set noAccess role for a user for a given site
   *
   * @param userId - The uuid of the user
   * @param siteId - The id of the site
   * @param noAccess - The new boolean value for the noAccess role
   */
  public static setNoAccess(userId: string, siteId: number, noAccess: boolean): RequestContext<unknown> {
    return new AdminApiRequest<unknown>('v2', 'user', userId, 'sites', siteId, 'roles', 'noAccess')
      .withAuth()
      .withBody({ no_access: noAccess })
      .put();
  }

  /**
   * Endpoint to get a user's role for a given site.
   *
   * @param siteId - The id of the site
   */
  public static geUserSiteRole(siteId: number): RequestContext<{ role: string }> {
    return new AdminApiRequest<{ role: string }>('v2', 'user', 'site', siteId, 'role')
      .withAuth()
      .withParams({ site_id: siteId })
      .get();
  }

  /**
   * Endpoint to get a list of users and their roles for a given site.
   */
  public static getUserSiteRoles(siteId: number): RequestContext<{ users: User[] }, User[]> {
    return new AdminApiRequest<{ users: User[] }, User[]>('v2', 'site', siteId, 'list', 'users')
      .withAuth()
      .withParams({ site_id: siteId })
      .withMapper((res) => res?.users ?? [])
      .get();
  }

  // ***************************
  // ******* BLOCKS API ********
  // ***************************

  /**
   * Get a Block based on Block ID
   *
   * @param siteId - The ID of the site you are currently using
   * @param blockId - The ID of the Block you wish to fetch
   */
  public static getBlock(siteId: number, blockId: number): RequestContext<Block> {
    return new AdminApiRequest<Block>('site', 'blocks', siteId, blockId).withAuth().get();
  }

  /**
   * Save block
   *
   * @param blockId - The ID of the site you are currently using
   * @param block - The Block object to be saved
   */
  public static saveBlock(siteId: number, block: Partial<Block>): RequestContext<Block> {
    return new AdminApiRequest<Block>('site', 'blocks', siteId).withAuth().withBody(block).post();
  }

  /**
   * Get a list of Blocks associated with a Site.
   *
   * @param siteId - The ID of the Site.
   * @param queryParams - Query paramters for filtering etc.
   */
  public static getBlocks(
    siteId: number,
    queryParams?: Partial<GetBlocksQueryParams>,
  ): RequestContext<
    {
      blocks: Array<Block>;
      count: number;
      total_count: number;
    },
    PaginationResult<Block>
  > {
    const start = queryParams?.start || 0;
    const rows = queryParams?.rows || 10;
    return new AdminApiRequest<
      {
        blocks: Array<Block>;
        count: number;
        total_count: number;
      },
      PaginationResult<Block>
    >('v2', 'block', 'site', siteId)
      .withAuth()
      .withParams({
        ...(queryParams || {}),
        start,
        rows,
      })
      .withMapper((res) => ({
        start,
        rows,
        count: res?.total_count || 0,
        items: res?.blocks || [],
        totalCount: res?.total_count ?? 0,
      }))
      .get();
  }

  /**
   * List all blocks for a given page
   */
  public static getPageBlocks(
    pageId: number,
    queryParams?: Partial<GetPageBlocksQueryParams>,
  ): RequestContext<
    {
      blocks: Array<Block>;
      count: number;
      total_count: number;
    },
    PaginationResult<Block>
  > {
    const start = queryParams?.start || 0;
    const rows = queryParams?.rows || 10;
    return new AdminApiRequest<
      {
        blocks: Array<Block>;
        count: number;
        total_count: number;
      },
      PaginationResult<Block>
    >('site', 'page', 'blocks', pageId)
      .withAuth()
      .withParams({
        ...(queryParams || {}),
        start,
        rows,
      })
      .withMapper((res) => ({
        start,
        rows,
        count: res?.count ?? res?.blocks?.length ?? 0,
        items: res?.blocks || [],
        totalCount: res?.total_count ?? 0,
      }))
      .get();
  }

  /**
   * List all blocks for a given page V2
   */
  public static getPageBlocksV2(
    pageId: number,
    queryParams?: Partial<GetPageBlocksQueryParams>,
  ): RequestContext<
    {
      blocks: Array<Block>;
      count: number;
      total_count: number;
    },
    PaginationResult<Block>
  > {
    const start = queryParams?.start || 0;
    const rows = queryParams?.rows || 10;
    return new AdminApiRequest<
      {
        blocks: Array<Block>;
        count: number;
        total_count: number;
      },
      PaginationResult<Block>
    >('v2', 'block', 'page', pageId)
      .withAuth()
      .withParams({
        ...(queryParams || {}),
        start,
        rows,
      })
      .withMapper((res) => ({
        start,
        rows,
        count: res?.total_count ?? res?.blocks?.length ?? 0,
        items: res?.blocks || [],
        totalCount: res?.total_count ?? 0,
      }))
      .get();
  }

  /**
   * Update or create a new Block
   *
   * @param siteId - The ID of the site owning the block
   * @param block - The Block objeckt to be updated or created. If it contains an ID, it will be used to update an
   *                existing block, otherwise a new one will be created.
   */
  public static updateOrCreateBlock(siteId: number, block: Partial<Block>): RequestContext<Block> {
    return new AdminApiRequest<Block>('site', 'blocks', siteId).withAuth().withBody(block).post();
  }

  /**
   * Delete a block item
   *
   * @param siteId - The ID of the site owning the block
   * @param blockId - The ID of the block to be deleted
   */
  public static deleteBlock(siteId: number, blockId: number): RequestContext<Block> {
    return new AdminApiRequest<Block>('site', 'blocks', siteId, blockId).withAuth().delete();
  }

  // ***************************
  // ***** TRANSLATIONS API ****
  // ***************************

  /**
   *
   * @param includeAll
   */
  public static getLanguages(includeAll: boolean): RequestContext<{ languages: Array<Language> }, Array<Language>> {
    return new AdminApiRequest<{ languages: Array<Language> }, Array<Language>>('translations', 'languages')
      .withAuth()
      .withParams({ include_all: includeAll })
      .withMapper((res) => res?.languages || [])
      .get();
  }

  // ***************************
  // ****** EMPLOYEES API ******
  // ***************************

  /**
   *  Get a single Employee based on ID
   *
   * @param siteId - The ID of the associated Site
   * @param employeeId - The ID of the Employee to find
   */
  public static getEmployee(siteId: number, employeeId: number): RequestContext<Employee> {
    return new AdminApiRequest<Employee>('employee', 'employee', siteId, employeeId).withAuth().get();
  }

  /**
   *  Get a single Employee based on ID
   *
   * @param employeeId - The ID of the Employee to find
   */
  public static getEmployeeSimple(employeeId: number): RequestContext<Employee> {
    return new AdminApiRequest<Employee>('employee', employeeId).withAuth().get();
  }

  /**
   * Get all Employees associated with a site
   *
   * @param siteId - The ID of the Site
   * @param queryParams - Parameters used for filtering and sorting
   */
  public static getEmployees(
    siteId: number,
    queryParams?: Partial<GetEmployeesQueryParams>,
  ): RequestContext<{
    result: Array<Employee>;
    count: number;
    total_count: number;
  }> {
    return new AdminApiRequest<{
      result: Array<Employee>;
      count: number;
      total_count: number;
    }>('employee', 'employees', siteId)
      .withAuth()
      .withParams(queryParams || {})
      .get();
  }

  /**
   * Create a new Employee
   *
   * @param siteId - The Site on which to create the Employee
   * @param employee - The Employee object to be created
   */
  public static createEmployee(siteId: number, employee: Partial<Employee>): RequestContext<Employee> {
    return new AdminApiRequest<Employee>('employee', 'employee')
      .withAuth()
      .withBody({ site_id: siteId, ...employee })
      .post();
  }

  /**
   * Update an existing Employee
   *
   * @param siteId - The Site of the Employee to update
   * @param employeeId - The ID of the Employee to update
   * @param employee - The Employee object containing updated data
   */
  public static updateEmployee(
    siteId: number,
    employeeId: number,
    employee: Partial<Employee>,
  ): RequestContext<Employee> {
    return new AdminApiRequest<Employee>('employee', 'employee', siteId, employeeId)
      .withAuth()
      .withBody(employee)
      .put();
  }

  /**
   * Delete an Employee
   *
   * @param employeeId - The ID of the Employee to delete
   */
  public static deleteEmployee(employeeId: number): RequestContext<Employee> {
    return new AdminApiRequest<Employee>('employee', 'employee', employeeId).withAuth().delete();
  }

  // *****************************
  // ******* LANGUAGES API *******
  // *****************************

  /**
   * Get all locales
   */
  public static getAllLocales(): RequestContext<Array<string>> {
    return new AdminApiRequest<Array<string>>('language', 'locales').withAuth().get();
  }

  // ***************************
  // ******* MODULES API *******
  // ***************************

  /**
   * Get a count of modules (including forms) for a Site
   *
   * @param siteId - The ID of the site to get module counts from
   * @param type - Count a specific module type (eg. 'form')
   * @param anyStatus - If true, count all modules, not just with status=published
   */
  public static getModuleCount(
    siteId: number,
    type?: string,
    anyStatus?: boolean,
  ): RequestContext<ModuleCountResponse> {
    return new AdminApiRequest<ModuleCountResponse>('module', 'modules', siteId, 'count')
      .withAuth()
      .withParams({ type, any_status: anyStatus })
      .get();
  }

  /**
   * Get a list of modules for a Site
   *
   * @param siteId - The ID of the site to get modules from
   */
  public static getFormModules(siteId: number, locale: string): RequestContext<{ modules: FormModule[] }> {
    return new AdminApiRequest<{ modules: FormModule[] }>('module', 'modules', siteId)
      .withAuth()
      .withParams({ type: 'form', locale })
      .get();
  }

  public static getFormModuleContentCounts(siteId: number): RequestContext<{ counts: Record<string, number> }> {
    return new AdminApiRequest<{ counts: Record<string, number> }>('module', 'modules', siteId, 'counts')
      .withAuth()
      .get();
  }

  /**
   * Get a single module with it's content
   * @param moduleId
   */
  public static getFormModule(moduleId: number): RequestContext<FormModule> {
    return new AdminApiRequest<FormModule>('module', 'module', moduleId).withAuth().get();
  }

  /**
   * Get a single module's content (form submissions)
   * @param moduleId
   */
  public static getFormModuleContent(
    siteId: number,
    moduleId: number,
    page = 0,
    rowsPerPage = 10,
    order = 'desc',
  ): RequestContext<{
    contents: Array<FormModuleResponse>;
    total_count: number;
  }> {
    return new AdminApiRequest<{
      contents: Array<FormModuleResponse>;
      total_count: number;
    }>('module', 'contents', siteId)
      .withAuth()
      .withParams({
        module_id: moduleId,
        start: page,
        rows: rowsPerPage,
        order,
      })
      .get();
  }

  public static deleteFormModule(siteId: number, moduleId: number): RequestContext<void> {
    return new AdminApiRequest<void>('module', 'module', siteId, moduleId).withAuth().delete();
  }

  public static deleteFormModuleContentById(
    moduleId: number,
    contentId: number,
  ): RequestContext<DeleteFormModuleContentByIdRequestBody, void> {
    return new AdminApiRequest<DeleteFormModuleContentByIdRequestBody, void>('module_content', moduleId)
      .withBody({
        module_content_ids: [contentId],
      })
      .withAuth()
      .delete();
  }

  public static deleteFormModuleContentByDaysOld(moduleId: number, days: 90 | 180 | 365): RequestContext<void> {
    return new AdminApiRequest<void>('module_content', moduleId, `over-${days}-days-old`).withAuth().delete();
  }

  public static deleteAllFormModuleContent(moduleId: number): RequestContext<void> {
    return new AdminApiRequest<void>('module_content', 'modules', moduleId).withAuth().delete();
  }

  public static async exportFormModuleContent(
    siteId: number,
    moduleId: number,
    format: 'xls' | 'csv' | 'pdf',
  ): Promise<Response> {
    const url = new URL(`/api/module/contents/export/${siteId}`, Settings.ADMIN_API_HOST);
    url.searchParams.append('module_id', String(moduleId));
    url.searchParams.append('format', format);
    const token = localStorage.getItem(Settings.TOKEN_LOCAL_STORAGE_KEY);

    const headers = new Headers();

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    return fetch(url, {
      credentials: 'include',
      headers,
    });
  }

  public static saveFormModule(module: Partial<FormModule>): RequestContext<FormModule> {
    return new AdminApiRequest<FormModule>('module', 'module').withAuth().withBody(module).post();
  }

  public static getRootFolders(siteId: number): RequestContext<{ folders: PageFolder[] }> {
    return new AdminApiRequest<{ folders: PageFolder[] }>('v2', 'page', siteId, 'folders').withAuth().get();
  }

  public static createFolder(
    siteId: number,
    folder: Pick<PageFolder, 'title' | 'parent_id'>,
  ): RequestContext<PageFolder> {
    return new AdminApiRequest<PageFolder>('v2', 'page', siteId, 'folders').withAuth().withBody(folder).post();
  }

  public static deleteFolder(siteId: number, folderId: number): RequestContext<void> {
    return new AdminApiRequest<void>('v2', 'page', siteId, 'folders', folderId).withAuth().delete();
  }

  public static updateFolder(
    siteId: number,
    folderId: number,
    folder: Partial<PageFolder>,
  ): RequestContext<PageFolder> {
    return new AdminApiRequest<PageFolder>('v2', 'page', siteId, 'folders', folderId).withAuth().withBody(folder).put();
  }

  public static movePageToFolder(pageId: number, folderId: number | undefined | null): RequestContext<unknown> {
    return new AdminApiRequest<unknown>('v2', 'page', pageId, 'move-to-folder')
      .withAuth()
      .withBody({
        folder_id: folderId,
      })
      .put();
  }
}

type DeleteFormModuleContentByIdRequestBody = {
  module_content_ids: number[];
};

// Helper types for getting the response shape from inline types in RequestContext
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type RequestContextResponseShape<Type> = Type extends RequestContext<infer _request, infer Resp> ? Resp : never;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type ApiFunctionReturnType<T extends keyof typeof Api> = ReturnType<(typeof Api)[T]>;

/**
 * Get the response shape for a given API function
 * usage: `ApiResponseShape<'getSite'>`
 */
export type ApiResponseShape<T extends keyof typeof Api> = RequestContextResponseShape<ApiFunctionReturnType<T>>;
