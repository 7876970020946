import React, { useEffect, useState } from 'react';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { DMManualBlockModel } from '../../../../declarations/models/blocks/DMBlock';
import { DMArtifact } from '../../../../declarations/models/DMMediaModel';
import DMBlockPreview from './DMBlockPreview';
import { Api } from '../../../../services/Api';

export const DMBlockManualPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const dmBlock = block as DMManualBlockModel;
  let dmLink = false;

  if (dmBlock?.dmLink) {
    dmLink = true;
  }

  const [museum, setMuseum] = useState<DMArtifact>();

  useEffect(() => {
    if (dmBlock.museum) {
      if (dmBlock.museum.uniqueId) {
        const ctx = Api.getDMArtifact(dmBlock.museum.uniqueId);

        ctx
          .fetchDirect(null)
          .then((res) => !!res && setMuseum(res))
          .finally(ctx.abort);
      }
    } else setMuseum(undefined);
  }, [dmBlock.museum?.uniqueId]);

  return <DMBlockPreview dmBlock={dmBlock} dmItems={dmBlock.dmItems} museum={museum} dmLink={dmLink} />;
};

export default DMBlockManualPreview;
