import React, { FC, ReactNode } from 'react';
import { Divider, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
import Container from './Container';
import { MenuItem as MenuItemModel, MenuItemDivider } from '../declarations/models/MenuItem';
import MenuItem from './MenuItem';
import { useStore } from './store/Store';
import { MaterialSymbol } from './MaterialSymbol';

export interface MainMenuProps {
  open: boolean;
  toggleMenuOpen: () => void;
  items?: Array<MenuItemModel | MenuItemDivider>;
}

export const MainMenu: FC<MainMenuProps> = ({ open, toggleMenuOpen, items }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();

  // Define a type for subItems that matches the existing MenuItem interface
  type SubMenuItem = {
    path: string;
    label: string;
  };

  let languageMenuItems: Array<SubMenuItem> = [];
  if (state?.selectedSite?.site_contents) {
    languageMenuItems = state.selectedSite.site_contents.map(
      (content): SubMenuItem => ({
        path: `/language-settings/${content.locale}`,
        label: tCommon(`Languages.${content.locale}`),
      }),
    );
  }

  const MENU_ITEMS: Array<MenuItemModel | MenuItemDivider> = [
    {
      icon: <MaterialSymbol name='home' />,
      path: '/dashboard',
      label: tComponents('MainMenu.dashboard'),
    },
    {
      icon: <MaterialSymbol name='folder_open' />,
      path: '/content',
      label: tComponents('MainMenu.content'),
    },
    {
      icon: <CustomIcon name='calendar_event' />,
      path: '/events',
      label: tComponents('MainMenu.events'),
    },
    {
      icon: <MaterialSymbol name='photo_library' />,
      path: '/media',
      label: tComponents('MainMenu.media'),
    },
    {
      icon: <MaterialSymbol name='badge' />,
      path: '/employees',
      label: tComponents('MainMenu.employees'),
    },
    {
      icon: <CustomIcon name='card' />,
      path: '/cards',
      label: tComponents('MainMenu.cards'),
    },
    {
      icon: <MaterialSymbol name='assignment' />,
      path: '/forms',
      label: tComponents('MainMenu.forms'),
    },
    {
      icon: <MaterialSymbol name='settings' />,
      path: '/settings/',
      label: tComponents('MainMenu.settings.site'),
    },
    {
      icon: <MaterialSymbol name='language' />,
      path: '/language-settings',
      label: tComponents('MainMenu.settings.language.title'),
      subItems: [
        ...languageMenuItems,
        {
          path: 'language-settings/create',
          label: tComponents('MainMenu.settings.language.create'),
        },
      ],
    },
  ];

  const menuItems = items || MENU_ITEMS;

  // Access control function
  const currentUser = state?.currentUser;
  const currentUserRole = currentUser?.current_site_role;
  const isSuperAdmin = currentUser?.user?.is_super_admin;

  const hasAccess = (menuItem: MenuItemModel): boolean => {
    if (isSuperAdmin) {
      return true;
    }

    const role = currentUserRole;

    if (role === 'admin') {
      return true;
    }
    if (role === 'editor') {
      if (
        menuItem.path === '/settings/' ||
        menuItem.path.startsWith('/settings/') ||
        menuItem.path === '/language-settings' ||
        menuItem.path.startsWith('/language-settings')
      ) {
        return false;
      }
      return true;
    }

    if (role === 'user') {
      if (menuItem.path === '/media' || menuItem.path === '/events') {
        return true;
      }
      return false;
    }
    return false;
  };

  const hasAccessToSubItem = (subItem: SubMenuItem): boolean => {
    if (isSuperAdmin) {
      return true;
    }

    const role = currentUserRole;

    if (role === 'admin') {
      return true;
    }
    if (role === 'editor') {
      if (subItem.path.startsWith('/language-settings')) {
        return false;
      }
      return true;
    }
    if (role === 'user') {
      return false;
    }
    return false;
  };

  // Recursive function to filter menu items
  const filterMenuItems = (
    menuItemsToFilter: Array<MenuItemModel | MenuItemDivider>,
  ): Array<MenuItemModel | MenuItemDivider> => {
    return menuItemsToFilter.reduce<Array<MenuItemModel | MenuItemDivider>>((filtered, item) => {
      if (item === 'divider') {
        filtered.push(item);
      } else if (hasAccess(item)) {
        const newItem = { ...item };
        if (item.subItems) {
          // Filter subItems based on access control
          const filteredSubItems = item.subItems.filter((subItem) => hasAccessToSubItem(subItem));
          if (filteredSubItems.length > 0) {
            newItem.subItems = filteredSubItems;
            filtered.push(newItem);
          }
        } else {
          filtered.push(newItem);
        }
      }
      return filtered;
    }, []);
  };

  const filteredMenuItems = filterMenuItems(menuItems);

  // Type guard to check if an item is a MenuItemModel
  const isMenuItemModel = (item: MenuItemModel | MenuItemDivider): item is MenuItemModel => {
    return item !== 'divider';
  };

  return (
    <Container gap={0} top left py={1} fullHeight fullWidth>
      <List sx={{ width: '100%' }} role='navigation'>
        {filteredMenuItems?.map((value: MenuItemModel | MenuItemDivider) => {
          if (value === 'divider') {
            return <Divider key={Math.random()} sx={{ my: 2 }} />;
          }
          if (isMenuItemModel(value)) {
            return <MenuItem key={value.label} item={value} menuIsOpen={open} toggleMenuOpen={toggleMenuOpen} />;
          }
          return null; // This should never happen, but satisfies TypeScript
        })}
      </List>
    </Container>
  );
};

export default MainMenu;
