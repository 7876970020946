import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { Box } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import { useTranslation } from 'react-i18next';
import { Draggable } from '../../../../declarations/Draggable';
import DraggableCollapseContainer from '../../../../components/DraggableCollapseContainer';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import PageListManualBlockItemHeader from './PageListManualBlockItemHeader';
import PageListManualBlockItemSkinSelector from './PageListManualBlockItemSkinSelector';
import PageListManualBlockItemContent from './PageListManualBlockItemContent';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { PageListItem, PageListManualBlock } from '../../../../declarations/models/blocks/PageListBlocks';
import { Api } from '../../../../services/Api';
import { Page } from '../../../../declarations/models/Page';
import { EventsManualBlock } from '../../../../declarations/models/blocks/EventsBlocks';

export interface PageListManualBlockItemProps {
  index: number;
  onReorder: (fromIndex: number, toIndex: number) => void;
  onDelete: (index: number) => void;
  color?: string;
  hideTitleInput?: boolean;
  overrideItemPath?: string;
  hideHighlightCheckbox?: boolean;
  hideSkinSelector?: boolean;
  disableReordering?: boolean;
}

type ItemPath = `${BlockPath}.items.${number}`;

export default function PageListManualBlockItem({
  index,
  onReorder,
  onDelete,
  color,
  hideTitleInput,
  overrideItemPath,
  hideHighlightCheckbox = false,
  hideSkinSelector = false,
  disableReordering = false,
}: PropsWithChildren<PageListManualBlockItemProps>) {
  const { blockPath } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const eventManager = useEditorEventManager();
  const [page, setPage] = useState<Partial<Page>>();
  const itemPath: ItemPath = (overrideItemPath as ItemPath) || `${blockPath}.items.${index}`;

  const {
    field: { value: visible, onChange: setVisible },
  } = useController<BlockSpecificPage<PageListManualBlock | EventsManualBlock>, `${ItemPath}.visible`>({
    name: `${itemPath}.visible`,
  });

  const item = useWatch<BlockSpecificPage<PageListManualBlock | EventsManualBlock>, ItemPath>({
    name: itemPath,
  }) as PageListItem;

  const currentPageSiteId = useWatch<Page, `site_id`>({
    name: `site_id`,
  });

  useEffect(() => {
    if (item?.id) {
      const ctx = Api.getPageSimple(item.id);
      ctx.fetchDirect(undefined).then(setPage).finally(ctx.abort);
    }
  }, [item.id]);

  let imgMimeType = '';
  if (page?.image?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${page?.image?.mimetype}`;
  }

  return (
    <DraggableCollapseContainer
      useEventHandler={useEditorEventHandler}
      useEventManager={eventManager}
      disabled={disableReordering}
      color={color}
      unmountOnExit
      type={Draggable.PAGE_LIST_ITEM}
      imageThumbnail={
        page?.image ? (
          <img
            src={`${page?.image.url}?dimension=250x250&${imgMimeType}`}
            alt={page.title}
            style={{
              height: '50px',
              aspectRatio: '1/1',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        ) : (
          <div style={{ width: '125px', height: '75px' }} />
        )
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      headerContent={(open) => (
        <PageListManualBlockItemHeader
          overrideExpandIcon={
            <Box p={1} ml={2}>
              <PageStatusCircle status={page?.status} />
            </Box>
          }
          title={page?.title}
          siteName={currentPageSiteId === page?.site_id ? undefined : page?.site_name}
          secondaryTitle={item.local?.title}
          open={open}
        />
      )}
      index={index}
      onReorder={onReorder}
      secondaryAction={
        <>
          {!hideSkinSelector && <PageListManualBlockItemSkinSelector path={`${itemPath}.local`} />}
          <DeleteButton
            onConfirm={() => onDelete(index)}
            component='IconButton'
            tooltip={tCommon('remove')}
            deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
          />
        </>
      }>
      <PageListManualBlockItemContent
        hideTitleInput={hideTitleInput}
        index={index}
        blockPath={blockPath}
        overrideItemPath={overrideItemPath}
        hideHighlightCheckbox={hideHighlightCheckbox}
      />
    </DraggableCollapseContainer>
  );
}
