import { Site } from '@/declarations/models/Site';
import { Page } from '@/declarations/models/Page';
import { PageTreeFilterValues } from '@/declarations/PageTreeFilterValues';
import { PageFolder } from '@/declarations/models/Folder';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

/**
 * Figure out which page id is the default (frontpage) for the selected site and language
 * @param site
 * @param locale
 */
export function findDefaultPageId(site: Site | null | undefined, locale: string | null | undefined): number | null {
  if (!site || !locale) {
    return null;
  }
  const siteContent = site.site_contents?.find((c) => c.locale === locale);
  if (!siteContent) {
    return null;
  }
  return siteContent.default_page_id || null;
}

/**
 * Filter function for pages and events
 * @param filterValues
 * @param page
 */
export function includePage(filterValues: PageTreeFilterValues, page: Page): boolean {
  // Filter categories
  if (filterValues.main_category && filterValues.main_category !== page.main_category) {
    return false;
  }

  if (
    !!filterValues.sub_categories?.length &&
    (!page.sub_categories?.length || !filterValues.sub_categories.some((c) => page.sub_categories?.includes(c)))
  ) {
    return false;
  }

  // Filter statuses
  if (!!filterValues.statuses?.length && !filterValues.statuses.includes(page.status)) {
    return false;
  }
  // Filter by custom query
  // This is applied last for performance reasons
  return (
    !filterValues?.query ||
    String(page.title || '')
      .trim()
      .toLowerCase()
      .includes(filterValues.query.toLowerCase())
  );
}

export function includeInPageIncludingFolder(
  filterValues: PageTreeFilterValues,
  page: Page,
  folder: PageFolder | null,
): boolean {
  if (!folder && !filterValues.query) {
    return page.page_folder_id == null && includePage(filterValues, page);
  }
  if (!folder && filterValues.query) {
    return includePage(filterValues, page);
  }
  if (folder && filterValues.query) {
    return (
      page.page_folder_id != null &&
      [folder.id, ...(folder.descendant_ids || [])].includes(page.page_folder_id) &&
      includePage(filterValues, page)
    );
  }

  if (folder) {
    return page.page_folder_id === folder.id && includePage(filterValues, page);
  }

  return page.page_folder_id == null && includePage(filterValues, page);
}
