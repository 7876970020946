import useFormInput, { FormInputBaseProps } from '@/hooks/useFormInput';
import { Autocomplete, TextField } from '@mui/material';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Flatten } from '@/utils/flatten';

export interface AutocompleteInputProps<T extends FieldValues, O extends Flatten<PathValue<T, Path<T>>>>
  extends FormInputBaseProps<T> {
  options: O[];
  getOptionLabel: (o: O) => string;
  getOptionKey: (o: O) => string | number;
  placeholder?: string;
  helperText?: ReactNode;
}

export function AutocompleteInput<T extends FieldValues, O extends Flatten<PathValue<T, Path<T>>>>({
  helperText,
  options,
  getOptionLabel,
  getOptionKey,
  ...baseProps
}: AutocompleteInputProps<T, O>) {
  const { t: tCommon } = useTranslation('common');
  const {
    field: { value, onChange },
    isError,
    label,
  } = useFormInput<T>(baseProps);

  return (
    <Autocomplete
      sx={{
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      }}
      id='tags-autocomplete'
      renderInput={(params) => (
        <TextField {...params} label={label ?? tCommon('search')} error={isError} helperText={helperText} />
      )}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(_, selectedValues) => {
        onChange(selectedValues);
      }}
      value={value}
      multiple
      filterSelectedOptions
      clearOnBlur={false}
      isOptionEqualToValue={(opt, val) => {
        return getOptionKey(opt) === getOptionKey(val);
      }}
      // hack. see index.css
      noOptionsText={<span className='hide-my-parent' />}
    />
  );
}
