/**
 * Returns a deeply copied copy of the provided value.
 * Use with caution on large objects. May not be optimized.
 * @param value
 */
import { Dictionary } from '../declarations/Dictionary';

export function deepCopy<T>(value: T): T {
  if (value === null || value === undefined || typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(deepCopy) as unknown as T;
  }

  return Object.keys(value).reduce((copy: T, key: string) => {
    return {
      ...copy,
      [key]: deepCopy(value[key as keyof T]),
    };
  }, {} as T);
}

/**
 * Validates that an object has all the expected props present.
 *
 * Useful in e.g typechecks.
 * @param value The value to validate
 * @param props The expected props
 */
export function hasProps<T>(value: T, ...props: Array<string>): boolean {
  if (typeof value !== 'object' || value === null || Array.isArray(value)) {
    return false;
  }
  const keys = Object.keys(value);
  if (!keys.length) {
    return false;
  }
  return !props.length || props.every((prop) => keys.includes(prop));
}

/**
 * Deeply compare two values
 * @param initial
 * @param current
 */
export function isDeepEqual(initial: unknown, current: unknown): boolean {
  if (initial === null || current === null || typeof initial !== typeof current || typeof initial !== 'object') {
    return initial === current;
  }
  if (Array.isArray(initial) && Array.isArray(current)) {
    if (initial?.length !== current?.length) {
      return false;
    }
    return initial.every((i, idx) => isDeepEqual(i, current[idx]));
  }
  const initialKeys = Object.keys(initial as Dictionary<never>);
  const currentKeys = Object.keys(current as Dictionary<never>);
  if (initialKeys.length !== currentKeys.length) {
    return false;
  }
  const allKeys = Object.keys([...initialKeys, ...currentKeys].reduce((acc, val) => ({ ...acc, [val]: 1 }), {}));
  return allKeys.every((key: string) =>
    isDeepEqual((initial as Dictionary<never>)[key], (current as Dictionary<never>)[key]),
  );
}

function isEmpty(v: unknown): boolean {
  return (
    v == null || v === '' || (Array.isArray(v) && v.length === 0) || (typeof v === 'object' && !Object.keys(v).length)
  );
}

/**
 * Removes all empty arrays, objects, strings and private properties (keys prefixed with `_`) from an object
 * @param obj
 */
export function removeEmptyAndPrivateValues(obj: object): object {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  const newObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.hasOwn(obj, key) && !key.startsWith('_')) {
      // @ts-ignore
      const value: any = obj[key];
      if (value != null) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            // @ts-ignore
            const newArray = value
              .map((v) => {
                if (v != null && typeof v === 'object') {
                  return removeEmptyAndPrivateValues(v);
                }
                return v;
              })
              .filter((v) => !isEmpty(v));
            if (newArray.length > 0) {
              // @ts-ignore
              newObj[key] = newArray;
            }
          }
        } else if (typeof value === 'object') {
          if (Object.keys(value)?.length < 1) {
            // eslint-disable-next-line no-continue
            continue;
          }
          // @ts-ignore
          const o = removeEmptyAndPrivateValues(value);
          if (Object.keys(o)?.length > 0) {
            // @ts-ignore
            newObj[key] = o;
          }
        } else if (value !== '') {
          // @ts-ignore
          newObj[key] = value;
        }
      }
    }
  }
  return newObj;
}

/**
 * Sorts an object's properties alphabetically.
 * Only sorts the first level, not child objects or arrays
 * @param obj
 */
export function sortObjectAlphabetically(obj: object): object {
  return Object.keys(obj)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        // @ts-ignore
        [key]: obj[key],
      }),
      {},
    );
}

export function isObjectType<T>(obj: T | any, ...keys: Array<string>): obj is T {
  return !keys.some((k) => !Object.hasOwn(obj, k));
}

export function notNil<T>(value: T | null | undefined): value is T {
  return value != null;
}
