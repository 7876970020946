import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import RadioInput from '@/components/forms/RadioInput';
import SelectInput from '@/components/forms/SelectInput';
import Styles from '@/assets/js/Styles';
import { set, useController, useWatch } from 'react-hook-form';
import { noop } from '@/utils/functions';
import Container from '../../../../components/Container';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import M24PageFinder from '../../../../components/Finder/M24PageFinder/M24PageFinder';
import { Page } from '../../../../declarations/models/Page';
import PageListManualBlockItem from './PageListManualBlockItem';
import { EventsManualBlock as EventsManualBlockModel } from '../../../../declarations/models/blocks/EventsBlocks';
import EventsDisplayOptions from './EventsDisplayOptions';

export const EventsManualBlock: BlockComponent = () => {
  const { blockPath, block } = useCurrentBlock();
  const { t } = useTranslation('components');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);
  const [normalPageFinderOpen, setNormalPageFinderOpen] = useState<boolean>(false);

  const currentBlock = block as EventsManualBlockModel;

  const {
    fields: items,
    append,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<EventsManualBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  const {
    field: { value: showAllLinkPage, onChange: onChangeShowAllLinkPage },
  } = useController<BlockSpecificPage<EventsManualBlockModel>, `${BlockPath}.showAllLinkPage`>({
    name: `${blockPath}.showAllLinkPage`,
  });

  const gridSizeOptions = ['auto', '3', '4', '5', '6'];
  const mobileGridOptions = ['auto', 'grid', 'list', 'horizontal'];

  return (
    <Container fullWidth column gap={4} left>
      <M24PageFinder
        open={pageFinderOpen}
        onClose={() => setPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          pages.forEach((page) => {
            if (page.source.id) {
              append({ id: page.source.id });
            }
          });
        }}
        multiSelect
        isEvent
      />

      <M24PageFinder
        hideSiteSelector
        open={normalPageFinderOpen}
        onClose={() => setNormalPageFinderOpen(false)}
        onSelectionConfirmed={(pages) => {
          if (pages.length > 0) {
            onChangeShowAllLinkPage(pages[0].source);
          }
        }}
      />

      <Container fullWidth column gap={0}>
        {items?.map((item, index) => {
          return (
            <PageListManualBlockItem
              key={String(item.id) + index} // eslint-disable-line react/no-array-index-key
              index={index}
              onReorder={move}
              onDelete={remove}
              color={Styles.Colors.STRONG_GREEN_TRANSPARENT}
              hideTitleInput
            />
          );
        })}
      </Container>
      <Button variant='contained' color='secondary' onClick={() => setPageFinderOpen(true)}>
        {t('EventsBlock.FinderButton')}
      </Button>

      {!currentBlock.view?.includes('list') && (
        <Container fullWidth left gap={2} mt={2} mb={2}>
          <SelectInput
            hideNoSelectionOption
            defaultValue='auto'
            options={gridSizeOptions}
            getOptionKey={(gridSize) => `size-${gridSize}`}
            getOptionLabel={(gridSize) => `${gridSize}`}
            path={`${blockPath}.gridSize`}
            label={t('CardModuleBlock.GridSizeLabel')}
          />

          <SelectInput
            hideNoSelectionOption
            defaultValue='auto'
            options={mobileGridOptions}
            getOptionKey={(o) => `mobileGrid-${o}`}
            getOptionLabel={(o) => t(`CardModuleViewTypeSelector.CardModuleViewTypeVariant.${o}`)}
            path={`${blockPath}.gridMobileBehaviour`}
            label={t('CardModuleBlock.GridMobileBehaviourLabel')}
          />
        </Container>
      )}

      <EventsDisplayOptions blockPath={blockPath} />

      <Container mt={4} column left fullWidth>
        <Typography variant='subtitle2'>{t('EventsBlock.ShowAllLinkPageTitle')}</Typography>
        {showAllLinkPage && (
          <PageListManualBlockItem
            index={0}
            onReorder={noop}
            onDelete={() => onChangeShowAllLinkPage(null)}
            overrideItemPath={`${blockPath}.showAllLinkPage`}
            color='primary'
            hideHighlightCheckbox
            hideSkinSelector
            disableReordering
          />
        )}
        <Button onClick={() => setNormalPageFinderOpen(true)} variant='contained' color='secondary'>
          {t('EventsBlock.ShowAllLinkPageButtonLabel')}
        </Button>
      </Container>
    </Container>
  );
};

export default EventsManualBlock;
