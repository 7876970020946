import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import ActionMenu from '../../../components/ActionMenu';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { Alignment, TextAlignment } from '../../../declarations/models/Alignment';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { BaseBlock } from '../../../declarations/models/blocks/BaseBlock';

const ALIGNMENT_OPTIONS: Array<TextAlignment> = [Alignment.LEFT, Alignment.RIGHT, Alignment.NORMAL, Alignment.WIDE];
const TEXT_ALIGNMENT_ICONS: Record<TextAlignment, ReactNode> = {
  [Alignment.NORMAL]: <MaterialSymbol name='format_align_center' />,
  [Alignment.LEFT]: <MaterialSymbol name='format_align_left' />,
  [Alignment.RIGHT]: <MaterialSymbol name='format_align_right' />,
  [Alignment.WIDE]: <MaterialSymbol name='format_align_justify' />,
};

export const PageBlockTextPositionSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BaseBlock & { position?: TextAlignment }>, `${typeof blockPath}.position`>({
    name: `${blockPath}.position`,
  });

  return (
    <ActionMenu
      id='text-alignment-selector'
      ariaLabel={tAria('components.PageBlockTextPositionSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockTextPositionSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? TEXT_ALIGNMENT_ICONS[value] : TEXT_ALIGNMENT_ICONS.normal}>
      {ALIGNMENT_OPTIONS.map((alignmentOption) => (
        <MenuItem key={alignmentOption} onClick={() => onChange(alignmentOption)} selected={value === alignmentOption}>
          <ListItemIcon>{TEXT_ALIGNMENT_ICONS[alignmentOption]}</ListItemIcon>
          <ListItemText>{tComponents(`PageBlockTextPositionSetting.options.${alignmentOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockTextPositionSetting;
