import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { Badge, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { QuoteBlock, QuoteTextSize } from '../../../declarations/models/blocks/QuoteBlock';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import ActionMenu from '../../../components/ActionMenu';
import { useCurrentBlock } from '../CurrentBlockProvider';

const SIZE_OPTIONS: Array<QuoteTextSize> = Object.values(QuoteTextSize);
const SIZE_ICONS: Record<QuoteTextSize, ReactNode> = {
  [QuoteTextSize.NORMAL]: <MaterialSymbol name='format_size' />,
  [QuoteTextSize.MEDIUM]: (
    <Badge badgeContent='M' color='secondary'>
      <MaterialSymbol name='format_size' />
    </Badge>
  ),
  [QuoteTextSize.LARGE]: (
    <Badge badgeContent='L' color='secondary'>
      <MaterialSymbol name='format_size' />
    </Badge>
  ),
  [QuoteTextSize.EXTRA_LARGE]: (
    <Badge badgeContent='XL' color='secondary'>
      <MaterialSymbol name='format_size' />
    </Badge>
  ),
};

export const PageBlockTextSizeSetting: SettingsComponent = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<QuoteBlock>, `${typeof blockPath}.quote_size`>({
    name: `${blockPath}.quote_size`,
  });

  return (
    <ActionMenu
      id='text-size-selector'
      ariaLabel={tAria('components.PageBlockTextSizeSetting.ActionMenuLabel')}
      tooltip={tComponents('PageBlockTextSizeSetting.Tooltip')}
      tooltipPlacement='top'
      icon={value ? SIZE_ICONS[value] : SIZE_ICONS['h-normal']}>
      {SIZE_OPTIONS.map((sizeOption) => (
        <MenuItem
          key={sizeOption}
          onClick={() => onChange(sizeOption)}
          selected={value === sizeOption}
          aria-label={tComponents(`PageBlockTextSizeSetting.options.${sizeOption}`)}>
          <ListItemText>{tComponents(`PageBlockTextSizeSetting.options.${sizeOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageBlockTextSizeSetting;
