import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import Image from '@/components/Image';
import Container from '../../components/Container';
import Styles from '../../assets/js/Styles';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';
import { useControlledFieldArray } from '../../hooks/useControlledFieldArray';
import { Page } from '../../declarations/models/Page';
import { Section as SectionModel } from '../../declarations/models/Section';
import { deepCopy } from '../../utils/object';
import { BaseBlock } from '../../declarations/models/blocks/BaseBlock';
import { SectionType } from '../../declarations/models/SectionType';
import SectionsController from '../../components/Section/SectionsController';
import Section from '../../components/Section/Section';
import SectionSettings from './SectionSettings';
import { Draggable } from '../../declarations/Draggable';
import PageEditorSectionContent from './PageEditorSectionContent';
import AddPageSectionButtonGroup from './AddPageSectionButtonGroup';
import { CurrentSectionProvider, SectionsPath } from './CurrentSectionProvider';
import PageMetadata from './PageMetadata';
import { ChildrenProp } from '../../declarations/ChildrenProp';
import { useEditorData, useEditorModel } from '../lib/components/EditorDataProvider';
import { usePageEditorFormState } from './PageEditorFormStateProvider';
import { EditorEventType, useEditorEventHandler, useEditorEventManager } from '../lib/eventManager/EditorEventManager';
import { MainCategory } from '../../declarations/Category';
import EventData from './EventData';
import { CardSectionId, ListCardsForPage } from './ListCardsForPage';

export const PageEditorContent: FC<ChildrenProp> = () => {
  const { t } = useTranslation('common');
  const { t: tComp } = useTranslation('components');
  const editorConfiguration = useEditorConfiguration();
  const eventManager = useEditorEventManager();
  const { siteId, modelId, skins, pageLocale, categories } = useEditorData();
  const [isReordering, setIsReordering] = useState<boolean>(false);
  useEditorEventHandler(async (event) => {
    if (event.type === EditorEventType.DRAGGING) {
      setIsReordering(true);
    }
  });

  const {
    fields: sections,
    append,
    move,
    remove,
    insert,
  } = useControlledFieldArray<Page, SectionsPath>({ name: 'content.sections' });

  const appendAndOpenSection = (item: SectionModel) => {
    append(item);
    // Wrap in timeout to allow the new component to render before firing the event. It's the new component that's listening for the event.
    setTimeout(() => {
      eventManager.fireEvent(EditorEventType.BLOCK_OR_SECTION_ADDED, {
        anchorId: `content.sections.${sections.length}`,
      });
    });
  };

  const defaultExpanded = useMemo<Array<string>>(() => {
    return sections.filter((s) => !s._collapse).map((s) => s.id);
  }, [sections]);

  const handleDuplicateSection = (section: SectionModel & { id?: string }, index: number) => {
    const sectionCopy = deepCopy(section);
    if (sectionCopy) {
      delete sectionCopy.id;
      sectionCopy.blocks?.forEach((block: BaseBlock & { id?: string }) => {
        // eslint-disable-next-line no-param-reassign
        delete block.id;
      });
      sectionCopy._recentlyPasted = true;
      insert(index + 1, sectionCopy);
    }
  };

  const getSectionTitle = (section: SectionModel): string => {
    if (section.type === SectionType.ARTICLE_BODY && section.introSection) {
      return t(`SectionType.article__body_introSection`);
    }
    return t(`SectionType.${section.type}`);
  };

  const model = useEditorModel<Page>();
  const { setCurrentFormData } = usePageEditorFormState();

  let imgMimeType = '';
  if (model?.image?.mimetype?.includes('png')) {
    imgMimeType = `mediaType=${model?.image?.mimetype}`;
  }

  useEffect(() => {
    if (model) {
      setCurrentFormData(model);
    }
  }, [model, setCurrentFormData]);

  const isMissingEventDate =
    model?.main_category === MainCategory.EVENT &&
    (model?.event_data?.time_periods ?? []).map((tp) => tp.start_date).filter(Boolean).length === 0;

  return (
    <Container
      sx={{ position: 'relative', backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY }}
      column
      gap={0}
      spaceBetween
      fullHeight
      fullWidth>
      <Container sx={{ overflow: 'auto' }} column top left fullHeight fullWidth>
        <SectionsController defaultExpanded={defaultExpanded} pr={0} pl={0} left fullHeight eventManager={eventManager}>
          <Section
            sectionId='page-editor-metadata'
            title={t('metadata')}
            subTitle={model.description || ''}
            color={Styles.Colors.LIGHT_GREY}
            disableDiffuse
            headerActions={
              <>
                <Image
                  key={model.image?.url}
                  sx={{
                    overflow: 'hidden',
                    objectFit: 'cover',
                    flex: 1,
                    width: '40px',
                    height: '40px',
                    borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
                    border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                    mr: 2,
                    backgroundColor: Styles.Colors.MEDIUM_GREY,
                  }}
                  src={`${model.image?.url}?dimension=250x250&${imgMimeType}`}
                />
                <MaterialSymbol name='info' />
              </>
            }
            useEventManager={eventManager}>
            <PageMetadata isReordering={isReordering} />
          </Section>
          {model.main_category === MainCategory.EVENT && (
            <Section
              sectionId='page-editor-event-data'
              title={t('eventData')}
              headerActions={<CustomIcon name='calendar_event' />}
              color={Styles.Colors.STRONG_GREEN_TRANSPARENT}
              subTitle={
                isMissingEventDate && (
                  <Alert
                    sx={{
                      background: 'none',
                      fontStyle: 'italic',
                    }}
                    severity='warning'>
                    <span
                      style={{
                        padding: '0 0.25rem',
                      }}>
                      {tComp('PageEditor.missingEventDateWarning')}
                    </span>
                  </Alert>
                )
              }>
              <EventData />
            </Section>
          )}
          {sections.map(
            (section, i) =>
              section.type !== 'article__links' && (
                <CurrentSectionProvider key={section.id} section={section} sectionIndex={i}>
                  <Section
                    isHidden={section.visible === 'hidden'}
                    sectionId={`${'content.sections' as SectionsPath}.${i}`}
                    title={getSectionTitle(section)}
                    subTitle={section.title || ''}
                    childrenCount={section.blocks?.length || 0}
                    headerActions={
                      <>
                        <SectionSettings
                          onRemoveSection={() => remove(i)}
                          onDuplicateSection={() => handleDuplicateSection(section, i)}
                        />
                        {!editorConfiguration.isSectionAvailable(section.type) && (
                          <MaterialSymbol name='error' color='error' />
                        )}
                      </>
                    }
                    type={Draggable.SECTION}
                    index={i}
                    onReorder={move}
                    value={section}
                    useEventManager={eventManager}
                    isPasted={section._recentlyPasted}
                    draggable>
                    <PageEditorSectionContent useEventManager={eventManager} />
                  </Section>
                </CurrentSectionProvider>
              ),
          )}
          <Section
            sectionId={CardSectionId}
            title={t('SectionType.article__links')}
            color={Styles.Colors.LIGHT_GREY}
            headerActions={<MaterialSymbol name='info' />}
            useEventManager={eventManager}>
            <ListCardsForPage
              siteId={siteId}
              pageId={modelId}
              skins={skins}
              locale={pageLocale}
              categories={categories}
            />
          </Section>
        </SectionsController>
      </Container>
      <Container
        px={3}
        pt={1}
        pb={1}
        spaceBetween
        fullWidth
        sx={{ backgroundColor: 'white', borderTop: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}` }}>
        <AddPageSectionButtonGroup onAddSection={appendAndOpenSection} />
      </Container>
    </Container>
  );
};

export default PageEditorContent;
