/* istanbul ignore file */

/**
 * Possible ways to save a page creation form
 */
export enum PageCreationSaveType {
  SAVE_AND_EDIT = 'save_and_edit',
  SAVE_AND_CREATE_NEW = 'save_and_create_new',
  SAVE_AND_CLOSE = 'save_and_close',
}
