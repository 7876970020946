import React, { FC } from 'react';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { MediaLicense } from '../declarations/models/MediaLicense';
import Container from './Container';
import CCIcon from './CCIcon';
import { licenseMenuProps } from '../editor/MediaEditor/MediaEditorFields/EditMediaLicenseCreditsFields';

export interface LicenseSelectorProps {
  license: MediaLicense | null;
  onChange: (license: MediaLicense | null) => void;
}

export const LicenseSelector: FC<LicenseSelectorProps> = ({ license, onChange }) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  const val = license === MediaLicense.NONE || !license ? '' : license;

  return (
    <Container sx={{ zIndex: 1 }} fullWidth>
      <TextField
        color='secondary'
        variant='outlined'
        label={tComponents('LicenseSelector.SelectLicense')}
        value={val}
        onChange={(e) => {
          onChange?.((e.target.value as MediaLicense) || null);
        }}
        InputProps={{
          startAdornment: val && (
            <InputAdornment position='start'>
              <IconButton aria-label={tCommon('clear')} onClick={() => onChange?.(null)}>
                <MaterialSymbol name='clear' color='error' />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        SelectProps={{ MenuProps: licenseMenuProps }}
        select>
        {Object.values(MediaLicense)
          .filter((cc) => cc !== MediaLicense.NONE)
          .map((cc) => {
            const selected = cc === license;
            return (
              <MenuItem
                key={cc}
                aria-label={tCommon(`MediaLicense.Label.${cc}`)}
                value={cc}
                selected={selected}
                disabled={selected}>
                <CCIcon license={cc} sx={{ width: '100%' }} width='120px' hideDescription={selected} />
              </MenuItem>
            );
          })}
      </TextField>
    </Container>
  );
};

export default LicenseSelector;
