import { useEffect, useRef, useState } from 'react';

/**
 * Use this hook to debounce a value or a callback
 * @param ms Delay in MS
 * @param value The value to debounce
 * @param onChange An optional callback to run with the debounced value
 * @return The debounced value
 */
export function useDebounce<T>(ms: number, value: T, onChange?: (debouncedValue: T) => void): T {
  const isInitialRun = useRef<boolean>(true);
  const callback = useRef(onChange);
  callback.current = onChange;

  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (isInitialRun.current) {
      isInitialRun.current = false;
      return;
    }
    const timerId = setTimeout(() => {
      setDebouncedValue(value);
      callback.current?.(value);
    }, ms);
    return () => clearTimeout(timerId);
  }, [value, ms]);

  return debouncedValue;
}
