import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ExternalMediaModel, isYouTubeMediaModel } from '../../../../../declarations/models/ExternalMediaModel';
import { getYouTubePlaybackUrl } from '../../../../../utils/url';
import Container from '../../../../../components/Container';
import { ChildrenProp } from '../../../../../declarations/ChildrenProp';

export interface YouTubeContentProps {
  item: ExternalMediaModel;
}

export const YouTubeContent: FC<YouTubeContentProps & ChildrenProp> = ({ item }) => {
  if (!isYouTubeMediaModel(item)) {
    console.error('YouTubeContent rendered with an incorrect model. This should never happen.');
    return null;
  }

  return (
    <Container p={2} top left fullWidth>
      <Box
        component='iframe'
        width='560px'
        height='315px'
        src={getYouTubePlaybackUrl(item.local?.youtubeId) || ''}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        sx={{
          border: 'none',
        }}
      />
    </Container>
  );
};

export default YouTubeContent;
