import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { DeepPartial, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Flatten } from '../../utils/flatten';

export interface FormConfiguration {
  variant: 'filled' | 'outlined' | 'standard';
  color: 'primary' | 'secondary';
  fullWidth: boolean;
}

export interface FormProps<T> extends Partial<FormConfiguration> {
  initialValue?: T;
  onSubmit?: (value: T) => void | Promise<void>;
}

const DEFAULT_FORM_CONFIGURATION: FormConfiguration = {
  color: 'secondary',
  variant: 'standard',
  fullWidth: true,
};

const FormConfigurationContext = createContext<FormConfiguration>(DEFAULT_FORM_CONFIGURATION);

export function useFormConfiguration(): FormConfiguration {
  return useContext(FormConfigurationContext);
}

export default function Form<T extends FieldValues>({
  children,
  onSubmit,
  initialValue,
  variant,
  color,
  fullWidth,
}: PropsWithChildren<FormProps<T>>) {
  const form = useForm<T>({ mode: 'onBlur' });

  const formConfig = useMemo<FormConfiguration>(
    () => ({
      variant: variant || DEFAULT_FORM_CONFIGURATION.variant,
      color: color || DEFAULT_FORM_CONFIGURATION.color,
      fullWidth: fullWidth || DEFAULT_FORM_CONFIGURATION.fullWidth,
    }),
    [variant, color, fullWidth],
  );

  useEffect(() => {
    if (initialValue) {
      form.reset((initialValue as Flatten<DeepPartial<T>>) || null);
    }
  }, [initialValue, form]);

  return (
    <FormConfigurationContext.Provider value={formConfig}>
      <FormProvider {...form}>
        <form
          style={{ width: '100%' }}
          data-testid='form'
          onSubmit={form.handleSubmit(async (v) => onSubmit?.(v as T))}>
          {children}
        </form>
      </FormProvider>
    </FormConfigurationContext.Provider>
  );
}
