import React, { ChangeEventHandler, FC, PropsWithChildren, useState } from 'react';
import GoogleMapModal from '@/components/GoogleMapModal';
import Container from '@/components/Container';
import { Button, TextField, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';
import { Location } from '@/declarations/models/Location';
import { GoogleMapMarker } from '@/declarations/GoogleMapMarker';

export interface SiteLocationFieldProps {
  value: Location;
  onChange: (value: SiteLocationFieldProps['value']) => void;
  defaultCenter?: { lat: number; lng: number };
}

export const SiteLocationField: FC<PropsWithChildren<SiteLocationFieldProps>> = ({
  children,
  value,
  onChange,
  defaultCenter,
}) => {
  const { t: tComponents } = useTranslation('components');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onChangeFullAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      ...value,
      content: { ...value.content, address: { ...value.content?.address, full_address: e.target.value || '' } },
    });
  };

  const onChangeStreetName: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      ...value,
      content: { ...value.content, address: { ...value.content?.address, street_name: e.target.value || '' } },
    });
  };

  const onChangePostalCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      ...value,
      content: { ...value.content, address: { ...value.content?.address, postal_code: e.target.value || '' } },
    });
  };
  const onChangePostalTown: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      ...value,
      content: { ...value.content, address: { ...value.content?.address, postal_town: e.target.value || '' } },
    });
  };

  const onMapSelectConfirmed = (marker: GoogleMapMarker, address?: string) => {
    const newVal = { ...value, latitude: marker.position.lat, longitude: marker.position.lng };
    if (address) {
      newVal.content = { ...value.content, address: { ...value.content?.address, full_address: address } };
    }
    onChange(newVal);
    setModalOpen(false);
  };

  return (
    <>
      <GoogleMapModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelectConfirmed={onMapSelectConfirmed}
        initialMarker={
          value && value.longitude && value.latitude
            ? {
                position: {
                  lat: parseFloat(value.latitude.toString()),
                  lng: parseFloat(value.longitude.toString()),
                },
                title: value.title,
              }
            : undefined
        }
        zoom={10}
        defaultCenter={defaultCenter}
      />
      <Container column gap={2}>
        <TextField
          label={tComponents('Settings.SiteSettings.SiteLocationsSettings.titleInputLabel')}
          value={value?.title || ''}
          onChange={(e) => onChange({ ...value, title: e.target.value || '' })}
          autoComplete='off'
          fullWidth
          color='secondary'
        />
        <TextField
          label={tComponents('Settings.SiteSettings.SiteLocationsSettings.full_address')}
          value={value.content?.address?.full_address || ''}
          onChange={onChangeFullAddress}
          autoComplete='off'
          fullWidth
          color='secondary'
        />
        <TextField
          label={tComponents('Settings.SiteSettings.SiteLocationsSettings.street_name')}
          value={value.content?.address?.street_name || ''}
          onChange={onChangeStreetName}
          autoComplete='off'
          fullWidth
          color='secondary'
        />
        <TextField
          label={tComponents('Settings.SiteSettings.SiteLocationsSettings.postal_code')}
          value={value.content?.address?.postal_code || ''}
          onChange={onChangePostalCode}
          autoComplete='off'
          fullWidth
          color='secondary'
        />
        <TextField
          label={tComponents('Settings.SiteSettings.SiteLocationsSettings.postal_town')}
          value={value.content?.address?.postal_town || ''}
          onChange={onChangePostalTown}
          autoComplete='off'
          fullWidth
          color='secondary'
        />

        <Typography variant='caption'>{`Lat: ${value?.latitude || '-'}, Long: ${value?.longitude || '-'}`}</Typography>
        <Container spaceBetween fullWidth>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<MaterialSymbol name='edit' fill />}
            onClick={() => setModalOpen(true)}>
            {tComponents('Settings.SiteSettings.SiteLocationsSettings.changeCoordinates')}
          </Button>
          <Container>{children}</Container>
        </Container>
      </Container>
    </>
  );
};
