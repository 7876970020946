import { createContext, useContext } from 'react';
import { EditorConfiguration } from './EditorConfiguration';

export const Configuration = createContext<EditorConfiguration | null>(null);

/**
 * Provide direct access to the currently active EditorConfiguration
 */
export function useEditorConfiguration(): EditorConfiguration {
  const config = useContext(Configuration);
  if (!config) {
    throw new Error('EditorConfigurationProvider not initialized');
  }
  return config;
}
