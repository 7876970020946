import React, { ChangeEvent, DragEvent, FC, useRef } from 'react';
import { Button, ButtonBase, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MaterialSymbol, MaterialSymbolProps } from '@/components/MaterialSymbol';
import { MimeTypeDMS } from '@/declarations/models/MimeType';
import Container from '../../../components/Container';
import Styles from '../../../assets/js/Styles';

interface UploadMediaStageProps {
  validFiles: Array<File>;
  setValidFiles: (files: Array<File>) => void;
}

const ALLOWED_MIME_TYPES = Object.values(MimeTypeDMS);

function isDragEvent(event: ChangeEvent | DragEvent): event is DragEvent {
  return !!event && Object.keys(event).includes('dataTransfer');
}

const getFileType = (fileType: string) => {
  return fileType.slice(0, 5);
};

const getFileTypeIcon = (fileType: string, symbolProps?: Omit<MaterialSymbolProps, 'name'>) => {
  switch (getFileType(fileType)) {
    case 'image':
      return <MaterialSymbol name='image' fill {...symbolProps} />;
    case 'audio':
      return <MaterialSymbol name='music_note' {...symbolProps} />;
    case 'video':
      return <MaterialSymbol name='movie' fill {...symbolProps} />;
    case 'appli':
      return <MaterialSymbol name='description' fill {...symbolProps} />;
  }
};

export const UploadMediaStage: FC<UploadMediaStageProps> = ({ validFiles, setValidFiles }) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFilesSelected = (event: ChangeEvent<HTMLInputElement> | DragEvent): void => {
    const files = isDragEvent(event) ? event.dataTransfer?.files : event.target.files;
    if (files?.length) {
      const validNew = Array.from(files).filter(
        (file) =>
          !validFiles.some((f) => f.name === file.name && f.size === file.size) &&
          ALLOWED_MIME_TYPES.includes(file.type as MimeTypeDMS),
      );
      const invalidCount = files.length - validNew.length;
      if (invalidCount > 0) {
        enqueueSnackbar(t('MediaView.MediaUpload.InvalidFilesRemoved', { count: invalidCount }), { variant: 'info' });
      }
      setValidFiles([...validFiles, ...validNew]);
    }
  };

  const handleFileDropped = (event: DragEvent) => {
    event.preventDefault();
    handleFilesSelected(event);
  };

  const removeFileFromUpload = (file: File, index: number) => {
    setValidFiles([...validFiles.slice(0, index), ...validFiles.slice(index + 1)]);
  };

  return (
    <Container column top fullWidth fullHeight>
      <input
        ref={inputRef}
        type='file'
        aria-labelledby='file-upload-drop-zone'
        onChange={handleFilesSelected}
        multiple
        hidden
        accept={ALLOWED_MIME_TYPES.join(',')}
      />
      <ButtonBase
        id='file-upload-drop-zone'
        type='button'
        onClick={() => inputRef.current?.click()}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleFileDropped}
        sx={{
          width: '100%',
          height: '200px',
          maxWidth: '1200px',
          border: `4px dashed ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
          borderRadius: Styles.Dimensions.RADIUS_ROUND_LG,
          backgroundColor: Styles.Colors.LIGHTEST_GREY,
          ':hover': {
            cursor: 'pointer',
            backgroundColor: Styles.Colors.LIGHT_GREY,
          },
        }}>
        <Container column gap={0}>
          <Container>
            <MaterialSymbol name='upload' sx={{ height: '48px', width: '48px', fontSize: 48 }} />
            <Typography variant='button' fontSize='48px'>
              {t('MediaView.MediaUpload.Upload')}
            </Typography>
          </Container>
          <Typography variant='subtitle1'>{t('MediaView.MediaUpload.HelpText')}</Typography>
        </Container>
      </ButtonBase>
      <Container fullWidth left wrap px={4} mt={4} sx={{ minHeight: '64px' }}>
        {validFiles.map((file: File, index) => (
          <Card
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant='outlined'
            sx={{
              maxWidth: '400px',
              backgroundColor: Styles.Colors.LIGHTEST_GREY,
            }}>
            {getFileType(file.type) === 'image' ? (
              <CardMedia
                component='img'
                height='200'
                image={URL.createObjectURL(file)}
                alt={file.name}
                title={file.name}
                sx={{
                  borderBottom: `1px solid ${Styles.Colors.LIGHT_GREY}`,
                  height: '200px',
                  minWidth: '200px',
                }}
              />
            ) : (
              <CardMedia
                sx={{
                  height: '200px',
                  minWidth: '200px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderBottom: `1px solid ${Styles.Colors.LIGHT_GREY}`,
                }}>
                {getFileTypeIcon(file.type, {
                  sx: {
                    fontSize: '4em',
                  },
                })}
              </CardMedia>
            )}
            <CardContent>
              <Typography variant='caption' sx={{ maxWidth: '200px', textAlign: 'center' }} noWrap>
                {file.name}
              </Typography>
            </CardContent>
            <CardActions sx={{ flexDirection: 'row-reverse' }}>
              <Button onClick={() => removeFileFromUpload(file, index)} endIcon={<MaterialSymbol name='cancel' fill />}>
                {tCommon('remove')}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Container>
    </Container>
  );
};

export default UploadMediaStage;
