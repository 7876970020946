import React, { FC, ReactNode, useRef, useState } from 'react';
import { Button, ButtonProps as ButtonPropsType, IconButtonProps, Menu, Tooltip, TooltipProps } from '@mui/material';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface ActionMenuButtonProps extends Pick<IconButtonProps, 'color' | 'edge' | 'sx'> {
  id: string;
  icon?: ReactNode;
  ariaLabel: string;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  keepOpenOnClick?: boolean;
  text?: string;
  width?: string;
  disabled?: boolean;
  ButtonProps?: ButtonPropsType;
}

/**
 * An implementation of the MUI Menu using a Button as a trigger.
 * Convenient for making a menu with dropdowns from a ButtonGroup.
 * This component is responsible for correctly handling A11Y.
 * @param id ID of the trigger. This should be unique
 * @param ariaLabel A good description of the menu, and it's purpose
 * @param icon The Icon to use for the trigger
 * @param children All menu items
 * @param tooltip An optional tooltip to display while hovering the button
 * @param tooltipPlacement The placement of the tooltip. Selected automatically if not defined
 * @param keepOpenOnClick When false (the default), the menu is closed when an item is selected/clicked
 * @param labelText An optional text next to the icon
 * @constructor
 */
export const ActionMenuButton: FC<ActionMenuButtonProps & ChildrenProp> = ({
  id,
  ariaLabel,
  icon,
  text = '',
  width = '100%',
  children,
  tooltip,
  tooltipPlacement,
  keepOpenOnClick = false,
  disabled = false,
  ButtonProps,
}) => {
  const actionMenuButton = useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <>
      <Tooltip title={tooltip || ''} placement={tooltipPlacement} arrow style={{ width }}>
        <Button
          ref={actionMenuButton}
          id={`${id}-button`}
          aria-label={ariaLabel}
          aria-haspopup='true'
          aria-controls={`${id}-menu`}
          startIcon={text?.length === 0 ? null : icon}
          disabled={disabled}
          onClick={open}
          {...ButtonProps}>
          {text?.length === 0 ? icon : text}
        </Button>
      </Tooltip>
      <Menu
        id={`${id}-menu`}
        anchorEl={actionMenuButton.current}
        open={isOpen}
        onClose={close}
        onClick={() => !keepOpenOnClick && close()}
        PaperProps={{ style: { maxHeight: '350px' } }}>
        {children}
      </Menu>
    </>
  );
};

export default ActionMenuButton;
