import React, { FC } from 'react';
import { Collapse, CSSObject, Typography, TypographyProps } from '@mui/material';
import Container from './Container';

export interface DropIndicatorArrowProps {
  visible: boolean;
  label?: string;
  color?: CSSObject['color'];
  borderStyle?: 'dotted' | 'dashed' | 'solid';
  arrowStyle?: 'dotted' | 'dashed' | 'solid';
  typographyProps?: Partial<Omit<TypographyProps, 'sx'>>;
  spacing?: number;
  noArrow?: boolean;
}

const ARROW_SIZE = 20;
const BORDER_SIZE = 2;
const BORDER_RADIUS = 6;
const ARROW_POINT_RADIUS = 4;

const HYP = Math.floor(Math.sqrt(2 * ARROW_SIZE ** 2));

export const DropIndicatorArrow: FC<DropIndicatorArrowProps> = ({
  visible,
  label,
  color = 'grey',
  borderStyle = 'solid',
  arrowStyle = 'solid',
  spacing = 0,
  typographyProps = {},
  noArrow = false,
}) => {
  return (
    <Collapse
      in={visible}
      timeout='auto'
      easing={{ enter: 'ease', exit: 'ease' }}
      sx={{ width: '100%', py: spacing / 2 }}>
      <Container
        role='alert'
        aria-labelledby='indicator-label'
        sx={{
          '&::before, &::after': {
            display: noArrow ? 'none' : undefined,
            position: 'absolute',
            content: '" "',
            width: `${ARROW_SIZE}px`,
            height: `${ARROW_SIZE}px`,
          },
          '&::before': {
            top: `${BORDER_SIZE / 2 - ARROW_SIZE / 2}px`,
            right: `${-(HYP - ARROW_POINT_RADIUS) / 4}px`,
            backgroundColor: 'white',
            transform: 'rotateZ(45deg)',
            border: `${BORDER_SIZE}px ${arrowStyle} ${color}`,
            borderTop: 'none',
            borderRight: 'none',
            borderTopLeftRadius: `${BORDER_RADIUS}px`,
            borderBottomRightRadius: `${BORDER_RADIUS}px`,
            borderBottomLeftRadius: `${ARROW_POINT_RADIUS}px`,
          },
          '&::after': {
            top: `-${HYP / 2 - BORDER_SIZE - 1}px`,
            height: `${HYP - BORDER_SIZE * 2}px`,
            right: 0,
            borderRight: `${BORDER_SIZE}px ${arrowStyle} ${color}`,
            borderRadius: `${BORDER_RADIUS}px`,
            backgroundColor: 'transparent',
          },
          position: 'relative',
          overflow: 'visible',
          height: 0,
          border: 'none',
          borderBottom: `${BORDER_SIZE}px ${borderStyle} ${color}`,
          p: 0,
          my: `${ARROW_SIZE}px`,
        }}
        left
        fullWidth>
        {!!label && (
          <Typography
            id='indicator-label'
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              left: '50%',
              right: '50%',
              px: 1,
              textAlign: 'center',
              width: 'max-content',
              transform: 'translateX(-50%)',
            }}
            {...typographyProps}>
            {label}
          </Typography>
        )}
      </Container>
    </Collapse>
  );
};

export default DropIndicatorArrow;
