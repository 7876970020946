import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from './MaterialSymbol';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

export interface GoogleMapsAutoCompleteProps {
  googleMapId: string;
  onSelect?: (selectedPlace: { placeType: PlaceType; location: { lat: number; lng: number }; address: string }) => void;
}

/**
 * Autocomplete search for use with GoogleMap component.
 * Google Maps script must be loaded in the maps component.
 * @param googleMapId Maps component div must have id the same ID as googleMapId
 * @param onSelect
 */
export const GoogleMapsAutoComplete: FC<GoogleMapsAutoCompleteProps> = ({ onSelect, googleMapId }) => {
  const { t: tComponents } = useTranslation('components');
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const mapDivRef = useRef<HTMLDivElement | null>(null);
  const mapInstanceRef = useRef<any | null>(null);
  const placesService = useRef<any | null>(null);

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (mapDivRef.current === null) {
      const map = document.getElementById(googleMapId);
      if (map === null) {
        return undefined;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mapDivRef.current = map;
    }

    if (mapInstanceRef.current === null) {
      mapInstanceRef.current = new window.google.maps.Map(mapDivRef.current);
    }

    if (!placesService.current && (window as any).google) {
      placesService.current = new window.google.maps.places.PlacesService(mapInstanceRef.current);
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, googleMapId]);

  return (
    <Autocomplete
      id='google-map-autocomplete'
      sx={{ width: 300 }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={tComponents('GoogleMapsAutoComplete.NoOptionsText')}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (onSelect && newValue) {
          const request = {
            placeId: newValue.place_id,
            fields: ['name', 'geometry', 'formatted_address'],
          };
          placesService.current.getDetails(request, (place: any, status: any) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              place &&
              place.geometry &&
              place.geometry.location
            ) {
              onSelect({
                placeType: newValue,
                location: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
                address: place.formatted_address,
              });
            }
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextField
          {...params}
          variant='outlined'
          color='secondary'
          label={tComponents('GoogleMapsAutoComplete.AddLocationLabel')}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <MaterialSymbol name='location_on' sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part: any, index: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={index} component='span' sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant='body2' color='text.secondary'>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleMapsAutoComplete;
