import React, { PropsWithChildren, ReactNode, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, useTheme } from '@mui/material';
import { ConnectDragSource } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { useSectionsController } from './SectionsController';
import Container from '../Container';
import Styles from '../../assets/js/Styles';
import { Draggable } from '../../declarations/Draggable';
import DragAndDropReorderingItemContainer from '../DragAndDropReorderingItemContainer';
import ActionDropzone from './ActionDropzone';
import DragAndDropIcon from '../DragAndDropIcon';
import {
  EditorEventType,
  EventManagerApi,
  useEditorEventHandler,
} from '../../editor/lib/eventManager/EditorEventManager';
import { sleep } from '../../utils/async';
import { MaterialSymbol } from '../MaterialSymbol';

export interface SectionProps<T> {
  /**
   * A unique ID for this section. Used to control expansion state
   */
  sectionId: string;
  /**
   * The title of the section
   */
  title: string;
  /**
   * The subtitle of the section
   */
  subTitle?: ReactNode;
  /**
   * Additional items to render on the right side of the section
   */
  headerActions?: ReactNode;
  /**
   * If set to true, a Drag-handle will appear to the right, making it possible to move the section using drag-and-drop.
   * Supply the `onReorder`-callback to actually do the move-operation.
   */
  draggable?: boolean;
  /**
   * Used when `draggable` is set to true.
   *
   * The type should be unique for each resource. Given the same type, sections can be moved across containers.
   */
  type?: Draggable;
  /**
   * Used when `draggable` is set to true.
   *
   * The current index of this item
   */
  index?: number;
  /**
   * Used when `draggable` is set to true.
   *
   * The value of this section.
   */
  value?: T;
  /**
   * Requires `index`
   * Used when `draggable` is set to true.
   *
   * Callback to actually perform the reorder.
   * This is only called when an item is moved WITHIN the same container, meaning only it's position is changed.
   * To accept items from OTHER containers as well, implement the `onRemove()`- AND `onAdd` callbacks.
   *
   * @param fromIndex The item's old index
   * @param toIndex The item's new index
   */
  onReorder?: (fromIndex: number, toIndex: number) => void;
  /**
   * Requires `index`
   * Used when `draggable` is set to true.
   *
   * This callback is called when an item FROM ANOTHER CONTAINER with THE SAME `type` is dropped/added to this container.
   *
   * @param item The item to add
   * @param index The index of where to add the item
   */
  onAdd?: (item: T | null, index: number) => void;
  /**
   * Requires `index`
   * Used when `draggable` is set to true.
   *
   * This callback is called when an item is REMOVED from the container, and dropped in ANOTHER CONTAINER OF THE SAME `type`
   *
   * NOTE: Do not confuse this callback with the `onDelete` callback
   *
   * @param index The index of the element to remove
   */
  onRemove?: (index: number) => void;
  color?: 'default' | 'primary' | 'secondary' | string;
  useEventManager?: EventManagerApi;
  isCut?: boolean;
  isPasted?: boolean;
  isHidden?: boolean;
  disableElevation?: boolean;
  childrenCount?: number;
  disableDiffuse?: boolean;
}

export default function Section<T>({
  children,
  sectionId,
  title,
  subTitle,
  headerActions,
  draggable = false,
  type = Draggable.SECTION,
  index,
  value,
  onAdd,
  onReorder,
  onRemove,
  color = 'primary',
  isCut = false,
  isPasted = false,
  isHidden = false,
  useEventManager,
  disableElevation = false,
  childrenCount,
  disableDiffuse = false,
}: PropsWithChildren<SectionProps<T>>) {
  const { t } = useTranslation('common');
  const sectionController = useSectionsController();
  if (!sectionController) {
    throw new Error('SectionsController not initialized');
  }

  // FIXME: this component is a UI wrapper, and should probably not be responsible for setting any form state.
  const {
    field: { value: recentlyPastedValue, onChange: onChangeRecentlyPasted },
  } = useController({
    name: `${sectionId}._recentlyPasted`,
    defaultValue: false,
  });

  const expanded = sectionController?.isExpanded(sectionId);
  const container = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  let bgColor = Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR;
  if (color && color !== 'default') {
    if (color === 'primary' || color === 'secondary') bgColor = theme.palette[color].main;
    else bgColor = color;
  }

  if (recentlyPastedValue && expanded) {
    // reset recentlyPastedValue once the section is expanded
    setTimeout(() => onChangeRecentlyPasted(false));
  }

  let blockBorder = 'none';
  let elevation = 0;
  if (!disableElevation && sectionController?.isNestedSection && expanded) {
    bgColor = '#fff';
    blockBorder = `1px solid ${Styles.Colors.LIGHT_GREY}`;
    elevation = 5;
  }

  const textColor = color === 'secondary' ? theme.palette.getContrastText(bgColor) : undefined;

  const handleMove = (from: number, to: number) => {
    if (onReorder) {
      onReorder(from, to);
      if (from !== to) {
        useEventManager?.fireEvent(EditorEventType.SECTION_REORDERED, {
          type,
          anchorId: sectionId,
        });
      }
    } else {
      throw new Error('onReorder() missing when drag-operation requires it');
    }
  };

  useEditorEventHandler(async (event) => {
    if (event.type === EditorEventType.PREVIEW_BLOCK_CLICKED || event.type === EditorEventType.BLOCK_OR_SECTION_ADDED) {
      const sectionPathParts = sectionId.split('.');
      if (sectionPathParts?.length <= 2) {
        return;
      }
      const sectionPath = `content.sections.${sectionPathParts[2]}`;
      if (!event.payload.anchorId?.includes(sectionPath)) {
        return;
      }

      let sleepTime = 0;
      if (event.payload.anchorId.includes(sectionId)) {
        if (!sectionController?.parentSectionController?.isExpanded(sectionPath)) {
          sleepTime += 500;
          sectionController?.parentSectionController?.toggleExpand(sectionPath);
        }
      }
      if (event.payload.anchorId === sectionId) {
        if (!sectionController?.isExpanded(sectionId)) {
          sleepTime += 500;
          sectionController?.toggleExpand(sectionId);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (event.type === EditorEventType.PREVIEW_BLOCK_CLICKED && !event.payload.scrollIntoView) {
          return;
        }
        await sleep(sleepTime);
        container.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  const renderPlaceholder = () => (
    <Container spaceBetween fullWidth>
      <ActionDropzone
        type={type}
        label={t('Draggable.DropHereToAbort')}
        sx={{
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
        }}
        fullWidth
      />
    </Container>
  );

  const renderDropIndicator = (visible: boolean) => {
    return (
      <ActionDropzone
        type={type}
        label={t('Draggable.MoveHere')}
        color='secondary'
        borderStyle='solid'
        sx={{
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
        }}
        canDrop={visible}
        fullWidth
      />
    );
  };

  const renderSection = (dragHandleRef: ConnectDragSource, dragging: boolean) => {
    const diffuse = !disableDiffuse && !dragging && !expanded && !isHidden;
    let outline = 'none';
    if (isCut) {
      outline = `4px dashed ${Styles.Colors.COPY_PASTE_PURPLE_LIGHT}`;
    } else if (isPasted) {
      outline = `4px solid ${Styles.Colors.COPY_PASTE_PURPLE_LIGHT}`;
    }
    return (
      <Accordion
        ref={container}
        TransitionProps={{
          unmountOnExit: [Draggable.BLOCK, Draggable.SECTION].includes(type),
          mountOnEnter: [Draggable.BLOCK, Draggable.SECTION].includes(type),
          timeout: 0,
        }}
        sx={{
          width: '100%',
          my: 0,
          backgroundColor: bgColor,
          color: textColor,
          border: sectionController?.isNestedSection ? blockBorder : undefined,
          outline,
          outlineOffset: '-4px',
          borderRadius: sectionController?.isNestedSection
            ? Styles.Dimensions.NESTED_SECTION_BORDER_RADIUS
            : Styles.Dimensions.SECTION_BORDER_RADIUS,
          '&::before, &::after': {
            display: 'none',
          },
        }}
        expanded={!dragging && expanded}
        elevation={elevation}
        disableGutters
        onChange={() => sectionController?.toggleExpand(sectionId)}>
        <AccordionSummary
          sx={{
            flexDirection: 'row-reverse',
            maxHeight: draggable ? theme.spacing(8) : theme.spacing(6),
            pr: 2,
            opacity: isHidden ? 0.4 : 1,
            '& .header-actions': {
              opacity: diffuse ? 0.4 : 1,
              transition: 'opacity 300ms ease',
            },
            '&:hover, &:focus-within': {
              '& .header-actions': {
                opacity: 1,
              },
            },
            '& .MuiAccordionSummary-content': {
              width: '100%',
            },
          }}
          id={`section-${sectionId}-header`}
          aria-controls={`section-${sectionId}-content`}
          aria-labelledby={`section-${sectionId}-label`}
          expandIcon={
            <MaterialSymbol name='chevron_right' sx={expanded ? { transform: 'rotate(270deg)' } : undefined} />
          }>
          <Container spaceBetween fullWidth>
            <Container
              left
              fullWidth
              sx={{
                overflow: 'hidden',
              }}>
              <Typography
                id={`section-${sectionId}-label`}
                sx={{ pl: 1, flexShrink: 0 }}
                variant='button'
                fontWeight='600'
                noWrap>
                {title} {childrenCount ? `(${childrenCount})` : ''}
              </Typography>
              {subTitle && (
                <Typography
                  sx={{
                    pl: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                    flexShrink: 1,
                    minWidth: 0,
                    maxWidth: 250,
                  }}
                  color='textSecondary'
                  variant='caption'>
                  {subTitle}
                </Typography>
              )}
            </Container>
            <Container right onClick={(e) => e.stopPropagation()} className='header-actions'>
              {headerActions}
              {draggable && <DragAndDropIcon ref={dragHandleRef} isDragging={dragging} fullHeight />}
            </Container>
          </Container>
        </AccordionSummary>
        <AccordionDetails sx={{ width: '100%' }}>{children}</AccordionDetails>
      </Accordion>
    );
  };

  return (
    <DragAndDropReorderingItemContainer
      useEventManager={useEventManager}
      type={type}
      index={index ?? -1}
      value={value}
      onReorder={handleMove}
      onAdd={onAdd}
      onRemove={onRemove}
      gap={0}
      sx={{
        my: sectionController?.isNestedSection ? 0.5 : 0,
        borderBottom: sectionController?.isNestedSection ? 'none' : `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
        '&:first-of-type': { mt: 0 },
        '&:last-of-type': { mb: 0 },
        transition: 'margin 200ms ease',
      }}
      fullWidth
      renderPlaceholder={renderPlaceholder}
      renderDropIndicator={renderDropIndicator}>
      {renderSection}
    </DragAndDropReorderingItemContainer>
  );
}
