import React, { FC, useEffect, useState } from 'react';
import { PageVersion } from '@/declarations/models/PageVersion';
import { Box, List, ListItemButton, Menu, MenuItem, Typography } from '@mui/material';
import { Api } from '@/services/Api';
import { useSearchParams } from 'react-router-dom';
import { formatAPITimestamp } from '@/utils/dates';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { useEditorModel } from '../lib/components/EditorDataProvider';
import { Page } from '../../declarations/models/Page';
import EditorHeader, { EditorHeaderProps } from '../lib/components/EditorHeader/EditorHeader';
import { Status } from '../../declarations/models/Status';
import { usePageEditorContext } from './PageEditorContext';

const VersionBox: FC<{ pageStatus: Status; version?: PageVersion }> = ({ pageStatus, version }) => {
  const { t: tCommon } = useTranslation('common');
  const iconIndicator = () => {
    if (pageStatus === Status.PUBLISHED && version?.status === Status.PUBLISHED) {
      return <PageStatusCircle status={Status.PUBLISHED} />;
    }
    if (pageStatus === Status.ARCHIVED && version?.status === Status.PUBLISHED) {
      return <PageStatusCircle status={Status.ARCHIVED} />;
    }
    if (pageStatus === Status.DRAFT && version?.status === Status.PUBLISHED) {
      return <PageStatusCircle status={Status.DRAFT} />;
    }
    return (
      <MaterialSymbol
        name='format_paint'
        sx={{
          fontSize: 18,
        }}
      />
    );
  };
  const labelColor = () => {
    if (!version) {
      return Styles.Colors.DARK_GREY;
    }
    if (pageStatus === Status.PUBLISHED && version.status === Status.PUBLISHED) {
      return Styles.Colors.STRONG_GREEN;
    }
    if (pageStatus === Status.ARCHIVED && version.status === Status.PUBLISHED) {
      return Styles.Colors.MEDIUM_BLUE;
    }
    if (pageStatus === Status.DRAFT && version.status === Status.PUBLISHED) {
      return Styles.Colors.DARK_GREY;
    }
    return Styles.Colors.DARK_GREY;
  };
  const label = () => {
    if (!version) {
      return tCommon('Status.no_draft');
    }
    if (pageStatus === Status.PUBLISHED && version.status === Status.PUBLISHED) {
      return tCommon('Status.published');
    }
    if (pageStatus === Status.ARCHIVED && version.status === Status.PUBLISHED) {
      return tCommon('Status.archived');
    }
    if (pageStatus === Status.DRAFT && version.status === Status.PUBLISHED) {
      return tCommon('Status.not_published');
    }
    return tCommon('Status.draft');
  };
  const lastEditedAt = () => {
    if (!version) {
      return null;
    }
    return (
      <Typography fontSize={10}>
        {tCommon('lastEdited')}: {formatAPITimestamp(version.revision_created_at)}
      </Typography>
    );
  };
  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{
        opacity: !version ? 0.7 : 1,
        px: 2,
      }}>
      <Box flexBasis='10%' display='flex' mr={2}>
        {iconIndicator()}
      </Box>
      <Box flexGrow={1} flexDirection='column'>
        <Typography fontSize={12} textTransform='uppercase' fontWeight='bold' color={labelColor()}>
          {label()}
        </Typography>
        {lastEditedAt()}
      </Box>
    </Box>
  );
};

const VersionActionMenu: FC<{
  pageStatus: Status;
  versions: PageVersion[];
  onSelected: (version: PageVersion) => void;
  selectedVersionId: number;
}> = ({ pageStatus, versions, onSelected, selectedVersionId }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (version: PageVersion, index: number) => {
    setSelectedIndex(index);
    onSelected(version);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedVersion = versions.find((v) => v.id === selectedVersionId);

  return (
    <div>
      <List component='nav' aria-label='Device settings' sx={{ bgcolor: 'background.paper', p: 0 }}>
        <ListItemButton
          id='lock-button'
          aria-haspopup='listbox'
          aria-controls='lock-menu'
          aria-label='when device is locked'
          aria-expanded={open ? 'true' : undefined}
          sx={{
            borderRadius: 1,
            border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            p: 0,
          }}
          onClick={handleClickListItem}>
          {selectedVersion ? <VersionBox pageStatus={pageStatus} version={selectedVersion} /> : <span />}
          <MaterialSymbol
            name='arrow_drop_down'
            sx={{
              color: Styles.Colors.MEDIUM_DARK_GREY,
              borderLeft: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
            }}
          />
        </ListItemButton>
      </List>
      <Menu
        id='lock-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}>
        {versions.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(option, index)}>
            <VersionBox pageStatus={pageStatus} version={option} />
          </MenuItem>
        ))}
        {versions.length === 1 && (
          <MenuItem disabled>
            <VersionBox pageStatus={pageStatus} version={undefined} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export const PageEditorHeader: FC<
  Required<Pick<EditorHeaderProps, 'menuOpen' | 'onToggleMenuOpen' | 'onTogglePageSettingsOpen'>>
> = ({ menuOpen, onToggleMenuOpen, onTogglePageSettingsOpen }) => {
  const page = useEditorModel<Page>();
  const { t: tCommon } = useTranslation('common');
  // const breadcrumbs = page?.breadcrumbs || [];
  const [pageVersions, setPageVersions] = useState<PageVersion[]>([]);
  useEffect(() => {
    Api.getPageVersions(page.id || 0)
      .fetchDirect(null)
      .then((res) => {
        if (res) {
          setPageVersions(res.versions);
        }
      });
  }, [page.id, page.updated_at]);

  const { setPages, selectionState } = usePageEditorContext();
  const parentPages = page.id ? selectionState?.getParents(page.id) : undefined;
  const parentPage = parentPages && parentPages.length ? parentPages[parentPages.length - 1] : undefined;

  const [, setParams] = useSearchParams();

  const currentPageBreadcrumb = {
    title: page.title,
    id: page.id ?? 0,
    path: page.path ?? null,
    parent_id: page.parent_id ?? null,
  };

  return (
    <EditorHeader
      breadcrumbs={(page.breadcrumbs || []).concat(currentPageBreadcrumb)}
      status={page.status || Status.DRAFT}
      updatedAt={page?.updated_at || null}
      publishedAt={page?.published_at || null}
      menuOpen={menuOpen}
      onToggleMenuOpen={onToggleMenuOpen}
      onTogglePageSettingsOpen={onTogglePageSettingsOpen}
      setPages={setPages}
      showMenuButton
      parentPage={parentPage}
      pageVersions={pageVersions}
      versionDropdown={
        <VersionActionMenu
          pageStatus={page.status}
          versions={pageVersions}
          onSelected={(v) => {
            if (v.id !== page?.active_version_id) {
              setParams({ versionId: v.id!.toString() });
            }
          }}
          selectedVersionId={page?.active_version_id || 0}
        />
      }
    />
  );
};

export default PageEditorHeader;
