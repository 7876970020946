import React, { FC, useCallback, useState } from 'react';
import { Breadcrumbs, Button, Chip, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { EventData, Page, TimePeriod } from '../../../declarations/models/Page';
import PageTreeActionMenu from './PageTreeActionMenu';
import { formatAPITimestamp } from '../../../utils/dates';
import Container from '../../../components/Container';
import PageTreeCheckbox from './PageTreeCheckbox';
import PageTreeLabel from './PageTreeLabel';
import { useContentView } from '../ContentViewContext';
import { Status } from '../../../declarations/models/Status';
import Styles from '../../../assets/js/Styles';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import Image from '../../../components/Image';

export interface PageTreeContentProps {
  page: Page;
  diffuse?: boolean;
  displayBreadcrumbs?: boolean;
  hideBorder?: boolean;
}

function formatDatePeriod(tp: TimePeriod) {
  let result = formatAPITimestamp(tp.start_date, 'date');
  if (tp.end_date) {
    result += ` - ${formatAPITimestamp(tp.end_date, 'date')}`;
  }
  if (tp.start_time) {
    result += ` ${formatAPITimestamp(tp.start_time, 'time')}`;
  }
  if (tp.end_time) {
    result += ` - ${formatAPITimestamp(tp.end_time, 'time')}`;
  }
  return result;
}

const getDateText = (eventData: EventData) => {
  const datesLen = eventData.time_periods?.length ?? 0;

  if (!eventData.time_periods || datesLen === 0) {
    return '';
  }

  if (datesLen === 1) {
    return formatDatePeriod(eventData.time_periods[0]);
  }

  return `${formatDatePeriod(eventData.time_periods[0])} (+${datesLen - 1})`;
};

export const PageTreeContent: FC<PageTreeContentProps> = ({
  page,
  diffuse,
  displayBreadcrumbs = false,
  hideBorder = false,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComp } = useTranslation('components');
  const navigate = useNavigate();
  const {
    selectionMode,
    openCreatePageModal,
    selectionState,
    movePagesMode,
    setMovePagesMode,
    setSelectionMode,
    setPages,
    hasDraft,
    filterValues,
  } = useContentView();
  const { getParents } = selectionState;
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;
  const breadcrumbs = getParents(page.id || 0)?.map((parent) => parent.title);
  const selectedPageIDs = selectionState.selectedItems?.map((p) => p.id || 0);
  const isEvent = page.main_category === 'event';
  const imageUrl = page.image_src;
  const isFrontpage = page.id === state.selectedSiteDefaultPageId;

  const [focused, setFocused] = useState<boolean>(false);

  const handleMovePages = useCallback(async (): Promise<Array<Page> | void> => {
    const result = await Api.bulkEditPages(selectedSiteId, {
      item_ids: selectedPageIDs,
      parent_id: page.id,
      item_type: 'page',
    }).fetchDirect(null);
    if (!result) {
      enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
    } else {
      enqueueSnackbar(tCommon('saved'), { variant: 'success' });
      return result.changed;
    }
  }, [enqueueSnackbar, page.id, selectedPageIDs, selectedSiteId, tCommon]);

  const updatePageTree = (savedPages: Array<Page>) => {
    setPages((prev: Array<Page>) => {
      const filteredPages = prev.filter((prevPage) => !savedPages.some((savedPage) => savedPage.id === prevPage.id));
      return [...filteredPages, ...savedPages];
    });
  };

  return (
    <Container
      onMouseOver={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
      sx={(theme) => ({
        py: 1,
        opacity: diffuse ? 0.5 : 1,
        borderBottom: hideBorder || displayBreadcrumbs || isEvent ? undefined : `1px solid ${theme.palette.divider}`,
        height: '60px',
      })}
      spaceBetween
      fullWidth>
      <Container left fullWidth gap={0}>
        {!isFrontpage && selectionMode && <PageTreeCheckbox pageId={page.id || 0} />}
        <Container left column pl={isEvent ? 2 : 0} gap={0}>
          <PageTreeLabel page={page} />
          {displayBreadcrumbs && (
            <Breadcrumbs
              separator={<MaterialSymbol name='chevron_right' />}
              sx={{
                '& .MuiBreadcrumbs-separator': {
                  mx: 0,
                },
              }}>
              {breadcrumbs?.map((breadcrumb) => (
                <Typography key={breadcrumb} variant='subtitle2' fontSize='small' color='text.secondary' noWrap>
                  {breadcrumb}
                </Typography>
              ))}
              <Typography variant='body2' color='text.primary' fontSize='small' noWrap>
                {page.title}
              </Typography>
            </Breadcrumbs>
          )}
          {isEvent && (
            <Container>
              <CustomIcon name='calendar_event' />
              <Typography variant='subtitle2' color='text.secondary' noWrap>
                {!page.event_data?.time_periods?.length
                  ? tComp('PageTreeContent.missingDate')
                  : getDateText(page.event_data)}
              </Typography>
              {page.event_data?.use_range &&
                page.event_data.week_days &&
                page.event_data.week_days.map((d) => (
                  <Chip key={d} size='small' label={tCommon(`WeekDay.${d}`)} sx={{ height: '20px' }} />
                ))}
            </Container>
          )}
        </Container>
        <Container ml={5} right>
          {!isEvent && page.main_category && (
            <Chip
              variant='outlined'
              color='secondary'
              size='small'
              label={tCommon(`MainCategory.${page.main_category}`)}
            />
          )}
          {page.sub_categories?.slice(0, 1).map((c) => (
            <Chip key={c} variant='outlined' color='secondary' size='small' label={tCommon(`SubCategory.${c}`)} />
          ))}

          {page.sub_categories && page.sub_categories?.length > 1 ? (
            <Chip
              variant='outlined'
              color='secondary'
              size='small'
              label={`+ ${(page.sub_categories?.length || 1) - 1}`}
            />
          ) : null}
          {page.location && <Chip variant='filled' color='secondary' size='small' label={page.location.title} />}
        </Container>
      </Container>
      {focused && (
        <Container right mr={6} gap={2} fullWidth>
          {page.id && movePagesMode && !selectedPageIDs?.includes(page.id) && (
            <Button
              disabled={selectionState.noneSelected}
              variant='outlined'
              sx={{
                boxShadow: undefined,
                px: 3,
                whiteSpace: 'nowrap',
                backgroundColor: Styles.Colors.LIGHT_GREY,
                border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                minWidth: 'fit-content',
              }}
              onClick={() =>
                handleMovePages().then((savedPages) => {
                  if (savedPages) {
                    selectionState.deselectAll();
                    setMovePagesMode(false);
                    setSelectionMode(false);
                    updatePageTree(savedPages);
                  }
                })
              }>
              {tComp('PageTreeContent.movePagesHereButtonLabel')}
            </Button>
          )}
          {!movePagesMode && (
            <>
              <Button
                variant='contained'
                sx={{
                  boxShadow: undefined,
                  px: 3,
                  whiteSpace: 'nowrap',
                  border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
                  minWidth: 'fit-content',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/editor/${page.id}`);
                }}
                startIcon={<MaterialSymbol name='edit' fill />}>
                {tComp(`PageTreeContent.edit${isEvent ? 'Event' : 'Page'}ButtonLabel`)}
              </Button>
              {!isEvent && !filterValues.lastEditedPagesView && (
                <Button
                  variant='contained'
                  color='success'
                  sx={{
                    px: 3,
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content',
                  }}
                  onClick={() => openCreatePageModal(page)}
                  startIcon={<MaterialSymbol name='add' />}>
                  {tComp('PageTreeContent.addSubPageButtonLabel')}
                </Button>
              )}
            </>
          )}
        </Container>
      )}
      <Container right>
        <Container column left sx={{ minWidth: '220px' }} gap={0}>
          <Container>
            <PageStatusCircle status={page.status} />
            <Typography variant='caption' color={page.status === Status.PUBLISHED ? 'green' : 'textSecondary'}>
              {page.status === Status.PUBLISHED && tCommon('Status.published')}
              {page.status === Status.DRAFT && tCommon('Status.not_published')}
              {page.status === Status.ARCHIVED && tCommon('Status.archived')}
            </Typography>
            {hasDraft(page.id!) && (
              <Tooltip title={tComp('PageTreeContent.HasDraftVersion')}>
                <Typography variant='caption' sx={{ display: 'flex', alignItems: 'center' }}>
                  [ <MaterialSymbol name='format_paint' sx={{ mx: 0, fontSize: 'small' }} /> ]
                </Typography>
              </Tooltip>
            )}
          </Container>
          <Container>
            <Typography variant='caption' color='textSecondary' noWrap>
              {tCommon('lastEdited')}: {formatAPITimestamp(page.updated_at || '', 'date') || '-'}
            </Typography>
          </Container>
        </Container>
        <Container>
          <Image
            sx={{
              overflow: 'hidden',
              objectFit: 'cover',
              height: '44px',
              flex: 1,
              width: '44px',
              borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
              border: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
              mr: 2,
              backgroundColor: Styles.Colors.LIGHT_GREY,
            }}
            src={`${imageUrl}?dimension=250x250${page.image?.mimetype?.includes('png') ? '&mediaType=image/png' : ''}`}
          />
        </Container>
        {!movePagesMode && (
          <PageTreeActionMenu
            page={page}
            onClose={() => {
              setFocused(false);
            }}
          />
        )}
      </Container>
    </Container>
  );
};

export default PageTreeContent;
