import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { Icon } from '@mui/material';
import Styles from '@/assets/js/Styles';
import DraggableCollapseContainer, {
  DraggableCollapseContainerProps,
} from '../../../../components/DraggableCollapseContainer';
import { Draggable } from '../../../../declarations/Draggable';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { deepCopy } from '../../../../utils/object';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { AttachmentsBlock } from '../../../../declarations/models/blocks/AttachmentsBlock';
import LinkListItemHeader from '../../../../components/Links/LinkListItemHeader';
import AttachmentsBlockItemContent from './AttachmentsBlockItemContent';
import { BlockItemPathPrefix } from '../../../../declarations/models/BlockItemPathPrefix';
import AttachmentsBlockActions from './AttachmentsBlockActions';

export const AttachmentsBlockItem = <T extends AttachmentsBlock['items'][0]>({
  index,
  onReorder,
  onRemove,
  onAdd,
}: Pick<DraggableCollapseContainerProps<T>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'>) => {
  const { t } = useTranslation('common');
  const { blockPath } = useCurrentBlock();
  const itemPathPrefix: BlockItemPathPrefix = `${blockPath}.items.${index}`;

  const {
    field: { value },
  } = useController<BlockSpecificPage<AttachmentsBlock>, typeof itemPathPrefix>({
    name: itemPathPrefix,
  });
  const item: T = value as T;

  return (
    <DraggableCollapseContainer<T>
      unmountOnExit
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      type={Draggable.ATTACHMENTS_BLOCK_ITEM}
      value={item}
      color={Styles.Colors.LIGHT_GREY}
      secondaryAction={
        <AttachmentsBlockActions
          onDelete={() => onRemove?.(index)}
          onDuplicate={() => onAdd?.(deepCopy(item), index + 1)}
        />
      }
      headerContent={
        // eslint-disable-next-line react/no-unstable-nested-components
        () => (
          <LinkListItemHeader
            item={value}
            icon={
              <Icon
                sx={{
                  mx: 1,
                }}>
                {item.type === 'image' ? <MaterialSymbol name='image' /> : <MaterialSymbol name='picture_as_pdf' />}
              </Icon>
            }
          />
        )
      }>
      <AttachmentsBlockItemContent item={item} pathPrefix={itemPathPrefix} />
    </DraggableCollapseContainer>
  );
};

export default AttachmentsBlockItem;
