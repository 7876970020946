import React, { FC, useEffect, useRef, useState } from 'react';
import { BlockPath, useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { useTranslation } from 'react-i18next';
import Container from '@/components/Container';
import { ProgramBlockModel } from '@/declarations/models/blocks/ProgramBlock';
import ProgramTimeItem from '@/editor/PageEditor/EditorBlock/ProgramBlock/ProgramTimeItem';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { Button } from '@mui/material';
import { Draggable } from '@/declarations/Draggable';

const ProgramBlock: FC = () => {
  const { blockPath } = useCurrentBlock();
  const { t } = useTranslation('components');

  const {
    fields: programTimeItems,
    append,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<ProgramBlockModel>, `${BlockPath}.items`>({
    name: `${blockPath}.items`,
  });

  const [newlyAddedItemIndex, setNewlyAddedItemIndex] = useState<number | null>(null);

  // Use a ref to track the initial append
  const hasAppendedInitialItem = useRef(false);

  // Add an empty program time item when the component mounts
  useEffect(() => {
    if (!hasAppendedInitialItem.current && programTimeItems.length === 0) {
      append({});
      hasAppendedInitialItem.current = true;
      setNewlyAddedItemIndex(0);
    }
  }, [programTimeItems.length, append]);

  const handleAddProgramTimeItem = () => {
    append({});
    setNewlyAddedItemIndex(programTimeItems.length);
  };

  return (
    <Container fullWidth column gap='3px'>
      {(programTimeItems || []).map((item, index) => {
        const uniqueKey = `${item.id || index}`;
        const initiallyOpen = index === newlyAddedItemIndex;

        return (
          <ProgramTimeItem
            key={uniqueKey}
            item={item}
            itemPathPrefix={`${blockPath}.items.${index}`}
            onReorder={move}
            onRemove={remove}
            index={index}
            type={Draggable.ITEM}
            initiallyOpen={initiallyOpen}
          />
        );
      })}
      <Container mt={1}>
        <Button variant='outlined' color='secondary' onClick={handleAddProgramTimeItem}>
          {t('ProgramBlock.AddProgramTime')}
        </Button>
      </Container>
    </Container>
  );
};

export default ProgramBlock;
