import React, { FC, useEffect, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../assets/js/Styles';
import { MenuItem as MenuItemModel } from '../declarations/models/MenuItem';
import { SubMenu } from './SubMenu';

export interface MenuItemProps {
  item: MenuItemModel & { subItems?: Array<{ path: string; label: string }> };
  menuIsOpen: boolean;
  toggleMenuOpen: () => void;
}

export const MenuItem: FC<MenuItemProps> = ({ item, menuIsOpen, toggleMenuOpen }) => {
  const { pathname } = useLocation();
  const active = pathname.includes(item.path);
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(active);

  const handleClick = () => {
    setTimeout(() => {
      setSubMenuOpen(!subMenuOpen);
    }, 0);
  };

  useEffect(() => {
    setSubMenuOpen(active);
  }, [active, menuIsOpen]);

  return (
    <Tooltip key={item.path} title={!menuIsOpen ? item.label : ''} placement='right' arrow>
      <>
        <ListItemButton
          component={Link}
          aria-current={active ? 'page' : undefined}
          to={item.path}
          onClick={(e) => {
            if (item.subItems && item.subItems.length > 0) {
              if (!menuIsOpen) {
                toggleMenuOpen();
              }
              handleClick();
              e.preventDefault();
            }
          }}
          sx={[
            {
              pl: 1.5,
              py: 1.5,
              transition: 'all 200ms ease',
              justifyContent: 'flex-start',
            },
            active &&
              ((theme) => ({
                borderLeft: `${menuIsOpen ? '6px' : '3px'} solid ${theme.palette.secondary.main}`,
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
              })),
          ]}
          {...(!menuIsOpen && { title: item.label })}>
          {item.icon !== null && (
            <ListItemIcon sx={(theme) => ({ minWidth: 'unset', color: theme.palette.text.primary })}>
              {item.icon || <MaterialSymbol name='circle' sx={{ fontSize: '12px' }} />}
            </ListItemIcon>
          )}
          <ListItemText
            sx={(theme) => ({
              m: 0,
              pl: 2,
              width: menuIsOpen ? '100%' : 0,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration[menuIsOpen ? 'enteringScreen' : 'leavingScreen'],
              }),
            })}>
            {item.label}
          </ListItemText>
          {item.subItems && item.subItems.length > 0 && (
            <>{subMenuOpen ? <MaterialSymbol name='expand_less' /> : <MaterialSymbol name='expand_more' />}</>
          )}
        </ListItemButton>
        {item.subItems && item.subItems.length > 0 && (
          <SubMenu open={subMenuOpen && menuIsOpen} menuItems={item.subItems} toggleMenuOpen={toggleMenuOpen} />
        )}
      </>
    </Tooltip>
  );
};

export default MenuItem;
