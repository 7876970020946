import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, IconButton, Link, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from './Container';

export const CategoriesInfo: FC = () => {
  const { t } = useTranslation('components');
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  return (
    <Alert
      sx={{ width: '100%' }}
      severity='info'
      action={
        <IconButton onClick={() => setInfoOpen(!infoOpen)}>
          {infoOpen ? <MaterialSymbol name='expand_less' /> : <MaterialSymbol name='expand_more' />}
        </IconButton>
      }>
      <Container column top left>
        <Typography fontSize='14px'>
          {t('Settings.SiteSettings.SiteCategoriesSettings.Info.Definition')}
          {!infoOpen && ' (...)'}
        </Typography>
        {infoOpen && (
          <>
            <Typography fontStyle='italic' fontSize='14px'>
              {t('Settings.SiteSettings.SiteCategoriesSettings.Info.Clarification')}
            </Typography>
            <Link href='https://museum24.no/kategorier'>
              {t('Settings.SiteSettings.SiteCategoriesSettings.Info.ReadMoreCategories')}
            </Link>
            <Link href='https://museum24.no/arrangement'>
              {t('Settings.SiteSettings.SiteCategoriesSettings.Info.ReadMoreEvents')}
            </Link>
            <Link href='https://museum24.no/sideutlisting'>
              {t('Settings.SiteSettings.SiteCategoriesSettings.Info.ReadMorePageListing')}
            </Link>
          </>
        )}
      </Container>
    </Alert>
  );
};

export default CategoriesInfo;
