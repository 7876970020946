import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from '@/hooks/useDebounce';
import { SiteLocationField } from '@/views/SettingsView/SiteLocationsSettings/SiteLocationField';
import { Location } from '@/declarations/models/Location';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';
import DeleteButton from '@/components/DeleteButton';
import { LILLEHAMMER_COORDINATES, MapV2Marker } from '@/declarations/models/blocks/MapV2';

export interface MapMarkerFormFieldProps {
  path: string;
  defaultValue: MapV2Marker;
  onDelete?: () => void;
}

export const MapV2MarkerFormField: FC<MapMarkerFormFieldProps> = ({ path, defaultValue, onDelete }) => {
  const [valueInternal, setValueInternal] = useState<typeof defaultValue>(defaultValue);
  const { t: tComponents } = useTranslation('components');
  const { setValue } = useFormContext();
  useDebounce(300, valueInternal, () => {
    setValue(path, valueInternal);
  });

  const locationChanged = (location: Location) => {
    setValueInternal({
      ...valueInternal,
      location,
    });
  };

  const defaultCenter = {
    ...LILLEHAMMER_COORDINATES,
  };
  if (valueInternal?.location.latitude && valueInternal?.location.longitude) {
    defaultCenter.lat = parseFloat(valueInternal.location.latitude.toString());
    defaultCenter.lng = parseFloat(valueInternal.location.longitude.toString());
  }

  return (
    <Accordion
      sx={{
        width: '100%',
      }}>
      <AccordionSummary expandIcon={<MaterialSymbol name='expand_more' />}>
        {valueInternal?.location?.title || tComponents('MapV2MarkerFormField.missingTitle')}
      </AccordionSummary>
      <AccordionDetails>
        {valueInternal && (
          <SiteLocationField value={valueInternal.location} onChange={locationChanged} defaultCenter={defaultCenter}>
            {onDelete && <DeleteButton onConfirm={onDelete} />}
          </SiteLocationField>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
