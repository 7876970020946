import React, { useMemo, useState, useRef, ReactNode } from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';
import { Flatten } from '../../utils/flatten';

export interface SelectInputProps<T, U extends Flatten<PathValue<T, Path<T>>>> extends FormInputBaseProps<T> {
  isLoading?: boolean;
  options: Array<U>;
  getOptionKey: (option: U) => string;
  getOptionLabel: (option: U) => string;
  multiSelect?: boolean;
  initialMultiSelectValue?: string[];
  hideNoSelectionOption?: boolean;
  renderValue?: (v: string | string[]) => ReactNode;
  /** optional onChange. will fire in _addition_ to the default form handlers onChange. */
  onChange?: (v: unknown | unknown[]) => void;
}

/**
 *
 * @param isLoading isLoading
 * @param options options
 * @param getOptionKey getOptionKey
 * @param getOptionLabel getOptionLabel
 * @param multiSelect multiSelect
 * @param initialMultiSelectValue string[], required when multiSelect is true since multiSelects state internally is using string[] instead of the options objects.
 * @param hideNoSelectionOption hide the 'no selection' option for non-required SelectInputs. Should be used together with defaultValue.
 * @param size size of select box ('small' | 'medium')
 * @param renderValue
 * @param onChange optional onChange. will fire in _addition_ to the default form handlers onChange.
 * @param baseProps baseProps
 * @constructor
 */
export default function SelectInput<T extends FieldValues, U extends Flatten<PathValue<T, Path<T>>>>({
  isLoading,
  options,
  getOptionKey,
  getOptionLabel,
  multiSelect = false,
  initialMultiSelectValue,
  hideNoSelectionOption = false,
  size = 'small',
  renderValue,
  onChange,
  ...baseProps
}: SelectInputProps<T, U>) {
  const getOptionKeyRef = useRef(getOptionKey);
  getOptionKeyRef.current = getOptionKey;

  const { t } = useTranslation('components');

  const {
    field: { value, onChange: onChangeInternal, ref, name, onBlur },
    variant,
    color,
    fullWidth,
    label,
    required,
    disabled,
    isError,
    errorMessage,
    defaultValue,
  } = useFormInput<T>(baseProps);

  const selectValue = useMemo<string>(() => {
    return getOptionKeyRef.current(value as U);
  }, [value]);

  const [multiSelectValue, setMultiSelectValue] = useState<string[]>(
    initialMultiSelectValue || (Array.isArray(value) ? value : []),
  );
  const [open, setOpen] = useState<boolean>(false);

  const handleValueChanged = (key: string | Array<string>) => {
    if (multiSelect && key) {
      if (key.includes('')) {
        setMultiSelectValue([]);
        onChangeInternal(defaultValue);
        setOpen(false);
        onChange?.(defaultValue);
      } else {
        const selectedOptions = options.filter((opt) => key.includes(getOptionKey(opt)));
        setMultiSelectValue(typeof key === 'string' ? key.split(',') : key);
        onChangeInternal(key ? selectedOptions : defaultValue);
        onChange?.(key ? selectedOptions : defaultValue);
      }
    } else {
      const change = key ? options.find((opt) => getOptionKey(opt) === key) || defaultValue : defaultValue;
      onChangeInternal(change);
      onChange?.(change);
    }
  };

  return (
    <FormControl
      variant={variant}
      color={color}
      fullWidth={fullWidth}
      size={size}
      disabled={isLoading || disabled}
      required={required}
      error={isError}>
      <InputLabel id='select-input-label'>{label}</InputLabel>
      <Select
        id='select-input'
        labelId='select-input-label'
        ref={ref}
        name={name}
        open={open}
        onOpen={() => setOpen(true)}
        label={label}
        onBlur={onBlur}
        onClose={() => setOpen(false)}
        renderValue={renderValue}
        value={multiSelect ? multiSelectValue : selectValue}
        multiple={multiSelect}
        onChange={(e) => handleValueChanged(e.target.value)}
        startAdornment={
          isLoading && (
            <InputAdornment position='start'>
              <CircularProgress variant='indeterminate' color='primary' size={20} />
            </InputAdornment>
          )
        }>
        {!required && !hideNoSelectionOption && <MenuItem value=''>{t('SelectInput.NoSelection')}</MenuItem>}
        {options.map((option) => (
          <MenuItem key={getOptionKey(option)} value={getOptionKey(option)}>
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
      {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}
