import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DMObjectType } from '@/declarations/models/DMObjectType';
import { DMSchoolBlockModel } from '@/declarations/models/blocks/DMBlock';
import { BlockPreviewComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { Api } from '../../../../services/Api';
import { DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import DMBlockPreviewItem from './DMBlockPreviewItem';

export const DMBlockSchoolPreview: BlockPreviewComponent = () => {
  const { t } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const { block } = useCurrentBlock();
  const currentBlock = block as DMSchoolBlockModel;

  const [dmItems, setDmItems] = useState<Array<DMMediaModel>>([]);

  useEffect(() => {
    let dmSchoolType = [DMObjectType.SCHOOL];

    if (currentBlock.includeDigitalResources && !currentBlock.includePhysicalResources) {
      dmSchoolType = [DMObjectType.SCHOOL_DIGITAL];
    }
    if (!currentBlock.includeDigitalResources && currentBlock.includePhysicalResources) {
      dmSchoolType = [DMObjectType.SCHOOL_PHYSICAL];
    }

    const ctx = Api.getDMSearchResult({
      start: 0,
      rows: currentBlock?.maxItems || 25,
      query: undefined,
      subjects: undefined,
      owners: currentBlock?.ownerCodes || 'NONE',
      licenses: undefined,
      types: dmSchoolType,
      order_by: currentBlock.sortBy || undefined,
      order: currentBlock.order || undefined,
      has_pictures: undefined,
      names: currentBlock?.schoolSubjects || undefined,
      classification: currentBlock?.schoolGrades || undefined,
    });

    ctx
      .fetchDirect(null)
      .then((res) => !!res?.items && setDmItems(res.items))
      .finally(ctx.abort);
  }, [
    currentBlock.order,
    currentBlock.ownerCodes,
    currentBlock.sortBy,
    currentBlock.schoolSubjects,
    currentBlock.schoolGrades,
    currentBlock.maxItems,
    currentBlock.includeDigitalResources,
    currentBlock.includePhysicalResources,
  ]);

  return (
    <div className='ekultur ekultur--school gridSize-auto'>
      <div className='ekultur__intro'>
        {currentBlock?.visibletitle && currentBlock?.title ? <h2>{currentBlock.title}</h2> : null}
        {currentBlock?.body && <p>{currentBlock?.body}</p>}
        {currentBlock?.enableFilters && (
          <div className='user-filter'>
            <div className='preview__blockmessage'>
              <strong>{tComponents('DMBlock.PreviewFilterInfo')}</strong>
            </div>
          </div>
        )}
      </div>
      <div className='ekultur__grid'>
        {dmItems?.map((item) => (
          <DMBlockPreviewItem key={item['artifact.uniqueId']} dmItem={item} />
        ))}
      </div>
    </div>
  );
};

export default DMBlockSchoolPreview;
