/* istanbul ignore file */
import { createTheme } from '@mui/material';
import Styles from './assets/js/Styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const DEFAULT_THEME = createTheme({
  palette: {
    primary: {
      main: Styles.Colors.THEME_PRIMARY,
    },
    secondary: {
      main: Styles.Colors.THEME_SECONDARY,
    },
    background: {
      default: Styles.Colors.THEME_BG_COLOR,
      paper: Styles.Colors.THEME_BG_COLOR,
    },
    success: {
      main: Styles.Colors.SUCCESS,
    },
    error: {
      main: Styles.Colors.ERROR,
    },
    warning: {
      main: Styles.Colors.WARNING,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
        type: 'button',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'unset',
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          backgroundColor: Styles.Colors.THEME_BG_COLOR,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          // FIXME: necessary because palette.primary.main (aka white) overrides label color when focused?!
          '&.Mui-focused': {
            color: 'inherit !important',
          },
          backgroundColor: Styles.Colors.THEME_BG_COLOR,
          left: '-5px',
          padding: '0 7px',
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: Styles.Colors.LINK_COLOR,
          ':visited': {
            color: Styles.Colors.LINK_COLOR_VISITED,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: Styles.Colors.MENU_ITEM_SELECTED_BACKGROUND_COLOR,
          },
        },
      },
    },
  },
});
