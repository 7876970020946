import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { QuoteBlock, QuoteTextSize } from '../../../declarations/models/blocks/QuoteBlock';
import { Alignment } from '../../../declarations/models/Alignment';

export const QuoteBlockPreview: BlockPreviewComponent = () => {
  const { block, blockType } = useCurrentBlock();
  const currentBlock = block as QuoteBlock;

  return (
    <div className='article__grid'>
      <figure className={`${blockType} layout--${currentBlock?.position || Alignment.AUTO}`}>
        <blockquote className={`${currentBlock?.quote_size || QuoteTextSize.NORMAL}`}>
          <p>{currentBlock?.quote}</p>
        </blockquote>
        {currentBlock?.byline && <figcaption>{currentBlock?.byline}</figcaption>}
      </figure>
    </div>
  );
};

export default QuoteBlockPreview;
