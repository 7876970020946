import React, { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../components/Container';
import { Employee } from '../../../declarations/models/Employee';
import { M24MediaModel } from '../../../declarations/models/M24MediaModel';
import { Coordinate } from '../../../declarations/models/Coordinate';
import ImageWithFocusPointSelector from '../../../components/ImageWithFocusPointSelector';
import { inputGroupStyling } from '../../MediaEditor/MediaEditorFields/EditMediaTextFields';
import Styles from '../../../assets/js/Styles';
import M24MediaFinder from '../../../components/Finder/M24MediaFinder/M24MediaFinder';
import { MediaResourceType } from '../../../declarations/models/MediaResourceType';

export interface EditEmployeeTextFieldsProps {
  image?: M24MediaModel;
  employee?: Partial<Employee>;
  setImageId: (id?: number | null) => void;
  setFocusPoint: (point: Coordinate) => void;
}

export const EditEmployeeImageField: FC<EditEmployeeTextFieldsProps> = ({
  image,
  employee,
  setImageId,
  setFocusPoint,
}) => {
  const { t } = useTranslation('components');
  const [finderOpen, setFinderOpen] = useState<boolean>(false);

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{t('EmployeeEditor.ImageFieldTitle')}</Typography>
      <Container fullWidth left gap={2}>
        <Container fullHeight fullWidth left sx={{ maxHeight: '300px' }}>
          {image ? (
            <ImageWithFocusPointSelector
              src={image?.url}
              mimetype={image?.mimetype}
              x={employee?.image_focus_point?.x}
              y={employee?.image_focus_point?.y}
              maxWidth='450px'
              onFocusPointChanged={setFocusPoint}
            />
          ) : (
            <MaterialSymbol
              name='person'
              fill
              sx={{
                fontSize: '250px',
                padding: '0 20px',
                color: 'rgba(0, 0, 0, 0.54)',
                backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
              }}
            />
          )}
        </Container>
        <Container fullHeight fullWidth right spaceBetween column gap={2}>
          <Container right sx={{ flexFlow: 'row wrap-reverse !important' }}>
            {image && (
              <Button
                color='error'
                variant='contained'
                startIcon={<MaterialSymbol name='cancel' />}
                onClick={() => setImageId(null)}
                sx={{ height: '40px', borderRadius: Styles.Dimensions.RADIUS_ROUND_XL }}>
                {t('EmployeeEditor.RemoveImage')}
              </Button>
            )}
            <Button
              color='success'
              variant='contained'
              startIcon={<MaterialSymbol name='add_circle' />}
              onClick={() => setFinderOpen(true)}
              sx={{ height: '40px', borderRadius: Styles.Dimensions.RADIUS_ROUND_XL }}>
              {t(image ? 'EmployeeEditor.SwapImage' : 'EmployeeEditor.FindImage')}
            </Button>
          </Container>
          {image?.filename && (
            <Typography textAlign='right' variant='caption'>
              {image.filename}
            </Typography>
          )}
        </Container>
      </Container>
      <M24MediaFinder
        open={finderOpen}
        onClose={() => setFinderOpen(false)}
        onSelectionConfirmed={([img]) => img && setImageId(Number(img.id))}
        multiSelect={false}
        selectableResourceTypes={[MediaResourceType.IMAGE]}
      />
    </Container>
  );
};

export default EditEmployeeImageField;
