import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';

export interface LanguageFooterSocialSettingsProps {
  blockPath: BlockPath;
}

export const LanguageFooterSocialSettings: FC<LanguageFooterSocialSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');

  const renderSocialTextInputs = (socialMediaName: string) => (
    <Container fullWidth>
      <TextInput
        path={`${blockPath}.${socialMediaName}`}
        label={t(`Settings.LanguageSettings.LanguageFooterSettings.social.${socialMediaName}`)}
        defaultValue=''
      />
      <TextInput
        path={`${blockPath}.${socialMediaName}Label`}
        label={t('Settings.LanguageSettings.LanguageFooterSettings.social.textFieldLabel')}
        placeholder={t('Settings.LanguageSettings.LanguageFooterSettings.social.textFieldPlaceholder')}
        defaultValue=''
      />
    </Container>
  );

  return (
    <Container fullWidth column top left gap={2}>
      {renderSocialTextInputs('facebook')}
      {renderSocialTextInputs('twitter')}
      {renderSocialTextInputs('instagram')}
      {renderSocialTextInputs('youtube')}
      {renderSocialTextInputs('vimeo')}
      {renderSocialTextInputs('flickr')}
      {renderSocialTextInputs('pinterest')}
      {renderSocialTextInputs('linkedin')}
      {renderSocialTextInputs('tripadvisor')}
    </Container>
  );
};

export default LanguageFooterSocialSettings;
