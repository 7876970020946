import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import GradeSelect from './GradeSelect';
import SubjectSelect from './SubjectSelect';
import MuseumSelect from './MuseumSelect';

interface DMSchoolSelectsProps {
  tComp: (key: string) => string;
  allDimuOwners: any[];
  selectedOwners: any[];
  allDMSchoolGrades: any[];
  selectedDMSchoolGrades: any[];
  allDMSchoolSubjects: any[];
  selectedDMSchoolSubjects: any[];
  handleOwnerCodesChanged: (owners: any[]) => void;
  handleDMSchoolGradesChanged: (grades: any[]) => void;
  handleDMSchoolSubjectsChanged: (subjects: any[]) => void;
  getOptionLabel: (option: any) => string;
  autocompleteItemStyle: (selected: boolean) => any;
}

const DMSchoolGradeSubjectSelects: FC<DMSchoolSelectsProps> = ({
  tComp,
  allDimuOwners,
  selectedOwners,
  allDMSchoolGrades,
  selectedDMSchoolGrades,
  allDMSchoolSubjects,
  selectedDMSchoolSubjects,
  handleOwnerCodesChanged,
  handleDMSchoolGradesChanged,
  handleDMSchoolSubjectsChanged,
  getOptionLabel,
  autocompleteItemStyle,
}) => {
  const defaultAutocompleteItemStyle = (selected: boolean) => ({
    backgroundColor: selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, alignItems: 'flex-start', width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
        <MuseumSelect
          tComp={tComp}
          allDimuOwners={allDimuOwners}
          selectedOwners={selectedOwners}
          handleOwnerCodesChanged={handleOwnerCodesChanged}
          getOptionLabel={getOptionLabel}
          autocompleteItemStyle={autocompleteItemStyle || defaultAutocompleteItemStyle}
        />
        <Typography variant='caption'>{tComp('DMBlock.OwnerCodeSelectOne')}</Typography>
      </Box>

      <GradeSelect
        tComp={tComp}
        allDMSchoolGrades={allDMSchoolGrades}
        selectedDMSchoolGrades={selectedDMSchoolGrades}
        handleDMSchoolGradesChanged={handleDMSchoolGradesChanged}
        autocompleteItemStyle={autocompleteItemStyle || defaultAutocompleteItemStyle}
      />
      <SubjectSelect
        tComp={tComp}
        allDMSchoolSubjects={allDMSchoolSubjects}
        selectedDMSchoolSubjects={selectedDMSchoolSubjects}
        handleDMSchoolSubjectsChanged={handleDMSchoolSubjectsChanged}
        autocompleteItemStyle={autocompleteItemStyle || defaultAutocompleteItemStyle}
      />
    </Box>
  );
};

export default DMSchoolGradeSubjectSelects;
