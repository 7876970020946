import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { RequestContext } from '../utils/ApiRequest/RequestContext';

type HookApi<T> = [T, boolean, () => void, Dispatch<SetStateAction<T>>];

/**
 * A utility hook for getting data from the API.
 * Provide a function to get the desired RequestContext.
 *
 * @example
 * const [pages, isLoadingPages, reloadPages, setPages] = useApi(() => Api.getAllPages(), []);
 *
 * @param requestFactory
 * @param defaultValue
 */
export function useApi<T, R = T, D = undefined>(
  requestFactory: () => RequestContext<T, R>,
  defaultValue?: D,
): HookApi<R | D> {
  const defaultValueRef = useRef<D>(defaultValue as D);
  defaultValueRef.current = defaultValue as D;
  const rebuildRequestContext = useRef<() => void>();
  const [requestContext, setRequestContext] = useState<RequestContext<T, R> | null>(null);
  const [data, setData] = useState<R | D>(defaultValue as D);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  rebuildRequestContext.current = () => {
    setRequestContext(requestFactory());
  };

  const refresh = useCallback(() => {
    rebuildRequestContext.current?.();
  }, []);

  useEffect(() => {
    // Initial load
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (!requestContext) {
      return;
    }
    setIsLoading(true);
    requestContext
      .fetchDirect(defaultValueRef.current)
      .then(setData)
      .finally(() => setIsLoading(false));
    return () => {
      requestContext.abort();
    };
  }, [requestContext]);

  return [data, isLoading, refresh, setData];
}
