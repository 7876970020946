import React, { FC } from 'react';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import { Alignment, TitleAlignment } from '@/declarations/models/Alignment';
import ActionMenuButton from '@/components/ActionMenuButton';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';

const TitleAlignmentSelector: FC<{ blockPath: BlockPath }> = ({ blockPath }) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');

  const {
    field: { value, onChange },
  } = useController<
    BlockSpecificPage<
      BaseBlock & {
        textAlign?: TitleAlignment;
      }
    >,
    `${typeof blockPath}.textAlign`
  >({
    name: `${blockPath}.textAlign`,
  });

  const options: Array<TitleAlignment> = [Alignment.AUTO, Alignment.LEFT, Alignment.CENTER, Alignment.MODULE];

  const icons: Record<TitleAlignment, React.ReactNode> = {
    [Alignment.LEFT]: <MaterialSymbol name='format_align_left' />,
    [Alignment.AUTO]: <MaterialSymbol name='format_align_left' />,
    [Alignment.CENTER]: <MaterialSymbol name='format_align_center' />,
    [Alignment.MODULE]: <CustomIcon name='width_full' />,
  };

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.CommonBlockContent.Alignment.ActionMenuLabel')}
      tooltip={tComponents('CommonBlockContent.Alignment.Tooltip')}
      tooltipPlacement='top'
      icon={value ? icons[value] : icons.left}
      width='auto'>
      {options.map((option) => (
        <MenuItem
          key={option}
          onClick={() => onChange(option)}
          selected={(!value && option === Alignment.AUTO) || value === option}
          aria-label={tComponents(`CommonBlockContent.Alignment.${option}`)}>
          <ListItemIcon>{icons[option]}</ListItemIcon>
          <ListItemText>{tComponents(`CommonBlockContent.Alignment.${option}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default TitleAlignmentSelector;
