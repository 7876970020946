import React, { FC } from 'react';
import { useWatch, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectInput from '@/components/forms/SelectInput';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import {
  Paper,
  Collapse,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import Container from '../../../../components/Container';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import {
  CardModuleBlock as CardModuleBlockModel,
  CardModuleView,
} from '../../../../declarations/models/blocks/CardModuleBlock';
import CardModuleBlockItem from './CardModuleBlockItem';
import CardModuleLinks from './CardModuleLinks';
import CardModuleBlockCardSearch from './CardModuleBlockCardSearch';
import SwitchInput from '../../../../components/forms/SwitchInput';
import TextInput from '../../../../components/forms/TextInput';
import CardModuleBlockMetadataFormatSelector from './CardModuleBlockMetadataFormatSelector';
import CardModuleBlockSizeSelector from './CardModuleBlockSizeSelector';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { Card } from '../../../../declarations/models/Card';
import { BlockType } from '../../../../declarations/models/BlockType';
import { Person } from '../../../../declarations/models/Person';
import Styles from '../../../../assets/js/Styles';

export const CardModuleBlock: FC = () => {
  const { blockPath, block } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState<boolean>(false);
  const currentBlock = block as CardModuleBlockModel;

  const {
    fields: selectedCardItems,
    append,
    insert,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<CardModuleBlockModel>, `${BlockPath}.items`, 'itemHash'>({
    name: `${blockPath}.items`,
  });

  type GroupType = false | 'alpha';
  const GROUP_BY_OPTIONS: Array<GroupType> = [false, 'alpha'];
  type SortType = false | 'title';
  const ORDER_BY_OPTIONS: Array<SortType> = [false, 'title'];

  const {
    field: { value: groupBy, onChange: onChangeGroupBy },
  } = useController<BlockSpecificPage<CardModuleBlockModel>, `${typeof blockPath}.groupBy`>({
    name: `${blockPath}.groupBy`,
  });

  const currentViewType = useWatch<BlockSpecificPage<CardModuleBlockModel>, `${BlockPath}.view`>({
    name: `${blockPath}.view`,
  });

  const cardModuleViewTypeIsOneOf = (...viewTypes: Array<CardModuleView>) => {
    return viewTypes.includes(currentViewType);
  };

  const {
    field: { value: mode, onChange: onChangeMode },
  } = useController<BlockSpecificPage<CardModuleBlockModel>, `${BlockPath}.structure__search`>({
    name: `${blockPath}.structure__search`,
    defaultValue: 'page',
  });

  const {
    field: { value: orderBy, onChange: onChangeOrderBy },
  } = useController<BlockSpecificPage<CardModuleBlockModel>, `${typeof blockPath}.orderBy`>({
    name: `${blockPath}.orderBy`,
  });

  const handleAddCard = (item: Card | Person | null) => {
    if (item) {
      append(item);
    }
  };

  const includeListSearch = useWatch<BlockSpecificPage<CardModuleBlockModel>, `${BlockPath}.includeListSearch`>({
    name: `${blockPath}.includeListSearch`,
  });

  const gridSizeOptions = ['auto', '2', '3', '4', '5', '6'];
  const mobileGridOptions = ['auto', 'grid', 'list', 'horizontal'];

  return (
    <Container fullWidth column>
      <Container fullWidth column left>
        <RadioGroup value={mode} onChange={(_, v) => onChangeMode(v)} row>
          <FormControlLabel control={<Radio value='page' />} label={tComponents('CardModuleBlock.Manual')} />
          <FormControlLabel control={<Radio value='search' />} label={tComponents('CardModuleBlock.Auto')} />
        </RadioGroup>
      </Container>

      {mode !== 'search' && (
        <Container
          sx={{ backgroundColor: Styles.Colors.BLOCK_ITEMS_BACKGROUND_COLORS }}
          p={2}
          mt={2}
          mb={2}
          gap={0}
          column
          top
          left
          fullWidth>
          <FinderButton
            type={FinderType.CARD}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<Card | Person>>) =>
                items.forEach((item) => handleAddCard(item.source)),
              selectableBlockTypes: [BlockType.CARD, BlockType.PERSON],
            }}
          />
          {selectedCardItems?.map((card, index) => {
            return (
              <CardModuleBlockItem
                key={`${card.id}-${index}`} // eslint-disable-line react/no-array-index-key
                index={index}
                onReorder={move}
                onAdd={(itemToAdd, addAtIndex) => itemToAdd && insert(addAtIndex, itemToAdd)}
                onRemove={remove}
                displayGridSelector={cardModuleViewTypeIsOneOf(
                  CardModuleView.GRID,
                  CardModuleView.GRID_NARROW,
                  CardModuleView.GRID_WIDE,
                  CardModuleView.GRID_FULL,
                  CardModuleView.HORIZONTAL,
                  CardModuleView.HORIZONTAL_WIDE,
                  CardModuleView.HORIZONTAL_FULL,
                )}
              />
            );
          })}
        </Container>
      )}

      {mode === 'search' && currentBlock?.entity_subtype === 'article__person' && (
        <Container
          fullWidth
          p={2}
          mt={2}
          left
          sx={{
            backgroundColor: Styles.Colors.LIGHT_BLUE,
            borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
            border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
            fontStyle: 'italic',
          }}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: tComponents('Migration.Persons.CardBlock') }} />
        </Container>
      )}

      {mode === 'search' && <CardModuleBlockCardSearch />}

      <Container fullWidth left gap={2} mt={2} mb={2}>
        {currentBlock.view?.includes('grid') && (
          <SelectInput
            hideNoSelectionOption
            defaultValue='auto'
            options={gridSizeOptions}
            getOptionKey={(gridSize) => `size-${gridSize}`}
            getOptionLabel={(gridSize) => `${gridSize}`}
            path={`${blockPath}.gridSize`}
            label={tComponents('CardModuleBlock.GridSizeLabel')}
          />
        )}
        {currentBlock.view?.includes('grid') && (
          <SelectInput
            hideNoSelectionOption
            defaultValue='auto'
            options={mobileGridOptions}
            getOptionKey={(o) => `mobileGrid-${o}`}
            getOptionLabel={(o) => tComponents(`CardModuleViewTypeSelector.CardModuleViewTypeVariant.${o}`)}
            path={`${blockPath}.gridMobileBehaviour`}
            label={tComponents('CardModuleBlock.GridMobileBehaviourLabel')}
          />
        )}
      </Container>

      <Container left column fullWidth>
        <Button
          variant='text'
          startIcon={
            <MaterialSymbol
              name='chevron_right'
              sx={{
                transform: advancedSettingsOpen ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.1s ease-in-out',
              }}
            />
          }
          onClick={() => setAdvancedSettingsOpen((o) => !o)}>
          <Typography
            sx={{
              typography: 'subtitle1',
              fontWeight: 'bold',
              '&, &:hover': {
                textDecoration: 'underline',
              },
            }}>
            {`${advancedSettingsOpen ? `${tCommon('close')} : ` : ''} ${tComponents(
              'LinkListBlock.AdvancedSettings.Title',
            )}`}
          </Typography>
        </Button>
        <Collapse in={advancedSettingsOpen} sx={{ width: '100%' }}>
          <Paper>
            <Container
              sx={{ backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR }}
              p={2}
              mt={2}
              mb={4}
              gap={2}
              column
              top
              left
              fullWidth>
              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <FormControl fullWidth>
                  <FormLabel color='secondary'>{tComponents('CardModuleBlockCardSearch.OrderBy')}</FormLabel>
                  <RadioGroup
                    value={orderBy === 'title' ? orderBy || false : false}
                    onClick={(e) => e.stopPropagation()}
                    row
                    onChange={(event, value) => {
                      onChangeOrderBy(value === 'false' ? false : value);
                    }}>
                    {ORDER_BY_OPTIONS.map((orderByOption) => (
                      <FormControlLabel
                        key={orderByOption?.toString()}
                        control={<Radio size='small' />}
                        label={tComponents(`CardModuleBlockCardSearch.OrderBySelector.${orderByOption}`)}
                        value={orderByOption}
                      />
                    ))}
                  </RadioGroup>

                  <FormLabel sx={{ mt: 2 }} color='secondary'>
                    {tComponents('CardModuleBlock.GroupByLabel')}
                  </FormLabel>
                  <RadioGroup
                    sx={{ marginBottom: '10px' }}
                    value={groupBy || false}
                    onClick={(e) => e.stopPropagation()}
                    row
                    onChange={(_event, value) => {
                      onChangeGroupBy(value === 'false' ? false : value);
                    }}>
                    {GROUP_BY_OPTIONS.map((groupByOption) => (
                      <FormControlLabel
                        key={groupByOption?.toString()}
                        control={<Radio size='small' />}
                        label={tComponents(`LinkListBlock.AdvancedSettings.GroupBy.${groupByOption}`)}
                        value={groupByOption}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <SwitchInput
                  path={`${blockPath}.reverseOrder`}
                  label={tComponents('LinkListBlock.AdvancedSettings.reverseOrder')}
                />
              </div>

              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <FormLabel color='secondary'>{tComponents('CardModuleBlockCardSearch.Metadata')}</FormLabel>
                <CardModuleBlockMetadataFormatSelector />
              </div>

              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <CardModuleBlockSizeSelector />
              </div>

              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <SwitchInput<BlockSpecificPage<CardModuleBlockModel>>
                  path={`${blockPath}.includeListSearch`}
                  label={tComponents(`CardModuleBlock.includeListSearch`)}
                />
                <SwitchInput<BlockSpecificPage<CardModuleBlockModel>>
                  path={`${blockPath}.includeGlobalListSearch`}
                  label={tComponents(`CardModuleBlock.includeGlobalListSearch`)}
                  disabled={!includeListSearch}
                />
                <TextInput
                  path={`${blockPath}.listSearchText`}
                  label={tComponents(`CardModuleBlock.listSearchText`)}
                  disabled={!includeListSearch}
                  defaultValue=''
                />
              </div>
              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <FormLabel color='secondary'>{tComponents('CardModuleBlockCardSearch.FooterLink')}</FormLabel>
                <CardModuleLinks />
              </div>

              <div style={{ border: '1px solid #ccc', width: '100%', padding: '10px' }}>
                <TextInput path={`${blockPath}.cssClass`} label='CSS klasse' />
              </div>
            </Container>
          </Paper>
        </Collapse>
      </Container>
    </Container>
  );
};
