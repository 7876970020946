import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SettingsInputContainer from '../../../components/SettingsInputContainer';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';

export const WebpageEKulturIntegrationsSettings: FC = () => {
  const { t } = useTranslation('components');

  return (
    <>
      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.digitaltMuseum`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.dmOwners`)}
            </Typography>
            <TextInput path='content.settings.dmOwners' defaultValue='' label='' />
          </Container>
        </Container>
      </SettingsInputContainer>

      <SettingsInputContainer title={t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.kulturPunkt`)}>
        <Container left fullWidth>
          <Container column left sx={{ width: '50%' }}>
            <Typography variant='subtitle2'>
              {t(`Settings.WebpageSettings.WebpageEKulturIntegrationsSettings.kulturpunktOwnerID`)}
            </Typography>
            <TextInput path='content.settings.kulturpunktOwnerID_v3' defaultValue='' label='' />
          </Container>
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default WebpageEKulturIntegrationsSettings;
