import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import DraggableCollapseContainer from '@/components/DraggableCollapseContainer';
import { Draggable } from '@/declarations/Draggable';
import Container from '@/components/Container';
import TextInput from '@/components/forms/TextInput';
import Styles from '@/assets/js/Styles';
import DeleteButton from '@/components/DeleteButton';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ProgramTime } from '@/declarations/models/ProgramTime';

type ProgramTimeItemProps = {
  item: ProgramTime;
  itemPathPrefix: string;
  onReorder: (fromIndex: number, toIndex: number) => void;
  onRemove: (index: number) => void;
  index: number;
  type: Draggable;
  initiallyOpen?: boolean;
};

const ProgramTimeItem: React.FC<ProgramTimeItemProps> = ({
  item,
  itemPathPrefix,
  onReorder,
  onRemove,
  index,
  type,
  initiallyOpen = false,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  const headerContent = () => {
    let content = '';
    if (item.title) {
      content += item.title;
    }
    if (item.time__from || item.time__to) {
      content += ' (';
      if (item.time__from) {
        content += item.time__from;
      }
      if (item.time__from && item.time__to) {
        content += ' - ';
      } else if (!item.time__from && item.time__to) {
        content += ' - ';
      }
      if (item.time__to) {
        content += item.time__to;
      }
      content += ')';
    }
    if (!content) {
      content = tCommon('title');
    }

    return <Typography ml={2}>{content}</Typography>;
  };

  return (
    <DraggableCollapseContainer
      type={type}
      index={index}
      onReorder={onReorder}
      headerContent={headerContent}
      color={Styles.Colors.LIGHT_GREY}
      secondaryAction={
        <Container right>
          <DeleteButton
            onConfirm={() => onRemove(index)}
            component='IconButton'
            tooltip={tCommon('remove')}
            deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
          />
        </Container>
      }
      initiallyOpen={initiallyOpen}>
      <Container top left fullWidth column>
        <Container top left fullWidth>
          <Container m={1}>
            <TextInput
              label={tComponents('ProgramBlock.ProgramTimeItem.From')}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              path={`${itemPathPrefix}.time__from`}
            />
          </Container>
          <Container m={1}>
            <TextInput
              label={tComponents('ProgramBlock.ProgramTimeItem.To')}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              path={`${itemPathPrefix}.time__to`}
            />
          </Container>
        </Container>
        <Container left fullWidth p={1}>
          <TextInput
            label={tComponents('ProgramBlock.ProgramTimeItem.Title')}
            variant='outlined'
            path={`${itemPathPrefix}.title`}
          />
        </Container>
        <Container left fullWidth p={1}>
          <TextInput
            label={tComponents('ProgramBlock.ProgramTimeItem.Description')}
            variant='outlined'
            multiline
            rows={2}
            path={`${itemPathPrefix}.descr`}
          />
        </Container>
      </Container>
    </DraggableCollapseContainer>
  );
};

export default ProgramTimeItem;
