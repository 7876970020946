import React from 'react';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const MessagesWidget = () => {
  const { t: tComponents } = useTranslation('components');
  return (
    <Container fullWidth fullHeight top left column>
      <Container
        fullWidth
        p={2}
        sx={{
          backgroundColor: Styles.Colors.MEDIUM_DARK_GREY,
          color: 'white',
        }}>
        <Typography fontSize='1.25rem'>{tComponents('DashboardView.MessagesWidget.Title')}</Typography>
      </Container>
      <iframe
        style={{
          border: 'none',
        }}
        title='Meldinger'
        src='https://museum24.no/meldinger?presentation=true'
        width='100%'
        height='100%'
      />
    </Container>
  );
};
