import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import Container from '../../../components/Container';
import { Employee, EmployeeLink } from '../../../declarations/models/Employee';
import { noWrapTextStyle } from '../../MediaView/MediaViewTypes/ListViewItem';
import { LinkTarget } from '../../../declarations/models/LinkExternal';
import { isValidUrl } from '../../../utils/url';

const noWrapLinkStyle = {
  ...noWrapTextStyle,
  color: 'inherit',
  fontSize: 'inherit',
  ':hover': {
    fontWeight: 700,
  },
};

const getLink = (link: EmployeeLink, i: number) => {
  if (link.page_id) {
    return (
      <Container key={i}>
        <MaterialSymbol
          name='contact_page'
          sx={{
            mr: 1,
            fontSize: 'medium',
          }}
        />
        <Typography
          sx={{ ...noWrapLinkStyle, fontSize: 'inherit' }}
          component={Link}
          onClick={(e) => e.stopPropagation()}
          to={`/editor/${link.page_id}`}
          target={LinkTarget.NEW_COMMON_TAB}>
          {link.link_name}
        </Typography>
        <MaterialSymbol
          name='open_in_new'
          sx={{
            fontSize: 'medium',
          }}
        />
      </Container>
    );
  }
  if (link.url_external) {
    return (
      <Container key={i}>
        <MaterialSymbol
          name='web'
          sx={{
            mr: 1,
            fontSize: 'medium',
          }}
        />
        <Typography
          sx={{ ...noWrapLinkStyle }}
          component={Link}
          onClick={(e) => e.stopPropagation()}
          to={isValidUrl(link.url_external) ? link.url_external : '404'}
          target={LinkTarget.NEW_TAB}>
          {link.link_name}
        </Typography>
        <MaterialSymbol
          name='open_in_new'
          sx={{
            fontSize: 'medium',
          }}
        />
      </Container>
    );
  }
};

export const EmployeeInfo: FC<{ employee: Partial<Employee> }> = ({ employee }) => {
  return (
    <Container
      column
      fullWidth
      left
      gap={0}
      p={1}
      sx={{ maxWidth: '100%', textAlign: 'left', border: `1px solid ${Styles.Colors.LIGHT_GREY}`, borderTop: 'none' }}>
      <Typography fontWeight={700}>{`${employee.first_name ?? ''} ${employee?.last_name ?? ''}`}</Typography>
      {!!employee.position && (
        <Typography fontStyle='italic' fontSize={14} pr='3px'>
          {employee.position}
        </Typography>
      )}
      {!!employee.description && (
        <Typography fontSize={14} mt={1} pr='3px'>
          {employee.description}
        </Typography>
      )}
      <Container column mt={2} left gap={0.5} sx={{ maxWidth: '100%' }}>
        {employee.email_primary && (
          <Container sx={{ maxWidth: '100%' }} gap={2}>
            <MaterialSymbol name='mail' sx={{ fontSize: 'medium' }} />
            <Typography sx={{ ...noWrapTextStyle, fontSize: 'inherit' }}>{employee.email_primary}</Typography>
          </Container>
        )}
        {employee.email_secondary && (
          <Container sx={{ maxWidth: '100%' }} gap={2}>
            <MaterialSymbol name='mail' sx={{ fontSize: 'medium' }} />
            <Typography sx={{ ...noWrapTextStyle, fontSize: 'inherit' }}>{employee.email_secondary}</Typography>
          </Container>
        )}
        {employee.phone_primary && (
          <Container sx={{ maxWidth: '100%' }} gap={2}>
            <MaterialSymbol name='call' sx={{ fontSize: 'medium' }} />
            <Typography sx={{ ...noWrapTextStyle, fontSize: 'inherit' }}>{employee.phone_primary}</Typography>
          </Container>
        )}
        {employee.phone_secondary && (
          <Container sx={{ maxWidth: '100%' }} gap={2}>
            <MaterialSymbol name='call' sx={{ fontSize: 'medium' }} />
            <Typography sx={{ ...noWrapTextStyle, fontSize: 'inherit' }}>{employee.phone_secondary}</Typography>
          </Container>
        )}
      </Container>
      <Container left column mt={0.5}>
        {employee.link_list?.slice(0, 2).map(getLink)}
      </Container>
      {employee.linked_page && (
        <Container left column mt={0.5}>
          <Container>
            <MaterialSymbol
              name='contact_page'
              sx={{
                mr: 1,
                fontSize: 'medium',
              }}
            />
            <Typography sx={{ ...noWrapLinkStyle, fontSize: 'inherit' }}>{employee.linked_page?.title}</Typography>
          </Container>
        </Container>
      )}
      <Container column left top gap={1} mt={2}>
        {!!employee.department && <Typography fontSize={14}>{employee.department}</Typography>}
      </Container>
    </Container>
  );
};

export default EmployeeInfo;
