import React, { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { BlockClipboardContext, useBlockClipboard } from '@/editor/lib/BlockClipboardContext';
import EditorEventManager from './eventManager/EditorEventManager';
import EditorConfigurationProvider from './configuration/EditorConfigurationProvider';
import { EditorContext } from './declarations/EditorContext';
import Form from '../../components/forms/Form';
import BaseModel from '../../declarations/models/BaseModel';
import EditorDataProvider, { EditorDataProviderProps } from './components/EditorDataProvider';
import { EditorLayout, EditorLayoutProps } from './components/EditorLayout';
import { SaveType } from './declarations/SaveType';
import { Page } from '../../declarations/models/Page';
import { usePageEditorFormState } from '../PageEditor/PageEditorFormStateProvider';

export interface BaseEditorProps<Model extends BaseModel>
  extends Pick<EditorLayoutProps, 'header' | 'preview'>,
    Pick<EditorDataProviderProps<Model>, 'loadModel'> {
  context: EditorContext;
  onSubmit: (saveType: SaveType, model: Model) => Promise<void | Page>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubmitHandler = createContext<BaseEditorProps<any>['onSubmit'] | null>(null);

/**
 * Access a function to programmatically submit the form with the given SaveType
 */
export function useEditorSubmitHandler() {
  const handler = useContext(SubmitHandler);
  const handlerRef = useRef<typeof handler>(handler);
  handlerRef.current = handler;

  const { handleSubmit, setValue, unregister } = useFormContext();
  const { setInitialFormData, setIsSubmitting } = usePageEditorFormState();

  return useCallback(
    async (saveType: SaveType) => {
      await handleSubmit((model) => {
        setIsSubmitting(true);
        handlerRef.current?.(saveType, model)?.then((savedPage) => {
          if (savedPage) {
            setInitialFormData(savedPage as Page);
            // Clean up keys from the model that no longer exist
            const savedPageKeys = Object.keys(savedPage);
            const modelKeys = Object.keys(model);
            const keysToKeepRegistered: Array<string> = ['image'];
            // eslint-disable-next-line no-restricted-syntax
            for (const key of modelKeys) {
              if (!savedPageKeys.includes(key)) {
                if (!keysToKeepRegistered.includes(key)) {
                  unregister(key);
                }
              }
            }
            // Set updated values to form
            // eslint-disable-next-line no-restricted-syntax
            for (const [name, value] of Object.entries(savedPage)) {
              setValue(name, value); // Don't use reset(), it causes input fields getting incorrect data from the form's defaultValues after reordering
            }
          }
          setIsSubmitting(false);
        });
      })();
    },
    [handleSubmit, setInitialFormData, setIsSubmitting, setValue, unregister],
  );
}

export const BaseEditor = <Model extends BaseModel>({
  children,
  context,
  onSubmit,
  loadModel,
  header,
  preview,
}: PropsWithChildren<BaseEditorProps<Model>>) => {
  const clipboardContextValue = useBlockClipboard();
  return (
    <EditorEventManager>
      <EditorConfigurationProvider context={context}>
        <Form<Model> variant='outlined'>
          <BlockClipboardContext.Provider value={clipboardContextValue}>
            <SubmitHandler.Provider value={onSubmit}>
              <EditorDataProvider<Model> loadModel={loadModel}>
                <EditorLayout header={header} preview={preview}>
                  {children}
                </EditorLayout>
              </EditorDataProvider>
            </SubmitHandler.Provider>
          </BlockClipboardContext.Provider>
        </Form>
      </EditorConfigurationProvider>
    </EditorEventManager>
  );
};

export default BaseEditor;
