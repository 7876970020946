import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SectionDirection } from '../../../../declarations/models/Section';
import ActionMenu from '../../../../components/ActionMenu';

export interface DirectionSelectorProps {
  availableOptions?: Array<SectionDirection>;
  direction: SectionDirection | null;
  onDirectionChange: (direction: SectionDirection | null) => void;
}

const DIRECTION_ICONS: Record<SectionDirection, ReactNode> = {
  ltr: <MaterialSymbol name='arrow_forward' />,
  carousel: <MaterialSymbol name='view_carousel' />,
  snappy: <MaterialSymbol name='science' fill />,
};

export const DirectionSelector: FC<DirectionSelectorProps> = ({
  availableOptions = ['ltr', 'carousel', 'snappy'],
  direction,
  onDirectionChange,
}) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  return (
    <ActionMenu
      id='section-direction-selector'
      ariaLabel={tAria('components.SectionDirectionSelector.ActionMenuLabel')}
      tooltip={tComponents('SectionDirectionSelector.Tooltip')}
      tooltipPlacement='top'
      icon={direction ? DIRECTION_ICONS[direction] : DIRECTION_ICONS.ltr}>
      <MenuItem selected={!direction} onClick={() => onDirectionChange(null)} divider>
        <ListItemIcon>{DIRECTION_ICONS.ltr}</ListItemIcon>
        <ListItemText>{tCommon('default')}</ListItemText>
      </MenuItem>

      {availableOptions.map((directionOption) => (
        <MenuItem
          key={directionOption}
          selected={direction === directionOption}
          onClick={() => onDirectionChange(directionOption)}>
          <ListItemIcon>{DIRECTION_ICONS[directionOption]}</ListItemIcon>
          <ListItemText>{tComponents(`SectionDirectionSelector.options.${directionOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default DirectionSelector;
