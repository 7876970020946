import React, { FC, ReactNode } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@/components/CustomIcon';
import { SectionWidth } from '../../../../declarations/models/Section';
import ActionMenu from '../../../../components/ActionMenu';

export interface WidthSelectorProps {
  availableOptions?: Array<SectionWidth>;
  width: SectionWidth | null;
  onWidthChange: (width: SectionWidth | null) => void;
}

const WIDTH_ICONS: Record<SectionWidth, ReactNode> = {
  article__grid: <CustomIcon name='width_narrow' />,
  section__grid: <CustomIcon name='width_normal' />,
  full__grid: <CustomIcon name='width_full' />,
  wide__grid: <CustomIcon name='width_wider' />,
};

export const PageSectionWidthSelectorSettings: FC<WidthSelectorProps> = ({
  availableOptions = ['article__grid', 'section__grid', 'full__grid', 'wide__grid'],
  width,
  onWidthChange,
}) => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');

  return (
    <ActionMenu
      id='section-width-selector'
      ariaLabel={tAria('components.SectionWidthSelector.ActionMenuLabel')}
      tooltip={tComponents('SectionWidthSelector.Tooltip')}
      tooltipPlacement='top'
      icon={width ? WIDTH_ICONS[width] : WIDTH_ICONS.section__grid}>
      {availableOptions.map((widthOption) => (
        <MenuItem key={widthOption} selected={width === widthOption} onClick={() => onWidthChange(widthOption)}>
          <ListItemIcon>{WIDTH_ICONS[widthOption]}</ListItemIcon>
          <ListItemText>{tComponents(`SectionWidthSelector.options.${widthOption}`)}</ListItemText>
        </MenuItem>
      ))}
    </ActionMenu>
  );
};

export default PageSectionWidthSelectorSettings;
