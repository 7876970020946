import React, { FC } from 'react';
import { List, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Container from './Container';
import Styles from '../assets/js/Styles';
import { MenuItem } from '../declarations/models/MenuItem';
import { SubMenu } from './SubMenu';

export interface SettingsMenuProps {
  width?: string;
  menuItems: Array<MenuItem>;
}

export const SettingsMenu: FC<SettingsMenuProps> = ({ menuItems, width = '200px' }) => {
  const { pathname } = useLocation();

  return (
    <Container
      gap={0}
      top
      left
      py={2}
      fullHeight
      sx={{
        minWidth: width,
        width: 'auto',
        borderRight: `1px solid ${Styles.Colors.MEDIUM_LIGHT_GREY}`,
        overflow: 'auto',
      }}>
      <List sx={{ width: '100%' }} role='navigation'>
        {menuItems.map(({ path, label, subItems }) => {
          const active = pathname.endsWith(path);
          return (
            <Tooltip key={path} title={label} placement='right' arrow>
              <>
                <ListItemButton
                  component={Link}
                  aria-current={active ? 'page' : undefined}
                  to={path}
                  sx={[
                    {
                      pl: 1.5,
                      py: 1.5,
                      transition: 'all 200ms ease',
                      justifyContent: 'flex-start',
                    },
                    active &&
                      ((theme) => ({
                        borderLeft: `6px solid ${theme.palette.secondary.main}`,
                        backgroundColor: Styles.Colors.THEME_BG_COLOR,
                      })),
                  ]}>
                  <ListItemText
                    sx={(theme) => ({
                      m: 0,
                      pl: 2,
                      width: '100%',
                      transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                      }),
                    })}>
                    {label}
                  </ListItemText>
                </ListItemButton>
                {subItems && subItems.length > 0 && (
                  <SubMenu
                    open
                    menuItems={subItems}
                    toggleMenuOpen={() => {
                      return null;
                    }}
                  />
                )}
              </>
            </Tooltip>
          );
        })}
      </List>
    </Container>
  );
};

export default SettingsMenu;
