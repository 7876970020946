import React, { FC, useEffect, useState } from 'react';
import { SortSelector } from '@/components/SortSelector';
import { DMSortType, SortDirection, SortOption, SortType } from '@/declarations/models/SortOption';
import Layout from '../../Layout';
import MediaList from '../../MediaList/MediaList';
import { ViewType } from '../../../declarations/ViewType';
import FinderLayout from '../FinderLayout';
import { DMMediaModel } from '../../../declarations/models/DMMediaModel';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { toggleItem } from '../../../utils/array';
import { Api } from '../../../services/Api';
import { MediaLicense } from '../../../declarations/models/MediaLicense';
import { Settings } from '../../../Settings';
import SelectedDMMediaItem from './SelectedDMMediaItem';
import { DMObjectType } from '../../../declarations/models/DMObjectType';
import DMFinderHeader from './DMFinderHeader';

export interface DMMediaFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<DMMediaModel>>) => void;
  multiSelect?: boolean;
  objectType?: DMObjectType;
  hasLocationDep?: boolean;
}

export const DMMediaFinder: FC<DMMediaFinderProps> = ({
  open,
  onClose,
  onSelectionConfirmed,
  multiSelect = true,
  objectType,
  hasLocationDep,
}) => {
  const [selectedLicense, setSelectedLicense] = useState<MediaLicense | null>(null);
  const [hasLocation, setHasLocation] = useState<boolean>(!!hasLocationDep);
  const [hasPictures, setHasPictures] = useState<boolean>(true);
  const [sortOption, setSortOption] = useState<SortOption>({
    sortBy: SortType.RELEVANCE,
    order: SortDirection.DESC,
  });

  useEffect(() => setHasLocation(!!hasLocationDep), [hasLocationDep]);

  const [page, setPage] = useState<{ start: number; rows: number }>({ start: 0, rows: 20 });
  const resetPage = () => {
    setPage((prevPage) => ({ ...prevPage, start: 0 }));
  };
  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<DMMediaModel>>>([]);
  const [mediaItems, setMediaItems] = useState<Array<GenericMedia<DMMediaModel>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentOwner, _setCurrentOwner] = useState<string>('');
  const setCurrentOwner: typeof _setCurrentOwner = (owner) => {
    resetPage();
    _setCurrentOwner(owner);
  };
  const [selectedObjectType, _setSelectedObjectType] = useState<DMObjectType | null>(null);
  const setSelectedObjectType: typeof _setSelectedObjectType = (type) => {
    resetPage();
    _setSelectedObjectType(type);
  };
  const [queryString, _setQueryString] = useState<string>('');
  const setQueryString: typeof _setQueryString = (query) => {
    resetPage();
    _setQueryString(query);
  };

  const resetState = () => {
    setSelectedItems([]);
    setQueryString('');
    setSelectedLicense(null);
    setHasLocation(!!hasLocationDep);
    setHasPictures(true);
    setSortOption({
      sortBy: SortType.RELEVANCE,
      order: SortDirection.DESC,
    });
    setCurrentOwner('');
    setSelectedObjectType(null);
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  const isSelected = (item: GenericMedia<DMMediaModel>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<DMMediaModel>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: DMMediaFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  const sortTypeToDMSortType = (sortType: SortType): DMSortType => {
    switch (sortType) {
      case SortType.TITLE:
        return DMSortType.TITLE;
      case SortType.UPDATED_AT:
        return DMSortType.UPDATED_AT;
      case SortType.RELEVANCE:
        return DMSortType.RELEVANCE;
      default:
        return DMSortType.RELEVANCE;
    }
  };

  useEffect(() => {
    if (!open) return;

    let unmounted = false;
    setIsLoading(true);

    const type = objectType || selectedObjectType;

    const ctx = Api.getDMSearchResult({
      ...page,
      query: queryString,
      licenses: selectedLicense !== null ? [selectedLicense] : undefined,
      owners: currentOwner || undefined,
      types: type ? [type] : undefined,
      has_location: hasLocation || undefined,
      has_pictures: hasPictures || undefined,
      order_by: sortTypeToDMSortType(sortOption.sortBy),
      order: sortOption.order,
    });
    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.totalCount || 0);
          setMediaItems(
            (loadedPage?.items || []).map(
              (item) =>
                ({
                  id: item['artifact.uuid'],
                  title: item['artifact.ingress.title'],
                  url: item['artifact.defaultMediaIdentifier']
                    ? `${Settings.DM_HOST}/image/${item['artifact.defaultMediaIdentifier']}`
                    : '',
                  license: item['artifact.ingress.license']?.[0] || MediaLicense.NONE,
                  source: item,
                } as GenericMedia<DMMediaModel>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) setIsLoading(false);
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [
    queryString,
    selectedLicense,
    currentOwner,
    selectedObjectType,
    page,
    open,
    objectType,
    hasLocation,
    hasPictures,
    sortOption,
  ]);

  return (
    <FinderLayout<DMMediaModel>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => <SelectedDMMediaItem item={item} onRemoveItem={handleSelectionChanged} />}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout
        headerContent={
          <DMFinderHeader
            queryString={queryString}
            mediaLicense={selectedLicense}
            selectedOwnerCode={currentOwner}
            selectedObjectType={selectedObjectType}
            onQueryChanged={setQueryString}
            onMediaLicenseChanged={setSelectedLicense}
            onOwnerCodeChanged={setCurrentOwner}
            onObjectTypeChanged={setSelectedObjectType}
            objectTypeSelectable={!objectType}
            hasLocation={hasLocation}
            setHasLocation={setHasLocation}
            hasPictures={hasPictures}
            setHasPictures={setHasPictures}
          />
        }>
        <MediaList
          isLoading={isLoading}
          items={mediaItems}
          page={page}
          totalItemCount={totalCount}
          onPageChange={(_, start, rows) => handlePageChanged({ start, rows })}
          onPageSizeChange={(rows) => handlePageChanged({ rows })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
          // TODO: Implement favouriteable
          isFavouriteable={false}
          variant='dm'
          customHeaderElement={
            <SortSelector
              availableOptions={[SortType.TITLE, SortType.UPDATED_AT, SortType.RELEVANCE]}
              sortOption={sortOption}
              setSortOption={setSortOption}
            />
          }
        />
      </Layout>
    </FinderLayout>
  );
};

export default DMMediaFinder;
