import React, { FC, useRef } from 'react';
import { useStore } from '@/components/store/Store';
import { useTranslation } from 'react-i18next';
import { useEditorModel } from '../../lib/components/EditorDataProvider';
import { formatAPITimestamp } from '../../../utils/dates';
import { Page } from '../../../declarations/models/Page';
import { PreviewBlock } from './PreviewBlock';
import CurrentBlockProvider from '../CurrentBlockProvider';
import { CurrentSectionProvider } from '../CurrentSectionProvider';
import { SectionType } from '../../../declarations/models/SectionType';
import { MainCategory } from '../../../declarations/Category';
import { EditorEventType, useEditorEventManager } from '../../lib/eventManager/EditorEventManager';

export const Preview: FC = () => {
  const model: Page = useEditorModel<Page>();
  const {
    state: { selectedSiteData, selectedLanguageSiteData },
  } = useStore();
  const sectionContainer = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('common');
  const eventManager = useEditorEventManager();

  const svgLogo =
    selectedLanguageSiteData?.content?.settings?.logo_header ||
    selectedSiteData?.content?.settings?.logo_header ||
    false;

  const siteName = selectedLanguageSiteData?.content?.name || selectedSiteData?.name || false;
  const siteNameIsNotALocation = selectedSiteData?.content?.siteIsNotAphysicalLocation || false;

  // Just a temporary config..:
  const scrollytellModules =
    'article__bodytext, frontpage__module, article__module, article__quote, article__relations';

  return (
    <div id='previewAdmin2'>
      <div className={`template ${model?.content?.skin || 'theme-default'}`}>
        {!model?.content?.isLongread && (
          <header className='header'>
            <div className='header__grid'>
              <div className='header__ident'>
                <div className='nav-home'>
                  <div className='header__logo'>
                    {svgLogo && (
                      /* eslint-disable-next-line react/no-danger */
                      <div dangerouslySetInnerHTML={{ __html: svgLogo }} />
                    )}
                  </div>
                  <div className='header__name'>{siteName}</div>
                </div>
              </div>
              <div className='header__previewinfo'>Meny og øvrige elementer i header vises kun i sluttportal.</div>
            </div>
          </header>
        )}
        <main className='content'>
          <article className='article'>
            {model.content?.sections?.map((section, sectionIndex) => {
              if (section?.visible !== 'hidden') {
                return (
                  <CurrentSectionProvider
                    // eslint-disable-next-line react/no-array-index-key
                    key={sectionIndex}
                    section={section}
                    sectionIndex={sectionIndex}>
                    {section?.type === SectionType.ARTICLE_HEAD && (
                      <>
                        <div
                          ref={sectionContainer}
                          className={`article__head pad-top-${section?.topPadding || 'auto'} pad-bottom-${
                            section?.bottomPadding || 'auto'
                          } skin__head ${section?.skin?.length ? section?.skin : ''}`}>
                          <div className='skin-head'>
                            <div className='article__grid'>
                              {section.blocks.map((block, blockIndex) => {
                                if (block.visible !== 'hidden') {
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <React.Fragment key={blockIndex}>
                                      <CurrentBlockProvider
                                        block={block}
                                        blockIndex={blockIndex}
                                        sectionIndex={sectionIndex}>
                                        <PreviewBlock />
                                      </CurrentBlockProvider>
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </div>
                          {model?.main_category === MainCategory.EVENT ? (
                            // TODO: Make a article__event -component and loop dates!
                            <div className='article__body article__grid'>
                              <div className='article__event'>
                                {model?.event_data?.is_passed ? (
                                  <div className='article__event_past'>NB! Arrangementet har funnet sted</div>
                                ) : null}
                                <div className='article__event_container'>
                                  {(model?.event_data?.additional_location_info ||
                                    model?.location?.title ||
                                    !siteNameIsNotALocation) && (
                                    <div className='article__event_place'>
                                      <strong>
                                        {model?.event_data?.additional_location_info && (
                                          <span>{model?.event_data?.additional_location_info} </span>
                                        )}
                                        {model?.location?.title && <span> {model?.location?.title}</span>}
                                        {!siteNameIsNotALocation && !model?.location?.title && <span> {siteName}</span>}
                                      </strong>
                                    </div>
                                  )}
                                  <div className='article__event_dates'>
                                    {model?.event_data?.time_periods?.map((item, index) => {
                                      return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={index} className='event__item'>
                                          <div className='event__date'>
                                            {formatAPITimestamp(item.start_date, 'date')}
                                            {item.end_date ? '-' : null}
                                            {item.end_date ? formatAPITimestamp(item.end_date, 'date') : ''}
                                          </div>
                                          <div className='event__time'>
                                            {item.start_time ? formatAPITimestamp(item.start_time, 'time') : null}
                                            {item.end_time ? '-' : null}
                                            {item.end_time ? formatAPITimestamp(item.end_time, 'time') : ''}
                                            <span> </span>
                                            {item.free_text || model?.event_data?.time_info || ''}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                {model?.event_data?.ticket_url && (
                                  <div className='module__button'>
                                    {model?.event_data?.ticket_text || 'Kjøp billett'}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    )}
                    {section?.type === SectionType.ARTICLE_BODY && !section?.introSection && (
                      <section
                        ref={sectionContainer}
                        className={`article__body skin__body ${section?.skin?.length ? section?.skin : ''} pad-top-${
                          section?.topPadding || 'auto'
                        } pad-bottom-${section?.bottomPadding || 'auto'}`}>
                        {section.blocks.map((block, blockIndex) => {
                          if (block.visible !== 'hidden') {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <React.Fragment key={blockIndex}>
                                <CurrentBlockProvider block={block} blockIndex={blockIndex} sectionIndex={sectionIndex}>
                                  <PreviewBlock />
                                </CurrentBlockProvider>
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </section>
                    )}

                    {section?.type === SectionType.ARTICLE_BODY && section?.introSection && (
                      <section
                        ref={sectionContainer}
                        className={`article__body introSection ${
                          section?.mediaobject?.url ? 'slide--media' : 'no--media'
                        }   ${section?.skin?.length ? section?.skin : ''}`}
                        style={{ position: 'relative', padding: '0' }}>
                        <div className={`slideContainer ${section?.width || 'full__grid'}`}>
                          <div style={{ zIndex: '0', position: 'absolute', inset: '0' }}>
                            <div className={`filterContainer ${section?.filter1 || ''}`} style={{ height: '100%' }}>
                              <div
                                className={section?.filter2 || ''}
                                style={{
                                  height: '100%',
                                  backgroundImage: `url(${section?.mediaobject?.url})`,
                                  backgroundAttachment: 'fixed',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                }}>
                                .
                              </div>
                            </div>
                          </div>

                          <div
                            className={`slide-front txt--${section?.txtColor}`}
                            style={{ zIndex: '1', position: 'relative', minHeight: '75vh' }}>
                            <div style={{ color: 'black' }} className='preview__info placeholder'>
                              Scrollytell/introseksjon: Se siden i portal for reell visning.
                            </div>
                            {section.blocks.map((block, blockIndex) => {
                              if (block.visible !== 'hidden' && scrollytellModules.indexOf(block.type) !== -1) {
                                return (
                                  // TODO: restrict blocktypes for introsection
                                  // eslint-disable-next-line react/no-array-index-key
                                  <React.Fragment key={blockIndex}>
                                    <CurrentBlockProvider
                                      block={block}
                                      blockIndex={blockIndex}
                                      sectionIndex={sectionIndex}>
                                      <PreviewBlock />
                                    </CurrentBlockProvider>
                                  </React.Fragment>
                                );
                              }
                              if (block.visible !== 'hidden' && scrollytellModules.indexOf(block.type) === -1) {
                                return (
                                  <div key={`info_${block.type}`} className='preview__info'>
                                    <strong>{t(`BlockType.${block.type}`)}</strong> støttes ikke som forgrunn i en
                                    scrollytell-seksjon. Gjør evnt seksjonen om til en vanlig innholdsseksjon.
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      </section>
                    )}

                    {section?.type === SectionType.GRID_ROW && (
                      <div
                        ref={sectionContainer}
                        className={`
                  section space-${section?.spacing || 'auto'}  ${section?.cssClass} ${section?.modifier} pad-top-${
                          section?.topPadding || 'auto'
                        } ${section?.centerTitle ? 'util-section-title-center' : ''}  ${
                          section?.removeLeftPadding ? 'util-no-left-padding' : ''
                        }   pad-bottom-${section?.bottomPadding || 'auto'} ${
                          section?.skin?.length ? section?.skin : ''
                        }`}>
                        <div className={section?.width || 'section__grid'}>
                          {section.title && section.visibletitle && (
                            <h2 className={`section__title ${section?.cssClass}`}>{section?.title}</h2>
                          )}
                          {section.blocks.map((block, blockIndex) => {
                            if (block.visible !== 'hidden') {
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={blockIndex}>
                                  <CurrentBlockProvider
                                    block={block}
                                    blockIndex={blockIndex}
                                    sectionIndex={sectionIndex}>
                                    <PreviewBlock />
                                  </CurrentBlockProvider>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                    )}
                    {section?.type === SectionType.PAGE_GRID && (
                      <section
                        ref={sectionContainer}
                        className={`section--page__grid ${section?.cssClass} pad-top-${section?.topPadding || 'auto'} ${
                          section?.centerTitle ? 'util-section-title-center' : ''
                        }  pad-bottom-${section?.bottomPadding || 'auto'} ${
                          section?.skin?.length ? section?.skin : ''
                        }`}>
                        <div className={section?.width || 'section__grid'}>
                          {section.title && section.visibletitle && (
                            <h2 className={`section__title ${section?.cssClass}`}>{section?.title}</h2>
                          )}
                          {section.blocks.map((block, blockIndex) => {
                            if (block.visible !== 'hidden') {
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={blockIndex}>
                                  <CurrentBlockProvider
                                    block={block}
                                    blockIndex={blockIndex}
                                    sectionIndex={sectionIndex}>
                                    <PreviewBlock />
                                  </CurrentBlockProvider>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </section>
                    )}
                  </CurrentSectionProvider>
                );
              }
              return null;
            })}
          </article>
        </main>
        <footer className='footer'>
          <div className='footer__grid'>
            <div className='footer__contact'>
              <div className='footer__address'>
                <strong className='contact__name'>{siteName}</strong>
                <span className='contact__address'>TIL INFO: Se sluttportal for komplett visning av footer.</span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Preview;
