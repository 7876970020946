import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CollapseContainer from '../../../components/CollapseContainer';
import {
  SharingContentApprovalStatus,
  SharingGivenModel,
  SharingGrantedModel,
  SiteSharingModel,
} from '../../../declarations/models/SiteSharingModel';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import SiteSharingSettingsFormGivenItem from './SiteSharingSettingsFormGivenItem';
import Styles from '../../../assets/js/Styles';
import SiteSharingSettingsFormGrantedItem from './SiteSharingSettingsFormGrantedItem';
import Container from '../../../components/Container';
import SiteSharingSettingsNewAgreementModal from './SiteSharingSettingsNewAgreementModal';

export const SiteSharingSettingsForm: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const [newGrantedModalOpen, setNewGrantedModalOpen] = useState<boolean>(false);
  const [newGivenModalOpen, setNewGivenModalOpen] = useState<boolean>(false);

  const { fields: granted, append: appendGranted } = useControlledFieldArray<SiteSharingModel, 'granted'>({
    name: `granted`,
  });

  const {
    fields: given,
    update: updateGiven,
    append: appendGiven,
  } = useControlledFieldArray<SiteSharingModel, 'given'>({
    name: `given`,
  });

  const getBorderColor = (item: SharingGrantedModel | SharingGivenModel) => {
    switch (item.approval_status) {
      case SharingContentApprovalStatus.APPROVED:
        return Styles.Colors.SUCCESS;
      case SharingContentApprovalStatus.PENDING:
        return Styles.Colors.WARNING;
      case SharingContentApprovalStatus.DENIED:
        return Styles.Colors.ERROR;
      case SharingContentApprovalStatus.REMOVED:
        return Styles.Colors.ERROR;
      default:
        return undefined;
    }
  };

  return (
    <>
      <SiteSharingSettingsNewAgreementModal
        open={newGrantedModalOpen}
        type='granted'
        onClose={(result) => {
          if (result) {
            appendGranted(result);
          }
          setNewGrantedModalOpen(false);
        }}
      />
      <SiteSharingSettingsNewAgreementModal
        open={newGivenModalOpen}
        type='given'
        onClose={(result) => {
          if (result) {
            appendGiven(result);
          }
          setNewGivenModalOpen(false);
        }}
      />
      <CollapseContainer
        title={tComponents('Settings.SiteSettings.SiteSharingSettings.accessToX', { x: granted?.length })}>
        {granted?.map((item) => (
          <SiteSharingSettingsFormGrantedItem item={item} borderColor={getBorderColor(item)} key={item.id} />
        ))}
        <Container py={1} left>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<MaterialSymbol name='add' />}
            onClick={() => setNewGrantedModalOpen(true)}>
            {tComponents('Settings.SiteSettings.SiteSharingSettings.addNewApplication')}
          </Button>
        </Container>
      </CollapseContainer>

      <CollapseContainer
        title={tComponents('Settings.SiteSettings.SiteSharingSettings.sharingWithX', { x: given?.length })}>
        {given?.map((item, index) => (
          <SiteSharingSettingsFormGivenItem
            item={item}
            borderColor={getBorderColor(item)}
            key={item.id}
            onRequestSuccess={(updatedItem) => updateGiven(index, updatedItem)}
          />
        ))}
        <Container py={1} left>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<MaterialSymbol name='add' />}
            onClick={() => setNewGivenModalOpen(true)}>
            {tComponents('Settings.SiteSettings.SiteSharingSettings.addNewAccess')}
          </Button>
        </Container>
      </CollapseContainer>
    </>
  );
};

export default SiteSharingSettingsForm;
