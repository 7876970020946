import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, FormControl } from '@mui/material';
import Container from '../../../../components/Container';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { useStore } from '../../../../components/store/Store';
import { getToggleFieldsSettings, settingsAreaStyle } from './EmployeesAutoBlock';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { EmployeesBlock } from '../../../../declarations/models/blocks/EmployeesBlock';
import DropIndicatorArrow from '../../../../components/DropIndicatorArrow';
import { Employee } from '../../../../declarations/models/Employee';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import EmployeesBlockItem from './EmployeesBlockItem';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import SwitchInput from '../../../../components/forms/SwitchInput';

export const EmployeesManualBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const { blockPath } = useCurrentBlock();

  const {
    fields: selectedEmployees,
    append,
    insert,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<EmployeesBlock>, `${BlockPath}.items`, 'itemHash'>({
    name: `${blockPath}.items`,
  });

  const handleAddEmployee = (item: Employee | null) => {
    if (item?.id) append({ id: item.id });
  };

  return (
    <Container column top gap={2} left fullWidth>
      <Container column fullWidth gap={0}>
        <Container mb={1} fullWidth>
          <FinderButton
            type={FinderType.EMPLOYEE}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<Employee>>) =>
                items.forEach((item) => handleAddEmployee(item.source)),
            }}
          />
        </Container>
        {(selectedEmployees || []).map((item, i) => (
          <Fragment key={item.id}>
            <Collapse sx={{ width: '100%' }}>
              <DropIndicatorArrow
                label={t('Draggable.MoveHere')}
                borderStyle='dashed'
                typographyProps={{
                  variant: 'caption',
                  lineHeight: '1rem',
                  fontStyle: 'italic',
                }}
                spacing={2}
                visible
                noArrow
              />
            </Collapse>
            <EmployeesBlockItem
              index={i}
              onReorder={move}
              onAdd={(itemToAdd, addAtIndex) => itemToAdd && insert(addAtIndex, itemToAdd)}
              onRemove={remove}
              siteId={siteId}
              itemPathPrefix={`${blockPath}.items`}
            />
          </Fragment>
        ))}
      </Container>
      {getToggleFieldsSettings(t, blockPath)}
      <FormControl sx={settingsAreaStyle}>
        <SwitchInput path={`${blockPath}.enableSearch`} label={t('EmployeesBlock.EnableSearch')} />
      </FormControl>
    </Container>
  );
};

export default EmployeesManualBlock;
