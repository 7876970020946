import React, { FC } from 'react';
import { ButtonGroup, FormControl, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import { BlockPath, useCurrentBlock } from '../../../CurrentBlockProvider';
import TextInput from '../../../../../components/forms/TextInput';
import SwitchInput from '../../../../../components/forms/SwitchInput';
import Container from '../../../../../components/Container';
import BannerBlockTitleSizeSelector from './BannerBlockTitleSizeSelector';
import BannerBlockTitleWeightSelector from './BannerBlockTitleWeightSelector';
import BannerBlockTitleTransformationSelector from './BannerBlockTitleTransformationSelector';
import BannerBlockTitleAlignmentSelector from './BannerBlockTitleAlignmentSelector';
import BannerBlockTitleVerticalAlignmentSelector from './BannerBlockTitleVerticalAlignmentSelector';

export const BannerTitle: FC = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value: titleLevelValue, onChange: onChangeTitleLevel },
  } = useController<BlockSpecificPage<BannerBlock>, `${BlockPath}.titleLevel`>({
    name: `${blockPath}.titleLevel`,
  });

  return (
    <>
      <Container column fullWidth gap={2}>
        <ButtonGroup variant='contained' fullWidth color='inherit'>
          <BannerBlockTitleSizeSelector />
          <BannerBlockTitleWeightSelector />
          <BannerBlockTitleTransformationSelector width='20%' />
          <BannerBlockTitleAlignmentSelector width='20%' />
          <BannerBlockTitleVerticalAlignmentSelector width='20%' />
        </ButtonGroup>

        <Container fullWidth>
          <TextInput
            path={`${blockPath}.title`}
            label={t('BannerBlock.BannerTitle.TitleLabel')}
            defaultValue=''
            multiline
            size='small'
          />
          <TextInput
            fullWidth={false}
            path={`${blockPath}.insertTitle`}
            defaultValue=''
            label={t('BannerBlock.BannerTitle.RunningHeadLabel')}
            size='small'
          />
        </Container>

        <Container fullWidth left>
          <SwitchInput path={`${blockPath}.hidetitle`} label={t('BannerBlock.BannerTitle.HideTitleLabel')} />
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={titleLevelValue === 'h1'}
                  onChange={(_, checked) => onChangeTitleLevel(checked ? 'h1' : '')}
                  color='secondary'
                />
              }
              label={t('BannerBlock.BannerTitle.SetTitleAsH1')}
            />
          </FormControl>
        </Container>
      </Container>
    </>
  );
};

export default BannerTitle;
