import React, { FC, useEffect, useState } from 'react';
import { IconButton, InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useDebounce } from '../hooks/useDebounce';

export interface SearchFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  initialValue?: string;
  onQueryChange: (query: string) => void;
  resetSearch?: number;
  round?: boolean;
  sx?: SxProps;
}

export const SearchField: FC<SearchFieldProps> = ({
  sx,
  initialValue,
  onQueryChange,
  round = false,
  resetSearch = 0,
  ...rest
}) => {
  const { t } = useTranslation('common');
  const [queryString, setQueryString] = useState<string>(initialValue || '');
  useDebounce(300, queryString, onQueryChange);
  useEffect(() => {
    if (resetSearch) {
      setQueryString('');
    }
  }, [resetSearch]);
  return (
    <TextField
      placeholder={t('search')}
      color='secondary'
      value={queryString}
      onChange={(event) => setQueryString(event.target.value || '')}
      sx={sx}
      InputProps={{
        sx: (theme) => ({ borderRadius: round ? theme.spacing(4) : undefined }),
        startAdornment: (
          <InputAdornment sx={(theme) => ({ color: theme.palette.primary.contrastText })} position='start'>
            <MaterialSymbol name='search' />
          </InputAdornment>
        ),
        endAdornment: queryString && (
          <InputAdornment position='end'>
            <IconButton onClick={() => setQueryString('')}>
              <MaterialSymbol name='clear' />
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete='off'
      {...rest}
    />
  );
};

export default SearchField;
