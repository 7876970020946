import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useCurrentBlock } from '../../../CurrentBlockProvider';
import { BannerBlock, BannerTitleWeight } from '../../../../../declarations/models/blocks/BannerBlock';
import { BlockSpecificPage } from '../../../../lib/declarations/BlockSpecificPage';
import ActionMenuButton from '../../../../../components/ActionMenuButton';

const WEIGHT_OPTIONS: Array<BannerTitleWeight> = Object.values(BannerTitleWeight);

export const BannerBlockTitleWeightSelector: FC = () => {
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const {
    field: { value, onChange },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof blockPath}.titleWeight`>({
    name: `${blockPath}.titleWeight`,
  });

  useEffect(() => {
    if (!value || !Object.values(BannerTitleWeight).includes(value)) {
      onChange(BannerTitleWeight.DEFAULT);
    }
  }, [onChange, value]);

  return (
    <ActionMenuButton
      id='text-size-selector'
      ariaLabel={tAria('components.BannerTitle.BannerBlockTitleWeightSelector.ActionMenuLabel')}
      tooltip={tComponents('BannerBlock.BannerTitle.BannerBlockTitleWeightSelector.tooltip')}
      tooltipPlacement='top'
      icon={<MaterialSymbol name='format_bold' />}
      text={
        value
          ? tComponents(`BannerBlock.BannerTitle.BannerBlockTitleWeightSelector.${value}`)
          : tComponents(`BannerBlock.BannerTitle.BannerBlockTitleWeightSelector.${BannerTitleWeight.DEFAULT}`)
      }>
      {WEIGHT_OPTIONS.map((weightOption) => (
        <MenuItem
          key={weightOption}
          onClick={() => onChange(weightOption)}
          selected={value === weightOption}
          aria-label={tComponents(`BannerBlock.BannerTitle.BannerBlockTitleWeightSelector.${weightOption}`)}>
          <ListItemIcon>
            <MaterialSymbol name='format_bold' />
          </ListItemIcon>
          <ListItemText>
            {tComponents(`BannerBlock.BannerTitle.BannerBlockTitleWeightSelector.${weightOption}`)}
          </ListItemText>
        </MenuItem>
      ))}
    </ActionMenuButton>
  );
};

export default BannerBlockTitleWeightSelector;
