/* istanbul ignore file */

export enum MediaResourceType {
  ANIMATION = 'gif',
  ASSET = 'asset',
  AUDIO = 'audio',
  CARD = 'card',
  DOCUMENT = 'document',
  FORM = 'form',
  IMAGE = 'image',
  LOCATION = 'location',
  PAGE = 'page',
  PERSON = 'person',
  SYSTEM_IMAGE = 'system_image',
  VIDEO = 'video',
}
