import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { ChildrenProp } from '../../declarations/ChildrenProp';
import { useStore } from '../../components/store/Store';
import { Site } from '../../declarations/models/Site';
import { Api } from '../../services/Api';
import BaseSettingsViewForm from '../BaseSettingsViewForm';

export interface LanguageSettingsViewProps extends ChildrenProp {
  locale: string;
}
export const LanguageSettingsView: FC<LanguageSettingsViewProps> = ({ children, locale }) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;

  const MENU_ITEMS = [
    { path: `/language-settings/${locale}/languageInfo`, label: tComponents('Settings.titles.languageInfo') },
    { path: `/language-settings/${locale}/seo`, label: tComponents('Settings.titles.seo') },
    { path: `/language-settings/${locale}/404`, label: tComponents('Settings.titles.404') },
    { path: `/language-settings/${locale}/logo`, label: tComponents('Settings.titles.logo') },
    { path: `/language-settings/${locale}/menu`, label: tComponents('Settings.titles.menu') },
    { path: `/language-settings/${locale}/footer`, label: tComponents('Settings.titles.footer') },
  ];

  const handleSaveSettingsForm = useCallback(
    async (site: Site): Promise<void | Site> => {
      const savedFormData = await Api.saveSiteContent<Site>(site, selectedSiteId, locale).fetchDirect(null);
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        return savedFormData;
      }
    },
    [enqueueSnackbar, locale, selectedSiteId, tCommon],
  );

  const handleLoadModel = () => {
    return Api.getSiteContent(selectedSiteId, locale);
  };

  return (
    <BaseSettingsViewForm<Site>
      title={tCommon(`language`)}
      onSubmit={handleSaveSettingsForm}
      loadModel={handleLoadModel}
      menuItems={MENU_ITEMS}>
      <>
        <Typography my={2} variant='h1' fontSize='xx-large'>
          {tCommon(`Languages.${locale}`)}
        </Typography>
        {children}
      </>
    </BaseSettingsViewForm>
  );
};

export default LanguageSettingsView;
