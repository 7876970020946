import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';
import Layout from '../../Layout';
import MediaList from '../../MediaList/MediaList';
import { ViewType } from '../../../declarations/ViewType';
import FinderLayout from '../FinderLayout';
import { GenericMedia } from '../../../declarations/GenericMedia';
import { toggleItem } from '../../../utils/array';
import { Api } from '../../../services/Api';
import { MediaLicense } from '../../../declarations/models/MediaLicense';
import SelectedKPOwnerItem from './SelectedKPOwnerItem';
import KPOwnerFinderHeader from './KPOwnerFinderHeader';
import { KPOwner } from '../../../declarations/models/KP';
import { resolveLocalizedString } from '../../../utils/strings';

export interface KPOwnerFinderProps {
  open: boolean;
  onClose: () => void;
  onSelectionConfirmed: (selectedItems: Array<GenericMedia<KPOwner>>) => void;
  multiSelect?: boolean;
}

export const KPOwnerFinder: FC<KPOwnerFinderProps> = ({ open, onClose, onSelectionConfirmed, multiSelect = true }) => {
  const [selectedItems, setSelectedItems] = useState<Array<GenericMedia<KPOwner>>>([]);
  const [items, setItems] = useState<Array<GenericMedia<KPOwner>>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<{ pageNumber: number; pageSize: number }>({ pageNumber: 1, pageSize: 20 });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryString, _setQueryString] = useState<string>('');
  const setQueryString: typeof _setQueryString = (query) => {
    setPage((prevPage) => ({ ...prevPage, pageNumber: 1 }));
    _setQueryString(query);
  };

  const resetState = () => {
    setSelectedItems([]);
    setQueryString('');
    setPage({ pageNumber: 1, pageSize: 20 });
  };

  const _onClose = () => {
    onClose();
    resetState();
  };

  const isSelected = (item: GenericMedia<KPOwner>): boolean => {
    return selectedItems.some((i) => i.id === item.id);
  };

  const handleSelectionChanged = (item: GenericMedia<KPOwner>) => {
    if (multiSelect) {
      setSelectedItems((selected) => toggleItem(selected, item, (a, b) => a.id === b.id));
    } else {
      setSelectedItems((previouslySelected) =>
        previouslySelected.length === 1 && previouslySelected[0].id === item.id ? [] : [item],
      );
    }
  };

  const handlePageChanged = (changes: Partial<typeof page>) => {
    setPage((prevPage) => ({ ...prevPage, ...changes }));
  };

  const handleSelectionConfirmed: KPOwnerFinderProps['onSelectionConfirmed'] = (selected) => {
    _onClose();
    setSelectedItems([]);
    onSelectionConfirmed(selected);
  };

  useEffect(() => {
    if (!open) return;

    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getKPOwners({
      start: page.pageNumber - 1,
      rows: page.pageSize,
      query: queryString,
    });
    ctx
      .fetchDirect(null)
      .then((loadedPage) => {
        if (!unmounted) {
          setTotalCount(loadedPage?.total_count || 0);
          setItems(
            (loadedPage?.items || []).map(
              (item) =>
                ({
                  id: String(item.id),
                  title: resolveLocalizedString(i18next.language, item.name),
                  url: item.image_url,
                  license: MediaLicense.NONE,
                  source: item,
                } as GenericMedia<KPOwner>),
            ),
          );
        }
      })
      .finally(() => {
        if (!unmounted) setIsLoading(false);
      });
    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [queryString, page, open]);

  return (
    <FinderLayout<KPOwner>
      open={open}
      selectedItems={selectedItems}
      renderSelectedItem={(item) => <SelectedKPOwnerItem item={item} onRemoveItem={handleSelectionChanged} />}
      onConfirmSelection={handleSelectionConfirmed}
      onClose={_onClose}>
      <Layout headerContent={<KPOwnerFinderHeader onQueryChanged={setQueryString} />}>
        <MediaList
          isLoading={isLoading}
          items={items}
          page={page}
          totalItemCount={totalCount}
          onPageChange={(pageNumber, _, pageSize) => handlePageChanged({ pageNumber, pageSize })}
          onPageSizeChange={(pageSize) => handlePageChanged({ pageSize })}
          defaultSelectedViewType={ViewType.GRID}
          getIsSelected={isSelected}
          onSelectChanged={handleSelectionChanged}
          isSelectable
        />
      </Layout>
    </FinderLayout>
  );
};

export default KPOwnerFinder;
