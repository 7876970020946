import React, { FC, ReactNode, useRef, useState } from 'react';
import { IconButton, IconButtonProps, Menu, Tooltip, TooltipProps } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface ActionMenuProps extends Pick<IconButtonProps, 'color' | 'edge' | 'sx'>, ChildrenProp {
  id: string;
  icon?: ReactNode;
  ariaLabel: string;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  keepOpenOnClick?: boolean;
  disabled?: boolean;
  hiddenWhenDisabled?: boolean;
  onClose?: () => void;
}

/**
 * An implementation of the MUI Menu using an IconButton as a trigger.
 * This component is responsible for correctly handling A11Y.
 * @param id ID of the trigger. This should be unique
 * @param ariaLabel A good description of the menu, and it's purpose
 * @param icon The Icon to use for the trigger
 * @param children All menu items
 * @param tooltip An optional tooltip to display while hovering the button
 * @param tooltipPlacement The placement of the tooltip. Selected automatically if not defined
 * @param keepOpenOnClick When false (the default), the menu is closed when an item is selected/clicked
 * @param disabled Make it grey and not clickable
 * @param hiddenWhenDisabled Hide the whole ActionMenu when disabled
 * @param onClose
 * @param buttonProps Additional props passed to the trigger
 * @constructor
 */
export const ActionMenu: FC<ActionMenuProps> = ({
  id,
  ariaLabel,
  icon = <MaterialSymbol name='more_vert' />,
  children,
  tooltip,
  tooltipPlacement,
  keepOpenOnClick = false,
  disabled = false,
  hiddenWhenDisabled = false,
  onClose,
  ...buttonProps
}) => {
  const actionMenuButton = useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => setIsOpen(true);
  const close = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  // Stop event since an action menu may be used inside other clickable elements
  return (
    <>
      {!(hiddenWhenDisabled && disabled) && (
        <div role='none' onClick={(e) => e.stopPropagation()}>
          <Tooltip title={tooltip || ''} placement={tooltipPlacement} arrow>
            <span>
              <IconButton
                sx={{ fontSize: '1em' }}
                {...buttonProps}
                ref={actionMenuButton}
                id={`${id}-button`}
                aria-label={ariaLabel}
                aria-haspopup='true'
                aria-controls={`${id}-menu`}
                onClick={open}
                disabled={disabled}>
                {icon}
              </IconButton>
            </span>
          </Tooltip>
          <Menu
            id={`${id}-menu`}
            anchorEl={actionMenuButton.current}
            open={isOpen}
            onClose={close}
            onClick={() => !keepOpenOnClick && close()}
            MenuListProps={{ style: { maxHeight: '80vh' } }}>
            {children}
          </Menu>
        </div>
      )}
    </>
  );
};

export default ActionMenu;
