import React, { forwardRef } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container, { ContainerProps } from './Container';
import { mergeSx } from '../utils/mergeSx';

export interface DragAndDropIconProps extends Omit<ContainerProps, 'component'> {
  isDragging?: boolean;
}

export const DragAndDropIcon = forwardRef<HTMLDivElement, DragAndDropIconProps>(
  ({ isDragging, sx = {}, ...containerProps }, ref) => {
    return (
      <Container
        component='span'
        ref={ref}
        sx={mergeSx(
          {
            color: 'text.secondary',
            cursor: isDragging ? 'grabbing' : 'grab',
          },
          sx,
        )}
        {...containerProps}>
        <MaterialSymbol name='drag_indicator' />
      </Container>
    );
  },
);

DragAndDropIcon.displayName = 'DragAndDropIcon';

export default DragAndDropIcon;
