import React, { FC, ReactNode } from 'react';
import Layout from '../../../components/Layout';
import Container from '../../../components/Container';
import { ChildrenProp } from '../../../declarations/ChildrenProp';

export interface EditorLayoutProps extends ChildrenProp {
  header: ReactNode;
  preview: ReactNode;
}

export const EditorLayout: FC<EditorLayoutProps> = ({ children, header, preview }) => {
  return (
    <Layout headerContent={header}>
      <Container fullWidth fullHeight spaceBetween top gap={0}>
        <Container sx={{ maxWidth: '50%' }} column top left fullWidth fullHeight gap={0}>
          {children}
        </Container>
        <Container sx={{ maxWidth: '50%', position: 'relative' }} column top left fullWidth fullHeight gap={0}>
          {preview}
        </Container>
      </Container>
    </Layout>
  );
};

export default EditorLayout;
