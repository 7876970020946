import React, { FC } from 'react';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/Container';
import { Employee } from '../../../declarations/models/Employee';
import { inputGroupStyling } from '../../MediaEditor/MediaEditorFields/EditMediaTextFields';
import { EmployeeContactField } from '../EmployeeEditor';

export interface EditEmployeeContactFieldsProps {
  employee?: Partial<Employee>;
  onChange: (attribute: EmployeeContactField, value: string) => void;
}

export const EditEmployeeContactFields: FC<EditEmployeeContactFieldsProps> = ({ employee, onChange }) => {
  const { t } = useTranslation('components');

  return (
    <Container left sx={inputGroupStyling} column>
      <Typography variant='h6'>{t('EmployeeEditor.ContactFieldsTitle')}</Typography>
      <Container fullWidth column left gap={2}>
        <TextField
          size='small'
          label={t('EmployeeEditor.Email')}
          fullWidth
          value={employee?.email_primary ?? ''}
          onChange={(e) => onChange('email_primary', e.target.value)}
        />
        <TextField
          size='small'
          label={t('EmployeeEditor.Email')}
          fullWidth
          value={employee?.email_secondary ?? ''}
          onChange={(e) => onChange('email_secondary', e.target.value)}
        />
        <Container fullWidth gap={2}>
          <TextField
            size='small'
            label={t('EmployeeEditor.Phone')}
            fullWidth
            value={employee?.phone_primary ?? ''}
            onChange={(e) => onChange('phone_primary', e.target.value)}
          />
          <TextField
            size='small'
            label={t('EmployeeEditor.Phone')}
            fullWidth
            value={employee?.phone_secondary ?? ''}
            onChange={(e) => onChange('phone_secondary', e.target.value)}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default EditEmployeeContactFields;
