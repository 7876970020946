import React, { FC } from 'react';
import Styles from '../../../../assets/js/Styles';
import Container, { ContainerProps } from '../../../../components/Container';
import { mergeSx } from '../../../../utils/mergeSx';

export const MediaOptionContainer: FC<ContainerProps> = ({ children, sx = {}, ...containerProps }) => {
  return (
    <Container
      sx={mergeSx(
        {
          backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          px: 4,
          py: 2,
          my: 5,
        },
        sx,
      )}
      left
      fullWidth
      {...containerProps}>
      {children}
    </Container>
  );
};

export default MediaOptionContainer;
