import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader';
import Container from '../../../components/Container';
import { useApi } from '../../../hooks/useApi';
import { Api } from '../../../services/Api';
import { useStore } from '../../../components/store/Store';
import SelectInput from '../../../components/forms/SelectInput';
import TextInput from '../../../components/forms/TextInput';

export const LanguageCreationViewForm: FC = () => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();

  const [languages, isLoadingLanguages, reloadLanguages] = useApi(() => Api.getLanguages(false), []);
  const siteContents = state.selectedSite?.site_contents;
  const availableLanguages = languages?.filter((language) => !siteContents?.find((l) => l.locale === language.code));
  const availableLanguageCodes = availableLanguages.map((l) => {
    return l.code;
  });

  useEffect(() => {
    reloadLanguages();
  }, [reloadLanguages]);

  return (
    <>
      {isLoadingLanguages ? (
        <Container fullWidth fullHeight>
          <Loader size={25} />
        </Container>
      ) : (
        <Container fullWidth top left column gap={2}>
          <SelectInput
            defaultValue=''
            options={['', ...availableLanguageCodes]}
            getOptionKey={String}
            getOptionLabel={(l) => (l ? tCommon(`Languages.${l}`) : tCommon(`none`))}
            path='locale'
            label={tCommon('selectFromList')}
            required
            fullWidth
          />
          <TextInput
            path='content.name'
            placeholder={tCommon(`ifOtherThanX`, { x: state.selectedSite?.name || '' })}
            label={tComponents('Settings.LanguageSettings.LanguageCreationView.name')}
            defaultValue=''
          />
          <TextInput
            path='content.settings.languagetext'
            placeholder={tComponents('Settings.LanguageSettings.LanguageCreationView.languagetextPlaceholder')}
            label={tComponents('Settings.LanguageSettings.LanguageCreationView.languagetext')}
            defaultValue=''
          />
          <TextInput
            path='content.description'
            placeholder={tCommon(`ifOtherThanX`, { x: state.selectedSite?.content?.description || '' })}
            label={tComponents('Settings.LanguageSettings.LanguageCreationView.description')}
            defaultValue=''
          />
        </Container>
      )}
    </>
  );
};

export default LanguageCreationViewForm;
