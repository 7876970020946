/* istanbul ignore file */
import { DMMediaModel } from './DMMediaModel';
import { Coordinate } from './Coordinate';
import { LocalSize } from './LocalSize';
import { Credit } from './M24MediaModel';

type CommonLocals = Partial<Coordinate> & {
  disableZoom?: boolean;
  disableCaption?: boolean;
  anySource: string;
  caption?: string;
  unknownSource: false;
  vimeoId: false;
  youtubeId: false;
  _editmode: boolean;
  size?: LocalSize;
  mimetype?: string;
};

export type CommonMediaModel = Partial<Coordinate> & {
  visibility?: boolean;
  type: 'external_url';
  url?: string;
  mimetype?: string;
  content?: {
    caption?: string;
    credits?: Array<Credit>;
  };
};

type UnknownMediaModel = {
  local: Omit<CommonLocals, 'unknownSource'> & {
    unknownSource: true;
  };
};

type DiMuMediaModel = {
  id: string;
  dimuId: string;
  dmsId: string;
  objectId: string;
  source: 'digitaltMuseum';
  url: string;
  mimetype?: string;
  item: DMMediaModel;
  local: CommonLocals & {
    dmsId: string;
    title?: string;
    sourceText?: string;
    sourceUrl?: string;
  };
};

type YouTubeMediaModel = {
  local: Omit<CommonLocals, 'youtubeId'> & {
    youtubeId: string;
  };
};

type VimeoMediaModel = {
  local: Omit<CommonLocals, 'vimeoId'> & {
    vimeoId: string;
  };
};

/**
 * Represents MediaItem that comes from an external/unknown source, like Vimeo, YouTube, etc.
 */
export type ExternalMediaModel = CommonMediaModel &
  (UnknownMediaModel | DiMuMediaModel | YouTubeMediaModel | VimeoMediaModel);

export function isDiMuMediaModel(model: unknown): model is DiMuMediaModel {
  return (
    !!model &&
    typeof model === 'object' &&
    Object.keys(model).includes('source') &&
    (model as DiMuMediaModel).source === 'digitaltMuseum'
  );
}

export function isVimeoMediaModel(model: unknown): model is VimeoMediaModel {
  return (
    !!model &&
    typeof model === 'object' &&
    Object.keys(model).includes('local') &&
    (model as VimeoMediaModel)?.local !== null &&
    typeof (model as VimeoMediaModel).local === 'object' &&
    Object.keys((model as VimeoMediaModel).local).includes('vimeoId') &&
    !!(model as VimeoMediaModel).local.vimeoId
  );
}

export function isYouTubeMediaModel(model: unknown): model is YouTubeMediaModel {
  return (
    !!model &&
    typeof model === 'object' &&
    Object.keys(model).includes('local') &&
    (model as YouTubeMediaModel)?.local !== null &&
    typeof (model as YouTubeMediaModel).local === 'object' &&
    Object.keys((model as YouTubeMediaModel).local).includes('youtubeId') &&
    !!(model as YouTubeMediaModel).local.youtubeId
  );
}
