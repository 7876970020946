import React, { FC } from 'react';
import { Button, ButtonGroup, Chip, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { formatAPITimestamp } from '../../utils/dates';
import Container from '../../components/Container';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';

export interface EditorHeaderProps {
  mediaItem?: M24MediaModel;
  previewOpen: boolean;
  setPreviewOpen: () => void;
  onClose: () => void;
  onSave: () => Promise<void>;
  isDirty?: boolean;
}

export const MediaEditorHeader: FC<EditorHeaderProps> = ({
  mediaItem,
  previewOpen,
  setPreviewOpen,
  onClose,
  onSave,
  isDirty,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const confirm = useConfirmDialog();

  const handleSaveAndExit = () => onSave().then(onClose);

  const handleExit = async () => {
    if (!isDirty || (await confirm(tCommon('exitUnsavedWarning')))) {
      onClose();
    }
  };

  return (
    <Container left fullWidth>
      <Button onClick={handleExit} startIcon={<MaterialSymbol name='cancel' />} sx={{ minWidth: 'fit-content' }}>
        {isDirty ? tCommon('cancel') : tCommon('exit')}
      </Button>
      <Divider orientation='vertical' variant='middle' flexItem sx={{ marginLeft: -1 }} />
      <Typography variant='h5' noWrap>
        {mediaItem?.title || mediaItem?.content?.title || mediaItem?.filename}
      </Typography>
      <Container sx={{ minWidth: 'fit-content', marginLeft: 'auto' }}>
        <Chip
          color='secondary'
          variant='outlined'
          label={tComponents('EditorHeader.LastUpdatedAt', {
            at: formatAPITimestamp(mediaItem?.updated_at || mediaItem?.created_at),
          })}
        />
        <Tooltip title={previewOpen ? 'Hide preview' : 'Show preview'} arrow>
          <IconButton onClick={setPreviewOpen}>
            {previewOpen ? <MaterialSymbol name='vertical_split' /> : <MaterialSymbol name='view_headline' />}
          </IconButton>
        </Tooltip>

        <ButtonGroup variant='contained' color='success' sx={{ minWidth: 'fit-content' }}>
          <Button disabled={!isDirty} onClick={onSave}>
            {tCommon('save')}
          </Button>
          <Button disabled={!isDirty} onClick={handleSaveAndExit} sx={{ width: 'fit-content' }}>
            {tCommon('saveAndExit')}
          </Button>
        </ButtonGroup>
      </Container>
    </Container>
  );
};

export default MediaEditorHeader;
