import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteButton from '@/components/DeleteButton';
import { noop } from '@/utils/functions';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import { EditorSectionActionButtonProps } from '../../../lib/components/EditorSectionActionButton';

export type CardModuleBlockActionsProps = Pick<EditorSectionActionButtonProps, 'onDelete'>;

export const CardModuleBlockActions: FC<CardModuleBlockActionsProps> = ({ onDelete }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <Container right>
      <DeleteButton
        onConfirm={onDelete ?? noop}
        component='IconButton'
        tooltip={tCommon('remove')}
        deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
      />
    </Container>
  );
};

export default CardModuleBlockActions;
