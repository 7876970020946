import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import SettingsInputContainer from '../../components/SettingsInputContainer';
import Container from '../../components/Container';
import M24PageFinder from '../../components/Finder/M24PageFinder/M24PageFinder';
import { Page } from '../../declarations/models/Page';
import { Site } from '../../declarations/models/Site';
import Styles from '../../assets/js/Styles';
import DeleteButton from '../../components/DeleteButton';

export const Language404Settings: FC = () => {
  const { t } = useTranslation('components');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);

  const {
    field: { value: pageNotFoundPage, onChange: onChangePageNotFoundPage },
  } = useController<Site, `content.settings.pageNotFoundPage`>({
    name: `content.settings.pageNotFoundPage`,
  });

  const {
    field: { value: pageNotFoundTitle, onChange: onChangePageNotFoundTitle },
  } = useController<Site, `content.settings.pagenotfoundTitle`>({
    name: `content.settings.pagenotfoundTitle`,
    defaultValue: '',
  });

  const {
    field: { value: pageNotFoundText, onChange: onChangePageNotFoundText },
  } = useController<Site, `content.settings.pagenotfoundText`>({
    name: `content.settings.pagenotfoundText`,
    defaultValue: '',
  });

  const handleOnChangePageNotFoundPage = (item: Page) => {
    onChangePageNotFoundTitle('');
    onChangePageNotFoundText('');
    onChangePageNotFoundPage(item);
  };

  return (
    <>
      <SettingsInputContainer title={t('Settings.LanguageSettings.404.title')} anchor='404'>
        <Container fullWidth column gap={2} left>
          <M24PageFinder
            hideSiteSelector
            open={pageFinderOpen}
            onClose={() => setPageFinderOpen(false)}
            onSelectionConfirmed={(pages) => {
              if (pages.length) {
                handleOnChangePageNotFoundPage(pages[0].source);
              }
            }}
          />

          {pageNotFoundPage && (
            <Container
              px={2}
              fullWidth
              spaceBetween
              sx={{
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                height: '50px',
              }}>
              <Container>
                <PageStatusCircle status={pageNotFoundPage.status} size={20} />
                <Typography>{pageNotFoundPage.title}</Typography>
                <Typography
                  variant='caption'
                  sx={{ px: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {pageNotFoundPage.path || pageNotFoundPage.url}
                </Typography>
              </Container>
              <DeleteButton
                onConfirm={() => onChangePageNotFoundPage(null)}
                deleteLabel='remove'
                deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
              />
            </Container>
          )}
          {!pageNotFoundPage && (
            <Container
              px={2}
              fullWidth
              spaceBetween
              sx={{
                backgroundColor: Styles.Colors.THEME_BG_COLOR,
                border: `1px dashed ${Styles.Colors.MEDIUM_DARK_GREY}`,
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                height: '50px',
              }}>
              <Typography>{t(`Settings.LanguageSettings.404.404pageMissing`)}</Typography>
            </Container>
          )}

          <Button
            variant='contained'
            color='secondary'
            sx={{ flex: '1 0 auto' }}
            onClick={() => setPageFinderOpen(true)}>
            {t(`Settings.LanguageSettings.404.addPageButton`)}
          </Button>
        </Container>
      </SettingsInputContainer>
      <SettingsInputContainer title={t('Settings.LanguageSettings.404.title')} anchor='404'>
        <Container fullWidth column gap={2} left>
          {pageNotFoundPage && (
            <Typography variant='caption'>{t('Settings.LanguageSettings.404.pagenotfoundInfoMessage')}</Typography>
          )}
          <TextField
            label={t('Settings.LanguageSettings.404.pagenotfoundTitle')}
            value={pageNotFoundTitle}
            disabled={!!pageNotFoundPage}
            onChange={onChangePageNotFoundTitle}
            type='text'
            autoComplete='off'
            fullWidth
            color='secondary'
          />
          <TextField
            label={t('Settings.LanguageSettings.404.pagenotfoundText')}
            value={pageNotFoundText}
            disabled={!!pageNotFoundPage}
            onChange={onChangePageNotFoundText}
            type='text'
            autoComplete='off'
            fullWidth
            color='secondary'
            multiline
            minRows={3}
            maxRows={10}
          />
        </Container>
      </SettingsInputContainer>
    </>
  );
};

export default Language404Settings;
