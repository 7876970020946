import React, { FC, useEffect, useState } from 'react';
import Loader from '@/components/Loader';
import Container from '@/components/Container';
import { Settings } from '@/Settings';
import { UserInformation } from '@/declarations/models/UserInformation';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import M24Logo from '@/components/icons/M24Logo';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import { Api } from '../../services/Api';
import { useStore } from '../store/Store';
import { ChildrenProp } from '../../declarations/ChildrenProp';

function hasAccessToApp(currentUser: UserInformation): boolean {
  // global access?
  if (currentUser?.cura_data?.appAccess?.apps?.some((app) => app.id === Settings.EKULTUR_APPLICATION_ID)) {
    return true;
  }

  // access in at least one museum
  const allMuseumApps = currentUser?.cura_data?.appAccess?.museums?.flatMap((museum) => museum.applications);
  if (allMuseumApps?.some((app) => app.id === Settings.EKULTUR_APPLICATION_ID)) {
    return true;
  }

  return false;
}

export const EkulturLoginController: FC<ChildrenProp> = ({ children }) => {
  const {
    setCurrentUser,
    state: { currentUser },
  } = useStore();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(AuthenticationService.isAuthenticated());
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const { t: tComponents } = useTranslation('components');
  const navigate = useNavigate();

  useEffect(() => {
    let unmounted = false;

    AuthenticationService.refreshToken()
      .then(() => Api.syncEKulturUser())
      .catch(() => AuthenticationService.login())
      .finally(() => {
        if (!unmounted) {
          setIsAuthenticated(AuthenticationService.isAuthenticated());
        }
      });

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;
    if (isAuthenticated) {
      setLoadingUser(true);
      const ctx = Api.getCurrentUserInfo();
      ctx
        .fetchDirect(null)
        .then((user) => {
          if (!unmounted) {
            setCurrentUser(user);
          }
        })
        .catch(() => {
          if (!unmounted) {
            setCurrentUser(null);
          }
        })
        .finally(() => {
          setLoadingUser(false);
        });
      return () => {
        ctx.abort();
        unmounted = true;
      };
    }
  }, [isAuthenticated, setCurrentUser]);

  if (loadingUser) {
    return (
      <Container
        sx={{
          height: '100vh',
        }}>
        <Loader size={40} loadingText={tComponents('EkulturLoginController.LoadingUser')} />
      </Container>
    );
  }

  if (!currentUser || !hasAccessToApp(currentUser)) {
    return (
      <Container
        sx={{
          height: '100vh',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}>
          <M24Logo color='secondary' width='300px' height='100px' />
          <p>{tComponents('EkulturLoginController.NoAccess')}</p>
          {isAuthenticated && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}>
              {currentUser?.user && (
                <Typography
                  sx={{
                    fontSize: 'small',
                    fontStyle: 'italic',
                  }}>
                  {tComponents('EkulturLoginController.LoggedInAsUser', {
                    user: `${currentUser.user.name}${
                      currentUser.cura_data?.primary_email ? ` (${currentUser.cura_data.primary_email})` : ''
                    }`,
                  })}
                </Typography>
              )}
              <Button variant='outlined' color='secondary' onClick={() => navigate('/logout')}>
                {tComponents('components:UserAvatarMenu.logOut')}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    );
  }
  return <>{children}</>;
};

export default EkulturLoginController;
