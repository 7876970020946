import React from 'react';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import { Status } from '@/declarations/models/Status';
import { Box } from '@mui/material';

const FormFinderHeader = ({
  onQueryChange,
  onStatusFilterChange,
  statusFilter,
}: {
  onQueryChange: (query: string) => void;
  onStatusFilterChange: (status: Status[]) => void;
  statusFilter: Status[];
}) => {
  return (
    <Box p={2}>
      <ToolbarHeader
        selectedStatuses={statusFilter}
        onQueryChange={onQueryChange}
        onSelectedStatusesChange={onStatusFilterChange}
      />
    </Box>
  );
};

export default FormFinderHeader;
