import React, { forwardRef, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { mergeSx } from '../utils/mergeSx';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface ContainerProps
  extends ChildrenProp,
    Partial<
      Pick<
        BoxProps,
        | 'component'
        | 'm'
        | 'mt'
        | 'mb'
        | 'ml'
        | 'mr'
        | 'mx'
        | 'my'
        | 'p'
        | 'pt'
        | 'pb'
        | 'pl'
        | 'pr'
        | 'px'
        | 'py'
        | 'sx'
        | 'onClick'
        | 'onMouseOver'
        | 'onMouseEnter'
        | 'onMouseLeave'
        | 'role'
        | 'className'
      >
    > {
  column?: boolean;
  wrap?: boolean;
  spaceBetween?: boolean;
  gap?: number | string;
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  debugBorder?: boolean;
}

const Container = forwardRef<HTMLElement, ContainerProps>(
  (
    {
      children,
      column = false,
      wrap = false,
      spaceBetween = false,
      gap = 1,
      top = false,
      bottom = false,
      left = false,
      right = false,
      fullWidth = false,
      fullHeight = false,
      debugBorder = false,
      sx,
      ...boxProps
    },
    ref,
  ) => {
    let justifyContent: 'flex-start' | 'center' | 'flex-end' | 'space-between' = 'center';
    let alignItems: 'flex-start' | 'center' | 'flex-end' = 'center';

    if (left && right) {
      throw new Error('An element cannot be on the left and right side at the same time...');
    }
    if (top && bottom) {
      throw new Error('An element cannot be at the top and bottom at the same time...');
    }
    if (spaceBetween && ((!column && (left || right)) || (column && (top || bottom)))) {
      throw new Error(`Space between will override ${column ? 'top/bottom' : 'left/right'}`);
    }

    if (column) {
      // Adjust vertically
      if (top) {
        justifyContent = 'flex-start';
      } else if (bottom) {
        justifyContent = 'flex-end';
      }
      // Adjust horizontally
      if (left) {
        alignItems = 'flex-start';
      } else if (right) {
        alignItems = 'flex-end';
      }
    } else {
      // Adjust vertically
      if (top) {
        alignItems = 'flex-start';
      } else if (bottom) {
        alignItems = 'flex-end';
      }
      // Adjust horizontally
      if (left) {
        justifyContent = 'flex-start';
      } else if (right) {
        justifyContent = 'flex-end';
      }
    }

    if (spaceBetween) {
      justifyContent = 'space-between';
    }

    useEffect(() => {
      if (debugBorder) {
        // eslint-disable-next-line no-console
        console.warn('Using debugBorder in Container. Do not push this to production!');
      }
    }, [debugBorder]);

    return (
      <Box
        ref={ref}
        sx={mergeSx(sx || {}, {
          width: fullWidth ? '100%' : undefined,
          height: fullHeight ? '100%' : undefined,
          display: 'flex',
          flexFlow: `${column ? 'column' : 'row'} ${wrap ? 'wrap' : 'nowrap'}`,
          gap,
          justifyContent,
          alignItems,
          border: debugBorder ? '1px solid black' : undefined,
        })}
        {...boxProps}>
        {children}
      </Box>
    );
  },
);

Container.displayName = 'Container';

export default Container;
