import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import { useControlledFieldArray } from '../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { HeaderMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/HeaderMenuBlock';
import { useStore } from '../../../components/store/Store';
import SwitchInput from '../../../components/forms/SwitchInput';
import Container from '../../../components/Container';
import DraggableCollapseContainer from '../../../components/DraggableCollapseContainer';
import { Draggable } from '../../../declarations/Draggable';
import TextInput from '../../../components/forms/TextInput';
import DeleteButton from '../../../components/DeleteButton';

export interface LanguageMainMenuLanguagesMenuSettingsProps {
  blockPath: BlockPath;
}

export const LanguageMainMenuLanguagesMenuSettings: FC<LanguageMainMenuLanguagesMenuSettingsProps> = ({
  blockPath,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { state } = useStore();

  const includeLanguageMenu = useWatch<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.includeLanguageMenu`>({
    name: `${blockPath}.includeLanguageMenu`,
  });

  const {
    fields: languageitems,
    append,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.languageitems`>({
    name: `${blockPath}.languageitems`,
  });

  const availableItems = state.selectedSite?.site_contents?.filter(
    (item) => !languageitems.find((l) => l.id === item.id),
  );

  return (
    <>
      <Container mb={2}>
        <SwitchInput
          path={`${blockPath}.includeLanguageMenu`}
          label={tComponents('Settings.LanguageSettings.LanguageMenuSettings.includeLanguageMenu')}
          defaultValue={false}
        />
      </Container>
      {includeLanguageMenu && (
        <Container top fullWidth spaceBetween>
          <Container column fullWidth left>
            <Typography>{tComponents('Settings.LanguageSettings.LanguageMenuSettings.availableLanguages')}:</Typography>
            {availableItems?.map((availableItem) => (
              <Button
                key={availableItem.id}
                variant='outlined'
                startIcon={<MaterialSymbol name='add_circle' />}
                onClick={() => append(availableItem)}
                size='large'
                sx={{ minWidth: '200px', justifyContent: 'flex-start' }}>
                {tCommon(`Languages.${availableItem.locale}`)}
              </Button>
            ))}
            {!availableItems?.length && <Typography variant='caption'>{tCommon('none')}</Typography>}
          </Container>
          <Container column fullWidth left>
            <Typography>{tComponents('Settings.LanguageSettings.LanguageMenuSettings.languagesInMenu')}:</Typography>
            {languageitems?.map((menuItem, index) => (
              <DraggableCollapseContainer
                key={menuItem.id}
                onReorder={move}
                onAdd={() => append}
                onRemove={remove}
                index={index}
                type={Draggable.ITEM}
                secondaryAction={
                  <DeleteButton
                    onConfirm={() => remove(index)}
                    deleteLabel='remove'
                    deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
                  />
                }
                headerContent={
                  // eslint-disable-next-line react/no-unstable-nested-components
                  () => (
                    <Typography sx={{ pl: 2 }}>
                      {menuItem.name
                        ? `${menuItem.name} (${tCommon(`Languages.${menuItem.locale}`)})`
                        : tCommon(`Languages.${menuItem.locale}`)}
                    </Typography>
                  )
                }>
                <Container p={2} left fullWidth>
                  <TextInput
                    path={`${blockPath}.languageitems.${index}.name`}
                    label={tComponents('Settings.LanguageSettings.LanguageMenuSettings.languageTitleInMenu')}
                    defaultValue=''
                  />
                </Container>
              </DraggableCollapseContainer>
            ))}
            {!languageitems?.length && <Typography variant='caption'>{tCommon('none')}</Typography>}
          </Container>
        </Container>
      )}
    </>
  );
};

export default LanguageMainMenuLanguagesMenuSettings;
