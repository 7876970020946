import React, { FC } from 'react';
import { Box, Divider } from '@mui/material';
import EmployeesGridViewItem from './EmployeesGridViewItem';
import { CommonEmployeesViewProps } from '../EmployeesViewList';

export const EmployeesGridView: FC<CommonEmployeesViewProps> = ({ items, onItemClick, onDeleteItem }) => {
  return (
    <>
      <Divider flexItem variant='middle' />
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 280px)',
          gridGap: '1.25rem',
        }}>
        {items.map((item) => {
          return (
            <EmployeesGridViewItem key={item.id} item={item} onItemClick={onItemClick} onDeleteItem={onDeleteItem} />
          );
        })}
      </Box>
    </>
  );
};

export default EmployeesGridView;
