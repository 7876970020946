import React, { FC } from 'react';
import { Checkbox, CheckboxProps, Tooltip, TooltipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SelectButtonProps extends Pick<CheckboxProps, 'edge' | 'color'> {
  selected: boolean;
  onChange: (selected: boolean) => void;
  tooltipPlacement?: TooltipProps['placement'];
  onBackground?: boolean;
}

export const SelectButton: FC<SelectButtonProps> = ({
  selected,
  onChange,
  color = 'secondary',
  tooltipPlacement,
  edge,
  onBackground,
}) => {
  const { t } = useTranslation('components');

  const label = t(selected ? 'SelectButton.Deselect' : 'SelectButton.Select');

  return (
    <Tooltip title={label} placement={tooltipPlacement} arrow disableInteractive>
      <Checkbox
        color={color}
        inputProps={{ 'aria-label': label }}
        checked={selected}
        onChange={() => onChange(!selected)}
        edge={edge}
        onClick={(e) => e.stopPropagation()}
        sx={
          onBackground
            ? {
                margin: '5px',
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                },
              }
            : undefined
        }
      />
    </Tooltip>
  );
};

export default SelectButton;
