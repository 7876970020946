import React, { FC, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '../assets/js/Styles';
import Container from './Container';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface CollapseContainerProps extends ChildrenProp {
  title: string;
  subTitle?: string;
  color?: 'default' | 'primary' | 'secondary';
  outlined?: boolean;
  borderColor?: string;
}

/**
 *
 * @param children
 * @param title
 * @param subTitle
 * @param color
 * @param outlined
 * @param borderColor
 */
export const CollapseContainer: FC<CollapseContainerProps> = ({
  children,
  title,
  subTitle,
  color = 'primary',
  outlined = false,
  borderColor,
}) => {
  const theme = useTheme();
  const container = useRef<HTMLDivElement | null>(null);
  const backgroundColor =
    color && color !== 'default' ? theme.palette[color].main : Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR;
  const textColor = theme.palette.getContrastText(backgroundColor);

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <Accordion
        variant={outlined ? 'outlined' : undefined}
        sx={{
          width: '100%',
          backgroundColor,
          color: textColor,
          borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
          '&::before, &::after': {
            display: 'none',
          },
          borderColor,
        }}
        expanded={expanded}
        elevation={0}
        disableGutters
        onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          sx={{
            flexDirection: 'row-reverse',
            minHeight: theme.spacing(6),
            pr: 2,
            '&:hover': {
              backgroundColor: !expanded ? Styles.Colors.THEME_PRIMARY_BG_HOVER : undefined,
            },
          }}
          expandIcon={
            <MaterialSymbol
              name='chevron_right'
              color={color === 'secondary' ? 'primary' : undefined}
              sx={expanded ? { transform: 'rotate(-90deg)' } : undefined}
            />
          }>
          <Container spaceBetween fullWidth>
            <Container left fullWidth>
              <Typography sx={{ pl: 1 }} variant='button' fontWeight='bold' noWrap>
                {title}
              </Typography>
              {subTitle && (
                <Typography sx={{ pl: 1 }} color='textSecondary' variant='caption' noWrap>
                  {subTitle}
                </Typography>
              )}
            </Container>
          </Container>
        </AccordionSummary>
        <AccordionDetails ref={container} sx={{ width: '100%' }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CollapseContainer;
