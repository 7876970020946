import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { SaveType } from '../editor/lib/declarations/SaveType';
import { useFormSubmitHandler } from '../views/BaseSettingsViewForm';

export const SaveFormButton: FC = () => {
  const { t: tCommon } = useTranslation('common');
  const {
    formState: { isValid, isSubmitting, isValidating, isDirty },
  } = useFormContext();

  const submitForm = useFormSubmitHandler();

  return (
    <Button
      variant='contained'
      color='secondary'
      disabled={!isDirty || !isValid || isSubmitting || isValidating}
      onClick={submitForm}>
      {tCommon(`SaveType.${SaveType.SAVE}`)}
    </Button>
  );
};

export default SaveFormButton;
