import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { useBlockClipboardContext } from '@/editor/lib/BlockClipboardContext';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import AddBlockButton from './AddBlockButton';
import Section from '../../components/Section/Section';
import Styles from '../../assets/js/Styles';
import { Page } from '../../declarations/models/Page';
import { Draggable } from '../../declarations/Draggable';
import BlockSettings from './BlockSettings';
import { BaseBlock } from '../../declarations/models/blocks/BaseBlock';
import { deepCopy } from '../../utils/object';
import { useControlledFieldArray } from '../../hooks/useControlledFieldArray';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';
import EditorBlock from './EditorBlock/EditorBlock';
import { useCurrentSection } from './CurrentSectionProvider';
import CommonSectionContent from './CommonSectionContent';
import { BlocksPath, CurrentBlockProvider } from './CurrentBlockProvider';
import SectionsController from '../../components/Section/SectionsController';
import { BlockType } from '../../declarations/models/BlockType';
import {
  EditorEvent,
  EditorEventType,
  EventManagerApi,
  useEditorEventHandler,
} from '../lib/eventManager/EditorEventManager';

export interface PageEditorSectionContentParams {
  useEventManager?: EventManagerApi;
}

export const PageEditorSectionContent: FC<PageEditorSectionContentParams> = ({ useEventManager }) => {
  const { t } = useTranslation('common');
  const editorConfiguration = useEditorConfiguration();
  const { sectionPath, sectionIndex } = useCurrentSection();

  const {
    fields: blocks,
    append,
    move,
    remove,
    insert,
  } = useControlledFieldArray<Page, BlocksPath>({ name: `${sectionPath}.blocks` });

  const { copy, paste, cut, currentCutBlockId } = useBlockClipboardContext();

  const appendAndOpen = (item: BaseBlock) => {
    append(item);
    // Wrap in timeout to allow the new component to render before firing the event. It's the new component that's listening for the event.
    if (useEventManager) {
      setTimeout(() => {
        useEventManager.fireEvent(EditorEventType.BLOCK_OR_SECTION_ADDED, {
          anchorId: `${sectionPath}.blocks.${blocks.length}`,
        });
      });
    }
  };
  const handlePasteBlock = () => {
    const val = paste();
    if (val != null) {
      useEventManager?.fireEvent(EditorEventType.BLOCK_PASTED, {
        cutBlockId: currentCutBlockId ?? undefined,
        anchorId: `${sectionPath}.blocks.${blocks.length}`,
      });
      append({
        ...val,
        _recentlyPasted: true,
      });
    }
  };

  const defaultExpanded = useMemo<Array<string>>(() => {
    return blocks.filter((s) => !s._collapse).map((s) => s.id);
  }, [blocks]);

  const handleDuplicateBlock = (block: BaseBlock & { id?: string }, index: number) => () => {
    const blockCopy = deepCopy(block);
    if (blockCopy) {
      delete blockCopy.id;
      insert(index + 1, { ...blockCopy, _recentlyPasted: true });
    }
  };

  const handleCopyBlock = (block: BaseBlock & { id?: string }) => () => {
    const { id } = block;
    const blockCopy = deepCopy(block);
    if (id && blockCopy) {
      delete blockCopy.id;
      copy(id, blockCopy);
    }
  };

  const handleCutBlock = (block: BaseBlock & { id?: string }) => () => {
    const { id } = block;
    const blockCopy = deepCopy(block);
    if (id && blockCopy) {
      delete blockCopy.id;
      cut(id, blockCopy, () => {
        remove(blocks.findIndex((b) => b.id === id));
      });
    }
  };

  return (
    <SectionsController defaultExpanded={defaultExpanded} nestedController eventManager={useEventManager}>
      <CommonSectionContent />
      {!blocks.length && (
        <Alert variant='outlined' severity='warning' sx={{ width: '100%', justifyContent: 'center' }}>
          {t('noContent')}
        </Alert>
      )}
      {blocks.map((block, i) => (
        <CurrentBlockProvider key={block.id} block={block} blockIndex={i} sectionIndex={sectionIndex}>
          <Section
            isHidden={block.visible === 'hidden'}
            type={Draggable.BLOCK}
            color={Styles.Colors.BLOCK}
            sectionId={`${`${sectionPath}.blocks` as BlocksPath}.${i}`}
            title={t(`BlockType.${block.type}`)}
            subTitle={block.title || ''}
            headerActions={
              <>
                <BlockSettings
                  onDuplicateBlock={handleDuplicateBlock(block, i)}
                  onRemoveBlock={() => remove(i)}
                  onCopyBlock={handleCopyBlock(block)}
                  onCutBlock={handleCutBlock(block)}
                />
                {!editorConfiguration.isBlockAvailable(block.type) && <MaterialSymbol name='error' color='error' />}
              </>
            }
            index={i}
            value={block}
            onAdd={(item, index) => item && insert(index, item)}
            onRemove={remove}
            onReorder={move}
            draggable={block.type !== BlockType.ARTICLE_TITLE}
            isCut={currentCutBlockId === block.id}
            isPasted={block._recentlyPasted}
            useEventManager={useEventManager}>
            <EditorBlock />
          </Section>
        </CurrentBlockProvider>
      ))}
      <AddBlockButton onAddBlock={appendAndOpen} onPaste={handlePasteBlock} />
    </SectionsController>
  );
};

export default PageEditorSectionContent;
