import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../components/store/Store';
import { Site } from '../../../declarations/models/Site';
import { Api } from '../../../services/Api';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import LanguageCreationViewForm from './LanguageCreationViewForm';

export const LanguageCreationView: FC = () => {
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state, changeSiteContext, changeSiteLanguage } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;
  const navigate = useNavigate();

  const getEmptyLanguageCreationModel = useCallback(() => {
    return {
      site_id: selectedSiteId,
      status: 'published',
      content: {
        sections: [
          {
            type: 'site__head',
            blocks: [
              {
                type: 'menu',
                required: true,
                multiple: false,
                items: [],
                searchresultPage: {},
                parentOverlayPage: {},
                focusitems: [],
              },
            ],
            _collapse: true,
          },
          {
            type: 'site__footer',
            blocks: [
              {
                type: 'footer__address',
                required: true,
                multiple: false,
                addresses: [],
              },
              {
                type: 'footer__social',
                required: false,
                multiple: false,
              },
              {
                type: 'footer__info',
                required: false,
                multiple: false,
                mediaobjects: [],
              },
              {
                type: 'footer__menu',
                required: false,
                multiple: false,
              },
            ],
            _collapse: true,
          },
        ],
        settings: {},
      },
      locale: '',
    } as unknown as Site;
  }, [selectedSiteId]);

  const handleSaveSettingsForm = useCallback(
    async (site: Site): Promise<void | Site> => {
      const savedFormData = await Api.saveSiteContent<Site>(site, selectedSiteId, site.locale || '').fetchDirect(null);
      if (!savedFormData) {
        enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
      } else {
        enqueueSnackbar(tCommon('saved'), { variant: 'success' });
        const updatedSite = await Api.getSite(selectedSiteId).fetchDirect(null);
        changeSiteContext(updatedSite, true);
        changeSiteLanguage(site.locale || null);
        return navigate(`/dashboard`);
      }
    },
    [changeSiteContext, changeSiteLanguage, enqueueSnackbar, navigate, selectedSiteId, tCommon],
  );

  return (
    <BaseSettingsViewForm<Site>
      title=''
      onSubmit={handleSaveSettingsForm}
      initialValue={getEmptyLanguageCreationModel()}>
      <LanguageCreationViewForm />
    </BaseSettingsViewForm>
  );
};

export default LanguageCreationView;
