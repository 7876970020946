import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMapMarker } from '../declarations/GoogleMapMarker';

export interface MapProps {
  center?: { lat: number; lng: number };
  selectedLocation?: GoogleMapMarker;
  zoom: number;
  markers?: GoogleMapMarker[];
  selectable?: boolean;
  onSelect?: (marker: GoogleMapMarker) => void;
  id?: string;
}

declare global {
  interface Window {
    google: any;
    initializeMap: () => Promise<void>;
  }
}

const oslo = { lat: 59.9, lng: 10.75 };

const GoogleMap: React.FC<MapProps> = ({
  id,
  center = oslo,
  selectedLocation,
  zoom,
  markers,
  selectable,
  onSelect,
}) => {
  const mapDivRef = useRef<HTMLDivElement | null>(null);
  const mapInstanceRef = useRef<any | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const markersRef = useRef<google.maps.Marker[]>([]);
  const [selected, setSelected] = useState<GoogleMapMarker>();

  const updateMarkersOnMap = useCallback((m: GoogleMapMarker[]) => {
    if (typeof window.google === 'undefined' || mapInstanceRef.current === null) {
      return;
    }
    // Remove existing markers
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    m?.forEach((marker) => {
      const newMarker = new window.google.maps.Marker({
        map: mapInstanceRef.current,
        position: marker.position,
        title: marker.title,
        label: marker.title,
      });
      markersRef.current.push(newMarker);
    });
  }, []);

  const initializeMap = useCallback(async () => {
    if (mapDivRef.current === null) {
      return;
    }

    const isCenteredAt = (position: { lat: number; lng: number }) => {
      const mapLat = mapInstanceRef.current?.getCenter().lat();
      const mapLng = mapInstanceRef.current?.getCenter().lng();
      return position.lat === mapLat && position.lng === mapLng;
    };

    if (mapInstanceRef.current === null || (center !== oslo && isCenteredAt(oslo))) {
      if (typeof window.google === 'undefined') {
        return;
      }
      mapInstanceRef.current = new window.google.maps.Map(mapDivRef.current, {
        center,
        zoom,
        gestureHandling: 'cooperative',
      });
    }

    if (markers) {
      updateMarkersOnMap(markers);
    }

    if (selectable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const clickListener = mapInstanceRef.current.addListener('click', (event: google.maps.MouseEvent) => {
        const newLocation = {
          position: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
        };
        setSelected(newLocation);
        if (onSelect) {
          onSelect(newLocation);
        }
      });

      return () => {
        if (typeof window.google !== 'undefined') {
          window.google.maps.event.removeListener(clickListener);
        }
      };
    }
  }, [center, zoom, markers, onSelect, selectable, updateMarkersOnMap]);

  useEffect(() => {
    if (typeof window.google === 'undefined') {
      const googleMapsScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');

      // Load the Google Maps API script if not already loaded
      if (!googleMapsScript) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.initializeMap = initializeMap;

        const newScript = document.createElement('script');
        newScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA-Sp10nhd6EOn3V_fImUusuSwualiJnbA&callback=initializeMap&libraries=places`;
        newScript.async = true;
        newScript.defer = true;
        document.body.appendChild(newScript);
      }
    } else {
      initializeMap().then(() => null);
    }
  }, [initializeMap]);

  useEffect(() => {
    if (selectable && selected) {
      updateMarkersOnMap([selected]);
    }
  }, [selectable, selected, updateMarkersOnMap]);

  useEffect(() => {
    if (selectable && selectedLocation) {
      setSelected(selectedLocation);
    }
  }, [selectedLocation, selectable]);

  useEffect(() => {
    if (center && mapInstanceRef.current !== null) {
      mapInstanceRef.current.setCenter(center);
    }
  }, [center]);

  return <div ref={mapDivRef} id={id} style={{ height: '100%', width: '100%' }} />;
};

export default GoogleMap;
