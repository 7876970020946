import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';
import { TableBlock } from '../../../declarations/models/blocks/TableBlock';
import { BlockModuleState } from '../../../declarations/models/BlockModuleState';

export const TableBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as TableBlock;
  const [open, setOpen] = useState<boolean>(true);

  const createTable = () => {
    const tableItemsArray = currentBlock.items.map((row) => {
      return Object.entries(row)
        .filter(([key]) => !Number.isNaN(Number(key)))
        .sort(([keyA], [keyB]) => {
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
          return 0;
        })
        .map(([, val]) => val);
    });
    const maxCols = tableItemsArray.reduce((max, row) => Math.max(max, row.length), 0);

    const max = currentBlock?.columns;

    type Col = {
      name: string;
    };
    const dummyCol: Col = { name: 'Dummy' };
    const cols: Col[] = [];
    for (let i = 0; i < max; i++) {
      cols.push(dummyCol);
    }

    return (
      // TODO: bruk plain HTML med stilsett
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableBody>
            {tableItemsArray.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`row-${i}`}>
                {cols.map((col, colIndex) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`col-${i}-${colIndex}`}
                    style={
                      (currentBlock.firstasheader && i === 0) || (currentBlock.firstColAsHeader && colIndex === 0)
                        ? { fontWeight: 'bold' }
                        : undefined
                    }>
                    {row[colIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <div className='article__grid'>
      {currentBlock?.module__type !== BlockModuleType.MODULE ? (
        <div className='article__content'>
          {currentBlock?.visibletitle && (
            <header className='article__bodytext'>
              <h2>{currentBlock?.title}</h2>
            </header>
          )}
          <section className='article__bodytext'>
            <div
              className='module__body'
              dangerouslySetInnerHTML={{ __html: currentBlock?.body || '' }} // eslint-disable-line react/no-danger
            />
            {createTable()}
          </section>
        </div>
      ) : (
        <figure className='module module--card module--content'>
          <div className={`module__grid skin skin-contentbox ${currentBlock?.skin || ''}`}>
            {currentBlock?.state === 'semi' ? (
              <div className='preview__blockmessage'>Tekstboks vil være delvis åpen i portal</div>
            ) : null}
            {currentBlock?.state === 'collapsed' ? (
              <div className='preview__blockmessage'>Tekstboks vil være lukket i portal</div>
            ) : null}
            <div className='module__content'>
              <div className='module__head'>
                {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
                {currentBlock?.state === BlockModuleState.COLLAPSED && (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type
                  <button
                    className='module__expand i-nav-more ng-scope rotate(90)'
                    style={{ transform: `rotate(${open ? '90' : '180'}deg)` }}
                    onClick={() => setOpen(!open)}
                  />
                )}
              </div>
              <div className='module__body' hidden={!open}>
                {/* eslint-disable-next-line react/no-danger */}
                <p className='modulebodytext' dangerouslySetInnerHTML={{ __html: currentBlock?.body || '' }} />
                {createTable()}
              </div>
            </div>
          </div>
        </figure>
      )}
    </div>
  );
};

export default TableBlockPreview;
