/* istanbul ignore file */

export enum MediaLicense {
  NONE = '',
  COPYRIGHT = 'AC 1',
  CC_BY = 'CC by',
  CC_BY_SA = 'CC by-sa',
  CC_BY_ND = 'CC by-nd',
  CC_BY_NC = 'CC by-nc',
  CC_BY_NC_SA = 'CC by-nc-sa',
  CC_BY_NC_ND = 'CC by-nc-nd',
  CC_PDM = 'CC pdm',
}
