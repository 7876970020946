import React, { FC } from 'react';
import { IconButton, ListItemAvatar, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { EmployeesViewItemProps } from './EmployeesGridViewItem';
import Container from '../../../components/Container';
import { buttonHoverStyle, noWrapTextStyle } from '../../MediaView/MediaViewTypes/ListViewItem';
import Styles from '../../../assets/js/Styles';
import Image from '../../../components/Image';

export const EmployeesListViewItem: FC<EmployeesViewItemProps> = ({ item, onItemClick, onDeleteItem }) => {
  return (
    <Container fullWidth spaceBetween my='5px' gap={0}>
      <Container
        left
        gap={0}
        sx={(theme) => ({
          width: 'calc(100% - 40px)',
          maxWidth: 'calc(100% - 40px)',
          '&:hover': buttonHoverStyle(theme),
        })}>
        <Container fullWidth fullHeight onClick={() => onItemClick(item)} sx={{ maxWidth: 'calc(100% - 380px)' }}>
          <ListItemAvatar sx={{ height: '64px' }}>
            {item.resource?.url ? (
              <Image
                src={item.resource.url}
                alt={item.resource.title}
                loading='lazy'
                focusPoint={item.image_focus_point}
                style={{
                  width: '64px',
                  height: '64px',
                  objectFit: 'cover',
                  overflow: 'hidden',
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
              />
            ) : (
              <MaterialSymbol
                name='person'
                sx={{
                  fontSize: '64px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
              />
            )}
          </ListItemAvatar>
          <Container fullWidth column left sx={{ maxWidth: 'calc(100% - 72px)' }} gap={0}>
            <Typography fontWeight={700} sx={noWrapTextStyle}>
              {`${item.first_name} ${item.last_name}`}
            </Typography>
            {item.position && (
              <Typography variant='caption' sx={noWrapTextStyle}>
                {item.position}
              </Typography>
            )}
          </Container>
        </Container>
        <Container
          column
          gap={0}
          left
          sx={{
            width: '380px',
            maxWidth: '380px',
            paddingLeft: '8px',
            '&:hover': { cursor: 'text' },
          }}>
          {item.phone_primary && (
            <Container left sx={{ maxWidth: '100%' }}>
              <MaterialSymbol name='call' fill sx={{ fontSize: 'small' }} />
              <Typography variant='caption' sx={noWrapTextStyle}>
                {item.phone_primary}
              </Typography>
            </Container>
          )}
          {item.email_primary && (
            <Container left sx={{ maxWidth: '100%' }}>
              <MaterialSymbol name='mail' sx={{ fontSize: 'small' }} />
              <Typography variant='caption' sx={noWrapTextStyle}>
                {item.email_primary}
              </Typography>
            </Container>
          )}
        </Container>
      </Container>
      <IconButton onClick={() => onDeleteItem && onDeleteItem(item)}>
        <MaterialSymbol name='delete' fill />
      </IconButton>
    </Container>
  );
};

export default EmployeesListViewItem;
