import React, { FC, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseFieldArrayReplace } from 'react-hook-form';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import Container from '../../../../components/Container';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { TableBlock, TableBlockItem } from '../../../../declarations/models/blocks/TableBlock';

export interface TableBlockTextInputProps {
  onParseText: UseFieldArrayReplace<BlockSpecificPage<TableBlock>, 'content.sections.0.blocks.0.items'>;
  setColumns: (...event: any[]) => void;
  isEmpty?: boolean;
}

export const TableBlockTextInput: FC<TableBlockTextInputProps> = ({ onParseText, setColumns, isEmpty }) => {
  const { t } = useTranslation('components');
  const confirm = useConfirmDialog();
  const [textInput, setTextInput] = useState<string>();

  async function makeTable(delim: string) {
    if (isEmpty || (await confirm(t('TableBlock.ConfirmParseTable')))) {
      let maxCols = 1;
      const arr = textInput
        ? textInput.split('\n').map((rowStr) => {
            const row = rowStr.split(delim);
            if (row.length > maxCols) maxCols = row.length;
            return row.reduce(
              (result: TableBlockItem, item: string, index: number) => {
                return { ...result, [index]: item };
              },
              { type: 'row' },
            );
          })
        : [];
      onParseText(arr);
      setColumns(maxCols);
    }
  }

  return (
    <Container column>
      <TextField
        fullWidth
        multiline
        minRows={4}
        maxRows={24}
        placeholder={t('TableBlock.InputFieldPlaceholder')}
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
      />
      <Container fullWidth left wrap>
        <Button variant='outlined' size='small' disabled={!textInput} onClick={() => makeTable('\t')}>
          {t('TableBlock.ParseWithTab')}
        </Button>
        <Button variant='outlined' size='small' disabled={!textInput} onClick={() => makeTable(',')}>
          {t('TableBlock.ParseWithComma')}
        </Button>
      </Container>
    </Container>
  );
};

export default TableBlockTextInput;
