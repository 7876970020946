import React, { FC } from 'react';
import { PageFolder } from '@/declarations/models/Folder';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { formatAPITimestamp } from '@/utils/dates';
import ActionMenu from '@/components/ActionMenu';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { useTranslation } from 'react-i18next';
import { set } from 'react-hook-form';

interface FolderListItemProps {
  folder: PageFolder;
  onClick: (folder: PageFolder) => void;
  onDelete?: (folderId: number) => void;
  onInitMove?: (folders: PageFolder[]) => void;
  onEdit?: (folder: PageFolder) => void;
}
const FolderListItem = ({ folder, onClick, onDelete, onInitMove, onEdit }: FolderListItemProps) => {
  const confirmFn = useConfirmDialog();
  const { t: tComponents } = useTranslation('components');
  const [editMode, setEditMode] = React.useState(false);
  const [title, setTitle] = React.useState(folder.title);
  const FolderWrapper: React.ElementType = editMode ? Box : ButtonBase;

  function menuItems() {
    const items = [];

    if (onEdit) {
      items.push(
        <MenuItem
          key={`edit-${folder.id}`}
          onClick={() => {
            setEditMode(true);
          }}>
          <ListItemIcon>
            <MaterialSymbol name='edit' />
          </ListItemIcon>
          <ListItemText>{tComponents('FolderView.editName')}</ListItemText>
        </MenuItem>,
      );
    }

    if (onInitMove) {
      items.push(
        <MenuItem key={`move-${folder.id}`} onClick={() => onInitMove([folder])}>
          <ListItemIcon>
            <MaterialSymbol name='arrow_forward' />
          </ListItemIcon>
          <ListItemText>{tComponents('FolderView.MoveActionButton')}</ListItemText>
        </MenuItem>,
      );
    }

    if (onDelete) {
      items.push(
        <MenuItem
          key={`delete-${folder.id}`}
          onClick={() => {
            confirmFn(
              <Box
                sx={{
                  p: 2,
                }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: tComponents('FolderView.ConfirmDeleteFolderDialogHtml', { folderName: folder.title }),
                }}
              />,
              {},
            ).then((confirmed) => {
              if (confirmed) {
                onDelete(folder.id);
              }
            });
          }}>
          <ListItemIcon>
            <MaterialSymbol color='error' name='delete' />
          </ListItemIcon>
          <ListItemText>{tComponents('FolderView.DeleteActionButton')}</ListItemText>
        </MenuItem>,
      );
    }

    return items;
  }

  const menuItemsToRender = menuItems();

  return (
    <Container
      fullWidth
      left
      sx={{
        backgroundColor: Styles.Colors.LIGHTEST_GREY,
      }}>
      <FolderWrapper
        onClick={editMode ? undefined : () => onClick(folder)}
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          p: 1,
        }}>
        <Container gap={2}>
          <MaterialSymbol
            name='folder_open'
            sx={{
              fontSize: '2.5rem',
            }}
          />
          {!editMode && (
            <Typography
              variant='body2'
              sx={{
                fontWeight: 'bold',
                fontSize: '1.125rem',
              }}>
              {folder.title}
            </Typography>
          )}
          {editMode && (
            <TextField
              onClick={(e) => e.stopPropagation()}
              size='small'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setEditMode(false);
                  if (title !== folder.title) {
                    onEdit?.({ ...folder, title });
                  }
                } else if (e.key === 'Escape') {
                  setEditMode(false);
                  setTitle(folder.title);
                }
              }}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(false);
                        if (title !== folder.title) {
                          onEdit?.({ ...folder, title });
                        }
                      }}>
                      <MaterialSymbol name='done' />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(false);
                        setTitle(folder.title);
                      }}>
                      <MaterialSymbol name='close' />
                    </IconButton>
                  </>
                ),
              }}
            />
          )}
        </Container>
        <Container
          right
          sx={{
            flexGrow: 1,
          }}
        />
      </FolderWrapper>

      <Container>
        <Typography color='text.secondary' variant='caption' mx={2}>
          {tComponents('FolderView.createdAtDate', { date: formatAPITimestamp(folder.created_at, 'date') })}
        </Typography>
        {menuItemsToRender.length > 0 && (
          <ActionMenu id='sort-selector' ariaLabel='sort-selector' icon={<MaterialSymbol name='more_vert' />}>
            {menuItems()}
          </ActionMenu>
        )}
      </Container>
    </Container>
  );
};

interface FolderViewProps {
  sx?: SxProps;
  siteFolders: PageFolder[];
  activePageFolder: PageFolder | null;
  onFolderClick: (folder: PageFolder | null) => void;
  onFolderDelete?: (folderId: number) => void;
  onFolderMove?: (folders: PageFolder[]) => void;
  onFolderEdit?: (folder: PageFolder) => void;
  breadcrumbs: PageFolder[];
  onCreateNewClick?: (title: string) => void;
}

export const FolderView: FC<FolderViewProps> = ({
  sx,
  siteFolders,
  breadcrumbs,
  activePageFolder,
  onFolderClick,
  onFolderDelete,
  onFolderMove,
  onFolderEdit,
  onCreateNewClick,
}) => {
  const { t: tComponents } = useTranslation('components');
  const [addFolderDialogOpen, setAddFolderDialogOpen] = React.useState(false);
  const [newFolderTitle, setNewFolderTitle] = React.useState('');

  return (
    <Container fullWidth column left sx={sx}>
      <Container fullWidth left gap={4}>
        {activePageFolder && Boolean(breadcrumbs?.length) && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: 0,
            }}>
            <Button
              size='small'
              onClick={() => {
                onFolderClick(null);
              }}>
              <span
                style={{
                  textDecoration: 'underline',
                }}>
                {tComponents('FolderView.rootFolderName')}
              </span>
            </Button>
            {breadcrumbs.map((folder, index) => (
              <div
                key={`crumb-${folder.id}`}
                style={{
                  display: 'contents',
                }}>
                <MaterialSymbol name='chevron_right' />
                {index === breadcrumbs.length - 1 ? (
                  <Chip
                    icon={<MaterialSymbol name='folder_open' />}
                    size='medium'
                    sx={{
                      fontWeight: 'bold',
                      px: 1,
                    }}
                    label={folder.title}
                  />
                ) : (
                  <Chip
                    icon={<MaterialSymbol name='folder' />}
                    size='medium'
                    sx={{
                      px: 1,
                    }}
                    onClick={() => onFolderClick(folder)}
                    label={folder.title}
                  />
                )}
              </div>
            ))}
          </Box>
        )}

        {onCreateNewClick && (
          <>
            {/*
          <Button
            size='small'
            variant='contained'
            startIcon={<MaterialSymbol name='add' />}
            onClick={() => onCreateNewClick(tComponents('FolderView.newFolderDefaultName'))}>
            {tComponents('FolderView.createNewFolder')}
          </Button>
          */}

            <Button
              size='small'
              variant='contained'
              startIcon={<MaterialSymbol name='add' />}
              onClick={() => setAddFolderDialogOpen(true)}>
              {tComponents('FolderView.createNewFolder')}
            </Button>

            <Dialog
              open={addFolderDialogOpen}
              onClose={() => {
                setAddFolderDialogOpen(false);
                setNewFolderTitle('');
              }}>
              <DialogTitle>
                <Typography variant='h4' component='h2' noWrap>
                  {tComponents('FolderView.createNewFolder')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box mt={1} mb={1}>
                  <TextField
                    sx={{ width: '300px' }}
                    autoFocus
                    value={newFolderTitle}
                    onChange={(e) => setNewFolderTitle(e.target.value)}
                    label={tComponents('FolderView.newFolderNameLabel')}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  size='small'
                  variant='contained'
                  color='success'
                  disabled={!newFolderTitle}
                  onClick={() => {
                    onCreateNewClick(newFolderTitle || tComponents('FolderView.newFolderDefaultName'));
                    setNewFolderTitle('');
                    setAddFolderDialogOpen(false);
                  }}>
                  {tComponents('FolderView.createAndClose')}
                </Button>
                {/*
                <Button
                  size='small'
                  variant='contained'
                  color='secondary'
                  disabled={!newFolderTitle}
                  startIcon={<MaterialSymbol name='add' />}
                  onClick={() => {
                    onCreateNewClick(newFolderTitle || tComponents('FolderView.newFolderDefaultName'));
                    setNewFolderTitle('');
                  }}>
                  {tComponents('FolderView.createAndNew')}
                </Button>
                   */}
                <Button
                  color='secondary'
                  size='small'
                  onClick={() => setAddFolderDialogOpen(false)}
                  endIcon={<MaterialSymbol name='close' />}>
                  {tComponents('FolderView.closeDialog')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {!onCreateNewClick && (
          <Container
            sx={{
              visibility: 'hidden',
            }}>
            <Button aria-hidden size='small' variant='contained'>
              &nbsp;
            </Button>
          </Container>
        )}
      </Container>
      <Container fullWidth left column>
        {(activePageFolder?.children || siteFolders).map((folder) => (
          <FolderListItem
            key={`folder-${folder.id}`}
            folder={folder}
            onClick={() => onFolderClick(folder)}
            onDelete={onFolderDelete}
            onInitMove={onFolderMove}
            onEdit={onFolderEdit}
          />
        ))}
      </Container>
    </Container>
  );
};
