import React from 'react';
import { FormModule } from '@/declarations/models/FormModule';
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import M24FormPreview from '@/components/M24FormPreview';
import { useEditorModel } from '@/editor/lib/components/EditorDataProvider';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';
import { useM24FormEditorAccordionContext } from '@/editor/M24FormEditor/M24FormEditor';
import { MaterialSymbol } from '@/components/MaterialSymbol';

const M24FormEditorPreviewWrapper = () => {
  const editorModel = useEditorModel<FormModule>();
  const { t: tAria } = useTranslation('aria');
  const { t: tComponents } = useTranslation('components');
  const [previewMode, setPreviewMode] = React.useState<'form' | 'json'>('form');
  const { setSectionIndexOpen, setBlockIndexOpen } = useM24FormEditorAccordionContext();
  return (
    <Box
      overflow='auto'
      sx={(theme) => ({
        backgroundColor: Styles.Colors.PREVIEW_BG_COLOR,
        color: theme.palette.getContrastText(Styles.Colors.PREVIEW_BG_COLOR),
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'flex-start',
      })}>
      {previewMode === 'form' ? (
        <M24FormPreview
          form={editorModel}
          onBlockClick={(sectionIndex, blockIndex) => {
            setSectionIndexOpen(sectionIndex);
            setBlockIndexOpen(blockIndex);
          }}
        />
      ) : (
        <pre>{JSON.stringify(editorModel, null, 2)}</pre>
      )}
      <SpeedDial
        /* istanbul ignore next */
        sx={(theme) => ({
          position: 'absolute',
          bottom: theme.spacing(4),
          left: theme.spacing(4),
          opacity: '0.5',
        })}
        direction='right'
        FabProps={{ color: 'secondary' }}
        ariaLabel={tAria('components.EditorPreviewContainer.SelectDeviceType')}
        icon={
          <SpeedDialIcon
            icon={previewMode === 'form' ? <MaterialSymbol name='list_alt' /> : <MaterialSymbol name='data_object' />}
            openIcon={<MaterialSymbol name='close' />}
          />
        }>
        <SpeedDialAction
          id='select-device-type-form-action'
          /* istanbul ignore next */
          sx={
            previewMode === 'form'
              ? (theme) => ({
                  '&:not(:hover)': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.getContrastText(theme.palette.secondary.main),
                  },
                })
              : undefined
          }
          icon={<MaterialSymbol name='list_alt' />}
          onClick={() => setPreviewMode('form')}
          tooltipTitle={tComponents(`EditorPreviewContainer.PreviewType.form`)}
          tooltipPlacement='top'
          arrow
        />
        <SpeedDialAction
          id='select-device-type-form-action'
          /* istanbul ignore next */
          sx={
            previewMode === 'json'
              ? (theme) => ({
                  '&:not(:hover)': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.getContrastText(theme.palette.secondary.main),
                  },
                })
              : undefined
          }
          icon={<MaterialSymbol name='data_object' />}
          onClick={() => setPreviewMode('json')}
          tooltipTitle={tComponents(`EditorPreviewContainer.PreviewType.developer`)}
          tooltipPlacement='top'
          arrow
        />
      </SpeedDial>
    </Box>
  );
};

export default M24FormEditorPreviewWrapper;
