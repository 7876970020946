import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockPath } from '../../CurrentBlockProvider';
import { OutlinedContainer } from '../../../../components/OutlinedContainer';
import Styles from '../../../../assets/js/Styles';
import CheckboxInput from '../../../../components/forms/CheckboxInput';

export const EventsDisplayOptions: FC<{ blockPath: BlockPath }> = ({ blockPath }) => {
  const { t } = useTranslation('components');
  return (
    <OutlinedContainer
      label={`${t('PageListManualBlock.DisplayInputs.Label')}:`}
      fullWidth
      labelBackgroundColor={Styles.Colors.THEME_BG_COLOR_SECONDARY}>
      <CheckboxInput path={`${blockPath}.displayCategory`} label={t('EventsBlock.DisplayOptions.Category')} />
      <CheckboxInput path={`${blockPath}.displayDescription`} label={t('EventsBlock.DisplayOptions.Description')} />
      <CheckboxInput path={`${blockPath}.displayLocation`} label={t('EventsBlock.DisplayOptions.Location')} />
      <CheckboxInput path={`${blockPath}.displayMuseum`} label={t('EventsBlock.DisplayOptions.Museum')} />
    </OutlinedContainer>
  );
};

export default EventsDisplayOptions;
