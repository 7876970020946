/* istanbul ignore file */
import { codeToToken, login, logout, refreshToken } from '@ekultur/authentication';
import { Settings } from '../Settings';
import { isJWTExpired } from '../utils/jwt';

/**
 * Provides a static API for login against eKultur
 */
export abstract class AuthenticationService {
  private static readonly host: string = Settings.EKULTUR_LOGIN_HOST;

  private static readonly gateway: string = Settings.EKULTUR_LOGIN_GATEWAY;

  private static readonly redirectURI = `${window.location.origin}${Settings.EKULTUR_LOGIN_CALLBACK_PATH}`;

  private static readonly sameSite = Settings.EKULTUR_SAME_SITE;

  /**
   * Refresh the current token.
   * Resolves if refresh is OK.
   * Rejects if the user needs to log in again
   */
  public static async refreshToken(): Promise<void> {
    return new Promise((onSuccess, onError) => {
      refreshToken({
        apiGateway: AuthenticationService.gateway,
        onSuccess,
        onError,
      });
    });
  }

  /**
   * Start the login process.
   * Redirects the user to the eKultur IDP with the provided callback-url
   */
  public static async login(): Promise<void> {
    login({
      loginHost: AuthenticationService.host,
      redirectUri: AuthenticationService.redirectURI,
      locationPostLogin: window.location.href,
    });
  }

  /**
   * Log the user out from eKultur
   */
  public static async logout(): Promise<void> {
    logout({
      apiGateway: AuthenticationService.gateway,
    });
  }

  /**
   * When the user is redirected back to the app from eKultur,
   * call this to trade the code and state for a valid token.
   * Resolves with the url the user was at when the login process was started
   * @param code Code from QueryParams
   * @param state State from QueryParams
   */
  public static async codeToToken(code: string, state: string): Promise<string> {
    return new Promise((resolve, onError) => {
      codeToToken({
        code,
        state,
        redirectUri: AuthenticationService.redirectURI,
        apiGateway: AuthenticationService.gateway,
        onSuccess: ({ previousPath }) => resolve(previousPath || window.location.origin),
        onError,
        sameSite: AuthenticationService.sameSite,
      });
    });
  }

  /**
   * Check whether a user is authenticated or not
   */
  public static isAuthenticated(): boolean {
    const existingToken = localStorage.getItem(Settings.TOKEN_LOCAL_STORAGE_KEY);
    try {
      return !!existingToken && !isJWTExpired(existingToken);
    } catch (e) {
      if ((e as unknown as Error)?.message?.includes('JWT')) {
        return false;
      }
      throw e;
    }
  }
}

export default AuthenticationService;
