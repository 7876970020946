import React, { ReactNode } from 'react';
import { useController } from 'react-hook-form';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { SettingsComponent } from '../../lib/declarations/EditorComponentTypes';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { useCurrentBlock } from '../CurrentBlockProvider';
import ViewTypeSelector from '../../lib/components/settings/ViewTypeSelector';
import { DMBlock, DMViewType } from '../../../declarations/models/blocks/DMBlock';

const VARIANT_ICONS: Record<DMViewType, ReactNode> = {
  [DMViewType.GRID]: <MaterialSymbol name='view_module' />,
  [DMViewType.LIST]: <MaterialSymbol name='view_list' />,
  [DMViewType.MAP]: <MaterialSymbol name='map' />,
};

export const PageBlockDMViewTypeSelector: SettingsComponent = () => {
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: selectedViewType, onChange: setSelectedViewType },
  } = useController<BlockSpecificPage<DMBlock>, `${typeof blockPath}.view`>({ name: `${blockPath}.view` });

  return (
    <ViewTypeSelector
      viewType={selectedViewType}
      viewTypes={Object.values(DMViewType)}
      onChange={setSelectedViewType}
      variantIcons={VARIANT_ICONS}
      variantTranslationPath='DMViewTypeSelector'
    />
  );
};

export default PageBlockDMViewTypeSelector;
