import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout';
import Header from '../Header';
import Container from '../Container';
import AuthenticationService from '../../services/AuthenticationService';

const COUNT_DOWN_SECONDS = 5;

export const EkulturLogoutHandler: FC = () => {
  const doLogoutTimerId = useRef<NodeJS.Timeout | null>(null);
  const countdownIntervalId = useRef<NodeJS.Timeout | null>(null);

  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const navigate = useNavigate();

  const [counter, setCounter] = useState<number>(COUNT_DOWN_SECONDS);
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const [logoutError, setLogoutError] = useState<boolean>(false);

  const cleanupTimers = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(doLogoutTimerId.current!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearInterval(countdownIntervalId.current!);
  }, []);

  const doLogout = useCallback(async () => {
    try {
      setIsLoggingOut(true);
      cleanupTimers();
      await AuthenticationService.logout();
      navigate('/', { replace: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setLogoutError(true);
      setIsLoggingOut(false);
    }
  }, [navigate, cleanupTimers]);

  const handleAbort = () => {
    cleanupTimers();
    navigate(-1);
  };

  useEffect(() => {
    if (!AuthenticationService.isAuthenticated()) {
      navigate('/', { replace: true });
    }
    doLogoutTimerId.current = setTimeout(doLogout, COUNT_DOWN_SECONDS * 1000);
    countdownIntervalId.current = setInterval(() => setCounter((c) => c - 1), 1000);
    return cleanupTimers;
  }, [cleanupTimers, doLogout, navigate]);

  let content: ReactNode;

  if (logoutError) {
    content = (
      <>
        <Typography variant='h4' component='h1' color='error'>
          {tComponents('EkulturLogoutHandler.logoutFailed')}
        </Typography>
        <Button variant='contained' color='primary' onClick={doLogout}>
          {tCommon('tryAgain')}
        </Button>
      </>
    );
  } else if (isLoggingOut) {
    content = (
      <>
        <Typography variant='h4' component='h1'>
          {tComponents('EkulturLogoutHandler.loggingOut')}
        </Typography>
        <CircularProgress variant='indeterminate' color='primary' />
      </>
    );
  } else {
    content = (
      <>
        <Typography variant='h4' component='h1'>
          {tComponents('EkulturLogoutHandler.loggingOutInXSec', { count: counter })}
        </Typography>
        <Container gap={4} fullWidth>
          <Button variant='outlined' onClick={handleAbort}>
            {tCommon('abort')}
          </Button>
          <Button variant='contained' color='primary' onClick={doLogout}>
            {tComponents('EkulturLogoutHandler.logOutNow')}
          </Button>
        </Container>
      </>
    );
  }

  return (
    <Layout headerContent={<Header />}>
      <Container fullHeight fullWidth>
        <Paper sx={{ width: '50%', height: '50%' }}>
          <Container p={4} gap={10} column fullWidth fullHeight>
            {content}
          </Container>
        </Paper>
      </Container>
    </Layout>
  );
};

export default EkulturLogoutHandler;
