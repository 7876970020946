import React, { FC, MouseEvent } from 'react';
import { IconButton, IconButtonProps, SvgIconProps, Tooltip, TooltipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';

export interface FavouriteButtonProps extends Pick<IconButtonProps, 'edge' | 'color'> {
  isFavourite: boolean;
  onChange?: (event: MouseEvent<HTMLButtonElement>, isFavourite: boolean) => void;
  tooltipPlacement?: TooltipProps['placement'];
  selectedColor?: SvgIconProps['color'];
}

export const FavouriteButton: FC<FavouriteButtonProps> = ({
  isFavourite,
  onChange,
  tooltipPlacement,
  selectedColor = 'primary',
  color = 'default',
}) => {
  const { t } = useTranslation('components');
  const label = t(isFavourite ? 'FavouriteButton.UnmarkAsFavourite' : 'FavouriteButton.MarkAsFavourite');
  return (
    <Tooltip title={label} placement={tooltipPlacement} arrow disableInteractive>
      <IconButton aria-label={label} color={color} onClick={(e) => onChange?.(e, !isFavourite)}>
        {isFavourite ? (
          <MaterialSymbol name='star' fill color={selectedColor} />
        ) : (
          <MaterialSymbol name='star' color='inherit' />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default FavouriteButton;
