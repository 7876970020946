import React from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import { useTranslation } from 'react-i18next';
import { DraggableCollapseContainerProps } from '../../../../components/DraggableCollapseContainer';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { TableBlock } from '../../../../declarations/models/blocks/TableBlock';
import Container from '../../../../components/Container';
import TextInput from '../../../../components/forms/TextInput';
import { BlockItemPathPrefix } from '../../../../declarations/models/BlockItemPathPrefix';

export interface TableBlockRowItemProps<T> extends Pick<DraggableCollapseContainerProps<T>, 'index' | 'onRemove'> {
  columns: number;
}

export const TableBlockRowItem = <T extends TableBlock['items'][0]>({
  index,
  onRemove,
  columns,
}: TableBlockRowItemProps<T>) => {
  const { blockPath } = useCurrentBlock();
  const { t: tCommon } = useTranslation('common');
  const itemPathPrefix: BlockItemPathPrefix = `${blockPath}.items.${index}`;

  return (
    <Container fullWidth>
      <Container spaceBetween fullWidth>
        <Container fullWidth>
          {Array.from({ length: columns }, (_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TextInput key={`col-${index}-${i}`} path={`${itemPathPrefix}.${i}`} size='small' label='' />
          ))}
        </Container>
        <Container right>
          <Container right>
            <DeleteButton
              onConfirm={() => onRemove?.(index)}
              component='IconButton'
              tooltip={tCommon('remove')}
              deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default TableBlockRowItem;
