import React, { FC } from 'react';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import { useController } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BannerBlock } from '@/declarations/models/blocks/BannerBlock';
import Container from '@/components/Container';
import { FinderButton, FinderType } from '@/components/Finder/FinderButton';
import { UserSelectableIcon, UserSelectableIconName } from '@/components/UserSelectableIcon';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { useTranslation } from 'react-i18next';

const SelectedRow: FC<{ icon: React.ReactNode; children: React.ReactNode; onRemove?: () => void }> = ({
  icon,
  children,
  onRemove,
}) => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        minHeight: '72px',
        maxWidth: '400px',
      }}>
      <Box
        sx={{
          borderBottomLeftRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          borderTopLeftRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          backgroundColor: Styles.Colors.MEDIUM_DARK_GREY,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100px',
        }}>
        {icon}
      </Box>
      <Box
        sx={{
          display: 'flex',
          p: 2,
          backgroundColor: Styles.Colors.LIGHT_GREY,
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottomRightRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          borderTopRightRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
        }}>
        {children}

        {!!onRemove && (
          <Tooltip title={t('remove')}>
            <IconButton onClick={onRemove}>
              <MaterialSymbol name='do_not_disturb_on' />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export function IconFinderButtonSmall({ path }: { path: BlockPath }) {
  const { t } = useTranslation('common');

  const {
    field: { value: icon, onChange: onChangeIcon },
  } = useController<BlockSpecificPage<BannerBlock>, `${typeof path}.icon`>({
    name: `${path}.icon`,
  });

  return (
    <Container fullWidth left>
      <Box sx={{ flex: '0 1 auto', maxWidth: '100%' }}>
        <FinderButton
          type={FinderType.ICON}
          overrideText={t('icon')}
          multiSelect={false}
          finderProps={{
            selectedIcon: icon,
            onSelectionConfirmed: (selected: UserSelectableIconName) => {
              onChangeIcon(selected);
            },
          }}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        />
      </Box>

      {!!icon && (
        <Box sx={{ flex: '1 1 auto', maxWidth: '100%' }}>
          <SelectedRow
            icon={<UserSelectableIcon name={icon} sx={{ fontSize: 48, color: 'white' }} />}
            onRemove={() => onChangeIcon(null)}>
            <Typography fontWeight={700}>
              {t('icon')}: {t(`userIcon.${icon}`)}
            </Typography>
          </SelectedRow>
        </Box>
      )}
    </Container>
  );
}
