import React, { useEffect, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useController, useWatch } from 'react-hook-form';
import { Employee } from '@/declarations/models/Employee';
import TextInput from '@/components/forms/TextInput';
import { GenericMedia } from '@/declarations/GenericMedia';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { BylineBlockModel } from '@/declarations/models/blocks/BylineBlock';
import { ExternalMediaModel } from '@/declarations/models/ExternalMediaModel';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { BaseBlock } from '@/declarations/models/blocks/BaseBlock';
import DropIndicatorArrow from '@/components/DropIndicatorArrow';
import EmployeesBlockItem from '@/editor/PageEditor/EditorBlock/EmployeeBlocks/EmployeesBlockItem';
import { useStore } from '@/components/store/Store';
import { AddMediaObject } from '@/components/AddMediaObject';
import Container from '../../../../components/Container';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import Styles from '../../../../assets/js/Styles';

const BylineBlock: FC = () => {
  const { blockPath, block } = useCurrentBlock();
  const { state } = useStore();
  const siteId = state.selectedSite?.id;
  const { t } = useTranslation('components');
  const currentBlock = block as BylineBlockModel;
  const {
    field: { value: mode, onChange: onChangeMode },
  } = useController<BlockSpecificPage<BylineBlockModel>, `${BlockPath}.mode`>({
    name: `${blockPath}.mode`,
    defaultValue: 'employee',
  });

  const {
    fields: selectedEmployees,
    append,
    insert,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<BylineBlockModel>, `${BlockPath}.employees`, 'itemHash'>({
    name: `${blockPath}.employees`,
  });

  const mediaObject = useWatch<BlockSpecificPage<BylineBlockModel>, `${BlockPath}.mediaobject`>({
    name: `${blockPath}.mediaobject`,
  });

  const handleAddEmployee = (item: Employee | null) => {
    if (item?.id) {
      selectedEmployees.forEach((_, index) => remove(index));
      append({ id: item.id });
    }
  };

  const {
    field: { onChange: onChangeMediaObject },
  } = useController<
    BlockSpecificPage<BaseBlock & { mediaobject?: M24MediaModel | ExternalMediaModel }>,
    `${BlockPath}.mediaobject`
  >({
    name: `${blockPath}.mediaobject`,
  });

  const {
    field: { onChange: onChangeLeadText },
  } = useController<BlockSpecificPage<BylineBlockModel>, `${BlockPath}.leadtext`>({
    name: `${blockPath}.leadtext`,
    defaultValue: '',
  });

  const handleModeChange = (_: React.ChangeEvent<HTMLInputElement>, v: string) => {
    onChangeMode(v);
    if (v === 'employee') {
      /* onChangeMediaObject(null); */
    } else if (v === 'manual') {
      /* selectedEmployees.forEach((__, index) => remove(index)); */
      /* onChangeLeadText(''); */
    }
  };

  useEffect(() => {
    if (currentBlock?.author?.id) {
      onChangeMode('person');
    }
    if (!currentBlock?.author?.id && currentBlock?.authortext) {
      onChangeMode('manual');
    }
  }, [currentBlock?.author?.id, currentBlock?.authortext, onChangeMode]);

  return (
    <Container gap={0} column top left fullWidth data-testid='bylineBlock'>
      <Container>
        <RadioGroup value={mode} onChange={handleModeChange} row>
          <FormControlLabel control={<Radio value='employee' />} label={t('BylineBlock.Employee')} />
          <FormControlLabel control={<Radio value='manual' />} label={t('BylineBlock.Manual')} />
          {currentBlock?.author?.id && (
            <FormControlLabel control={<Radio value='person' />} label={t('BylineBlock.Person')} />
          )}
        </RadioGroup>
      </Container>
      <Divider sx={{ mt: 2, mb: 2, width: '100%' }} />

      <Container gap={2} column top left>
        <TextInput path={`${blockPath}.leadtext`} label={t('BylineBlock.WrittenBy')} defaultValue='' />
        <br />
      </Container>
      {mode === 'employee' && (
        <Container gap={2} top left fullWidth column>
          <Container gap={0} column top left fullWidth>
            <Container gap={0} column top fullWidth>
              {(selectedEmployees || []).map((item, i) => (
                <Fragment key={item.id}>
                  <Collapse sx={{ width: '100%' }}>
                    <DropIndicatorArrow
                      label={t('Draggable.MoveHere')}
                      borderStyle='dashed'
                      typographyProps={{
                        variant: 'caption',
                        lineHeight: '1rem',
                        fontStyle: 'italic',
                      }}
                      spacing={2}
                      visible
                      noArrow
                    />
                  </Collapse>
                  <EmployeesBlockItem
                    index={i}
                    onReorder={move}
                    onAdd={(itemToAdd, addAtIndex) => itemToAdd && insert(addAtIndex, itemToAdd)}
                    onRemove={remove}
                    siteId={siteId}
                    itemPathPrefix={`${blockPath}.employees`}
                    dragAndDropDisabled
                  />
                </Fragment>
              ))}
            </Container>
            <Container gap={0} column top fullWidth>
              <FinderButton
                type={FinderType.EMPLOYEE}
                multiSelect={false}
                finderProps={{
                  onSelectionConfirmed: (items: Array<GenericMedia<Employee>>) =>
                    items.forEach((item) => handleAddEmployee(item.source)),
                }}
              />
            </Container>
          </Container>
        </Container>
      )}
      {mode === 'manual' && (
        <Container gap={2} top left fullWidth column>
          <Container top left fullWidth>
            <TextInput path={`${blockPath}.authortext`} label={t('BylineBlock.Author')} defaultValue='' />
            <TextInput path={`${blockPath}.description`} label={t('BylineBlock.Description')} defaultValue='' />
          </Container>
          <Container gap={2} top left fullWidth>
            <AddMediaObject canEdit mediaObject={mediaObject} finderType={FinderType.M24} />
          </Container>
        </Container>
      )}
      {mode === 'person' && (
        <Container
          gap={2}
          top
          left
          fullWidth
          column
          sx={{
            backgroundColor: Styles.Colors.LIGHT_BLUE,
            borderRadius: Styles.Dimensions.SECTION_BORDER_RADIUS,
            border: `1px solid ${Styles.Colors.MEDIUM_BLUE}`,
            fontStyle: 'italic',
            fontSize: '15px',
          }}>
          <Container gap={2} p={2} top left fullWidth>
            {t('BylineBlock.PersonCardsNotSupported')}
          </Container>
        </Container>
      )}
    </Container>
  );
};

export default BylineBlock;
