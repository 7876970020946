import React, { Fragment, ReactNode } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Styles from '../../assets/js/Styles';
import { GenericMedia } from '../../declarations/GenericMedia';

export interface FinderSelectedMediaListProps<T = unknown> {
  selectedItems: Array<GenericMedia<T>>;
  onSelect: () => void;
  onCancel: () => void;
  renderItem: (item: GenericMedia<T>) => ReactNode;
}

/**
 * Used to render a list of selected media-items in a finder
 * @param selectedItems
 * @param onSelect
 * @param onCancel
 * @param renderItem
 * @constructor
 */
export const FinderSelectedMediaList = <T,>({
  selectedItems,
  onSelect,
  onCancel,
  renderItem,
}: FinderSelectedMediaListProps<T>) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');
  const noItems = !selectedItems?.length;
  return (
    <Paper
      sx={{
        width: '33vw',
        height: '100%',
        backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
        overflow: 'hidden',
      }}>
      <Container gap={0} column spaceBetween fullHeight fullWidth>
        <Container
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
          column
          top={!noItems}
          left={!noItems}
          pt={1}
          pb={10}
          px={2}
          fullHeight
          fullWidth>
          {noItems && (
            <Typography variant='button' color='textSecondary'>
              {tComponents('Finder.UseSelected_interval', {
                postProcess: 'interval',
                count: 0,
              })}
            </Typography>
          )}
          {selectedItems.map((item) => (
            <Fragment key={item.id}>{renderItem(item)}</Fragment>
          ))}
        </Container>
        <Container
          // istanbul ignore next
          sx={(theme) => ({ boxShadow: theme.shadows[3] })}
          p={2}
          pt={1.5}
          right
          fullWidth>
          <Button onClick={onCancel} color='secondary' variant='contained'>
            {tCommon('cancel')}
          </Button>
          <Button onClick={onSelect} color='success' variant='contained' disabled={noItems}>
            {tComponents('Finder.UseSelected_interval', {
              postProcess: 'interval',
              count: selectedItems.length,
            })}
          </Button>
        </Container>
      </Container>
    </Paper>
  );
};

export default FinderSelectedMediaList;
