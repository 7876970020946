import { PageFolder } from '@/declarations/models/Folder';

export function findFolder(folders: PageFolder[], id: number): PageFolder | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    }
    if (folder.children) {
      const found = findFolder(folder.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findBreadCrumb(folders: PageFolder[], folder: PageFolder): PageFolder[] {
  const breadCrumb: PageFolder[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const f of folders) {
    if (f.id === folder.id) {
      breadCrumb.push(f);
      return breadCrumb;
    }
    if (f.children) {
      const found = findBreadCrumb(f.children, folder);
      if (found.length > 0) {
        breadCrumb.push(f);
        breadCrumb.push(...found);
        return breadCrumb;
      }
    }
  }

  return breadCrumb;
}
