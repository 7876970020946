import React, { FC } from 'react';
import { useDrop } from 'react-dnd';
import { Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { Draggable } from '../../declarations/Draggable';
import Container, { ContainerProps } from '../Container';
import { mergeSx } from '../../utils/mergeSx';

export interface ActionDropzoneProps extends ContainerProps {
  type: Draggable | Array<Draggable>;
  label: string;
  canDrop?: boolean;
  onAction?: <T>(item: T) => void;
  borderStyle?: 'solid' | 'dashed' | 'dotted' | 'double';
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' | 'default';
}

export const ActionDropzone: FC<ActionDropzoneProps> = ({
  type,
  onAction,
  label,
  borderStyle = 'solid',
  color = 'default',
  canDrop = true,
  sx,
  ...containerProps
}) => {
  const [{ visible }, drop] = useDrop(
    () => ({
      accept: type,
      drop: (item) => onAction?.(item),
      collect: (monitor) => ({
        visible: canDrop && monitor.canDrop(),
      }),
    }),
    [type, canDrop],
  );

  return (
    <Container
      ref={drop}
      aria-label={label}
      aria-hidden={!visible}
      {...containerProps}
      sx={mergeSx(
        (theme) => ({
          borderWidth: visible ? '3px' : 0,
          borderStyle,
          borderColor: color === 'default' ? theme.palette.text.disabled : theme.palette[color].main,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          backgroundColor: theme.palette.background.default,
          height: visible ? theme.spacing(7) : 0,
          visibility: visible ? 'visible' : 'hidden',
          opacity: visible ? 1 : 0,
          overflow: 'hidden',
          transition: 'all 200ms ease',
          my: visible ? '4px' : 0,
        }),
        sx || {},
      )}>
      <Typography variant='body2' color='textSecondary' textAlign='center'>
        {label}
      </Typography>
    </Container>
  );
};

export default ActionDropzone;
