import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from '@mui/material';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';
import { Flatten } from '../../utils/flatten';

export interface RadioInputProps<T, O extends Flatten<PathValue<T, Path<T>>>>
  extends FormInputBaseProps<T>,
    Pick<FormControlLabelProps, 'labelPlacement'> {
  options: Array<O>;
  loading?: boolean;
  direction?: 'vertical' | 'horizontal';
  getOptionKey: (option: O) => string | number;
  getOptionLabel: (option: O) => string | number;
}

const skeleton = (
  <Container left gap={2} pt={2}>
    <Skeleton variant='circular' width={24} height={24} />
    <Skeleton variant='text' width={80} height={24} />
  </Container>
);

export default function RadioInput<T extends FieldValues, O extends Flatten<PathValue<T, Path<T>>>>({
  options,
  getOptionKey,
  getOptionLabel,
  direction = 'vertical',
  labelPlacement,
  loading = false,
  ...baseProps
}: RadioInputProps<T, O>) {
  const { t } = useTranslation('components');
  const {
    field,
    fieldState,
    formContext: { setValue },
    required,
    color,
    disabled,
    isError,
    errorMessage,
    label,
    fullWidth,
  } = useFormInput<T>(baseProps);

  const handleValueChanged = (key: string | number) => {
    setValue(baseProps.path, (!key ? null : options.find((opt) => getOptionKey(opt) === key) || null) as O, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <FormControl required={required} color={color} disabled={disabled} error={isError} fullWidth={fullWidth}>
      <FormLabel id='radio-group-label'>{label}</FormLabel>
      <FormHelperText>{errorMessage}</FormHelperText>
      {loading ? (
        <>
          {skeleton}
          {skeleton}
          {skeleton}
        </>
      ) : (
        <RadioGroup
          {...field}
          value={field.value ? getOptionKey(field.value as O) : ''}
          onChange={(e) => handleValueChanged(e.target.value)}
          aria-labelledby='radio-group-label'
          row={direction === 'horizontal'}>
          {!required && (
            <FormControlLabel
              value=''
              control={<Radio color={color} />}
              label={t('RadioInput.NoValue')}
              labelPlacement={labelPlacement}
            />
          )}

          {options.map((option) => {
            const key = getOptionKey(option);
            return (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color={color} />}
                label={getOptionLabel(option)}
                labelPlacement={labelPlacement}
              />
            );
          })}
        </RadioGroup>
      )}
      {!!fieldState.error?.message && <FormHelperText>{fieldState.error.message}</FormHelperText>}
    </FormControl>
  );
}
