import React, { FC } from 'react';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import Links from '../../../../components/Links/Links';
import { LinkType } from '../../../../declarations/models/LinkType';
import { CardModuleBlock } from '../../../../declarations/models/blocks/CardModuleBlock';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';

export const CardModuleLinks: FC = () => {
  const { blockPath } = useCurrentBlock();

  const {
    fields: linkItems,
    append,
    update,
    move,
    remove,
  } = useControlledFieldArray<BlockSpecificPage<CardModuleBlock>, `${BlockPath}.links`>({
    name: `${blockPath}.links`,
  });

  const eventManager = useEditorEventManager();

  return (
    <Links
      onUpdate={update}
      onAppend={append}
      onRemove={remove}
      onReorder={move}
      linkItems={linkItems}
      linkTypes={[LinkType.LINK, LinkType.PAGE]}
      itemsArrayName='links'
      showLinkStyleSelector={false}
      blockPath={blockPath}
      useEventHandler={useEditorEventHandler}
      useEventManager={eventManager}
    />
  );
};

export default CardModuleLinks;
