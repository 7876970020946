import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../../../services/Api';
import { KPProcessedFields } from '../../../../declarations/models/KP';
import { resolveLocalizedString } from '../../../../utils/strings';

export const KPBlockPreviewItem: FC<{
  lng: string;
  id?: number;
  item?: KPProcessedFields;
  type?: string;
  size?: string;
  integrated?: boolean;
}> = ({ lng, id, item, type, size, integrated }) => {
  const { t } = useTranslation('common');
  const [kpObject, setKpObject] = useState<KPProcessedFields>();

  useEffect(() => {
    if (item) setKpObject(item);
    else if (id) {
      const ctx = Api.getKPObject(id);
      ctx
        .fetchDirect(null)
        .then((o) => !!o && setKpObject(o))
        .finally(ctx.abort);
    } else setKpObject(undefined);
  }, [id, item]);

  return (
    // eslint-disable-next-line react/no-unknown-property,jsx-a11y/anchor-is-valid
    <a
      data-type={type || 'Kulturpunkt'}
      data-target={integrated ? '_self' : 'm24ref_kulturpunkt'}
      data-size={size || ''}
      className='module ecard skin-kulturpunkt'>
      <div className='module__media'>
        <svg
          className='ecard__logo logo-kp'
          xmlns='http://www.w3.org/2000/svg'
          width='29'
          height='34'
          viewBox='0 0 29 34'>
          <g transform='translate(11.234 1)'>
            <rect width='5.864' height='32.279' transform='translate(-9.308)' fill='#fff' />
            <path
              d='M-4741.756,3067l-11.659,15.885,9.437,16.264h-6.982l-9.726-16.264L-4749.12,3067Z'
              transform='translate(4758.145 -3066.927)'
              fill='#fff'
            />
            <rect width='5.864' height='32.279' transform='translate(-10)' fill='#fff' />
            <path
              d='M-4741.756,3067l-11.659,15.885,9.437,16.264h-6.982l-9.726-16.264L-4749.12,3067Z'
              transform='translate(4758.837 -3066.927)'
              fill='#fff'
            />
          </g>
        </svg>
        {!!kpObject?.image_src && <img alt='' src={`${kpObject.image_src}?&dimension=600x600`} loading='lazy' />}
      </div>
      <div className='module__content'>
        <div className='module__head'>
          {kpObject?.schema_id && (
            <span className='ecard__insert'>${t(`KulturioSchemaTypes.${kpObject?.schema_id}`)}</span>
          )}
          <h3 className='ecard__title'>{resolveLocalizedString(lng, kpObject?.title)}</h3>
        </div>
        <div className='module__body'>
          <p>{resolveLocalizedString(lng, kpObject?.description)}</p>
        </div>
        <div className='module__foot'>
          {type === 'Museum' && <span className='module__button'>Les mer på KulturPunkt</span>}
          <span className='ecard__owner'>{resolveLocalizedString(lng, kpObject?.owner_name)}</span>
        </div>
      </div>
    </a>
  );
};

export default KPBlockPreviewItem;
