import { FieldValues, Path, PathValue, useController, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { FormConfiguration, useFormConfiguration } from '../components/forms/Form';
import { useErrorMessage } from './useErrorMessage';
import { Flatten } from '../utils/flatten';

interface ValidationProps {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
}

type Overrides = FormConfiguration;

export interface FormInputBaseProps<T> extends ValidationProps, Partial<Overrides> {
  path: Path<T>;
  label: ReactNode;
  defaultValue?: Flatten<PathValue<T, Path<T>>>;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export default function useFormInput<T extends FieldValues>({
  path,
  defaultValue,
  required,
  min,
  max,
  minLength,
  maxLength,
  disabled,
  color,
  variant,
  ...rest
}: FormInputBaseProps<T>) {
  const config = useFormConfiguration();
  const formContext = useFormContext<T>();
  const controller = useController<T>({
    name: path,
    defaultValue,
    rules: { required, min, max, maxLength, minLength },
  });
  const errorMessage = useErrorMessage(controller.fieldState.error, { min, max });

  return {
    ...config,
    defaultValue,
    required,
    min,
    max,
    minLength,
    maxLength,
    ...rest,
    ...controller,
    color: color || config.color,
    variant: variant || config.variant,
    disabled: controller.formState.isSubmitting || disabled,
    isError: controller.fieldState.invalid,
    errorMessage,
    formContext,
  };
}
