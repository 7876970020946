import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Skeleton } from '@mui/material';
import { useDebounce } from '@/hooks/useDebounce';
import { apiTimestampToDate, dateToApiTimestamp } from '@/utils/dates';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useFormInput, { FormInputBaseProps } from '../../hooks/useFormInput';

export interface DateTimeInputProps<T> extends FormInputBaseProps<T> {
  isLoading?: boolean;
  inputType?: 'date' | 'date-time' | 'time';
  is12HourFormat?: boolean;
}

const getDayJsLocale = () => (i18next.language.toLocaleLowerCase().includes('no') ? 'nb' : 'en-gb');

/**
 * @param isLoading isLoading
 * @param options options
 * @param baseProps baseProps
 * @constructor
 */
export default function DateTimeInput<T extends FieldValues>({
  isLoading,
  inputType = 'date-time',
  defaultValue,
  is12HourFormat = false,
  ...baseProps
}: DateTimeInputProps<T>) {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    field: { value, onChange },
  } = useFormInput<T>(baseProps);

  const apiToInternal = (date: string | null) => (date ? dayjs(apiTimestampToDate(date)) : null);
  const internalToApi = (date: dayjs.Dayjs | null) => (date ? dateToApiTimestamp(date.toDate()) : '');

  const [internalState, setInternalState] = useState<dayjs.Dayjs | null>(
    apiToInternal(value || (defaultValue as typeof value)) ?? null,
  );

  const mappedOnChange = (date: dayjs.Dayjs | null) => {
    return onChange(internalToApi(date));
  };

  useDebounce(800, internalState, mappedOnChange);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getDayJsLocale()}>
      {isLoading ? (
        <Skeleton variant='text' width={230} height={48} />
      ) : (
        <>
          {inputType === 'date' && (
            <DatePicker value={internalState} onChange={setInternalState} label={baseProps.label} />
          )}
          {inputType === 'time' && (
            <TimePicker
              defaultValue={defaultValue}
              value={internalState}
              onChange={setInternalState}
              sx={{
                maxWidth: '7em',
              }}
              ampm={is12HourFormat}
            />
          )}
        </>
      )}
    </LocalizationProvider>
  );
}
