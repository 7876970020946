export enum Alignment {
  AUTO = 'auto',
  DEFAULT = 'default',
  NORMAL = 'normal',
  WIDE = 'wide',
  FULL = 'full',
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  MODULE = 'module',
}

export type TextAlignment = Alignment.NORMAL | Alignment.LEFT | Alignment.RIGHT | Alignment.WIDE;
export type BannerTextAlignment = Alignment.DEFAULT | Alignment.LEFT | Alignment.CENTER | Alignment.RIGHT;
export type PositionAlignment = Alignment.NORMAL | Alignment.LEFT | Alignment.RIGHT | Alignment.WIDE | Alignment.FULL;
export type TitleAlignment = Alignment.AUTO | Alignment.LEFT | Alignment.CENTER | Alignment.MODULE;
