import React, { FC } from 'react';
import { getSubCategories, MainCategory, SubCategory } from '@/declarations/Category';
import { Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { useTranslation } from 'react-i18next';

interface FilterSubCategoriesProps {
  mainCategory?: MainCategory;
  onChangeMainCategory: (mainCategory: MainCategory) => void;
  subCategories: Array<SubCategory>;
  onChangeSubCategories: (subCategories: Array<SubCategory>) => void;
}

const FilterSubCategories: FC<FilterSubCategoriesProps> = ({
  mainCategory,
  onChangeMainCategory,
  subCategories,
  onChangeSubCategories,
}) => {
  const { t: tCommon } = useTranslation('common');
  const availableSubCategories = !mainCategory ? [] : getSubCategories(mainCategory);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
        p: 4,
        gap: 2,
      }}>
      <Box>
        <FormControl
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}>
          <RadioGroup
            row
            value={mainCategory}
            onChange={(event) => onChangeMainCategory(event.target.value as MainCategory)}>
            {Object.values(MainCategory)
              .filter((c) => c !== MainCategory.EVENT)
              .map((c) => (
                <FormControlLabel key={c} value={c} control={<Radio />} label={tCommon(`MainCategory.${c ?? ''}`)} />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}>
        {availableSubCategories.map((subCategory) => (
          <FormControlLabel
            key={subCategory}
            control={
              <Checkbox
                checked={subCategories.includes(subCategory)}
                onChange={(event) =>
                  onChangeSubCategories(
                    event.target.checked
                      ? [...subCategories, subCategory]
                      : subCategories.filter((c) => c !== subCategory),
                  )
                }
              />
            }
            label={tCommon(`SubCategory.${subCategory}`)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FilterSubCategories;
