import React, { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useConfirmDialog from '@/components/ConfirmDialogProvider';
import { ChildrenProp } from '../../declarations/ChildrenProp';
import { useStore } from '../../components/store/Store';
import { Api } from '../../services/Api';
import { ViewType } from '../../declarations/ViewType';
import Container from '../../components/Container';
import { SortDirection, SortOption, SortType } from '../../declarations/models/SortOption';
import EmployeesViewHeader from './EmployeesViewHeader';
import EmployeesViewList from './EmployeesViewList';
import { Employee } from '../../declarations/models/Employee';
import FullScreenModal from '../../components/FullScreenModal';
import EmployeeEditor from '../../editor/EmployeeEditor/EmployeeEditor';

export const EmployeesView: FC<ChildrenProp> = () => {
  const { t } = useTranslation('common');
  const store = useStore();
  const siteId = store?.state?.selectedSite?.id || 0;
  const selectedSiteLanguage = store?.state?.selectedSiteLanguage || 'no';
  const { enqueueSnackbar } = useSnackbar();

  const [selectedViewType, setSelectedViewType] = useState<ViewType>(ViewType.GRID);

  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');
  const [sortOption, setSortOption] = useState<SortOption>({ sortBy: SortType.UPDATED_AT, order: SortDirection.DESC });
  const [refreshDep, setRefreshDep] = useState(Date.now);
  const refreshPage = () => setRefreshDep(Date.now());

  const [employeeId, setEmployeeId] = useState<number>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const confirm = useConfirmDialog();

  const handleOnItemClick = (item: Employee) => {
    if (item.id) {
      setEmployeeId(item.id);
      setEditModalOpen(true);
    }
  };

  const handleItemDelete = async (item: Employee) => {
    const id = Number(item.id);
    if (!Number.isNaN(id) && (await confirm(t('deleteWarning')))) {
      await Api.deleteEmployee(id)
        .fetchDirect(null)
        .catch((e) => enqueueSnackbar(`${t('deleteFailed')}: ${e}`, { variant: 'error' }))
        .then((res) => !!res && enqueueSnackbar(t('deleteSuccess'), { variant: 'success' }));
      refreshPage();
    }
  };

  const handleAddNew = () => {
    setEmployeeId(undefined);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    refreshPage();
    setEditModalOpen(false);
  };

  useEffect(() => {
    let unmounted = false;
    setIsLoading(true);

    const ctx = Api.getEmployees(siteId, {
      text_query: searchInput || undefined,
      order_by: sortOption.sortBy,
      order: sortOption.order,
      locale: selectedSiteLanguage,
    });

    ctx
      .fetchDirect(null)
      .then((res) => {
        setTotalCount(res?.total_count ?? 0);
        setEmployees(res?.result || []);
      })
      .catch((error) => console.error(error))
      .finally(() => !unmounted && setIsLoading(false));

    return () => {
      unmounted = true;
      ctx.abort();
    };
  }, [siteId, sortOption, refreshDep, searchInput, selectedSiteLanguage]);

  return (
    <Container p={6} fullWidth fullHeight column gap={0} top left>
      <FullScreenModal modalOpen={editModalOpen}>
        <EmployeeEditor onCloseEditor={handleCloseModal} employeeId={employeeId} />
      </FullScreenModal>
      <EmployeesViewHeader
        query={searchInput}
        onQueryChange={setSearchInput}
        count={employees.length ?? 0}
        totalCount={totalCount}
        selectedViewType={selectedViewType}
        setSelectedViewType={setSelectedViewType}
        sortOption={sortOption}
        setSortOption={setSortOption}
        onAddNewEmployee={handleAddNew}
      />
      <Container
        fullWidth
        top
        gap={0}
        sx={{
          flex: 1,
        }}>
        <EmployeesViewList
          isLoading={isLoading}
          items={employees}
          onItemClick={handleOnItemClick}
          selectedViewType={selectedViewType}
          onDeleteItem={handleItemDelete}
        />
      </Container>
    </Container>
  );
};

export default EmployeesView;
