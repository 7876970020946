import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dictionary } from '../declarations/Dictionary';

type Key = string | number;

export interface RecursiveSelectionState<T> {
  /**
   * Clear the selection state
   * @alias for deselectAll()
   */
  clear: () => void;
  /**
   * Select a node, and optionally it's subtree
   * @param key The key of the node to select
   * @param selectEntireSubtree Whether to select all child nodes as well
   */
  select: (key: Key, selectEntireSubtree?: boolean) => void;
  /**
   * Deselect a node, and optionally it's subtree
   * @param key The key of the node to deselect
   * @param selectEntireSubtree Whether to deselect all child nodes as well
   */
  deselect: (key: Key, selectEntireSubtree?: boolean) => void;
  /**
   * Select all nodes (including children)
   */
  selectAll: () => void;
  /**
   * Deselect all nodes
   * @alias for clear()
   */
  deselectAll: () => void;
  /**
   * Check whether a node or a subtree is selected
   * @param key The key of the (root) node to check
   * @param checkEntireSubtree Setting this to true makes this function only return true if the entire subtree is selected
   */
  isSelected: (key: Key, checkEntireSubtree?: boolean) => boolean;
  /**
   * Check whether a subtree has one or more selected items.
   *
   * <b>Note1:</b> this method will return false if all or none items are selected.
   *
   * <b>Note2:</b> The root node is excluded from the check
   *
   * @param key The key of the root node to check
   */
  isPartiallySelected: (key: Key) => boolean;
  /**
   * Returns all items with a falsy parentId.
   * Optionally, you can provide a parentId to ignore (treat every child of param as a root node)
   */
  getRootNodes: (ignoreParentId?: number) => Array<T>;
  /**
   * Get all nodes under the specified node, excluding the root
   * @param key The key of the root node to fetch the subtree of
   */
  getSubtree: (key: Key) => Array<T>;
  /**
   * Get all immediate children for a given node.
   *
   * This method returns only the first level below the provided node.
   * Use getSubtree() to get ALL nodes below the provided node
   * @see getSubtree
   * @param key
   */
  getChildren: (key: Key) => Array<T>;
  /**
   * Get all siblings for a given node.
   *
   * This method returns only the siblings, not their child nodes.
   * @param key
   */
  getSiblings: (key: Key) => Array<T>;
  /**
   * Get all parents for a given node.
   * @param key
   */
  getParents: (key: Key) => Array<T>;
  /**
   * Whether a node has any child nodes or not
   * @param key
   */
  hasChildren: (key: Key) => boolean;
  /**
   * Get the keys for all provided items
   */
  getKeys: () => Array<Key>;
  /**
   * Total number of items in the tree
   */
  itemCount: number;
  /**
   * Total number of selected items
   */
  selectedCount: number;
  /**
   * Whether all nodes in the tree are selected or not
   */
  allSelected: boolean;
  /**
   * True if one or more nodes are selected, but not all or none.
   */
  someSelected: boolean;
  /**
   * True if no items are selected
   */
  noneSelected: boolean;
  /**
   * A list of all selected items
   */
  selectedItems: Array<T>;
}

/**
 * Keeps track of selected nodes in a tree.
 * Additionally, provides utility methods for faster access to nodes, such as a node's children, or root-nodes.
 * @param items A list of all the nodes in the tree
 * @param getKey Function to get a unique ID for a node
 * @param getParentKey Function to retrieve the unique ID for a node's parent
 * @param clearSelectionStateOnItemChange If true, all selected nodes will be deselected if the list of items change
 */
export function useRecursiveSelectionState<T>(
  items: Array<T>,
  getKey: (item: T) => Key,
  getParentKey: (item: T) => Key | null,
  clearSelectionStateOnItemChange = false,
): RecursiveSelectionState<T> {
  const clearSelectionStateOnItemChangeRef = useRef<boolean>(clearSelectionStateOnItemChange);
  clearSelectionStateOnItemChangeRef.current = clearSelectionStateOnItemChange;
  const getKeyRef = useRef(getKey);
  getKeyRef.current = getKey;
  const getParentKeyRef = useRef(getParentKey);
  getParentKeyRef.current = getParentKey;

  const [selected, setSelected] = useState<Array<Key>>([]);

  const allKeys = useMemo<Array<Key>>(() => {
    return items.map(getKeyRef.current);
  }, [items]);

  const childrenLookup = useMemo<Dictionary<T>>(() => {
    // validate items
    if (items?.some((item) => getKeyRef.current(item) === getParentKeyRef.current(item))) {
      throw new Error('[useRecursiveSelectionState] An item is referencing itself');
    }
    // Create lookup
    return items.reduce((map, node) => {
      const parentKey = getKeyRef.current(node);
      // eslint-disable-next-line no-param-reassign
      map[parentKey] = items.filter((child) => getParentKeyRef.current(child) === parentKey);
      return map;
    }, {} as Dictionary<T>);
  }, [items]);

  const siblingsLookup = useMemo<Dictionary<T>>(() => {
    // validate items
    if (items?.some((item) => getKeyRef.current(item) === getParentKeyRef.current(item))) {
      throw new Error('[useRecursiveSelectionState] An item is referencing itself');
    }
    // Create lookup
    return items.reduce((map, node) => {
      const currentNodeKey = getKeyRef.current(node);
      const parentKey = getParentKeyRef.current(node);
      // eslint-disable-next-line no-param-reassign
      map[currentNodeKey] = items.filter(
        (item) => getParentKeyRef.current(item) === parentKey && getKeyRef.current(item) !== currentNodeKey,
      );
      return map;
    }, {} as Dictionary<T>);
  }, [items]);

  const parentsLookup = useMemo<Dictionary<T>>(() => {
    // validate items
    if (items?.some((item) => getKeyRef.current(item) === getParentKeyRef.current(item))) {
      throw new Error('[useRecursiveSelectionState] An item is referencing itself');
    }

    // Create lookup
    return items.reduce((map, node) => {
      const nodeKey = getKeyRef.current(node);
      const parents = [];
      let parentKey = getParentKeyRef.current(node);
      while (parentKey) {
        // eslint-disable-next-line no-loop-func
        const parentNode = items.find((item) => getKeyRef.current(item) === parentKey);
        if (parentNode) {
          parents.unshift(parentNode);
          parentKey = getParentKeyRef.current(parentNode);
        } else {
          parentKey = null;
        }
      }
      // eslint-disable-next-line no-param-reassign
      map[nodeKey] = parents;
      return map;
    }, {} as Dictionary<T>);
  }, [items]);

  const subtreeLookup = useMemo<Dictionary<Key>>(() => {
    const getAllChildren = (key: Key, cache: Dictionary<Key>): Array<Key> => {
      if (cache[key]) {
        return cache[key];
      }
      return (childrenLookup[key] || []).reduce((children, child) => {
        const childKey = getKeyRef.current(child);
        children.push(childKey, ...getAllChildren(childKey, cache));
        return children;
      }, [] as Array<Key>);
    };
    return items.reduce((lookup, node) => {
      const parentKey = getKeyRef.current(node);
      // eslint-disable-next-line no-param-reassign
      lookup[parentKey] = getAllChildren(parentKey, lookup);
      return lookup;
    }, {} as Dictionary<Key>);
  }, [items, childrenLookup]);

  const clear = useCallback<RecursiveSelectionState<T>['clear']>(() => {
    setSelected([]);
  }, []);

  const selectAll = useCallback<RecursiveSelectionState<T>['selectAll']>(() => {
    setSelected(allKeys);
  }, [allKeys]);

  const select = useCallback<RecursiveSelectionState<T>['select']>(
    (key: Key, selectEntireSubtree = false) => {
      setSelected((prevItems) => {
        const keysToAdd = [key];
        if (selectEntireSubtree) {
          const subtree = subtreeLookup[key] || [];
          keysToAdd.push(...subtree);
        }
        return [...prevItems, ...keysToAdd.filter((i) => !prevItems.includes(i))];
      });
    },
    [subtreeLookup],
  );

  const deselect = useCallback<RecursiveSelectionState<T>['deselect']>(
    (key: Key, deselectEntireSubtree = false) => {
      setSelected((prevSelected) => {
        const keysToRemove = [key];
        if (deselectEntireSubtree) {
          const subtree = subtreeLookup[key] || [];
          keysToRemove.push(...subtree);
        }
        return prevSelected.filter((childKey) => !keysToRemove.includes(childKey));
      });
    },
    [subtreeLookup],
  );

  const isSelected = useCallback<RecursiveSelectionState<T>['isSelected']>(
    (key: Key, checkEntireSubtree = false): boolean => {
      if (!checkEntireSubtree) {
        return selected.includes(key);
      }
      return selected.includes(key) && (subtreeLookup[key] || []).every((child) => selected.includes(child));
    },
    [selected, subtreeLookup],
  );

  const isPartiallySelected = useCallback<RecursiveSelectionState<T>['isPartiallySelected']>(
    (key: Key): boolean => {
      return !isSelected(key, true) && (subtreeLookup[key] || []).some((k) => selected.includes(k));
    },
    [isSelected, selected, subtreeLookup],
  );

  const getRootNodes = useCallback<RecursiveSelectionState<T>['getRootNodes']>(
    (ignoreParentId) => {
      return items.filter((node) => !getParentKeyRef.current(node) || getParentKeyRef.current(node) === ignoreParentId);
    },
    [items],
  );

  const getSubtree = useCallback<RecursiveSelectionState<T>['getSubtree']>(
    (key: Key) => {
      const subtreeNodeKeys = subtreeLookup[key] || [];
      return items.filter((item) => subtreeNodeKeys.includes(getKeyRef.current(item)));
    },
    [items, subtreeLookup],
  );

  const hasChildren = useCallback<RecursiveSelectionState<T>['hasChildren']>(
    (key: Key) => {
      return !!subtreeLookup[key]?.length;
    },
    [subtreeLookup],
  );

  const getKeys = useCallback<RecursiveSelectionState<T>['getKeys']>(() => {
    return [...allKeys];
  }, [allKeys]);

  const getChildren = useCallback<RecursiveSelectionState<T>['getChildren']>(
    (key: Key) => {
      return childrenLookup[key] || [];
    },
    [childrenLookup],
  );

  const getSiblings = useCallback<RecursiveSelectionState<T>['getSiblings']>(
    (key: Key) => {
      return siblingsLookup[key] || [];
    },
    [siblingsLookup],
  );

  const getParents = useCallback<RecursiveSelectionState<T>['getParents']>(
    (key: Key) => {
      return parentsLookup[key] || [];
    },
    [parentsLookup],
  );

  useEffect(() => {
    if (clearSelectionStateOnItemChangeRef.current) {
      clear();
    }
  }, [items, clear]);

  return useMemo<RecursiveSelectionState<T>>(
    () => ({
      clear,
      select,
      deselect,
      selectAll,
      deselectAll: clear,
      isSelected,
      isPartiallySelected,
      getRootNodes,
      getSubtree,
      hasChildren,
      getChildren,
      getSiblings,
      getParents,
      getKeys,
      itemCount: items.length,
      selectedCount: selected.length,
      allSelected: items.length > 0 && items.length === selected.length,
      someSelected: selected.length > 0 && selected.length < items.length,
      noneSelected: selected.length === 0,
      selectedItems: items.filter((item) => selected.includes(getKeyRef.current(item))),
    }),
    [
      clear,
      select,
      deselect,
      selectAll,
      isSelected,
      isPartiallySelected,
      getRootNodes,
      getSubtree,
      hasChildren,
      getChildren,
      getSiblings,
      getParents,
      getKeys,
      items,
      selected,
    ],
  );
}
