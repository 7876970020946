import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { ToolbarHeader } from '@/components/ToolbarHeader';
import { SortSelector } from '@/components/SortSelector';
import Container from '../../components/Container';
import ViewTypeSelector from '../../components/ViewTypeSelector';
import { ViewType } from '../../declarations/ViewType';
import { SortOption, SortType } from '../../declarations/models/SortOption';

interface EmployeesViewHeaderProps {
  count: number;
  totalCount: number;
  selectedViewType: ViewType;
  setSelectedViewType: (viewType: ViewType) => void;
  onQueryChange: (query: string) => void;
  query: string;
  sortOption: SortOption;
  setSortOption: (opt: SortOption) => void;
  onAddNewEmployee: () => void;
}

export const EmployeesViewHeader: FC<EmployeesViewHeaderProps> = ({
  onQueryChange,
  query,
  count,
  totalCount,
  selectedViewType,
  setSelectedViewType,
  sortOption,
  setSortOption,
  onAddNewEmployee,
}) => {
  const { t: tComp } = useTranslation('components');

  return (
    <>
      <ToolbarHeader
        heading={
          <Typography variant='h3' component='h1' fontSize={30}>
            {tComp('EmployeesView.Title')}
          </Typography>
        }
        topRight={
          <Button
            onClick={onAddNewEmployee}
            variant='contained'
            color='success'
            startIcon={<MaterialSymbol name='add' />}
            type='button'>
            {tComp('EmployeesView.AddEmployee')}
          </Button>
        }
        query={query}
        onQueryChange={onQueryChange}
      />
      <Container fullWidth spaceBetween p={2} sx={{ flexFlow: 'row wrap-reverse !important' }}>
        <SortSelector
          sortOption={sortOption}
          setSortOption={setSortOption}
          availableOptions={[SortType.LAST_NAME, SortType.UPDATED_AT]}
        />
        <Container right ml='auto' gap={2}>
          <Typography variant='caption'>{tComp('EmployeesView.HeaderCount', { count, total: totalCount })}</Typography>
          <ViewTypeSelector value={selectedViewType} onChange={setSelectedViewType} />
        </Container>
      </Container>
    </>
  );
};

export default EmployeesViewHeader;
