import React, { FC } from 'react';
import { Page } from '../../../declarations/models/Page';
import { useEditorModel } from '../../lib/components/EditorDataProvider';
import EditorPreviewContainer from '../../lib/components/EditorPreviewContainer';

export const PreviewWrapper: FC = () => {
  const model = useEditorModel<Page>();
  return (
    <>
      <EditorPreviewContainer portalUrl={model?.url ? `//${model.url}` : ''} />
    </>
  );
};

export default PreviewWrapper;
