/* istanbul ignore file */
/* eslint-disable max-classes-per-file */

abstract class Colors {
  public static readonly WHITE: string = '#FFF';

  public static readonly LIGHTEST_GREY: string = '#F4F5F7';

  public static readonly LIGHT_GREY: string = '#EBEEF0';

  public static readonly MEDIUM_LIGHT_GREY: string = '#D1D6DB';

  public static readonly MEDIUM_GREY: string = '#B8BFC6';

  public static readonly MEDIUM_DARK_GREY: string = '#858D94';

  public static readonly DARK_GREY: string = '#3C3D3E';

  public static readonly LIGHTEST_BLUE: string = '#e0f2ff';

  public static readonly LIGHT_BLUE: string = '#A4E1FF';

  public static readonly MEDIUM_BLUE: string = '#008ED6';

  public static readonly LIGHT_RED: string = '#FFC5CB';

  public static readonly MEDIUM_RED: string = '#EC5C6B';

  public static readonly LIGHT_GREEN: string = '#BBFFBF';

  public static readonly MEDIUM_GREEN: string = '#6DA570';

  public static readonly LIGHT_YELLOW: string = '#FFE7B0';

  public static readonly MEDIUM_YELLOW: string = '#F2C664';

  public static readonly ERROR_BG = Colors.LIGHT_RED;

  public static readonly ERROR_BORDER = Colors.MEDIUM_RED;

  public static readonly SUCCESS_BG = Colors.LIGHT_GREEN;

  public static readonly SUCCESS_BORDER = Colors.MEDIUM_GREEN;

  public static readonly WARNING_BG = Colors.LIGHT_YELLOW;

  public static readonly WARNING_BORDER = Colors.MEDIUM_YELLOW;

  public static readonly INFO_BG = Colors.LIGHT_BLUE;

  public static readonly INFO_BORDER = Colors.MEDIUM_BLUE;

  public static readonly BLACK: string = '#000';

  public static readonly BLOCK_SETTINGS = Colors.LIGHTEST_BLUE;

  public static readonly BLOCK: string = '#efefef';

  public static readonly STRONG_GREEN_TRANSPARENT: string = '#07A31130';

  public static readonly MEDIUM_GREEN_TRANSPARENT: string = '#6DA57030';

  public static readonly STRONG_GREEN: string = '#0E842B';

  public static readonly STRONG_ORANGE: string = '#f57c00';

  public static readonly STRONG_RED: string = '#d32f2f';

  public static readonly THEME_PRIMARY: string = Colors.WHITE;

  public static readonly THEME_PRIMARY_BG_HOVER: string = Colors.LIGHT_GREY;

  public static readonly THEME_SECONDARY: string = Colors.BLACK;

  public static readonly THEME_SECONDARY_BG_HOVER: string = Colors.DARK_GREY;

  public static readonly THEME_BG_COLOR: string = Colors.WHITE;

  public static readonly THEME_BG_COLOR_SECONDARY: string = Colors.LIGHTEST_GREY;

  public static readonly PREVIEW_BG_COLOR: string = Colors.MEDIUM_GREY;

  public static readonly BLOCK_ITEMS_BACKGROUND_COLORS: string = Colors.LIGHT_GREY;

  public static readonly FIXED_SECTION_BACKGROUND_COLOR: string = Colors.LIGHT_GREY;

  public static readonly NESTED_SECTION_BORDER_COLOR: string = Colors.LIGHTEST_GREY;

  public static readonly FINDER_BUTTON_BG_COLOR: string = Colors.BLACK;

  public static readonly MEDIA_ITEM_SELECTED_COLOR: string = Colors.STRONG_GREEN;

  public static readonly FINDER_M24_PRIMARY_HEADER_BACKGROUND: string = Colors.LIGHT_GREY;

  public static readonly FINDER_M24_SECONDARY_HEADER_BACKGROUND: string = Colors.DARK_GREY;

  public static readonly FINDER_DM_PRIMARY_HEADER_BACKGROUND: string = Colors.LIGHT_GREY;

  public static readonly FINDER_DM_SECONDARY_HEADER_BACKGROUND = Colors.MEDIUM_LIGHT_GREY;

  public static readonly DROP_INDICATOR_COLOR: string = Colors.STRONG_GREEN;

  public static readonly MENU_ITEM_SELECTED_BACKGROUND_COLOR: string = Colors.LIGHT_GREY;

  public static readonly MENU_ICON_INVERTED_BACKGROUND_COLOR: string = Colors.MEDIUM_LIGHT_GREY;

  public static readonly MENU_ICON_INVERTED_COLOR: string = Colors.LIGHT_GREY;

  public static readonly SIZE_SELECTOR_DARK_GREY: string = Colors.MEDIUM_GREY;

  public static readonly SIZE_SELECTOR_MEDIUM_DARK_GREY: string = Colors.MEDIUM_LIGHT_GREY;

  public static readonly SIZE_SELECTOR_MEDIUM_GREY: string = '#b0b0b0';

  public static readonly SIZE_SELECTOR_MEDIUM_LIGHT_GREY: string = '#c6c6c6';

  public static readonly SIZE_SELECTOR_LIGHT_GREY: string = '#e1e1e1';

  public static readonly SIZE_SELECTOR_LIGHTEST_GREY: string = Colors.LIGHT_GREY;

  public static readonly SIZE_SELECTOR_BG_HOVER: string = '#0077b082';

  public static readonly LINK_COLOR: string = '#0288d1';

  public static readonly LINK_COLOR_VISITED: string = '#ab47bc';

  public static readonly SUCCESS: string = Colors.STRONG_GREEN;

  public static readonly WARNING: string = Colors.STRONG_ORANGE;

  public static readonly ERROR: string = Colors.STRONG_RED;

  public static readonly KP_ARTICLE: string = '#e6ebf1';

  public static readonly KP_PRES: string = '#e0f5ff';

  public static readonly KP_OWNER: string = '#ffeeb5';

  public static readonly COPY_PASTE_PURPLE_LIGHT: string = '#d3bcd7';

  public static readonly COPY_PASTE_PURPLE_LIGHTEST: string = '#e9deed';
}

abstract class Dimensions {
  public static readonly DASHBOARD_PANE_MIN_HEIGHT: string = '240px';

  public static readonly DASHBOARD_PANE_MIN_WIDTH: string = '300px';

  public static readonly SECTION_BORDER_RADIUS: string = '2px';

  public static readonly NESTED_SECTION_BORDER_RADIUS: string = '0px';

  public static readonly RADIUS_NONE = '0px';

  public static readonly RADIUS_ROUND_SM = '2px';

  public static readonly RADIUS_ROUND_MD = '4px';

  public static readonly RADIUS_ROUND_LG = '16px';

  public static readonly RADIUS_ROUND_XL = '32px';

  public static readonly RADIUS_ROUND_FULL = '50%';

  public static readonly RADIUS_ROUNDNESS_DEFAULT = Dimensions.RADIUS_ROUND_SM;
}

export default {
  Colors,
  Dimensions,
};
