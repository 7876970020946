import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import Container from '../../../components/Container';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const DefaultEditorBlock: BlockComponent = () => {
  const { t } = useTranslation('common');
  const { blockType } = useCurrentBlock();

  const blockName = t(`BlockType.${blockType}`);

  return (
    <Container pl={4} top left column fullWidth>
      <Typography variant='h6' component='span'>
        {blockName}
      </Typography>
      <Typography variant='subtitle1' color='textSecondary'>
        The &quot;{blockName}&quot; block has not yet been implemented.
      </Typography>
    </Container>
  );
};

export default DefaultEditorBlock;
