import React, { FC, Fragment, useMemo } from 'react';
import Container from '@/components/Container';
import SelectInput from '@/components/forms/SelectInput';
import Styles from '@/assets/js/Styles';
import FinderButton, { FinderType } from '@/components/Finder/FinderButton';
import { GenericMedia } from '@/declarations/GenericMedia';
import { M24MediaModel } from '@/declarations/models/M24MediaModel';
import { DMMediaModel } from '@/declarations/models/DMMediaModel';
import { Checkbox, Collapse, FormControlLabel, SxProps, Typography, Radio, RadioGroup } from '@mui/material';
import { MediaViewType } from '@/declarations/models/blocks/MediaViewType';
import DropIndicatorArrow from '@/components/DropIndicatorArrow';
import MediaBlockItem from '@/editor/PageEditor/EditorBlock/MediaBlock/MediaBlockItem';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import MediaOptionContainer from '@/editor/PageEditor/EditorBlock/MediaBlock/MediaOptionContainer';
import CheckboxInput from '@/components/forms/CheckboxInput';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { MediaBlock as MediaBlockModel } from '@/declarations/models/blocks/MediaBlock';
import { useWatch, useController } from 'react-hook-form';
import { useKeyGenerator } from '@/hooks/useKeyGenerator';
import { ExternalMediaModel } from '@/declarations/models/ExternalMediaModel';
import { isM24MediaModel } from '@/utils/typeChecks';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BlockPath } from '@/editor/PageEditor/CurrentBlockProvider';
import {
  getVimeoPlaybackUrl,
  getVimeoVideoIdFromUrl,
  getYouTubePlaybackUrl,
  getYouTubeVideoIdFromUrl,
} from '@/utils/url';
import { useTranslation } from 'react-i18next';
import { BlockItemPathPrefix } from '@/declarations/models/BlockItemPathPrefix';
import { mergeSx } from '@/utils/mergeSx';

type MediaItemsBlockPath = `${BlockPath}.items`;
type MediaItemsViewPath = `${BlockPath}.view`;

export interface CommonMediaListProps {
  blockPath: BlockPath;
  listName?: 'items' | 'mediaitems';
  siteId?: number;
  sx?: SxProps;
}

// FIXME: the list is in use by both MediaBlock and BannerBlock, but the types are not compatible
//  This components types assumes MediaBlock is being used, but BannerBlock is using it as well
export const CommonMediaList: FC<CommonMediaListProps> = ({ blockPath, listName = 'items', siteId, sx }) => {
  const { t } = useTranslation('components');

  const gridSizeOptions = ['auto', '2', '3', '4', '5', '6', '7', '8'];

  const getMediaItemKey = useKeyGenerator<M24MediaModel | ExternalMediaModel>((item): string => {
    if (isM24MediaModel(item)) {
      return String(item.id || item.dms_id || item.filename || item.url);
    }
    if ('source' in item && item.source === 'digitaltMuseum') {
      return String(
        item.id || item.dimuId || item.dmsId || item.objectId || item.local?.dmsId || item.url || item.local?.anySource,
      );
    }
    return String(item.local?.vimeoId || item.local?.youtubeId || item.local?.anySource);
  });

  const {
    fields: selectedMediaItems,
    append,
    insert,
    move,
    remove,
    update,
  } = useControlledFieldArray<BlockSpecificPage<MediaBlockModel>, MediaItemsBlockPath, 'itemHash'>({
    name: `${blockPath}.${listName}` as MediaItemsBlockPath,
  });

  const currentMediaViewType: MediaViewType = useWatch<BlockSpecificPage<MediaBlockModel>, MediaItemsViewPath>({
    name: `${blockPath}.view`,
  });

  const {
    field: { value: layout, onChange: onChangeLayout },
  } = useController<BlockSpecificPage<MediaBlockModel>, `${BlockPath}.layout`>({
    name: `${blockPath}.layout`,
    defaultValue: 'auto',
  });

  const mediaViewTypeIsOneOf = (...mediaTypes: Array<MediaViewType>) => {
    return mediaTypes.includes(currentMediaViewType);
  };

  const { allItemsHasCaptionsDisabled, someItemsHasCaptionsDisabled } = useMemo(() => {
    const hasCaptionsDisabled = (item: M24MediaModel | ExternalMediaModel) => item?.local?.disableCaption === true;
    const allDisabled = selectedMediaItems.every(hasCaptionsDisabled);
    return {
      allItemsHasCaptionsDisabled: allDisabled,
      someItemsHasCaptionsDisabled: !allDisabled && selectedMediaItems.some(hasCaptionsDisabled),
    };
  }, [selectedMediaItems]);

  const handleToggleCaption = (checked: boolean) => {
    selectedMediaItems.forEach((item, i) => {
      update(i, {
        ...item,
        local: {
          ...(item.local || {}),
          disableCaption: !checked,
        },
      } as typeof item);
    });
  };

  const handleAddM24Model = (item: M24MediaModel | null) => {
    if (item) {
      append(item as M24MediaModel);
    }
  };

  const handleAddDMModel = (item: DMMediaModel | null, url: string) => {
    if (item && url) {
      append({
        dimuId: item['artifact.uniqueId'],
        dmsId: item['artifact.defaultMediaIdentifier'],
        id: item['artifact.defaultMediaIdentifier'],
        item,
        objectId: item['identifier.id'],
        source: 'digitaltMuseum',
        type: 'external_url',
        url,
        local: {
          anySource: url,
          dmsId: item['artifact.defaultMediaIdentifier'],
          title: item['artifact.ingress.title'],
          sourceUrl: url,
          unknownSource: false,
          vimeoId: false,
          youtubeId: false,
          _editmode: true,
        },
      } as ExternalMediaModel);
    }
  };

  const handleAddVimeoMedia = (vimeoVideoId: string | null, url: string) => {
    if (vimeoVideoId !== null) {
      const anySource: string = getVimeoPlaybackUrl(vimeoVideoId) || url;
      append({
        local: {
          anySource,
          youtubeId: false,
          vimeoId: vimeoVideoId,
          unknownSource: false,
        },
        type: 'external_url',
      } as ExternalMediaModel);
    }
  };

  const handleAddYouTubeMedia = (youTubeVideoId: string | null, url: string) => {
    if (youTubeVideoId !== null) {
      const anySource: string = getYouTubePlaybackUrl(youTubeVideoId) || url;
      append({
        local: {
          anySource,
          youtubeId: youTubeVideoId,
          vimeoId: false,
          unknownSource: false,
        },
        type: 'external_url',
      } as ExternalMediaModel);
    }
  };

  // TODO: Remove ignore:
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddExternalMedia = (url: string) => {
    // TODO: Handle add external media
    if (url) {
      const vimeoVideoId = getVimeoVideoIdFromUrl(url);
      if (vimeoVideoId) {
        handleAddVimeoMedia(vimeoVideoId, url);
        return;
      }
      const youTubeVideoId = getYouTubeVideoIdFromUrl(url);
      if (youTubeVideoId) {
        handleAddYouTubeMedia(youTubeVideoId, url);
        return;
      }
      append({
        local: {
          anySource: url,
          youtubeId: false,
          vimeoId: false,
          unknownSource: true,
        },
        type: 'external_url',
      } as ExternalMediaModel);
    }
  };

  const renderToggleCaptionsOption = (label: string) => (
    <FormControlLabel
      sx={{ width: '100%' }}
      control={
        <Checkbox
          indeterminate={someItemsHasCaptionsDisabled}
          checked={!allItemsHasCaptionsDisabled}
          onChange={(event) => handleToggleCaption(event.target.checked)}
        />
      }
      label={label}
    />
  );

  return (
    <Container gap={0} column top left fullWidth>
      <Container
        p={2}
        mt={2}
        gap={0}
        column
        top
        left
        fullWidth
        sx={mergeSx({ backgroundColor: Styles.Colors.BLOCK_ITEMS_BACKGROUND_COLORS }, sx ?? {})}>
        <Container pb={2} fullWidth left>
          <FinderButton
            type={FinderType.M24}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
                items.forEach((item) => handleAddM24Model(item.source)),
            }}
          />
          <FinderButton
            type={FinderType.DM}
            finderProps={{
              onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
                items.forEach((item) => handleAddDMModel(item.source, item.url)),
            }}
          />
        </Container>

        {(selectedMediaItems || []).map((item, i) => (
          <Fragment key={getMediaItemKey(item)}>
            <Collapse in={mediaViewTypeIsOneOf(MediaViewType.COMPARE_TWO_IMAGES) && i % 2 === 0} sx={{ width: '100%' }}>
              <DropIndicatorArrow
                borderStyle='dashed'
                label={t('MediaBlock.CompareTwoImages')}
                typographyProps={{
                  variant: 'caption',
                  lineHeight: '1rem',
                  fontStyle: 'italic',
                }}
                spacing={2}
                visible
                noArrow
              />
            </Collapse>
            <MediaBlockItem
              index={i}
              // TODO: 30/11/2023 better typing
              itemPathPrefix={`${blockPath}.${listName}.${i}` as BlockItemPathPrefix}
              onReorder={move}
              onAdd={(itemToAdd, addAtIndex) => itemToAdd && insert(addAtIndex, itemToAdd)}
              onRemove={remove}
              siteId={siteId}
              displayGridSelector={mediaViewTypeIsOneOf(
                MediaViewType.GRID,
                MediaViewType.GRID_WIDE,
                MediaViewType.GRID_FULL,
                MediaViewType.GRID_NARROW,
              )}
            />
          </Fragment>
        ))}

        <Collapse
          in={mediaViewTypeIsOneOf(MediaViewType.COMPARE_TWO_IMAGES) && (selectedMediaItems?.length || 0) % 2 !== 0}
          sx={{ width: '100%' }}>
          <Container
            sx={(theme) => ({
              border: `2px solid ${theme.palette.warning.main}`,
              borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
              py: 3,
              px: 4,
              my: 1,
            })}
            fullWidth>
            <MaterialSymbol name='error' fill color='warning' />
            <Typography variant='caption' fontStyle='italic' lineHeight='1rem'>
              {t('MediaBlock.MissingImageToCompare')}
            </Typography>
          </Container>
        </Collapse>
      </Container>
      {mediaViewTypeIsOneOf(MediaViewType.LIST, MediaViewType.LIST_WIDE, MediaViewType.LIST_FULL) &&
        selectedMediaItems?.length > 0 && (
          <MediaOptionContainer>
            <CheckboxInput<BlockSpecificPage<MediaBlockModel>>
              path={`${blockPath}.disableZoom`}
              fullWidth={false}
              label={t('MediaBlock.EnableZoomButton')}
              invert
            />
          </MediaOptionContainer>
        )}
      {mediaViewTypeIsOneOf(
        MediaViewType.GRID_NARROW,
        MediaViewType.GRID,
        MediaViewType.GRID_WIDE,
        MediaViewType.GRID_FULL,
        MediaViewType.GRID_PACKERY_NARROW,
        MediaViewType.GRID_PACKERY,
        MediaViewType.GRID_PACKERY_WIDE,
        MediaViewType.GRID_PACKERY_FULL,
      ) && (
        <MediaOptionContainer column>
          <CheckboxInput<BlockSpecificPage<MediaBlockModel>>
            path={`${blockPath}.displayCaptionsOnThumbnails`}
            label={t('MediaBlock.ShowCaptionsUnderThumbnails')}
            fullWidth={false}
          />

          <Container fullWidth column left>
            <RadioGroup value={layout} onChange={(_, v) => onChangeLayout(v)} row>
              <FormControlLabel control={<Radio value='auto' />} label={t('MediaBlock.Layout.Auto')} />
              <FormControlLabel control={<Radio value='frame' />} label={t('MediaBlock.Layout.Frame')} />
            </RadioGroup>
          </Container>

          {mediaViewTypeIsOneOf(MediaViewType.GRID, MediaViewType.GRID_WIDE, MediaViewType.GRID_FULL) && (
            <Container fullWidth left gap={2} mt={2} mb={2}>
              <SelectInput
                hideNoSelectionOption
                defaultValue='auto'
                options={gridSizeOptions}
                getOptionKey={(gridSize) => `size-${gridSize}`}
                getOptionLabel={(gridSize) => `${gridSize}`}
                path={`${blockPath}.gridSize`}
                label={t('CardModuleBlock.GridSizeLabel')}
              />
            </Container>
          )}
        </MediaOptionContainer>
      )}

      {mediaViewTypeIsOneOf(MediaViewType.SLIDESHOW, MediaViewType.SLIDESHOW_WIDE, MediaViewType.SLIDESHOW_FULL) && (
        <MediaOptionContainer column>
          {renderToggleCaptionsOption(t('MediaBlock.ShowCaptions'))}

          <Container fullWidth column left>
            <RadioGroup value={layout} onChange={(_, v) => onChangeLayout(v)} row>
              <FormControlLabel control={<Radio value='auto' />} label={t('MediaBlock.Layout.Auto')} />
              <FormControlLabel control={<Radio value='frame' />} label={t('MediaBlock.Layout.Frame')} />
            </RadioGroup>
          </Container>
        </MediaOptionContainer>
      )}
    </Container>
  );
};
