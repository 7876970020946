import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useStore } from '@/components/store/Store';
import { Api } from '@/services/Api';
import { User } from '@/declarations/models/User';

interface HeadCell {
  id: keyof User | 'role' | 'noAccess' | 'uuid';
  label: string;
  isSuperAdmin?: boolean;
}

interface SiteUsersSettingsListProps {
  users: User[];
  onToggleSuccess?: (message: string) => void;
  onToggleError?: (message: string) => void;
}

export const SiteUsersSettingsList: FC<SiteUsersSettingsListProps> = ({ users, onToggleSuccess, onToggleError }) => {
  const { state } = useStore();
  const { currentUser } = state;
  const { t: tComponents } = useTranslation('components');
  const selectedSiteId = state.selectedSite?.id ?? 0;
  const siblingCount = state.selectedSiteData?.sibling_count ?? 0;

  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({});
  const [loadingSwitches, setLoadingSwitches] = useState<{ [key: string]: boolean }>({});

  const getUserKey = (user: User): string => user.uuid || user.email || user.name || '';

  // Initialize switchStates based on users prop
  useEffect(() => {
    if (Array.isArray(users)) {
      const initialSwitchStates = users.reduce<{ [key: string]: boolean }>((acc, user) => {
        const key = getUserKey(user);
        if (key) {
          acc[key] = user.is_noAccess ?? false;
        }
        return acc;
      }, {});

      setSwitchStates(initialSwitchStates);
    }
  }, [users]);

  // Define head cells, conditionally including 'noAccess' based on siblingCount
  const headCells: HeadCell[] = [
    { id: 'name', label: tComponents('SiteUsersSettings.name') },
    { id: 'email', label: tComponents('SiteUsersSettings.email') },
    { id: 'uuid', label: 'UUID', isSuperAdmin: true },
    { id: 'role', label: `eKultur ${tComponents('SiteUsersSettings.role')}` },
    ...(siblingCount > 1
      ? [
          {
            id: 'noAccess' as const, // Explicitly cast id to 'noAccess'
            label: 'No Access',
          },
        ]
      : []),
  ];

  const handleSwitchToggle = async (user: User, newState: boolean) => {
    const identifier = getUserKey(user);
    if (!identifier) {
      console.warn(`Cannot toggle access for user without identifier: ${user.name}`);
      return;
    }

    // Store the previous state before making any changes
    const previousState = switchStates[identifier];

    // Optimistic UI Update
    setSwitchStates((prevState) => ({ ...prevState, [identifier]: newState }));
    setLoadingSwitches((prev) => ({ ...prev, [identifier]: true }));

    try {
      // Make the API call to set the new state
      const [, error] = await Api.setNoAccess(identifier, selectedSiteId, newState).fetch();

      // Handle errors if any
      if (error) {
        const errorMessage = 'Failed to update access';
        throw new Error(errorMessage);
      }

      if (onToggleSuccess) {
        onToggleSuccess(`Successfully updated access for ${user.email || user.name}.`);
      }
    } catch (error) {
      // Revert to the previous state in case of an error
      setSwitchStates((prevState) => ({ ...prevState, [identifier]: previousState }));

      if (onToggleError) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
        onToggleError(errorMessage);
      }
    } finally {
      // Remove loading state
      setLoadingSwitches((prev) => ({ ...prev, [identifier]: false }));
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='Site Users Settings Table'>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => {
              // Hide columns based on super admin status
              if (headCell.isSuperAdmin && !currentUser?.user?.is_super_admin) {
                return null;
              }
              return (
                <TableCell
                  key={headCell.id}
                  align={
                    headCell.id === 'email' ||
                    headCell.id === 'uuid' ||
                    headCell.id === 'role' ||
                    headCell.id === 'noAccess'
                      ? 'center'
                      : 'left'
                  }>
                  {headCell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => {
            const key = getUserKey(user);
            return (
              <TableRow key={key}>
                <TableCell component='th' scope='row' align='left'>
                  {user.name}
                </TableCell>
                <TableCell align='center'>{user.email}</TableCell>
                {currentUser?.user?.is_super_admin && <TableCell align='center'>{user.uuid}</TableCell>}
                <TableCell align='center'>{user.role}</TableCell>
                {siblingCount > 1 && (
                  <TableCell align='center'>
                    <Switch
                      checked={switchStates[key] || false}
                      onChange={(e) => handleSwitchToggle(user, e.target.checked)}
                      color='secondary'
                      inputProps={{ 'aria-label': `No Access Switch for ${user.email || user.name}` }}
                      disabled={loadingSwitches[key] || user.uuid === currentUser?.user?.cura_uuid}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
