import React, { FC, ReactNode } from 'react';
import { ClickAwayListener, Fade, Modal, SxProps } from '@mui/material';
import Container from './Container';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface MultiModalProps extends ChildrenProp {
  open: boolean;
  onClose?: () => void;
  secondaryContent?: ReactNode;
  sx?: SxProps;
}

export const MultiModal: FC<MultiModalProps> = ({ children, open, onClose, secondaryContent, sx = {} }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        ...sx,
      }}>
      <Fade in={open}>
        <Container p={4} fullWidth fullHeight>
          <ClickAwayListener onClickAway={() => onClose?.()} mouseEvent='onMouseUp'>
            <Container fullHeight fullWidth spaceBetween gap={2}>
              <Container sx={{ flex: '1 0 auto', position: 'relative' }} fullHeight>
                {children}
              </Container>
              {secondaryContent && (
                <Container sx={{ flex: '0 1 auto', position: 'relative' }} fullHeight>
                  {secondaryContent}
                </Container>
              )}
            </Container>
          </ClickAwayListener>
        </Container>
      </Fade>
    </Modal>
  );
};

export default MultiModal;
