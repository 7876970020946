import React, { FC, MouseEvent, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@/assets/js/Styles';
import Image, { ImageProps } from './Image';
import Container from './Container';

export interface ImageWithFocusPointSelectorProps extends Pick<ImageProps, 'disabled' | 'alt'> {
  src: string;
  title?: string;
  mimetype?: string;
  x?: string;
  y?: string;
  maxWidth?: string;
  onFocusPointChanged: (focusPoint: { x: string; y: string }) => void;
}

export const ImageWithFocusPointSelector: FC<ImageWithFocusPointSelectorProps> = ({
  src,
  title = '',
  mimetype = '',
  alt = '',
  x = '0',
  y = '0',
  maxWidth = '200px',
  onFocusPointChanged,
  disabled = false,
}) => {
  const { t } = useTranslation('components');
  const imageElement = useRef<HTMLImageElement | null>(null);

  const handleFocusPointChanged = (e: MouseEvent<HTMLDivElement>) => {
    if (disabled || !imageElement.current || e.target !== imageElement.current) {
      return;
    }
    const image = imageElement.current.getBoundingClientRect();
    const coordinatesOfClick = {
      x: e.clientX - (image.x + image.width / 2),
      y: e.clientY - (image.y + image.height / 2),
    };
    const coordinatesAsPercentage = {
      x: (coordinatesOfClick.x / image.width) * 2,
      y: (coordinatesOfClick.y / image.height) * 2,
    };
    const focusPoint = {
      x: coordinatesAsPercentage.x.toFixed(2),
      y: (-coordinatesAsPercentage.y).toFixed(2),
    };
    onFocusPointChanged(focusPoint);
  };

  let imgMimeType = '';
  if (mimetype?.includes('png')) {
    imgMimeType = `mediaType=${mimetype}`;
  }

  return (
    <Container sx={{ maxWidth, height: '100%' }} column left>
      <Container
        column
        sx={{
          position: 'relative',
          maxWidth,
          maxHeight: '100%',
          overflow: 'hidden',
          transformOrigin: '0 0',
          transition: 'all 100ms ease-in-out',
          m: 0,
          p: 0,
          '&:hover': {
            cursor: disabled ? 'not-allowed' : 'crosshair',
          },
        }}
        fullWidth>
        <Image
          ref={imageElement}
          src={`${src}?${imgMimeType}&dimension=250x250`}
          role='button'
          aria-label={t('ImageWithFocusPointSelector.SelectFocusPoint', { imageName: title || 'image' })}
          title={title}
          alt={alt}
          sx={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
          onClick={handleFocusPointChanged}
          disabled={disabled}
        />

        <Box
          role='presentation'
          sx={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            top: `${50 + Number(-y) * 50}%`,
            left: `${50 + Number(x) * 50}%`,
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFF4',
            border: '1px solid #FFF',
            outline: '1px solid #000',
            borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
            transition: 'all 100ms ease-out',
            pointerEvents: 'none',
          }}>
          <Box
            sx={{
              width: '5px',
              height: '5px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFF',
              border: '1px solid #000',
              borderRadius: Styles.Dimensions.RADIUS_ROUND_FULL,
            }}
          />
        </Box>
      </Container>
      {!disabled && (
        <Typography px={1} variant='caption' sx={{ lineHeight: '1.25' }}>
          {t('ImageWithFocusPointSelector.SelectFocusPointDescription')}
        </Typography>
      )}
    </Container>
  );
};

export default ImageWithFocusPointSelector;
