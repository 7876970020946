import React, { FC, ReactElement, useState } from 'react';
import { ButtonBase, Paper, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import CustomIcon from '@/components/CustomIcon';
import { IconFinder, IconFinderProps } from '@/components/Finder/IconFinder/IconFinder';
import Container from '../Container';
import Styles from '../../assets/js/Styles';
import { M24MediaFinder, M24MediaFinderProps } from './M24MediaFinder/M24MediaFinder';
import { DMMediaFinder, DMMediaFinderProps } from './DMMediaFinder/DMMediaFinder';
import DMLogo from '../icons/DMLogo';
import { CardFinder, CardFinderProps } from './CardFinder/CardFinder';
import { MediaResourceType } from '../../declarations/models/MediaResourceType';
import { mergeSx } from '../../utils/mergeSx';
import { EmployeeFinder, EmployeeFinderProps } from './EmployeeFinder/EmployeeFinder';
import { KPObjectFinder, KPObjectFinderProps } from './KPObjectFinder/KPObjectFinder';
import KPLogo from '../icons/KPLogo';
import { KPOwnerFinder, KPOwnerFinderProps } from './KPOwnerFinder/KPOwnerFinder';
import { FormFinder, FormFinderProps } from './FormFinder/FormFinder';

export enum FinderType {
  M24 = 'm24',
  DM = 'digitaltMuseum',
  CARD = 'card',
  EMPLOYEE = 'employee',
  KP = 'kp',
  KP_OWNER = 'kp_owner',
  FORM = 'form',
  ICON = 'icon',
}

type FinderButton = {
  multiSelect?: boolean;
  sx?: SxProps;
  hideIcon?: boolean;
  overrideIcon?: ReactElement | false;
  overrideText?: string;
};

interface M24FinderButton extends FinderButton {
  type: FinderType.M24;
  finderProps: Omit<M24MediaFinderProps, 'open' | 'onClose'>;
}

interface DMFinderButton extends FinderButton {
  type: FinderType.DM;
  finderProps: Omit<DMMediaFinderProps, 'open' | 'onClose'>;
}

interface CardFinderButton extends FinderButton {
  type: FinderType.CARD;
  finderProps: Omit<CardFinderProps, 'open' | 'onClose'>;
}

interface EmployeeFinderButton extends FinderButton {
  type: FinderType.EMPLOYEE;
  finderProps: Omit<EmployeeFinderProps, 'open' | 'onClose'>;
}

interface KPObjectFinderButton extends FinderButton {
  type: FinderType.KP;
  finderProps: Omit<KPObjectFinderProps, 'open' | 'onClose'>;
}

interface KPOwnerFinderButton extends FinderButton {
  type: FinderType.KP_OWNER;
  finderProps: Omit<KPOwnerFinderProps, 'open' | 'onClose'>;
}

interface FormFinderButton extends FinderButton {
  type: FinderType.FORM;
  finderProps: Omit<FormFinderProps, 'open' | 'onClose'>;
}

interface IconFinderButton extends FinderButton {
  type: FinderType.ICON;
  finderProps: Omit<IconFinderProps, 'open' | 'onClose'>;
}

export type FinderButtonProps =
  | M24FinderButton
  | DMFinderButton
  | CardFinderButton
  | EmployeeFinderButton
  | KPObjectFinderButton
  | KPOwnerFinderButton
  | FormFinderButton
  | IconFinderButton;

export const FinderButton: FC<FinderButtonProps> = ({
  type,
  finderProps,
  multiSelect,
  sx,
  hideIcon = false,
  overrideIcon,
  overrideText,
}) => {
  const { t } = useTranslation('components');
  const [open, setOpen] = useState<boolean>(false);

  const mergedSx = mergeSx(
    (theme) => ({
      backgroundColor: Styles.Colors.FINDER_BUTTON_BG_COLOR,
      color: theme.palette.getContrastText(Styles.Colors.FINDER_BUTTON_BG_COLOR),
      height: '48px',
      width: '100%',
      overflow: 'hidden',
    }),
    sx || {},
  );

  const close = () => setOpen(false);

  const getFinderIcon = () => {
    switch (type) {
      case FinderType.DM:
        return <DMLogo height='20px' width='39px' />;
      case FinderType.CARD:
        return <CustomIcon name='card' />;
      case FinderType.EMPLOYEE:
        return <MaterialSymbol name='person_add' />;
      case FinderType.KP:
        return <KPLogo height='20px' width='18px' />;
      case FinderType.KP_OWNER:
        return <MaterialSymbol name='museum' />;
      case FinderType.FORM:
        return <MaterialSymbol name='assignment' />;
      case FinderType.ICON:
        return <MaterialSymbol name='insert_emoticon' />;
      default:
        if (finderProps?.selectableResourceTypes?.length === 2) {
          switch (finderProps.selectableResourceTypes[0]) {
            case MediaResourceType.DOCUMENT:
              return <MaterialSymbol name='picture_as_pdf' />;
          }
        }
        return <MaterialSymbol name='perm_media' fill />;
    }
  };

  return (
    <>
      <Paper sx={mergedSx}>
        <ButtonBase
          sx={(theme) => ({
            width: '100%',
            height: '100%',
            py: 1,
            px: 2,
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            '&:hover, &:focus, &:focus-visible, &:focus-within': {
              boxShadow: `0 0 3px 2px ${theme.palette.primary.main}`,
            },
          })}
          onClick={() => setOpen(true)}>
          <Container gap={2} fullWidth fullHeight>
            {!hideIcon && (
              <Container gap={0} column fullHeight>
                {overrideIcon || getFinderIcon()}
              </Container>
            )}
            <Typography
              variant='caption'
              component='span'
              sx={{ wordBreak: 'break-word', textAlign: `${hideIcon ? 'center' : 'left'}` }}>
              {overrideText || t(`FinderButton.${type}`)}
            </Typography>
          </Container>
        </ButtonBase>
      </Paper>
      {type === FinderType.M24 && (
        <M24MediaFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.DM && (
        <DMMediaFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.CARD && (
        <CardFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.EMPLOYEE && (
        <EmployeeFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.KP && (
        <KPObjectFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.KP_OWNER && (
        <KPOwnerFinder open={open} onClose={close} {...finderProps} multiSelect={multiSelect} />
      )}
      {type === FinderType.FORM && <FormFinder open={open} onClose={close} {...finderProps} />}
      {type === FinderType.ICON && <IconFinder open={open} onClose={close} {...finderProps} />}
    </>
  );
};

export default FinderButton;
