import React, { FC, ReactNode } from 'react';
import { styled, Typography } from '@mui/material';
import { useM24PageFinderContentView } from '../M24PageFinder';
import { Page } from '../../../../declarations/models/Page';

export interface PageTreeLabelProps {
  page: Page;
}

const Highlight = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.getContrastText(theme.palette.secondary.light),
}));

export const M24PageFinderPageTreeLabel: FC<PageTreeLabelProps> = ({ page }) => {
  const { filterValues } = useM24PageFinderContentView();

  const query = String(filterValues.query || '')
    .trim()
    .toLowerCase();
  const label: string = String(page.title || '').trim();
  const labelLC = label.toLowerCase();

  let highlightedLabel: Array<ReactNode> | null = null;

  if (query && label && labelLC.includes(query)) {
    const startIdx = labelLC.indexOf(query);
    const endIdx = startIdx + query.length;
    highlightedLabel = [
      startIdx > 0 && label.substring(0, startIdx),
      <Highlight key={label}>{label.substring(startIdx, endIdx)}</Highlight>,
      endIdx < label.length && label.substring(endIdx),
    ];
  }

  return (
    <Typography variant='subtitle2' component='span' noWrap>
      {highlightedLabel || label}
    </Typography>
  );
};

export default M24PageFinderPageTreeLabel;
