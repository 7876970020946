import React, { FC } from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { GenericMedia } from '@/declarations/GenericMedia';
import Container from '../../Container';
import FavouriteButton from '../../FavouriteButton';
import SelectButton from '../../SelectButton';
import PlaceholderImage from '../../../assets/images/broken_image.png';
import Styles from '../../../assets/js/Styles';
import { CommonMediaListViewTypeProps } from '../CommonMediaListViewTypeProps';

export type ListViewProps = CommonMediaListViewTypeProps & {
  renderListItemContent?: (item: GenericMedia<unknown>) => React.ReactNode;
};

export const ListView: FC<ListViewProps> = ({
  items,
  isSelectable,
  isFavouriteable,
  getIsFavourite,
  getIsSelected,
  onFavouriteChanged,
  onSelectChanged,
  onItemClick,
  renderListItemContent,
}) => {
  if (onItemClick && isSelectable && onSelectChanged) {
    // eslint-disable-next-line no-console
    console.warn('[ListView] onItemClick and onSelectChanged are both supplied. onItemClick will be ignored.');
  }
  return (
    <List>
      {items.map((item) => {
        const isSelected = !!getIsSelected?.(item);
        const isFavourite = !!getIsFavourite?.(item);
        const handleClick = () => {
          if (isSelectable && onSelectChanged) {
            onSelectChanged(item, !isSelected);
            return;
          }
          return onItemClick?.(item);
        };
        return (
          <ListItem
            key={item.id}
            aria-label={item.title}
            secondaryAction={
              <Container right onClick={(e) => e.stopPropagation()}>
                {isFavouriteable && (
                  <FavouriteButton
                    isFavourite={isFavourite}
                    onChange={() => onFavouriteChanged?.(item, !isFavourite)}
                  />
                )}
                {isSelectable && <SelectButton selected={isSelected} onChange={handleClick} />}
              </Container>
            }>
            <ListItemButton
              aria-label={item.title}
              onClick={handleClick}
              sx={
                isSelectable || !!onItemClick
                  ? (theme) => ({
                      '&:hover': {
                        backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
                        color: theme.palette.getContrastText(Styles.Colors.THEME_PRIMARY_BG_HOVER),
                        cursor: 'pointer',
                      },
                    })
                  : undefined
              }>
              {renderListItemContent ? (
                renderListItemContent(item)
              ) : (
                <>
                  <ListItemAvatar>
                    <Avatar alt={item.title} src={`${item.url}?&dimension=167x167`} variant='square' />
                  </ListItemAvatar>
                  <ListItemText>{item.title}</ListItemText>
                </>
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ListView;
