import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Container from '../../Container';
import { GenericMedia } from '../../../declarations/GenericMedia';
import SelectedMediaItemContainer from '../SelectedMediaItemContainer';
import { KPProcessedFields } from '../../../declarations/models/KP';
import { renderKeyValuePair } from '../DMMediaFinder/SelectedDMMediaItem';

export interface SelectedDMMediaItemProps {
  item: GenericMedia<KPProcessedFields>;
  onRemoveItem: (item: GenericMedia<KPProcessedFields>) => void;
}

export const SelectedKPObjectItem: FC<SelectedDMMediaItemProps> = ({ item, onRemoveItem }) => {
  const { t: tCommon } = useTranslation('common');
  return (
    <SelectedMediaItemContainer item={item} onRemoveItem={onRemoveItem}>
      <Container column top left fullWidth fullHeight>
        <Typography
          sx={{
            wordBreak: 'break-word',
            fontWeight: 'bold',
            overflow: 'hidden',
          }}>
          {item.title}
        </Typography>
        <Container column left fullWidth>
          {/* FIXME: remove typecheck when only using KPProcessedFields in Finder */}
          {renderKeyValuePair(tCommon('publishingStatus'), tCommon(`Status.${item.source.status}`))}
        </Container>
      </Container>
    </SelectedMediaItemContainer>
  );
};

export default SelectedKPObjectItem;
