import React from 'react';
import { useController } from 'react-hook-form';
import { FormControl, FormGroup, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { EmployeesBlock as EmployeesBlockModel } from '../../../../declarations/models/blocks/EmployeesBlock';
import Container from '../../../../components/Container';
import CheckboxInput from '../../../../components/forms/CheckboxInput';
import Styles from '../../../../assets/js/Styles';
import SwitchInput from '../../../../components/forms/SwitchInput';
import SelectInput from '../../../../components/forms/SelectInput';
import { SortDirection, SortType } from '../../../../declarations/models/SortOption';
import SiteSharingBlockSettings from '../SiteSharingBlockSettings';

export const settingsAreaStyle = {
  width: '100%',
  backgroundColor: Styles.Colors.FIXED_SECTION_BACKGROUND_COLOR,
  padding: 2,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  gap: 2,
};

export const EMPLOYEES_TOGGLE_FIELDS = ['Email', 'Phone', 'Links', 'Position', 'Department', 'Site', 'Description'];

const SORT_OPTIONS = [SortType.LAST_NAME, SortType.FIRST_NAME, SortType.DEPARTMENT];

export const getToggleFieldsSettings = (t: any, blockPath: BlockPath) => (
  <FormControl sx={settingsAreaStyle}>
    <FormLabel>{t('EmployeesBlock.SelectInfoToShow')}</FormLabel>
    <FormGroup row>
      {EMPLOYEES_TOGGLE_FIELDS.map((field) => (
        <CheckboxInput
          key={field}
          path={`${blockPath}.show${field}`}
          label={t(`EmployeesBlock.Show${field}`)}
          fullWidth={false}
        />
      ))}
    </FormGroup>
  </FormControl>
);

export const EmployeesAutoBlock: BlockComponent = () => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: siteIds, onChange: setSiteIds },
  } = useController<BlockSpecificPage<EmployeesBlockModel>, `${BlockPath}.siteIds`>({ name: `${blockPath}.siteIds` });

  return (
    <Container column top gap={2} left fullWidth>
      <Container sx={settingsAreaStyle}>
        <SiteSharingBlockSettings siteIds={siteIds} setSiteIds={setSiteIds} />
      </Container>
      {getToggleFieldsSettings(tComp, blockPath)}
      <FormControl sx={settingsAreaStyle}>
        <Container my={1}>
          <SelectInput
            options={SORT_OPTIONS}
            getOptionKey={(o) => o}
            getOptionLabel={(o) => tCommon(`SortTypes.${o}`)}
            path={`${blockPath}.sortBy`}
            label={tCommon('SortTypeLabel')}
            defaultValue={SortType.LAST_NAME}
            size='small'
            hideNoSelectionOption
          />
          <SelectInput
            options={Object.values(SortDirection)}
            getOptionKey={(o) => o}
            getOptionLabel={(o) => tCommon(`SortDirections.${o}`)}
            path={`${blockPath}.order`}
            label={tCommon('SortDirectionLabel')}
            defaultValue={SortDirection.ASC}
            size='small'
            hideNoSelectionOption
          />
        </Container>
        <SwitchInput path={`${blockPath}.enableSearch`} label={tComp('EmployeesBlock.EnableSearch')} />
      </FormControl>
    </Container>
  );
};

export default EmployeesAutoBlock;
