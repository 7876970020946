import React, { FC } from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FinderHeader } from '@/components/Finder/FinderHeader';
import KPLogo from '../../icons/KPLogo';

export interface KPOwnerFinderHeaderProps {
  onQueryChanged: (query: string) => void;
}

export const KPOwnerFinderHeader: FC<KPOwnerFinderHeaderProps> = ({ onQueryChanged }) => {
  const { t } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');

  return (
    <FinderHeader
      title={
        <>
          <KPLogo height='40px' width='36px' />
          <Typography variant='h2' fontSize='28px' lineHeight='30px' fontWeight='700' letterSpacing='1px' pl={2}>
            KulturPunkt
          </Typography>
        </>
      }
      topRight={
        <FormControlLabel
          control={<Radio disableRipple checked />}
          label={
            <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
              {tCommon('all')}
            </Typography>
          }
          labelPlacement='end'
        />
      }
      bottomRight={
        <FormControlLabel
          control={<Radio disableRipple checked />}
          label={
            <Typography variant='body2' fontSize='14px' lineHeight='16px' fontWeight='400' letterSpacing='0.25px'>
              {t('KPFinderHeader.KPOwnerHeader')}
            </Typography>
          }
          labelPlacement='end'
        />
      }
      onQueryChange={onQueryChanged}
    />

    // <Container column top fullWidth gap={0}>
    //   <Container
    //     sx={(theme) => ({
    //       backgroundColor: Styles.Colors.FINDER_DM_PRIMARY_HEADER_BACKGROUND,
    //       color: theme.palette.getContrastText(Styles.Colors.FINDER_DM_PRIMARY_HEADER_BACKGROUND),
    //     })}
    //     p={2}
    //     spaceBetween
    //     fullWidth>
    //     <Container gap={0} left>
    //       <KPLogo height='40px' width='36px' />
    //       <Typography variant='h2' fontSize='28px' lineHeight='30px' fontWeight='700' letterSpacing='1px' pl={2}>
    //         {t('KPFinderHeader.KPOwnerHeader')}
    //       </Typography>
    //     </Container>
    //
    //     <Container right gap={2} sx={{ maxWidth: '50%' }} fullWidth>
    //       <SearchField initialValue={queryString} size='small' onQueryChange={onQueryChanged} round fullWidth />
    //     </Container>
    //   </Container>
    // </Container>
  );
};

export default KPOwnerFinderHeader;
