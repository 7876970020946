import React, { FC } from 'react';
import { Location } from '../../../declarations/models/Location';
import BaseSettingsViewForm from '../../BaseSettingsViewForm';
import SiteLocationsSettingsItemForm from './SiteLocationsSettingsItemForm';

export interface SiteLocationsSettingsItemProps {
  location: Location;
  defaultCenter?: { lat: number; lng: number };
  onChange: (m: Location) => void;
  onDeleteLocation: (l: Location) => void;
  handleSaveSettingsForm: (l: Location) => Promise<void | Location>;
}

export const SiteLocationsSettingsItem: FC<SiteLocationsSettingsItemProps> = ({
  location,
  defaultCenter,
  onChange,
  handleSaveSettingsForm,
  onDeleteLocation,
}) => {
  return (
    <BaseSettingsViewForm<Location>
      onSubmit={handleSaveSettingsForm}
      title='title'
      saveButtonInTop={false}
      saveButtonInHeader={false}
      withLayout={false}
      initialValue={location}>
      <SiteLocationsSettingsItemForm
        onChange={(m) => onChange(m)}
        defaultCenter={defaultCenter}
        onDelete={() => onDeleteLocation(location)}
      />
    </BaseSettingsViewForm>
  );
};

export default SiteLocationsSettingsItem;
