import React, { FC } from 'react';
import { Autocomplete, TextField, Chip, MenuItem } from '@mui/material';

interface SubjectSelectProps {
  tComp: (key: string) => string;
  allDMSchoolSubjects: string[];
  selectedDMSchoolSubjects: string[];
  autocompleteItemStyle: (selected: boolean) => any;
  handleDMSchoolSubjectsChanged: (subjects: string[]) => void;
}

const SubjectSelect: FC<SubjectSelectProps> = ({
  tComp,
  allDMSchoolSubjects,
  selectedDMSchoolSubjects,
  autocompleteItemStyle,
  handleDMSchoolSubjectsChanged,
}) => {
  return (
    <Autocomplete
      multiple
      options={allDMSchoolSubjects}
      getOptionLabel={(option) => tComp(`DMBlock.School.Subject.Options.${option}`)}
      value={selectedDMSchoolSubjects}
      onChange={(e, v) => handleDMSchoolSubjectsChanged(v)}
      renderInput={(params) => (
        <TextField {...params} label={tComp('DMBlock.School.Subject.SelectSubject')} size='small' />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip size='small' {...getTagProps({ index })} key={option} label={option} />)
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} sx={autocompleteItemStyle(selected)}>
          {option}
        </MenuItem>
      )}
      fullWidth
      disableCloseOnSelect
    />
  );
};

export default SubjectSelect;
