import React, { FC, useCallback, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Container from '../../../components/Container';
import { Api } from '../../../services/Api';
import {
  SharingContentApprovalStatus,
  SharingContentType,
  SharingGivenModel,
  SharingGrantedModel,
} from '../../../declarations/models/SiteSharingModel';
import { useStore } from '../../../components/store/Store';
import { useApi } from '../../../hooks/useApi';
import { Site } from '../../../declarations/models/Site';

export interface SiteSharingSettingsNewAgreementModalProps {
  open: boolean;
  onClose: (result: SharingGrantedModel | SharingGivenModel | null) => void;
  type: 'granted' | 'given';
}

export const SiteSharingSettingsNewAgreementModal: FC<SiteSharingSettingsNewAgreementModalProps> = ({
  open,
  onClose,
  type,
}) => {
  const { t: tComponents } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useStore();
  const selectedSiteId = state.selectedSite?.id || 0;
  const [availableSites, isLoadingAvailableSites] = useApi(() => Api.getAllSitesForSiteSharing(), []);
  const [contentType, setContentType] = useState<SharingContentType>(SharingContentType.RESOURCES);
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);

  const handleSaveForm = useCallback(async (): Promise<SharingGrantedModel | SharingGivenModel | void> => {
    const newAgreementModel = {
      content_type: contentType,
      new: true,
      remote_site_id: selectedSite?.id,
      approval_status: type === 'given' ? SharingContentApprovalStatus.APPROVED : undefined,
    };
    const savedFormData =
      type === 'given'
        ? await Api.saveSiteSharingModel<SharingGrantedModel | SharingGivenModel>(
            newAgreementModel,
            selectedSiteId,
          ).fetchDirect(null)
        : await Api.saveSiteSharingRequest<SharingGrantedModel | SharingGivenModel>(
            newAgreementModel,
            selectedSiteId,
          ).fetchDirect(null);
    if (!savedFormData) {
      enqueueSnackbar(tCommon('saveFailed'), { variant: 'error' });
    } else {
      enqueueSnackbar(tCommon('saved'), { variant: 'success' });
      if (type === 'given') {
        const result = { ...savedFormData, site_given: selectedSite?.name };
        onClose(result);
      } else {
        const result = { ...savedFormData, site_granted: selectedSite?.name };
        onClose(result);
      }
    }
  }, [contentType, enqueueSnackbar, onClose, selectedSite?.id, selectedSite?.name, selectedSiteId, tCommon, type]);

  return (
    <Dialog open={open} onClose={() => onClose(null)}>
      <Container p={2} mb={2} column spaceBetween sx={{ width: '40vh', height: '40vh' }}>
        <Container fullWidth fullHeight column top gap={2}>
          <Container p={2}>
            <Typography variant='h4' component='h2' noWrap>
              {tComponents(
                `Settings.SiteSettings.SiteSharingSettings.SiteSharingSettingsNewAgreementModal.titles.${type}`,
              )}
            </Typography>
          </Container>
          <Container fullWidth>
            <Typography>
              {tComponents('Settings.SiteSettings.SiteSharingSettings.SharingContentType.label')}:
            </Typography>
            <FormControl>
              <RadioGroup
                value={contentType}
                onChange={(e) => setContentType(e.target.value as SharingContentType)}
                defaultValue=''
                row>
                <FormControlLabel
                  value={SharingContentType.RESOURCES}
                  control={<Radio color='secondary' />}
                  label={tComponents('Settings.SiteSettings.SiteSharingSettings.SharingContentType.resources')}
                />
                <FormControlLabel
                  value={SharingContentType.CONTENT}
                  control={<Radio color='secondary' />}
                  label={tComponents('Settings.SiteSettings.SiteSharingSettings.SharingContentType.content')}
                />
                <FormControlLabel
                  value={SharingContentType.TEMPLATES}
                  control={<Radio color='secondary' />}
                  label={tComponents('Settings.SiteSettings.SiteSharingSettings.SharingContentType.templates')}
                />
              </RadioGroup>
            </FormControl>
          </Container>
          <Autocomplete
            getOptionLabel={(option: Site) => option.name}
            loading={isLoadingAvailableSites}
            loadingText={tCommon('loading')}
            options={availableSites}
            value={selectedSite}
            onChange={(_, newValue: Site | null) => {
              setSelectedSite(newValue);
            }}
            sx={{ width: '80%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={tComponents(
                  'Settings.SiteSettings.SiteSharingSettings.SiteSharingSettingsNewAgreementModal.selectSite',
                )}
              />
            )}
            ListboxProps={{ style: { maxHeight: 230 } }}
          />
        </Container>
        <Button variant='contained' color='secondary' disabled={!selectedSite} onClick={() => handleSaveForm()}>
          {tCommon(`save`)}
        </Button>
      </Container>
    </Dialog>
  );
};

export default SiteSharingSettingsNewAgreementModal;
