import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { ArticleTitleBlock } from '../../../declarations/models/blocks/ArticleTitleBlock';
import { useCurrentSection } from '../CurrentSectionProvider';
import { Page } from '../../../declarations/models/Page';
import { useEditorModel } from '../../lib/components/EditorDataProvider';

export const ArticleTitleBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const { section } = useCurrentSection();
  const model: Page = useEditorModel<Page>();
  const currentBlock = block as ArticleTitleBlock;

  return (
    <>
      <div className='article__title'>
        {!currentBlock?.hideInsertTitle && model?.insert_title && (
          <div className='article__insert_title'>{model.insert_title}</div>
        )}
        {!currentBlock?.hidetitle && <h1 style={{ whiteSpace: 'pre-line' }}>{currentBlock.title || model.title}</h1>}
      </div>
      {currentBlock?.leadtext && currentBlock?.leadtext?.length > 0 && (
        <div className='article__leadtext'>
          <p style={{ whiteSpace: 'pre-line' }}>{currentBlock?.leadtext}</p>
        </div>
      )}
    </>
  );
};

export default ArticleTitleBlockPreview;
