import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useWatch } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import { BlockPath } from '../../../editor/PageEditor/CurrentBlockProvider';
import { BlockSpecificPage } from '../../../editor/lib/declarations/BlockSpecificPage';
import { HeaderMenuBlock } from '../../../declarations/models/blocks/settingsBlocks/HeaderMenuBlock';
import Container from '../../../components/Container';
import SwitchInput from '../../../components/forms/SwitchInput';
import TextInput from '../../../components/forms/TextInput';
import M24PageFinder from '../../../components/Finder/M24PageFinder/M24PageFinder';
import Styles from '../../../assets/js/Styles';
import DeleteButton from '../../../components/DeleteButton';

export interface LanguageMenuSearchFieldSettingsProps {
  blockPath: BlockPath;
}

export const LanguageMenuSearchFieldSettings: FC<LanguageMenuSearchFieldSettingsProps> = ({ blockPath }) => {
  const { t } = useTranslation('components');
  const [pageFinderOpen, setPageFinderOpen] = useState<boolean>(false);

  const includeSearchField = useWatch<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.includeSearchField`>({
    name: `${blockPath}.includeSearchField`,
  });

  const includeSearchButton = useWatch<BlockSpecificPage<HeaderMenuBlock>, `${BlockPath}.includeSearchButton`>({
    name: `${blockPath}.includeSearchButton`,
  });

  const {
    field: { value: searchresultPage, onChange: onChangeSearchresultPage },
  } = useController<BlockSpecificPage<HeaderMenuBlock>, `${typeof blockPath}.searchresultPage`>({
    name: `${blockPath}.searchresultPage`,
  });

  return (
    <Container fullWidth top left>
      <Container column fullWidth top left>
        <Container column fullWidth top left>
          <SwitchInput
            path={`${blockPath}.includeSearchField`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.includeSearchField')}
            defaultValue={false}
          />
          <TextInput
            path={`${blockPath}.searchFieldText`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.searchFieldText')}
            defaultValue=''
            disabled={!includeSearchField}
          />
        </Container>
        <Container column fullWidth top left>
          <SwitchInput
            path={`${blockPath}.includeSearchButton`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.includeSearchButton')}
            defaultValue={false}
            disabled={!includeSearchField}
          />
          <TextInput
            path={`${blockPath}.searchButtonText`}
            label={t('Settings.LanguageSettings.LanguageMenuSettings.searchButtonText')}
            defaultValue=''
            disabled={!includeSearchField || !includeSearchButton}
          />
        </Container>
        <Container column fullWidth top left>
          <Typography>{t('Settings.LanguageSettings.LanguageMenuSettings.searchresultPage')}:</Typography>
          <M24PageFinder
            hideSiteSelector
            open={pageFinderOpen}
            onClose={() => setPageFinderOpen(false)}
            onSelectionConfirmed={(pages) => {
              if (pages.length) {
                onChangeSearchresultPage(pages[0].source);
              }
            }}
          />
          {searchresultPage && (
            <Container
              px={2}
              fullWidth
              spaceBetween
              sx={{
                backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
                borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                height: '50px',
              }}>
              <Container>
                <PageStatusCircle status={searchresultPage.status} size={20} />
                <Typography>{searchresultPage.title}</Typography>
                <Typography
                  variant='caption'
                  sx={{ px: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {searchresultPage.path || searchresultPage.url}
                </Typography>
              </Container>
              <DeleteButton
                onConfirm={() => onChangeSearchresultPage(null)}
                deleteLabel='remove'
                deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
              />
            </Container>
          )}
          <Button
            variant='contained'
            color='secondary'
            sx={{ flex: '1 0 auto' }}
            onClick={() => setPageFinderOpen(true)}>
            {t(`Settings.LanguageSettings.LanguageMenuSettings.addPageButton`)}
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default LanguageMenuSearchFieldSettings;
