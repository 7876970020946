/* istanbul ignore file */
/**
 * The different actions/operations that can be performed on the store
 */
export enum ActionType {
  /**
   * Change the Site selected as context for the application content
   */
  CHANGE_SITE_CONTEXT = 'changeSiteContext',
  /**
   * Set full site data for the selected
   */
  SET_SELECTED_SITE_DATA = 'setSelectedFullSite',
  /**
   * Set the selected site+language content
   */
  SET_SELECTED_LANGUAGE_DATA = 'setSelectedLanguageData',
  /**
   * Change the current language for the selected site context
   */
  CHANGE_SITE_CONTEXT_LANGUAGE = 'changeSiteContextLanguage',
  /**
   * This is a NOOP. Not useful for much, rather than testing.
   * May be removed later.
   */
  NOOP = 'noop',
  /**
   * Set the currently logged-in user
   */
  SET_CURRENT_USER = 'setCurrentUser',
  /**
   * Mark a page as favourite
   */
  TOGGLE_FAVOURITE_PAGE = 'toggleFavouritePage',
  /**
   * Set the current selected site skins
   */
  SET_SITE_SKINS = 'setSiteSkins',
  /**
   * Set the current selected site themes
   */
  SET_SITE_THEMES = 'setSiteThemes',
  /**
   * Update the current user's role for the selected site
   */
  UPDATE_CURRENT_SITE_ROLE = 'updateCurrentSiteRole',
}
