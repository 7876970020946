import React, { forwardRef } from 'react';
import { Box, FormLabel } from '@mui/material';
import Styles from '../assets/js/Styles';
import Container, { ContainerProps } from './Container';

export interface OutlinedContainerProps extends ContainerProps {
  label?: string;
  labelBackgroundColor?: string;
}

const OutlinedContainer = forwardRef<HTMLElement, OutlinedContainerProps>(
  (
    { label, children, fullHeight, fullWidth, labelBackgroundColor = Styles.Colors.THEME_PRIMARY, ...OutlinedBoxProps },
    ref,
  ) => {
    return (
      <Box ref={ref} sx={{ width: fullWidth ? '100%' : undefined, height: fullHeight ? '100%' : undefined }}>
        <FormLabel
          sx={{
            marginLeft: '0.71em',
            marginTop: '-0.71em',
            paddingLeft: '0.44em',
            paddingRight: '0.44em',
            zIndex: 2,
            backgroundColor: labelBackgroundColor,
            position: 'absolute',
            fontSize: '0.75em',
            width: 'auto',
          }}>
          {label}
        </FormLabel>
        <Box
          sx={{
            position: 'relative',
            borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            fontSize: '0.875rem',
          }}>
          <Container
            sx={{
              padding: '20px',
              display: 'flex',
              gap: '4px',
              flexWrap: 'wrap',
              overflow: 'auto',
            }}
            {...OutlinedBoxProps}>
            {children}
          </Container>
          <fieldset
            aria-hidden='true'
            style={{
              textAlign: 'left',
              position: 'absolute',
              bottom: 0,
              right: 0,
              top: '-5px',
              left: 0,
              margin: 0,
              padding: '0 8px',
              pointerEvents: 'none',
              borderRadius: 'inherit',
              borderStyle: 'solid',
              borderWidth: '1px',
              overflow: 'hidden',
              minWidth: '0%',
              borderColor: Styles.Colors.NESTED_SECTION_BORDER_COLOR,
            }}>
            <legend
              style={{
                float: 'unset',
                overflow: 'hidden',
                display: 'block',
                width: 'auto',
                padding: 0,
                height: '11px',
                fontSize: '0.75em',
                visibility: 'hidden',
                maxWidth: '100%',
                whiteSpace: 'nowrap',
              }}>
              <span>{label}</span>
            </legend>
          </fieldset>
        </Box>
      </Box>
    );
  },
);

OutlinedContainer.displayName = 'OutlinedContainer';

export { OutlinedContainer };
