import { Box, SxProps } from '@mui/material';
import React, { FC, ReactNode, useEffect } from 'react';
import { mergeSx } from '../utils/mergeSx';
import { ChildrenProp } from '../declarations/ChildrenProp';

export interface LayoutProps extends ChildrenProp {
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  sx?: SxProps;
  contentOverflow?: string;
  scrollContentToTopTrigger?: unknown;
}

export const Layout: FC<LayoutProps> = ({
  children,
  headerContent,
  footerContent,
  sx,
  contentOverflow = 'auto',
  scrollContentToTopTrigger,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollContentToTopTrigger && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [scrollContentToTopTrigger]);
  return (
    <Box
      sx={mergeSx(
        {
          display: 'grid',
          gridTemplateColumns: '100%',
          gridTemplateRows: 'min-content 1fr min-content',
          gridTemplateAreas: `
          "header"
          "content"
          "footer"
        `,
          height: '100%',
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        },
        sx || {},
      )}>
      {!!headerContent && (
        <Box
          sx={{
            gridArea: 'header',
            zIndex: 1,
          }}>
          {headerContent}
        </Box>
      )}
      {!!children && (
        <Box
          ref={contentRef}
          component='main'
          style={{
            gridArea: 'content',
            overflow: contentOverflow,
          }}>
          {children}
        </Box>
      )}
      {!!footerContent && (
        <Box
          component='footer'
          style={{
            gridArea: 'footer',
            zIndex: 1,
          }}>
          {footerContent}
        </Box>
      )}
    </Box>
  );
};

export default Layout;
