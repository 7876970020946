import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxInput from '@/components/forms/CheckboxInput';
import { BlockComponent } from '../../lib/declarations/EditorComponentTypes';
import Container from '../../../components/Container';
import TextInput from '../../../components/forms/TextInput';
import { BlockSpecificPage } from '../../lib/declarations/BlockSpecificPage';
import { WidgetBlock as WidgetBlockModel } from '../../../declarations/models/blocks/WidgetBlock';
import { useCurrentBlock } from '../CurrentBlockProvider';

export const WidgetBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath } = useCurrentBlock();

  return (
    <Container gap={2} column top left fullWidth data-testid='widgetBlock'>
      <Container gap={0} column top left fullWidth>
        <Container gap={2} top left fullWidth>
          <CheckboxInput<BlockSpecificPage<WidgetBlockModel>>
            path={`${blockPath}.isWidget`}
            label={t('WidgetBlock.isWidget')}
          />
        </Container>
        <Container gap={2} top left fullWidth>
          <TextInput<BlockSpecificPage<WidgetBlockModel>>
            path={`${blockPath}.html`}
            label={t('WidgetBlock.html')}
            type='text'
            multiline
            minRows={5}
            maxRows={10}
          />
        </Container>
      </Container>
      <Container gap={0} column top left fullWidth>
        <Container gap={2} top left fullWidth>
          <TextInput<BlockSpecificPage<WidgetBlockModel>>
            path={`${blockPath}.iframeSrc`}
            label={t('WidgetBlock.src')}
          />
          <TextInput<BlockSpecificPage<WidgetBlockModel>>
            path={`${blockPath}.iframeHeight`}
            label={t('WidgetBlock.height')}
            type='number'
          />
          <TextInput<BlockSpecificPage<WidgetBlockModel>>
            path={`${blockPath}.iframeWidth`}
            label={t('WidgetBlock.width')}
            type='number'
          />
        </Container>
      </Container>
    </Container>
  );
};

export default WidgetBlock;
