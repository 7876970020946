import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import { FormModule } from '../../declarations/models/FormModule';
import Styles from '../../assets/js/Styles';
import { Status } from '../../declarations/models/Status';
import { formatAPITimestamp } from '../../utils/dates';
import DeleteButton from '../../components/DeleteButton';

interface FormItemProps {
  form: FormModule;
  onEditClick: (id: number) => void;
  onShowAnswersClick: (id: number) => void;
  onDelete: (id: number) => void;
  contentCount?: number;
  isLoadingContentCounts?: boolean;
}

export const FormItem: FC<FormItemProps> = ({
  form: { id, title, status, updated_at, used_in_pages },
  onEditClick,
  onShowAnswersClick,
  onDelete,
  contentCount,
  isLoadingContentCounts,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tComponents } = useTranslation('components');

  return (
    <Grid
      role='listitem'
      container
      spacing={3}
      paddingLeft={0}
      paddingRight={2}
      key={id}
      borderRadius={1}
      border={1}
      borderColor={Styles.Colors.MEDIUM_LIGHT_GREY}
      bgcolor={Styles.Colors.LIGHTEST_GREY}>
      <Grid item py={2} xs={4}>
        <Typography fontWeight='bold' fontSize={18}>
          {title}
        </Typography>
        <Box display='flex' alignItems='center' gap={1}>
          <MaterialSymbol
            name='circle'
            fill
            sx={{ fontSize: '16px', top: 0 }}
            color={status === Status.PUBLISHED ? 'success' : 'disabled'}
          />
          <Typography variant='caption' color='textSecondary'>
            {tCommon(`Status.${status}`)} {formatAPITimestamp(updated_at)}
          </Typography>
        </Box>
      </Grid>
      <Grid item py={2} xs={2}>
        <Box
          sx={{
            typography: 'caption',
            textAlign: 'left',
            fontWeight: 'bold',
          }}>
          {tComponents('FormView.NumberOfPagesInUse', { count: used_in_pages?.length ?? 0 })}
        </Box>
      </Grid>
      <Grid item py={2} xs={6}>
        {id && (
          <Box display='flex' alignItems='center' justifyContent='flex-end' gap={1}>
            <Button
              variant='outlined'
              color='secondary'
              startIcon={<MaterialSymbol name='edit' fill />}
              size='large'
              onClick={() => onEditClick(id)}>
              {tComponents('FormView.EditForm')}
            </Button>
            <Button
              onClick={() => onShowAnswersClick(id)}
              variant='contained'
              color='secondary'
              size='large'
              sx={{
                backgroundColor: Styles.Colors.BLACK,
              }}>
              {isLoadingContentCounts ? (
                <CircularProgress variant='indeterminate' color='primary' size={25} />
              ) : (
                tComponents('FormView.ShowAnswers', { count: contentCount ?? 0 })
              )}
            </Button>
            {/* todo: redo DeleteButton according to XD, looks a bit different */}
            <DeleteButton onConfirm={() => onDelete(id)} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
