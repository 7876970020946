import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ListItemAvatar, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import DeleteButton from '@/components/DeleteButton';
import DraggableContainer, { DraggableContainerProps } from '../../../../components/DraggableContainer';
import { Draggable } from '../../../../declarations/Draggable';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import Container from '../../../../components/Container';
import Image from '../../../../components/Image';
import Styles from '../../../../assets/js/Styles';
import { Api } from '../../../../services/Api';
import { DmIdItem, DMManualBlockModel } from '../../../../declarations/models/blocks/DMBlock';
import { DMArtifact } from '../../../../declarations/models/DMMediaModel';
import DMLogo from '../../../../components/icons/DMLogo';
import { noWrapTextStyle } from '../../../../views/MediaView/MediaViewTypes/ListViewItem';

type DMBlockItemPathPrefix = `${BlockPath}.dmItems.${number}`;

export const DMBlockItem = ({
  index,
  onReorder,
  onRemove,
  onAdd,
}: Pick<DraggableContainerProps<DmIdItem>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'>) => {
  const { t } = useTranslation('common');
  const { blockPath } = useCurrentBlock();
  const itemPathPrefix: DMBlockItemPathPrefix = `${blockPath}.dmItems.${index}`;
  const [dmItem, setDmItem] = useState<DMArtifact>();

  const {
    field: { value },
  } = useController<BlockSpecificPage<DMManualBlockModel>, typeof itemPathPrefix>({
    name: itemPathPrefix,
  });
  const item = value as DmIdItem;

  useEffect(() => {
    if (item.uniqueId) {
      const ctx = Api.getDMArtifact(item.uniqueId);
      ctx
        .fetchDirect(null)
        .then((e) => e && setDmItem(e))
        .finally(ctx.abort);
    }
  }, [item]);

  const place = dmItem?.placeOfProduction || dmItem?.place;
  const placeString = typeof place === 'string' ? place : place?.place || '';

  return (
    <DraggableContainer<DmIdItem>
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      type={Draggable.DM_ITEM}
      value={item}
      secondaryAction={
        <DeleteButton
          onConfirm={() => onRemove?.(index)}
          component='IconButton'
          tooltip={t('remove')}
          deleteIcon={<MaterialSymbol name='do_not_disturb_on' />}
        />
      }
      headerContent={
        <Container left fullWidth>
          <ListItemAvatar sx={{ height: '80px' }}>
            {dmItem?.images?.length ? (
              <Image
                src={dmItem.images[0].url}
                alt={dmItem.description}
                loading='lazy'
                style={{
                  width: '64px',
                  height: '64px',
                  objectFit: 'cover',
                  overflow: 'hidden',
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                  margin: '8px',
                }}
              />
            ) : (
              <DMLogo
                sx={{
                  width: '64px',
                  height: '64px',
                  padding: '20px 8px',
                  margin: '8px',
                  color: 'rgba(0, 0, 0, 0.54)',
                  backgroundColor: Styles.Colors.THEME_PRIMARY_BG_HOVER,
                  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
                }}
              />
            )}
          </ListItemAvatar>
          {dmItem ? (
            <Container column left gap={0} sx={{ maxWidth: 'calc(100% - 72px)' }}>
              <Typography sx={noWrapTextStyle}>{dmItem.title}</Typography>

              <Container left wrap gap='0 8px' sx={{ ...noWrapTextStyle, maxHeight: '40px' }}>
                <Typography variant='caption' fontWeight={700}>
                  {t(`DMContentType.${dmItem.type}`)}
                </Typography>
                <Typography variant='caption' sx={noWrapTextStyle}>
                  <b>DimuID: </b>
                  {dmItem?.unique_id}
                </Typography>
                <Typography variant='caption' sx={noWrapTextStyle}>
                  <b>{t('owner')}: </b>
                  {dmItem.owner}
                </Typography>
                {!!placeString && (
                  <Typography variant='caption' sx={noWrapTextStyle}>
                    <b>{t('place')}: </b>
                    {placeString}
                  </Typography>
                )}
              </Container>
            </Container>
          ) : (
            <Typography color={Styles.Colors.MEDIUM_GREY} fontStyle='italic'>
              {t('loading')}
            </Typography>
          )}
        </Container>
      }
    />
  );
};

export default DMBlockItem;
