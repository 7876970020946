import banner from './banner.svg?react';
import banner_image_above from './banner_image_above.svg?react';
import banner_image_below from './banner_image_below.svg?react';
import banner_image_left from './banner_image_left.svg?react';
import banner_special from './banner_special.svg?react';
import banner_text_on_image from './banner_text_on_image.svg?react';
import banner_variable_height from './banner_variable_height.svg?react';
import calendar_event from './calendar_event.svg?react';
import calendar_event_auto from './calendar_event_auto.svg?react';
import card from './card.svg?react';
import card_module_horizontal from './card_module_horizontal.svg?react';
import dm from './dm.svg?react';
import dm_auto from './dm_auto.svg?react';
import gallery from './gallery.svg?react';
import gallery_auto from './gallery_auto.svg?react';
import image_left from './image_left.svg?react';
import image_right from './image_right.svg?react';
import kulturpunkt from './kulturpunkt.svg?react';
import kulturpunkt_auto from './kulturpunkt_auto.svg?react';
import move_page from './move_page.svg?react';
import page_listing_auto from './page_listing_auto.svg?react';
import page_listing_manual from './page_listing_manual.svg?react';
import page_settings from './page_settings.svg?react';
import section_content from './section_content.svg?react';
import section_grid from './section_grid.svg?react';
import section_list from './section_list.svg?react';
import size_l from './size_l.svg?react';
import size_m from './size_m.svg?react';
import size_m_large from './size_m_large.svg?react';
import size_m_small from './size_m_small.svg?react';
import size_s from './size_s.svg?react';
import size_s_large from './size_s_large.svg?react';
import size_xl from './size_xl.svg?react';
import vertical_align_justify from './vertical_align_justify.svg?react';
import width_wider from './width_wider.svg?react';
import width_full from './width_full.svg?react';
import width_narrow from './width_narrow.svg?react';
import width_normal from './width_normal.svg?react';

const icons = {
  banner,
  banner_image_above,
  banner_image_below,
  banner_image_left,
  banner_special,
  banner_text_on_image,
  banner_variable_height,
  calendar_event,
  calendar_event_auto,
  card,
  card_module_horizontal,
  dm,
  dm_auto,
  gallery,
  gallery_auto,
  image_left,
  image_right,
  kulturpunkt,
  kulturpunkt_auto,
  move_page,
  page_listing_auto,
  page_listing_manual,
  page_settings,
  section_content,
  section_grid,
  section_list,
  size_l,
  size_m,
  size_m_large,
  size_m_small,
  size_s,
  size_s_large,
  size_xl,
  vertical_align_justify,
  width_wider,
  width_full,
  width_narrow,
  width_normal,
} as const;

export type CustomIconName = keyof typeof icons;

export default icons;
