/* istanbul ignore file */

/**
 * Possible ways to save a model in the Editor
 */
export enum SaveType {
  SAVE = 'save',
  SAVE_AND_PUBLISH = 'save_and_publish',
  SAVE_AND_UNPUBLISH = 'save_and_unpublish',
  SAVE_AND_ARCHIVE = 'save_and_archive',
  SAVE_AS_NEW_TEMPLATE = 'save_as_new_template',
  SAVE_AS_NEW_VERSION = 'save_as_new_version',
  DELETE_DRAFT = 'delete_draft',
  DELETE_TEMPLATE = 'delete_template',
  // SAVE_AS_COPY = 'save_as_copy',
}
