/* istanbul ignore file */
export enum SectionType {
  ARTICLE_HEAD = 'article__head',
  ARTICLE_BODY = 'article__body', // And intro-section? (noted by `section.introSection`)
  ARTICLE_LINKS = 'article__links',
  GRID_ROW = 'grid__row',
  PAGE_GRID = 'page__grid',
  SITE_HEAD = 'site__head', // Settings section type
  SITE_FOOTER = 'site__footer', // Settings section type
}
