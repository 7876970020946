import React from 'react';
import { BlockPreviewComponent } from '../../lib/declarations/EditorComponentTypes';
import { useCurrentBlock } from '../CurrentBlockProvider';
import { BlockModuleType } from '../../../declarations/models/BlockModuleType';
import { ProgramBlockModel } from '../../../declarations/models/blocks/ProgramBlock';

export const ProgramBlockPreview: BlockPreviewComponent = () => {
  const { block } = useCurrentBlock();
  const currentBlock = block as ProgramBlockModel;

  const programContent = () => {
    return (
      <ul>
        {currentBlock?.items &&
          currentBlock?.items.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`pi-${i}`}>
              <strong>
                {item?.time__from} {item.time__to && ` – ${item.time__to}`} {item.title}
              </strong>
              {item.descr && <p>{item.descr}</p>}
            </li>
          ))}
      </ul>
    );
  };

  return (
    <div className='article__grid'>
      {currentBlock?.module__type !== BlockModuleType.MODULE ? (
        <div className='article__content'>
          {currentBlock?.visibletitle && (
            <div className='article__bodytext'>
              <h2>{currentBlock?.title}</h2>
            </div>
          )}
          <div className='article__bodytext'>
            {currentBlock?.body && <p>{currentBlock?.body}</p>}
            {programContent()}
          </div>
        </div>
      ) : (
        <div className='module module--card module--content'>
          <div className={`module__grid skin skin-contentbox ${currentBlock?.skin || ''}`}>
            {currentBlock?.state === 'semi' ? (
              <div className='preview__blockmessage'>Tekstboks vil være delvis åpen i portal</div>
            ) : null}
            {currentBlock?.state === 'collapsed' ? (
              <div className='preview__blockmessage'>Tekstboks vil være lukket i portal</div>
            ) : null}
            <div className='module__content'>
              <div className='module__head'>
                <i className='module__icon i-material i-ma-event' />
                {currentBlock?.visibletitle && <h2>{currentBlock?.title}</h2>}
              </div>
              <div className='module__body'>
                {currentBlock?.body && <p>{currentBlock?.body}</p>}
                {programContent()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramBlockPreview;
