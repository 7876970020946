import React from 'react';
import { useEditorModel } from '../../lib/components/EditorDataProvider';
import BaseModel from '../../../declarations/models/BaseModel';

export const DeveloperPreview = <Model extends BaseModel>() => {
  const model = useEditorModel<Model>();
  return <pre data-testid='developer-preview-content'>{JSON.stringify(model, undefined, 2)}</pre>;
};

export default DeveloperPreview;
