import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import Image from '../../../../components/Image';
import Styles from '../../../../assets/js/Styles';
import { DMArtifact } from '../../../../declarations/models/DMMediaModel';
import DMLogo from '../../../../components/icons/DMLogo';
import { noWrapTextStyle } from '../../../../views/MediaView/MediaViewTypes/ListViewItem';

interface SingleDMItemProps {
  dmArtifact: DMArtifact;
  onRemove: () => void;
}

export const SingleDMItem: FC<SingleDMItemProps> = ({ dmArtifact, onRemove }) => {
  const { t } = useTranslation('common');

  const { place } = dmArtifact;
  const placeString = typeof place === 'string' ? place : place?.place || '';

  return (
    <Container left fullWidth top>
      <Container top sx={{ width: '128px' }}>
        {dmArtifact?.images?.length ? (
          <Image
            src={dmArtifact.images[0].url}
            alt={dmArtifact.description}
            loading='lazy'
            maxHeight='128px'
            maxWidth='128px'
            style={{ borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT }}
          />
        ) : (
          <DMLogo
            sx={{
              width: '128px',
              height: '128px',
              padding: '43px 24px',
              color: 'rgba(0, 0, 0, 0.54)',
              backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
              borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
            }}
          />
        )}
      </Container>
      <Container column left gap={0} sx={{ width: 'calc(100% - 136px)' }}>
        <Container fullWidth spaceBetween>
          <Typography sx={{ ...noWrapTextStyle, maxWidth: 'calc(100% - 48px)' }}>{dmArtifact?.title}</Typography>
          <Tooltip title={t('DMBlock.RemoveObject')} arrow color='error'>
            <IconButton size='small' onClick={onRemove}>
              <MaterialSymbol name='do_not_disturb_on' />
            </IconButton>
          </Tooltip>
        </Container>

        <Container column left gap={0} fullWidth>
          <Typography variant='caption' sx={noWrapTextStyle}>
            <b>DimuID: </b>
            {dmArtifact?.unique_id}
          </Typography>
          <Typography variant='caption' sx={noWrapTextStyle}>
            <b>{t('owner')}: </b>
            {dmArtifact?.owner}
          </Typography>
          {!!placeString && (
            <Typography variant='caption' sx={noWrapTextStyle}>
              <b>{t('place')}: </b>
              {placeString}
            </Typography>
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default SingleDMItem;
