import React from 'react';
import { BlockComponent } from '@/editor/lib/declarations/EditorComponentTypes';
import Container from '@/components/Container';
import { Button, CircularProgress, Typography } from '@mui/material';
import Styles from '@/assets/js/Styles';
import { BlockPath, useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { useControlledFieldArray } from '@/hooks/useControlledFieldArray';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import {
  LILLEHAMMER_COORDINATES,
  MapDesign,
  MapType,
  type MapV2Block as MapV2BlockType,
} from '@/declarations/models/blocks/MapV2';
import { MapV2MarkerFormField } from '@/editor/PageEditor/EditorBlock/MapV2Block/MapV2MarkerFormField';
import { useTranslation } from 'react-i18next';
import { useApi } from '@/hooks/useApi';
import { Api } from '@/services/Api';
import { useStore } from '@/components/store/Store';
import { Location } from '@/declarations/models/Location';

import RadioInput from '@/components/forms/RadioInput';
import { AutocompleteInput } from '@/components/forms/AutocompleteInput';
import CheckboxInput from '@/components/forms/CheckboxInput';
import { useWatch } from 'react-hook-form';

export const MapV2Block: BlockComponent = () => {
  const block = useCurrentBlock<MapV2BlockType>();
  const { t: tComponents } = useTranslation('components');
  const { state } = useStore();
  const siteId = state.selectedSite?.id || 0;
  const [allSiteLocations, isLoadingAllSiteLocations] = useApi(() => Api.getSiteLocations(siteId), [] as Location[]);

  const { fields, append, remove } = useControlledFieldArray<BlockSpecificPage<MapV2BlockType>, `${BlockPath}.markers`>(
    {
      name: `${block.blockPath}.markers`,
    },
  );

  const canChangeMapType = useWatch<BlockSpecificPage<MapV2BlockType>>({
    name: `${block.blockPath}.mapDesign`,
  });

  return (
    <Container top left column fullWidth>
      <Container fullWidth top left column>
        {isLoadingAllSiteLocations ? (
          <CircularProgress variant='indeterminate' color='secondary' size={30} />
        ) : (
          <AutocompleteInput
            options={allSiteLocations}
            getOptionLabel={(o) => o.title || o.content?.address?.full_address || '-'}
            getOptionKey={(o) => `${o.id}`}
            path={`${block.blockPath}.siteLocations`}
            defaultValue={[]}
            label={tComponents('MapV2Block.addSiteLocationLabel')}
            helperText={tComponents('MapV2Block.addSiteLocationHelpText')}
          />
        )}
      </Container>

      <Container
        sx={{ backgroundColor: Styles.Colors.BLOCK_ITEMS_BACKGROUND_COLORS }}
        p={2}
        mt={0}
        mb={2}
        gap={0}
        column
        top
        left
        fullWidth>
        <Button
          sx={{
            marginTop: 2,
          }}
          fullWidth
          variant='outlined'
          color='secondary'
          onClick={() =>
            append({
              location: {
                latitude: LILLEHAMMER_COORDINATES.lat,
                longitude: LILLEHAMMER_COORDINATES.lng,
                is_local: true,
              },
              type: 'location',
            })
          }>
          {tComponents('MapV2Block.AddLocation')}
        </Button>
        <br />
        <Container fullWidth top left column>
          {fields.map((field, index) => (
            <MapV2MarkerFormField
              defaultValue={{
                location: field.location,
                type: field.type,
              }}
              key={field.id}
              path={`${block.blockPath}.markers.${index}`}
              onDelete={() => remove(index)}
            />
          ))}
        </Container>
      </Container>

      <Container
        fullWidth
        top
        left
        column
        sx={{
          backgroundColor: Styles.Colors.LIGHTEST_BLUE,
          border: `1px solid ${Styles.Colors.LIGHT_BLUE}`,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          p: 2,
          mt: 2,
        }}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: tComponents('MapV2Block.InfoBoxHtml') }} />
      </Container>

      <Container
        fullWidth
        top
        left
        column
        component='fieldset'
        sx={{
          border: `1px solid ${Styles.Colors.DARK_GREY}`,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          p: 2,
          mt: 2,
        }}>
        <>
          <RadioInput<BlockSpecificPage<MapV2BlockType>, MapDesign>
            required
            options={[MapDesign.NORMAL, MapDesign.BLACK_WHITE]}
            path={`${block.blockPath}.mapDesign`}
            getOptionKey={(option) => option}
            getOptionLabel={(option) => tComponents(`MapV2Block.MapDesign.${option}`)}
            defaultValue={MapDesign.NORMAL}
            label={tComponents('MapV2Block.MapDesign.label')}
            direction='horizontal'
          />
          {canChangeMapType === MapDesign.NORMAL && (
            <RadioInput<BlockSpecificPage<MapV2BlockType>, MapType>
              required
              options={[MapType.ROADMAP, MapType.SATELLITE]}
              path={`${block.blockPath}.mapType`}
              getOptionKey={(option) => option}
              getOptionLabel={(option) => tComponents(`MapV2Block.MapType.${option}`)}
              defaultValue={MapType.ROADMAP}
              label={tComponents('MapV2Block.MapType.label')}
              direction='horizontal'
            />
          )}
          <br />
          <CheckboxInput
            path={`${block.blockPath}.enableStreetView`}
            label={tComponents('MapV2Block.enableStreetViewLabel')}
            helperText={tComponents('MapV2Block.enableStreetViewHelperText')}
          />

          <CheckboxInput
            path={`${block.blockPath}.enableMapType`}
            label={tComponents('MapV2Block.enableMapTypeLabel')}
            helperText={tComponents('MapV2Block.enableMapTypeHelperText')}
          />
        </>
      </Container>
      <Typography
        variant='subtitle2'
        sx={{
          mt: 2,
        }}>
        Zoom/gruppering
      </Typography>
      <Container
        fullWidth
        top
        left
        column
        sx={{
          backgroundColor: Styles.Colors.LIGHTEST_BLUE,
          border: `1px solid ${Styles.Colors.LIGHT_BLUE}`,
          borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
          p: 2,
          mt: 2,
        }}>
        <em>{tComponents('MapV2Block.InfoBoxZoom')}</em>
      </Container>
    </Container>
  );
};
