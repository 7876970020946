import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const KPLogo: FC<Omit<SvgIconProps, 'viewBox' | 'preserveAspectRatio'>> = (props) => {
  return (
    <SvgIcon
      sx={{ width: 'unset', height: 'unset' }}
      width='30'
      height='34'
      viewBox='0 0 30 34'
      {...props}
      preserveAspectRatio='xMinYMin'
      data-testid='KPLogo'>
      <defs>
        <clipPath id='b'>
          <rect width='30' height='34' />
        </clipPath>
      </defs>
      <g id='a' clipPath='url(#b)'>
        <g transform='translate(11.234 1)'>
          <rect width='5.864' height='32.279' transform='translate(-9.308)' />
          <path
            d='M-4741.756,3067l-11.659,15.885,9.437,16.264h-6.982l-9.726-16.264L-4749.12,3067Z'
            transform='translate(4758.145 -3066.927)'
          />
          <rect width='5.864' height='32.279' transform='translate(-10)' />
          <path
            d='M-4741.756,3067l-11.659,15.885,9.437,16.264h-6.982l-9.726-16.264L-4749.12,3067Z'
            transform='translate(4758.837 -3066.927)'
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default KPLogo;
