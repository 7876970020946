import React, { FC } from 'react';
import EditorSectionActionButton, {
  EditorSectionActionButtonProps,
} from '../../../lib/components/EditorSectionActionButton';
import Container from '../../../../components/Container';

export const AttachmentsBlockActions: FC<Pick<EditorSectionActionButtonProps, 'onDelete' | 'onDuplicate'>> = ({
  onDuplicate,
  onDelete,
}) => {
  return (
    <Container right>
      <EditorSectionActionButton
        edge='end'
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        hideable={false}
        duplicatable
        deletable
      />
    </Container>
  );
};

export default AttachmentsBlockActions;
