import React, { FC, PropsWithChildren, ReactNode } from 'react';
import Container from '@/components/Container';
import Styles from '@/assets/js/Styles';
import SearchField from '@/components/SearchField';

export const FinderHeader: FC<
  PropsWithChildren<{
    title?: ReactNode;
    topRight?: ReactNode;
    bottomRight?: ReactNode;
    onQueryChange?: (query: string) => void;
    searchField?: ReactNode;
  }>
> = ({ children, title, topRight, bottomRight, onQueryChange, searchField }) => {
  return (
    <Container column top fullWidth>
      <Container
        fullWidth
        spaceBetween
        px={4}
        py={2}
        sx={{
          backgroundColor: Styles.Colors.LIGHT_GREY,
        }}>
        <Container>{title}</Container>
        <Container>{topRight}</Container>
      </Container>
      <Container spaceBetween fullWidth px={4} py={2} wrap>
        {!searchField &&
          (onQueryChange ? <SearchField onQueryChange={onQueryChange} round size='small' /> : <Container />)}
        {searchField}
        {bottomRight ? (
          <Container
            right
            sx={{
              flexGrow: 1,
            }}>
            {bottomRight}
          </Container>
        ) : null}
      </Container>
      {children}
    </Container>
  );
};
