import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { CloseReason, Fab, OpenReason, SpeedDial, SpeedDialAction } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Styles from '@/assets/js/Styles';
import { SectionType } from '../../declarations/models/SectionType';
import SectionIcon from '../../components/SectionIcon';
import { Section } from '../../declarations/models/Section';
import { Page } from '../../declarations/models/Page';
import { SectionModelFactory } from '../lib/factories/SectionModelFactory';
import { distinct } from '../../utils/array';
import { useEditorConfiguration } from '../lib/configuration/EditorConfigurationContext';

export interface AddPageSectionButtonGroupProps {
  onAddSection: (section: Section) => void;
}

export const AddPageSectionButtonGroup: FC<AddPageSectionButtonGroupProps> = ({ onAddSection }) => {
  const { t } = useTranslation('common');
  const config = useEditorConfiguration();
  const sections: Array<Section> = useWatch<Page, 'content.sections'>({ name: 'content.sections', defaultValue: [] });
  const [isOpen, setIsOpen] = React.useState(false);
  const canCloseDialTimeout = useRef<boolean>(false);

  const handleAddSection = (sectionType: SectionType) =>
    onAddSection(SectionModelFactory.makeSectionModel(sectionType));

  const addedSections = distinct(sections.map((section) => section.type));
  const availableSections = config
    .getAvailableSections()
    .filter(
      (section) =>
        (section !== SectionType.ARTICLE_LINKS && section !== SectionType.ARTICLE_HEAD) ||
        !addedSections.includes(section),
    );

  const openDial = (_e: unknown, reason: OpenReason) => {
    if (reason !== 'mouseEnter' && !isOpen) {
      canCloseDialTimeout.current = false;
      setIsOpen(true);
      // HACK: opening the dial triggers closing,
      //  wait for the animation to finish before allowing the dial to close again.
      setTimeout(() => {
        canCloseDialTimeout.current = true;
      }, 300);
    }
  };
  const closeDial = (_e: unknown, reason: CloseReason) => {
    if (reason !== 'mouseLeave' && isOpen && canCloseDialTimeout.current) {
      setIsOpen(false);
    }
  };

  if (!availableSections?.length) {
    return null;
  }

  if (availableSections?.length === 1) {
    return (
      <Fab
        aria-label={t('addX', { x: t(`SectionType.${availableSections[0]}`) })}
        onClick={() => handleAddSection(availableSections[0])}>
        <MaterialSymbol name='add' />
      </Fab>
    );
  }

  return (
    <SpeedDial
      open={isOpen}
      onOpen={openDial}
      onClose={closeDial}
      ariaLabel={t('add')}
      FabProps={{
        sx: {
          backgroundColor: Styles.Colors.MEDIUM_LIGHT_GREY,
          width: '45px',
          height: '45px',
        },
      }}
      icon={<MaterialSymbol name='add' />}
      direction='right'>
      {availableSections.map((section) => (
        <SpeedDialAction
          key={section}
          icon={<SectionIcon sectionType={section} />}
          tooltipTitle={t(`SectionType.${section}`)}
          arrow
          onClick={() => handleAddSection(section)}
        />
      ))}
    </SpeedDial>
  );
};

export default AddPageSectionButtonGroup;
